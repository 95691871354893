
import { FacilityType, FacilityTypeError } from "src/app/models/facility.model";

function validateEmail(email)
{
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}

export let FacilityValidation = (data: FacilityType, type) => {
 
    var emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    let error = new FacilityTypeError(false)
    if (type === "init")
        return error;
    if (!data.name) {
        error.name = true
    }
    if (!data.facilityCode) {
        error.facilityCode = true
    }
    if (!data.skillId) {
        error.skillId = true;
    }
    if (!data.address1) {
        error.address1 = true;
    }
    if (!data.address2) {
        error.address2 = true;
    }
    if (!data.stateId) {
        error.stateId = true;
    }
    if (!data.countryId) {
        error.countryId = true;
    }
    if (!data.postCode) {
        error.postCode = true;
    }
    if(!data. facilitySkills){
        error. facilitySkills= true;
    }
    // if(!validateEmail(data.email)){
    //     error.email= true;
    // }
   return error;
}
