import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { environment } from 'src/environments/environment';
import { NotificationService } from '@progress/kendo-angular-notification';
@Component({
  selector: 'import-attechment',
  templateUrl: './import-attechment.component.html',
  styleUrls: ['./import-attechment.component.scss']
})
export class ImportAttechmentComponent implements OnInit {
  constructor(private _notif: NotificationService) { }
  @Input() url: string;
  @Input() formate:Array<string>;
  @Input() maxSize:number;
  @Input() type:string="";
  @Input() fileUploadType:number=0;
  @Output() valueChange = new EventEmitter<{}>();
  @Output() extChange=new EventEmitter<string>();
  fileBaseUrl=environment.IMG_LOAD_SERVER;
  error=""
  ngOnInit(): void {
  }
  uploadFile(){
    HttpClient.importExcelFile(this.formate,this.fileUploadType,(err,data)=>{
      if(!err){
        this._notif.show({
          content: "successfully Uploaded",
          type: { style: "success", icon: true },
        });
        this.error=null
          this.valueChange.emit({status:true});
      }
      else{
        this.error=err;
        this.valueChange.emit({status:false})
        this._notif.show({
          content: "Failed to upload",
          type: { style: "error", icon: true },
        });
      }
    })
  }
  closeUploadFile(){
    this.error=null;
    this.valueChange.emit(null)
    this.extChange.emit(null);
  }
  ngOnChanges(changes: any): void {
    if(changes.url){
      this.url=changes.url.currentValue
    }
    if(changes.formate){
      this.formate=changes.formate.currentValue
    }
    if(changes.maxSize){
      this.maxSize=changes.maxSize.currentValue
    }
    if(changes.type){
      this.type=changes.type.currentValue
    }
  }
}
