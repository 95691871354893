<div *ngIf="type=='login' && !resetpass" class="container-fluid">
  <div class="row"  style=" background-color: #f1f1f1;">
    <div class="col-12 col-md-12 p-0">
      <div class="login-img">
        <!-- <div style=""> -->
          <img src="assets/klcc-building.jpg" alt="" >
        <!-- </div> -->
      </div>
      <div id="authentication-wrapper" >
        <div style="background: #ffffffb5;padding: 26px;height:100vh;float: left;width: 100%;border: solid 1px #c2c2c2;">
       
        <div id="wrap" class="logintitle">
          <label>ASSET MANAGEMENT SYSTEM</label>
         
        </div>
        <div id="welcome-message" style="padding: 0;margin-left: 0;margin-bottom: 0;">
          <h4 style="color:#c17537;margin:0px;padding:0px; margin-bottom: 10px;">Login to your account </h4>
        </div>
        
        <div class="error-msg">{{errorMsg}}</div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <kendo-textbox-container floatingLabel="Username">
              <input [(ngModel)]="email" kendoTextBox type="email" />
            </kendo-textbox-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <kendo-textbox-container floatingLabel="Password">
              <input [(ngModel)]="password" kendoTextBox type="password" />
            </kendo-textbox-container>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12">
            <button class="submit-btn btn btn-success" (click)="loginUser()">Login {{fullname}}</button>
          </div>
           <div class="col-xs-12 col-sm-12 col-md-12">
            <div>
             <span class="span_reminder"> <input  type="checkbox" > &nbsp;Remember me</span>   
              <a class="text-primary"  style="float: right;cursor: pointer;" (click)="type='forgetpassword';errorMsg=''">Forgot Password?</a>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 copy-rightimg">
            <p style="text-align: center;  color: #cadfe4;font-size: 24px;"><span style="font-size: 20px !important;">by </span> <img src="assets/ibcc.png" alt="IBCC favicon"> ibcc</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<div class="container-fluid" id="authentication-wrapper" *ngIf="type=='forgetpassword' && !resetpass">
  <div class="row"  style=" background-color: #f1f1f1;">
    <div class="col-12 col-md-8">
      <div class="login-img">
          <img src="assets/klcc-building.jpg" alt="">
      </div>
    </div>
    <div class="col-12 col-md-4">
    <div id="authentication-wrapper"  class="pr-45">
      <div id="wrap" class="logintitle">
        <label>ASSET MANAGEMENT SYSTEM</label>
       
      </div>
      <div id="welcome-message" style="padding-left: 0;margin-left: 0;margin-bottom: 0;">
        <h4>Forgot Password</h4>
      </div>
      <div class="error-msg">{{errorMsg}}</div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <!-- <kendo-textbox-container floatingLabel="Username">
            <input kendoTextBox type="email" />
          </kendo-textbox-container> -->
          <kendo-textbox-container floatingLabel="Email">
            <input [(ngModel)]="email" kendoTextBox type="email" />
          </kendo-textbox-container>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
          <button class="submit-btn btn btn-success" (click)="forgetPassword()">Submit</button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12">
        <kendo-textbox-container> <a class="text-primary" (click)="type='login'" style="text-align: center; padding-top: 0;cursor: pointer;">Back To Login</a>
        </kendo-textbox-container>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 copy-rightimg"  >
        <p style="text-align: center;  color: #cadfe4;font-size: 24px;"><span style="font-size: 20px !important;">by </span> <img src="assets/ibcc.png" alt="IBCC favicon"> ibcc</p>
      </div>
    </div>
  </div>
  </div>
</div>
<style>
#welcome-message{
  margin:30px 0PX 0PX 0PX
}
#welcome-message{
  margin: 0;
    color: #171717;
    font-weight: 500;
    FLOAT: LEFT;
    WIDTH: 100%;
    TEXT-ALIGN: CENTER;

}
.k-textbox, .k-textarea, .k-multiselect .k-multiselect-wrap{

}
input.k-textbox{
  padding: 0.375rem 0.75rem!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    color: #495057!important;
    background-color: #fff!important;
    border: 1px solid #ced4da!important;
    border-radius: 0.25rem!important;
}
  .copy-rightimg{
  position: absolute;bottom:85px; width: 87%;
  }
.login-img{
  height:100vh; 
  padding:0px ;
  border-right: solid 1px #adadad;
  padding: 0px 5px 0px 0px;
  position: absolute;
  width: 100%;
}
.login-img>img{
  width: 100%;height: 100%;border-radius:0px;
}

.span_reminder{
  width:100%;float: left;
}
.span_reminder>input{
  float: left;
    width: auto!important;
    margin-top: 6px;
}
.pr-45{
  padding-right: 45px;
}
.logintitle{margin-top: 80px;display: flex;justify-content: space-between;}
        .logintitle>label{width: 100%;font-weight:bold;font-size: 24px;
   
    color:#24548d!important;
    TEXT-ALIGN: CENTER;}
       
        @media only screen and (max-width: 767px) { 
  .login-img{
    display: none;
  height: clac(100vh - 10%) ; padding: 0px
}
.copy-rightimg{
  position: static;
}
.logintitle{
    width: 100%;
    float: left;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}
.login-img>img{
  width: 100%;height: 100%;border-radius: 50px;
} 

        .logintitle>label{width:100%; order: 2;}
        #wrap>img{
          text-align: center;
    order: 1;
    width: 82px;
    margin: 0 auto;
        }
        #authentication-wrapper{
          overflow-y: auto;
          width: 100%;
    background: #f1f1f1;
    position: absolute;
    height: 100vh;
        }
        .pr-45{
          padding:10px
        }
}
/* @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .copy-rightimg{
  position: static;
}
} */

</style>
<div *ngIf="type=='login' && resetpass" class="container-fluid">
  <div class="row" style=" background-color: #f1f1f1;">
    <div class="col-12 col-md-12 p-0" >
      <div  class="login-img">
        <!-- <div style=""> -->
          <img src="assets/klcc-building.jpg" alt="">
        <!-- </div> -->
      </div>
    </div>
    <div  class="col-12  col-md-4">
    <div id="authentication-wrapper">
      <div id="wrap" class="logintitle" >
        <label>ASSET MANAGEMENT SYSTEM</label>
       
      </div>
      <div id="welcome-message" style="padding-left: 0;margin-left: 0;margin-bottom: 0;">
        <h4>Reset your account </h4>
      </div>
      
      <div class="error-msg">{{errorMsg}}</div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <kendo-textbox-container floatingLabel="Password">
            <input [(ngModel)]="password1" kendoTextBox type="password" />
          </kendo-textbox-container>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
          <kendo-textbox-container floatingLabel="Confirm Password">
            <input [(ngModel)]="password" kendoTextBox type="password" />
          </kendo-textbox-container>
          <div *ngIf="password!==password1" class="error-msg">Password not match</div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
          <button class="submit-btn btn btn-success" (click)="updatePasswordUser()">Update Password</button>
        </div>
         <!-- <div class="col-xs-12 col-sm-12 col-md-12">
          <div>
            <input  type="checkbox" > <span style="color: #d7dfe4;">&nbsp;Remember me</span>   
            <a class="text-primary" (click)="type='forgetpassword'" style="float: right;cursor: pointer;">Forgot Passowrd?</a>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12" style="position: absolute;bottom: 40px;">
          <p style="text-align: center;  color: #cadfe4;font-size: 24px;"><span style="font-size: 20px !important;">by </span> <img src="assets/ibcc.png" alt="IBCC favicon"> ibcc</p>
        </div> -->
      </div>
    </div>
    </div>
  </div>

</div>