import { Component, OnInit } from '@angular/core';
import { DateFilterCellComponent } from '@progress/kendo-angular-grid';
import { convertDateFormate, pading } from 'src/ApiModel/Util';
import HttpClient from 'src/Util/ApiHandling';
import { objectToQueryString } from 'src/Util/Util';

@Component({
  selector: 'app-averag-wooutstanding-report',
  templateUrl: './averag-wooutstanding-report.component.html',
  styleUrls: ['./averag-wooutstanding-report.component.scss']
})
export class AveragWOOutstandingReportComponent implements OnInit {
  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }
  public parentId = -1;
  public moduleId = -1;

  public serviceObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }
  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public facility: any;
  public serviceType: any;
  public fromDate: Date;
  public toDate: Date;
  public monthId: number;
  public outstandingWO: number;
  public noDataFound: boolean = false;

  public outstandingWorkOrder = {
    alldata: [],
    filterData: [
      { id: 1, name: "New" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Waiting" },
      { id: 7, name: "InActive" },
      { id: 8, name: "Active" },
    ],
    selectedObj: null,
    objectData: {}
  }

  public month = {
    alldata: [],
    filterData: [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },

    ],
    selectedObj: null,
    objectData: {}
  }
  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "ServicesType")

    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy");
        result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        //ServicesType
        result[1].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.serviceObj.objectData = result[1].reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        this.serviceObj.alldata = result[1];
        this.serviceObj.filterData = []

      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }

  convertLocalDateString(d) {
    let ndate = new Date(d);
    return `${ndate.getFullYear()}-${pading(ndate.getMonth() + 1)}-${pading(ndate.getDate())}T00:00:00`
  }

  download() {
    let fileName = 'Average-WorkOrder-OutstandingData.csv';
    let columnNames = [
      "DEPARTMENT",
      "FACILITY",
      "WORKORDER NO",
      "WO CREATEDDATE",
      "WO CLOSING DATE TIME",
      "WO OUTSTANDING DAYS",
      "YTD OUTSTANDING DAYS",

    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["DEPARTMENT"],
        c["FACILITY"],
        c["WORKORDERNO"],
        c["CREATEDDATE"],
        c["CLOSINGDATETIME"],
        c["OUTSTANDINGDAYSMTD"],
        c["OUTSTANDINGDAYSYTD"],

      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
  }

  fetchReport() {
    let path = "";
    const end_date = (this.toDate != undefined) ? this.convertLocalDateString(this.toDate) : this.toDate;
    const facility_id = this.facility?.ID ?? 0;
    const serviceType_id = this.serviceType?.ID ?? 0;

    var qString = { endDate: end_date, servicdeTypeId: serviceType_id, facilityId: facility_id }

    path = `/api/CustomReport/GetOutstandingWorkOrders?${objectToQueryString(qString)}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          this.report = this.report.map((item) => {
            item["CREATEDDATE"] = convertDateFormate(item["CREATEDDATE"]);
            item["CLOSINGDATETIME"] = (item["CLOSINGDATETIME"] != null) ? convertDateFormate(item["CLOSINGDATETIME"]) : item["CLOSINGDATETIME"];
            return item;
          });
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].ID != null;
          this.noDataFound = !this.showReport;
          if (this.showReport) {
            const copy = ['OUTSTANDINGDAYSMTD', 'OUTSTANDINGDAYSYTD'];
            const res = this.report.map(data => copy.reduce((o, k) => (o[k] = data[k], o), {}));

            let avg = {};
            let temp = {};
            res.forEach(obj => Object.keys(obj).forEach(k => {
              if (obj[k] === null) return;
              temp[k] = temp[k] || { sum: 0, count: 0 };
              temp[k].sum += obj[k];
              temp[k].count++;
              avg[k] = temp[k].sum / temp[k].count;
            }));
            console.log(avg); console.log(temp);
            let monthlyRow: any = { OUTSTANDINGDAYSMTD: avg['OUTSTANDINGDAYSMTD'].toFixed(2), FACILITY: "Monthly Average" };
            let ytdRow: any = { OUTSTANDINGDAYSYTD: avg['OUTSTANDINGDAYSYTD'].toFixed(2), FACILITY: "YTD Average" }
            this.report.push(monthlyRow);
            this.report.push(ytdRow);
          }
        })
        .catch((err) => {

          reject(err)
        })
    })
  }

  public column = [
    { field: "DEPARTMENT", title: "DEPARTMENT", width: 200 },
    { field: "FACILITY", title: "FACILITY", width: 200 },
    { field: "WORKORDERNO", title: "WO NO", width: 200 },
    { field: "CREATEDDATE", title: "WO CREATED DATE", width: 200 },
    { field: "CLOSINGDATETIME", title: "WO CLOSING DATE TIME", width: 200 },
    { field: "OUTSTANDINGDAYSMTD", title: "WO OUTSTANDING DAYS", width: 200 },
    { field: "OUTSTANDINGDAYSYTD", title: "YTD OUTSTANDING DAYS", width: 200 },
  ]
}

export class AvgWorkOrderOutstandingReport {
  CLOSINGDATETIME: Date;
  CREATEDDATE: Date;
  DEPARTMENT: string;
  ID: number;
  NAME: string;
  OUTSTANDINGDAYSMTD: number;
  OUTSTANDINGDAYSYTD: number;
  REFERENCEID: number;
  REFERENCETYPE: string

}


