<div class="card m-3"  >
    <div class="card-header">
        <h5>Work Order Query</h5>
        <div>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid [data]="areagrid" [column]="column2" [size]="10" [action]="true" [deleteFlag]="false"
            [DownloadPDFFlag]="true"></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose('main')" [width]="1200" [height]="650">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    <kendo-tabstrip>
        <!-- <kendo-tabstrip-tab  *ngIf="type==='new' || currentTab==workOrderType.assets.id" [title]="workOrderType.assets.title" [selected]="currentTab==workOrderType.assets.id">
          <ng-template kendoTabContent >
                <div class="py-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Request No</label>
                            <input kendoTextBox class="form-control" [value]="tempUpdateObj['workOrderNo']" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Assets<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="assetDataObj.filterData" 
                                [value]="assetDataObj.selectedObj" 
                                (valueChange)="valuechange('assets',$event)" 
                                class="form-control"
                                [textField]="'serialNo'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Description<span class="required">*</span></label>
                            <input [(ngModel)]="description" kendoTextBox  class="form-control" />
                        </div>
                        <div class="form-group col-5">
                            <label>Service Type<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="serviceObj.alldata" 
                                [value]="serviceObj.selectedObj" 
                                (valueChange)="valuechange('service',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Work Order Type<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="workOrderTypeObj.filterData" 
                                [value]="workOrderTypeObj.selectedObj" 
                                (valueChange)="valuechange('workOrderType',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Consequences Category<span class="required">*</span></label>
                                <kendo-dropdownlist  
                                [data]="consequanceObj.filterData" 
                                [value]="consequanceObj.selectedObj" 
                                (valueChange)="valuechange('consequences',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Status<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="ticketStatusObj.filterData" 
                                [value]="ticketStatusObj.selectedObj" 
                                (valueChange)="valuechange('ticketStatus',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Chargeable<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="chargeAbleObj.filterData" 
                                [value]="chargeAbleObj.selectedObj" 
                                (valueChange)="valuechange('chargeable',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assignee</label>
                            <kendo-dropdownlist  
                                [data]="assigineeObj.filterData" 
                                [value]="assigineeObj.selectedObj" 
                                (valueChange)="valuechange('assignee',$event)" 
                                class="form-control"
                                [textField]="'staffName'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Assign To</label>
                            <kendo-dropdownlist  
                                [data]="assignToObj.filterData" 
                                [value]="assignToObj.selectedObj" 
                                (valueChange)="valuechange('assignTo',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                            Save
                        </button>
                        <button kendoButton *ngIf="type=='new'"  primary="true" class="px-3" (click)="reset()">
                            Reset
                        </button>
                    </div>
                
                </div>
              </ng-template>
         </kendo-tabstrip-tab> -->
        <kendo-tabstrip-tab [title]="'Workorder Detail'" [selected]="true"  >
            <ng-template kendoTabContent>
                <div class="py-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Request No</label>
                            <input kendoTextBox [value]="tempUpdateObj['requestNo']" class="form-control" disabled />
                        </div>
                        <div class="form-group col-5">
                            <label>Facility<span class="required">*</span></label>
                            <kendo-dropdownlist [data]="facilityObj.filterData" [value]="facilityObj.selectedObj"
                                (valueChange)="valuechange('facility',$event)" class="form-control" [textField]="'NAME'"
                                [valueField]="'ID'" [disabled]="disabled">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Block Code<span class="required">*</span></label>
                            <kendo-dropdownlist [data]="blockObj.filterData" [value]="blockObj.selectedObj"
                                (valueChange)="valuechange('block',$event)" class="form-control" [textField]="'CODE'"
                                [valueField]="'ID'" [disabled]="disabled">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Block Name</label>
                            <input kendoTextBox class="form-control"
                                [value]="blockObj.selectedObj && blockObj.selectedObj.NAME" disabled />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Level Code<span class="required">*</span></label>
                            <kendo-dropdownlist [data]="levelObj.filterData" [value]="levelObj.selectedObj"
                                (valueChange)="valuechange('level',$event)" class="form-control" [textField]="'CODE'"
                                [valueField]="'ID'" [disabled]="disabled">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Level Name</label>
                            <input kendoTextBox class="form-control"
                                [value]="levelObj.selectedObj && levelObj.selectedObj.NAME" disabled />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Area Code<span class="required">*</span></label>
                            <kendo-dropdownlist [data]="areaObj.filterData" [value]="areaObj.selectedObj"
                                (valueChange)="valuechange('area',$event)" class="form-control" [textField]="'CODE'"
                                [valueField]="'ID'" [disabled]="disabled">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Area Name</label>
                            <input kendoTextBox [value]="areaObj.selectedObj && areaObj.selectedObj.NAME"
                                class="form-control" disabled />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Location Code<span class="required">*</span></label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="locationObj.filterData" [value]="locationObj.selectedObj"
                                    (valueChange)="valuechange('location',$event)" class="form-control"
                                    [textField]="'CODE'" [valueField]="'ID'" [disabled]="disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.referenceId">*Required</kendo-formerror>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Location Name</label>
                            <input kendoTextBox [value]="locationObj.selectedObj && locationObj.selectedObj.NAME"
                                class="form-control" disabled />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Description<span class="required">*</span></label>
                            <div style="width: 100%">
                                <input kendoTextBox [(ngModel)]="description" class="form-control" disabled />
                                <kendo-formerror *ngIf="error.description">*Required</kendo-formerror>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Service Type<span class="required">*</span></label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="serviceObj.alldata" [value]="serviceObj.selectedObj"
                                    (valueChange)="valuechange('service',$event)" class="form-control"
                                    [textField]="'NAME'" [valueField]="'ID'" [disabled]="!disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.serviceTypeId">*Required</kendo-formerror>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Work Order Type<span class="required">*</span></label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="workOrderTypeObj.filterData"
                                    [value]="workOrderTypeObj.selectedObj"
                                    (valueChange)="valuechange('workOrderType',$event)" class="form-control"
                                    [textField]="'name'" [valueField]="'id'" [disabled]="!disabled">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Consequences Category<span class="required">*</span></label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="consequanceObj.filterData"
                                    [value]="consequanceObj.selectedObj"
                                    (valueChange)="valuechange('consequences',$event)" class="form-control"
                                    [textField]="'NAME'" [valueField]="'ID'" [disabled]="!disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.ticketConsequenceId">*Required</kendo-formerror>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Status<span class="required">*</span></label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="ticketStatusObj.filterData"
                                    [value]="ticketStatusObj.selectedObj"
                                    (valueChange)="valuechange('ticketStatus',$event)" class="form-control"
                                    [textField]="'NAME'" [valueField]="'ID'" [disabled]="disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.ticketStatusId">*Required</kendo-formerror>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Chargeable<span class="required">*</span></label>
                            <kendo-dropdownlist [data]="chargeAbleObj.filterData" [value]="chargeAbleObj.selectedObj"
                                (valueChange)="valuechange('chargeable',$event)" class="form-control"
                                [textField]="'name'" [valueField]="'id'" [disabled]="!disabled">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assignee</label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="assigineeObj.filterData" [value]="assigineeObj.selectedObj"
                                    (valueChange)="valuechange('assignee',$event)" class="form-control"
                                    [textField]="'staffName'" [valueField]="'id'" [disabled]="!disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Assign To</label>
                            <div style="width: 100%">
                                <kendo-dropdownlist [data]="assignToObj.filterData" [value]="assignToObj.selectedObj"
                                    (valueChange)="valuechange('assignTo',$event)" class="form-control"
                                    [textField]="'name'" [valueField]="'id'" [disabled]="!disabled">
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.bpmid">*Required</kendo-formerror>
                            </div>
                        </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton primary="true" class="mr-2 px-3" (click)="saveData()">
                            Save
                        </button>
                        <!-- <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button kendoButton *ngIf="type=='new'"  primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button> -->
                    </div>

                </div>
            </ng-template>
        </kendo-tabstrip-tab>app-completeion-info
        <kendo-tabstrip-tab [title]="'Work Order Reassign'"  >
            <ng-template kendoTabContent>
                <app-work-order-reassign [workorder]="tempUpdateObj"></app-work-order-reassign>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Spare Part Replacement'">
            <ng-template kendoTabContent>
                <div class="card-body register-form-body">
                    <app-table-grid [data]="spareGridData" [column]="sparePartColumn" [size]="10" [action]="false">
                    </app-table-grid>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Attachments'" *ngIf="permission && permission.isUpdate">
            <ng-template kendoTabContent>
                <div class="form-row" style="margin-top: 1rem;">
                    <div class="form-group col-5">
                        <label>File Type</label>
                        <kendo-dropdownlist [data]="documentFormate" class="form-control" [textField]="'name'"
                            [valueField]="'id'" [(ngModel)]="attechForm.FILETYPE" [valuePrimitive]="true">
                        </kendo-dropdownlist>
                    </div>
                    <div class="form-group col-5">
                        <label>File Name</label>
                        <div style="width: 100%;">
                            <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                            <!-- <kendo-formerror *ngIf="attechError.FILENAME">*required</kendo-formerror> -->

                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-5">
                        <label>Attachment</label>
                        <div style="width: 100%;">
                            <app-file-upload type="doc" [formate]="[attechForm.FILETYPE]" [url]="attechForm.URL"
                                (valueChange)="attechForm.URL=$event" style="width: 100%;">
                            </app-file-upload>
                        </div>
                    </div>

                    <div class="form-group col-5">
                        <button (click)="addAttechment()" kendoButton primary="true">
                            Add
                        </button>
                    </div>
                    <app-attachment-table-grid [data]="attechmentDoc.alldata" [column]="attechmentColumn" [size]="5">
                    </app-attachment-table-grid>

                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Completion Info'"  >
            <ng-template kendoTabContent>
                <app-completeion-info [workorder]="tempUpdateObj" [woStatus]="ticketStatusObj"
                    (statusChange)="workorderStatus($event)" [chargeable]="chargeAbleObj" [user]="assigineeObj">
                </app-completeion-info>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>

</kendo-dialog>

<kendo-dialog *ngIf="attachmentOpened" (close)="onDialogClose('attachment')" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>

    <div class="form-row">
        <div class="form-group col-5">
            <label>File Type</label>
            <kendo-dropdownlist [data]="documentFormate" class="form-control" [textField]="'name'" [valueField]="'id'"
                [(ngModel)]="attechForm.FILETYPE" [valuePrimitive]="true">
            </kendo-dropdownlist>
        </div>
        <div class="form-group col-5">
            <label>File Name</label>
            <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <!-- <kendo-formerror *ngIf="attechError.FILENAME">*required</kendo-formerror> -->

            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Attachment</label>
            <div style="width: 100%;">
                <app-file-upload type="doc" [formate]="[attechForm.FILETYPE]" [url]="attechForm.URL"
                    (valueChange)="attechForm.URL=$event" style="width: 100%;">
                </app-file-upload>
                <!-- <kendo-formerror *ngIf="attechError.URL">*required</kendo-formerror> -->
            </div>
        </div>
        <div class="form-group col-5">
            <button (click)="addAttechment()" kendoButton primary="true">
                Add
            </button>
        </div>
    </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('attachment')">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid [data]="attechmentDoc.alldata" [column]="attechmentColumn" [size]="5">
    </app-attachment-table-grid>

</kendo-dialog>

<kendo-dialog *ngIf="viewOpen" (close)="viewOpen=false" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            View Spare Part
        </div>
    </kendo-dialog-titlebar>
    <div class="card-body register-form-body">
        <app-table-grid [data]="spareGridData" [column]="sparePartColumn" [size]="5" [action]="false">
        </app-table-grid>
    </div>
</kendo-dialog>


<kendo-dialog *ngIf="pdfDialog" (close)="onDialogClose('pdfDialog')" [width]="1200" [height]="650">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    <div style="background-color:white;color:black">
        <div class="form-row">
            <div class="form-group col-5" style="padding:1em;">
                <button kendoButton (click)="pdfworkorder.saveAs(fileName)" primary="true">
                    Save As PDF...
                </button>
            </div>
            <div class="form-group col-5"></div>
        </div>
        <kendo-pdf-export #pdfworkorder paperSize="A4" margin="1cm" [scale]="0.7">
            <div style="font-size: 18px; line-height: 1.3em;padding-left:3em;margin:auto;text-align: center;"><b>Work
                    Order Details</b> </div>
            <div class="py-4">
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Request No</strong> </label>
                        <label>{{tempUpdateObj['requestNo']}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Facility</strong></label>
                        <label>{{facilityObj.selectedObj.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Block Code</strong></label>
                        <label>{{blockObj.selectedObj.CODE}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Block Name</strong></label>
                        <label>{{blockObj.selectedObj && blockObj.selectedObj.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Level Code</strong></label>
                        <label>{{levelObj.selectedObj.CODE}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Level Name</strong></label>
                        <label>{{levelObj.selectedObj && levelObj.selectedObj.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Area Code</strong></label>
                        <label>{{areaObj.selectedObj.CODE}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Area Name</strong></label>
                        <label>{{areaObj.selectedObj && areaObj.selectedObj.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Location Code</strong></label>
                        <label>{{locationObj.selectedObj.CODE}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Location Name</strong></label>
                        <label>{{locationObj.selectedObj && locationObj.selectedObj.NAME}}</label>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Description</strong></label>
                        <div style="width: 100%">
                            <label>{{description}}</label>
                        </div>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Service Type</strong></label>
                        <label>{{serviceObj.selectedObj?.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Work Order Type</strong></label>
                        <label>{{workOrderTypeObj.selectedObj && workOrderTypeObj.selectedObj?.name}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Consequences Category</strong></label>
                        <label>{{consequanceObj.selectedObj?.NAME}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Status</strong></label>
                        <label>{{ticketStatusObj.selectedObj?.NAME}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Chargeable</strong></label>
                        <label>{{chargeAbleObj.selectedObj && chargeAbleObj.selectedObj?.name}}</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group mb-0 col-5">
                        <label><strong>Assignee</strong></label>
                        <label>{{assigineeObj.selectedObj?.staffName}}</label>
                    </div>
                    <div class="form-group mb-0 col-5">
                        <label><strong>Assign To</strong></label>
                        <label>{{assignToObj.selectedObj && assignToObj?.selectedObj?.name}}</label>
                    </div>
                </div>
            </div>

            <app-work-order-reassign-pdf [workorder]="tempUpdateObj">

            </app-work-order-reassign-pdf>

            <div style="font-size: 18px; line-height: 1.3em;padding-left:3em"><b>Spare Part Replace</b> </div>
            <div class="py-4" style="padding: 4em;">
                <table style="width: 100%;border: 1px solid gray;text-align: center;padding:5px;">
                    <thead>
                        <tr>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Part No.</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Part Description</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Bin No.</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Quantity on hand (QOH)
                            </th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Facility Name</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Facility Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of spareGridData">
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">{{item?.partNo}}</td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">{{item?.partDescription}}
                            </td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">{{item?.binNo}}</td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">{{item?.qoh}}</td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">
                                {{item?.facilityObj?.NAME}}</td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">
                                {{item?.facilityObj?.FACILITYCODE}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="font-size: 18px; line-height: 1.3em;padding-left:3em"><b>Completion Info</b></div>

            <app-completeion-info-pdf [workorder]="tempUpdateObj" [woStatus]="ticketStatusObj"
                (statusChange)="workorderStatus($event)" [chargeable]="chargeAbleObj" [user]="assigineeObj">
            </app-completeion-info-pdf>


            <div style="font-size: 18px; line-height: 1.3em;padding-left:3em"><b>Attachments</b> </div>
            <div class="py-4" style="padding: 4em;">
                <table style="width: 100%;border: 1px solid gray;text-align: center;padding:5px;">
                    <thead>
                        <tr>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">File Type</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">File Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of attechmentDoc.alldata">
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">{{item?.FILETYPE}}</td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">
                                <div (click)="downloadAttachment(item.URL,item.FILENAME)" style="cursor: pointer; display: flex;flex-direction: column;align-items: center;">
                                    <img src="assets/pdf-icon.svg" *ngIf="['pdf','PDF'].includes(item?.FILETYPE)" style="height: 3.5cm;width: 5.5cm;">
                                    <img crossorigin="anonymous" src="{{backendUrl+item.URL+'?not-from-cache-please'}}" *ngIf="['jpg','png','JPG','PNG'].includes(item?.FILETYPE)" style="height: 3.5cm;width: 5.5cm;">
                                    <!-- <img src="assets/image.svg" *ngIf="['jpg','png','JPG','PNG'].includes(item?.FILETYPE)" style="height: 3.5cm;width: 5.5cm;"> -->
                                    <img src="assets/word.svg" *ngIf="['doc','docx'].includes(item?.FILETYPE)" style="height: 3.5cm;width: 5.5cm;">{{item?.FILENAME}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="font-size: 18px; line-height: 1.3em;padding-left:3em"><b>Checklist</b> </div>
            <div class="py-4" style="padding: 4em;">
                <table style="width: 100%;border: 1px solid gray;text-align: center;padding:5px;">
                    <thead>
                        <tr>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Task Checklist</th>
                            <th style="border: 1px solid gray;text-align: center;padding:5px;">Task Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of checklist">
                            <td style="border: 1px solid gray;text-align: center;padding:5px;" [innerHTML]="item?.description">
                                </td>
                            <td style="border: 1px solid gray;text-align: center;padding:5px;">
                                {{item?.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </kendo-pdf-export>
    </div>
</kendo-dialog>