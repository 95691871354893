import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import HttpClient from 'src/Util/ApiHandling';
import { eventListner, EVENT_NAME } from "src/event"
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import { NotificationService } from "@progress/kendo-angular-notification";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule";

@Component({
  selector: 'app-userrole',
  templateUrl: './userrole.component.html',
  styleUrls: ['./userrole.component.scss']
})
export class UserroleComponent {
  public opened = false;
  public permissionerror=false
  public removeDialogOpen = false;
  public actionsLayout = "normal";
  public dailogTitle = "";
  public mainArr =[]
  public permissionData;
  public permissionDataById: any;
  public permission:Permission=new Permission();
  public userFormData;
  public roleId;
  public globalGetModule;
  public removeRoleByIdData;
  public statusDropDown = [];
  // public gridData: any[] = user_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column = [
    { field: "name", title: "Role Name", isFilter: true, width: 180 },
    { field: "moduleNames", title: "Competency", isFilter: true }
  ]
  public permissionColumn = [
    { field: "name", type: "text", title: "Module Name", width: 180 },
    { field: "isCreate", type: "checkbox", title: "Add", width: 50 },
    { field: "isRead", type: "checkbox", title: "Read", width: 60 },
    { field: "isUpdate", type: "checkbox", title: "Update", width: 70 },
    { field: "isDelete", type: "checkbox", title: "Delete", width: 70 },
    { field: "isExport", type: "checkbox", title: "Export", width: 70  },
    { field: "isImport", type: "checkbox", title: "Import", width: 70  },
    { field: "isPrint", type: "checkbox", title: "Print", width: 70  },
    

  ]
  public rolePermission = [];
  public gridData = [];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  userForm: FormGroup;
  submitted = false;
  userroles = ['Super Admin', 'Company User', 'External User', 'Facility User'];
  status = ['Active', 'Inactive'];
  competencys = ['Management', 'Building System', 'Machanical', 'Electrical', 'Operations', 'Integrated Services', 'CSRM', 'IBCC', 'Housekeeping'];

  constructor(private formBuilder: FormBuilder,
    private _notif: NotificationService
  ) {
    eventListner(EVENT_NAME.edit_column, (e) => { this.getRoleById(e) })
    eventListner(EVENT_NAME.permission_change, (e) => { this.permissionStateChange(e) })
    eventListner(EVENT_NAME.remove_column, (e) => { this.removeRolebyId(e) })
  }

  ngOnInit() {
    if(UserModuleObj.user_screen_mapping){
      this.permission=UserModuleObj.user_screen_mapping;
    }
    this.getRoles();
    this.getModule();
    
    this.getStatus("dbo", "status"),
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      remark: [''],
      loggedInUserId: [],
      moduleNames: [''],
      statusId:[],
      id: [0]
    }, {
      // validator: MustMatch('password', 'confirmPassword')
    });

    this.userForm.patchValue({
      statusId: 1,
    });
    this.userForm.patchValue({
      loggedInUserId:parseInt(getCookie(userid))
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.userForm.controls; }

  

  getRoles() {
    HttpClient.get("/Api/MasterData/GetRoles", true, true)
      .then((result: any) => {
      let roleresult =  shiftLastDataInto1st(result)||[];
        this.gridData = roleresult.map((d) => {
          return d;
        })
      })
      .catch((err) => {
      })
  }

  onDialogClose(type) {
    if (type = "role") {
      this.submitted = false;
      this.userForm.reset();
      this.rolePermission = [];
      this.getModule();
      this.roleId = "";
      this.opened = false;

    }
    if (type = "remove") {
      this.submitted = false;
      this.userForm.reset();
      this.rolePermission = [];
      this.getModule();
      this.roleId = "";
      this.removeDialogOpen = false;
    }
  }

  openRoleDailog(type) {
    if (type == "new") {
      this.dailogTitle = "Add Role";
         this.permissionerror = false
      
      this.rolePermission =[]
      this.manipulateMainDataintoGrid(this.globalGetModule,"new");
    }
    if (type == "update") {
      this.dailogTitle = "Edit Role"
      this.submitted = false;
    }
    this.opened = true;
  }

  getRoleById(data) {
  
    this.submitted = false;
    this.roleId = data.dataItem.id;
    this.openRoleDailog('update');
    HttpClient.get("/Api/MasterData/GetRoleById?id=" + data.dataItem.id, true, true)
      .then((result: any) => {
      
        const data = result;
        this.margeAllModuleWithgeinModule(result.modules);
        this.userForm.patchValue({
          name: data['name'],
          remark: data['remark'],
          loggedInUserId: data['loggedInUserId'],
          id: data['id'],
          statusId:data['statusId']
        }, {
          // validator: MustMatch('password', 'confirmPassword')
        });
      })
  }

  getModule() {
    HttpClient.get("/Api/MasterData/GetModules", true, true)
      .then((result: Array<any>) => {
        
        // let result:any=roleModule;
        this.permissionData = result;
        this.globalGetModule = result;
        // this.manipulateMainDataintoGrid(result);
      });
  }

  permissionStateChange(e) {
  
    this.rolePermission = e;
  }

  submitData() {
    this.userForm.value.loggedInUserId =parseInt(getCookie(userid))
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    else {
      this.rolePermission.forEach(ele => {
        if (ele.j != undefined && ele.j != null && ele.j >= 0) {
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isRead = ele.isRead;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isCreate = ele.isCreate;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isUpdate = ele.isUpdate;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isDelete = ele.isDelete;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isExport = ele.isExport;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isImport = ele.isImport;
          this.permissionData[ele.i].subModules[ele.j].permissionValueSelf.isPrint = ele.isPrint;
         
        }
        else {
          this.permissionData[ele.i].permissionValueSelf.isRead = ele.isRead;
          this.permissionData[ele.i].permissionValueSelf.isCreate = ele.isCreate;
          this.permissionData[ele.i].permissionValueSelf.isUpdate = ele.isUpdate;
          this.permissionData[ele.i].permissionValueSelf.isDelete = ele.isDelete;
          this.permissionData[ele.i].permissionValueSelf.isExport = ele.isExport;
          this.permissionData[ele.i].permissionValueSelf.isImport = ele.isImport;
          this.permissionData[ele.i].permissionValueSelf.isPrint= ele.isPrint;
         
        }
      });

      let checkedModule = this.getCheckedModules(this.permissionData);
     if (checkedModule.length == 0) {
       this.permissionerror = true
        return;
      }
      else {
        this.permissionerror = false
        let actualMod = checkedModule.map((e)=>{
          return e.subModules
        })
        
            let arr=[]
            let i
        for( i=0;i< actualMod.length;i++){
              arr[i] = actualMod[i];
              this.mainArr.push(...arr[i])
        }
           let modulesArr =[]
           modulesArr =this.mainArr.filter((e)=>{
            if(e.permissionValueSelf.isPrint!=false ||e.permissionValueSelf.isPrint!=false ||e.permissionValueSelf.isImport!=false || e.permissionValueSelf.isExport!=false || e.permissionValueSelf.isDelete!=false || e.permissionValueSelf.isCreate!=false || e.permissionValueSelf.isRead!=false || e.permissionValueSelf.isUpdate!=false || e.permissionValueSelf.isUpdate!=false){
            return e;
             }
           })
     
        const params = {
          ...this.userForm.value,
            id:0,
          modules: modulesArr
        };
        HttpClient.fetchData("/Api/MasterData/AddRole", "post", params, true, true)
          .then((result: any) => {
            if (result.isValid == true) {
              HttpClient.insertAduitLog("Users","Add Role","Add Role in Users").then((res:any)=>{
                if (res.isValid == true) {
        
                }
              })
              this._notif.show({
                content: result.messageKey,
                type: { style: "success", icon: true },
              });
              this.userForm.reset();
              this.roleId = "";
              this.submitted = false;
            this.globalGetModule;
          this.getRoles()
            this.opened = false
             
              // var modules = params.modules;
              // var allModuleName = [];
              // modules.forEach(x => {
              //   var moduleName = x.name;
              //   allModuleName.push(moduleName);
              //   var subModule = x.subModules;
              //   if (subModule.length > 0) {
              //     subModule.forEach(y => {
              //       var subModuleName = y.name;
              //       allModuleName.push(subModuleName);
              //     })
              //   }
              // })
              // let obj = {
              //   ...this.userForm.value,
              //   moduleNames: allModuleName,
              //   id:result.id
              // }
              // this.gridData.unshift(obj);
              // this.gridData = JSON.parse(JSON.stringify(this.gridData))
              // this.userForm.reset();
              // this.rolePermission = [];
              // this.submitted = false;
              // this.opened = false;
            }
            else {
              this._notif.show({
                content: result.messageKey,
                type: { style: "error", icon: true },
              });
            }
          }).catch((error) => {
          })
      }
    }
  }

  onReset() {
    this.submitted = false;
    this.userForm.reset();
    this.rolePermission.forEach(ele => {
              ele.isCreate = false;
              ele.isDelete = false;
              ele.isUpdate = false;
              ele.isRead = false;
              ele.isExport = false;
              ele.isImport = false;
              ele.isPrint = false;
    })
}

  manipulateMainDataintoGrid(result,type) {
    if(type==="new"){
      this.rolePermission = []
      result.forEach((d: any, i) => {
        let obj = {
          id: d.id,
          i: i,
          j:-1,
          parentId: d.parentId,
          name: d.name,
          isRead: false,
          isCreate: false,
          isUpdate: false,
          isDelete: false,
          isExport:  false,
          isImport:  false,
          isPrint: false
        }
        this.rolePermission.push(obj)
        if (d.subModules && d.subModules.length > 0) {
          d.subModules.forEach((ele, j) => {
            let obj = {
              id: ele.id,
              j: j,
              i: i,
              parentId: ele.parentId,
              name: ele.name,
              isRead:  false,
              isCreate: false,
              isUpdate: false,
              isDelete:  false,
              isExport:  false,
              isImport: false,
              isPrint:  false,
            }
            this.rolePermission.push(obj);
          });
        }
      })
    }

    else{
    this.rolePermission = []
    result.forEach((d: any, i) => {
      let obj = {
        id: d.id,
        i: i,
        j:-1,
        parentId: d.parentId,
        name: d.name,
        isRead: d.permissionValueSelf.isRead,
        isCreate: d.permissionValueSelf.isCreate,
        isUpdate: d.permissionValueSelf.isUpdate,
        isDelete: d.permissionValueSelf.isDelete,
        isExport: d.permissionValueSelf.isExport,
        isImport: d.permissionValueSelf.isImport,
        isPrint: d.permissionValueSelf.isPrint
      }
      this.rolePermission.push(obj)
      if (d.subModules && d.subModules.length > 0) {
        d.subModules.forEach((ele, j) => {
          let obj = {
            id: ele.id,
            j: j,
            i: i,
            parentId: ele.parentId,
            name: ele.name,
            isRead: ele.permissionValueSelf.isRead,
            isCreate: ele.permissionValueSelf.isCreate,
            isUpdate: ele.permissionValueSelf.isUpdate,
            isDelete: ele.permissionValueSelf.isDelete,
            isExport: ele.permissionValueSelf.isExport,
            isImport: ele.permissionValueSelf.isImport,
            isPrint: ele.permissionValueSelf.isPrint,
          }
          this.rolePermission.push(obj);
        });
      }
    
    })
  }
  
  }

  editRowColumn() {
    this.submitted = true;
    // stop here if form is invalid
    this.rolePermission.forEach(ele => {
      if (ele.j>=0) {
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isRead = ele.isRead;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isCreate = ele.isCreate;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isUpdate = ele.isUpdate;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isDelete = ele.isDelete;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isImport = ele.isImport;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isExport = ele.isExport;
        this.permissionDataById[ele.i].subModules[ele.j].permissionValueSelf.isPrint = ele.isPrint;
    
      }
      else {
        this.permissionDataById[ele.i].permissionValueSelf.isUpdate = ele.isUpdate;
        this.permissionDataById[ele.i].permissionValueSelf.isRead = ele.isRead;
        this.permissionDataById[ele.i].permissionValueSelf.isDelete = ele.isDelete;
        this.permissionDataById[ele.i].permissionValueSelf.isCreate = ele.isCreate;
        this.permissionDataById[ele.i].permissionValueSelf.isImport = ele.isImport;
        this.permissionDataById[ele.i].permissionValueSelf.isExport = ele.isExport;
        this.permissionDataById[ele.i].permissionValueSelf.isPrint = ele.isPrint;
       // console.log(this.permissionDataById[ele.i],ele)

      }
    });
    let checkedModule = this.getCheckedModules(this.permissionDataById)
    let actualMod = checkedModule.map((e)=>{
      return e.subModules
    })
    
        let arr=[]
        let i
    for( i=0;i< actualMod.length;i++){
          arr[i] = actualMod[i];
          this.mainArr.push(...arr[i])
    }
       let modulesArr =[]
       modulesArr =this.mainArr.filter((e)=>{
        if(e.permissionValueSelf.isPrint!=false ||e.permissionValueSelf.isPrint!=false ||e.permissionValueSelf.isImport!=false || e.permissionValueSelf.isExport!=false || e.permissionValueSelf.isDelete!=false || e.permissionValueSelf.isCreate!=false || e.permissionValueSelf.isRead!=false || e.permissionValueSelf.isUpdate!=false || e.permissionValueSelf.isUpdate!=false){
        return e;
         }
       })
  const params = {
      ...this.userForm.value,
      modules:  modulesArr
    };
    HttpClient.fetchData("/Api/MasterData/UpdateRole", "post", params, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Users","Update Role","Update Role in Users").then((res:any)=>{
            if (res.isValid == true) {
    
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.userForm.reset();
          this.roleId = "";
          this.globalGetModule;
          this.onReset()
          this.submitted = false;
          this.getRoles()
          // modules.forEach(x => {
          //   var moduleName = x.name;
          //   allModuleName.push(moduleName);
          //   var subModule = x.subModules;
          //   if (subModule.length > 0) {
          //     subModule.forEach(y => {
          //       var subModuleName = y.name;
          //       allModuleName.push(subModuleName);
          //     })
          //   }
          // })
          // let obj = {
          //   ...this.userForm.value,
          //   moduleNames: allModuleName
          // }
          // this.gridData = this.gridData.map((d) => {
          //   if (d.id == this.userForm.value.id) {
          //     d = obj;
          //   }
          //   return d;
          // })
         
          // this.roleId = "";
          // this.globalGetModule;
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      }).catch((error) => {

      })
  }

  removeRolebyId(e) {
    this.removeRoleByIdData = e.dataItem;
    this.removeDialogOpen = true;
  }

  removeRole() {
    
    const params = {
      "loggedInUserId": this.removeRoleByIdData.loggedInUserId,
      "id": this.removeRoleByIdData.id,
      "employeeGlobalId": 0,
      "instance": ""
    };
    HttpClient.fetchData("/Api/MasterData/DeleteRole", "post", params, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Users","Remove Role","Remove Role in Users").then((res:any)=>{
            if (res.isValid == true) {
    
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style:"success", icon: true },
          });
          this.gridData = this.gridData.filter(x => {
            return x.id != this.removeRoleByIdData.id;
          })
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      }).catch((error) => {
      });
  }

  margeAllModuleWithgeinModule(modules) {
    
    let tempModule = this.globalGetModule.map((m) => {
      // result.forEach((d)=>{
      //   if(d.subModules && d.subModules.length>0){
      //     d.subModules.forEach((d1)=>{
      //       this.globalGetModule.push(d1);
      //     })
      //   }
      // })
      // let tempFindModule = modules.filter((d) => {
      //   return d.parentId == m.id
      // })
      
      // if(tempFindModule.length > 0){
      //   let tempFindSubModule = modules.filter((d) => {
      //     return d.id == m.id
      //   })
      // }
      if (m.subModules  && m.subModules.length > 0) {
        // let tempSubmodule = tempFindModule[0].subModules;
        // m.rolesModuleId = tempFindModule[0].rolesModuleId;
        // m.permissionValueSelf.isRead = tempFindModule[0].permissionValueSelf.isRead
        // m.permissionValueSelf.isCreate = tempFindModule[0].permissionValueSelf.isCreate
        // m.permissionValueSelf.isUpdate = tempFindModule[0].permissionValueSelf.isUpdate
        // m.permissionValueSelf.isDelete = tempFindModule[0].permissionValueSelf.isDelete
        // m.permissionValueSelf.isImport = tempFindModule[0].permissionValueSelf.isImport
        // m.permissionValueSelf.isExport = tempFindModule[0].permissionValueSelf.isExport
        // m.permissionValueSelf.isPrint = tempFindModule[0].permissionValueSelf.isPrint

        let subModule = m.subModules.map((subM) => {
          let findSubModule = modules.filter((ts) => {
            return ts.id === subM.id;
          })
          if (findSubModule.length > 0) {
            let findSub = findSubModule[0];
            subM.rolesModuleId = findSub.roleModuleId
            subM.permissionValueSelf.isRead = findSub.permissionValueSelf.isRead
            subM.permissionValueSelf.isCreate = findSub.permissionValueSelf.isCreate
            subM.permissionValueSelf.isUpdate = findSub.permissionValueSelf.isUpdate
            subM.permissionValueSelf.isDelete = findSub.permissionValueSelf.isDelete
            subM.permissionValueSelf.isImport = findSub.permissionValueSelf.isImport
            subM.permissionValueSelf.isExport = findSub.permissionValueSelf.isExport
            subM.permissionValueSelf.isPrint = findSub.permissionValueSelf.isPrint
          }
          return subM;
        })
        m.subModules = subModule;
      }
      return m;
    })
    this.permissionDataById = tempModule;
    this.manipulateMainDataintoGrid(tempModule,"allmodule")
  }

  getCheckedModules(modules) {
    let templModules = modules.filter((m) => {
      let subModule = m.subModules.filter((d) => {
        return d.permissionValueSelf.isRead || d.permissionValueSelf.isCreate || d.permissionValueSelf.isUpdate || d.permissionValueSelf.isDelete || d.permissionValueSelf.isImport || d.permissionValueSelf.isExport || d.permissionValueSelf.isPrint 
      })
       if (subModule.length > 0){
        return true;
      }
      else {
        return m.permissionValueSelf.isRead || m.permissionValueSelf.isCreate || m.permissionValueSelf.isUpdate || m.permissionValueSelf.isDelete || m.permissionValueSelf.isExport ||  m.permissionValueSelf.isImport ||  m.permissionValueSelf.isPrint
      }
    })
    return templModules;
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

