import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { rejects } from 'assert';
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from 'src/Util/ApiHandling';
import { NotificationService } from "@progress/kendo-angular-notification";
import { AssetClassification } from 'src/app/models/asset.model';
import { assetClassificationValidation } from 'src/validator/assetClassification';
import { setCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
@Component({
  selector: 'app-asset-classification',
  templateUrl: './asset-classification.component.html',
  styleUrls: ['./asset-classification.component.scss']
})
export class AssetClassificationComponent {
  model: any = {};
  public opened = false;
  public removeDialogOpen = false;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public assetId;
  public removeAss_Id_Data;
  public statusObj = {};
  public permission:Permission=new Permission();
  assetClassificationForm: AssetClassification = new AssetClassification();
 
  error = assetClassificationValidation(this.assetClassificationForm, "init")
  public column_asset = [
    { field: "code", title: "Asset Classification Code", isFilter: true },
    { field: "description", title: "Asset Classification Description", isFilter: true },
    { field: "status", title: "Status", isFilter: true },
    { field: "remarks", title: "Remarks", isFilter: true },
  ]
  public gridData=[];
  submitted = false;
  public statusDropDown;
  public tempDropDown: Array<{ name: string; id: number, description: string }> = [
    { name: "Active", id: 1, description: "active" },
    { name: "InActive", id: 2, description: "inactive" },
  ];

  constructor(
              private _notif:NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => { this. editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.editColumn(e) })
    
  }


  editColumn(data) {
    if (data.action === "edit") {
      this. assetClassificationForm= data.dataItem;
      this.error = assetClassificationValidation(this.assetClassificationForm, "init")
      this.openRoleDailog("update");
    }
    else if (data.action === "remove") {
      this.assetClassificationForm = data.dataItem;
      this.removeDialogOpen = true;
    }
  }

  ngOnInit() {
   
    if(UserModuleObj.asset){
      this.permission=UserModuleObj.asset;
    }
    this. getAssetStatus().then((result)=>{
      this.getAssetsData();
    })
 }
 
 onReset() {
    this.submitted = false;
    this.openRoleDailog("new")
    // this.assetClassificationForm =new AssetClassification()
    // this.error =assetClassificationValidation(this.assetClassificationForm, "init")
 
  }

  onDialogClose(type) {
    if (type = "asset") {
      this.submitted = false;
        this.opened = false;

    }
    if (type = "remove") {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
  }

 openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.assetClassificationForm =new AssetClassification()
      this.error =assetClassificationValidation(this.assetClassificationForm, "init")
      let value=  this.statusDropDown.filter((e)=>{
       return  e.name==="Active"
      })
      this.assetClassificationForm.assetStatusId=value[0].id;
      this.dailogTitle = "Add Asset Classification"
    }
    if (type == "update") {
      this.dailogTitle = "Edit Asset Classification"
    }
    this.opened = true;
  }


  getAssetStatus() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetStatuses", true, true)
        .then((result: any) => {

          this.statusDropDown = result;
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }


  submitData() {
    this.submitted = true;
    this.error = assetClassificationValidation(this.assetClassificationForm, "")
   
    if (!this.error.description) {
        
        this.assetSubmitData();
    }
 
  }

  assetSubmitData() {
    if(this.type=="new"){
      this.assetClassificationForm.code =null
     const params = {
      ...this.assetClassificationForm,
     id:0
    }
    HttpClient.fetchData("/api/Assets/AddAssetClassification", "post", params, true,true)
      .then((result: any) => {
        if(result.isValid==true){
          HttpClient.insertAduitLog("Asset Classification","Add Asset Classification","Add Asset lassification").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
        this.getAssetsData()
        // this.submitted = false;
        // const statusValue = this.statusDropDown.filter(x => {
        //   return x.id == params.assetStatusId;
        // })
        
        //  let obj = {
        //   ...this.assetClassificationForm,
        //   id:result.id,
        //   status: statusValue[0].name
        // }
        //  this.gridData.unshift(obj)
        // this.gridData = JSON.parse(JSON.stringify(this.gridData));
        this.opened = false;
      }
      else{
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
     });
    }
    if(this.type=="update"){
      const params = {
        ...this.assetClassificationForm,
      };

     
      
      HttpClient.fetchData("/api/Assets/UpdateAssetClassification", "post", params, true,true)
        .then((result: any) => {
          if(result.isValid==true){
            HttpClient.insertAduitLog("Asset Classification","Update Asset Classification","Update Asset lassification").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            this.getAssetsData()
        //   const statusValue = this.statusDropDown.filter(x => {
        //     return x.id == params.assetStatusId;
        //   })
        //   let obj = {
        //     ...this.assetClassificationForm,
        //     status: statusValue[0].name
        //   }
        //   this.gridData = this.gridData.map((d) => {
        //     if (d.id == this.assetClassificationForm.id) {
        //       d = obj;
        //     }
        //     return d;
        //   })
        //  this.gridData = JSON.parse(JSON.stringify(this.gridData));
        
          this.opened = false;
        }
        else{
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
        })
    }
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  getAssetsData() {
    HttpClient.get("/api/Assets/GetAssetClassifications", true,true)
      .then((result: any) => {
        let data = shiftLastDataInto1st(result)
        this.bindStatusObj(this.statusDropDown);
        this.gridData = data.map((d) => {
          d["status"] = this.statusObj[d.assetStatusId];
          d["deleteKey"]= (this.permission.isDelete && d.status.toLowerCase()=="inactive")?true:false;
          return d;
        })
      })
  }

removeAssetClassification() {
    const params = {
    ...this.assetClassificationForm
    };
    HttpClient.fetchData("/api/Assets/DeleteAssetClassificationById", "post", params, true,true)
      .then((result:any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Asset Classification","Delete Asset Classification","Delete Asset lassification").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
        this.gridData = this.gridData.filter(x => {
          return x.id != this.assetClassificationForm.id;
        })
        this.gridData = JSON.parse(JSON.stringify(this.gridData));
        this.removeDialogOpen = false;
      }
      else{
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
      }).catch((error) => {
      });
  }

 
}