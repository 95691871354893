<kendo-grid
id="role-permission"
[data]="gridData"
[height]="260"
[selectable]="true"
[rowSelected]="isRowSelected"
[rowClass]="rowCallback"
(dataStateChange)="dataStateChange($event)"
>
<kendo-grid-column
  *ngFor="let col of column;let i =index;"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
   <ng-template   kendoGridCellTemplate let-dataItem>
       <div *ngIf="i==0" style="display: flex;justify-content: center;flex-direction: column;text-align: center;">{{dataItem[col.field]}}</div>
    
      <div *ngIf="i!=0 && dataItem[col.field]" style="display: flex;justify-content: center;flex-direction: column;">
           <span style="text-align: center;" ><b>{{dataItem[col.field]}}</b></span>
           <span style="text-align: center;">
           <input  type="checkbox" [checked]="dataItem[col.field+'_status']" [(ngModel)]="dataItem[col.field+'_status']" (change)="dataStateChange($event)" />
          </span>
           <!-- <span *ngIf="dataItem.parentId>0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{dataItem[col.field]}}</span> -->
      </div>
  </ng-template>
</kendo-grid-column>
<!-- <kendo-grid-command-column title="command" width="100">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand     icon="pencil" style="color: blue;background: transparent;border:0px;" ></button>
      <button kendoGridRemoveCommand  icon="delete" style="color: red;background: transparent;border: 0px;"></button>
  </ng-template>
</kendo-grid-command-column> -->
<!-- <kendo-grid-column field="ProductName" title="Product Name">
</kendo-grid-column>
<kendo-grid-column
  field="FirstOrderedOn"
  title="First Ordered On"
  width="240"
  filter="date"
  format="{0:d}"
>
</kendo-grid-column>
<kendo-grid-column
  field="UnitPrice"
  title="Unit Price"
  width="180"
  filter="numeric"
  format="{0:c}"
>
</kendo-grid-column> -->
<!-- <kendo-grid-column field="Discontinued" width="120" filter="boolean">
  <ng-template kendoGridCellTemplate let-dataItem>
    <input type="checkbox" [checked]="dataItem.Discontinued" disabled />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>