
<div class="card m-3"   >
    <div class="card-header" >
        <h5> Stock Take Activity </h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')"  *ngIf="permission && permission.isCreate" class="header-right-btn">
               Add Stock Take Activity
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"   >
       
        <app-table-grid
        [data]=" gridData "
        [column]="column2"
        [size]="10"
        [editFlag] ="false"
        [renewFlag]="true"
        [deleteFlag]="permission && permission.isDelete"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Stock Adjustment ID </label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartActivityForm.STOCKADJUSTMENTID" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Stock No. <span class="required">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [data]="stockDropDown"    [textField]="'stockNo'"
                [valueField]="'id'"
                [valuePrimitive]="true" class="form-control"  [(ngModel)]="sparePartActivityForm.STOCKNO"  (valueChange)="stockValueChanged($event)" class="form-control">
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.STOCKNO">*Required</kendo-formerror>
            </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Date</label> 
                <kendo-datetimepicker [format]="format" [(value)]="currentDate"> </kendo-datetimepicker>
                </div>
            <div class="form-group col-5">
                <label>Part No.</label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartActivityForm.PARTNO" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Part Description</label>
                <input kendoTextBox class="form-control"  [(ngModel)] ="partDesc" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Spare Part Category</label>
                <kendo-dropdownlist  class="form-control" [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"   [(ngModel)]="stockCatId" [data]=" stockCategoryDropDow" disabled>
                </kendo-dropdownlist>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Location <span class="required">*</span> </label>
                <div style="width: 100%;">
                <kendo-dropdownlist [data]="LocDropDown"   [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true" class="form-control"  [(ngModel)]="sparePartActivityForm.LOCATIONID" class="form-control">
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.LOCATIONID">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Quantity <span class="required">*</span> </label>
                <div style="width: 100%;">
                <input type="number" [(ngModel)]="quantity " [min]="0" kendoTextBox class="form-control"  />
                <kendo-formerror *ngIf="quantityErr">*Required</kendo-formerror>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Physical Quantity <span class="required">*</span></label>
                <div style="width: 100%;">
                <input type="number"  [min]="0" kendoTextBox class="form-control" [(ngModel)]="sparePartActivityForm.PHYSICALQUANTITY" (input)="onSearchChange($event.target.value)"/>
                <kendo-formerror *ngIf="error.PHYSICALQUANTITY">*Required</kendo-formerror>
            </div>
        </div>
            <div class="form-group col-5">
                <label>Variance</label>
                <input kendoTextBox class="form-control"  [(ngModel)]="sparePartActivityForm.VARIANCE" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Adjusted Quantity</label>
                <input type="number"  [min]="0" kendoTextBox class="form-control" [(ngModel)]="sparePartActivityForm.ADJUSTEDQUANTITY" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Remark</label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartActivityForm.REMARK" />
            </div>
        </div>

          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
      <button kendoButton primary="true" (click)="onReset()">
        Reset
       </button>
      <button kendoButton primary="true" *ngIf="type=='new'" (click)=" submitSpTakeActivityFrom()">
       Save
      </button>
      <button kendoButton primary="true" *ngIf="type=='update'" (click)=" submitSpTakeActivityFrom()">
        Update
       </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="  removeSpareTake()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>