import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class TicketConsequance{
    ID: number
    NAME:string
    MODIFIEDBY: number
    MODIFIEDDAT: Date
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string;
    CREATEDBY: number
    CREATEDDATE: Date
}
export class TicketConsequanceObj{
    alldata:Array<TicketConsequance>=[]
    filterData:Array<TicketConsequance>=[]
    selectedObj:TicketConsequance=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:TicketConsequance)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.TicketConsequenceType)
            .then((result:Array<TicketConsequance>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}