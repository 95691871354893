import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class TicketStatus{
    ID: number
    NAME:string
    COLORCODE:string
    ISACTIVE:boolean
    CREATEDON: Date
}
export class TicketStatusObj{
    alldata:Array<TicketStatus>=[]
    filterData:Array<TicketStatus>=[]
    selectedObj:TicketStatus=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:TicketStatus)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.ticketstatus)
            .then((result:Array<TicketStatus>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}