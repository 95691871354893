import { Component, Input, OnInit } from "@angular/core";

import HttpClient from "src/Util/ApiHandling";
import { getCookie } from "src/Util/Util";
import { userid } from "src/Util/const";
import { NotificationService } from "@progress/kendo-angular-notification";
import { eventListner, EVENT_NAME } from "src/event";
import { FacilityType } from "src/app/models/facility.model";
import { FacilityValidation } from "src/validator/facility";
import { environment } from "src/environments/environment";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"

@Component({
  selector: "app-facility-registeration",
  templateUrl: "./facility-registeration.component.html",
  styleUrls: ["./facility-registeration.component.scss"],
})
export class FacilityRegisterationComponent implements OnInit {
  public value: Date = new Date();
  public value2: Date = new Date();
  public moduleId=-1;
  public range = { start: null, end: null };
  public allStateData = [];
  public opened = false;
  public removeDialogOpen = false;
  public permission:Permission=new Permission();
  public type = "";
  public dailogTitle = "";
  submitted = false;
  public selectedServiceFlag = [];
  public stateObj = {};
  public countryObj = {};
  public service_obj = {};
  public emailErr = false;
  public emailValidation = true;
  public facilityPerson={};
  facilityForm: FacilityType = new FacilityType();
  public error = FacilityValidation(this.facilityForm, "init");
  public skillDropDown = [];
  public stateDropDown = [];
  public countryDropDown = [];
  public facilitySkills = [];
  public facilityParentId;
  public employeId=parseInt(getCookie("userId")||"1")
  public monthDayList = [
    { full: "January", short: "Jan", day: "Sun" },
    { full: "February", short: "Feb", day: "Mon" },
    { full: "March", short: "Mar", day: "Tue" },
    { full: "April", short: "Apr", day: "Wed" },
    { full: "May", short: "May", day: "Thr" },
    { full: "June", short: "Jun", day: "Fri" },
    { full: "July", short: "Jul", day: "Sat" },
    { full: "Augest", short: "Aug", day: "Sun" },
    { full: "September", short: "Sep", day: "" },
    { full: "October", short: "Oct", day: "" },
    { full: "November", short: "Nov", day: "" },
    { full: "December", short: "Dec", day: "" },
  ];
  public column = [
    { field: "name", title: "Facility Name", isFilter: true, width: 150 },
    {
      field: "facilityCode",
      title: "Facility Code",
      isFilter: true,
      width: 140,
    },
    // { field: "service", title: "Service Name", isFilter: true, width: 140 },
    { field: "address1", title: "Address1", isFilter: true, width: 140 },
    { field: "address2", title: "Address2", isFilter: true, width: 140 },
    { field: "country", title: "Country", isFilter: true, width: 130 },
    { field: "state", title: "State", isFilter: true, width: 100 },
    { field: "postCode", title: "Post Code", isFilter: true, width: 130 },
    { field: "active_From", title: "Active From", isFilter: true, width: 140 },
    { field: "active_To", title: "Active To", isFilter: true, width: 140 },
    {
      field: "nameModifiedBy",
      title: "Last Updated By",
      isFilter: true,
      width: 140,
    },
    {
      field: "last_update_on",
      title: "Last Updated On",
      isFilter: true,
      width: 140,
    },
  ];

  public gridData = [];

  public service = [];
 
  public contectColumn=[
    { 
      field: "name", 
      title: "Contact Person",
      errorField:"name_error",
      validation:(val)=>{
          if(val && val.length>=3)
            return ""
          else 
            return "*Required"
      }, 
    isFilter: false, 
    width: 140 
  },
    { 
        field: "designation", 
        title: "Designation", 
        isFilter: false, 
        width: 140,
        errorField:"designation_error",
        validation:(val)=>{
          if(val && val.length>=3)
            return ""
          else 
            return "*Required"
         }, 
      },
    { 
      field: "contactNo", 
      title: "Contact No", 
      isFilter: false, 
      width: 140,
      errorField:"contactNo_error",
     
        validation:(val)=>{
          const re = /^(\+\d{1,3}[- ]?)?\d{10,12}$/;
          if(val && re.test(val))
            return ""
          else 
            return "*Inavlid Number"
         }, 
    },
    { 
      field: "email", 
      title: "Email", 
      isFilter: false, 
      width: 140,
      errorField:"email_error",
      validation:(val)=>{
       const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
       if(val && re.test(String(val).toLowerCase()))
            return ""
        else 
          return "*Invalid Email"
       },
}

  ]
  
  public attachmentOpened = false;
  public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
  public attechmentDoc=new AttechmentObj()
  public attechForm:Attechment=new Attechment();
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public attechId:number=-1;
  // public valuelist: any = ['Mechanical','Electrical','Housekeeping','Building System'];

  // asset_classi = ['Mechanical','Electrical','Housekeeping','Building System'];
public contectGrid=[]
  public actionsLayout = "normal";
  country = [
    { id: 1, name: "India" },
    { id: 2, name: "Malaysia" },
  ];
  state = [
    { id: 1, name: "Maharashtra" },
    { id: 2, name: "UP" },
  ];
  public column2 = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true }
  ]
  public importopened=false;

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e); 
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.attachment_column, (e) => { this.attachementColumn(e) })
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
      this.deleteAttechment(e);
   })
   eventListner(EVENT_NAME.download_attechment,(e)=>{
     this.downloadAttechment(e)
   })
  }
  addAttechment(){
    if(!this.attechForm.URL){
      this.attechError.URL=true
    }
    else{
      this.attechError.URL=false
    }
    if(!this.attechForm.FILENAME){
      this.attechError.FILENAME=true
    }
    else{
      this.attechError.FILENAME=false
    }
    if(!this.attechError.URL && !this.attechError.FILENAME ){
      this.attechForm.FILECONTENTS=this.attechForm.URL;
      this.attechForm.MODULEHIERARCHYID=this.moduleId;
      this.attechForm.saveData()
      .then((result:any)=>{
        this.attechForm.ID=result.id;
        HttpClient.insertAduitLog("Facility","Add Facility Registration","Add Facility Registration in Facility").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this.attechmentDoc.getData(this.attechId,this.moduleId)
        .then((result)=>{
          this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
          this.attechForm=new Attechment();
        })
        // this.attechmentDoc.alldata.push(this.attechForm);
        // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        // this.attechForm=new Attechment();
      })
      .catch((err)=>{
         console.log(err)
      })
  }
  }
  attachementColumn(data:any){
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.REFERENCEID=data.id;
  
  
  //this.attechError={URL:false,FILENAME:false}
  
  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  this.attechId=data.id;
  this.attechmentDoc=new AttechmentObj();
  this.attechmentDoc.getData(data.id,this.moduleId)
  .then((result:any)=>{
    this.attachmentOpened = true;
    this.dailogTitle = "Add Attachment"
  //this.attachmentOpened = true;
  //this.dailogTitle = "Add Attachment"
  })
  .catch((err)=>{
   this.attechmentDoc.alldata=[]
  })
  }
  // attachementColumn(data:any) {
  //   console.log(data)
  //   this.attechForm={ 
  //         ID:0,
  //         REFERENCEID:data.id,
  //         FILETYPE:"pdf",
  //         FILENAME:"",
  //         URL:"",
  //         EMPLOYEEGLOBALID:this.employeId,
  //         CREATEDON:new Date(),
  //         MODULEHIERARCHYID:this.moduleId,
  //       }
  //   // this.attechError={id:false,type:false,name:false,url:false}

  //   this.attechForm.EMPLOYEEGLOBALID=data.id;
  //   this.attechId=data.id;
  //   this.getAttechment(data.id)
  //   .then((result:any)=>{
  //     this.attechmentDoc=[...result];
  //     this.attachmentOpened = true;
  //     this.dailogTitle = "Add Attachment"
  //   })
  //   .catch((err)=>{
  //      this.attechmentDoc=[]
  //   })
  //   // HttpClient.getAttechment("facility",this.attechId.toString())
  //   // .then((result:any)=>{
  //   //   this.attechmentDoc=result;
  //     // this.attachmentOpened = true;
  //     // this.dailogTitle = "Add Attachment"
  //   // })
  //   // .catch((err)=>{
  //   //    this.attechmentDoc=[]
  //   // })

  // }
  deleteAttechment(e){
    this.deleteLookupAttechment(e.dataItem)
    .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
    })
    .catch((err)=>{
     
    })
   // console.log(e)
  }
  downloadAttechment(data){

    window.open(environment.backendUrl+data.URL)
  }
  ngOnInit() {
    if(UserModuleObj.facility){
      this.permission=UserModuleObj.facility;
    }
    this.getStateData("dbo", "state").then((result) => {
      Promise.all([
        this.getcountryData("dbo", "country"),
        this.getskillData("dbo", "skill"),
        this.getModuleHierarchYdata("dbo","moduleHierarchy")
      ]).then((e) => {
        this.getFacility();
      });
    });
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.facilityForm = new FacilityType();
      this.selectedServiceFlag = [];
      this.contectGrid=[]
      this.error = FacilityValidation(this.facilityForm, "init");
      this.facilityForm.id = 0;
      this.dailogTitle = "Add Facility Registration";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Facility Registration";
    }
    this.opened = true;
  }
  onReset() {
    this.submitted = false;
   this.openRoleDailog("new")
  }

  editColumn(data) {
    if (data.action === "edit") {
      this.facilityForm = data.dataItem;
      this.contectGrid=this.facilityForm.facilityContactPersons || []
      this.error = FacilityValidation(this.facilityForm, "init");
      this.stateDropDown = this.allStateData.filter((e) => {
        return e.COUNTRYID == data.dataItem.countryId;
      });
      this.selectedServiceFlag =this.facilityForm.facilitySkills.map((d)=>{
        let skillId = d.skillId;
         return {
           id:skillId,
           name:this.service_obj[skillId]
         }
       });
      this.facilityForm.activeFrom = data.dataItem.activeFrom && new Date(data.dataItem.activeFrom);
      this.facilityForm.activeTo = data.dataItem.activeTo && new Date(data.dataItem.activeTo);
      this.facilityForm.modifiedDate = new Date()
      this.openRoleDailog("update");
    } else if (data.action === "remove") {
      this.facilityForm = data.dataItem;
      this.removeDialogOpen = true;
    }
  }

  onDialogClose(type) {
    if ((type = "new")) {
      this.opened = false;
    }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
    if (type == "attachment") {
      this.attachmentOpened = false
    }
  }

  bindStatusObj(result) {
    this.stateObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindCountryObj(result) {
    this.countryObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindStateObj(result) {
    this.stateObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  pading(n) {
    if (n > 9) return n;
    else return "0" + n;
  }

  dateFormating(da) {
    if(!da){
      return null;
    }
    let df = new Date(da);
    return `${this.pading(df.getDate())}-${
      this.monthDayList[df.getMonth()].short
    }-${df.getFullYear()}`;
  }

  getFacility() {
    HttpClient.get("/api/Facility/GetFacilities", true).then(
      (result: any) => {
       
        this.bindCountryObj(this.countryDropDown);
        if (result.length > 0) {
          this.gridData = result.map((d) => {
            d["state"] = this.stateObj[d.stateId];
            d["country"] = this.countryObj[d.countryId];
            d["last_update_on"] = this.dateFormating(d.modifiedDate);
            // d["service"] =
            //   d.facilitySkills &&
            //   d.facilitySkills
            //     .map((m) => {
            //       return this.service_obj[m.skillId];
            //     })
            //     .join(",");
            d["active_From"] = this.dateFormating(d.activeFrom);
            d["active_To"] = this.dateFormating(d.activeTo);
            return d;
          });
        }
        
      }
    );
  }

  getcountryData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true
      )
        .then((result: any) => {
          let countryData = [];
          countryData = JSON.parse(result.gridData);
          this.countryDropDown = countryData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStateData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,true)
        .then((result: any) => {
          let stateData = [];
          this.allStateData = JSON.parse(result.gridData);
          this.bindStateObj(this.allStateData);
          resolve(stateData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  valuechange(id) {
    let countryId = id;
    //this.getStateData("dbo","state",countryId)
    this.stateDropDown = this.allStateData.filter((e) => {
      return e.COUNTRYID == countryId;
    });
  }

  getskillData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true
      )
        .then((result: any) => {
          let skillData = [];
          skillData = JSON.parse(result.gridData);
          this.service = skillData.map((e) => {
            return { id: e.ID, name: e.NAME };
          });
            this.service = skillData.map((e)=>{
            this.service_obj[e.ID] = e.NAME;
            return { id: e.ID, name: e.NAME }
        });
         
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  onSubmit() {
    this.submitted = true;
    this.error = FacilityValidation(this.facilityForm, "");
    this.facilityForm.id= (this.type=="new")?0:this.facilityForm.id
    let newContectList=this.contectGrid.filter((d)=>d.action!=="new");
    this.facilityForm.activeFrom=this.convertLocalDateString(this.facilityForm.activeFrom);
    this.facilityForm.activeTo=this.convertLocalDateString(this.facilityForm.activeTo);
    this.facilityForm.facilityContactPersons= newContectList.map((d)=>{
         delete d["action"];
         delete d["index"];
         d["statusId"]=1;
         if(!d.createdBy){
           d["createdBy"]=1;
         }
         if(!d.modifiedBy){
          d["modifiedBy"]=1;
        }
        if(!d.createdDate){
          d["createdDate"]=new Date();
        }
        if(!d.modifiedDate){
          d["modifiedDate"]=new Date();
        }
         return d;
    })
    if (this.selectedServiceFlag.length) {
      
      let mainFlagObj = {};
      if (this.facilityForm.facilitySkills) {
        mainFlagObj = this.facilityForm.facilitySkills.reduce((acc, d) => {
          acc[d.skillId] = d;
          return acc;
        }, {});
      }
      this.facilityForm.facilitySkills = this.selectedServiceFlag.map((d) => {
        if (mainFlagObj[d.id]) {
          return mainFlagObj[d.id];
        } else {
          return {
            id: 0,
            facilityId:this.facilityForm.id,
            skillId: d.id,
          };
        }
      });      
    }
    if (
      !this.error.name &&
      !this.error.facilityCode &&
      !this.error.address1 &&
      !this.error.countryId &&
      !this.error.stateId &&
      !this.error.postCode &&
      !this.error.postCode &&
      !this.error.address2 &&
      !this.error.email
    ) {
      if (this.type == "new") {
        this.facilityForm.createdBy = parseInt(getCookie(userid));
        this.facilityForm.createdDate = new Date();
        this.facilityForm.modifiedBy = parseInt(getCookie(userid));
        this.facilityForm.modifiedDate = new Date();
        // this.facilityForm.activeFrom = new Date();
        // this.facilityForm.activeTo = new Date();
        this.facilityForm.nameCreatedBy = null;
        this.facilityForm.nameModifiedBy = null;
      }
      this.addFacilty();
    }
  }
 
  addFacilty() {
    const paramsObj = {
      ...this.facilityForm,
      blocks: [],
    };
    if (this.type == "new") {
      HttpClient.fetchData(
        "/api/Facility/AddFacility",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
      
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Facility","Add Facility Registration","Add Facility Registration in Facility").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this.opened = false;
          this.getFacility()
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }

    if (this.type == "update") {
   
      const params = {
        ...this.facilityForm,
        blocks: [],
      };
      HttpClient.fetchData(
        "/api/Facility/UpdateFacility",
        "post",
        params,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Facility","Update Facility Registration","Update Facility Registration in Facility").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          // const countryValue = this.countryDropDown.filter((x) => {
          //   return x.ID == params.countryId;
          // });
          // const stateValue = this.stateDropDown.filter((x) => {
          //   return x.ID == params.stateId;
          // });
          // let obj = {
          //   ...this.facilityForm,
          //   state: stateValue[0] && stateValue[0].NAME,
          //   country: countryValue[0].NAME,
          //   last_update_on: this.dateFormating(this.facilityForm.modifiedDate),
          //   active_From: this.dateFormating(this.facilityForm.activeFrom),
          //   active_To: this.dateFormating(this.facilityForm.activeTo),
          //   service:
          //     this.facilityForm.facilitySkills &&
          //     this.facilityForm.facilitySkills.map((d) => {
          //       return this.service_obj[d.skillId];
          //     }),
          // };
          // this.gridData = this.gridData.map((d) => {
          //   if (d.id == this.facilityForm.id) {
          //     d = obj;
          //   }
          //   return d;
          // });
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
          this.getFacility()

        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }
  }

  removeFacility() {
    const params = {
      loggedInUserId: 1,
      id: this.facilityForm.id,
      employeeGlobalId: 0,
      instance: "",
    };
    HttpClient.fetchData(
      "/api/Facility/DeleteFacilityById",
      "post",
      params,
      true,
      true
    )
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Facility","Delete Facility","Delete Facility in Facility").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridData = this.gridData.filter((x) => {
            return x.id != this.facilityForm.id;
          });
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((error) => {});
  }

  facilitySkillsData(data) {
    console.log(data);
  }
//   addAttechment(){
//     if(!this.attechForm.URL){
//       this.attechError.URL=true
//     }
//     if(!this.attechError.URL){
//       this.saveAttechment(this.attechForm)
//       .then((result:any)=>{
//         this.attechForm.ID=result.id;
//         this.attechmentDoc.push(this.attechForm);
//         this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
//         this.attechForm={ 
//           ID:0,
//           REFERENCEID:this.attechId,
//           FILETYPE:"pdf",
//           FILENAME:"",
//           URL:"",
//           EMPLOYEEGLOBALID:1,
//           CREATEDON:new Date(),
//           MODULEHIERARCHYID:this.moduleId,
//         }
//       })
//       .catch((err)=>{
//          console.log(err)
//       })
//   }
//  }
 
  saveAttechment(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"attachment",
      "userId":1,
      "newData":JSON.stringify(data)
    }
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/MasterData/SaveLookupData","post",obj,true,true)
      .then((response)=>{
        HttpClient.insertAduitLog("Facility","Save Facility Attachment","Save Facility Attachment in Facility").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
         resolve(response)
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
        // this.opened=false;
      })
      .catch((err)=>{
    
        reject(err)
      })
    })
  }

  deleteLookupAttechment(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"attachment",
      "userId":1,
      "oldData":JSON.stringify(data)
    }
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/MasterData/DeleteLookupData","post",obj,true,true)
      .then((response)=>{
        HttpClient.insertAduitLog("Facility","Delete Facility Attachment","Delete Facility Attachment in Facility").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
         resolve(response)
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
        // this.opened=false;
      })
      .catch((err)=>{
        console.log(err);
        reject(err)
      })
    })
    
  }

  getModuleHierarchYdata(schema, tablename){
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        
      ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
        let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
                return e.NAME ==="Facility"
          })
            this.facilityParentId = ModFacArr[0].PARENTID
            this.moduleId=ModFacArr[0].ID;
         // console.log(ModFacArr)
      });
    }

    getAttechment(RefId){
      return new Promise((resolve,reject)=>{
        HttpClient.get("/api/MasterData/GetLookupData?Schema=dbo&Table=attachment",true)
        .then((result:any)=>{
            let attechmentData=JSON.parse(result.gridData)
            let temp=attechmentData.filter((d)=>d.REFERENCEID==RefId && d.MODULEHIERARCHYID==this.moduleId);
            resolve(temp);
        })
        .catch((err)=>{
            reject(err);
        })
      })
    }
    downloadTemplate(){
      window.open("/assets/template/user.xls")
    }
    refreshData(e){
      if(e.status){
        this.getFacility()
      }
    }

    convertLocalDateString(d):any{
      if(!d)
         return null;
      function padding(n){
        if(n<10){
            return "0"+n 
        }
        return n;
      }
      let ndate=new Date(d);
      return `${ndate.getFullYear()}-${padding(ndate.getMonth()+1)}-${padding(ndate.getDate())}T${padding(ndate.getHours())}:${padding(ndate.getMinutes())}:${padding(ndate.getSeconds())}`
    }
}
