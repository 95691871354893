import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import HttpClient from 'src/Util/ApiHandling';
import { Model } from 'src/ApiModel/Model';
import { ModelApi } from 'src/ApiModel/const';
import { AreaLocation } from 'src/ApiModel/Model/AreaLocation';
import { Facility } from 'src/ApiModel/Model/Facility';
import { Level } from 'src/ApiModel/Model/BlockLevel';
import { Block } from 'src/ApiModel/Model/Block';
import { Area } from 'src/ApiModel/Model/LevelArea';
import { AssetModel } from 'src/ApiModel/Model/AssetMode';
import { getCookie, getQueryParams, setCookie } from 'src/Util/Util';
import { authorization, userid } from 'src/Util/const';
import { WorkOrder } from 'src/ApiModel/Model/WorkOrder';
import { Attechment } from 'src/ApiModel/Model/Attechment';


class Error{
  description=false;
  serviceType=false;
  attechment=false;
  constructor(){
    this.description=false;
    this.serviceType=false;
    this.attechment=false;
  }
}





@Component({
  selector: 'app-mobile-main',
  templateUrl: './mobile-main.component.html',
  styleUrls: ['./mobile-main.component.scss']
})
export class MobileMainComponent implements OnInit {

  constructor() { 

  }
  public error=new Error();
  public constData={
    type:{
      location:"location",
      asset:"asset"
    },
    screenType:{
      home:"home",
      detail:"detail",
      success:"success"
    },
    osName:{
      ios:"iOS",
      android:"Android"
    }
  }
  public header="";
  public type=getQueryParams("QRCodeType");
  public id=parseInt(getQueryParams("id"));
  public title="";
  public subtitle="";
  public screenType=this.constData.screenType.home;
  public workorderText=""
  public model=new Model();
  public workOrderNumber="kdk mdl mldm dl m"
  public window:any=window
  public card=[
    {
      title:"Tenant",
      text:"I am the person who occupied this building",
      logo:"/assets/tanent_icon.jpeg",
      status:true
    },
    {
      title:"Visitor",
      text:"I am an external visitor",
      logo:"/assets/visitor_icon.jpeg",
      status:false
    }
  ]
  public serviceData=[
    {
      id:1,
      name:"Ram"
    },
    {
      id:2,
      name:"Pooja"
    }
  ]
  public attechments=[];
  getOS() {
    var uA = navigator.userAgent || navigator.vendor || this.window.opera;
    if ((/iPad|iPhone|iPod/.test(uA) && !this.window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
  
    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) 
       if (new RegExp(os[i],'i').test(uA)) 
    return os[i];
  }
  ngOnInit(): void {
    console.log("os name==>",this.getOS())
    let argmentArray=[ModelApi.ServicesType];
    //let detailApi=this.type===this.constData.type.location?ModelApi.arealocation:ModelApi.Asset;
    if(this.type===this.constData.type.location){
      argmentArray.push(ModelApi.arealocation);
      argmentArray.push(ModelApi.facility);
      argmentArray.push(ModelApi.block);
      argmentArray.push(ModelApi.blocklevel);
      argmentArray.push(ModelApi.levelarea);
    }
    else{
      argmentArray.push(ModelApi.Asset);
    }
    this.loginUser()
    .then((result)=>{
          this.getModuleHierarchYdata("dbo","moduleHierarchy")
          this.model.getData(...argmentArray)
          .then((result)=>{
              if(this.type==this.constData.type.location){
                  let locationDetail:AreaLocation= this.model.location.objectData[this.id]
                  if(locationDetail){
                  //  this.title=locationDetail.NAME;
                    let facility:Facility=this.model.facility.objectData[locationDetail.FACILITYID];
                    let level:Level=this.model.level.objectData[locationDetail.BLOCKLEVELID];
                    let block:Block=this.model.block.objectData[locationDetail.BLOCKID];
                  let area:Area=this.model.area.objectData[locationDetail.LEVELAREAID];
                  this.subtitle=level.NAME+" , "+area.NAME+" , "+locationDetail.NAME;
                  this.title=facility.NAME;
                  }
              }
              else if(this.type==this.constData.type.asset){
                  let asset:AssetModel=this.model.asset.objectData[this.id];
                  this.title=asset.assetNumber;
                  this.subtitle=asset.assetCode
              }
          })
          .catch((err)=>{
    
          })
    })
    .catch((err)=>{

    })
  }
  
  openAppStore(type){
    window.open(environment[type])
  }
  getAttechment(){
    HttpClient.uploadMultipleFile(["*"],20,(data)=>{
      //console.log(data);
      // data.forEach(img => {
      //     let fi=img.indexOf(",");
      //     let fileC=img.substring(fi+1);
      //     let fileMeta=img.substring(0,fi);
      //     let spt=fileMeta.split(/[://;]/g);
      //     console.log(spt);
      // });
      this.attechments=[...this.attechments,...data]

    },true)
  }
  deleteAttechment(index){
    this.attechments=this.attechments.filter((d,i)=>i!=index)
  }
  loginUser(){
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/Api/users/authenticate","post",{
        "username": environment.external_user_id, 
        "password": environment.external_user_password
      })
      .then((result:any)=>{
        if(result.jwtToken){
          setCookie(authorization,result.jwtToken,1)
          setCookie(userid,result.userId,1)
          resolve(true);
        }
        else{
          reject(false)
          //this.errorMsg="Username or Password is Invalid"
        }
      })
      .catch((err)=>{
          reject(false)
      })
    })
  }padding(n){
    if(n<10)
      return "0"+n;
    return n;
  }
checkValidation(){
  this.error=new Error()
  let error=false;
  if(!this.workorderText){
   error=true;
   this.error.description=true;
  }
  if(!this.model.service.selectedObj){
    error=false
    this.error.serviceType=true;
  }
  if(this.attechments.length<=0){
    error=true;
    this.error.attechment=true;
  }
  return error;
}

  saveWorkOrder(){
       if(this.checkValidation()){
         return;
       }
       let userId:any=getCookie(userid);
       let d=new Date();
       let df=`${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}T${this.padding(d.getHours())}:${this.padding(d.getMinutes())}:${this.padding(d.getSeconds())}.${d.getMilliseconds()}Z`
       let obj1={
                  "id":0,
                  "userId":userId,
                  "ticketStatusId":1,
                  "ticketConsequenceId":7,
                  "description":this.workorderText,
                  "serviceTypeId":this.model.service?.selectedObj?.ID || -1,
                  "referenceId":this.id,
                  "referenceType":this.type,
                  "assignee":userId,
                  "bpmid":environment.external_user_bpm_id,
                  "createdDate":df,
                  "createdBy":userId,
                  "modifiedDate":df,
                  "modifiedBy":userId,
                  "defectPhoto":"",
                  "chargeable":1,
                  "acceptanceDate":df,
                  "TicketTypeID":3,
                  "RequestSourceID":3,
                  "ConvertToWorkOrder":false,
                  "workOrderNo":"1653225684893"
                }
      this.addWorkOrder(obj1)
      .then((result:any)=>{
        this.workOrderNumber="CRM/2022/00000"+result.id
        let df=new Date().getTime();
        let attechment:Array<Attechment>=this.attechments.map((d,i)=>{
          let a=new Attechment();
          let fi=d.indexOf(",");
          let fileC=d.substring(fi+1);
          let fileMeta=d.substring(0,fi);
          let spt=fileMeta.split(/[://;]/g)
          if(spt[1]==="image"){
            a.FILENAME=df.toString()+i
            a.FILETYPE=spt[2];
            a.FILECONTENTS=fileC;
            a.MODULEHIERARCHYID=this.moduleId;
            a.REFERENCEID=result.id;
            a.EMPLOYEEGLOBALID=userId;
          }
          return a;
        })

        this.screenType=this.constData.screenType.success;
        return  Promise.all(attechment.map((a)=>{
          return a.saveData();
        }))

      })
      .then((imgResult)=>{
          console.log(imgResult)
      })
      .catch((err)=>{
        console.log(err)
      })
      this.model.service.selectedObj
    
  }
  addWorkOrder(obj){
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/WorkOrder/AddWorkOrder","post",obj,true,true)
      .then((result:any)=>{
       if(result.isValid==true){
         resolve(result)
       }
       else{
        reject(null)
       }
      })
      .catch((err)=>{
        reject(null)
      //  console.log(err)
      })
    })
   
   }
   addAttechment(){

   }
   public moduleId=-1;
   getModuleHierarchYdata(schema, tablename){ 
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
              return e.NAME ==="Work Order"
        })
          this.moduleId=ModFacArr[0].ID;
       // console.log(ModFacArr)
    });
  }
  homeScreen(){
    this.screenType=this.constData.screenType.home;
    this.model.service.selectedObj=null;
    this.workorderText=""
    this.attechments=[];
    this.model.service.selectedObj=null;
  }
  detailScreen(){
    this.screenType=this.constData.screenType.home;
    this.model.service.selectedObj=null;
    this.workorderText=""
    this.attechments=[];
    let selectedUser=this.card.find((d)=>d.status);
    if(selectedUser.title==="Tenant"){
      this.openDeapLink(this.getOS());
      //this.openAppStore('play_store')
    }
    else{
     this.screenType=this.constData.screenType.detail;
    }

  }
  selectedUser(e,index){
    this.card=this.card.map((d,i)=>{
      if(i==index){
        d.status=true;
      }
      else{
        d.status=false;
      }
      return d;
    })
  }
  openDeapLink(osName){
    if(osName===this.constData.osName.ios){
      let link = environment.deaplink_request_workorder_ios.replace('{param1}','id='+this.id+'?'+this.type)
     this.window.open(link)
    }
    else{
      let link = environment.deaplink_request_workorder_android.replace('{param1}','id='+this.id+'?'+this.type)
     this.window.open(link)
    }
  }
}
