import { Component, OnInit } from '@angular/core';
import { Model } from 'src/ApiModel/Model';
import { ModelApi } from 'src/ApiModel/const';
import { AssetStandardization } from 'src/ApiModel/Model/AssetStanderdtion';
import { AssetType } from 'src/ApiModel/Model/AssetType';
import { AssetClassfication } from 'src/ApiModel/Model/AssetClassification';
import { Status } from 'src/ApiModel/Model/Status';
@Component({
  selector: 'app-asset-summery',
  templateUrl: './asset-summery.component.html',
  styleUrls: ['./asset-summery.component.scss']
})
export class AssetSummeryComponent implements OnInit {

  constructor() { }
  public model=new Model()
  public gridData=[]
  public assetClassification=[]
  public assetStatus=[]
  public column=[
    {field:"asset.assetCode",title:"Asset ID",isFilter:false ,width:200},
    {field:"assetCL.description",title:"Asset Classification",isFilter:true ,width:200},
    {field:"status.NAME",title:"Status", isFilter:true,width:200},
    {field:"asset.assetNumber",title:"Asset No.", isFilter:false ,width:200},
    {field:"asset.serialNo",title:"Serial No", isFilter:false ,width:200},
    {field:"assetType.CODE",title:"Asset Type Code", isFilter:false ,width:200},
    {field:"assetType.DESCRIPTION",title:"Asset Type Description", isFilter:false ,width:200},
  ]
  ngOnInit(): void {
    this.model.getData(ModelApi.Asset,ModelApi.assetClassfication,ModelApi.assetStardantion,ModelApi.AssetType,ModelApi.AssetType,ModelApi.Status)
    .then((result)=>{
        this.manuculateAssetData(this.model.asset.alldata);
     //  console.log(this.model)
    })
    .catch((err)=>{

    })
  }
  manuculateAssetData(assetData){
   this.gridData= assetData.map((d)=>{
          let assetST:AssetStandardization=this.model.assetStardantion.objectData[d.assetStandardizationId]
          let assetType:AssetType=this.model.assetType.objectData[assetST.assetTypeId];
          let assetCl:AssetClassfication=this.model.assetClassfication.objectData[assetType.ASSETCLASSIFICATIONID]
          let assetStatus:Status=this.model.status.objectData[assetST.assetStatusId];
          let obj={
            asset:d,
            assetST:assetST,
            assetCL:assetCl,
            assetType:assetType,
            status:assetStatus
          }
          return obj;
    })
   this.graphCalculate(this.gridData);
 // console.log(this.gridData,this.model.assetClassfication);
  }
  graphCalculate(data:any){
    let ac={};
    let ass={}
    data.forEach((d)=>{
           if(ac[d.assetCL.id]){
            ac[d.assetCL.id]=ac[d.assetCL.id]+1;
           }
           else{
            ac[d.assetCL.id]=1;
           }
           if(ass[d.status.ID]){
            ass[d.status.ID]=ass[d.status.ID]+1;
           }
           else{
            ass[d.status.ID]=1;
           }
    })
    for(let c in this.model.assetClassfication.objectData){
            if(ac[c]){
                 this.assetClassification.push({label:this.model.assetClassfication.objectData[c].code,value:ac[c]})
            }
            else{
              this.assetClassification.push({label:this.model.assetClassfication.objectData[c].code,value:0})
            }
    }
    let asCount=this.model.asset.alldata.length || 0;
    let fp=100/asCount;
    for(let c in this.model.status.objectData){
      if(ass[c]){
        this.assetStatus.push({label:this.model.status.objectData[c].NAME,value:(ass[c]*fp)/100})
      }
      else{
        this.assetStatus.push({label:this.model.status.objectData[c].NAME,value:0}) 
      }
    }
    this.assetStatus=[...this.assetStatus]
    this.assetClassification=this.assetClassification.filter((d)=>d.value)
    // this.assetClassification=[...this.assetClassification]
    // console.log(this.assetStatus,this.assetClassification)
  }
  filterChangedHandler(e){

  }
}
