<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
[selectable]="selectableSettings"
(selectionChange)="onSelect($event)"
(remove)="removeHandler($event)"
>
<!-- <kendo-grid-checkbox-column
       width="40"
        showSelectAll="true"
        [columnMenu]="true"
       
     > -->
     <!-- <kendo-grid-checkbox-column title="" width="40">
        <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input  type="checkbox" [kendoGridSelectionCheckbox]="idx" />
       </ng-template>
   </kendo-grid-checkbox-column> -->
    
    <!-- </kendo-grid-checkbox-column> -->
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>

<ng-template   kendoGridCellTemplate let-dataItem>
 
   <!-- <div *ngIf="col.type=='text'">
       <span *ngIf="dataItem.parentId==0"><b>{{dataItem[col.field]}}</b></span>
       <span *ngIf="dataItem.parentId>0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{dataItem[col.field]}}</span>
  </div> -->
  <div *ngIf="col.type=='text'" >
    {{dataItem[col.field]}}
  </div>
  <div *ngIf="col.type=='date'" >
    <kendo-datepicker format="dd/MMM/yyyy"   (blur)="onSelect($event)" [(value)]="dataItem[col.field]"    [min]="min" [max]="max"></kendo-datepicker>
  </div>

</ng-template>
</kendo-grid-column>
<kendo-grid-command-column title="Reset" width="100">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
  <button kendoGridRemoveCommand icon="reset" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
  </ng-template>
</kendo-grid-command-column>

<!-- <kendo-grid-command-column title="" width="200">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
    <kendo-datepicker [(value)]="value" [min]="min" [max]="max"></kendo-datepicker>
  </ng-template>
  
</kendo-grid-command-column> -->
</kendo-grid>