import { Component, OnInit } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import { LocationType } from "src/app/models/location.model";
import { GenerateQrCodeForLocation } from "src/app/models/qrcode-generate.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { LocationValidation } from "src/validator/location";
import { environment } from 'src/environments/environment';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {addBlukLocation} from "src/ApiModel/ImportApi/location"
@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.scss"],
})
export class LocationComponent implements OnInit {
  public opened = false;
  public removeDialogOpen = false;
  public permission:Permission=new Permission();
  public qrCodeDialogOpen = false;
  public locationQrValue;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public blockCodeDropDown = [];
  public levelCodeDropDown = [];
  public areaCodeDropDown = [];
  public facilityDropDown =[];
  public statusDropDown = [];
  public allBlocksValue = [];
  public allLevelsValue = [];
  public allAreasValue = [];
  public loationIncharge =[];
  public companyRe =[]
  public statusObj = {};
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public companyRObj = {}
  public facilityObj ={};
  public imageUrl = environment.backendUrl+`/`;
  public qrCodeGenUrl;
  public assetLocationHierarchyId
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationInc: Array<{ name: string; id: number }> | any;
  public locationInChargeObj={}
  public companyRepresentiveObj={}
  public filterdCompanyRe: Array<{ name: string; id: number }> | any;
  public qrCodeLocationForm:  GenerateQrCodeForLocation = new GenerateQrCodeForLocation()
  public isunique=false;
  public uniqueList = [];
  public blockName ;
  public levelName;
  public areaName;
  public statusValue = true;
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  submitted = false;
  public LocationForm: LocationType = new LocationType();
  error = LocationValidation(this.LocationForm, "init");
  public column = [
    { field: "FACILITY", title: "Facility", isFilter: true,width:160 },
    { field: "BLOCKCODE", title: "Block Code", isFilter: true,width:140 },
    { field: "LEVELCODE", title: "Level Code", isFilter: true,width:140  },
    { field: "AREACODE", title: "Area Code", isFilter: true,width:140 },
    { field: "NAME", title: "Location Name", isFilter: true ,width:160},
    { field: "CODE", title: "Location Code", isFilter: true ,width:160},
    { field: "STATUS", title: "Status", isFilter: true ,width:140},
    { field: "START_SERVICE_DATE", title: "Active From Date", isFilter: true,width:140 },
    { field: "STOP_SERVICE_DATE", title: "Active To Date", isFilter: true,width:140 },
    { field: "AUTHORIZEDPERSON", title: "Authorized Person", isFilter: true , width:160},
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true , width:160},
    { field: "last_update_on", title: "Last Updated On", isFilter: true , width:160},
  ];
 
  public gridData = [];
  public importopened=false;
  public qrcodePrintData=[];
  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.qrCode_attachment, (e) => { this.qrCodeGeneration(e) })
  }
  ngOnInit(): void {
    if(UserModuleObj.location)
    this.permission=UserModuleObj.location;
    Promise.all([
      this.getfacility(),
      this. getAllUser(),
      this.getStatus("dbo", "status"),
    ]).then((e) => {
      this.getLocationData("dbo", "arealocation");
      this.getModuleHierarchYdata("dbo","moduleHierarchy")
    });
    
    // new Date(dateStr).toISOString()
}

  onReset() {
    this.submitted = false;
    this. openLocationDailog("new")
    this. blockName = "" ;
    this.levelName = "";
    this.areaName = "";
  }

  onDialogClose(type) {
    if ((type = "new")) {
      this.LocationForm = new LocationType();
      this.blockName ="";
      this.levelName = "";
      this.areaName = "";
      this.opened = false;
   }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
    if(type="qrcode"){
      this.qrCodeDialogOpen = false;
    }
  }

  openLocationDailog(type) {
    this.type = type;
    if (type == "new") {
      this.LocationForm = new LocationType();
      this.error = LocationValidation(this.LocationForm, "init");
      this. filterdBlockData =[];
      this.filterdLevelData =[];
      this.filterdAreaData =[];
      this.blockName ="";
      this.levelName = "";
      this.areaName = "";
      this.LocationForm.ID = 0;
      this.LocationForm.STATUSID=1;
      this.dailogTitle = "Add Location";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Location";
    }
    this.opened = true;
  }

  editColumn(data) {
    if (data.action === "edit") {
      this.error = LocationValidation(this.LocationForm, "init");
 
      this.LocationForm.BLOCKID = data.dataItem.BLOCKID
      this.LocationForm.BLOCKLEVELID = data.dataItem.BLOCKLEVELID
      this.LocationForm.CODE = data.dataItem.CODE 
      this.LocationForm.COMPANYREPRESENTATIVE = data.dataItem.COMPANYREPRESENTATIVE
      this.LocationForm.CREATEDBY = data.dataItem.CREATEDBY
      this.LocationForm.CREATEDDATE = data.dataItem.CREATEDDATE 
      this.LocationForm.FACILITYID = data.dataItem.FACILITYID
      this.LocationForm.ID = data.dataItem.ID
      this.LocationForm.LEVELAREAID = data.dataItem.LEVELAREAID
      this.LocationForm.LOCATIONINCHARGE = data.dataItem.LOCATIONINCHARGE
      this.LocationForm.MODIFIEDBY = data.dataItem.MODIFIEDBY
      this.LocationForm.MODIFIEDDATE = new Date()
      this.LocationForm.NAME = data.dataItem.NAME
      this.LocationForm.SHORTNAME = data.dataItem.SHORTNAME
      this.LocationForm.STARTSERVICEDATE = new Date(data.dataItem.STARTSERVICEDATE)
        let stopSeriviceDate = data.dataItem.STOPSERVICEDATE;
        if(stopSeriviceDate==null){
          this.LocationForm.STOPSERVICEDATE = data.dataItem.STOPSERVICEDATE
        }
        else{
          this.LocationForm.STOPSERVICEDATE = new Date(data.dataItem.STOPSERVICEDATE)
        }
        this.LocationForm.STATUSID = parseInt(data.dataItem.STATUSID);
        if (data.dataItem.BLOCKLEVELID) {
        let selectLevelValue = this.levelCodeDropDown.filter((e) => {
          return e.id == data.dataItem.BLOCKLEVELID;
        });
        if (selectLevelValue) {
          this.levelName =  selectLevelValue[0] && selectLevelValue[0].name;
        }
      }
      if (data.dataItem.LEVELAREAID) {
        let selectareaValue = this.areaCodeDropDown.filter((e) => {
          return e.id == data.dataItem.LEVELAREAID;
        });
        if (selectareaValue) {
          this.areaName =  selectareaValue[0] && selectareaValue[0].name;
        }
      }
      if (data.dataItem.BLOCKID) {
        let selectBolckValue = this.blockCodeDropDown.filter((e) => {
          return e.id == data.dataItem.BLOCKID;
        });
        if (selectBolckValue) {
          this.blockName = selectBolckValue[0] && selectBolckValue[0].name;
        }
      }
       this.openLocationDailog("update");
    } else if (data.action === "remove") {
      this.LocationForm = data.dataItem;
      this.LocationForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.removeDialogOpen = true;
    }
  }

  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
          if(result.length>0){
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue.filter((e)=>{
              return e.statusId==1;
            });
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
         
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue.filter((e)=>{
            return e.statusId==1;
          });
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }
          this.areaCodeDropDown = this.allAreasValue.filter((e)=>{
            return e.statusId==1;
          });
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  facilityValueChange(facilityId) {
    let blockData  =  this.blockCodeDropDown.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = blockData.slice();
  }

  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    let levelData =this.levelCodeDropDown.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = levelData.slice();
  }

  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
    let areaData =  this.areaCodeDropDown.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData =areaData.slice();
  }

  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
  }

  handleBlockFilter(value) {
    this.filterdBlockData = this.blockCodeDropDown.filter(
      (s) => s.code.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleLevelFilter(value) {
    this.filterdLevelData = this.levelCodeDropDown.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  handleAreaFilter(value) {
    this.filterdAreaData = this.areaCodeDropDown.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  
  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindCompanyRep(result){
    this.companyRObj = result.reduce((acc,d)=>{
      acc[d.id] = d.name;
      return acc;
    },{})
  }
  getLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
  
      let LocationData = [];
      LocationData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindStatusObj(this.statusDropDown);
      this.bindBlockObj(this.filterdBlockData);
      this.bindLevelObj(this.filterdLevelData);
      this.bindFacilityObj(this.facilityDropDown);
      this.bindAreaObj(this.filterdAreaData);
      this.bindCompanyRep(this.filterdCompanyRe);
      this.gridData = LocationData.map((d,index) => {
        d["SNo"] = index+1
        d["LOCATIONCODE"] = d.CODE;
        d["LOCATIONNAME"] = d.NAME;
        d["STATUS"] = this.statusObj[d.STATUSID];
        d["BLOCKCODE"] = this.blockObj[d.BLOCKID];
        d["LEVELCODE"] = this.levelObj[d.BLOCKLEVELID];
        d["FACILITY"] = this.facilityObj[d.FACILITYID];
        d["AREACODE"] =this.areaObj[d.LEVELAREAID];
        d["AUTHORIZEDPERSON"] = this.companyRObj[d.COMPANYREPRESENTATIVE];
        d["START_SERVICE_DATE"] = this.dateFormating(d.STARTSERVICEDATE);
        if(d.STOPSERVICEDATE==null){
          d["STOP_SERVICE_DATE"] ="";
        }
        else{
          d["STOP_SERVICE_DATE"] = this.dateFormating(d.STOPSERVICEDATE)
        }
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE)
        return d;
      });

     
    });
  }

  onSubmit() {
    this.submitted = true;
     this.LocationForm.STARTSERVICEDATE= this.convertLocalDateString(this.LocationForm.STARTSERVICEDATE);
     this.LocationForm.STOPSERVICEDATE= this.convertLocalDateString(this.LocationForm.STOPSERVICEDATE);
    this.error = LocationValidation(this.LocationForm, "");
    if (
      !this.error.NAME &&
      !this.error.CODE &&
      !this.error.BLOCKLEVELID &&
      !this.error.LEVELAREAID &&
      !this.error.STARTSERVICEDATE &&
      !this.error.BLOCKID &&
      !this.error.FACILITYID
    )
     {
      
      if (this.type == "new") {
           this.LocationForm.ID = 0,
           this.LocationForm.CREATEDBY = parseInt(getCookie(userid)),
           this.LocationForm.MODIFIEDBY = parseInt(getCookie(userid)),
           this.LocationForm.CREATEDDATE = new Date(),
           this.LocationForm.MODIFIEDDATE = new Date(),
           this.LocationForm.SHORTNAME = null
         }
           if(this.type=="update"){
          let myDate1 = this.LocationForm.STARTSERVICEDATE
          myDate1.setDate( myDate1.getDate() +1);
          if(this.LocationForm.STOPSERVICEDATE!=null){
            let myDate2 = this.LocationForm.STOPSERVICEDATE
            myDate2.setDate( myDate2.getDate() +1);
            this.LocationForm.STARTSERVICEDATE = myDate2
          }
          }
      this.addLocationData(JSON.stringify(this.LocationForm));
    }
  }

  addLocationData(data) {
    const paramsObj = {
      newData: data,
      schemaName: "dbo",
      tableName: "arealocation",
      userId: getCookie(userid),
    };

    if (this.type == "new") {
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Locations","Add Location","Add Location in inLocations").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this.getLocationData("dbo", "arealocation");
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }

    if (this.type == "update") {
      HttpClient.fetchData(
        "/api/MasterData/UpdateLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
      
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Locations","Update Location","Update Location in inLocations").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getLocationData("dbo", "arealocation");
         
          this.opened = false;
        }
      });
    }
  }


  getAllUser(){
    return new Promise((resolve, reject) => {
      HttpClient.get(
       "/api/Users/GetAllUser",
        true,
        true
      )
        .then((result: any) => {
          let data = result;
          let LocInc;
          let CompanyRe;
           LocInc = data.filter((e)=>{
            return e.categoryId==3
          })
          this.loationIncharge = LocInc.map((e)=>{
            return {id:e.id,name:e.staffName}
          })
          this.filterdLocationInc =this.loationIncharge.slice()
          this.locationInChargeObj=this.filterdLocationInc.reduce((acc,d)=>{
            acc[d.name]=d.id;
            return acc;
        },{})
           CompanyRe = data.filter((e)=>{
            return e.categoryId==1
          })
            this.companyRe= CompanyRe.map((e)=>{
            return {id:e.id,name:e.staffName}
          })
          this.filterdCompanyRe =  this.companyRe.slice();
          this.companyRepresentiveObj=this.filterdCompanyRe.reduce((acc,d)=>{
            acc[d.name]=d.id;
            return acc;
        },{})
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  handleCompanyFilter(value){
    this.filterdCompanyRe = this.companyRe.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleLocationFilter(value){
    this.filterdLocationInc = this.loationIncharge.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  checkedUniqueCode(){
    this.isunique=false;
    let val:string=this.LocationForm.CODE;
    if(!(this.uniqueList.filter((d)=>d==val.toLowerCase()).length>0)){
        this.isunique=this.gridData.filter((d)=>{
          return val.toLowerCase()==d.CODE.toLowerCase()
        }).length>0
    }
  }

  statusValueChange(status){
    if(status==1){
      this.statusValue = true;
      this.LocationForm.STOPSERVICEDATE =null;
    }
      if(status==2){
        this.statusValue = false;
      }
  }

  download() {
    let fileName = 'LocationData.csv';
    let columnNames = ["ID","FACILITY","BLOCKCODE","LEVELCODE", "AREACODE", "LOCATIONCODE","LOCATIONNAME","SHORTNAME","LOCATIONINCHARGE","START_SERVICE_DATE","STOP_SERVICE_DATE","STATUS","NAMEMODIFIEDBY"];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';

    this.gridData.map(c => {
      csv += [c["ID"],c["FACILITY"],c["BLOCKCODE"],c["LEVELCODE"],c ["AREACODE"], c["LOCATIONCODE"],c["LOCATIONNAME"],c["SHORTNAME"],c["LOCATIONINCHARGE"],c["START_SERVICE_DATE"],c["STOP_SERVICE_DATE"],c["STATUS"],c["NAMEMODIFIEDBY"]].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    HttpClient.insertAduitLog("Locations","Download "+fileName,"Download "+fileName+" Location in inLocations").then((res:any)=>{
      if (res.isValid == true) {

      }
    })
    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  qrCodeGeneration(data){
    
        this.qrCodeLocationForm.id = data.ID;
        this.qrCodeLocationForm.moduleHierarchyId = this.assetLocationHierarchyId;
        this.qrCodeLocationForm.blockId=data.BLOCKID;
        this.qrCodeLocationForm.name=data.NAME;
        this.qrCodeLocationForm.nameCreatedBy=data.NAMECREATEDBY;
        this.qrCodeLocationForm.nameModifiedBy=data.MODIFIEDBY;
        this.qrCodeLocationForm.shortName = data.SHORTNAME;
        this.qrCodeLocationForm.blockLevelId= data.BLOCKLEVELID;
        this.qrCodeLocationForm.code= data.CODE;
        this.qrCodeLocationForm.companyRepresentative=data.COMPANYREPRESENTATIVE;
        this.qrCodeLocationForm.createdBy = data.CREATEDBY;
        this.qrCodeLocationForm.createdDate= data.CREATEDDATE;
        this.qrCodeLocationForm.facilityId=data.FACILITYID;
        this.qrCodeLocationForm.levelAreaId = data.LEVELAREAID;
        this.qrCodeLocationForm.locationIncharge=data.LOCATIONINCHARGE;
        this.qrCodeLocationForm.modifiedBy = data.MODIFIEDBY;
        this.qrCodeLocationForm.modifiedDate = new Date();
        this.qrCodeLocationForm.nameCreatedBy=data.NAMECREATEDBY;
        this.qrCodeLocationForm.nameModifiedBy = data.NAMEMODIFIEDBY;
        this.qrCodeLocationForm.statusId = data.STATUSID;
        this.qrCodeLocationForm.startServiceDate = data.STARTSERVICEDATE;
        this.qrCodeLocationForm.stopServiceDate = data.STOPSERVICEDATE;
        this.qrCodeLocationForm.locationSubLocations = []
        // HttpClient.fetchData("/api/MasterData/GenerateQRCodeForLocation", "post",  this.qrCodeLocationForm, true, true)
        // .then((result: any) => {
        //       if(result===true){
        //         HttpClient.get(
        //         `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${this.qrCodeLocationForm.id}&&moduleHierarchyId=${this.qrCodeLocationForm.moduleHierarchyId}`,
        //           true,
        //           true
        //         ).then((result: any) => {
        //           this.qrCodeGenUrl = result
                  
        //         })
        //       }
        // });
    this.qrcodePrintData=[]    
    this.qrCodeDialogOpen= true;
    this.locationQrValue = data.FACILITY+`-`+  data.BLOCKCODE + `-`+ data.LEVELCODE + `-` + data.AREACODE +`-` + data.CODE
    let obj={
      data:`${window.location.origin}/mobile?QRCodeType=location&id=${data.ID}`,//JSON.stringify({QRCodeType:"Location",QRCodeObjectId:d.ID}),
      assetCode:null,
      assetNo:null,
      location:this.locationQrValue 
    }
   this.qrcodePrintData.push(obj)
}

getModuleHierarchYdata(schema, tablename){
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
    
  let ModuleHieracrhyData = JSON.parse(result.gridData)
    let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
            return e.NAME ==="Location"
      })
        this.assetLocationHierarchyId = ModFacArr[0].ID
     
  });
}

viewQR(){
  window.open(this.imageUrl+this.qrCodeGenUrl)
}
downloadTemplate(){
  window.open("/assets/template/location.xlsx")
}
convertLocalDateString(d):any{
  if(!d)
     return null;
  function padding(n){
    if(n<10){
        return "0"+n 
    }
    return n;
  }
  let ndate=new Date(d);
  return `${ndate.getFullYear()}-${padding(ndate.getMonth()+1)}-${padding(ndate.getDate())}T${padding(ndate.getHours())}:${padding(ndate.getMinutes())}:${padding(ndate.getSeconds())}`
}
importDataChange(e){
let locList=addBlukLocation(e,this.facilityObj,this.blockObj,this.levelObj,this.areaObj,this.locationInChargeObj,this.companyRepresentiveObj);

Promise.all([
  locList.map((d)=>{
    return this.addImportLocationData(JSON.stringify(d));
  })
])
.then((result)=>{
  this._notif.show({
    content: "Successfully Save",
    type: { style: "success", icon: true },
  });
  this.importopened=false;
  setTimeout(()=>{
    this.getLocationData("dbo", "arealocation");
  },2000)
})
.catch((err)=>{
  this._notif.show({
    content: "Error in Import",
    type: { style: "error", icon: true },
  });
})
console.log(e,locList);
}
addImportLocationData(data) {
      const paramsObj = {
        newData: data,
        schemaName: "dbo",
        tableName: "arealocation",
        userId: getCookie(userid),
      };
     return new Promise((resolve,reject)=>{
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        resolve(result);
      })
      .catch((err)=>{
         resolve(err)
      })
     })

  }
}
