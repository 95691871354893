import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ERParameterMapping } from 'src/app/models/er.model';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { PMValidation } from "src/validator/er";
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";

@Component({
  selector: 'app-paramter-mapping',
  templateUrl: './paramter-mapping.component.html',
  styleUrls: ['./paramter-mapping.component.scss']
})
export class ParamterMappingComponent implements OnInit {
  removeDialogOpen: boolean = false
  public formats = "dd-MM-yyyy"
  public emailTimenotification:any
  public contectGrid = []
  public editUserDropDown :any  = []
  public designationName ;
  public emailName;
  public companyName;
  public designAtion:any =[]
  public assetType = "new" ;
  public permission:Permission=new Permission();
  public isAccess: boolean = false;
  public isLocation:boolean = false;
  public personInChargeArray = []
  public usrShiftDropDown: any = [

  ]
  public personInChargeGrid = []
  public blockValue: any = []
  public levelValue: any = []
  public areaValue: any = []
  public locationValue: any = []
  public subLocationValue: any = []
  public FacilitValue: any = []

  public erReadingArray = []
  public gridData = []
  public opened: boolean = false;
  public erNoSHow: boolean = false;
  public dailogTitle = ""
  public type = ""
  public actionsLayout = "normal";
  public serviceObj = {}
  public categoryObj = {}
  public frequencyObj = {}
  public userObj = {}
  public facility = ""
  public bCode = ""
  public bname = ""
  public lCode = ""
  public lname = ""
  public loCode = ""
  public loname = ""
  public aCode = ""
  public aname = ""
  public subloCode = ""
  public subloname = ""
  public frequencyDisable: boolean = false
  public pMForm: ERParameterMapping = new ERParameterMapping()
  error = PMValidation(this.pMForm, "init");
  public categoryDropDown = [
    { id: 1, name: "Asset" },
    { id: 2, name: "Non-Asset" }
  ]
  public userDropDown: any = []
  public serviceDropDown: any = []
  public staffDropDown: any = []
  public assetNoDropDown = []
  public frequencyDropDown: any = []
  public monthDayList = [
    { full: "January", short: "Jan", day: "Sun" },
    { full: "February", short: "Feb", day: "Mon" },
    { full: "March", short: "Mar", day: "Tue" },
    { full: "April", short: "Apr", day: "Wed" },
    { full: "May", short: "May", day: "Thr" },
    { full: "June", short: "Jun", day: "Fri" },
    { full: "July", short: "Jul", day: "Sat" },
    { full: "Augest", short: "Aug", day: "Sun" },
    { full: "September", short: "Sep", day: "" },
    { full: "October", short: "Oct", day: "" },
    { full: "November", short: "Nov", day: "" },
    { full: "December", short: "Dec", day: "" },
  ]


  public column1 = [
    { field: "description", title: "Description", isFilter: true, width: 200 },
    { field: "category", title: "Category", isFilter: true, width: 200 },
    { field: "erno", title: "ER No", isFilter: true, width: 200 },
    { field: "location", title: "Location", isFilter: true, width: 200 },
    { field: "service", title: "Service Type", isFilter: true, width: 200 },
    { field: "frequency", title: "Frequency", isFilter: true, width: 200 },
    { field: "effectiveStartDate", title: "Effective Start Date", isFilter: true, width: 200 },
    { field: "emailTimeNotification", title: "Email time notification ", isFilter: true, width: 200 },
    { field: "assignTo", title: "Assign to", isFilter: true, width: 200 },
  ]

  public contectColumn = [
    {
      field: "parameterName",
      title: "Parameter Name",
      type: "input",
      errorField: "pmname_error",
      isFilter: false,
      width: 140,
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
    },
    {
      field: "uomid",
      title: "UOM",
      type: "uomDropdown",
      isFilter: false,
      width: 140,
      errorField: "uom_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
    },
    {
      field: "min",
      title: "Min",
      type: "input",
      isFilter: false,
      width: 140,
      validation: (val) => {
        if (val)
          return ""

      },

    },
    {
      field: "max",
      title: "Max",
      type: "input",
      isFilter: false,
      width: 140,
      errorField: "email_error",
      validation: (val) => {
        if (val)
          return ""

      },
    },
    {
      field: "status",
      title: "Status",
      type: "statusDropDown",
      isFilter: false,
      width: 140,
      errorField: "status_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
    },
    {
      field: "remarks",
      title: "Remark",
      type: "input",
      isFilter: false,
      width: 140,
      validation: (val) => {
        if (val)
          return ""

      },

    }
  ]

  public personInChargeColumn = [
    {
      field: "personInChargeId",
      title: "Person In Charge Name ",
      type: "userDropDown",
      errorField: "person_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
      isFilter: false,
      width: 140
    },
    {
      field: "designation",
      title: "Designation",
      type: "disabledData",
      disable: "true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "emailAddress",
      title: "Email address ",
      type: "disabledData",
      disable: "true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "companyName",
      title: "Company Name",
      type: "disabledData",
      disable: "true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "remarks",
      title: "Remarks",
      type: "input",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
  ]

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });

    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  ngOnInit(): void {

    Promise.all([
      this.getAssetRegistrationData(),
      this.getLoockup("dbo", "ServicesType"),
      this.getStaff(),
      this.getLoockup("dbo", "ERFrequency"),
      this.getLoockup("dbo", "block"),
      this.getLoockup("dbo", "blocklevel"),
      this.getLoockup("dbo", "levelarea"),
      this.getLoockup("dbo", "arealocation"),
      this.getLoockup("dbo", "locationsublocation"),
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "ERParameterShift"),
      this.getLoockup("dbo", "Designation")
    ]).then((result) => {
      this.serviceDropDown = result[1]
      this.userDropDown = result[2]
      this.frequencyDropDown = result[3]
      this.blockValue = result[4]
      this.levelValue = result[5]
      this.areaValue = result[6]
      this.locationValue = result[7]
      this.subLocationValue = result[8]
      this.FacilitValue = result[9]
      this.usrShiftDropDown = result[10]
      this.designAtion = result[11]
      this.getERPMData()
      this.getUser()
    })
    if(UserModuleObj.engineering_records){
      this.permission=UserModuleObj.engineering_records;
      this.isAccess = true;
    }else{
      this.isAccess = false;
    }

  }

  editColumn(data) {
    if (data.action == "edit") {
      this.openRoleDailog("update")
      this.assetType = "edit";
      this.getPMByID(data.dataItem.id)
      this.personInChargeGrid = []
      this.contectGrid = []
    }
    if (data.action == "remove") {
      this.removeDialogOpen = true;
      this.getPMByID(data.dataItem.id)
    }
  }

  openRoleDailog(data) {
    this.type = data
    if (data === 'new') {
      this.assetType = "new" ;
      this.opened = true;
      this.facility = ""
      this.bCode = ""
      this.bname = ""
      this.lCode = ""
      this.lname = ""
      this.loCode = ""
      this.loname = ""
      this.aCode = ""
      this.aname = ""
      this.subloCode = ""
      this.subloname = ""
      this.dailogTitle = "Add Parameter Mapping"
      this.pMForm = new ERParameterMapping()
      this.personInChargeGrid = []
      this.contectGrid = []
      this.error = PMValidation(this.pMForm, "init");
    }
    if (data === 'update') {
      this.dailogTitle = "Update Parameter Mapping"
      this.opened = true;

    }
  }

  onDialogClose(data) {
    if (data === "new") {
      this.opened = false
    }
    if (data === "remove") {
      this.removeDialogOpen = false
    }


  }


  onReset() {
    this.openRoleDailog('new')
    this.error = PMValidation(this.pMForm, "init");
  }

  getAssetRegistrationData() {
    return new Promise((resolve, rejects) => {
      HttpClient.get(
        "/api/Assets/GetAssets",
        true,
        true
      ).then((result: any) => {
        this.assetNoDropDown = result
        resolve(result);
      }).catch((e) => {
        rejects(e)
      })
    })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData);
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getStaff() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/BPM/GetBpms", true, true)
        .then((result: any) => {
          resolve(result);
        }).catch((err) => {
          reject(err)
        })
    })
  }

  personchargeChangeValue(data) {
    this.personInChargeArray = []
    for (let i = 0; i < data.length; i++) {
      this.personInChargeArray.push({
        id: 0, erparameterId: 0, personInChargeId: data[i].personInChargeId
        , createdDate: new Date(), remarks: data[i].remarks
      });
    }
  }

  erReadingChangeValue(data) {
    this.erReadingArray = []
    for (let i = 0; i < data.length; i++) {
      this.erReadingArray.push({
        id: 0, erparameterId: 0, parameterName: data[i].parameterName,
        uomid: data[i].uomid, min: data[i].min, max: data[i].max, status: data[i].status, createdDate: new Date(), remarks: data[i].remark
      });
    }
  }

  categoryValueChange(data) {
    if (data == 1) {
      this.erNoSHow = true;
      this.isLocation = true;
    }
    else {
      this.erNoSHow = false;
      this.erNoSHow = false;
      this.pMForm.erno = ""
      this.isLocation = false;
      this.facility = ""
      this.bCode = ""
      this.bname = ""
      this.lCode = ""
      this.lname = ""
      this.loCode = ""
      this.loname = ""
      this.aCode = ""
      this.aname = ""
      this.subloCode = ""
      this.subloname = ""

    }
  }


  onSubmit() {
    this.pMForm.id = (this.type == "new") ? 0 : this.pMForm.id;
    this.pMForm.erreadingModels = this.erReadingArray;
    if(this.pMForm.emailTimeNotificationdt != '' && this.pMForm.emailTimeNotificationdt != undefined){
    this.pMForm.emailTimeNotificationdt =  this.formatDateTime(new Date(this.pMForm.emailTimeNotificationdt))  
    }
    this.pMForm.erpersonInChargeModels = this.personInChargeArray;
    this.pMForm.createdBy = parseInt(getCookie(userid))
    this.pMForm.updatedBy = parseInt(getCookie(userid))
    if(this.pMForm.effectiveStartDate != undefined){
    this.pMForm.effectiveStartDate=this.convertLocalDateString(this.pMForm.effectiveStartDate)
    }
    this.pMForm.createdDate = new Date()
    this.pMForm.updatedDate = new Date()
    this.error = PMValidation(this.pMForm, "");
    if (!this.error.categoryId || !this.error.erno || !this.error.effectiveStartDate || !this.error.frequencyId) {
      this.addPMData()
    }
  }

  formatDateTime(date) {
    if(date == null)
    return '';
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
  hour = '' + d.getHours(),
  minutes = '' + d.getMinutes(),
  secound = '' + d.getSeconds();
  if (month.length < 2) 
    month = '0' + month;
  if (day.length < 2) 
    day = '0' + day;
    if (hour.length < 2) 
    hour = '0' + hour;
    if (minutes.length < 2) 
    minutes = '0' + minutes;
    if (secound.length < 2) 
    secound = '0' + secound;
    return year+'-' +month +'-'+day +'T' + hour + ':'+minutes+':' + secound;
  
  }
  

  addPMData() {
    if (this.type == "new") {
      const params = {
        ...this.pMForm
      };
      HttpClient.fetchData("/api/ER/AddErparameter", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Add Parameter Mapping","Add Parameter Mapping in Engineering Records").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.opened = false;
          this.getERPMData()
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
    if (this.type == "update") {
      const params = {
        ...this.pMForm
      };
      debugger;
      if(params.emailTimeNotificationdt == '1-01-01T00:00:00'){
        params.emailTimeNotificationdt = "00:00:00";
      }
     HttpClient.fetchData("/api/ER/UpdateErparameter", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Update Parameter Mapping","Update Parameter Mapping in Engineering Records").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.opened = false;
          this.getERPMData()
        }
      })
    }
  }

  pading(n) {
    if (n > 9)
      return n;
    else
      return "0" + n
  }
  dateFormating(da) {
    let df = new Date(da);
    return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindServiceObj(result) {
    this.serviceObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindcategoryObj(result) {
    this.categoryObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindFrequencyObj(result) {
    this.frequencyObj = result.reduce((acc, d) => {
      acc[d.ID] = d.FREQUENCY;
      return acc;
    }, {})
  }

  bindStaffObj(result) {
    this.userObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }


  getERPMData() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/ER/GetErparameter", true, true)
        .then((result: any) => {
          let data = shiftLastDataInto1st(result)
          this.bindServiceObj(this.serviceDropDown);
          this.bindcategoryObj(this.categoryDropDown);
          this.bindFrequencyObj(this.frequencyDropDown);
          this.bindStaffObj(this.userDropDown);
          this.gridData = data.map((d) => {

            d["service"] = this.serviceObj[d.serviceTypeId]
            d["category"] = this.categoryObj[d.categoryId];
            d["frequency"] = this.frequencyObj[d.frequencyId];
            d["assignTo"] = this.userObj[d.assignTo];
            d["effectiveStartDate"] = d.effectiveStartDate == null?"":this.dateFormating(new Date(d.effectiveStartDate))
          
            // d["emailTimeNotification"] = d.emailTimeNotification
            d["dailyTimeErcreation"] = this.convertTimeFormat(d.dailyTimeErcreationdt)
            return d;
          })
        })
    })
  }

  convertTimeFormat(date) {
    var today = new Date(date);
    return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  }

  getPMByID(id) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/ER/GetErparameterById?id=${id}`, true, true)
        .then((result: any) => {
         
          this.pMForm = result;
          
           this.personInChargeGrid = this.pMForm.erpersonInChargeModels.map((e)=>{
             if(e.personInChargeId){
               this.staffNameChange(e.personInChargeId)
             }
             e["designation"] = this.designationName
             e["emailAddress"] = this.emailName
             e["companyName"] = this.companyName
             return e;
           }) 
           this.contectGrid = this.pMForm.erreadingModels
           const[date1,date2] = result.emailTimeNotificationdt.split("+")
         
          this.pMForm.emailTimeNotificationdt =  result.emailTimeNotificationdt == null?null:new Date(date1)
          // this.pMForm.emailTimeNotification = result.emailTimeNotification
          this.pMForm.effectiveStartDate = result.effectiveStartDate == null?null:new Date(result.effectiveStartDate)
          this.pMForm.dailyTimeErcreationdt = new Date(result.dailyTimeErcreationdt)
          this.assetValueChangeAfterEdit(this.pMForm.erno,this.pMForm.categoryId)
          // this.contectGrid = result.
        })
    })
  }

  removeData() {
   
    HttpClient.fetchData(`/api/ER/DeleteErparameter?id=${this.pMForm.id}`, "post", true, true).then((result: any) => {
      if (result.isValid == true) {
        HttpClient.insertAduitLog("Engineering Records","Delete Parameter Mapping","Delete Parameter Mapping in Engineering Records").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
        this.opened = false;
        this.getERPMData()
      }
      else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
    })
  }

  assetValueChange(data) {
    if(this.pMForm.categoryId ===1){
      let assetValue = this.assetNoDropDown.filter((e) => {
        return e.assetNumber === data;
      })

      this.pMForm.erno = assetValue[0].assetNumber
      let locationValue = assetValue[0].assetLocations
      let bindBlockValue = this.blockValue.filter((e) => {
        return e.ID === locationValue[0].blockId
      })
      this.bCode = bindBlockValue[0].CODE
      this.bname = bindBlockValue[0].NAME
      let bindAreaValue = this.areaValue.filter((e) => {
        return e.ID === locationValue[0].areaId
      })
      this.aCode = bindAreaValue[0].CODE
      this.aname = bindAreaValue[0].NAME

      let bindlevelValue = this.levelValue.filter((e) => {
        return e.ID === locationValue[0].levelId
      })
      this.lCode = bindlevelValue[0].CODE
      this.lname = bindlevelValue[0].NAME

      let bindlocValue = this.locationValue.filter((e) => {
        return e.ID === locationValue[0].locationId
      })
      this.loCode = bindlocValue[0].CODE
      this.loname = bindlocValue[0].NAME

      let bindSuLocValue = this.subLocationValue.filter((e) => {
        return e.ID === locationValue[0].subLocationId
      })
      if(bindSuLocValue.length != 0){
      this.subloCode = bindSuLocValue[0].CODE
      this.subloname = bindSuLocValue[0].NAME
      }
      let facilityValue = this.FacilitValue.filter((e) => {
        return e.ID === locationValue[0].facilityId
      })

      this.facility = facilityValue[0].NAME
    }
      else{
      
      }
   
  }

  getUser(){
    return new Promise((resolve,reject)=>{
        HttpClient.get("/api/Users/GetAllUser", true, true)
          .then((result: any) => {
            this.editUserDropDown = result;
            resolve(result);
          }).catch((err)=>{
            reject(err)
          })
    })
  }

  assetValueChangeAfterEdit(data,categoryId) {
    if(categoryId === 1){
      this.erNoSHow = true;
      let assetValue = this.assetNoDropDown.filter((e) => {
        return e.assetNumber === data;
      })
      if(assetValue.length>0){
        // this.pMForm.erno = assetValue[0].id
        this.pMForm.erno = assetValue[0].assetNumber
        let locationValue = assetValue[0].assetLocations
        let bindBlockValue = this.blockValue.filter((e) => {
          return e.ID === locationValue[0].blockId
        })
        this.bCode = bindBlockValue[0].CODE
        this.bname = bindBlockValue[0].NAME
        let bindAreaValue = this.areaValue.filter((e) => {
          return e.ID === locationValue[0].areaId
        })
        this.aCode = bindAreaValue[0].CODE
        this.aname = bindAreaValue[0].NAME
  
        let bindlevelValue = this.levelValue.filter((e) => {
          return e.ID === locationValue[0].levelId
        })
        this.lCode = bindlevelValue[0].CODE
        this.lname = bindlevelValue[0].NAME
  
        let bindlocValue = this.locationValue.filter((e) => {
          return e.ID === locationValue[0].locationId
        })
        this.loCode = bindlocValue[0].CODE
        this.loname = bindlocValue[0].NAME
  
        let bindSuLocValue = this.subLocationValue.filter((e) => {
          return e.ID === locationValue[0].subLocationId
        })
        this.subloCode = bindSuLocValue[0].CODE
        this.subloname = bindSuLocValue[0].NAME
        let facilityValue = this.FacilitValue.filter((e) => {
          return e.ID === locationValue[0].facilityId
        })
  
        this.facility = facilityValue[0].NAME
      }
      
    }
      else{
        this.erNoSHow = false;
        this.pMForm.erno = data
        this.facility = ""
        this.bCode = ""
        this.bname = ""
        this.lCode = ""
        this.lname = ""
        this.loCode = ""
        this.loname = ""
        this.aCode = ""
        this.aname = ""
        this.subloCode = ""
        this.subloname = ""
      }
   
  }
  valueChange(data){
    console.log("dfgfdgfdgfdh",data)
  }

  staffNameChange(data){
    let value=  this.editUserDropDown.filter((e)=>{
      return e.id === data
    });
      if(value.length>0){
        let designationValue = this.designAtion.filter((e)=>{
          return e.ID===value[0].designationId
        })
             if( designationValue.length>0){
              this.designationName = designationValue[0].NAME
             }
    
             if(value.length>0){
              this.emailName = value[0].userName
              this.companyName = value[0].companyName
             }
      }
    
  
   
    //  this.gridData[0].assetDes = value[0].assetCode

  }


  dateStringCOnverion(date1):any{
    var dateString = new Date(date1)
var date = new Date(dateString);
var date2 = new Date()
var yr = date2.getFullYear();
var mo = date2.getMonth() + 1;
var day = date2.getDate();

var hours = date.getHours();
var hr = hours < 10 ? '0' + hours : hours;

var minutes = date.getMinutes();
var min = (minutes < 10) ? '0' + minutes : minutes;

var seconds = date.getSeconds();
var sec = (seconds < 10) ? '0' + seconds : seconds;

var newDateString = yr + '-' + mo  + '-' + day;
var newTimeString = hr + ':' + min + ':' + sec;

return newDateString + 'T' + newTimeString;
  }

  // pading(n) {
  //   if (n > 9)
  //     return n;
  //   else
  //     return "0" + n
  // }
  convertLocalDateString(d):any{
    let ndate=new Date(d);
    return `${ndate.getFullYear()}-${this.pading(ndate.getMonth()+1)}-${this.pading(ndate.getDate())}T00:00:00`
  }
}

