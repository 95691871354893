import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, LOCALE_ID, NgZone } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { InfoComponent } from './components/info/info.component';
import { HeaderComponent } from './header/header.component';
import { CustomMessagesService } from './services/custom-messages.service';
import { AssetsManagmentComponent } from './components/assets-managment/assets-managment.component';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { EditService, SchedulerModule } from '@progress/kendo-angular-scheduler';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { MessageService } from '@progress/kendo-angular-l10n';
import { ToolsModule } from './tools/tools.module';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { BarcodesModule } from "@progress/kendo-angular-barcodes";

const drawerRoutes = [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'info', component: InfoComponent },
    { path: 'assets', component: AssetsManagmentComponent },
    { path: 'input', component: InputComponent },
    { path: 'register', component: RagisterComponent },
    { path: 'rolepermission', component: RolepermissionComponent },
    { path: 'userrole', component: UserroleComponent },
    { path: 'user-shift', component: UserShiftComponent },
    { path: 'asset-classification', component: AssetClassificationComponent },
    { path: 'asset-type-code-classification', component: AssetTypeCodeClassificationComponent },
    { path: 'asset-standardization', component: AssetStandardizationComponent },
    { path: 'contractor-vendor', component: ContractorVendorComponent },
    { path: 'blocks', component: BlocksComponent },
    { path: 'levels', component: LevelsComponent },
    { path: 'area', component: AreaComponent },
    { path: 'location', component: LocationComponent },
    { path: 'sub-location', component: SubLocationComponent },
    { path: 'facility-registeration', component: FacilityRegisterationComponent },
    { path: 'asset-registration', component: AssetRegistrationComponent },
    { path: 'maintanence-status', component: MaintenenceStatusComponent },
    { path: 'warrenty-coverage', component: WarrantyCoverageComponent },
    { path: 'attachments', component: AttachmentsComponent },
    { path: 'calendar', component: CalenderComponent },
    { path: 'list-value', component: ListValueComponent },
    { path: 'lookup/:name', component: LookupComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'asset-register', component: AssetRegisterComponent },
    { path: 'qr-code-scanning', component: QrCodeScanningComponent },
    { path: 'asset-qr-code-printing', component: AssetQrCodePrintingComponent },
    { path: 'location-qr-code-printing', component: LocationQrCodePrintingComponent },
    { path: 'spare-parts', component: SparePartsComponent },
    { path: 'spare-part-update', component: SparePartUpdateComponent },
    { path: 'spare-part-request', component: SparePartRequestComponent },
    { path: 'spare-part-return', component: SparePartReturnComponent },
    { path: 'monthly-spare-part-register', component: MonthlySparePartRegisterComponent },
    { path: 'stock-take-activity', component: StockTakeActivityComponent },
    { path: 'work-order-query', component: WorkOrderQueryComponent },
    { path: 'raise-work-request', component: RiseWorkRequestComponent },
    { path: 'ppm', component: PpmComponent },
    { path: 'daily-inspection', component: DailyInspectionComponent },
    { path: 'predective-maintenance', component: PredectiveMaintenanceComponent },
    { path: 'ppm-load-balancing', component: PpmLoadBalancingComponent },
    { path: 'create-checklist', component: CreateChecklistComponent },
    { path: 'sla-mapping-for-checklist', component: SlaMappingForChecklistComponent },
    { path: 'contract-management-for-facility-user', component: ContractManagementForFacilityUserComponent },
    { path: 'checklist', component: ChecklistContainerComponent },
    { path: 'checklist-new', component: ChecklistCombindComponent },
    { path: 'download-app', component: DownloadAppComponent },
    { path: 'license-certifciate', component: LicsenceCertificateComponent },
    { path: 'parameter-mapping', component: ParamterMappingComponent },
    { path: 'er-task', component: ErTaskCodeGenerationComponent },
    { path: 'er-report-daily', component: ErReportDailyValueComponent },
    { path: 'er-report-monthly', component: ErReportMonthlyComponent },
    
    { path: 'er-capture', component: ErCaptureComponent },
    { path: 'c', component: ErReportMonthlyComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'notification', component: NotificationCentreComponent },
    { path: 'closedwosummary', component: ClosedWorkOrderReportComponent },
    { path: 'company-working-hour', component: CompanyWorkingHourComponent },
    { path: 'audit-log', component: AuditLogComponent },
    { path: 'avgWOOutstanding', component: AveragWOOutstandingReportComponent },
    { path: 'reportForContractor', component: WorkOrderReportForContractorComponent },
    { path: 'workOrderReportForIBCC', component: WorkOrderReportForIBCCComponent },
    { path: 'workOrderReportForIBCCVendorPerformance', component: WorkOrderVenderPerformanceComponent },
    { path: 'workOrderReportForIBCCSpareParts', component: WorkOrderSparePartsComponent },
    { path: 'workOrderIBCCSparePartsRequests', component: WorkOrderSparePartsRequestComponent },
    { path: 'workOrderIBCCSparePartsReturns', component: WorkOrderSparePartsReturnComponent },
    { path: 'asset_summery', component: AssetSummeryComponent },
    { path: 'schedule_wo_summery', component: ScheduleWoSummeryComponent },
    { path: 'unschedule_wo_summery', component: UnscheduleWoSummeryComponent },
    { path: 'inventory_summery', component: InventorySummeryComponent },
    { path: 'feedback_received', component: FeedbackReceivedPercentageComponent },
    { path: 'external_feedback', component: ExternalFeedbackComponent },
];

import 'hammerjs';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import { LoginUserComponent } from './components/login-user/login-user.component';
import { RagisterComponent } from './components/ragister/ragister.component';
import { DashboardtableComponent } from './components/dashboardtable/dashboardtable.component';
import { InputComponent } from './components/input/input.component';
import { RolepermissionComponent } from './components/rolepermission/rolepermission.component';
import { UserroleComponent } from './components/userrole/userrole.component';
import { UserShiftComponent } from './components/user-shift/user-shift.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { MenuModule } from '@progress/kendo-angular-menu';
import { AssetClassificationComponent } from './components/asset-classification/asset-classification.component';
import { AssetStandardizationComponent } from './components/asset-standardization/asset-standardization.component';
import { ContractorVendorComponent } from './components/contractor-vendor/contractor-vendor.component';
import { AssetTypeCodeClassificationComponent } from './components/asset-type-code-classification/asset-type-code-classification.component';
import { BlocksComponent } from './components/blocks/blocks.component';
import { LevelsComponent } from './components/levels/levels.component';
import { FacilityRegisterationComponent } from './components/facility-registeration/facility-registeration.component';
import { AreaComponent } from './components/area/area.component';
import { LocationComponent } from './components/location/location.component';
import { SubLocationComponent } from './components/sub-location/sub-location.component';
import { RolesPermissionTableGridComponent } from './components/roles-permission-table-grid/roles-permission-table-grid.component';
import { AssetRegistrationComponent } from './components/fams/asset-registration/asset-registration.component';
import { MaintenenceStatusComponent } from './components/fams/maintenence-status/maintenence-status.component';
import { WarrantyCoverageComponent } from './components/fams/warranty-coverage/warranty-coverage.component';
import { AttachmentsComponent } from './components/fams/attachments/attachments.component';
import { CalenderComponent } from './components/calender/calender.component';
import { ListValueComponent } from './components/list-value/list-value.component';
import { LookupComponent } from "./components/lookup-data/lookup.component";
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AssetRegisterComponent } from './components/fams/asset-register/asset-register.component';
import { QrCodeScanningComponent } from './components/fams/qr-code/qr-code-scanning/qr-code-scanning.component';
import { AssetQrCodePrintingComponent } from './components/fams/qr-code/asset-qr-code-printing/asset-qr-code-printing.component';
import { LocationQrCodePrintingComponent } from './components/fams/qr-code/location-qr-code-printing/location-qr-code-printing.component';
import { SparePartsComponent } from './components/fams/spare-parts/spare-parts/spare-parts.component';
import { SparePartUpdateComponent } from './components/fams/spare-parts/spare-part-update/spare-part-update.component';
import { SparePartRequestComponent } from './components/fams/spare-parts/spare-part-request/spare-part-request.component';
import { SparePartReturnComponent } from './components/fams/spare-parts/spare-part-return/spare-part-return.component';
import { MonthlySparePartRegisterComponent } from './components/fams/spare-parts/monthly-spare-part-register/monthly-spare-part-register.component';
import { StockTakeActivityComponent } from './components/fams/spare-parts/stock-take-activity/stock-take-activity.component';
import { WorkOrderQueryComponent } from './components/fams/work-order/work-order-query/work-order-query.component';
import { RiseWorkRequestComponent } from './components/fams/work-order/rise-work-request/rise-work-request.component';
import { WorkOrderAssetComponent } from './components/fams/work-order/work-order-query/work-order-asset/work-order-asset.component';
import { WorkOrderOnLocationComponent } from './components/fams/work-order/work-order-query/work-order-on-location/work-order-on-location.component';
import { WorkOrderReassignComponent } from './components/fams/work-order/work-order-reassign/work-order-reassign.component';
import { SparePartReplacementComponent } from './components/fams/work-order/work-order-query/spare-part-replacement/spare-part-replacement.component';
import { WorkOrderAttactmentsComponent } from './components/fams/work-order/work-order-query/work-order-attactments/work-order-attactments.component';
import { CompleteionInfoComponent } from './components/fams/work-order/work-order-query/completeion-info/completeion-info.component';
import { WorkOrderInquiryComponent } from './components/fams/work-order/work-order-query/work-order-inquiry/work-order-inquiry.component';
import { OverallGridViewComponent } from './components/fams/work-order/work-order-query/overall-grid-view/overall-grid-view.component';
import { PpmComponent } from './components/fams/planner/ppm/ppm.component';
import { DailyInspectionComponent } from './components/fams/planner/daily-inspection/daily-inspection.component';
import { PredectiveMaintenanceComponent } from './components/fams/planner/predective-maintenance/predective-maintenance.component';
import { PpmLoadBalancingComponent } from './components/fams/planner/ppm-load-balancing/ppm-load-balancing.component';
import { PpmAssetComponent } from './components/fams/planner/ppm/ppm-asset/ppm-asset.component';
import { PpmLocationComponent } from './components/fams/planner/ppm/ppm-location/ppm-location.component';
import { PpmPlannerComponent } from './components/fams/planner/ppm/add-ppm-planner/add-ppm-planner.component';
import { PpmPageComponent } from './components/fams/planner/ppm/ppmpage/ppm-page.component';
import { SearchPpmAssetComponent } from './components/fams/planner/ppm/search-result/asset/asset.component';
import { SearchPpmLocationComponent } from './components/fams/planner/ppm/search-result/location/location.component';
import { AddDailyInspectionComponent } from './components/fams/planner/daily-inspection/add-daily-inspection/add-daily-ins.component';
import { DailyInsLocationComponent } from './components/fams/planner/daily-inspection/daily-inspection-location/daily-ins-location.component';
import { DailyInsPageComponent } from './components/fams/planner/daily-inspection/dailyinspectionpage/daily-ins-page.component';
import { DailyInsAssetComponent } from './components/fams/planner/daily-inspection/pdaily-inspection-asset/daily-ins-asset.component';
import { SearchDailyInsAssetComponent } from './components/fams/planner/daily-inspection/search-result/asset/asset.component';
import { SearchDailyInsLocationComponent } from './components/fams/planner/daily-inspection/search-result/location/location.component';
import { PdmAssetComponent } from './components/fams/planner/predective-maintenance/pdm-asset/pdm-asset.component';
import { PdmLocationComponent } from './components/fams/planner/predective-maintenance/pdm-location/pdm-location.component';
import { PdmPageComponent } from './components/fams/planner/predective-maintenance/pdmpage/pdm-page.component';
import { SearchPdmAssetComponent } from './components/fams/planner/predective-maintenance/search-result/asset/asset.component';
import { SearchPdmLocationComponent } from './components/fams/planner/predective-maintenance/search-result/location/location.component';
import { AddPdmPlannerComponent } from './components/fams/planner/predective-maintenance/add-pdm/add-pdm.component';
import { CreateChecklistComponent } from './components/fams/checklist-n-sla/create-checklist/create-checklist.component';
import { SlaMappingForChecklistComponent } from './components/fams/checklist-n-sla/sla-mapping-for-checklist/sla-mapping-for-checklist.component';
import { ContractManagementForFacilityUserComponent } from './components/fams/contract-management-for-facility-user/contract-management-for-facility-user.component';
import { ChecklistContainerComponent } from './components/fams/checklist-n-sla/checklist-container/checklist-container.component';
import { ChecklistMaintanceTaskComponent } from './components/fams/checklist-n-sla/checklist-maintance-task/checklist-maintance-task.component';
import { ChecklistTaskItemComponent } from './components/fams/checklist-n-sla/checklist-task-item/checklist-task-item.component';
import { ChecklistAnswerTypeComponent } from './components/fams/checklist-n-sla/checklist-answer-type/checklist-answer-type.component';
import { ChecklistAnswerListComponent } from './components/fams/checklist-n-sla/checklist-answer-list/checklist-answer-list.component';
import { ChecklistCombindComponent } from './components/fams/checklist-n-sla/checklist-combind/checklist-combind.component';
import { DownloadAppComponent } from './components/fams/qr-code/download-app/download-app.component';
import { LicsenceCertificateComponent } from './components/fams/asset-register/licensce&certificate/licensce-certificate.component';
import { ErCaptureComponent } from './components/era/er-capture/er-capture.component';
import { ErReportDailyValueComponent } from './components/era/er-report-daily/er-report-daily.component';
import { ErTaskCodeGenerationComponent } from './components/era/er-task-code-generation/er-task-code-generation.component';
import { ParamterMappingComponent } from './components/era/paramter-mapping/paramter-mapping.component';
import { ErReportMonthlyComponent } from './components/era/er-report-monthly/er-report-monthly.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { NotificationCentreComponent } from './components/notification-centre/notification-centre.component';
import { CompleteionInfoComponentPdf } from './components/fams/work-order/work-order-query/completeion-info/completion-info-pdf.component';
import { WorkOrderReassignComponentPdf } from './components/fams/work-order/work-order-reassign/work-order-reassign-pdf.component';
import { ClosedWorkOrderReportComponent } from './components/reports/closed-work-order-report/closed-work-order-report.component';
import { CompanyWorkingHourComponent } from './components/company-working-hour/company-working-hour.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { AveragWOOutstandingReportComponent } from './components/reports/averag-wooutstanding-report/averag-wooutstanding-report.component';
import { WorkOrderReportForContractorComponent } from './components/reports/work-order-report-for-contractor/work-order-report-for-contractor.component';
import { WorkOrderReportForIBCCComponent } from './components/reports/work-order-report-for-ibcc/work-order-report-for-ibcc.component';
import { DatePipe } from '@angular/common';
import { WorkOrderVenderPerformanceComponent } from './components/reports/work-order-vender-performance/work-order-vender-performance.component';
import { WorkOrderSparePartsRequestComponent } from './components/reports/work-order-spare-parts-request/work-order-spare-parts-request.component';
import { WorkOrderSparePartsReturnComponent } from './components/reports/work-order-spare-parts-return/work-order-spare-parts-return.component';
import { WorkOrderSparePartsComponent } from './components/reports/work-order-spare-parts/work-order-spare-parts.component';
import { AssetSummeryComponent } from './components/asset-summery/asset-summery.component';
import { ScheduleWoSummeryComponent } from './components/schedule-wo-summery/schedule-wo-summery.component';
import { UnscheduleWoSummeryComponent } from './components/unschedule-wo-summery/unschedule-wo-summery.component';
import { InventorySummeryComponent } from './components/inventory-summery/inventory-summery.component';
import { ExternalFeedbackComponent } from './components/reports/external-feedback/external-feedback.component';
import { FeedbackReceivedPercentageComponent } from './components/reports/feedback-received-percentage/feedback-received-percentage.component';
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ProfileComponent,
        HeaderComponent,
        InfoComponent,
        AssetsManagmentComponent,
        LoginUserComponent,
        RagisterComponent,
        DashboardtableComponent,
        InputComponent,
        // CardComponent,
        RolepermissionComponent,
        UserroleComponent,
        UserShiftComponent,
        AssetClassificationComponent,
        AssetStandardizationComponent,
        ContractorVendorComponent,
        AssetTypeCodeClassificationComponent,
        BlocksComponent,
        LevelsComponent,
        AreaComponent,
        LocationComponent,
        SubLocationComponent,
        RolesPermissionTableGridComponent,
        FacilityRegisterationComponent,
        AssetRegistrationComponent,
        MaintenenceStatusComponent,
        WarrantyCoverageComponent,
        AttachmentsComponent,
        CalenderComponent,
        ListValueComponent,
        LookupComponent,
        ResetPasswordComponent,
        AssetRegisterComponent,
        QrCodeScanningComponent,
        AssetQrCodePrintingComponent,
        LocationQrCodePrintingComponent,
        SparePartsComponent,
        SparePartUpdateComponent,
        SparePartRequestComponent,
        SparePartReturnComponent,
        MonthlySparePartRegisterComponent,
        StockTakeActivityComponent,
        WorkOrderQueryComponent,
        RiseWorkRequestComponent,
        WorkOrderAssetComponent,
        WorkOrderOnLocationComponent,
        WorkOrderReassignComponent,
        SparePartReplacementComponent,
        WorkOrderAttactmentsComponent,
        CompleteionInfoComponent,
        WorkOrderInquiryComponent,
        OverallGridViewComponent,
        PpmComponent,
        DailyInspectionComponent,
        PredectiveMaintenanceComponent,
        PpmLoadBalancingComponent,
        PpmAssetComponent,
        PpmLocationComponent,
        PpmPlannerComponent,
        PpmPageComponent,
        SearchPpmAssetComponent,
        SearchPpmLocationComponent,
        AddDailyInspectionComponent,
        DailyInsLocationComponent,
        DailyInsPageComponent,
        DailyInsAssetComponent,
        SearchDailyInsAssetComponent,
        SearchDailyInsLocationComponent,
        AddPdmPlannerComponent,
        PdmAssetComponent,
        PdmLocationComponent,
        PdmPageComponent,
        SearchPdmAssetComponent,
        SearchPdmLocationComponent,

        CreateChecklistComponent,
        SlaMappingForChecklistComponent,
        ContractManagementForFacilityUserComponent,
        ChecklistContainerComponent,
        ChecklistMaintanceTaskComponent,
        ChecklistTaskItemComponent,
        ChecklistAnswerTypeComponent,
        ChecklistAnswerListComponent,
        ChecklistCombindComponent,
        DownloadAppComponent,
        LicsenceCertificateComponent,
        ErCaptureComponent,
        ErReportDailyValueComponent,
        ErTaskCodeGenerationComponent,
        ParamterMappingComponent,
        ErReportMonthlyComponent,
        TermsAndConditionsComponent,
        PrivacyComponent,
        NotificationCentreComponent,
        CompleteionInfoComponentPdf,
        WorkOrderReassignComponentPdf,
        ClosedWorkOrderReportComponent,
        CompanyWorkingHourComponent,
        AuditLogComponent,
        AveragWOOutstandingReportComponent,
        WorkOrderReportForContractorComponent,
        WorkOrderReportForIBCCComponent,
        WorkOrderVenderPerformanceComponent,
        WorkOrderSparePartsComponent,
        WorkOrderSparePartsRequestComponent,
        WorkOrderSparePartsReturnComponent,
        AssetSummeryComponent,
        ScheduleWoSummeryComponent,
        UnscheduleWoSummeryComponent,
        InventorySummeryComponent,
        ExternalFeedbackComponent,
        FeedbackReceivedPercentageComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        PDFModule,
        ExcelModule,
        LabelModule,
        LayoutModule,
        SchedulerModule,
        ButtonsModule,
        EditorModule,
        FileSelectModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ChartsModule,
        PDFExportModule,
        IntlModule,
        InputsModule,
        DropDownsModule,
        RouterModule.forRoot(drawerRoutes),
        NotificationModule,
        DateInputsModule,
        ToolsModule,
        MenuModule,
        DialogsModule,
        UploadsModule,
        BarcodesModule
    ],

    providers: [DatePipe,
        { provide: MessageService, useClass: CustomMessagesService },
        { provide: LOCALE_ID, useValue: 'en-US' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

