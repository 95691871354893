<div class="card m-3">
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Work Orders Report for IBCC – Spare Parts</h5>

    </div>
    <div class="card-body register-form-body">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Facility<span class="required">*</span></label>
                <kendo-dropdownlist [data]="facilityObj.filterData" [(ngModel)]="facility" class="form-control"
                    [textField]="'NAME'" [valueField]="'ID'">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5" style="text-align: center;width: 100%;">
                <button kendoButton primary="true" class="mr-2 px-3" style="margin-top: 35px;" (click)="fetchReport()">
                    Fetch Report
                </button>
            </div>
        </div>


    </div>
    <div *ngIf="noDataFound" style="margin:auto">No Data Found</div>
    <div *ngIf="showReport">
        <button kendoButton primary="true" (click)="download()" class="header-right-btn">
            Export
        </button>
        <kendo-pdf-export #pdfworkorder paperSize="A4" margin="1cm" [scale]="1">
            <div>

                <div class="py-4">
                    <kendo-grid [data]="report" [sortable]="false" [pageable]="false">
                        <kendo-grid-column *ngFor="let col of column" field={{col.field}} title={{col.title}}
                            width="{{col.width}}">
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </kendo-pdf-export>
    </div>
</div>