export class SparePart{
        id: number;
        stockType: string;
        stockTradeId: number;
        stockCategoryId: number;
        stockNo: string;
        uomId:number;
        isActive: true;
        createdOn: Date;
        sparePartDetails:Array<sparePartDetails>
        manufacturerID:number;
      
}

class sparePartDetails{
    id: number;
    sparePartId: number;
    partNo: string;
    partDescription: string;
    attachmentId: number;
    facilityId: number;
    partSourceId: number;
    binNo: string;
    minUnit: number;
    maxUnit: number;
    minPricePerUnit: number;
    maxPricePerUnit: number;
    statusId: 1;
    qoh: number;
    lifeOptionId: number;
    isActive: true;
    createdOn: Date;
    locationId:number;
}

export class sparePartError{
  stockTradeId:Boolean;
  stockType:Boolean;
  stockCategoryId:boolean;
  uomId:boolean
  stockNo:boolean
  constructor(status){
    this.  stockTradeId = status;
    this. stockType= status;
    this. stockCategoryId= status;
    this.  uomId= status;
    this.stockNo = status;
}
}



export class sparePartDetailsValue{
  id: number;
  sparePartId: number;
  partNo: string;
  partDescription: string;
  attachmentId: number;
  facilityId: number;
  partSourceId: number;
  binNo: string;
  minUnit: number;
  maxUnit: number;
  minPricePerUnit: number;
  maxPricePerUnit: number;
  statusId: 1;
  qoh: number;
  lifeOptionId: number;
  isActive: true;
  createdOn: Date
  locationId:number
  manufacturerID:number
}

export class sparePartDetailsError{
  partNo:Boolean;
  partDescription:Boolean;
  partSourceId:boolean;
  maxUnit:boolean;
  minUnit:boolean;
  constructor(status){
    this. partNo = status;
    this. partDescription= status;
    this. partSourceId= status;
    this.maxUnit = status;
    this.minUnit = status
}
}

export class SparePartUpdate{
ID:number;
SPAREPARTID:number;
SPAREPARTUPDATEID:number;
PARTNO:number;
DISPOSAL:boolean;
SUPPLIERRETURN:boolean;
MINUNIT:number;
MAXUNIT:number;
ESTIMATEDLIFESPAN:number;
EXPIRYDATE:Date;
QUANTITY:number;
ERPPURCHASECOST:number;
COSTPERPCS:number;
INVOICENO:number;
BPMID:number;
BINNO:number;
REMARKS:string;
CREATEDBY:number;
CREATEDDATE:Date
}

export class SparePartUpdateError{
  SPAREPARTID:Boolean;
  MINUNIT:Boolean;
  EXPIRYDATE:boolean;
  BINNO:boolean;
  QUANTITY:boolean;

  constructor(status){
    this.SPAREPARTID = status;
    this.MINUNIT = status;
    this.EXPIRYDATE= status;
    this.BINNO=status;
    this.QUANTITY=status
  
  }
}

export class SpareTakeActivity{
  ID:number;
  STOCKADJUSTMENTID:number;
  STOCKNO:number;
  UPDATIONDATE:Date;
  PARTNO:number;
  LOCATIONID:number;
  PHYSICALQUANTITY:number;
  VARIANCE:number;
  ADJUSTEDQUANTITY:number;
  REMARK:string;
  CreatedDate:Date;
 ModifiedBy:number;
  ModifiedDate:Date;
 CreatedBy:number
}



export class SpareTakeActivityError{
  STOCKNO:Boolean;
  LOCATIONID:Boolean;
  PHYSICALQUANTITY:boolean;
 constructor(status){
    this.STOCKNO = status;
    this.LOCATIONID = status;
    this.PHYSICALQUANTITY= status;
  }
}
export class SparePartReturn{
  ID:number;
  SPAREPARTREQUESTID:number;
  RETURNDATE:Date;
  FACILITYID:number
}
export class SparePartReturnDetails{
  ID:number;
  SPAREPARTRETURNID:number;
  RETURNDATE:Date;
  WORKORDERID:number;
  SPAREPARTDETAILID:number;
  QUANTITYRETUNED:number;
  QUANTITYDISPOSAL:number;
  REMARKS:string
}


export class SparePartReturnError{
  SPAREPARTREQUESTID:Boolean;
  RETURNDATE:Boolean;
 constructor(status){
    this.SPAREPARTREQUESTID = status;
    this. RETURNDATE = status;
  }
}

export class SparePartReturnDetError{
  QUANTITYRETUNED:Boolean;
  QUANTITYDISPOSAL:Boolean;
 constructor(status){
    this.QUANTITYRETUNED = status;
    this. QUANTITYDISPOSAL= status;
  }
}

export class SparePartDetailsValue{
  ID:number;
  SPAREPARTRETURNID:number;
  RETURNDATE:Date;
  WORKORDERID:number;
  SPAREPARTDETAILID:number;
  QUANTITYRETUNED:number;
  QUANTITYDISPOSAL:number;
  REMARKS:string;
  PARTNO:string;
  DESCRIPTION:string;
  BINNO:number;
  MODIFIEDBY:number;
  MODIFIEDDATE:Date;
  CREATEDBY:number;
  CREATEDDDATE:Date

}

export class SparePartRequest {
  ID:number;
  MATERIALREQUESTNO:Date;
  TICKETID:number;
  REQUESTDATE:Date;
  REQUESTSTAUSID:number;
  APPROVEDBY:number;
  APPROVEDDATE:Date;
  FACILITYID:number;
  APPROVALSTATUS:string;
  CREATEDBY:number;
  CREATEDDATE:Date;
  MODIFIEDBY:number;
  MODIFIEDDATE:Date;
  NAMECREATEDBY:string;
  NAMEMODIFIEDBY:string
}

export class SparePartRequestValue{
  ID:number;
  MATERIALREQUESTNO:Date;
  TICKETID:number;
  REQUESTDATE:Date;
  REQUESTSTAUSID:number;
  APPROVEDBY:number;
  APPROVEDDATE:Date;
  FACILITYID:number;
  APPROVALSTATUS:boolean;
  CREATEDBY:number;
  CREATEDDATE:Date;
  MODIFIEDBY:number;
  MODIFIEDDATE:Date;
  NAMECREATEDBY:string;
  NAMEMODIFIEDBY:string
  
}

export class SparePartRequestError{
  REQUESTDATE:Boolean;
  REQUESTSTAUSID:Boolean;
  TICKETID:Boolean
 constructor(status){
    this.REQUESTDATE = status;
    this.REQUESTSTAUSID = status;
    this.TICKETID= status;
  }
}

export class SparePartReqDetails{
ID:number;
SPAREPARTREQUESTID:number;
SPAREPARTDETAILID:number;
REMARK:string;
SPAREPARTDETAIL:string
}