<div class="card m-3">
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Closed Work Order Summary</h5>

    </div>
    <div class="card-body register-form-body">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Facility<span class="required">*</span></label>
                <kendo-dropdownlist [data]="facilityObj.filterData" [(ngModel)]="facility" class="form-control"
                    [textField]="'NAME'" [valueField]="'ID'">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Work Order Type<span class="required">*</span></label>
                <div style="width: 100%">
                    <kendo-dropdownlist [data]="workOrderTypeObj.filterData" [(ngModel)]="woType" class="form-control"
                        [textField]="'name'" [valueField]="'id'">
                    </kendo-dropdownlist>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>From Date<span class="required">*</span></label>
                <kendo-datepicker [format]="format" [(ngModel)]="fromDate">
                </kendo-datepicker>
            </div>
            <div class="form-group col-5">
                <label>To date<span class="required">*</span></label>
                <kendo-datepicker [format]="format" [(ngModel)]="toDate">
                </kendo-datepicker>
            </div>
        </div>
        <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton primary="true" class="mr-2 px-3" (click)="fetchReport()">
                Fetch Report
            </button>
        </div>
    </div>

    <div *ngIf="noDataFound" style="margin:auto;">No Data Found</div>

    <div class="card-body register-form-body" *ngIf="showReport">
        <div class="form-row">
            <div class="form-group col-5">
                <button kendoButton (click)="pdfworkorder.saveAs('ClosedWorkOrderSummaryReport.pdf')" primary="true">
                    Save As PDF...
                </button>
            </div>
            <div class="form-group col-5">
                <button kendoButton  (click)="download()" class="header-right-btn" primary="true" style="background-color: #0b4d8b !important;">
                    Export To Excel
                </button>
            </div>
        </div>

        <kendo-pdf-export #pdfworkorder paperSize="A4" margin="1cm" [scale]="0.7">
            <div class="container">
                <div style="display: flex;justify-content: space-between;padding: 1rem;">
                    <h5><b>Closed Work Order Summary</b></h5>
                </div>
                <div class="py-4">
                    <div class="form-row">
                        <div class="col-4">
                            <label>Plant / Facility</label>
                        </div>
                        <div class="col-5">
                            <label>{{report.plant}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Period</label>
                        </div>
                        <div class="col-5">
                            <label>{{convertLocalDateString(fromDate)}} TO {{convertLocalDateString(toDate)}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Work Order Type</label>
                        </div>
                        <div class="col-5">
                            <label>{{report.workOrderType}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Total No of Work Order</label>
                        </div>
                        <div class="col-5">
                            <label>{{report.totalWorkOrders}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Total No of Work Order Open</label>
                        </div>
                        <div class="col-5">
                            <label>{{report.totalOpenWorkOrders}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Total No of Work Order Closed</label>
                        </div>
                        <div class="col-5">
                            <label>{{report.totalClosedWorkOrders}}</label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-4">
                            <label>Percentage of Closed Work Order</label>
                        </div>
                        <div class="col-5">
                            <!-- <label>{{report.percentClosedWorkOrders}}</label> -->
                            <label>{{((report.totalClosedWorkOrders/report.totalWorkOrders)*100).toFixed(2)}} %</label>
                        </div>
                    </div>
                </div>
                <div class="py-4">

                    <!-- <kendo-grid [data]="report.lstCLostWOSummary" [sortable]="false" [pageable]="false">
                        <kendo-grid-column *ngFor="let col of column" width="{{col.width}}" field={{col.field}}
                            title={{col.title}}>
                        </kendo-grid-column>
                    </kendo-grid> -->
                    <table style="width: 100%">
                        <thead>
                            <tr>
                                <th style="text-align: left;">Work Center Description</th>
                                <th>No. of Work Order Open</th>
                                <th>No. of Work Order Closed</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of report.lstCLostWOSummary">
                                <td style="text-align: left;">{{item?.workCenterDesc}}</td>
                                <td>{{item?.openWorkOrders}}</td>
                                <td>{{item?.closedWorkOrders}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </kendo-pdf-export>
    </div>
</div>