import { SparePartUpdate,SparePartUpdateError,sparePartError,SpareTakeActivityError, SparePart, SpareTakeActivity,SparePartRequest,SparePartRequestError, sparePartDetailsValue, sparePartDetailsError, SparePartReturn, SparePartReturnError, SparePartReturnDetError, SparePartReturnDetails} from "src/app/models/spare.model";
export let SparePartUpdateValidation = (data:SparePartUpdate, type) => {
    let error = new SparePartUpdateError(false)
    if (type === "init")
        return error;
    if (!data.BINNO) {
        error.BINNO = true
    }
    if (!data.EXPIRYDATE) {
        error.EXPIRYDATE = true
    }
    if (!data.MINUNIT) {
        error.MINUNIT = true
    }
    if (!data.SPAREPARTID) {
        error.SPAREPARTID = true
    }

    if (!data.QUANTITY) {
        error.QUANTITY = true
    }
 
   return error;
}

export let SpareTakeActivityValidation = (data:SpareTakeActivity, type) => {
    let error = new SpareTakeActivityError(false)
    if (type === "init")
        return error;
    if (!data.LOCATIONID) {
        error.LOCATIONID = true
    }
    if (!data.PHYSICALQUANTITY) {
        error.PHYSICALQUANTITY = true
    }
    if (!data.STOCKNO) {
        error.STOCKNO = true
    }
  return error;
}

export let SpareRequestValidation = (data:SparePartRequest, type) => {
    let error = new SparePartRequestError(false)
    if (type === "init")
        return error;
    if (!data.TICKETID) {
        error.TICKETID = true
    }
    if (!data.REQUESTDATE) {
        error.REQUESTDATE = true
    }
    if (!data.REQUESTSTAUSID) {
        error.REQUESTSTAUSID= true
    }
  return error;
}

export let SparePartValidation = (data:SparePart, type) => {
    let error = new sparePartError(false)
    if (type === "init")
        return error;
    if (!data. stockTradeId) {
        error. stockTradeId = true
    }
    if (!data.stockNo) {
        error.stockNo = true
    }
    if (!data. stockType) {
        error. stockType = true
    }
    if (!data. stockCategoryId) {
        error. stockCategoryId= true
    }
    if (!data.uomId) {
        error.uomId= true
    }
  return error;
}


export let SparePartRequestValidation = (data: sparePartDetailsValue, type) => {
    let error = new sparePartDetailsError(false)
    if (type === "init")
        return error;
    if (!data.  partNo) {
        error.  partNo = true
    }
    if (!data. partDescription) {
        error. partDescription = true
    }
    if (!data. partSourceId) {
        error. partSourceId= true
    }
    if (!data. maxUnit) {
        error. maxUnit= true
    }
    if (!data. minUnit) {
        error. minUnit= true
    }
  return error;
}


export let SparePartReturnValidation = (data:SparePartReturn, type) => {
    let error = new SparePartReturnError(false)
    if (type === "init")
        return error;
    if (!data. SPAREPARTREQUESTID) {
        error. SPAREPARTREQUESTID = true
    }
    if (!data.RETURNDATE) {
        error.RETURNDATE = true
    }
  return error;
}

export let SparePartReturnDetailValidation = (data:SparePartReturnDetails, type) => {
    let error = new SparePartReturnDetError(false)
    if (type === "init")
        return error;
    if (!data. QUANTITYRETUNED) {
        error. QUANTITYRETUNED = true
    }
    if (!data.QUANTITYDISPOSAL) {
        error.QUANTITYDISPOSAL= true
    }
  return error;
}