export class AssetStandardization{
  id:number;
  assetTypeId:number;
  assetModelId:number;
  assetManufacturerId:number;
  createdDate:string;
  modifiedDate:string;
  assetStatusId:number
}
export class AssetStandardizationError{
    id:Boolean;
    assetTypeId:Boolean;
    assetModelId:Boolean;
    assetStatusId:Boolean;
    assetManufacturerId:Boolean;
   constructor(status){
        this.id=status;
        this.assetTypeId=status;
        this.assetModelId=status;
        this.assetStatusId=status;
        this.assetManufacturerId=status;
    }
}



class AssetTypeMaintenanceFlags{
   id:number=0;
   assetTypeId:number=0;
   maintenanceFlagId:number=0;
}

export class AssetType{
    id:number;
    assetClassificationId:number;
    code:string;
    description:string;
    expectedLifeSpan:number;
    ageLessThan5Yrs: number;
    age5To10Yrs: number;
    ageGreaterThan10Years:number;
    assetTypeMaintenanceFlags:Array<AssetTypeMaintenanceFlags>
}

export class AssetTypeError{
    id:Boolean;
    assetClassificationId:Boolean;
    code:Boolean;
    description:Boolean;
    maintenanceFlagId:Boolean;
    expectedLifeSpan:Boolean;
    ageLessThan5Yrs: Boolean;
    age5To10Yrs: Boolean;
    ageGreaterThan10Years:Boolean;
    constructor(status){
        this.id=status;
        this.assetClassificationId=status;
        this.code=status;
        this.description=status;
        this.maintenanceFlagId=status;
        this.expectedLifeSpan=status;
        this.ageLessThan5Yrs=status;
        this.age5To10Yrs=status;
        this.ageGreaterThan10Years=status;
    }
}


export class AssetClassification{
        code:string;
        description:string
        assetStatusId: number
        remarks:string
        id:number

        constructor(){
            this.remarks =""
        }
}
export class AssetClassificationError{
    description:boolean
      constructor(status){
        this.description = status
    }
}