import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"

// let obj = { 
//       "customerName": "Ram Chandra", 
//       "genderId": 2, 
//       "id": 0, 
//       "statusId": 1, 
//       "staffName": "rram", 
//       "companyEmail": "sahu1205.ramchandra@gmail.com", 
//       "userName": "sahu1205.ramchandra@gmail.com", 
//       "mobileNo": "9031144771", 
//       "categoryId": 1, 
//       "contractorId": null, 
//       "designationId": 7, 
//       "nationalityId": 1, 
//       "locationID": 46, 
//       "companyName": "KLCC", 
//       "facilities": [
//           { "id": 0, "employeeGlobalId": 0, "facilityId": 14 }
//         ], 
//      "userRoles": [
//          { "loggedInUserId": 1, "id": 0, "roleId": 2, 
//            "rolesModulePermissions": [
//                { "loggedInUserId": 1, "id": 0, "roleId": 2, "permissionValue": 0 }
//             ] 
//         }], 
//     "competencies": [
//         { "loggedInUserId": 1, "id": 0, "employeeGlobalId": 1, "skillId": 1, "createdOn": "2022-01-06T15:55:47.135Z" }
//     ], 
//     "employeeSpecialities": [
//         { "id": 0, "employeeGlobalId": "1", "specialityId": 1, "createdBy": "1", "createdDate": "2022-01-06T15:55:47.136Z", "modifiedBy": "1", "modifiedDate": "2022-01-06T15:55:47.136Z" }], 
//     "services": [{ "id": 0, "loggedInUserId": "1", "serviceTypeId": 3 }], 
//     "loggedInUserId": 1, 
//     "isDeleted": false, 
//     "password": "xuzabscd" 
// }

export const userObjMap = {
    "Staff Name": "staffName",
    "Username (email)": "userName",
    "Gender": "genderId",
    "Office No.": "",
    "Company Email": "companyEmail",
    "Mobile No.": "",
    "Login User Type": "",
    "Status": "",
    "Designation": "",
    "Nationality": "",
    "Contractor": "",
    "Central Pool": "",
    "Grade": "",
    "Competency": "",
    "Speciality": "",
    "Services": "",
    "Labour Cost Per Hour": "",
    "Location": "",
    "Company Name": "",
    "Customer": "",
    "User Role": "",
    "All Facilities": "",
    "Selected Facilities": "",
    "Select User Role": ""
}


function resversKey(objR){
    let newObj={};
    for(let i in objR){
       newObj[objR[i]]=i;
    }
    return newObj;
}
export function addBlukUser(
    userList,genderObj,statusObj,contectorObj,designationObj,nationalObj,
    locationObj,facilityObj,roleObj,skillObj,specialitiesObj,
    servicesObj
    ) 
  {
      genderObj=resversKey(genderObj);
      statusObj=resversKey(statusObj);
      contectorObj=resversKey(contectorObj);
      designationObj=resversKey(designationObj);
      nationalObj=resversKey(nationalObj);
      locationObj=resversKey(locationObj);
      facilityObj=resversKey(facilityObj);
      roleObj=resversKey(roleObj);
      skillObj=resversKey(skillObj);
      specialitiesObj=resversKey(specialitiesObj);
      servicesObj=resversKey(servicesObj);
      console.log(genderObj);
    let empId=parseInt(getCookie(userid));
    let userObjList = userList.map((d) => {
        let obj = { 
            "customerName": d["Company Name"], 
            "genderId": genderObj[d["Gender"]], 
            "id": 0, 
            "statusId": d["Status"], 
            "staffName": d["Staff Name"], 
            "companyEmail": d["Company Email"], 
            "userName": d["Company Email"], 
            "mobileNo": d["Mobile No."], 
            "categoryId": 1, 
            "contractorId": contectorObj[d["Contractor"]], 
            "designationId": designationObj[d["Designation"]], 
            "nationalityId": nationalObj[d["Nationality"]], 
            "locationID": locationObj[d["Location"]], 
            "companyName": "KLCC", 
            "facilities": [
               // { "id": 0, "employeeGlobalId": 0, "facilityId": 14 }
              ], 
           "userRoles": [
              // { "loggedInUserId": 1, "id": 0, "roleId": 2}
            ], 
          "competencies": [
            //  { "loggedInUserId": 1, "id": 0, "employeeGlobalId": 1, "skillId": 1, "createdOn": "2022-01-06T15:55:47.135Z" }
          ], 
          "employeeSpecialities": [
              //{ "id": 0, "employeeGlobalId": "1", "specialityId": 1, "createdBy": "1", "createdDate": "2022-01-06T15:55:47.136Z", "modifiedBy": "1", "modifiedDate": "2022-01-06T15:55:47.136Z" }
            ], 
          "services": [
              //{ "id": 0, "loggedInUserId": "1", "serviceTypeId": 3 }
            ], 
          "loggedInUserId": 1, 
          "isDeleted": false, 
          "password": "xuzabscd" 
      }
      if(d["Selected Facilities"] && facilityObj[d["Selected Facilities"]]){
        obj.facilities.push(
            {
                 "id": 0, "employeeGlobalId": empId, "facilityId":facilityObj[d["Selected Facilities"]]  
            }
        )
      }
      if(d["Select User Role"] && roleObj[d["Select User Role"]]){
          obj.userRoles.push(
            { "loggedInUserId": empId, "id": 0, "roleId":roleObj[d["Select User Role"]]}
          )
      }
      if(d["Competency"] && skillObj[d["Competency"]]){
        obj.competencies.push(
            { "loggedInUserId": empId, "id": 0, "employeeGlobalId": empId, "skillId": skillObj[d["Competency"]]}
        )
      }
      if(d["Speciality"] && specialitiesObj[d["Speciality"]]){
       obj.employeeSpecialities.push(
        { "id": 0, "employeeGlobalId": empId, "specialityId": specialitiesObj[d["Speciality"]] }
       )
      }
      if(d["Services"] && servicesObj[d["Services"]]){
        obj.services.push(
            { "id": 0, "loggedInUserId": empId, "serviceTypeId":servicesObj[d["Services"]]}
        )
      }
      return obj;
    })
    return userObjList;
}