<div class="card m-3"   >
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Asset Type Code Details</h5>
        <div>      
          <button kendoButton *ngIf="permission && permission.isCreate" primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> Asset Type Code Details
          </button>
        </div>
    </div>
    <div   class="card-body register-form-body">
        <!-- <form [formGroup]="Asset_type_cl" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col-6">
                    <label>Asset Classification Code</label>
                    <input kendoTextBox formControlName="asset_code" class="form-control"/>
                </div>
                <div class="form-group col-6">
                    <label>Asset Classification Description</label>
                    <input kendoTextBox formControlName="asset_dis" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label>Asset Type Code</label>
                    <input kendoTextBox formControlName="asset_cla_dis" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Type Description</label>
                    <input kendoTextBox formControlName="asset_type_code" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Maintenence Flag</label>
                    <kendo-dropdownlist formControlName="maintenence_f"  [data]="maintenence_f" class="form-control">
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Equipment Function Description</label>
                    <input kendoTextBox formControlName="equip_fun_d" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Life Expectency</label>
                    <input kendoTextBox formControlName="life_ex" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Expected Life Span</label>
                    <input kendoTextBox formControlName="exp_l_s" class="form-control"/>
                </div>
            </div>
            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">QAP Details</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>QAP Asset Service</label>
                    <input kendoTextBox formControlName="qap_asswt_ser" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>PPM Completion</label>
                    <input kendoTextBox formControlName="ppm_compl" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Equipment Uptime</label>
                    <input kendoTextBox formControlName="equip_up" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>QAP Uptime Target</label>
                    <input kendoTextBox formControlName="qap_up_tar" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Effective Form</label>
                    <input kendoTextBox formControlName="effe_form" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Effective To</label>
                    <input kendoTextBox formControlName="effec_to" class="form-control"/>
                </div>
            </div>
            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">Uptime Target</h3>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Age < 5 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_5" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>Asset Age > 10 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_10" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Age 5 - 10 Yrs (%)</label>
                    <input kendoTextBox formControlName="asset_age_5g" class="form-control"/>
                </div>
                <div class="form-group col-5">
                </div>
            </div>

            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">Variation Date Detail</h3>
                <div class="form-group float-right">
					<label class="k-label pr-4 mr-2">Variation Date Details Required &nbsp;&nbsp;
						<input type="checkbox" kendoCheckBox />
					</label>
				</div>
            </div>
           
        </form> -->
        
        <app-table-grid
            [data]="gridDataassettype"
            [column]="column_asset_type"
            [size]="10"
            [editFlag] ="permission && permission.isUpdate"
            [deleteFlag] ="permission && permission.isDelete"
        ></app-table-grid>
      
        <!-- <div class="text-center mb-4 mt-4">
            <button kendoButton primary="true" class="mr-2">Save</button>
            <button kendoButton primary="true" class="mr-2">Save and add new</button>
            <button kendoButton type="reset" (click)="onReset()">Reset</button>
        </div> -->
       
    </div>
  </div>
  <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

            <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                <kendo-tabstrip-tab  title="Basic Details" [selected]="currentTab=='basic'">
                  <ng-template kendoTabContent >
                    <div class="p-4"> 
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label>Asset Classification Code<span class="required">*</span></label>
                                <!-- <input kendoTextBox formControlName="asset_code" class="form-control"/> -->
                                <div style="width: 100%;">
                                    <kendo-dropdownlist 
                                                [data]="filterdClassificationData" 
                                                class="form-control"
                                                [value]="selectedAsset"
                                                textField="code"
                                                valueField="id"
                                                (valueChange)="selectedAsset=$event"
                                                [filterable]="true"
                                                (filterChange)=" handleasset_clFilter($event)"
                                        >
                                        </kendo-dropdownlist>
                                        <kendo-formerror *ngIf="error.assetClassificationId">*Required</kendo-formerror>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <label>Asset Classification Description</label>
                                <div style="width: 100%;">
                                    <input [(ngModel)]="selectedAsset.description" kendoTextBox class="form-control" disabled/>
                                
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label>Asset Type Code</label>
                                <div style="width: 100%;">
                                    <input kendoTextBox [(ngModel)]="formData.code"  class="form-control" [disabled] ="assetTypeDis" (blur)="checkedUniqueCode()"/>
                                    <kendo-formerror *ngIf="isunique">Asset Type Code already exist.</kendo-formerror>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <label>Asset Type Description</label>
                                <input kendoTextBox   [(ngModel)]="formData.description" class="form-control"/>
                            </div>
                        </div>
                        
                        <div class="form-row">
                            <!-- <div class="form-group col-6">
                                <label>Life Expectency</label>
                                <input kendoTextBox formControlName="life_ex" class="form-control"/>
                            </div> -->
                        
                        <div class="form-group col-6">
                                <label>Maintenence Flag<span class="required">*</span></label>
                                <div style="width: 100%;">
                                        <kendo-multiselect 
                                            [data]="maintenence_f" 
                                            [value]="selectedMaintanceFlag"
                                            textField="name"
                                            valueField="id"
                                            (valueChange)="selectedMaintanceFlag=$event"
                                        >
                                        </kendo-multiselect>
                                        <kendo-formerror *ngIf="error.maintenanceFlagId">*Required</kendo-formerror>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <label>Expected Life Span</label>
                                <input type="number"  [min]="0" kendoTextBox [(ngModel)]="formData.expectedLifeSpan"  class="form-control"/>
                                    
                            
                            </div>
                        </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>
                <!-- <kendo-tabstrip-tab title="QAP Details">
                  <ng-template kendoTabContent>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label>QAP Asset Service</label>
                            <input kendoTextBox formControlName="qap_asswt_ser" class="form-control"/>
                        </div>
                        <div class="form-group col-6">
                            <label>PPM Completion</label>
                            <input kendoTextBox formControlName="ppm_compl" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label>Equipment Uptime</label>
                            <input kendoTextBox formControlName="equip_up" class="form-control"/>
                        </div>
                        <div class="form-group col-6">
                            <label>QAP Uptime Target</label>
                            <input kendoTextBox formControlName="qap_up_tar" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label>Effective From</label>
                            <input kendoTextBox formControlName="effe_form" class="form-control"/>
                        </div>
                        <div class="form-group col-6">
                            <label>Effective To</label>
                            <input kendoTextBox formControlName="effec_to" class="form-control"/>
                        </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab> -->
                <kendo-tabstrip-tab title="Uptime Target" [selected]="currentTab=='uptime'">
                  <ng-template kendoTabContent>
                    <div class="p-4"> 
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label>Asset Age < 5 Yrs (%)</label>
                            <div style="width: 100%;">
                                <input type="number" [min]="0" kendoTextBox [(ngModel)]="formData.ageLessThan5Yrs"  class="form-control"/>
                               
                            </div>
                        </div>
                        <div class="form-group col-6">
                            <label>Asset Age 5 - 10 Yrs (%)</label>
                            <div style="width: 100%;">
                                <input type="number" [min]="0" kendoTextBox [(ngModel)]="formData.age5To10Yrs" class="form-control"/>

                            </div>
                
                        </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                              
                                <label>Asset Age > 10 Yrs (%)</label>
                                <div style="width: 100%;">
                                    <input type="number" [min]="0" kendoTextBox [(ngModel)]="formData.ageGreaterThan10Years"  class="form-control"/>
                                
                                </div>

                            </div>
                            <div class="form-group col-6">
                            </div>
                        </div>
                  </div>
                  </ng-template>
                </kendo-tabstrip-tab>
                <!-- <kendo-tabstrip-tab title="Variation Date Details">
                  <ng-template kendoTabContent>
                    <div class="px-3 pt-4 pb-4">
                        <div class="form-group">
                            <label class="k-label pr-4 mr-2">Variation Date Details Required &nbsp;&nbsp;
                                <input type="checkbox" kendoCheckBox />
                            </label>
                        </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab> -->
               
              </kendo-tabstrip>
              <!-- <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Classification Code</label>
                    <input kendoTextBox formControlName="asset_code" class="form-control"/>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Classification Description</label>
                    <input kendoTextBox formControlName="asset_dis" class="form-control"/>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Type Code</label>
                    <input kendoTextBox formControlName="asset_cla_dis" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Type Description</label>
                    <input kendoTextBox formControlName="asset_type_code" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Maintenence Flag</label>
                    <kendo-dropdownlist formControlName="maintenence_f"  [data]="maintenence_f" class="form-control">
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Equipment Function Description</label>
                    <input kendoTextBox formControlName="equip_fun_d" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Life Expectency</label>
                    <input kendoTextBox formControlName="life_ex" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Expected Life Span</label>
                    <input kendoTextBox formControlName="exp_l_s" class="form-control"/>
                </div>
            </div> -->
            
            <!-- <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">QAP Details</h3>
            </div>
            
            <div class="px-3 pt-4 pb-4">
                <h3 class="font-weight-bold h3-heading">Uptime Target</h3>
            </div> -->
        <kendo-dialog-actions [layout]="actionsLayout">
            <button kendoButton (click)="onDialogClose()">Cancel</button>
            <button kendoButton *ngIf="type=='new'"  primary="true" (click)="onReset()">
                Reset
            </button>
            <button kendoButton *ngIf="type=='new'"  primary="true" (click)="submitData()">
                Save
            </button>
            <button kendoButton *ngIf="type=='update'"  primary="true" (click)="submitData()">
                Update
            </button>
        </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeRole()">Delete</button>
    
    </kendo-dialog-actions>
  </kendo-dialog>
