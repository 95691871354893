import { Component, Input, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { GroupDescriptor, process, State } from "@progress/kendo-data-query";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"
@Component({
  selector: 'app-table-grid',
  templateUrl: './table-grid.component.html',
  styleUrls: ['./table-grid.component.scss']
})
export class TableGridComponent implements OnChanges {
  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() action:Boolean=true;
  @Input() checkbox:Boolean =false;
  @Input() deleteFlag:Boolean=true;
  @Input() editFlag:Boolean=true;
  @Input() fileFlag:Boolean=false;
  @Input() approved:Boolean=false;
  @Input() qrcode:Boolean=false;
  @Input() addAsset:Boolean=false;
  @Input() unlockFlag:Boolean=false;
  @Input() reject:Boolean=false;
  @Input() viewFlag:Boolean=false;
  @Input() renewFlag:Boolean = false;
  @Input() assetFlag:Boolean = false;
  @Input() deleteKey:string="";
  @Input() renewKey:string ="";
  @Input() renewKeyValue:string ="";
  @Input() sparePartFlag:Boolean=false;
  @Input() groups: GroupDescriptor[] = [];
  @Input() filters:Array<any>=[]
  @Input() exportFlag:Boolean=false;
  @Input() DownloadPDFFlag:Boolean = false;
  @Input() id:string=""
  public elememtId="element"+Math.random();
  public gridData:GridDataResult;
  public state: State = {
    skip: 0,
    group:[],
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.action){
      this.action=changes.action.currentValue
    }
    if(changes.deleteFlag){
      this.deleteFlag=changes.deleteFlag.currentValue
    }
    if(changes.fileFlag){
      this.fileFlag=changes.fileFlag.currentValue
    }
    if(changes.assetFlag){
      this.assetFlag=changes.assetFlag.currentValue
    }
    if(changes.approved){
      this.approved=changes.approved.currentValue
    }
    if(changes.reject){
      this.reject=changes.reject.currentValue
    }
    if(changes.deleteKey){
    
      this.deleteKey=changes.deleteKey.currentValue
    }
    if(changes.renewKey){
    
      this.renewKey=changes.renewKey.currentValue
    }
    if(changes.renewKeyValue){
    
      this.renewKeyValue=changes.renewKeyValue.currentValue
    }
    if(changes.checkbox){
      this.checkbox=changes.checkbox.currentValue
    }
    if(changes.qrcode){
      this.qrcode=changes.qrcode.currentValue
    }
    if(changes.addAsset){
      this.addAsset= changes.addAsset.currentValue
    }
    if(changes.unlockFlag){
      this.unlockFlag= changes.unlockFlag.currentValue
    }
    if(changes.editFlag){
      this.editFlag= changes.editFlag.currentValue
    }
    if(changes.viewFlag){
      this.viewFlag= changes.viewFlag.currentValue
    }
    if(changes.renewFlag){
      this.renewFlag = changes.renewFlag.currentValue
    }
    if(changes.sparePartFlag){
      this.sparePartFlag=changes.sparePartFlag.currentValue;
    }
    if(changes.groups){
      this.groups=changes.groups.currentValue;
      this.state.group=this.groups;
    }
    if(changes.filters){
      this.filters=changes.filters.currentValue
      this.state.filter.filters=[...this.filters];
      this.gridData = process(this.data, this.state);
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }
  
  

  ngOnInit(): void {
    if(this.groups && this.groups.length>0){
      this.state.group=this.groups;
     // this.state.take=null;
    }
  }
  editHandler(e){
    dispatchEvent(EVENT_NAME.edit_column+this.id,e);
  }
  removeHandler(e){
    dispatchEvent(EVENT_NAME.remove_column+this.id,e);
  }
  addAttachment(e){
    dispatchEvent(EVENT_NAME.attachment_column+this.id,e);
  }
 addQrCode(e){
    dispatchEvent(EVENT_NAME.qrCode_attachment+this.id,e);
  }

  addAssetReg(e){
    dispatchEvent(EVENT_NAME.addAssetReg_attachment+this.id,e); 
  }
  unlockData(e){
    dispatchEvent(EVENT_NAME.unlock_data+this.id,e); 
  }

  viewData(e){
    dispatchEvent(EVENT_NAME.view_column+this.id,e); 
  }

  viewAssetData(e){
    dispatchEvent(EVENT_NAME.view_asset_column+this.id,e);
  }

  public onSelect(e) {
    dispatchEvent(EVENT_NAME.wo_id+this.id,e.selectedRows[0].dataItem.id)
  }

  approvedData(type,e){
    let obj ={
      type:type,
      data:e
    }
    dispatchEvent(EVENT_NAME.approved_column+this.id,obj); 
  }

  rejectData(type,e){
    let obj ={
      type:type,
      data:e
    }
    dispatchEvent(EVENT_NAME.reject_column+this.id,obj); 
  }

  downloadPDF(type:string,e:any){
    let obj ={type:type,dataItem:e};
    dispatchEvent(EVENT_NAME.download_pdf+this.id,obj); 
  }

  checkboxEvent(e,d){
     let obj ={
         status:e.target.checked,
         data:d
     }
    dispatchEvent(EVENT_NAME.checkbox_column+this.id,obj);
  }

  changeDate(e){
    dispatchEvent(EVENT_NAME.renew_column+this.id,e); 
  }

  changeRenewDate(e){
    let obj ={
      action:"renew",
      data :e
    }
    dispatchEvent(EVENT_NAME.renew_column+this.id,obj); 
  }
}
