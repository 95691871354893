import { Component, OnInit } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {MaintenaceChecklistTask,MaintenaceChecklistTaskObj} from "src/ApiModel/Model/MaintanceChecklistTask"
import { eventListner, EVENT_NAME } from 'src/event';
@Component({
  selector: 'app-checklist-maintance-task',
  templateUrl: './checklist-maintance-task.component.html',
  styleUrls: ['./checklist-maintance-task.component.scss']
})
export class ChecklistMaintanceTaskComponent implements OnInit {

  constructor() { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Maintenance Task"
  public model:Model=new Model();
  public data=new MaintenaceChecklistTask();
  public task:any=[]
  public column=[
    {field:"checkListObj.checkListNo",title:"Checklist No",isFilter:true,width:200},
    {field:"DESCRIPTION",title:"Description", isFilter:true,width:200},
  ]
  ngOnInit(): void {
    this.model.getData(ModelApi.ChekList)
    .then((result)=>{
      this.getData();
    })
    .catch((err)=>{
  
    })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
  }
  saveData(){
    if(this.type==="new"){
       this.data.saveData(this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
       .then((result)=>{
            console.log("save",result)
            this.getData()
            this.opened=false;
       })
       .catch((err)=>{
          console.log("err",err)
       })
    }
    else if(this.type=="update"){
      this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
      .then((result)=>{
           console.log("save",result)
           this.getData()
           this.opened=false;
      })
      .catch((err)=>{
         console.log("err",err)
      })
    }
  }
  reset(){

  }

  getData(){
    this.model.getData(ModelApi.MaintenaceTask)
    .then((result)=>{
      this.task=this.model.maintenaceTask.alldata.map((d)=>{
        d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
        return d;
       })
    })
  }
  editColumn(d){
    this.dailogTitle="Update Maintenance Task"
     this.data.ID=d.dataItem.ID;
     this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
     this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }
}
