import { Component, OnInit } from '@angular/core';
;
import { sampleProducts } from 'src/app/Data/table';
import { eventListner, EVENT_NAME } from "src/event";
import { ContractorValidation } from 'src/validator/contractor-vendor';
import { ContractorVendor } from '../../models/contractor-vendor.model';
import HttpClient from 'src/Util/ApiHandling';
import { NotificationService } from "@progress/kendo-angular-notification";
import { environment } from 'src/environments/environment';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"

@Component({
  selector: 'app-contractor-vendor',
  templateUrl: './contractor-vendor.component.html',
  styleUrls: ['./contractor-vendor.component.scss']
})
export class ContractorVendorComponent {
  public opened = false;
  public attachmentOpened = false;
  public removeDialogOpen = false;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public stateObj = {};
  public countryObj = {};
  public statusObj = {};
  public specialisation_obj = {};
  public skillValue = [];
  public removeBpm_Id_Data;
  public contractorType = [];
  public statusDropDown = [];
  public allStateData=[]
  public parentId=-1;
  public moduleId=-1;
  public permission:Permission=new Permission();
  public specializationDetailFlag =[]
  public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
  public attechmentDoc=new AttechmentObj()
  public attechForm:Attechment=new Attechment();
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public attechId:number=-1;
  public employeId=parseInt(getCookie("userId")||"1")
  private bpmSkillId;
  public bpmSkillIdValue;
  public importopened=false;
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  public contectColumn=[
    { 
      field: "name", 
      title: "Contact Person",
      errorField:"name_error",
      validation:(val)=>{
          if(val && val.length>=3)
            return ""
          else 
            return "*Required"
      }, 
    isFilter: false, 
    width: 140 
  },
    { 
        field: "designation", 
        title: "Designation", 
        isFilter: false, 
        width: 140,
        errorField:"designation_error",
        validation:(val)=>{
          if(val && val.length>=3)
            return ""
          else 
            return "*Required"
         }, 
      },
    { 
      field: "contactNo", 
      title: "Contact No", 
      isFilter: false, 
      width: 140,
      errorField:"contactNo_error",
      validation:(val)=>{
        const re = /^(\+\d{1,3}[- ]?)?\d{10,12}$/;
        if(val && re.test(val))
          return ""
        else 
          return "*Inavlid Number"
       }, 
    },
    { 
      field: "email", 
      title: "Email", 
      isFilter: false, 
      width: 140,
      errorField:"email_error",
      validation:(val)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(val && re.test(String(val).toLowerCase()))
             return ""
         else 
           return "*Invalid Email"
        },
    }

  ]
  public contectGrid=[]
  public onTabSelect(e) {
  }

  public contractorVendorForm: ContractorVendor = new ContractorVendor();
  error = ContractorValidation(this.contractorVendorForm, "init");
  
  public column1 = [
    { field: "registrationNo", title: "Contractor/Vendor Registration No.", isFilter: true, width: 200 },
    { field: "name", title: "Contractor/Vendor Name", isFilter: true },
    { field: "country", title: "Country", isFilter: true },
    { field: "state", title: "State", isFilter: true, width: 100 },
    { field: "status", title: "Status", isFilter: true, width: 100 }
  ]
  public gridData = [];
  public column2 = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true }
  ]
  public gridData2 = sampleProducts;

  submitted = false;

  public spec_det = [];
  public countryDropDown = [];
  public stateDropDown = []
  public usercolumn=[
    {field:"c_per",title:"Contact Person",type:'text',width:150},
    {field:"desig",title:"Designation", type:'text',width:140},
    {field:"con_no",title:"Contact No.", type:'text',width:140},
    {field:"emial_c",title:"Email", type:'text',width:140},
    {field:"action",title:"Action", type:'submit',width:140}
    ]

  public usergridData = [
    {
      c_per:'',
      desig:'',
      con_no:'',
      emial_c:''
    }
  ];
  public isDublicate=false;
  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.attachment_column, (e) => { this.attachementColumn(e) })
    eventListner(EVENT_NAME.remove_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
       this.deleteAttechment(e);
    })
    eventListner(EVENT_NAME.download_attechment,(e)=>{
      this.downloadAttechment(e)
    })
  }
  addAttechment(){
    if(!this.attechForm.URL){
      this.attechError.URL=true
    }
    else{
      this.attechError.URL=false
    }
    if(!this.attechForm.FILENAME){
      this.attechError.FILENAME=true
    }
    else{
      this.attechError.FILENAME=false
    }
    if(!this.attechError.URL && !this.attechError.FILENAME ){
      this.attechForm.FILECONTENTS=this.attechForm.URL;
      this.attechForm.MODULEHIERARCHYID=this.moduleId;
      this.attechForm.saveData()
      .then((result:any)=>{
        this.attechForm.ID=result[0]?.id;
        this.attechmentDoc.getData(this.attechId,this.moduleId)
        .then((result)=>{
         // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
          this.attechForm=new Attechment();
        })
        // this.attechmentDoc.alldata.push(this.attechForm);
        // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        // this.attechForm=new Attechment();
      })
      .catch((err)=>{
        
      })
  }
  }
  attachementColumn(data:any){
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.REFERENCEID=data.id;
  
  
  this.attechError={URL:false,FILENAME:false}
  
  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  this.attechId=data.id;
  this.attechmentDoc=new AttechmentObj();
  this.attechmentDoc.getData(data.id,this.moduleId)
  .then((result:any)=>{
    this.attachmentOpened = true;
    this.dailogTitle = "Add Attachment"
  //this.attachmentOpened = true;
  //this.dailogTitle = "Add Attachment"
  })
  .catch((err)=>{
   this.attechmentDoc.alldata=[]
  })
  }
  // attachementColumn(data:any) {
  //   console.log(data)
  //   this.attechForm={ 
  //         ID:0,
  //         REFERENCEID:data.id,
  //         FILETYPE:"pdf",
  //         FILENAME:"",
  //         URL:"",
  //         EMPLOYEEGLOBALID:this.employeId,
  //         CREATEDON:new Date(),
  //         MODULEHIERARCHYID:this.moduleId,
  //       }
    
  //    this.attechError={URL:false,FILENAME:false}

  //  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  //   this.attechId=data.id;
  //   this.getAttechment(data.id)
  //   .then((result:any)=>{
  //     this.attechmentDoc=[...result];
      // this.attachmentOpened = true;
      // this.dailogTitle = "Add Attachment"
  //   })
  //   .catch((err)=>{
  //      this.attechmentDoc=[]
  //   })
  // }
  deleteAttechment(e){
    this.attechForm.deleteData(e.dataItem)
    .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
    })
    .catch((err)=>{
     
    })
   // console.log(e)
  }
  downloadAttechment(data){
    window.open(environment.backendUrl +data.URL)
  }

  ngOnInit() {
    if(UserModuleObj.contractor){
      this.permission=UserModuleObj.contractor;
    }
    this.getStateData("dbo","state",null)
    .then((sresult)=>{
    Promise.all([
      this.getModuleHierarchYdata("dbo","moduleHierarchy"),
      this.getBpmType(),
      this.getStatus("dbo","status"),
      this.getskillData("dbo", "skill"),
      this.getcountryData("dbo", "country"),
    ])
      .then((result) => {
        this.getBpm();
    })
    })
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.contractorVendorForm = new ContractorVendor();
   
      this.contractorVendorForm.statusId =1;
      this.specializationDetailFlag = []
      this.contectGrid=[]
      this.error = ContractorValidation(this.contractorVendorForm, "init");
      this.dailogTitle = "Add Contractor & Vendor"
    }
    if (type == "update") {
      this.dailogTitle = "Edit Contractor & Vendor"
    }
    this.opened = true;
  }

  onDialogClose(type) {
    if (type == "contractor") {
      this.opened = false;
    }
    if (type == "attachment") {
      this.attachmentOpened = false
    }
  }

  editColumn(data) {
    if (data.action == "edit") {
      this.opened = true;
      this.openRoleDailog("update")
      HttpClient.get("/api/BPM/GetBpmById?id=" + data.dataItem.id, true, true)
        .then((result: any) => {

    
          this.contectGrid=result.bpmregisterContactPersons || []
          this.error = ContractorValidation(this.contractorVendorForm, "init");
          const data = result;
          this.contractorVendorForm.name = data["name"];
          this.contractorVendorForm.remarks = data["remarks"];
          this.contractorVendorForm.statusId = data["statusId"];
          this.contractorVendorForm.address1 = data["address1"];
          this.contractorVendorForm.address2 = data["address2"];
          this.contractorVendorForm.email = data["email"];
          this.contractorVendorForm.contactNo = data["ContactNo"];
          this.contractorVendorForm.designation = data["designation"];
          this.contractorVendorForm.stateId = data["stateId"];
          this.contractorVendorForm.countryId = data["countryId"];
          this.contractorVendorForm.faxNo = data["faxNo"];
          this.contractorVendorForm.phoneNo = data["phoneNo"];
          this.contractorVendorForm.contactNo = data["contactNo"];
          this.contractorVendorForm.noOfUserAccess = data["noOfUserAccess"];
          this.contractorVendorForm.postCode = data["postCode"];
          this.contractorVendorForm.registrationNo = data["registrationNo"];
          this.contractorVendorForm.bpmskills =data["bpmskills"]
         
          this.specializationDetailFlag  = this.contractorVendorForm.bpmskills.map((d)=>{
            let skillId = d.skillId;
           
             return {
               id:skillId,
               name:this.specialisation_obj[skillId]
             }
           });

          
          this.contractorVendorForm.id = data["id"];
          if(this.countryObj[data["countryId"]])
              this.stateDropDown=this.allStateData.filter((e)=>{return e.COUNTRYID==data["countryId"]});
          else{
            this.stateDropDown=[]
            this.contractorVendorForm.countryId=0
          }

        })
    }
    else if(data.action == "remove") {
      this.removeBpm_Id_Data = data.dataItem
      this.removeDialogOpen = true;
    }
  }

  onSubmit(){
    this.submitted = true;
    let contectError=false;
    if(!contectError){
          let newContectList=this.contectGrid.filter((d)=>d.action!=="new")
          this.contractorVendorForm.bpmregisterContactPersons= newContectList.map((d)=>{
              delete d["action"];
              delete d["index"];
               this.contectColumn.forEach((c)=>{
                 if(d[c.errorField]){
                   delete d[c.errorField];
                 }
               })
              d["statusId"]=1;
              if(!d.createdBy){
                d["createdBy"]=1;
              }
              if(!d.modifiedBy){
                d["modifiedBy"]=1;
              }
              if(!d.createdDate){
                d["createdDate"]=new Date();
              }
              if(!d.modifiedDate){
                d["modifiedDate"]=new Date();
              }
              return d;
          })
     }
    this.contractorVendorForm.id= (this.type=="new")?0:this.contractorVendorForm.id
    if (this.specializationDetailFlag.length) {
      
      let mainFlagObj = {};
      if (this.contractorVendorForm.bpmskills) {
        mainFlagObj = this.contractorVendorForm.bpmskills.reduce((acc, d) => {
          acc[d.skillId] = d;
          return acc;
        }, {});
      }
      this.contractorVendorForm.bpmskills = this.specializationDetailFlag.map((d) => {
        if (mainFlagObj[d.id]) {
          return mainFlagObj[d.id];
        } else {
          return {
            id: 0,
            bpmId: this.contractorVendorForm.id,
            skillId: d.id,
          };
        }
      });
    }
    this.error = ContractorValidation(this.contractorVendorForm, "");
    if(!contectError  && !this.error.address1  && !this.error.registrationNo&& !this.error.postCode && !this.error.countryId && !this.error.stateId){
         this.addBPMData();
    }
  }


  onReset(){
    this.openRoleDailog("new")
  }

  addBPMData() {
    if (this.type == "new") {
     const params = {
        ...this.contractorVendorForm
      };

      
      HttpClient.fetchData("/api/BPM/AddBpm", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("General Master","Add Contractor Vendor","Add Contractor Vendor in General Master ").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getBpm();
          // this.submitted = false;
          // const countryValue = this.countryDropDown.filter(x => {
          //   return x.ID == params.countryId;
          // })

          // console.log(this.stateDropDown);
          // const stateValue = this.stateDropDown.filter(x => {
          //   return x.ID == params.stateId;
          // })
          //  let obj = {
          //   ...this.contractorVendorForm,
          //   id: result.id,
          //   state: stateValue[0].NAME,
          //   country: countryValue[0].NAME
          // }
          // this.gridData.unshift(obj)
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
    if (this.type == "update") {
      const params = {
        ...this.contractorVendorForm
      };

      HttpClient.fetchData("/api/BPM/UpdateBpm", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("General Master","Update Contractor Vendor","Update Contractor Vendor in General Master ").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this.getBpm();
          // const countryValue = this.countryDropDown.filter(x => {
          //   return x.ID == params.countryId;
          // })
          // const stateValue = this.stateDropDown.filter(x => {
          //   return x.ID == params.stateId;
          // })
          // let obj = {
          //   ...this.contractorVendorForm,
          //   state: stateValue[0].NAME,
          //   country: countryValue[0].NAME
          // }
          // this.gridData = this.gridData.map((d) => {
          //   if (d.id == this.contractorVendorForm.id) {
          //     d = obj;
          //   }
          //   return d;
          // })
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
      })
    }
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  getBpmType() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/BPM/GetBpmtypes", true, true)
        .then((result: any) => {
          this.contractorType = result;
          resolve(result)
        })
        .catch(error => {
          reject(error);
        })
    })
  }



  bindCountryObj(result) {
    this.countryObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindStateObj(result) {
    this.stateObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindStatusObj(result) {
    
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  getBpm() {
    HttpClient.get("/api/BPM/GetBpms", true, true)
      .then((result: any) => {
        let data = shiftLastDataInto1st(result)
        this.bindCountryObj(this.countryDropDown);
        this.bindStatusObj(this.statusDropDown)
        this.gridData = data.map((d) => {
           d["state"] = this.stateObj[d.stateId];
          d["country"] = this.countryObj[d.countryId];
          d["status"] = this.statusObj[d.statusId];
          d["deleteKey"]= (this.permission.isDelete && d.status.toLowerCase()=="inactive")?true:false;
          return d;
        })
       
      })
  }

  getskillData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
        .then((result: any) => {
          let skillData = [];
          skillData = JSON.parse(result.gridData);
          this.spec_det = skillData.map((e)=>{
            this.specialisation_obj[e.ID] = e.NAME;
            return { id: e.ID, name: e.NAME }
        });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getcountryData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
        .then((result: any) => {
          let countryData = [];
          countryData = JSON.parse(result.gridData);
          this.countryDropDown = countryData
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  removeBpm() {
    const params = {
      "loggedInUserId": this.removeBpm_Id_Data.loggedInUserId,
      "id": this.removeBpm_Id_Data.id,
      "employeeGlobalId": 0,
      "instance": ""
    };
    HttpClient.fetchData("/api/BPM/DeleteBpmById", "post", params, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("General Master","Delete Contractor Vendor","Delete Contractor Vendor in General Master ").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridData = this.gridData.filter(x => {
            return x.id != this.removeBpm_Id_Data.id;
          })
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      }).catch((error) => {
      });
  }

  valuechange(id) {
    let countryId=id;
    //this.getStateData("dbo","state",countryId)
    this.stateDropDown   =  this.allStateData.filter((e)=>{return e.COUNTRYID==countryId});
           
    
  }

  getStateChangeData(schema,tablename,countryId){

      return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
        .then((result: any) => {
          let stateData = [];
          stateData  = JSON.parse(result.gridData);
            let filteredStateValue  =  stateData.filter((e)=>{return e.COUNTRYID==countryId});
             this.stateDropDown = filteredStateValue;
            //  this.bindStateObj(this.stateDropDown);
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getStateData(schema,tablename,countryId){
    
      return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
        .then((result: any) => {
          let stateData = [];
          this.allStateData  = JSON.parse(result.gridData);
          this.bindStateObj(this.allStateData);

          resolve(stateData)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  facilityValue(id){
     
  }
  
  checkedContectError(){
    let error=false;
    this.contectGrid=this.contectGrid.map((d)=>{
        if(d!=="new"){
             this.contectColumn.forEach((c)=>{
                  let errormsg=c.validation(d[c.field])
                  if(errormsg){
                    error=true
                    d[c.errorField]=errormsg
                  }
             })
        }
        return d;
    })
    return error;
  }
  
  // addAttrechment(){
  //   this.attechError={id:false,type:false,name:false,url:false}
  //   if(!this.attechForm.name){
  //     this.attechError.name=true
  //   }
  //   if(!this.attechForm.url){
  //     this.attechError.url=true
  //   }
  //   if(!this.attechError.name && !this.attechError.url){
  //     console.log(this.attechForm,this.attechId)
  //     this.attechForm.id=this.attechmentDoc.length+1;
  //     this.attechmentDoc.push(this.attechForm);
  //     this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc))
  //     this.attechForm={id:-1,type:"pdf",name:"",url:""}
  //   }
  // }

  // saveAttechment(){
  //   HttpClient.saveAttechment("contractor",this.attechId.toString(),this.attechmentDoc)
  //   .then((result)=>{
  //     this.onDialogClose('attachment');
  //   })
  //   .catch((err)=>{
  //      console.log(err)
  //   })
  // }
  // addAttechment(){
  //   if(!this.attechForm.URL){
  //     this.attechError.URL=true
  //   }
  //   else{
  //     this.attechError.URL=false
  //   }
  //   if(!this.attechForm.FILENAME){
  //     this.attechError.FILENAME=true
  //   }
  //   else{
  //     this.attechError.FILENAME=false
  //   }
  //   if(!this.attechError.URL && !this.attechError.FILENAME ){
  //    // this.attechForm.
  //     this.saveAttechment(this.attechForm)
  //     .then((result:any)=>{
  //       this.attechForm.ID=result.id;
  //       this.attechmentDoc.push(this.attechForm);
  //       this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
  //       this.attechForm={ 
  //         ID:0,
  //         REFERENCEID:this.attechId,
  //         FILETYPE:"pdf",
  //         FILENAME:"",
  //         URL:"",
  //         EMPLOYEEGLOBALID:1,
  //         CREATEDON:new Date(),
  //         MODULEHIERARCHYID:this.moduleId,
  //       }
  //     })
  //     .catch((err)=>{
  //        console.log(err)
  //     })
  // }
 //}
 
  saveAttechment(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"attachment",
      "userId":1,
      "newData":JSON.stringify(data)
    }
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/MasterData/SaveLookupData","post",obj,true,true)
      .then((response)=>{
         
          resolve(response)
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
        // this.opened=false;
      })
      .catch((err)=>{
       
        reject(err)
      })
    })
  }

  deleteLookupAttechment(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"attachment",
      "userId":1,
      "oldData":JSON.stringify(data)
    }
    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/MasterData/DeleteLookupData","post",obj,true,true)
      .then((response)=>{
      
         resolve(response)
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
        // this.opened=false;
      })
      .catch((err)=>{
        console.log(err);
        reject(err)
      })
    })
    
  }

  getModuleHierarchYdata(schema, tablename){
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
        let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
                return e.NAME ==="Contractor"
          })
            this.parentId = ModFacArr[0].PARENTID
            this.moduleId=ModFacArr[0].ID;
         // console.log(ModFacArr)
      });
    }

    getAttechment(RefId){
      return new Promise((resolve,reject)=>{
        HttpClient.get("/api/MasterData/GetLookupData?Schema=dbo&Table=attachment",true,true)
        .then((result:any)=>{
            let attechmentData=JSON.parse(result.gridData)
            let temp=attechmentData.filter((d)=>d.REFERENCEID==RefId && d.MODULEHIERARCHYID==this.moduleId);
            resolve(temp);
        })
        .catch((err)=>{
            reject(err);
        })
      })
    }
    checkDublicateName(){
     this.isDublicate= this.gridData.filter((d)=>d.name==this.contractorVendorForm.name).length>0
    }
    downloadTemplate(){
      window.open("/assets/template/user.xls")
    }
    refreshData(e){
       if(e.status){
         this.getBpm()
       }
    }
}
