var lock=true;
var count=0;
var obj={}
export var eventListner=(name,cb)=>{
   obj[name]=cb;
  //  window.addEventListener(name,(e:any)=>{
  //      obj[name] && obj[name](e.detail);
  //  })
  //  document.addEventListener(name,(e:any)=>{
  //   obj[name] && obj[name](e.detail);
  //  })
}

export var dispatchEvent=(name,data)=>{
          obj[name] && obj[name](data);
      // var event = new CustomEvent(name, {detail: data});
      // count++;
      // if(count%2==0)
      //   window.dispatchEvent(event);
      // else
      //  document.dispatchEvent(event);
               
}