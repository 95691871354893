
class  assetBpms{
          id: number=0;
          assetId: number=0;
          bpmregisterId: number=0
}
  
  class assetInfos{
    id: number=0;
    assetId: number=0;
    commissioningDate:Date;
    serviceStartDate:Date;
    serviceEndDate:Date;
    lifeSpan: number=0;
    statusId: number;
    assetAge: string=null;
    yearInService: string=null;
    operatingHours: string=null
  }
  
  class  assetLocations{
    id: number;
    assetId: number;
    facilityId: number;
    blockId: number;
    levelId: number;
    areaId: number;
    locationId: number;
    subLocationId: number;
    assets: [
      string
    ]
  }
  
  class assetMaintenances{
    id: number=0;
    assetId: number=0;
    ppmflag:string ="No";
    ppmcreated:boolean=true;
    di:string ="No";
    lastScheduledWorkOrderNo: string =null;
    lastUnScheduledWorkOrderNo: string=null;
    lastScheduledWorkOrderDate:Date;
    lastUnScheduledWorkOrderDate:Date;
    totalDownTime: string =null;
    cumulativepartsCost:number= 0;
    cumulativeLabourCost: number=0;
    cumulativeContractCost:number=0;
    warrantyStartDate:Date;
    warrantyEndDate:Date;
    warrantyDurationInMonths:number=0
  }
  
  export class AssetRegistration{
      id: number;
      assetStandardizationId: number;
      assetNumber: string;
      assetCode: string;
      serialNo: string;
      chasisNo: string;
      engineNo: string;
      contractTypeId: number;
      createdBy: number;
      createdDate:Date;
      modifiedBy: number;
      modifiedDate:Date;
      moduleHierarchyId: number;
      assetBpms:Array<assetBpms>;
      assetInfos: Array<assetInfos>
      assetLocations: Array<assetLocations>;
      assetMaintenances:Array<assetMaintenances>
    
  }

  export class AssetInfoValue {
    id: number=0;
    assetId: number;
    commissioningDate:Date;
    serviceStartDate:Date;
    serviceEndDate:Date;
    lifeSpan: number;
    statusId: number;
    assetAge: string=null;
    yearInService: string=null;
    operatingHours: string=null
  }

  export class AssetLocationValue{
    id: number=0;
    assetId: number;
    facilityId: number;
    blockId: number;
    levelId: number;
    areaId: number;
    locationId: number;
    subLocationId: number;
    assets: [
      string
    ]
  }

  export class AssetMaintenancesValue{
    id: number=0;
    assetId: number=0;
    ppmflag:string ="No";
    ppmcreated:boolean=true;
    di:string ="No";
    lastScheduledWorkOrderNo: string =null;
    lastUnScheduledWorkOrderNo: string=null;
    lastScheduledWorkOrderDate:Date;
    lastUnScheduledWorkOrderDate:Date;
    totalDownTime: string =null;
    cumulativepartsCost:number;
    cumulativeLabourCost: number;
    cumulativeContractCost:number;
    warrantyStartDate:Date;
    warrantyEndDate:Date;
    warrantyDurationInMonths:number
  }

  export class AssetRegTypeError{
   assetStandardizationId:Boolean;
   assetNumber:Boolean;
   constructor(status){
      this.assetStandardizationId = status;
      this.assetNumber= status;
    }
  }

  export class LocationTypeError{
     facilityId:Boolean;
    blockId:Boolean;
    locationId:Boolean;
    levelId:Boolean;
   areaId:Boolean;
   subLocationId:Boolean;
    constructor(status){
       this.facilityId = status;
       this.blockId= status;
       this.locationId= status;
       this.areaId= status;
       this.subLocationId= status;
       this.levelId = status;
     }
  }