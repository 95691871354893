<div style="font-size: 18px; line-height: 1.3em;padding-left:3em"><b>Reassignment</b></div>

<div class="py-4" style="padding: 4em;">
    <table style="width: 100%;">
        <thead>
            <tr>
                <th>Assignee</th>
                <th>New Assignee</th>
                <th>Assignee To</th>
                <th>New Assignee To</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of task">
                <td>{{item?.assigneeObj?.staffName}}</td>
                <td>{{item?.newAssigneeObj?.staffName}}</td>
                <td>{{item?.assignToObj?.name}}</td>
                <td>{{item?.newAssigneeToObj?.name}}</td>
            </tr>
        </tbody>
    </table>
</div>