<!-- <kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
[selectable]="true"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"
[rowClass]="rowCallback"
>
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
   <ng-template   kendoGridCellTemplate let-dataItem>
       <span *ngIf="col.type=='checkbox'">
        Add <input   type="checkbox" [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
       </span>
       <span *ngIf="col.type=='checkbox'">
        Delete <input *ngIf="col.type=='checkbox'"  type="checkbox" [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
       </span>
       <span *ngIf="col.type=='checkbox'">
        Modification <input *ngIf="col.type=='checkbox'"  type="checkbox" [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
       </span>
       <span *ngIf="col.type=='checkbox'">
        Print <input *ngIf="col.type=='checkbox'"  type="checkbox" [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
       </span> 
       <span *ngIf="col.type=='checkbox'">
        All <input *ngIf="col.type=='checkbox'"  type="checkbox" [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
       </span>
       
   <div *ngIf="col.type=='text'">
           <span><b>{{dataItem[col.field]}}</b></span>
     </div>
  </ng-template>
</kendo-grid-column>
<kendo-grid-command-column title="Action" width="100">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand     icon="pencil" style="color: blue;background: transparent;border:0px;" ></button>
      <button kendoGridRemoveCommand  icon="delete" style="color: red;background: transparent;border: 0px;"></button>
  </ng-template>
</kendo-grid-command-column>
</kendo-grid> -->



<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
[selectable]="true"
[rowSelected]="isRowSelected"
[rowClass]="rowCallback"
(dataStateChange)="dataStateChange($event)"

>
<kendo-grid-column-group
   *ngFor="let header of column"
   title={{header.title}}
   width={{header.width}}
   [locked]="false"
   class="xyz"
   [headerStyle]="{'text-align': 'center'}"
>
        <kendo-grid-column
        *ngFor="let col of header.child"
        field={{col.field}}
        title={{col.title}}
        width={{col.width}}
        [filterable]="col.isFilter"
        filter={{col.filter}}
        [style]="col.style"

        >
        <ng-template   kendoGridCellTemplate let-dataItem>
            <input *ngIf="col.type=='checkbox'"  type="checkbox"  [checked]="dataItem[col.field]" [(ngModel)]="dataItem[col.field]" (change)="dataStateChange($event)" />
            <div *ngIf="col.type=='text'">
                <span><b>{{dataItem[col.field]}}</b></span>
              </div>
        </ng-template>
        </kendo-grid-column>
      
</kendo-grid-column-group>
<kendo-grid-command-column width="100">
 
    <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand    icon="pencil" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
        <button kendoGridRemoveCommand  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button>
    </ng-template>
  </kendo-grid-command-column>
<!-- <kendo-grid-command-column title="command" width="100">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand     icon="pencil" style="color: blue;background: transparent;border:0px;" ></button>
      <button kendoGridRemoveCommand  icon="delete" style="color: red;background: transparent;border: 0px;"></button>
  </ng-template>
</kendo-grid-command-column> -->
<!-- <kendo-grid-column field="ProductName" title="Product Name">
</kendo-grid-column>
<kendo-grid-column
  field="FirstOrderedOn"
  title="First Ordered On"
  width="240"
  filter="date"
  format="{0:d}"
>
</kendo-grid-column>
<kendo-grid-column
  field="UnitPrice"
  title="Unit Price"
  width="180"
  filter="numeric"
  format="{0:c}"
>
</kendo-grid-column> -->
<!-- <kendo-grid-column field="Discontinued" width="120" filter="boolean">
  <ng-template kendoGridCellTemplate let-dataItem>
    <input type="checkbox" [checked]="dataItem.Discontinued" disabled />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>