<div class="dashboard-body">
    <div class="dashboard-content"> 
  <div class="content-wrapper">
    <div class="page-wrapper">
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Stock Trade</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphObj.trade" categoryField="NAME" field="value">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>
 
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>

      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Stock Category</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphObj.category" categoryField="NAME" field="value">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <div class="custom-tablesection">
    <div class="custom-dropdown">
    </div>
    <div class="custom-tab">
        <div style="padding: 1rem;"> 
             <!-- <kendo-tabstrip>
                <kendo-tabstrip-tab title="Corrective Work Order" [selected]="true">
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            [data]="workorder.corrective" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>
                 <kendo-tabstrip-tab title="Proactive Work Order">
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            [data]="workorder.proactive" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>
                 <kendo-tabstrip-tab title="Alarm Work Order" >
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            [data]="workorder.alarm" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>

                 <kendo-tabstrip-tab title="Request Work Order" >
                    <ng-template kendoTabContent>
                      <div>
                          <app-dashboard-table-grid 
                              [data]="workorder.request" 
                              [column]="column" 
                              [size]="10" 
                              [action]="false"  
                              (filteredChanged)="filterChangedHandler($event)"
                          >
                          </app-dashboard-table-grid>
                      </div>
                   </ng-template>
                   </kendo-tabstrip-tab>
            </kendo-tabstrip> -->
            <app-dashboard-table-grid 
                [column]="column"
                id="uwo-summery"
                [data]="gridData"
                [size]="10"
                [action]="false"
                (filteredChanged)="filterChangedHandler($event)"
             >
            </app-dashboard-table-grid>

        </div>
    </div>
   </div>
        
  </div>
  </div>