<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"
>
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
</kendo-grid-column>

</kendo-grid>