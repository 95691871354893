import { Component, OnInit } from '@angular/core';
import { pading } from 'src/ApiModel/Util';
import HttpClient from 'src/Util/ApiHandling';
import { convertLocalDateString, objectToQueryString } from 'src/Util/Util';

@Component({
  selector: 'app-work-order-vender-performance',
  templateUrl: './work-order-vender-performance.component.html',
  styleUrls: ['./work-order-vender-performance.component.scss']
})
export class WorkOrderVenderPerformanceComponent implements OnInit {

  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public serviceObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public parentId = -1;
  public moduleId = -1;

  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public noDataFound: boolean = false;
  public facility: any;
  public fromDate: Date;
  public toDate: Date;
  public workOrderType: any;
  public workOrderStatus = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: 1, name: "New" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Waiting" },
      { id: 4, name: "Complete" },
      { id: 5, name: "Close" },
      { id: 6, name: "Cancel" },
      { id: 7, name: "InActive" },
      { id: 8, name: "Active" },
    ],
    selectedObj: null,
    objectData: {}
  }
  public workOrderTypeObj = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: 1, name: "Routine Maintenance" },
      { id: 2, name: "Corrective Maintenance" },
      { id: 3, name: "Reactive Maintenance" }
    ],
    selectedObj: null,
    objectData: {}
  }
  public withinSLAData: any = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ];
  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "ServicesType")
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
          result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        //ServicesType

        this.serviceObj.objectData = result[1].reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        this.serviceObj.alldata = result[1];
        this.serviceObj.filterData = []
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }
  public column = [
    { field: "SL_NO", title: "S.No", width: 50 },
    { field: "TRADE", title: "Trade", width: 200 },
    { field: "OPENED", title: "WO Opened", width: 200 },
    { field: "CLOSED", title: "WO Closed", width: 200 },
    { field: "CLOSURE(%)", title: "WO Closure(%)", width: 200 },
    { field: "CLOSEDWITHINSLA", title: "WO Closed within SLA", width: 200 },

  ];
  download() {
    let fileName = 'Work-Orders-Report-for-IBCC-VendorPerformance.csv';
    let columnNames = [
      "S.No",
      "TRADE",
      "WO Opened",
      "WO Closed",
      "WO Closure(%)",
      "WO Closed within SLA"
    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["SL_NO"],
        c["TRADE"],
        c["OPENED"],
        c["CLOSED"],
        c["CLOSURE(%)"],
        c["CLOSEDWITHINSLA"]

      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  fetchReport() {
    let path = "";
    const from_date = (this.fromDate != undefined) ? convertLocalDateString(this.fromDate) : this.fromDate;
    const to_date = this.toDate != undefined ? convertLocalDateString(this.toDate) : this.toDate;

    var qString = { startDate: from_date, endDate: to_date, workOrderTypeId: this.workOrderType?.id, facilityId: this.facility?.ID }

    path = `/api/CustomReport/GetWorkOrdersForVendorPerformance?${objectToQueryString(qString)}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          this.report = this.report.map((item) => {
            item["CLOSURE(%)"] = item["CLOSURE(%)"]?.toFixed(2);
            return item;
          });
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].TRADE != null;
          this.noDataFound = !this.showReport
          if (this.showReport) {
            const copy = ['OPENED', 'CLOSED', 'CLOSEDWITHINSLA'];
            const res = this.report.map(data => copy.reduce((o, k) => (o[k] = data[k], o), {}));
            let avg = {};
            let temp = {};
            res.forEach(obj => Object.keys(obj).forEach(k => {
              if (obj[k] === null) return;
              temp[k] = temp[k] || { sum: 0, count: 0 };
              temp[k].sum += obj[k];
              temp[k].count++;
              avg[k] = temp[k].sum;/// temp[k].count;
            }));
            console.log(avg); console.log(temp);
            let avgRating: any = {
              TRADE: "TOTAL",
              OPENED: avg['OPENED'],
              CLOSED: avg['CLOSED'],
              "CLOSURE(%)": ((avg['CLOSED'] / avg['OPENED'])*100).toFixed(2),
              CLOSEDWITHINSLA: avg['CLOSEDWITHINSLA']
            };
            this.report.push(avgRating);
          }
        })
        .catch((err) => {

          reject(err)
        })
    })
  }

}
