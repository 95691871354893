import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-ppm-page',
  templateUrl: './ppm-page.component.html',
  styleUrls: ['./ppm-page.component.scss']
})
export class PpmPageComponent implements OnInit {
  public onTabSelect(e) {
    console.log(e);
  }
 
  constructor(){

  }
 
  public column2=[
    {field:"c_2",title:"Asset no", isFilter:true,width:100},
    {field:"c_4",title:"PPM WO ID", isFilter:true,width:100},
    {field:"c_5",title:"Asset description", isFilter:true,width:100},
    {field:"c_5",title:"Location", isFilter:true,width:100},
    {field:"c_6",title:"PPM WO Date", isFilter:true,width:100},
    {field:"c_7",title:"Status", isFilter:true,width:100},
   
   
  ];

  public column3=[
    {field:"c_2",title:"Facility", isFilter:true,width:100},
    {field:"c_3",title:"Service Type", isFilter:true,width:100},
    {field:"c_4",title:"Checklist ID", isFilter:true,width:100},
    {field:"c_5",title:"Checklist Name", isFilter:true,width:100},
    {field:"c_6",title:"PPM ID", isFilter:true,width:100},
    {field:"c_7",title:"Block Code", isFilter:true,width:100},
    {field:"c_7",title:"Block Name", isFilter:true,width:100},
    {field:"c_7",title:"Level Code", isFilter:true,width:100},
    {field:"c_7",title:"Level Name", isFilter:true,width:100},
    {field:"c_7",title:"Area Code", isFilter:true,width:100},
    {field:"c_7",title:"Area Name", isFilter:true,width:100},
    {field:"c_7",title:"Location Code", isFilter:true,width:100},
    {field:"c_7",title:"Location Name", isFilter:true,width:100},
    {field:"c_7",title:"Frequency", isFilter:true,width:100},
    {field:"c_7",title:"First Date of PPM", isFilter:true,width:100},
    {field:"c_7",title:"Expired date for PPM", isFilter:true,width:100},
    {field:"c_7",title:"Assignee", isFilter:true,width:100},
    {field:"c_7",title:"Assign To", isFilter:true,width:100},
    {field:"c_7",title:"Contact No.", isFilter:true,width:100},
    {field:"c_7",title:"Status", isFilter:true,width:100},

   
   
  ];
  public column4=[
    {field:"c_2",title:"Asset No.", isFilter:true,width:100},
    {field:"c_3",title:"Service Type", isFilter:true,width:100},
    {field:"c_4",title:"Checklist ID", isFilter:true,width:100},
    {field:"c_5",title:"PPM ID", isFilter:true,width:100},
    {field:"c_6",title:"Location", isFilter:true,width:100},
    {field:"c_7",title:"Year", isFilter:true,width:100},
    {field:"c_7",title:"Asset Type Code", isFilter:true,width:100},
    {field:"c_7",title:"Asset Type Code Description", isFilter:true,width:100},
    {field:"c_7",title:"Asset Classification", isFilter:true,width:100},
    {field:"c_7",title:"Model", isFilter:true,width:100},
    {field:"c_7",title:"Manufacturer", isFilter:true,width:100},
    {field:"c_7",title:"Serial Number", isFilter:true,width:100},
    {field:"c_7",title:"Frequency", isFilter:true,width:100},
    {field:"c_7",title:"Expired date for PPM", isFilter:true,width:100},
    {field:"c_7",title:"First Date of PPM", isFilter:true,width:100},
    {field:"c_7",title:"Assignee", isFilter:true,width:100},
    {field:"c_7",title:"Assign To", isFilter:true,width:100},
    {field:"c_7",title:"Contact No.", isFilter:true,width:100},
    {field:"c_7",title:"Status", isFilter:true,width:100},
   ];
   
  public areagrid=[
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    },
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    }
  ];
// public gridData=sampleProducts;
      
 
ngOnInit(){
      
}

}
