
<div class="card m-3"   >
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Company Working Hour</h5>
      
    </div>
    <div class="card-body register-form-body"  >
      <form class=""  [formGroup]="BlockForm">
        <div class="form-row">
        <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
          <label>Working Hour Start</label>
          <div style="width: 100%;">
              <kendo-timepicker   [format]="'hh:mm a'"   #WorkingHourStart formControlName="WorkingHourStart" ></kendo-timepicker>
             
          </div>
        </div>
        </div>
        <div class="form-row">
          <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
            <label>Working Hour End</label>
            <div style="width: 100%;">
              <kendo-timepicker   [format]="'hh:mm a'" #WorkingHourEnd formControlName="WorkingHourEnd"></kendo-timepicker>
              
            </div>
          </div>
          </div>




   <kendo-dialog-actions [layout]="actionsLayout">

  <button  kendoButton (click)="onSubmit()" primary="true">
  Save
  </button>
</kendo-dialog-actions>
</form>
    </div>

</div>
