import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class AssetRegistration{ 
    id: number;
    assetStandardizationId: number;
    assetNumber: string;
    assetCode: string;
    serialNo: string;
    chasisNo: string;
    engineNo: string;
    contractTypeId: number;
    createdBy: number;
    createdDate:Date;
    modifiedBy: number;
    modifiedDate:Date;
    moduleHierarchyId: number;
    assetLocations:Array<{locationId:number}>
}

export class AssetRegistrationObj{
    alldata:Array<AssetRegistration>=[]
    filterData:Array<AssetRegistration>=[]
    selectedObj:AssetRegistration | any=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AssetRegistration)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/Assets/GetAssets",true,true)
            .then((result:Array<AssetRegistration>)=>{
                console.log("asset data=>",result)
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }
}