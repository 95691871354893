<div class="dashboard-body">
    <div class="dashboard-content"> 
  <div class="content-wrapper">
    <div class="page-wrapper">
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Corrective Work Order</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphCount" categoryField="label" field="corrective">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>

      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Proactive Work Order</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphCount" categoryField="label" field="proactive">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Alarm Work Order</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphCount" categoryField="label" field="alarm">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="true"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>
      <!-- <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Request Work Order</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphCount" categoryField="label" field="request">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="true"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div> -->
    </div>



    <div class="page-wrapper">
 
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Request Work Order</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="graphCount" categoryField="label" field="request">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="true"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>

      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Work Order Count By Month</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="bar" [data]="workorder.month" categoryField="month" field="count">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>

    </div>
  </div>
  <div class="custom-tablesection">
    <div class="custom-dropdown">
    </div>
    <div class="custom-tab">
        <div> 
             <kendo-tabstrip>
                <kendo-tabstrip-tab title="Corrective Work Order" [selected]="true">
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            id="uwo-summery"
                            [data]="workorder.corrective" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>
                 <kendo-tabstrip-tab title="Proactive Work Order">
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            id="uwo-summery"
                            [data]="workorder.proactive" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>
                 <kendo-tabstrip-tab title="Alarm Work Order" >
                  <ng-template kendoTabContent>
                    <div>
                        <app-dashboard-table-grid 
                            id="uwo-summery"
                            [data]="workorder.alarm" 
                            [column]="column" 
                            [size]="10" 
                            [action]="false"  
                            (filteredChanged)="filterChangedHandler($event)"
                        >
                        </app-dashboard-table-grid>
                    </div>
                 </ng-template>
                 </kendo-tabstrip-tab>

                 <kendo-tabstrip-tab title="Request Work Order" >
                    <ng-template kendoTabContent>
                      <div>
                          <app-dashboard-table-grid 
                              id="uwo-summery"
                              [data]="workorder.request" 
                              [column]="column" 
                              [size]="10" 
                              [action]="false"  
                              (filteredChanged)="filterChangedHandler($event)"
                          >
                          </app-dashboard-table-grid>
                      </div>
                   </ng-template>
                   </kendo-tabstrip-tab>
                   <kendo-tabstrip-tab title="Work Order Count By Month">
                    <ng-template kendoTabContent>
                      <div>
                          <app-dashboard-table-grid 
                              id="uwo-summery"
                              [data]="workorder.month" 
                              [column]="mcolumn" 
                              [size]="12" 
                              [action]="false"  
                              (filteredChanged)="filterChangedHandler($event)"
                          >
                          </app-dashboard-table-grid>
                      </div>
                   </ng-template>
                   </kendo-tabstrip-tab>
            </kendo-tabstrip>
            <!-- <app-table-grid  
                [column]="column"
                [data]="gridData"
                [size]="10"
                [action]="false"
             >
            </app-table-grid> -->

        </div>
    </div>
   </div>
        
  </div>
  </div>