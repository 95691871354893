import { Component, OnInit } from '@angular/core';
import { pading } from 'src/ApiModel/Util';
import HttpClient from 'src/Util/ApiHandling';

@Component({
  selector: 'app-closed-work-order-report',
  templateUrl: './closed-work-order-report.component.html',
  styleUrls: ['./closed-work-order-report.component.scss']
})
export class ClosedWorkOrderReportComponent implements OnInit {

  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public noDataFound: boolean = false;
  public data = new RequestClosedWorkOrderSummaryReport();
  public report: ClosedWorkOrderSummaryReport;
  public workOrderTypeObj = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: -1, name: "Routine Maintenance" },
      { id: 4, name: "Corrective Maintenance" },
      { id: 7, name: "Reactive Maintenance" }
    ],
    selectedObj: null,
    objectData: {}
  }
  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }
  public parentId = -1;
  public moduleId = -1;

  public facility: any;
  public woType: any;
  public fromDate: Date = new Date();
  public toDate: Date = new Date();

  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility")
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
          result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }

  convertLocalDateString(d) {
    let ndate = new Date(d);
    return `${ndate.getFullYear()}-${pading(ndate.getMonth() + 1)}-${pading(ndate.getDate())}`
  }
  fetchReport() {
    this.data.FacilityId = this.facility?.ID;
    this.data.WorkOrderTypeId = this.woType?.id;
    this.data.FromDate = this.fromDate;
    this.data.ToDate = this.toDate;

    console.log(this.data)

    this.fetchData(this.data);
  }


  fetchData(objData) {
    let path = "";
    path = `/api/WorkOrder/ClosedWorkOrderSummaryReport`
    return new Promise((resolve, reject) => {
      HttpClient.fetchData(path, "post", objData, true, true)
        .then((result: any) => {
          resolve(result);
          this.report = result;
          console.log(result);
          this.showReport = result.totalWorkOrders > 0;
          this.noDataFound = !this.showReport
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  public column = [
    { field: "workCenterDesc", title: "Work Center Description", width: 200 },
    { field: "openWorkOrders", title: "No. of Work Order Open", width: 200 },
    { field: "closedWorkOrders", title: "No. of Work Order Closed", width: 200 }
  ]

  download() {
    console.log(this.report);
    let fileName = 'ClosedWorkOrder-Report.csv';
    let reportHeader = ["", "Closed Work Order Summary"].join(',');
    let csv = reportHeader;
    csv += '\r\n';
    csv += '\r\n';
    csv += ["Plant/Facility", this.report.plant];
    csv += '\r\n';
    csv += ["Period", this.convertLocalDateString(this.fromDate) + ' TO ' + this.convertLocalDateString(this.toDate)];
    csv += '\r\n';
    csv += ["Work Order Type", this.report.workOrderType];
    csv += '\r\n';
    csv += ["Total No of Work Order", this.report.totalWorkOrders];
    csv += '\r\n';
    csv += ["Total No of Work Order Open", this.report.totalOpenWorkOrders];
    csv += '\r\n';
    csv += ["Total No of Work Order Closed", this.report.totalClosedWorkOrders];
    csv += '\r\n';
    csv += ["Percentage of Closed Work Order", ((this.report.totalClosedWorkOrders / this.report.totalWorkOrders) * 100).toFixed(2) + "%"];
    csv += '\r\n';
    csv += '\r\n';
    let columnNames = [
      "Work Center Description",
      "No. of Work Order Open",
      "No. of Work Order Closed",
    ];
    let header = columnNames.join(',');
    csv += header;
    csv += '\r\n';
    this.report.lstCLostWOSummary.map(c => {
      csv += [
        c["workCenterDesc"],
        c["openWorkOrders"],
        c["closedWorkOrders"],
      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}

export class RequestClosedWorkOrderSummaryReport {
  FromDate: Date;
  ToDate: Date;
  WorkOrderTypeId: number
  FacilityId: number
}

export class ClosedWorkOrderSummaryReport {
  plant: string;
  workOrderType: string;
  totalWorkOrders: number;
  totalOpenWorkOrders: number;
  totalClosedWorkOrders: number;
  percentClosedWorkOrders: number;
  lstCLostWOSummary: Array<LstClosedWorkOrderSummaryReport>;
}

export class LstClosedWorkOrderSummaryReport {
  workCenterDesc: string;
  total: number;
  openWorkOrders: number;
  closedWorkOrders: number;

}
