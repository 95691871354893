import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ModelApi } from "src/ApiModel/const"
import { Model } from "src/ApiModel/Model"
import { TicketReassignment } from "src/ApiModel/Model/TicketReassignment"
import { convertDateFormate } from "src/ApiModel/Util"

class ReassignError {
    user: boolean = false;
    bpm: boolean = false;
}


@Component({
    selector: 'app-work-order-reassign-pdf',
    templateUrl: './work-order-reassign-pdf.component.html',
    styleUrls: ['./work-order-reassign-pdf.component.scss']
})
export class WorkOrderReassignComponentPdf implements OnChanges {
    @Input() workorder: any;

    constructor() {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workorder) {
            this.workorder = changes.workorder.currentValue;
            this.model.workOrder.selectedObj = this.workorder;
        }
    }
    public convertDateFormate = convertDateFormate;
    public format = "dd/MM/yyyy";
    public type = ""
    public model: Model = new Model();
    public data = new TicketReassignment()
    public error = new ReassignError()
    public task: any = []
    public column = [
        { field: "assigneeObj.staffName", title: "Assignee", isFilter: true, width: 200 },
        { field: "newAssigneeObj.staffName", title: "New Assignee", isFilter: true, width: 200 },
        { field: "assignToObj.name", title: "Assign To", isFilter: true, width: 200 },
        { field: "newAssigneeToObj.name", title: "New Assign To", isFilter: true, width: 200 }
    ]
    ngOnInit(): void {
        this.model.getData(
            ModelApi.WorkerOrder,
            ModelApi.Contractor,
            ModelApi.User
        )
            .then((result) => {
                this.getData();
            })
            .catch((err) => {

            })
    }
    getData() {
        this.model.getData(ModelApi.ticketreassignment)
            .then((result) => {
                this.task = this.model.ticketReassign.alldata.filter((d) => d.TICKETID == this.workorder.id).map((d) => {
                    if (d.ID) {
                        d["workOrderObj"] = this.model.workOrder.objectData[d.TICKETID];
                        d["assigneeObj"] = this.model.user.objectData[d.ASSIGNEE];
                        d["assignToObj"] = this.model.contractor.objectData[d.BPMID];
                        d["newAssigneeObj"] = this.model.user.objectData[d.NEWASSIGNEE];
                        d["newAssigneeToObj"] = this.model.contractor.objectData[d.NEWBPMID];
                    }
                    return d;
                })
            })
    }

}
