import { Component, Input, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {TicketReassignment} from "src/ApiModel/Model/TicketReassignment"
import { eventListner, EVENT_NAME } from 'src/event';
import {convertDateFormate} from "src/ApiModel/Util"

class ReassignError{
  user:boolean=false;
  bpm:boolean=false;
}


@Component({
  selector: 'app-work-order-reassign',
  templateUrl: './work-order-reassign.component.html',
  styleUrls: ['./work-order-reassign.component.scss']
})
export class WorkOrderReassignComponent implements OnChanges {
@Input() workorder:any;
  constructor() { 
    // eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    // eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.workorder){
      this.workorder=changes.workorder.currentValue;
      this.model.workOrder.selectedObj=this.workorder;
    }
  }
  public convertDateFormate=convertDateFormate;
  public format = "dd/MM/yyyy";
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Ticket Reassignment"
  public model:Model=new Model();
  public data=new TicketReassignment()
  public error=new ReassignError()
  public task:any=[]
  public column=[
    {field:"workOrderObj.workOrderNo",title:"Workorder No", isFilter:true,width:200},
    {field:"workOrderObj.description",title:"Workorder Description", isFilter:true,width:200},
    // {field:"REASSIGNMENTDATE",title:"Reassignment Date",isFilter:true,width:200},
    // {field:"REASON",title:"Reason", isFilter:true,width:200},
    {field:"assigneeObj.staffName",title:"Assignee", isFilter:true,width:200},
    {field:"newAssigneeObj.staffName",title:"New Assignee", isFilter:true,width:200},
    {field:"assignToObj.name",title:"Assign To", isFilter:true,width:200},
    {field:"newAssigneeToObj.name",title:"New Assign To", isFilter:true,width:200}
  ]
  ngOnInit(): void {
    this.model.getData(
      ModelApi.WorkerOrder,
      ModelApi.Contractor,
      ModelApi.User
      )
    .then((result)=>{
     // this.model.maintenaceFrequence.selectedObj.DESCRIPTION
     this.getData();
    })
    .catch((err)=>{
    
    })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
  }
  valuechange(type,data){
    if(type=="workOrder"){
      this.model.workOrder.selectedObj=data;
    }
    else if(type=="contractor"){
      this.model.contractor.selectedObj=data;
    }
    else if(type=="user"){
      this.model.user.selectedObj=data;
    }
    else if(type=="maintenaceFrequence"){
      this.model.maintenaceFrequence.selectedObj=data;
    }
    else if(type=="status"){
      this.model.status.selectedObj=data;
    }
  }
  saveData(){
  
    this.error=new ReassignError();
    if(!this.data.BPMREASION){
      this.error.bpm=true;
    }
    if(!this.data.USERREASON){
      this.error.user=true;
    }
    if(this.type==="new"){
     this.data.NEWBPMID=this.model.contractor.selectedObj.id;
     this.data.NEWASSIGNEE=this.model.user.selectedObj.id;
     this.data.TICKETID=this.model.workOrder.selectedObj.id;
     this.data.TICKETSTATUSID=this.model.workOrder.selectedObj.ticketStatusId;
     this.data.ASSIGNEE=this.model.workOrder.selectedObj.assignee;
     this.data.BPMID=this.model.workOrder.selectedObj.bpmid;
    
       this.data.saveData()
       .then((result)=>{
           console.log("sdfsfs",result);
            this.getData()
            this.opened=false;
       })
       .catch((err)=>{
         
       })
    }
    // else if(this.type=="update"){
    //   this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
    //   .then((result)=>{
    //        console.log("save",result)
    //        this.getData()
    //        this.opened=false;
    //   })
    //   .catch((err)=>{
    //      console.log("err",err)
    //   })
    // }
  }
  reset(){

  }
  
  getData(){
    this.model.getData(ModelApi.ticketreassignment)
    .then((result)=>{
     // console.log("workorder=>",this.workorder)
      this.task=this.model.ticketReassign.alldata.filter((d)=>d.TICKETID==this.workorder.id).map((d)=>{
        if(d.ID){
          d["workOrderObj"]=this.model.workOrder.objectData[d.TICKETID];
          d["assigneeObj"]=this.model.user.objectData[d.ASSIGNEE];
          d["assignToObj"]=this.model.contractor.objectData[d.BPMID];
          d["newAssigneeObj"]=this.model.user.objectData[d.NEWASSIGNEE];
          d["newAssigneeToObj"]=this.model.contractor.objectData[d.NEWBPMID];
        }
        return d;
       })
    })
  }
  editColumn(d){
   this.dailogTitle="Update Ticket Reassignment"
     this.data.ID=d.dataItem.ID;
    //  this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
    //  this.model.checklist.selectedObj=d.dataItem["checkListObj"];
    //  this.type="update";
    //  this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }

}
