export class calender{
    loggedInUserId: number;
    id: number;
    name: string;
    year: number;
    remark:string;
    createdBy:number;
    createdDate:Date;
    modifiedBy:number;
    modifiedDate:Date
    holidayCalendars: [
      {
        loggedInUserId: number;
        id: number;
        calendarId: number;
        holidayId: number;
        holidayName: number;
        date:string;
        dateString: string
      }
    ]
  }
export class CalenderError{
  name: boolean;
  year: boolean;
  holidayCalendars:boolean;
  constructor(status){
    this.name=status;
    this.year=status;
    this.holidayCalendars=status
  }
}