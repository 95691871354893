

<div class="card m-3">
    <div class="card-header" >
        <h5>Answer Type</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Answer type
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid 
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
        [deleteFlag]="false"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           {{dailogTitle}}
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                        <div class="form-group col-10">
                            <label>DESCRIPTION</label>
                            <input kendoTextBox [(ngModel)]="data.DESCRIPTION"  class="form-control"/>
                        </div>
                      </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                          <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button kendoButton   primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                      </div>
                  
                    </div>
</kendo-dialog>