import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class AssetModel{ 
    id:number;
    description:string;
    name:string;
    assetNumber:string;
    assetCode:string;
}

export class AssetModelObj{
    alldata:Array<AssetModel>=[]
    filterData:Array<AssetModel>=[]
    selectedObj:AssetModel=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AssetModel)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/MasterData/SelectAssetModels",true,true)
            .then((result:Array<AssetModel>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }
}