import HttpClient from "src/Util/ApiHandling";
import { Component } from '@angular/core';
import { Attechment, AttechmentObj } from 'src/ApiModel/Model/attechment';
import { environment } from "src/environments/environment";
import { eventListner, EVENT_NAME } from "src/event";
import { LicenseAndCertRegistration } from "src/app/models/license&cert.model";
import { NotificationService } from "@progress/kendo-angular-notification";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { licenseRegValidation } from "src/validator/license";
import { userid } from "src/Util/const";
import { data } from "jquery";
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";


@Component({
  selector: 'app-license-component',
  templateUrl: './licensce-certificate.component.html',
  styleUrls: ['./licensce-certificate.component.scss']
})
export class LicsenceCertificateComponent {
  public notiDateDisabled :boolean = false
  public contectGrid = []
  public nonAssetGrid = []
  public permission:Permission=new Permission();
  public isAccess: boolean = false;
  public ReferenceNoDisable: boolean = false
  public nonAssetData =[]
  public assetData =[]
  public assetTitle =""
  public btnChangedTabValue =""
  public updatedTitle  =""
  public removeType = "new"
  public min: Date = new Date(2000, 2, 10);
  public mininspect:Date = new Date(2000, 2, 10);
  public assetTabShow:boolean = false;
  public nonassetTabShow:boolean = false;
  public renewValue:boolean = false
  public tabTemplateOpened :boolean = false;
  public personInChargeGrid = []
  public personInChargeValue =[]
  public historyGridData =[]
  public dataOnTabSetRelated:any =[]
  public actionsLayout = "normal";
  public dailogTitle = "";
  public type = "";
  public facilityName;
  public removeDialogOpen: boolean = false
  public opened: boolean = false;
  public Updateopened: boolean = false;
  public attechForm: Attechment = new Attechment();
  public isCompany: boolean = false;
  public isPersonal: boolean = false;
  public attechmentDoc = new AttechmentObj()
  public attechId: number = -1;
  public MODULEHIEARCHYID;
  public contractVendorValue: any = []
  public companyName;
  public designationName;
 
  public gridData = [];
  public afterSelectionTabLicenseID ;
  public assetNoDropDown =[]
  public personInChargeData =[]
  public attechError: { URL: boolean, FILENAME: boolean } = { URL: false, FILENAME: false }
  public categoryDropDown = [
    { id: 1, name: "Asset" },
    { id: 2, name: "Company" },
    { id: 3, name: "Personnel" },
    { id: 4, name: "Non Asset" },
  ]

  public companyType = [
    { id: 1, name: "Facility" },
    { id: 2, name: "Contractor and Vendor" },
  ]

  public serviceDropDown: any = [];
  public compentencyDropDown: any = [];
  public facilityDropDown: any = [];
  public contractorVenDropDown: any = [];
  public staffDropDown: any = [];
  public designationValue: any = []
  public nonAssetValue: any = []
  public personInChargeArray: any = []
  public titleOfTab =""
  public licenseId;
  public compentencyObj: any = {};
  public serviceObj: any = {};
  public staffObj: any = {};
  public facilityObj: any = {};
  public categoryObj: any = {};
  public companyObj: any = {};
  public emailObj: any = {};
  public assetObj:any ={}
  public designationObj:any = {}
  public assetDescObj:any ={}
  public licenseImage =[
    {id:"pdf",name:"pdf"},
    {id:"doc",name:"doc"},
    {id:"jpg",name:"jpg"},
    {id:"png",name:"png"}
  ];
  public format = "dd/MM/yyyy";
  public licenseRegForm: LicenseAndCertRegistration = new LicenseAndCertRegistration();
  error = licenseRegValidation(this.licenseRegForm, "init");
  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    // eventListner(EVENT_NAME.edit_column, (e) => {
    //   this.editColumnAfterUpdate(e);
    // });
    eventListner(EVENT_NAME.renew_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  ngOnInit() {
    Promise.all([
      this.getLoockup("dbo", "ServicesType"),
      this.getLoockup("dbo", "skill"),
      this.getLoockup("dbo", "facility"),
      this.getBpm(),
      this.getStaff(),
      this.getLoockup("dbo", "Designation"),
    ]).then((result:any) => {
      this.serviceDropDown = result[0];
      this.serviceDropDown.sort((a,b)=>{
        return a.NAME.localeCompare(b.NAME)
      })
      this.categoryDropDown.sort((a,b)=>{
        return a.name.localeCompare(b.name)
      })
      this.compentencyDropDown = result[1]
      this.compentencyDropDown.sort((a,b)=>{
        return a.NAME.localeCompare(b.NAME)
      })
      this.facilityDropDown = result[2]
      this.facilityDropDown.sort((a,b)=>{
        return a.NAME.localeCompare(b.NAME)
      })
      this.contractorVenDropDown = result[3]
      this.contractVendorValue = result[3]
      this.staffDropDown = result[4]
      this.designationValue = result[5]
      this.getLicenseCerData()
    })
    this.min = new Date(new Date) ;
    this.min.setDate(this.min.getDate()+1)

    if(UserModuleObj.license_and_certificate){
      this.permission=UserModuleObj.license_and_certificate;
      this.isAccess = true;
    }else{
      this.isAccess = false;
    }
  }
  public column = [
    { field: "registrationNo", title: "Registration No/Reference No.", isFilter: true, width: 250 },
    { field: "licenseNo", title: "License No.", isFilter: true, width: 200 },
    { field: "description", title: "License and Certificate Description", isFilter: true, width: 260 },
    { field: "category", title: "Category", isFilter: true, width: 200 },
    { field: "issuingBody", title: "Issuing Body", isFilter: true, width: 200 },
    { field: "service", title: "Service Type", isFilter: true, width: 200 },
    { field: "compentency", title: "Competency", isFilter: true, width: 200 },
    { field: "staff", title: "StaffName", isFilter: true, width: 200 },
    { field: "licenseIssuingDate", title: "LicenseIssuingDate", isFilter: true, width: 200 },
    { field: "expiryDate", title: "Expiry", isFilter: true, width: 200 },
    { field: "notificationForInspection", title: "NotificationForInspection", isFilter: true, width: 200 },
    { field: "inspectionConductedOn", title: "inspectionConductedOn", isFilter: true, width: 200 },
    { field: "nextInspectionDate", title: "NextInspectionDate", isFilter: true, width: 200 },
  ];


  public Historycolumn = [
    { field: "registrationNo", title: "Registration No/Reference No.", isFilter: true, width: 250 },
    { field: "licenseNo", title: "License No.", isFilter: true, width: 200 },
    { field: "description", title: "License and Certificate Description", isFilter: true, width: 260 },
    { field: "category", title: "Category", isFilter: true, width: 200 },
    { field: "issuingBody", title: "Issuing Body", isFilter: true, width: 200 },
    { field: "service", title: "Service Type", isFilter: true, width: 200 },
    { field: "compentency", title: "Competency", isFilter: true, width: 200 },
    { field: "expiryDate", title: "Expiry Date", isFilter: true, width: 200 },
   
  ];

  public monthDayList = [
    { full: "January", short: "Jan", day: "Sun" },
    { full: "February", short: "Feb", day: "Mon" },
    { full: "March", short: "Mar", day: "Tue" },
    { full: "April", short: "Apr", day: "Wed" },
    { full: "May", short: "May", day: "Thr" },
    { full: "June", short: "Jun", day: "Fri" },
    { full: "July", short: "Jul", day: "Sat" },
    { full: "Augest", short: "Aug", day: "Sun" },
    { full: "September", short: "Sep", day: "" },
    { full: "October", short: "Oct", day: "" },
    { full: "November", short: "Nov", day: "" },
    { full: "December", short: "Dec", day: "" },
  ]

  public task = [
    { "refNo": "CRM/2022/0000000285", "licNo": "CRM/2022/0000000285", "li&c": "testing" },
  ]

  editColumn(data) {

    if (data.action === "remove") {
      this.removeDialogOpen = true;
      this.dataOnTabSetRelated=data.dataItem
      this.licenseRegForm.id = data.dataItem.id
      this.type = "remove"
    }

    if (data.action === "remove" && (this.titleOfTab == "Asset" || this.titleOfTab == "Non-Asset" || this.titleOfTab == "Person In Charge")) {
      this.removeDialogOpen = true;
      this.dataOnTabSetRelated=data.dataItem
      this.afterSelectionTabLicenseID = data.dataItem.licenseCertificateId
      this.licenseRegForm.id = data.dataItem.licenseCertificateId
      this.type = "removeWIthTab"
    }

    if (data.action === "edit") {
        this.licenseRegForm.id = data.dataItem.id
        this.licenseId =  this.licenseRegForm.id
        this.getLicenseById(data.dataItem.id)
          this.dataOnTabSetRelated=data.dataItem
          this.updatedTitle = "Update License & Certificate Details  "
        this.Updateopened = true;
        this.ReferenceNoDisable = true;
        this.type = "update"
       }

       if(data.action === "edit" && this.titleOfTab == "Asset"){
        this.contectGrid = []
        this.licenseRegForm.id = data.dataItem.licenseCertificateId
          this.contectGrid.push(data.dataItem)
          this.contectGrid.map((e)=>{
            e["assetName"] = e.assetId
            e["action"] = "licenseEdit"
            e["length"] = data.dataItem.length
            return
          })

          this.openRoleDailog("updateAsset")
       }
       else{
         this.contectGrid = []
       }

       if(data.action === "edit" && this.titleOfTab == "Non-Asset"){
        this.licenseRegForm.id = data.dataItem.licenseCertificateId
        this.nonAssetGrid =[]
        this.nonAssetGrid.push(data.dataItem)
        this.nonAssetGrid.map((e)=>{
          e["action"] = "licenseEdit"
          e["length"] = data.dataItem.length
          return
        })

        this.openRoleDailog("updatenonAsset")
     }
     else{
       this.nonAssetGrid = []
     }
     if(data.action === "edit" && this.titleOfTab == "Person In Charge"){
      this.licenseRegForm.id = data.dataItem.licenseCertificateId
      this.personInChargeGrid = []
      this.personInChargeGrid.push(data.dataItem)
      this.personInChargeGrid.map((e)=>{
        e["action"] = "licenseEdit"
        e["personInChargeName"] = e.personInChargeId
        e["length"] = data.dataItem.length
        return
      })

      this.openRoleDailog("updatePIC")
   }
   else{
     this.personInChargeGrid = []
   }

    if(data.action === "renew"){
      this.licenseRegForm.id =  data.data.id
      this.getLicenseById(data.data.id)
      this.dataOnTabSetRelated=data.dataItem

    this.Updateopened = true;
    this.updatedTitle = "Renew License & Certificate Details "
    this.type = "update"
    }

  }

  // editColumnAfterUpdate(data){
  //       console.log("hhhhhhhhh",data)
  // }

  removeColumn(data) {
    console.log(data)

  }

 
  public documentFormate = [
    { id: "pdf", name: "pdf" },
    { id: "jpg", name: "jpg" },
    { id: "png", name: "png" },
  ];
  public onTabSelect(e) {
    if(e.title ==="Registration"){
      this.getLicenseById(this.licenseRegForm.id);
      this.titleOfTab = "Registration"
      this.tabTemplateOpened = false
    }
    if(e.title ==="Person In Charge"){
      this.getpersonInChargeData(this.licenseRegForm.id);
      this.titleOfTab = "Person In Charge"
      this.tabTemplateOpened = false
    }
    if(e.title === "Non-Asset"){
      this.getnonAssetData(this.licenseRegForm.id)
      this.titleOfTab = "Non-Asset"
      this.tabTemplateOpened = false
    }
    if(e.title === "Asset"){
      this.getAssetRegistrationData().then((e)=>{
        this.getAssetData(this.licenseRegForm.id);
        this.titleOfTab = "Asset"
        this.tabTemplateOpened = false
      })
       }
       if(e.title === "History"){
        this.getHistoryData()
      }
    console.log(e);
  }


  public contectColumn = [
    {
      field: "assetName",
      title: "Asset No.",
      type: "dropdown",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
      isFilter: false,
      width: 140
    },
    {
      field: "assetDes",
      title: "Asset Type Code Description",
      isFilter: false,
      type: "disabledData",
      disable:"true",
      width: 140,
      errorField: "designation_error",
      validation: (val) => {
        if (val)
          return ""

      },

    },
    {
      field: "remarks",
      title: "Remark",
      type: "input",
      isFilter: false,
      width: 140,
      errorField: "remark_error",
      validation: (val) => {
        if (val)
          return ""

      },
    },

  ]


  public NonAssetColumn = [
    {
      field: "nonAssetDesc",
      title: "Non Asset Description",
      type: "input",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
      isFilter: false,
      width: 140
    },
    {
      field: "remarks",
      title: "Remarks",
      isFilter: false,
      type: "input",
      width: 140,
      errorField: "designation_error",
      validation: (val) => {
        if (val)
          return ""
      },
    },

  ]


  peronInChargeGridColumn =[
    { field: "registrationNo", title: "Registration No/Reference No.", isFilter: true, width: 200 },

  ]

  personInChargeColumn = [
    {
      field: "personInChargeName",
      title: "Person In Charge Name ",
      type: "userDropDown",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
      isFilter: false,
      width: 140
    },
    {
      field: "designation",
      title: "Designation",
      type: "disabledData",
      disable:"true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "emailAddress",
      title: "Email address ",
      type: "disabledData",
      disable:"true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "companyName",
      title: "Company Name",
      type: "disabledData",
      disable:"true",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "remarks",
      title: "Remarks",
      type: "input",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
  ]

  public column2 = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true },
  ];

  addAttechment() {
    if (!this.attechForm.URL) {
      this.attechError.URL = true
    }
    else {
      this.attechError.URL = false
    }
    if (!this.attechForm.FILENAME) {
      this.attechError.FILENAME = true
    }
    else {
      this.attechError.FILENAME = false
    }
    if (!this.attechError.URL && !this.attechError.FILENAME) {
      this.attechForm.FILECONTENTS = this.attechForm.URL;
      this.attechForm.MODULEHIERARCHYID = this.MODULEHIEARCHYID;
      this.attechForm.REFERENCEID = this.licenseRegForm.id;
      this.attechForm.saveData()
        .then((result: any) => {
            HttpClient.insertAduitLog("Engineering Records","Add Attachment in License & Certificate","Add Attachment in License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {
    
            }
          })
          this.attechForm.ID = result[0]?.id;
          this.attechmentDoc.getData(this.attechId, this.MODULEHIEARCHYID)
            .then((result) => {
              //this.attechmentDoc = JSON.parse(JSON.stringify(this.attechmentDoc));
              this.attechForm = new Attechment();
            })
          // this.attechmentDoc.alldata.push(this.attechForm);
          // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  picreset(){
    this.personInChargeGrid = []
  }
  saveAttechment(data) {
    let obj = {
      schemaName: "dbo",
      tableName: "attachment",
      userId: 1,
      newData: JSON.stringify(data),
    };
    return new Promise((resolve, reject) => {
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        obj,
        true,
        true
      )
        .then((response) => {
          HttpClient.insertAduitLog("Engineering Records","Add Attachment in License & Certificate","Add Attachment in License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {
    
            }
          })
          resolve(response);
          //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName)
          // this.opened=false;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  attachementColumn(data: any) {
    this.attechForm.ID = 0;
    this.attechForm.FILETYPE = "pdf";
    this.attechForm.FILENAME = ""
    this.attechForm.URL = ""
    this.attechForm.MODULEHIERARCHYID = this.MODULEHIEARCHYID;
    this.attechForm.REFERENCEID = data.id;


    this.attechError = { URL: false, FILENAME: false }

    // this.attechForm.EMPLOYEEGLOBALID=data.id;
    this.attechId = data.id;
    this.attechmentDoc = new AttechmentObj();
    this.attechmentDoc.getData(data.id, this.MODULEHIEARCHYID)
      .then((result: any) => {


        //this.attachmentOpened = true;
        //this.dailogTitle = "Add Attachment"
      })
      .catch((err) => {
        this.attechmentDoc.alldata = []
      })
  }
  deleteAttechment(e) {
    this.attechForm.deleteData(e.dataItem)
      .then((result) => {
        this.attechmentDoc.alldata = this.attechmentDoc.alldata.filter((d, i) => i !== e.rowIndex);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  downloadAttechment(data) {
    window.open(environment.backendUrl + data.URL);
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData);

      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Spare Parts Reg";
      });
      // this.facilityParentId = ModFacArr[0].PARENTID
      this.MODULEHIEARCHYID = ModFacArr[0].ID;
    });
  }

  onDialogClose(type) {
    
    // console.log("type",type)
    if(type=== "new"){
      this.opened = false;
      this.titleOfTab = ""
    }

    if(type == "update"){
        this.Updateopened = false;
        this.titleOfTab = ""
    }
    if(type== "tabType"){
      this.tabTemplateOpened = false;
    }

    // if(type== "tabType" && this.titleOfTab === "Non-Asset"){
    //   this.tabTemplateOpened = false;
    //   this.titleOfTab = "Non-Asset"
      
    // }
    // if(type== "tabType" && this.titleOfTab === "Asset"){
    //   this.tabTemplateOpened = false;
    //   this.titleOfTab = "Asset"
    //   this.licenseId = this.
    // }
    // if(type== "tabType" && this.titleOfTab === "Person In Charge"){
    //   this.tabTemplateOpened = false;
    //   this.titleOfTab = "Person In Charge"
    // }
   }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.dailogTitle = "Add License&Certificate Details"
      this.opened = true;
      this.licenseRegForm = new LicenseAndCertRegistration()
      this.error = licenseRegValidation( this.licenseRegForm, "init");
    }
    if(type === "newAsset"){
      this.assetTitle = "Add Asset"
      this.tabTemplateOpened = true;
      this.contectGrid = []
      this.nonAssetGrid = []
      this.personInChargeGrid =[]
    }

    if(type === "updateAsset"){
      this.assetTitle = "Update Asset"
      this.tabTemplateOpened = true;
    }
    if(type === "newnonAsset"){
      this.assetTitle = "Add Non-Asset"
      this.tabTemplateOpened = true;
      this.contectGrid = []
      this.nonAssetGrid = []
      this.personInChargeGrid =[]
    }

    if(type === "updatenonAsset"){
      this.assetTitle = "Update Non-Asset"
      this.tabTemplateOpened = true;
    }

    if(type === "newPIC"){
      this.assetTitle = "Add Person In Charge"
      this.tabTemplateOpened = true;
      this.contectGrid = []
      this.nonAssetGrid = []
      this.personInChargeGrid =[]
    }

    if(type === "updatePIC"){
      this.assetTitle = "Update Person In Charge"
      this.tabTemplateOpened = true;
    }
  }

  onReset() {
    this.licenseRegForm = new LicenseAndCertRegistration()
    this.error = licenseRegValidation( this.licenseRegForm, "init");
  }

  categoryValueChange(data) {
    if (data == 2) {
      this.isPersonal = false;
      this.isCompany = true;
    }
    else if (data == 3) {
      this.isPersonal = true;
      this.isCompany = false
    }

    else {
      this.isCompany = false;
      this.isPersonal = false;
    }

  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData);
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getBpm() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/BPM/GetBpms", true, true)
        .then((result: any) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  getStaff() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Users/GetAllUser", true, true)
        .then((result: any) => {
          resolve(result);
        }).catch((err) => {
          reject(err)
        })
    })
  }

  onSubmit() {
      this.licenseRegForm.id = (this.type == "new") ? 0 : this.licenseRegForm.id;
      this.licenseRegForm.expiryDate = this.licenseRegForm.expiryDate === undefined?null:this.convertLocalDateString(this.licenseRegForm.expiryDate)
      this.licenseRegForm.notificationForInspection = this.licenseRegForm.notificationForInspection === undefined?null:this.convertLocalDateString(this.licenseRegForm.notificationForInspection)
      this.licenseRegForm.inspectionConductedOn = this.licenseRegForm.inspectionConductedOn === undefined?null: this.convertLocalDateString(this.licenseRegForm.inspectionConductedOn)
      this.licenseRegForm.nextInspectionDate = this.licenseRegForm.nextInspectionDate === undefined?null: this.convertLocalDateString(this.licenseRegForm.nextInspectionDate)
      this.licenseRegForm.modifiedDate = this.convertLocalDateString(new Date());

    if (this.type == "new") {
      this.licenseRegForm.licenseCertificateAssets = []
      this.licenseRegForm.licenseCertificateHistories = []
      this.licenseRegForm.licenseCertificateNonAssets = []
      this.licenseRegForm.createdDate = this.convertLocalDateString(new Date());
      console.log(this.licenseRegForm.nextInspectionDate)
    }
   
    this.error = licenseRegValidation(this.licenseRegForm, "");
    if (!this.error.categoryId || !this.error.registrationNo) {
      this.addLicenseData()
    }
  }

  addLicenseData() {
    this.licenseRegForm.licenseIssuingDate = this.convertLocalDateString(this.licenseRegForm.licenseIssuingDate)
    if (this.type == "new") {
      const params = {
        ...this.licenseRegForm
      };

      HttpClient.fetchData("/api/License/AddLicenseCertificate", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Add License & Certificate","Add License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.opened = false;
          this.getLicenseCerData()
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
    if (this.type == "update") {
      //this.licenseRegForm.licenseIssuingDate = new Date(this.licenseRegForm.licenseIssuingDate)
      const params = {
        ...this.licenseRegForm
      };
     console.log(this.licenseRegForm)
      HttpClient.fetchData("/api/License/UpdateLicenseCertificate", "post", params, true, true).then((result: any) => {
        this.getLicenseCerData()
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getLicenseCerData()
          this.opened = false;
        }
      })
    }
  }

  bindCompentencyObj(result) {
    this.compentencyObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindServiceObj(result) {
    this.serviceObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindStaffObj(result) {
    this.staffObj = result.reduce((acc, d) => {
      acc[d.id] = d.staffName;
      return acc;
    }, {})

    this.companyObj = result.reduce((acc, d) => {
      acc[d.id] = d.companyName;
      return acc;
    }, {})

    this.emailObj = result.reduce((acc, d) => {
      acc[d.id] = d.userName;
      return acc;
    }, {});

  }


  bindcategoryObj(result) {
    this.categoryObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindfacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }
  pading(n) {
    if (n > 9)
      return n;
    else
      return "0" + n
  }
  dateFormating(da) {
    let df = new Date(da);
    return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }
  convertLocalDateString(d):any{
    let ndate=new Date(d);
    return `${ndate.getFullYear()}-${this.pading(ndate.getMonth()+1)}-${this.pading(ndate.getDate())}T00:00:00`
  }
  getLicenseCerData() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/License/GetLicenseCertificate", true, true)
        .then((result: any) => {
          let data = shiftLastDataInto1st(result)
          this.bindfacilityObj(this.facilityDropDown);
          this.bindCompentencyObj(this.compentencyDropDown);
          this.bindServiceObj(this.serviceDropDown);
          this.bindStaffObj(this.staffDropDown);
          this.bindcategoryObj(this.categoryDropDown);

          this.gridData = data.map((d,index) => {
            d["soNo"] = index+1
            d["compentency"] = this.compentencyObj[d.competencyId]
            d["service"] = this.compentencyObj[d.serviceTypeId]
            d["staff"] = this.staffObj[d.staffId];
            d["category"] = this.categoryObj[d.categoryId];
 
            d["inspectionConductedOn"] = d.inspectionConductedOn == null ? "":this.dateFormating(new Date(d.inspectionConductedOn))
            d["licenseIssuingDate"] =  d.licenseIssuingDate == null ? "":this.dateFormating(new Date(d.licenseIssuingDate))
            d["nextInspectionDate"] =  d.nextInspectionDate == null ? "":this.dateFormating(new Date(d.nextInspectionDate))
            d["notificationForInspection"] =  d.notificationForInspection == null ? "":this.dateFormating(new Date(d.notificationForInspection))
            d["expiryDate"] =  d.expiryDate == null ? "":this.dateFormating(new Date(d.expiryDate))
            d["deleteKey"]= new Date(d.expiryDate)<new Date()?true:false;
            d["renewKeyValue"] = this.checkExpiryDate(d.expiryDate);

            return d;
          })

          console.log(this.gridData)
        })
    })
  }


  checkExpiryDate(date){
    let todayDate = new Date();
    let reciveDate = new Date(date);
    let getMonthVlaue = reciveDate.getMonth()-2;

    if(  todayDate.getFullYear() ==  reciveDate.getFullYear() && todayDate.getMonth()>= getMonthVlaue){
      return this.renewValue = true;
    }
    else{
      return this.renewValue = false;
    }

  }



  staffNameChange(data) {
    let staffValue = this.staffDropDown.filter((e) => {
      return e.id === data;
    })
    this.companyName = staffValue[0].companyName
    let designationId = staffValue[0].designationId
    let designationValue = this.designationValue.filter((e) => {
      return e.ID === designationId
    })
    this.designationName = designationValue[0].NAME
  }

  contaractorValueChange(data) {
    let value = this.contractorVenDropDown.filter((e => {
      return e.id == data
    }))
    this.facilityName = value[0].name
  }

  companyTypeChange(data) {
    this.facilityName = ""
    this.contractorVenDropDown = [];
    if (data === 1) {
      this.contractorVenDropDown = this.facilityDropDown.map((e => {
        return { id: e.ID, registrationNo: e.FACILITYCODE, name: e.NAME }
      }));
    }
    else {
      this.contractorVenDropDown = this.contractVendorValue
    }
  }

  getLicenseById(id) {
    HttpClient.get(`/api/License/GetLicenseCertificateById?id=${id}`, true, true)
      .then((result: any) => {
        this.licenseRegForm = result;
        this.licenseRegForm.id = result.id
        // this.licenseRegForm.nextInspectionDate = new Date(result.nextInspectionDate)
        // this.licenseRegForm.notificationForInspection = new Date(result.notificationForInspection)
        
       let after8hoursDate = this.changeDateAfterEightHours(this.licenseRegForm.notificationForInspection);
           if(new Date()>=after8hoursDate){
             this.notiDateDisabled = false;
           }
           else{
            this.notiDateDisabled = true;
           }
        this.licenseRegForm.licenseIssuingDate = result.licenseIssuingDate === null?null:new Date(result.licenseIssuingDate)
        this.licenseRegForm.expiryDate = result.expiryDate === null?null:new Date(result.expiryDate)
        this.licenseRegForm.inspectionConductedOn = result.inspectionConductedOn === null?null:new Date(result.inspectionConductedOn)
        this.licenseRegForm.nextInspectionDate = result.nextInspectionDate === null?null:new Date(result.nextInspectionDate)
        this.licenseRegForm.notificationForInspection = result.notificationForInspection === null?null:new Date(result.notificationForInspection)

      })
  }

  

  getValueOfAsset(data) {
   
    this.licenseRegForm.licenseCertificateAssets = []
     
    if(data[0].id!=0){
      for (let i = 0; i < data.length; i++) {
        this.licenseRegForm.licenseCertificateAssets.push({
          id: data[i].id, licenseCertificateId: data[i].licenseCertificateId, assetId: data[i].assetName,
          remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
        });
      }

      this.updateAsset()
    }
    else{
      for (let i = 0; i < data.length; i++) {
      this.licenseRegForm.licenseCertificateAssets.push({
        id: 0, licenseCertificateId: this.licenseRegForm.id, assetId: data[i].assetName,
        remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
      });
    }

  }
  
  
  }

  getNonAsset(data) {
    this.nonAssetValue = []
    if(data[0].id!=0){
      for (let i = 0; i < data.length; i++) {
        this.nonAssetValue.push({
          id: data[i].id, licenseCertificateId: data[i].licenseCertificateId, description: data[i].nonAssetDesc,
          remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
        });
      }
      this.updateNonAsset()
    }
   
     else{
      for (let i = 0; i < data.length; i++) {
      this.nonAssetValue.push({
        id: 0, licenseCertificateId:  this.licenseRegForm.id, description: data[i].nonAssetDesc,
        remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
      });
     }
    }
    
  }

  personchargeValue(data) {
    this.personInChargeArray = []
  
      if(data[0].id!=0){
        for (let i = 0; i < data.length; i++) {
      this.personInChargeArray.push({
        id: data[i].id, licenseCertificateId: data[i].licenseCertificateId, personInChargeId: data[i].personInChargeName,
        remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
      });
    }
    this.updatePersonInCharge()
    }
    else{
      for (let i = 0; i < data.length; i++) {
      this.personInChargeArray.push({
        id: 0, licenseCertificateId: this.licenseRegForm.id, personInChargeId: data[i].personInChargeName,
        remarks: data[i].remarks, createdDate: new Date(), createdBy: parseInt(getCookie(userid))
      });
    }
    
  }
 
  }


  addAsset() {
    let params = this.licenseRegForm.licenseCertificateAssets
    if(params.length>0){
      HttpClient.fetchData(`/api/License/AddAssetList`, "post", params, true, true)
      .then((result: any) => {
        if (result.isValid === true) {
          HttpClient.insertAduitLog("Engineering Records","Add AssetList in License & Certificate","Add AssetList of License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.tabTemplateOpened = false;
          this.getAssetData(params[0].licenseCertificateId)
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
   
  }

  updateAsset(){
      let params:any = Object.assign({}, ...this.licenseRegForm.licenseCertificateAssets);
      HttpClient.fetchData(`/api/License/UpdateAsset`, "post", params, true, true)
       .then((result: any) => {
         if (result.isValid === true) {
          HttpClient.insertAduitLog("Engineering Records","Update AssetList in License & Certificate","Update AssetList of License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
           this._notif.show({
             content: result.messageKey,
             type: { style: "success", icon: true },
           });
           this.tabTemplateOpened = false;
           this.licenseRegForm.id = params.licenseCertificateId
           this.getAssetData(params.licenseCertificateId)
         }
         else {
           this._notif.show({
             content: result.messageKey,
             type: { style: "error", icon: true },
           });
         }
       })
     }

  updateNonAsset(){
    let params = Object.assign({}, ...this.nonAssetValue);
    HttpClient.fetchData(`/api/License/UpdateNonAsset`, "post", params, true, true)
     .then((result: any) => {
       if (result.isValid === true) {
        HttpClient.insertAduitLog("Engineering Records","Update NonAsset in License & Certificate","Update NonAsset of License & Certificate Engineering Records").then((res:any)=>{
          if (res.isValid == true) {   
          }
        })
         this._notif.show({
           content: result.messageKey,
           type: { style: "success", icon: true },
         });
         this.tabTemplateOpened = false;
         this.licenseRegForm.id = params.licenseCertificateId
         this.getnonAssetData(params.licenseCertificateId)
       }
       else {
         this._notif.show({
           content: result.messageKey,
           type: { style: "error", icon: true },
         });
       }
     })
}

updatePersonInCharge(){
  let params = Object.assign({}, ...this.personInChargeArray);
    HttpClient.fetchData(`/api/License/UpdateLicenseCertificatePersonInChange`, "post", params, true, true)
     .then((result: any) => {
       if (result.isValid === true) {
        HttpClient.insertAduitLog("Engineering Records","Update PersonInChange in License & Certificate","Update PersonInChange of License & Certificate Engineering Records").then((res:any)=>{
          if (res.isValid == true) {   
          }
        })
         this._notif.show({
           content: result.messageKey,
           type: { style: "success", icon: true },
         });
         this.tabTemplateOpened = false;
         this.licenseRegForm.id = params.licenseCertificateId
         this.getpersonInChargeData(params.licenseCertificateId)
       }
       else {
         this._notif.show({
           content: result.messageKey,
           type: { style: "error", icon: true },
         });
       }
     })
}
  addNonAsstValue() {
    let params = this.nonAssetValue
    if(params.length>0){
      HttpClient.fetchData(`/api/License/AddNonAssetList`, "post", params, true, true)
      .then((result: any) => {
        HttpClient.insertAduitLog("Engineering Records","Add NonAsset in License & Certificate","Add NonAsset of License & Certificate Engineering Records").then((res:any)=>{
          if (res.isValid == true) {   
          }
        })
        if (result.isValid === true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.tabTemplateOpened = false;
          this.getnonAssetData(params[0].licenseCertificateId)
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
  
  }

  addPersonInCharge() {
    let params = this.personInChargeArray
    if (params.length>0){
      HttpClient.fetchData(`/api/License/AddPersonInChangeList`, "post", params, true, true)
      .then((result: any) => {
        if (result.isValid === true) {
          HttpClient.insertAduitLog("Engineering Records","Add PersonInChangeList in License & Certificate","Add PersonInChangeList of License & Certificate Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.tabTemplateOpened = false;
          this.getpersonInChargeData(params[0].licenseCertificateId)
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
  
  }

  bindDesignationObj(result){
    this.designationObj = result.reduce((acc,d)=>{
      acc[d.ID] = d.NAME
      return acc;
    },{})

  }

  getpersonInChargeData(id){
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/License/GetPersonInChangeList?licenseCertificateID=${id}`, true, true)
        .then((result: any) => {
          this.bindStaffObj(this.staffDropDown);
          this.bindDesignationObj(this.designationValue)
          this.personInChargeData = result.map((e)=>{
            e["personInChargeName"] = this.staffObj[e.personInChargeId];
            e["companyName"] = this.companyObj[e.personInChargeId];
            e["emailAddress"] = this.emailObj[e.personInChargeId];
          var designationdata = this.filteredPersonInCharge(e.personInChargeId)[0];
          if(designationdata != undefined){
            e["designation"] = this.designationObj[this.filteredPersonInCharge(e.personInChargeId)[0].designationId]
          }
             return e;
          })
         }).catch((err)=>{
          reject(err)
        })
    })
  }


  filteredPersonInCharge(data){
     let value =  this.staffDropDown.filter((e=>{
           if(e.id === data){
             return e.designationId;
           };
        })); 
      return value;
  }

  getnonAssetData(id){
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/License/GetNonAssetList?licenseCertificateID=${id}`, true, true)
        .then((result: any) => {
            console.log("res",result)
          this.nonAssetData = result.map((e)=>{
            e["nonAssetDesc"] = e.description
            return e;
          })
        })
      })
    }

    getAssetRegistrationData(){
      return new Promise((resolve,rejects)=>{
        HttpClient.get(
          "/api/Assets/GetAssets",
          true,
          true
        ).then((result: any) => {
           this.assetNoDropDown = result
          resolve(result);
      }).catch((e)=>{
        rejects(e)
         })
      })
    }
  
    bindAssetObj(result){
      this.assetObj = result.reduce((acc,d)=>{
        acc[d.id] = d.assetNumber;
        return acc; 
      },{})
      
      this.assetDescObj =  result.reduce((acc,d)=>{
        acc[d.id] = d.assetCode;
        return acc; 
      },{})
    }

    getAssetData(id){
      return new Promise((resolve, reject) => {
        HttpClient.get(`/api/License/GetAssetList?licenseCertificateID=${id}`, true, true)
          .then((result: any) => {
            this.bindAssetObj(this.assetNoDropDown)
            this.assetData = result.map((e)=>{
              e["assetName"] = this.assetObj[e.assetId];
              e["assetDes"] = this.assetDescObj[e.assetId]
              return e;
            })
          })
        })
      }

      removeTabLicense() {
      
          if(this.titleOfTab === "Asset"){
            let  obj={
              "id": this.dataOnTabSetRelated.id,
              "licenseCertificateId": this.dataOnTabSetRelated.licenseCertificateId,
              "assetId": this.dataOnTabSetRelated.assetId,
              "remarks": this.dataOnTabSetRelated.remarks,
              "createdDate": this.dataOnTabSetRelated.createdDate,
              "createdBy":this.dataOnTabSetRelated.createdBy
              }
              HttpClient.fetchData(`/api/License/DeleteAsset`, "post", obj, true, true)
          .then((result: any) => {
            if (result.isValid === true) {
              HttpClient.insertAduitLog("Engineering Records","Delete Asset in License & Certificate","Delete Asset of License & Certificate Engineering Records").then((res:any)=>{
                if (res.isValid == true) {   
                }
              })
              this._notif.show({
                content: result.messageKey,
                type: { style: "success", icon: true },
              });
              this.removeDialogOpen = false;
              this.licenseRegForm.id = this.dataOnTabSetRelated.licenseCertificateId;
              this.getAssetData(this.dataOnTabSetRelated.licenseCertificateId)
            }
            else {
              this._notif.show({
                content: result.messageKey,
                type: { style: "error", icon: true },
              });
            }
          })
            
          }
          if(this.titleOfTab === "Non-Asset"){
            let  obj={
              "id": this.dataOnTabSetRelated.id,
              "licenseCertificateId": this.dataOnTabSetRelated.licenseCertificateId,
              "description": this.dataOnTabSetRelated.nonAssetDesc,
              "remarks": this.dataOnTabSetRelated.remarks,
              "createdDate": this.dataOnTabSetRelated.createdDate,
              "createdBy":  this.dataOnTabSetRelated.createdBy
              }
              HttpClient.fetchData(`/api/License/DeleteNonAsset`, "post", obj, true, true)
          .then((result: any) => {
            if (result.isValid === true) {
              HttpClient.insertAduitLog("Engineering Records","Delete NonAsset in License & Certificate","Delete NonAsset of License & Certificate Engineering Records").then((res:any)=>{
                if (res.isValid == true) {   
                }
              })
              this._notif.show({
                content: result.messageKey,
                type: { style: "success", icon: true },
              });
              this.removeDialogOpen = false;
              // this.licenseId = this.dataOnTabSetRelated.licenseCertificateId;
              this.getnonAssetData(this.dataOnTabSetRelated.licenseCertificateId)
            }
            else {
              this._notif.show({
                content: result.messageKey,
                type: { style: "error", icon: true },
              });
            }
          })
            
          }
          if(this.titleOfTab === "Person In Charge"){
            let  obj={
              "id": this.dataOnTabSetRelated.id,
              "licenseCertificateId":this.dataOnTabSetRelated.licenseCertificateId,
              "personInChargeId": this.dataOnTabSetRelated.personInChargeId,
              "remarks": this.dataOnTabSetRelated.remarks,
              "createdDate":this.dataOnTabSetRelated.createdDate,
              "createdBy":this.dataOnTabSetRelated.createdBy,
              }
              HttpClient.fetchData(`/api/License/DeletePersonInChange`, "post", obj, true, true)
          .then((result: any) => {
            if (result.isValid === true) {
              HttpClient.insertAduitLog("Engineering Records","Delete PersonInChange in License & Certificate","Delete PersonInChange of License & Certificate Engineering Records").then((res:any)=>{
                if (res.isValid == true) {   
                }
              })
              this._notif.show({
                content: result.messageKey,
                type: { style: "success", icon: true },
              });
              this.removeDialogOpen = false;
              this.licenseRegForm.id = this.dataOnTabSetRelated.licenseCertificateId;
              this.getpersonInChargeData(this.dataOnTabSetRelated.licenseCertificateId)
            }
            else {
              this._notif.show({
                content: result.messageKey,
                type: { style: "error", icon: true },
              });
            }
          })
           }

          }


      removeLicense(){
        const params = {
          ...this.licenseRegForm
        };
        if(this.titleOfTab === ""){
        HttpClient.fetchData(`/api/License/DeleteLicenseCertificate`, "post", params, true, true)
          .then((result: any) => {
            if (result.isValid === true) {
              HttpClient.insertAduitLog("Engineering Records","Delete License & Certificate","Delete License & Certificate Engineering Records").then((res:any)=>{
                if (res.isValid == true) {   
                }
              })
              this._notif.show({
                content: result.messageKey,
                type: { style: "success", icon: true },
              });
              this.removeDialogOpen = false;
              this.getLicenseCerData()
            }
            else {
              this._notif.show({
                content: result.messageKey,
                type: { style: "error", icon: true },
              });
            }
          })
        }
           }
      
      getHistoryData(){
        return new Promise((resolve, reject) => {
          HttpClient.get(`/api/License/GetLicenseCertificateHistory?id=${this.licenseRegForm.id}`, true, true)
            .then((result: any) => {
              let data = shiftLastDataInto1st(result)
              this.bindfacilityObj(this.facilityDropDown);
              this.bindCompentencyObj(this.compentencyDropDown);
              this.bindServiceObj(this.serviceDropDown);
              this.bindStaffObj(this.staffDropDown);
              this.bindcategoryObj(this.categoryDropDown);
              console.log("historyData",result)
    
              this.historyGridData = data.map((d) => {
                d["compentency"] = this.compentencyObj[d.competencyId]
                d["service"] = this.compentencyObj[d.serviceTypeId]
                d["staff"] = this.staffObj[d.staffId];
                d["category"] = this.categoryObj[d.categoryId];
                d["inspectionConductedOn"] = this.dateFormating(new Date(d.inspectionConductedOn))
                d["licenseIssuingDate"] = this.dateFormating(new Date(d.licenseIssuingDate))
                d["nextInspectionDate"] = this.dateFormating(new Date(d.licenseIssuingDate))
                d["notificationForInspection"] = this.dateFormating(new Date(d.notificationForInspection))
                d["expiryDate"] = this.dateFormating(new Date(d.expiryDate))
    
                return d;
              })
            })
        })
      }

      licenseValueChange(date){
       this.min = new Date(date) ;
       this.min.setDate(this.min.getDate()+1)
   }
   inspectionConductionChange(date){
    this.mininspect = new Date(date) ;
    this.mininspect.setDate(this.mininspect.getDate()+1)
   }

   download() {
    let fileName = 'license&certification.csv';
    let columnNames = [
"soNo",
"registrationNo",
"licenseNo",
"description", 
"category",
"issuingBody", 
"service",
"compentency", 
"staff", 
"licenseIssuingDate", 
"expiryDate", 
"notificationForInspection", 
"inspectionConductedOn",
"nextInspectionDate", 
 
    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
  this.gridData.map(c => {
      csv += [
        c["soNo"],
        c["registrationNo"],
        c["licenseNo"],
        c["description"], 
        c["category"],
        c["issuingBody"], 
        c["service"],
        c["compentency"], 
        c["staff"], 
        c["licenseIssuingDate"], 
        c["expiryDate"], 
        c["notificationForInspection"], 
        c["inspectionConductedOn"],
        c["nextInspectionDate"], 
      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  handleAssetFilter(data){

  }

  changeDateAfterEightHours(getdate:any){
    const date = new Date(getdate);
    const noOfH = 8;
    date.setTime(date.getTime()+ noOfH *60*60*1000);
   return date;


  }
  
}