import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
 export class AssetType{
     ID:number;
     ASSETCLASSIFICATIONID:number;
     CODE:string;
     DESCRIPTION:string;
     EXPECTEDLIFESPAN:number;
     AGELESSTHAN5YRS: number;
     AGE5TO10YRS: number;
     AGEGREATERTHAN10YEARS:number;
 }

export class AssetTypeObj{
    alldata:Array<AssetType>=[]
    filterData:Array<AssetType>=[]
    selectedObj:AssetType=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AssetType)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            // HttpClient.get("/api/Assets/GetAssetTypes",true,true)
            getLoockup(Schema.dbo,LOOKUPTABLE.AssetType)
            .then((result:Array<AssetType>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }
}