
<div class="card m-3"  >
    <div class="card-header" >
        <h5> Monthly Spare Part Register </h5>
       <div>
        <button kendoButton  primary="true" *ngIf="permission && permission.isExport" (click)="download()" class="header-right-btn">
          Export
           </button>  &nbsp;  &nbsp;  
       </div>
    </div>
    <div class="card-body register-form-body"  >
        <div class="row">
            <div style="display: flex;
            justify-content: center;">
           <div class="col-sm-4 mr-5">
            <kendo-dropdownlist
            [data]="yearDropDown"
            [textField]="'name'"
             [valueField]="'name'"
            [valuePrimitive]="true"
            [(ngModel)]="year"
            (valueChange) ="yearChanged($event)"
          >
          </kendo-dropdownlist>
           </div> 
           <div class="col-sm-4">
            <kendo-dropdownlist
            [data]="monthDropDown"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            [(ngModel)]="month"
            (valueChange) ="monthChanged($event)"
          >
          </kendo-dropdownlist>  
           </div> 

           <div> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <button kendoButton primary="true" (click)="fetchData()">
             Fetch
             </button>
           </div>
        </div>
        </div>
        
        
        <br>
        <div  >
       <app-table-grid
        [data]="gridData"
        [column]="column2"
        [size]="10"
        [action] = "true"
        [editFlag] ="false"
        [viewFlag] ="true"
        [deleteFlag] ="false"
      ></app-table-grid>
      </div>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="opened=false" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Current Quantity Details 
      </div>
    </kendo-dialog-titlebar>
        <app-table-grid
        [data]="QuantitygridData"
        [column]="column3"
        [size]="10"
        [action] = "false"
      ></app-table-grid>
  </kendo-dialog>


