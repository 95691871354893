

<div class="card m-3">
    <div class="card-header" >
        <h5>Answer List</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Answer List
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid 
        [data]="task"
        [column]="column"
        [size]="10"
        [action]="true"
        [fileFlag]="true"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="taskItemOpen" (close)="taskItemOpen=false" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           Answer List Item
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                        <div class="form-group col-8">
                            <label>Description</label>
                            <input kendoTextBox [(ngModel)]="taskItem.DESCRIPTION"  class="form-control"/>
                        </div>
                        <div class="form-group col-8">
                        </div>
                      </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton   primary="true" class="mr-2 px-3" (click)="addNewItem()">
                            Save
                        </button>
                        <button kendoButton   primary="true" class="px-3" >
                            Reset
                        </button>
                    </div>
                      <app-attachment-table-grid 
                      [data]="taskItemGrid" 
                      [column]="taskItemColumn" 
                      [size]="5"
                      >
                      </app-attachment-table-grid>

                  
                    </div>
</kendo-dialog>


<kendo-dialog *ngIf="opened" (close)="opened=false" [width]="1024">
  <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         Answer List
      </div>
    </kendo-dialog-titlebar>
                <div class="py-4">
                    <div class="form-row">
                      <div class="form-group col-8">
                          <label>Name</label>
                          <input kendoTextBox [(ngModel)]="data.NAME"  class="form-control"/>
                      </div>
                      <div class="form-group col-8">
                      </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                      <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                          Save
                      </button>
                      <button kendoButton   primary="true" class="px-3" >
                          Reset
                      </button>
                  </div>           
                  </div>
</kendo-dialog>