<div class="card m-3"   >
    <!-- <h5 class="card-header">Levels</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Privacy</h5>
        
    </div>
    <div   class="card-body register-form-body">
      <form class="k-form k-form-horizontal"  [formGroup]="formGroup">
                   <kendo-editor  #privacy formControlName="privacy">
                      <kendo-toolbar>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorUnderlineButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorStrikethroughButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorSubscriptButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorSuperscriptButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorAlignLeftButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAlignCenterButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAlignRightButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAlignJustifyButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-dropdownlist
                          kendoEditorFormat
                        ></kendo-toolbar-dropdownlist>
                        <kendo-toolbar-dropdownlist
                          kendoEditorFontSize
                        ></kendo-toolbar-dropdownlist>
                        <kendo-toolbar-dropdownlist
                          kendoEditorFontFamily
                        ></kendo-toolbar-dropdownlist>
                        <kendo-toolbar-colorpicker
                          kendoEditorForeColor
                        ></kendo-toolbar-colorpicker>
                        <kendo-toolbar-colorpicker
                          kendoEditorBackColor
                          view="gradient"
                        ></kendo-toolbar-colorpicker>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorInsertUnorderedListButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorInsertOrderedListButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorBlockquoteButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorCreateLinkButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-button
                          kendoEditorInsertFileButton
                        ></kendo-toolbar-button>
                      
                        <kendo-toolbar-button
                          kendoEditorViewSourceButton
                        ></kendo-toolbar-button>
                        <kendo-toolbar-button
                          kendoEditorCleanFormattingButton
                        ></kendo-toolbar-button>
                        <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorAddColumnBeforeButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAddColumnAfterButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAddRowBeforeButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorAddRowAfterButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorDeleteColumnButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorDeleteRowButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorDeleteTableButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button
                            kendoEditorMergeCellsButton
                          ></kendo-toolbar-button>
                          <kendo-toolbar-button
                            kendoEditorSplitCellButton
                          ></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                      </kendo-toolbar>
                    </kendo-editor>
                    <kendo-dialog-actions [layout]="actionsLayout">
                   
                      <button  kendoButton (click)="onSubmit()" primary="true">Save</button>
                  </kendo-dialog-actions>
       </form>
    </div>
</div>
          
