import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";

@Component({
  selector: 'app-er-report-monthly',
  templateUrl: './er-report-monthly.component.html',
  styleUrls: ['./er-report-monthly.component.scss']
})
export class ErReportMonthlyComponent implements OnInit {

  public gridData = []
  public picDropDown:any =[]
  public userObj = {}
  public permission:Permission=new Permission();
  public isAccess: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.getBPM().then((e)=>{
      this.getERReportMonthly()
    })
    if(UserModuleObj.engineering_records){
      this.permission=UserModuleObj.engineering_records;
      this.isAccess = true;
    }else{
      this.isAccess = false;
    }
  }


  public column1 = [
    { field: "user", title: "Tenant", isFilter: true, width: 200 },
    { field: "meterNo", title: "Meter No", isFilter: true,width: 200 },
    { field: "level", title: "Location  ", isFilter: true,width: 200 },
    // { field: "level", title: "Level  ", isFilter: true,width: 200 },
    // { field: "facility", title: "Facility  ", isFilter: true, width: 200 },
    { field: "yearMonths", title: "Year Months", isFilter: true, width: 300 },
    { field: "currentDate", title: "Current Date", isFilter: true, width: 200 },
    { field: "currentReading", title: "Current Reading", isFilter: true, width: 200 },
    { field: "previousDate", title: "Previous Date", isFilter: true, width: 200 },
    { field: "previousReading", title: "Previous Reading", isFilter: true, width: 200 },
    { field: "consumptions", title: "Consumptions (kWh)", isFilter: true, width: 200 },
    { field: "icptRebate", title: "ICPT", isFilter: true, width: 200 },
    { field: "rate", title: "Rate/kWh (RM)", isFilter: true, width: 200 },
    { field: "totalCharge", title: "Total Charge (RM)", isFilter: true, width: 200 },
   ]

   bindStaffObj(result) {
    this.userObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }



  getERReportMonthly(){
  return new Promise((resolve, reject) => {
      HttpClient.get(`/api/ER/GetERMonthlyReport`, true, true)
        .then((result: any) => {
          this.bindStaffObj(this.picDropDown);
          this.gridData = result.map((e,index)=>{
            e["SoNo"] = index+1
            if(e.assignTo){
              e["user"] = this.userObj[e.assignTo]
            }
            return e;
          });
         
        })
      })
}

getBPM() {
  return new Promise((resolve, reject) => {
    HttpClient.get("/api/BPM/GetBpms", true, true)
      .then((result: any) => {
        this.picDropDown = result;
        resolve(result);
      }).catch((err) => {
        reject(err)
      })
  })
}

download() {
  let fileName = 'ermonthly.csv';
  let columnNames = [
    "SoNo",
    "meterNo",
    "user",
    "location",
    // "level",
    // "facility",
    "yearMonths",
    "currentDate",
    "currentReading",
    "previousDate",
    "previousReading",
    "consumptions",
    "icpt",
    "rate",
    "totalCharge"
  ];
  let header = columnNames.join(',');
  let csv = header;
  csv += '\r\n';
this.gridData.map(c => {
    csv += [
      c["SoNo"],
      c["meterNo"],
      c["user"],
      c["level"],
      // c["level"],
      // c["facility"],
      c["yearMonths"],
      c["currentDate"],
      c["currentReading"],
      c["previousDate"],
      c["previousReading"],
      c["consumptions"],
      c["icptRebate"],
      c["rate"],
      c["totalCharge"]
    ].join(',');
    csv += '\r\n';
  })
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  var link = document.createElement("a");
  if (link.download !== undefined) {
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
}

