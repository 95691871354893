import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ResourceEditorBase } from '@progress/kendo-angular-scheduler';
import { sampleProducts } from 'src/app/Data/table';
import { LeaveDetils, UserShiftDetails } from 'src/app/models/user-shift.model';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie } from 'src/Util/Util';
import { UserLeaveDetailsValidation, UserShiftDetailsValidation } from 'src/validator/user-shift';

@Component({
  selector: 'app-user-shift',
  templateUrl: './user-shift.component.html',
  styleUrls: ['./user-shift.component.scss']
})
export class UserShiftComponent {
  public opened=false;
  public globalLeaveId;
  public filteredShiftData:any = []
  public leaveopened :boolean = false;
 public gridleaveDetails = []
  public leaveGrid:any =[]
  public leaveDetailArray =[]
  public removeDialogOpen = false;
  public type="";
  public leavDetailTab = false;
  public actionsLayout = "normal";
  public format = "dd/MM/yyyy HH:mm";
  public dailogTitle="";
  public staffNameDropDown =[]
  public designationDropDown =[]
  public loginUserTypeDropDown =[]
  public shiftDropDown =[]
  public designationObj={}
  public loginUserObj={}
  public userObj ={}
  public searchLeaveFrom:any;
  public searchLeaveTo:any;
  public designationName
  public  GlobalUserId
  public  GlobalShiftId
  public userType
  public mobileNo
  public userShiftForm :UserShiftDetails = new UserShiftDetails()
  public userLeaveForm:  LeaveDetils  = new LeaveDetils();
  shifterror = UserShiftDetailsValidation(this.userShiftForm ,"init");
  leaveerror = UserLeaveDetailsValidation(this.userLeaveForm,"init");
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  public columnLeave=[
    {field:"leaveTo",title:"Leave To", isFilter:true},
    {field:"leaveFrom",title:"Leave From", isFilter:true},
    {field:"noOfDays",title:"No. Of Days", isFilter:true},
     {field:"noOfHours",title:"No. Of Hours", isFilter:true}
  ];
  public column=[
    {field:"staffName",title:"Staff Name",isFilter:true},
    {field:"shiftTime",title:"Shift Time", isFilter:true},
    {field:"breakTime",title:"Break Time", isFilter:true},
  ];

  public personInChargeColumn = [
    {
      field: "leaveFrom",
      title: "Leave From ",
      type: "leavefromdate",
      errorField: "person_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
      isFilter: false,
      width: 140
    },
    {
      field: "leaveTo",
      title: "Leave To",
      type: "leaveTodate",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "noOfDays",
      title: "No Of Days ",
      type: "input",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
    {
      field: "noOfHours",
      title: "No Of Hours",
      type: "input",
      errorField: "name_error",
      validation: (val) => {
        if (val)
          return ""

      },
      isFilter: false,
      width: 140
    },
   ]

  public onTabSelect(e) {
 
  }

  public gridDataleave:any=[];
// public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  public value: Date = new Date(2000, 2, 10);
  public value2: Date = new Date(2000, 3, 10);
  public min: Date = new Date(2000, 2, 10, 2, 30);
  public max: Date = new Date(2002, 2, 10, 22, 15);
  public valuetime: Date = new Date(2000, 2, 10, 10, 0);

  user_shift_form: FormGroup;
  submitted = false;
  userroles = ['Super Admin','Company User', 'External User', 'Facility User'];
  status = ['Active','Inactive'];
  competencys = ['Management','Building System', 'Machanical', 'Electrical', 'Operations', 'Integrated Services', 'CSRM','IBCC','Housekeeping'];
  constructor(private formBuilder: FormBuilder,private _notif:NotificationService) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.view_column,(e)=>{this.viewLeaveColumn(e)})
   }
 

  ngOnInit() {
    Promise.all([ 
         this.getUserRegistartion(),
         this.getShiftData("dbo","shift"),
         this.getloginUserTypeData("dbo", "category"),
    ]).then((e)=>{
        this.getDesignationData("dbo", "Designation"),
        this. getUserShiftandLeaveDetails()

    })
}

onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.user_shift_form.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.user_shift_form.value, null, 4));
  }

  onReset() {
   this.userShiftForm  = new UserShiftDetails()
   this.userLeaveForm  = new LeaveDetils();
   this.leaveGrid =[]
    this.openRoleDailog("new");
    
  }

  onDialogClose(type){
    if (type = "new") {
      this.userShiftForm  = new UserShiftDetails()
   this.userLeaveForm  = new LeaveDetils();
      this.opened = false;
     }
    if ((type = "remove")) {
    this.removeDialogOpen = false;
    }
    if ((type = "leave")) {
      this.leaveopened = false;
      }
  }

  viewLeaveColumn(data){
    console.log(data.id)
    this.leaveopened = true 
    this.getLeaveDataById(data.id)
  }
  editColumn(data){
    if(data.action==="edit"){
      this.getShiftById = data.dataItem.id
      this.userShiftForm.DATETIMEFROM =  this.removeZero(data.dataItem.breakTime)
      this.userShiftForm.DATETIMETO = this.removeZero(data.dataItem.shiftTime)
      this.userShiftForm.EMPLOYEEGLOBALID =data.dataItem.userId
      this.staffValueChange(data.dataItem.userId)
      this.GlobalShiftId = data.dataItem.id
      this.GlobalUserId = data.dataItem.userId
      this.leavDetailTab = true
      this.openRoleDailog("update");
    }

    if(data.action==="remove"){
     if(data.dataItem.shiftId){
        HttpClient.fetchData(`/api/Users/DeleteLeaveById?id=${data.dataItem.id}`,"post",true,true)
        .then((result:any)=>{
          if (result.isValid == true) {
            HttpClient.insertAduitLog("Users","Delete User","Delete User in Users").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            this.getLeaveDataById(data.dataItem.shiftId)
           this.removeDialogOpen=false;
          }
        })
      }
    
     else{
      this.userLeaveForm = data.dataItem
      this.GlobalShiftId = data.dataItem.id
      this.userLeaveForm.LEAVEFROM = new Date(data.dataItem.LEAVEFROM )
      this.userLeaveForm.LEAVETO = new Date(data.dataItem.LEAVETO )
      this.removeDialogOpen = true
     }
    
    }
   
  }


  removeZero(date){
          let H = date.toString().slice(0,2)
          let m = date.toString().slice(3,5)
          let s = date.toString().slice(6,8)

          let hour;
          let min;
          let sec;

        
          if( H.indexOf(0) === 0){
            hour = H.slice(1)
            }
            else{
              hour = H
            }
            if( m.indexOf(0) === 0){
              min = m.slice(1)
              }
              else{
                min = m
              }
              if( s.indexOf(0) === 0){
                sec = s.slice(1)
                }
                else{
                  sec = m
                }
 
      return new Date(2000, 2, 10, H, m, s)
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.userShiftForm = new UserShiftDetails();
       this.designationName =""
       this.userType=""
       this.mobileNo=""
       this.userLeaveForm = new  LeaveDetils()
       this.shifterror = UserShiftDetailsValidation(this.userShiftForm, "init");
       this.dailogTitle="Add User Shift And Leave Details"
    }
    if(type=="update"){
      this.dailogTitle="Edit User Shift And Leave Details"
      this.shifterror = UserShiftDetailsValidation(this.userShiftForm ,"init");
    }
    this.opened=true;
  }
 
  getDesignationData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
          this.designationDropDown = JSON.parse(result.gridData);
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getloginUserTypeData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.loginUserTypeDropDown = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

 getUserRegistartion(){
      return new Promise((resolve,reject)=>{
        HttpClient.get("/api/Users/GetAllUser", true, true)
      .then((result: any) => {
            this.staffNameDropDown = result.map((e)=>{
              return {id:e.id,name:e.staffName,designationId:e.designationId,mobileNo:e.mobileNo,categoryId:e.categoryId}
            })
            this.filteredShiftData =  this.staffNameDropDown .slice();
        resolve(result);
      }).catch((e)=>{
        reject(e)
      })
      })
  }

  getShiftData(schema,tablename){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
          this.shiftDropDown = JSON.parse(result.gridData);
          
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  bindDesignationObj(result) {
    this.designationObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindLoginTypeObj(result) {
    this.loginUserObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindUserObj(result) {
    this.userObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  staffValueChange(data){
    let value = this.staffNameDropDown.filter((e)=>{
      return e.id===data
    })
      let desgId = value[0].designationId
      let catId =value[0].categoryId
  this.bindDesignationObj(this.designationDropDown);
  this.bindLoginTypeObj(this.loginUserTypeDropDown);
  this.designationName = this.designationObj[desgId];
  this.userType = this.loginUserObj[catId]
  this.mobileNo = value[0].mobileNo

  }

  shiftValueChange(data){
   
  }

  getShiftById(id){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `api/Users/GetByShiftId?id = ${id}`,
        true,
        true
      ).then((result: any) => {
          
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    }); 
  }

  submitShiftFrom(type){
 
 

   console.log( this.shifterror.DATETIMEFROM,this.shifterror.DATETIMETO)
      this.shifterror = UserShiftDetailsValidation(this.userShiftForm, "");
      if(!this.shifterror.EMPLOYEEGLOBALID && !this.shifterror.DATETIMEFROM && !this.shifterror.DATETIMETO){
        if (this.type == "new") {
         let  obj =
        {
            "id" : 0,
            "userId": this.userShiftForm.EMPLOYEEGLOBALID,
            "shiftTime":  this.timeformat( new Date(this.userShiftForm.DATETIMETO)),
            "breakTime":  this.timeformat( new Date(this.userShiftForm.DATETIMEFROM)),
            "createdOn": new Date(),
            "createdBy": parseInt(getCookie(userid)),
            "modifiedBy": parseInt(getCookie(userid)),
            "modifiedDate": new Date(),
            "status": 0,
            "userLeaves": []
          }
       
          this.saveShift(obj,type)
      }
     
      
    }
   
}

 saveShift(data,type){
    let  path="";
    if( this.type==="new" && type === "save&new"){
       path=`/api/Users/AddShift`
       HttpClient.fetchData(path,"post",data,true,true)
       .then((result:any)=>{
        HttpClient.insertAduitLog("Users","Add & Save shift","Add & Save shift in Users").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
          this._notif.show({
             content: result.message,
             type: { style: "success", icon: true },
           });
           this.getUserShiftandLeaveDetails()
    this.openRoleDailog("new");
       })
      .catch((err)=>{
        
       })
    }
    if( this.type==="new" && type === "new"){
      path=`/api/Users/AddShift`
      HttpClient.fetchData(path,"post",data,true,true)
      .then((result:any)=>{
        HttpClient.insertAduitLog("Users","Add shift","Add shift in Users").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
         this._notif.show({
            content: result.message,
            type: { style: "success", icon: true },
          });
          this.getUserShiftandLeaveDetails()
         this.opened=false;
        
      })
     .catch((err)=>{
       
      })
   }
   
  }

  submitLeaveFrom(){
    this.leaveerror = UserLeaveDetailsValidation(this.userLeaveForm,"init");
    if(! this.leaveerror.USERID){
      if (this.type == "new") {
          this.userLeaveForm.CREATEDON = new Date();
          this.userLeaveForm.ID=0
      }
       this.saveLeave(JSON.stringify(this.userLeaveForm))
    }
}

 saveLeave(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"leaveDetail",
      "userId":getCookie(userid),
      "newData":data
    }
     let  path="";
    if( this.type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(this.type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      this.opened=false;
      if (result.isValid == true) {
        HttpClient.insertAduitLog("Users",this.type+" Leave",this.type+" Leave in Users").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
       this.opened=false;
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }


  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

    timeformat(date) {
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds()
    // var x = h >= 12 ? 'pm' : 'am';
    // h = h % 12;
    // h = h ? h : 12;
    m = m < 10 ? '0'+m: m;
    h = m < 10 ? '0'+h: h;
    s = s < 10 ? '0'+s: s;
    var mytime= h + ':' + m + ':' + s;
    return mytime;
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.abs(diffInTime / oneDay);
    return diffInDays;
}

 diff_hours(dt2, dt1) 
  {
   let date1 = new Date(dt2);
   let date2= new Date(dt1);
 var diff =(date2.getTime() - date1.getTime()) / 1000;
  diff /= (60 * 60);

  // Min 4 hr Max 8 hr
  if(diff > 4){
    diff = 8
  }
  if(diff < 4)
  { diff = 4}
  if(diff <= 24){
    diff = 0;
  }
  return Math.abs(Math.round(diff));
 }

  getUserShiftandLeaveDetails(){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/Users/GetAllShifts`,
        true,
        true
      )
        .then((result: any) => {
          let leaveDetails =[]
          this.bindUserObj(this.staffNameDropDown);
          this.gridDataleave =result.map((d)=>{
               d["staffName"] = this.userObj[d.userId]
              //  d["leave_form"] = this.dateFormating(d.LEAVEFROM)
              //  d["leave_to"] = this.dateFormating(d.LEAVETO)
              //  d["days"]= this.getNumberOfDays(d.LEAVEFROM,d.LEAVETO)
              //  d["hours"] = this.diff_hours(d.LEAVEFROM,d.LEAVETO)
               return d;
          })
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getUserShiftById(schema,tablename,id){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
        true,
        true
      ).then((result: any) => {
       let ShiftDetails = JSON.parse(result.gridData);
        this.userShiftForm.EMPLOYEEGLOBALID = ShiftDetails[0].EMPLOYEEGLOBALID;
        this.staffValueChange(this.userShiftForm.EMPLOYEEGLOBALID)
        this.userShiftForm.SHIFTID = ShiftDetails[0].SHIFTID
        this.userShiftForm.ID = ShiftDetails[0].ID
        this.shiftValueChange( this.userShiftForm.SHIFTID)
        resolve(result)
      }).catch((e)=>{
        reject(e)
      })
  })
}

 
removeLeaveData(){
 let obj={
    "schemaName":"dbo",
    "tableName":"leaveDetail",
    "userId":getCookie(userid),
    "oldData":JSON.stringify( this.userLeaveForm),    
    "newData":JSON.stringify( this.userLeaveForm)
  }
   let  path=`/api/MasterData/DeleteLookupData`
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
    if (result.isValid == true) {
      HttpClient.insertAduitLog("Users","Delete Leave","Delete Leave in Users").then((res:any)=>{
        if (res.isValid == true) {

        }
      })
      this._notif.show({
        content: result.messageKey,
        type: { style: "success", icon: true },
      });
    }
    this. getUserShiftandLeaveDetails()
     this.removeDialogOpen=false;
  })
  .catch((err)=>{
    console.log(err);
  })
}

personchargeChangeValue(data){

      this.leaveDetailArray = []
      for(let i=0 ;i<data.length ;i++){
        this.leaveDetailArray.push({
          "id":0,
          "userId": this.GlobalUserId,
          "shiftId": this.GlobalShiftId,
          "leaveFrom":new Date(data[i].leaveFrom),
          "leaveTo": new Date(data[i].leaveTo),
          "noOfDays":parseInt(data[i].noOfDays),
          "noOfHours": parseInt(data[i].noOfHours),
          "createdOn": parseInt(getCookie(userid)),
          "createdDate": new Date(),
          "modifiedBy":  parseInt(getCookie(userid)),
          "modifiedDate": new Date()
        });
      }
  
}

addLeave(){
  HttpClient.fetchData('/api/Users/AddLeavelist',"post",this.leaveDetailArray,true,true)
  .then((result:any)=>{
    if (result.isValid == true) {
      this._notif.show({
        content: result.messageKey,
        type: { style: "success", icon: true },
      });
     this.opened=false;
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}


getLeaveDataById(id){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Users/GetByShiftId?id=${id}`,
      true,
      true
    ).then((result: any) => {
      let data = result.userLeaves
        this.gridleaveDetails = data.map((e)=>{
          e["leaveTo"] = this.dateFormating(new Date(e.leaveTo))
          e["leaveFrom"] = this.dateFormating(new Date(e.leaveFrom))
          return e;
        })
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

geteaveForm(date){
    if(this.searchLeaveFrom && this.searchLeaveTo){
      this.gridleaveDetails.filter((e)=>{
        return new Date(e.leaveFrom) === this.searchLeaveFrom && new Date(e.leaveTo) === this.searchLeaveTo;
      })
    }
    else{
      this.gridleaveDetails.filter((e)=>{
        return new Date(e.leaveFrom) ===date;
      })
    }
  }

geteaveTo(date){
  if(this.searchLeaveFrom && this.searchLeaveTo){
    this.gridleaveDetails.filter((e)=>{
      return new Date(e.leaveFrom) === this.searchLeaveFrom && new Date(e.leaveTo) === this.searchLeaveTo;
    })
  }
  else{
    this.gridleaveDetails.filter((e)=>{
      return new Date(e.leaveTo) === date;
    })
}
}

removeShiftData(){
 
    HttpClient.fetchData(`/api/Users/DeleteShiftById?id=${this.GlobalShiftId}`,"post",true,true)
    .then((result:any)=>{
      if (result.isValid == true) {
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
        this.getUserShiftandLeaveDetails()
       this.removeDialogOpen=false;
      }
    })
  
  
}

handleStaffFilter(value){

  this.filteredShiftData = this.staffNameDropDown.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  

}



}

