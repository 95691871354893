import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"
import { LocationType } from "src/app/models/location.model";;
function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key]]=i;
        }
        else{
       newObj[objR[i]]=i;
        }
    }
    return newObj;
}
export function addBlukLocation(
    locationList,facilityObj,blockObj,levelObj,areaObj,locationInChargeObj,
    locationReprentive
    ) 
  {
    let empId=parseInt(getCookie(userid));
    facilityObj=resversKey(facilityObj);
    blockObj=resversKey(blockObj);
    levelObj=resversKey(levelObj);
    areaObj=resversKey(areaObj);
   // locationInChargeObj=resversKey(locationInChargeObj);
    //locationReprentive=resversKey(locationReprentive);
    let locationData= locationList.map((d)=>{
        let loc:LocationType=new LocationType();
        loc.FACILITYID=facilityObj[d["Facility"]];
        loc.BLOCKID=blockObj[d["Block Code"]];
        loc.BLOCKLEVELID=levelObj[d["Level Code"]];
        loc.LEVELAREAID=areaObj[d["Area Code"]];
        loc.NAME=d["Location Name"];
        loc.CODE=d["Location Code"];
        loc.STARTSERVICEDATE=d["Start Service Date"];
        loc.STOPSERVICEDATE=d["Stop Service Date"];
        loc.LOCATIONINCHARGE=locationInChargeObj[d["Location Incharge"]];
        loc.COMPANYREPRESENTATIVE=locationReprentive[d["Company Representative"]]
        loc.MODIFIEDDATE=new Date();
        loc.CREATEDDATE=new Date();
        loc.CREATEDBY=empId;
        loc.MODIFIEDBY=empId;
        loc.STATUSID=1;
        return loc;
    })
    return locationData;
    //   bpmObj=resversKey(bpmObj,"name");
    //   consequanceObj=resversKey(consequanceObj,"NAME");
    //   serviceObj=resversKey(serviceObj,"NAME");
    // let empId=parseInt(getCookie(userid));
    // let userObjList = checkList.map((d) => {
    //     let obj = {
    //         id:0,
    //         description:d["Checklist Name"],
    //         bpmid:bpmObj[d["Contractor/Vendor Name"]?.toLowerCase()],
    //         ticketConsequenceTypeId:consequanceObj[d["Consequences Category"]?.toLowerCase()],
    //         serviceTypeId:serviceObj[d["Service Type"]?.toLowerCase()],
    //         createdDate:new Date(),
    //         createdBy:empId,
    //         userId:empId,
    //         modifiedDate:new Date(),
    //         modifiedBy:empId 
    //     }
    //   return obj;
    // })
    // return userObjList;
}