import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { pading } from 'src/ApiModel/Util';
import { convertLocalDateString, objectToQueryString } from 'src/Util/Util';

@Component({
  selector: 'app-work-order-report-for-contractor',
  templateUrl: './work-order-report-for-contractor.component.html',
  styleUrls: ['./work-order-report-for-contractor.component.scss']
})
export class WorkOrderReportForContractorComponent implements OnInit {
  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }
  public parentId = -1;
  public moduleId = -1;

  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public noDataFound: boolean = false;

  public facility: any;
  public fromDate: Date;
  public toDate: Date;
  public workOrderStatusddl: any;
  public workOrderTypeddl: any;

  public workOrderStatus = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: 1, name: "New" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Waiting" },
      { id: 4, name: "Completed" },
      { id: 5, name: "Closed" },
      { id: 6, name: "Cancel" },
      // { id: 7, name: "InActive" },
      // { id: 8, name: "Active" },
    ],
    selectedObj: null,
    objectData: {}
  }
  public workOrderTypeObj = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: 1, name: "Routine Maintenance" },
      { id: 2, name: "Corrective Maintenance" },
      { id: 3, name: "Reactive Maintenance" }
    ],
    selectedObj: null,
    objectData: {}
  }
  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility")
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
        result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
          this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }


  download() {
    let fileName = 'Work-Orders-Report-for-Contractor.csv';
    let columnNames = [
      "Work Order no",
      "Work Order Type",
      "Consequence Category",
      "Service Type",
      "WO Date",
      "WO Status",
      "Location",
      "Asset No",
      "Chargeable",
      "Checklist Name",
      "Follow Up Reason",
      "Request Description",
      "Request No",
      "Assignee",
      "Assign To",
      "WO Start Date/Time",
      "WO End Date/Time",
      "WO Completed By",
      "Closed By",
      "Respond SLA Started",
      "Respond SLA Recorded",
      "Completion SLA Record Start",
      "Completion SLA Record Stop",
      "Waiting Time Start",
      "Waiting Time End",
      "Net Waiting Time",
      "SLA",
      "Used Spare Parts"
    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["WORKORDERNO"],
        c["TICKETTYPE"],
        c["CONSEQUENCETYPE"],
        c["SERVICETYPE"],
        c["WORKORDERDATE"],
        c["TICKETSTAUS"],
        c["LOCATIONNAME"],
        c["ASSETNUMBER"],
        c["CHARGABLE"],
        c["CHECKLISTNAME"],
        c["FOLLOWUPREASON"],
        c["DESCRIPTION"],
        c["REQUESTNO"],
        c["ASSIGNEEUSER"],
        c["ASSIGNTOVENDOR"],
        c["WOSTARTDATETIME"],
        c["WOENDDATETIME"],
        c["COMPLETEDBYUSER"],
        c["CLOSEDBYUSER"],
        c["RESPONDSLASTARTED"],
        c["RESPONDSLARECORDED"],
        c["COMPLETIONSLARECORDSTART"],
        c["COMPLETIONSLARECORDSTOP"],
        c["WAITINGTIMESTART"],
        c["WAITINGTIMESTOP"],
        c["NETWAITINGTIME"],
        c["SLA"],
        c["USEDSPAREPARTS"]
      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  fetchReport() {
    let path = "";

    const from_date = (this.fromDate != undefined) ? convertLocalDateString(this.fromDate) : this.fromDate;
    const to_date = this.toDate != undefined ? convertLocalDateString(this.toDate) : this.toDate;

    var qString = { startDate: from_date, endDate: to_date, workOrderTypeId: this.workOrderTypeddl?.id, facilityId: this.facility?.ID, workOrderStatusId: this.workOrderStatusddl?.id }

    path = `/api/CustomReport/GetWorkOrdersForContratcor?${objectToQueryString(qString)}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].ID > 0;
          this.noDataFound = !this.showReport;
        })
        .catch((err) => {

          reject(err)
        })
    })
  }

  public column = [
    { field: "WORKORDERNO", title: "Work Order no", width: 200 },
    { field: "TICKETTYPE", title: "Work Order Type", width: 200 },
    { field: "CONSEQUENCETYPE", title: "Consequence Category", width: 200 },
    { field: "SERVICETYPE", title: "Service Type", width: 200 },
    { field: "WORKORDERDATE", title: "WO Date", width: 200 },
    { field: "TICKETSTAUS", title: "WO Status", width: 200 },
    { field: "LOCATIONNAME", title: "Location", width: 200 },
    { field: "ASSETNUMBER", title: "Asset No", width: 200 },
    { field: "CHARGABLE", title: "Chargeable", width: 200 },
    { field: "CHECKLISTNAME", title: "Checklist Name", width: 200 },
    { field: "FOLLOWUPREASON", title: "Follow Up Reason", width: 200 },
    { field: "DESCRIPTION", title: "Request Description", width: 200 },
    { field: "REQUESTNO", title: "Request No", width: 200 },
    { field: "ASSIGNEEUSER", title: "Assignee", width: 200 },
    { field: "ASSIGNTOVENDOR", title: "Assign To", width: 200 },
    { field: "WOSTARTDATETIME", title: "WO Start Date/Time", width: 200 },
    { field: "WOENDDATETIME", title: "WO End Date/Time", width: 200 },
    { field: "COMPLETEDBYUSER", title: "WO Completed By", width: 200 },
    { field: "CLOSEDBYUSER", title: "Closed By", width: 200 },
    { field: "RESPONDSLASTARTED", title: "Respond SLA Started", width: 200 },
    { field: "RESPONDSLARECORDED", title: "Respond SLA Recorded", width: 200 },
    { field: "COMPLETIONSLARECORDSTART", title: "Completion SLA Record Start", width: 250 },
    { field: "COMPLETIONSLARECORDSTOP", title: "Completion SLA Record Stop", width: 250 },
    { field: "WAITINGTIMESTART", title: "Waiting Time Start", width: 200 },
    { field: "WAITINGTIMESTOP", title: "Waiting Time End", width: 200 },
    { field: "NETWAITINGTIME", title: "Net Waiting Time", width: 200 },
    { field: "SLA", title: "SLA", width: 200 },
    { field: "USEDSPAREPARTS", title: "Used Spare Parts", width: 200 }
  ];
}
