
import HttpClient from "src/Util/ApiHandling"
import { userid } from "src/Util/const";
import { getCookie } from "src/Util/Util";
import { ApiCount } from "src/Util/Util";
let monthDayList=[
  {full:"January",short:"Jan",day:"Sun"},
  {full:"February",short:"Feb",day:"Mon"},
  {full:"March",short:"Mar",day:"Tue"},
  {full:"April",short:"Apr",day:"Wed"},
  {full:"May",short:"May",day:"Thr"},
  {full:"June",short:"Jun",day:"Fri"},
  {full:"July",short:"Jul",day:"Sat"},
  {full:"Augest",short:"Aug",day:"Sun"},
  {full:"September",short:"Sep",day:""},
  {full:"October",short:"Oct",day:""},
  {full:"November",short:"Nov",day:""},
  {full:"December",short:"Dec",day:""},
]
export function  pading(n){
  if(n>9)
    return n;
  else
   return "0"+n
}

export function convertDateFormate(d1){
    let df=new Date(d1);
    return `${pading(df.getDate())}-${monthDayList[df.getMonth()].short}-${df.getFullYear()}`
}

export function convertDateFormatTime(d1){
  let df=new Date(d1);
  return `${pading(df.getDate())}-${monthDayList[df.getMonth()].short}-${df.getFullYear()} ${pading(df.getHours())}:${pading(df.getMinutes())}`
}

export function  getLoockup(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
          let temp = JSON.parse(result.gridData)
            temp=temp.map((d)=>{
              if(d["MODIFIEDDATE"]){
                d["MODIFIEDDATE_formate"]=convertDateFormate(d["MODIFIEDDATE"])
              }
              else if(d["CREATEDDATE"]){
                d["CREATEDDATE_formate"]=convertDateFormate(d["CREATEDDATE"])
              }
              else if(d["createdDate"]){
                d["createdDate_formate"]=convertDateFormate(d["createdDate"])
              }
              else if(d["modifiedDate"]){
                d["modifiedDate_formate"]=convertDateFormate(d["modifiedDate"])
              }
              return d;
            })
          resolve(temp)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }


export function saveLookup(schema,table,objData,type){
    let  path="";
    if( type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
      let obj={
        "schemaName":schema,
        "tableName":table,
        "userId":parseInt(getCookie(userid)),
        "newData":JSON.stringify(objData)
      }
  return new Promise((resolve,reject)=>{
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      HttpClient.insertAduitLog(table ,type+ " in"+table,type+ " in"+table).then((res:any)=>{
        if (res.isValid == true) {

        }
      })
       resolve(result);
    })
    .catch((err)=>{
      reject(err)
    })
  })
  }

  export function removeLookup(schema,table,objData){
      let obj={
        "schemaName":schema,
        "tableName":table,
        "userId":1,
        "oldData":JSON.stringify(objData),    
        "newData":null//JSON.stringify(this.deleteItem)
      }
       let  path=`/api/MasterData/DeleteLookupData`
      return new Promise((resolve,reject)=>{
          HttpClient.fetchData(path,"post",obj,true,true)
          .then((result:any)=>{
            HttpClient.insertAduitLog(table , "Delete in"+table, "Delete in"+table).then((res:any)=>{
              if (res.isValid == true) {
      
              }
            })
              resolve(result)
          })
          .catch((err)=>{
            reject(err)
          })
      }) 
    }

export function convertListToObj(list:Array<any>,key:string,valueKey:string=null,isLowerAndTrim=false){
   return list.reduce((acc,d)=>{
      let keyValue=d[key];
      if(isLowerAndTrim){
        keyValue=keyValue?.trim().toLowerCase();
      }
      if(valueKey){
        acc[keyValue]=d[valueKey]
      }
      else{
        acc[keyValue]=d;
      }
      return acc;
   },{})
}