<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"

>
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
</kendo-grid-column>
<kendo-grid-command-column *ngIf="action" title="" width="300">
  <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <!-- <button kendoGridEditCommand    icon="export" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button *ngIf="deleteFlag" kendoGridRemoveCommand  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button> -->
      <a href="JavaScript:Void(0);" (click)="workOrderQuesry(dataItem)" style="font-size: 13px;color: #68aaff;font-weight: 500;margin-left: 14px;">Go To Work Order Query</a>
  </ng-template>
</kendo-grid-command-column>
</kendo-grid>