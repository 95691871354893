import { Status } from './../../../../ApiModel/Model/Status';
import { State } from '@progress/kendo-data-query';

import { Component, OnInit } from '@angular/core';
import { Attechment, AttechmentObj } from 'src/ApiModel/Model/Attechment';
import { environment } from 'src/environments/environment';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";

@Component({
  selector: 'app-er-capture',
  templateUrl: './er-capture.component.html',
  styleUrls: ['./er-capture.component.scss']
})
export class ErCaptureComponent implements OnInit {

  public categoryDropDown =[]
  public designationValue :any =[]
  removeDialogOpen :boolean = false
  public moduleId=-1;
  public contectGrid =[]
  public designationName ;
  public emailName;
  public  picName;
  public companyName;
  public erCaptureId;
  public permission:Permission=new Permission();
  public isAccess: boolean = false;
  public picGrid :any =[]
  public checklistData =[]
  public picDropDown:any =[]
  public serviceObj = {}
  public shiftObj = {}
  public userObj = {}
  public serviceDropDown:any =[]
  public userDropDown:any  =[]
  public usrShiftDropDown:any  =[]
  public opened:boolean = false;
  public dailogTitle = ""
  public type=""
  public actionsLayout = "normal";
  public attachmentOpened = false;
  public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
  public attechmentDoc=new AttechmentObj()
  public attechForm:Attechment=new Attechment();
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public attechId:number=-1;
  public onTabSelect(e) {
    if(e.title = "Checklist"){
      this.getCheckList();
    }
    if(e.title="Attachment" ){
      console.log(this.erCaptureId)
      this.attechmentDoc.getData(this.erCaptureId,33)
    }
      
    }
  
  
  public column1 = [
    { field: "erparameterId", title: "ER Parameter ID  ", isFilter: true, width: 250 },
    { field: "ertaskId", title: "ER Task ID  ", isFilter: true, width: 200 },
    { field: "description", title: "Parameter Description ", isFilter: true ,width: 200 },
    { field: "location", title: "Location ", isFilter: true ,width: 200},
    { field: "categoryName", title: "Category Name", isFilter: true,width: 200 },
    { field: "erno", title: "ER No", isFilter: true, width: 100 },
    { field: "facilityCode", title: "Facility Code", isFilter: true, width: 200 },
    { field: "facilityName", title: "Facility Name", isFilter: true, width: 200 },
    { field: "areaCode", title: "Area Code", isFilter: true, width: 200 },
    { field: "recordDateTime", title: "Record Date/time", isFilter: true, width: 200 },
    { field: "shift", title: "Shift", isFilter: true, width: 200 },
    { field: "shiftTime", title: "Shift Time", isFilter: true, width: 200 },
    { field: "service", title: "Service Type", isFilter: true, width: 200 },
    { field: "user", title: "User ID", isFilter: true, width: 200 },
   
  ]

  public column3 =[
    { field: "parameterName", title: "Parameter Name", isFilter: true, width: 200 },
    { field: "uomName", title: "UOM", isFilter: true },
    { field: "min", title: "Min", isFilter: true },
    { field: "max", title: "Max", isFilter: true },
    { field: "actualValue", title: "Actual Value", isFilter: true,width: 200  },
    { field: "status", title: "Status", isFilter: true },
    { field: "remarks", title: "Remark", isFilter: true, width: 100 },
  
   
  ]

  public PICcolumn =[
    { field: "picName", title: "Person In Charge", isFilter: true, width: 200 },
    { field: "designation", title: "Designation", isFilter: true },
    { field: "email", title: "Email", isFilter: true },
    { field: "companyName", title: "Company Name", isFilter: true },
    { field: "remarks", title: "Remarks", isFilter: true,width: 200  },
    ]

  public gridData =[]
  public column2 = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true }
  ]



  constructor() {
    eventListner(EVENT_NAME.view_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.download_attechment,(e)=>{
      this.downloadAttechment(e)
    })
   }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "ServicesType"),
      this.getBPM(),
      this.getLoockup("dbo", "ERShift"),
      this.getLoockup("dbo", "Designation")
    ]).then((result)=>{
      
      this.serviceDropDown = result[0]
      this.userDropDown = result[1]
      this.usrShiftDropDown = result[2]
      this.designationValue = result[3]
      this.getErCapturData()
      this.getUser()
    })
    if(UserModuleObj.engineering_records){
      this.permission=UserModuleObj.engineering_records;
      this.isAccess = true;
    }else{
      this.isAccess = false;
    }
  }

  getUser(){
    return new Promise((resolve,reject)=>{
        HttpClient.get("/api/Users/GetAllUser", true, true)
          .then((result: any) => {
            this.picDropDown = result;
            
            resolve(result);
          }).catch((err)=>{
            reject(err)
          })
    })
  }


  editColumn(data){
   this.erCaptureId = data.id
    this.getCheckList();

      this.opened = true;
  }
  openRoleDailog(data){
    if(data === 'new'){
        this.opened = true;
    }
  }
  onDialogClose(data){

    this.opened = false
  }
  removeBlockData(){

  }
  onSubmit(){}
  onReset(){}
  addAttechment(){
    if(!this.attechForm.URL){
      this.attechError.URL=true
    }
    else{
      this.attechError.URL=false
    }
    if(!this.attechForm.FILENAME){
      this.attechError.FILENAME=true
    }
    else{
      this.attechError.FILENAME=false
    }
    if(!this.attechError.URL && !this.attechError.FILENAME ){
      this.attechForm.FILECONTENTS=this.attechForm.URL;
      this.attechForm.MODULEHIERARCHYID=this.moduleId;
      this.attechForm.saveData()
      .then((result:any)=>{
        HttpClient.insertAduitLog("Engineering Records","Save Capture","Save Capture in Engineering Records").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this.attechForm.ID=result.id;
        this.attechmentDoc.getData(this.erCaptureId,33)
        .then((result)=>{
          this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
          this.attechForm=new Attechment();
        })
        // this.attechmentDoc.alldata.push(this.attechForm);
        // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        // this.attechForm=new Attechment();
      })
      .catch((err)=>{
        
      })
  }
  }
  attachementColumn(data:any){
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=33;
    this.attechForm.REFERENCEID=data.id;
  
  
  this.attechError={URL:false,FILENAME:false}
  
  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  this.attechId=data.id;
  this.attechmentDoc=new AttechmentObj();
  this.attechmentDoc.getData(data.id,this.moduleId)
  .then((result:any)=>{
    this.attachmentOpened = true;
    this.dailogTitle = "Add Attachment"
  //this.attachmentOpened = true;
  //this.dailogTitle = "Add Attachment"
  })
  .catch((err)=>{
   this.attechmentDoc.alldata=[]
  })
  }
  // attachementColumn(data:any) {
  //   console.log(data)
  //   this.attechForm={ 
  //         ID:0,
  //         REFERENCEID:data.id,
  //         FILETYPE:"pdf",
  //         FILENAME:"",
  //         URL:"",
  //         EMPLOYEEGLOBALID:this.employeId,
  //         CREATEDON:new Date(),
  //         MODULEHIERARCHYID:this.moduleId,
  //       }
    
  //    this.attechError={URL:false,FILENAME:false}

  //  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  //   this.attechId=data.id;
  //   this.getAttechment(data.id)
  //   .then((result:any)=>{
  //     this.attechmentDoc=[...result];
      // this.attachmentOpened = true;
      // this.dailogTitle = "Add Attachment"
  //   })
  //   .catch((err)=>{
  //      this.attechmentDoc=[]
  //   })
  // }
  deleteAttechment(e){
    this.attechForm.deleteData(e.dataItem)
    .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
    })
    .catch((err)=>{
     
    })
   // console.log(e)
  }
  downloadAttechment(data){
    window.open(environment.backendUrl +data.URL)
  }
  bindStaffObj(result) {
    this.userObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindServiceObj(result) {
    this.serviceObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }

  bindShiftObj(result) {
    this.shiftObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {})
  }
  

  getErCapturData(){
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/ER/GetAllERTasks`, true, true)
        .then((result: any) => {
          this.bindServiceObj(this.serviceDropDown);
          this.bindShiftObj(this.usrShiftDropDown);
          this.bindStaffObj(this.userDropDown);
          this.gridData = result.map((e,index)=>{
            e["soNo"] = index+1
            e["shift"] = this.shiftObj[e.shiftTypeId]
            e["service"] = this.serviceObj[e.serviceTypeId]
            // e["user"] = this.userObj[e.assignTo]
            e["user"] = e.userName;
            e["recordDateTime"] = e.recordDateTime == null?"":this.formatDateTime(new Date(e.recordDateTime))
            return e;
          });
        })
      })
  }

  formatDateTime(date) {
  if(date == null)
  return '';
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear(),
hour = '' + d.getHours(),
minutes = '' + d.getMinutes(),
secound = '' + d.getSeconds();
if (month.length < 2) 
  month = '0' + month;
if (day.length < 2) 
  day = '0' + day;
  if (hour.length < 2) 
  hour = '0' + hour;
  if (minutes.length < 2) 
  minutes = '0' + minutes;
  if (secound.length < 2) 
  secound = '0' + secound;
  return day +'/' +month +'/'+year +' ' + hour + ':'+minutes+':' + secound;

}

  getCheckList(){
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/ER/GetErTaskById?id=${this.erCaptureId}`, true, true)
        .then((result: any) => {
        
           if(result.erpersonInChargeModels){

           
            this.picGrid = result.erpersonInChargeModels.map((e)=>{
              if(e.personInChargeId){
                this.staffNameChange(e.personInChargeId)
              }
              e["picName"] = this.picName
              e["designation"] = this.designationName
              e["email"] = this.emailName
              e["companyName"] = this.companyName
              
              return e;
            }) 
           }

           if(result.erreadingModels){
        
          this.checklistData = result.erreadingModels.map((e)=>{
            e["uomName"] = e.uomName;
            if(e.status == 1)
            {
              e["status"] = e.status = "Pass";
            }
            else if(e.status == 2)
            {
              e["status"] = e.status = "Fail";
            }
            else {
              e["status"] = e.status = " ";
            }
              return e;
          });
           }
        })
      
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData);
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getBPM() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/BPM/GetBpms", true, true)
        .then((result: any) => {
          resolve(result);
        }).catch((err) => {
          reject(err)
        })
    })
  }

  staffNameChange(data){
    let value=  this.picDropDown.filter((e)=>{
      return e.id === data
    });
      if(value.length>0){
        let designationValue = this.designationValue.filter((e)=>{
          return e.ID===value[0].designationId
        })
             if(designationValue.length>0){
              this.designationName = designationValue[0].NAME
             }
              this.picName = value[0].staffName
              this.emailName = value[0].userName
              this.companyName = value[0].companyName
             
      }
    }

   
    download() {
      let fileName = 'ercapture.csv';
      let columnNames = [
        "So NO.",
        "ER Parameter Id",
        "ER Task Id",
        "Description",
        "Location",
        "Category Name",
        "ER NO.",
        "Facility Code",
        "Facility Name",
        "Area Code",
        "Record DateTime",
        "Shift",
        "Service",
        "User",
      ];
      let header = columnNames.join(',');
      let csv = header;
      csv += '\r\n';
    this.gridData.map(c => {
        csv += [
          c["soNo"],
          c["erparameterId"],
          c["ertaskId"],
          c["description"],
          c["location"] = c.location.replace(/,/g, ''),
          c["categoryName"],
          c["erno"],
          c["facilityCode"],
          c["facilityName"],
          c["areaCode"],
          c["recordDateTime"],
          c["shift"],
          c["service"],
          c["user"],
        ].join(',');
        csv += '\r\n';
      })
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
    

