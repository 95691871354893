import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';

@Component({
  selector: 'app-inventory-summery',
  templateUrl: './inventory-summery.component.html',
  styleUrls: ['./inventory-summery.component.scss']
})
export class InventorySummeryComponent implements OnInit {

  constructor() { }
   graphValue=[];
   stockTradeObj={}
   stockCategoryObj={};
   stockTradeList=[]
   stockCategoryList=[]
   public gridData=[]
   public column=[
    {field:"stockNo",title:"Stock No",isFilter:false ,width:200},
    {field:"category",title:"Category",isFilter:true ,width:200},
    {field:"trade",title:"Stock Trade",isFilter:true ,width:200},
  ]
   public graphObj={
       trade:[],
       category:[]
   }
  ngOnInit(): void {
    Promise.all([this.getStockTrade(),this.getStockCategory()])
    .then((result)=>{
       this.getSpareList()
    })
    .catch((err)=>{

    })
  }
  getStockTrade() {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=mm&Table=stockTrade`,
        true,
        true
      )
        .then((result: any) => {
          this.stockTradeObj={}
          console.log("stock trade =>",result)
         let stockTradeDropDow:any = JSON.parse(result.gridData);
         this.stockTradeList=stockTradeDropDow;
         stockTradeDropDow.forEach(e => {
           this.stockTradeObj[e.ID]=e;
         });
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  getStockCategory() {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=mm&Table=stockCategory`,
        true,
        true
      )
        .then((result: any) => {
          this.stockCategoryObj={}
          console.log("stock category =>",result)
         let stockCategoryDropDow = JSON.parse(result.gridData);
         this.stockCategoryList=stockCategoryDropDow;
         stockCategoryDropDow.forEach(e => {
          this.stockCategoryObj[e.ID]=e;
        });
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getSpareList() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetSpareList", true, true)
        .then((result: any) => {
          console.log("stock list =>",result)
          let graphCount={
            trade:{},
            category:{}
          }
          let stockList=[]
          result.forEach((d)=>{
            let obj=d;
            if(graphCount.trade[d.stockTradeId]){
              graphCount.trade[d.stockTradeId]=graphCount.trade[d.stockTradeId]+1
            }
            else {
              graphCount.trade[d.stockTradeId]=1;
            }


            if(graphCount.category[d.stockCategoryId]){
              graphCount.category[d.stockCategoryId]=graphCount.category[d.stockCategoryId]+1
            }
            else {
              graphCount.category[d.stockCategoryId]=1;
            }
            

            if(this.stockCategoryObj[d.stockCategoryId]){
               obj["category"]=this.stockCategoryObj[d.stockCategoryId]?.NAME
            }
            if(this.stockTradeObj[d.stockTradeId]){
              obj["trade"]=this.stockTradeObj[d.stockTradeId]?.NAME;
            }
            stockList.push(obj);
          })
          this.gridData=[...stockList];
          resolve(result);
          this.stockCategoryList=this.stockCategoryList.map((d)=>{
            if(graphCount.category[d.ID]){
              d["value"]=graphCount.category[d.ID];
            }
            else{
              d["value"]=0;
            }
            return d;
          })
          this.stockTradeList=this.stockTradeList.map((d)=>{
            if(graphCount.trade[d.ID]){
              d["value"]=graphCount.trade[d.ID];
            }
            else{
              d["value"]=0;
            }
            return d;
          })
          console.log("stockList==>",this.stockTradeList,this.stockCategoryList)
           this.graphObj.trade=this.stockTradeList.filter((d)=>d.value) || []
           this.graphObj.category=this.stockCategoryList.filter((d)=>d.value) || []
           this.graphObj={...this.graphObj}
        })
        .catch((err) => {
          reject(err);
        });
    // });
      })
       
      
      
  }
  filterChangedHandler(e){}
}
