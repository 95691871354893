import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ModelApi } from 'src/ApiModel/const';
import { Model } from 'src/ApiModel/Model';
import { WorkOrderObj } from 'src/ApiModel/Model/WorkOrder';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"
import { TicketReassignment } from 'src/ApiModel/Model/TicketReassignment';
import { NotificationService } from '@progress/kendo-angular-notification';
import { pading,convertDateFormate } from 'src/ApiModel/Util';

@Component({
  selector: 'app-ppm-load-balancing',
  templateUrl: './ppm-load-balancing.component.html',
  styleUrls: ['./ppm-load-balancing.component.scss']
})
export class PpmLoadBalancingComponent implements OnInit {

  public onTabSelect(e) {
    console.log(e);
  }
  public opened=false;
  public type="";
  asset_classi = ['Select'];
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
  public gridData = [];
  public format = "dd/MM/yyyy HH:mm";
  public avgWorkOrderPerMonth=0;
  public totalWorkOrderPInYear=0;
  public commictionvalue: Date = new Date(2021, 5, 1, 22);
 year_data= ['2021'];
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column=[
    {field:"month",title:"Month", isFilter:true,width:100},
    {field:"week1_count",title:"Week1", isFilter:true,width:100},
    {field:"week2_count",title:"Week2", isFilter:true,width:100},
    {field:"week3_count",title:"Week3", isFilter:true,width:100},
    {field:"week4_count",title:"Week4", isFilter:true,width:100},
    {field:"week5_count",title:"Week5", isFilter:true,width:100},
    {field:"tootal_count",title:"Total", isFilter:true,width:100},
  ];

  public columnReassign=[
    {title:"PPM WO ID",field:"workOrderNo", isFilter:true,width:250},
    {title:"Asset No.",field:"assetNo", isFilter:true,width:100},
    {title:"Asset Description",field:"assetDescription", isFilter:true,width:100},
    {title:"Location",field:"location", isFilter:true,width:100},
    {title:"Status",field:"status", isFilter:true,width:100},
    {title:"Assignee",field:"assignee", isFilter:true,width:100},
    // {title:"Target Date",field:"targetDate", isFilter:true,width:100},
    {title:"Assign To",field:"assignTo", isFilter:true,width:100},
  ];
  // let obj={
  //   workOrderNo:d.workOrderNo,
  //   assetNo:null,
  //   assetDescription:null,
  //   location:null,
  //   status:this.model.ticketStatus.objectData[d.ticketStatusId].NAME,
  //   assignTo:this.model.contractor.objectData[d.bpmid].name,
  //   assignee:this.model.user.objectData[d.userId].name,
  // }

  public reassignWorkOrder=[];

  public areagrid=[
  ];
  public ppmLoadBalancing:WorkOrderObj=new WorkOrderObj();
// public gridData=sampleProducts;
public filterGrid={
  assetClassificatioon:null,
  assignee:null,
  assignTo:null,
  areaName:null,
  type:null,
  year:null,
  month:null,
  facility:null,
  planner:null,
  block:null,
  level:null,
  area:null,
  location:null
}   
public reassignObj={
  assignee:null,
  assignTo:null,
  proposeDate:null,
  targetDate:null
} 
public model:Model=new Model();

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  // public value: Date = new Date(2000, 2, 10);

  maintenence_status: FormGroup;
  submitted = false;
  // status = ['Active','Inactive'];
  
  constructor(private formBuilder: FormBuilder,public _notif:NotificationService) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
   }
   editColumn(data){}

  ngOnInit() {
    this.model.getData(
      ModelApi.Asset,
      ModelApi.User,
      ModelApi.Contractor,
      ModelApi.MaintenancePlanner,
      ModelApi.levelarea,
      ModelApi.block,
      ModelApi.blocklevel,
      ModelApi.arealocation,
      ModelApi.facility,
      ModelApi.month,
      ModelApi.Year,
      ModelApi.assetClassfication,
      ModelApi.ticketstatus
      )
    .then((result)=>{
     // this.reset();
      //  console.log(this.model)
       this.model.user.filterData=this.model.user.alldata.filter((d)=>d.categoryId==1)
      //this.model.planner.filterData=this.model.planner.alldata.filter((d)=>d.MAINTENANCETYPEID==this.id)
      //this.getData();
    })
    .catch((err)=>{
      console.log(err)
    })
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }
  
  onDialogClose(){
   
    this.opened=false;
   
  }

  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Work Order Screen"
    }
    if(type=="update"){
      this.dailogTitle="Edit Maintenence Status"
    }
    this.opened=true;
  }

  filterChange(type,data){
    if(type=="assetClassificatioon"){
      this.filterGrid.assetClassificatioon=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee =data;
    }
    else if(type=="location"){
      this.filterGrid.location=data;
    }
    else if(type=="year"){
      this.filterGrid.year=data;
    }
    else if(type=="planner"){
      this.filterGrid.planner=data;
    }
    else if(type=="assignTo"){
      this.filterGrid.assignTo=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee=data;
    }
    else if(type=='facility'){
      this.filterGrid.facility=data
      this.model.block.filterData=this.model.block.alldata.filter((d)=>d.FACILITYID==data.ID)
      this.filterGrid.block=null;
      this.filterGrid.level=null;
      this.filterGrid.area=null;
      this.filterGrid.location=null;
    }
    else if(type=="block"){
        this.filterGrid.block=data
        this.model.level.filterData=this.model.level.alldata.filter((d)=>d.BLOCKID==data.ID)
        this.filterGrid.level=null;
        this.filterGrid.area=null;
        this.filterGrid.location=null;
    }
    else if(type==="level"){
      this.filterGrid.level=data
      this.model.area.filterData=this.model.area.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
      this.filterGrid.area=null;
      this.filterGrid.location=null;
     // this.areaObj={...this.areaObj}      
    }
    else if(type==="area"){
      this.filterGrid.area=data
      this.model.location.filterData=this.model.location.alldata.filter((d)=>d.LEVELAREAID==data.ID)
      this.filterGrid.location=null;
    }
    else if(type==="location"){
      this.filterGrid.location=data
    }
    else if(type==="month"){
      this.filterGrid.month=data;
    }
  }
 fetchData(){
  let m=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  let a=[];
    m.forEach((d,i)=>{
       let obj={
          month:d,
          week1:[],
          week2:[],
          week3:[],
          week4:[],
          week5:[]
       }
       a.push(obj);
    })
   let assetClasificationId=this.filterGrid.assetClassificatioon?.id || null;
   let plannerId=this.filterGrid.planner?.id||null;
   let locationId=this.filterGrid.location?.ID || null;
   let assignTo=this.filterGrid.assignTo?.id || null;
   let assignee=this.filterGrid.assignee?.id || null;
   let year=this.filterGrid.year?.ID || null;
   let month=this.filterGrid?.month?.ID || null;
   let blockId=this.filterGrid.block?.ID || null;
   let levelId=this.filterGrid.level?.ID || null;
   let areaId=this.filterGrid.area?.ID || null;
   this.avgWorkOrderPerMonth=0;
   this.totalWorkOrderPInYear=0;
   this.ppmLoadBalancing.getByFilter(assetClasificationId,plannerId,locationId,assignTo,assignee,year,month,blockId,levelId,areaId)
   .then((result:Array<{weekNumber:number,monthNumber:number,workOrders:Array<any>}>)=>{
     console.log(result);
    //  public List<TicketModel> WorkOrders { get; set; }
    //  public int WeekNumber { get; set; }
    //  public int MonthNumber { get; set; }
     // this.totalWorkOrderPInYear=result.length;
      result.forEach((d)=>{
        a[d.monthNumber-1]["week"+d.weekNumber]=d.workOrders ||[]
        this.totalWorkOrderPInYear+=(d.workOrders ||[]).length;
      })
      this.avgWorkOrderPerMonth=parseInt((this.totalWorkOrderPInYear/12)+"")
      this.areagrid=a.filter((d)=>d["week1"].length>0 || d["week2"].length>0 || d["week3"].length>0 || d["week4"].length>0 || d["week5"].length>0)
   })
   .catch((err)=>{
          console.log(err)
   })

 }
 resetFetch(){
   this.filterGrid={
    assetClassificatioon:null,
    assignee:null,
    assignTo:null,
    areaName:null,
    type:null,
    year:null,
    month:null,
    facility:null,
    planner:null,
    block:null,
    level:null,
    area:null,
    location:null
  }   
 }

 openRessignWorkOrder(weekName,data){
   console.log(weekName,data);
   this.opened=true;
   if(data[weekName].length>0){
   this.reassignWorkOrder= data[weekName].map((d)=>{
      let obj={
        ticketId:d.id,
        workOrderNo:d.workOrderNo,
        assetNo:null,
        assetDescription:null,
        location:null,
        status:this.model.ticketStatus.objectData[d.ticketStatusId].NAME,
        assignTo:this.model.contractor.objectData[d.bpmid].name,
        assignee:this.model.user.objectData[d.assignee].staffName,
        bpmid:d.bpmid,
        userId:d.assignee,
        ticketStatusId:d.ticketStatusId
      }
      return obj;
    })
   }
   else{
     this.reassignWorkOrder=[]
   }
 }
 convertLocalDateString(d){
   if(!d)
     return null;
  let ndate=new Date(d);
  return `${ndate.getFullYear()}-${pading(ndate.getMonth()+1)}-${pading(ndate.getDate())}T${pading(ndate.getHours())}:${pading(ndate.getMinutes())}:${ndate.getSeconds()}`
}
 reassignWO(){
   Promise.all(this.reassignWorkOrder.map((d)=>{
     let obj=new TicketReassignment();
     obj.ASSIGNEE=d.userId
     obj.BPMID=d.bpmid
     obj.BPMREASION="load balance"
     obj.NEWASSIGNEE=this.reassignObj.assignee.id
     obj.NEWBPMID=this.reassignObj.assignTo.id;
     obj.PROPOSEDDATE=this.convertLocalDateString(this.reassignObj.proposeDate)
     obj.TARGETDATE=this.convertLocalDateString(this.reassignObj.targetDate)
     obj.REASON="load";
     obj.REASSIGNMENTDATE=new Date();
     obj.TICKETID=d.ticketId;
     obj.TICKETSTATUSID=d.ticketStatusId;
     obj.USERREASON="load balance"
     return obj.saveData()
   }))
   .then((result)=>{
    this._notif.show({
      content: "Successfully saved.",
      type: { style: "success", icon: true },
    });
    this.opened=false;
    this.reassignWorkOrder=[];
    this.fetchData()
   })
   .catch((err)=>{
    this._notif.show({
      content: "Fail",
      type: { style: "error", icon: true },
    });
    this.opened=false;
    this.reassignWorkOrder=[];
   })
 }
}
