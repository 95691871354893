import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class ContractType{ 
    id:number;
    description:string;
    name:string;
  
}

export class ContractTypeObj{
    alldata:Array<ContractType>=[]
    filterData:Array<ContractType>=[]
    selectedObj:ContractType=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:ContractType)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
           // HttpClient.get("/api/MasterData/SelectAssetManufacturers",true,true)
           getLoockup(Schema.mm,LOOKUPTABLE.contractType)
            .then((result:Array<ContractType>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}