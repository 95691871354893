
<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
>

<!-- <kendo-grid-column field="ProductID" title="Contact Person">
    <div>1</div>
</kendo-grid-column>
<kendo-grid-column field="ProductName" title="Contact No.">
    <div>2</div>
</kendo-grid-column>
<kendo-grid-column field="Category.CategoryName" title="Email">
    <div>3</div>
</kendo-grid-column> -->

<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
  >
    <ng-template   kendoGridCellTemplate let-isNew="isNew" let-dataItem>
        <input  
            kendoTextBox 
            class="form-control" 
            [(ngModel)]="dataItem[col.field]"
            [disabled] ="col.disable"
            (change)="executeAction('edit-save',dataItem.index,dataItem)"
        />
        <div style="color: red;" *ngIf="dataItem[col.errorField]">{{dataItem[col.errorField]}}</div>
        
    </ng-template>
    <!-- <ng-template *ngIf="col.type=='submit'" kendoGridCellTemplate let-isNew="isNew" let-dataItem>
        <button class="submit-btn-m">Submit</button>
    </ng-template>
    <ng-template *ngIf="col.type=='delete'" kendoGridCellTemplate let-isNew="isNew" let-dataItem>
        <button  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button>
    </ng-template> -->

</kendo-grid-column>
<kendo-grid-column title="" width="80" *ngIf="action">
    <ng-template kendoGridCellTemplate  let-dataItem>
        <!-- <span *ngIf="dataItem.action=='view'" class="k-icon k-i-edit k-i-pencil action-icon" (click)="executeAction('edit',dataItem.index,dataItem)" ></span>
        <span *ngIf="dataItem.action=='view'" class="k-icon k-i-delete k-i-trash action-icon" (click)="executeAction('delete',dataItem.index,dataItem)"></span> -->

        <!-- <button kendoGridEditCommand *ngIf="dataItem.action=='view'"    icon="pencil" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
        <button kendoGridRemoveCommand *ngIf="dataItem.action=='view'"  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button> -->
        <!-- <button *ngIf="dataItem.action=='new' || dataItem.action=='edit'"   icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button> -->
        <!-- <button *ngIf="dataItem.action=='view'"  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button> -->
        <button *ngIf="dataItem.action=='new'" class="submit-btn-m" (click)="executeAction('new',dataItem.index,dataItem)">Add</button>
        <!-- <span *ngIf="dataItem.action=='edit'" class="k-icon k-i-check k-i-checkmark action-icon" (click)="executeAction('edit-save',dataItem.index,dataItem)" ></span> -->
        <span *ngIf="dataItem.action=='edit'" class="k-icon k-i-delete k-i-trash action-icon" (click)="executeAction('delete',dataItem.index,dataItem)"></span>


        <!-- <button *ngIf="dataItem.action=='edit'"   icon="save" style="color: lightgray;background: transparent;border: 0px;"></button>
        <button *ngIf="dataItem.action=='edit'"   icon="cancel" style="color: lightgray;background: transparent;border: 0px;"></button> -->
        <!-- <button *ngIf="dataItem.action=='edit'" class="submit-btn-m">Submit</button> -->

        <!-- <button  kendoButton (click) ="viewColumn(dataItem)"    icon="eye" style="color: lightskyblue;background: transparent;border: 0px;"></button> -->
    </ng-template>
  </kendo-grid-column>
<!-- <kendo-grid-command-column *ngIf="action" title="" width="100">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand    icon="pencil" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button *ngIf="deleteFlag" kendoGridRemoveCommand  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button>
  </ng-template>
</kendo-grid-command-column> -->
</kendo-grid>