import { Component, OnInit } from '@angular/core';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { getQueryParams } from 'src/Util/Util';

@Component({
  selector: 'app-work-order-query',
  templateUrl: './work-order-query.component.html',
  styleUrls: ['./work-order-query.component.scss']
})
export class WorkOrderQueryComponent implements OnInit {
  public permission:Permission=new Permission();
  public selectedTab="location";
  constructor() { }
  public onTabSelect(e) {
    if(e.index==0){
      this.selectedTab="location"
    }
    else{
      this.selectedTab="asset"
    }
   // console.log(e);
  }

  ngOnInit(): void {
    this.selectedTab=  getQueryParams("type") || "location";
    if(UserModuleObj.work_order){
      this.permission=UserModuleObj.work_order;
    }
  }

}
