

<div class="card m-3"   >
  <!-- <h5 class="card-header">Blocks</h5> -->
  <div class="card-header" style="display: flex;justify-content: space-between;">
      <h5>Notifications</h5>
    
  </div>
  <div class="card-body register-form-body"  >
    <form class=""  [formGroup]="BlockForm">
      <div class="form-row">
        <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
          <label>Select Platform</label>
          <div style="width: 100%;">
                  <kendo-multiselect 
                      [data]="SelectPlatformData" 
                      textField="name"
                      valueField="ID"
                      formControlName="SelectPlatform"
                  >
                  </kendo-multiselect>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
          <label>Recipient User Type</label>
          <div style="width: 100%;">
          
                  <kendo-multiselect 
                      [data]="loginUserTypeDropDown" 
                      textField="NAME"
                      valueField="ID"
                      formControlName="Usertype"
                  >
                  </kendo-multiselect>
          </div>
        </div>
        </div>
        <div class="form-row">
          <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
              <label>Title</label>
              <div style="width:100%">
              <input kendoTextBox  formControlName="Title"  class="form-control"/>
              </div>
          </div>
      </div>
          <div class="form-row">
              <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Text</label>
                  <div style="width:100%">
                  
                  <textarea  class="form-control" 
                  name="message"
                  rows="8" #Text formControlName="Text"
                  ></textarea>
                  </div>
              </div>
          </div>

       
           <kendo-dialog-actions [layout]="actionsLayout">
          <button kendoButton (click)="onReset()">Cancel</button>
       
          <button  kendoButton (click)="onSubmit()" primary="true">
          Send
          </button>
        </kendo-dialog-actions>
      </form>
    </div>
  </div>
