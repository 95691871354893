import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EditorResizableOptions } from "@progress/kendo-angular-editor";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import HttpClient from "src/Util/ApiHandling";
import { NotificationService } from "@progress/kendo-angular-notification";
@Component({
  selector: 'app-notification-centre',
  templateUrl: './notification-centre.component.html',
  styleUrls: ['./notification-centre.component.scss']
})
export class NotificationCentreComponent implements OnInit {
  public actionsLayout = "normal";
  public SelectPlatformData = [{"name":"User email address","ID":"1"},{"name":"Mobile application","ID":"2"},];
  public UsertypeData =  [{"name":"Company User","id":"1"},{"name":"Facility User","id":"2"},{"name":"External User","id":"3"},{"name":"Super Admin","id":"4"}];
  public BlockForm: FormGroup;
  public opened=false;
  public permission:Permission=new Permission();
  public loginUserTypeDropDown:any=[];
  constructor(private _notif: NotificationService) {}
  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 150,
    maxWidth: 600,
    maxHeight: 450,
  };

  ngOnInit(): void {
    if(UserModuleObj.level)
    this.permission=UserModuleObj.level;
    this.BlockForm = new FormGroup({
      SelectPlatform: new FormControl(''),
      Usertype: new FormControl(''),
      Title: new FormControl(''),
      Text: new FormControl(''),
    });
    this.getloginUserTypeData("dbo", "category");
  }
  onSubmit()
  {
    let SelectPlatformArray =this.BlockForm.get("SelectPlatform").value;
    let SelectPlatform='';
    SelectPlatformArray.forEach(element => {
      SelectPlatform != ''?
      SelectPlatform = SelectPlatform+','+element.ID: SelectPlatform = element.ID;
    });
    let UsertypeArray =this.BlockForm.get("Usertype").value;
    let Usertype='';
    UsertypeArray.forEach(element => {
      Usertype!=''?
      Usertype = Usertype+','+element.ID.toString(): Usertype = element.ID.toString();
    });
    if(SelectPlatform !=''&& Usertype !=''){   
    let paramsObj={
      "id": 0,
      "platform": SelectPlatform,
      "recipientUserType": Usertype,
      "title": this.BlockForm.get("Title").value,
      "text": this.BlockForm.get("Text").value,
      "createdOn":new Date(),
      }
    HttpClient.fetchData(
      "/api/MasterData/AddSystemSettingNotification",
      "post",
      paramsObj,
      true,
      true
    ).then((result: any) => {
      if (result.isValid == true) {
        HttpClient.insertAduitLog("System Setting","Add Notification","Add Notification in System Setting").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
      } else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
    });
  }
  }
  getloginUserTypeData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.loginUserTypeDropDown = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  onReset()
  {
    this.BlockForm.reset();
    this.BlockForm = new FormGroup({
      SelectPlatform: new FormControl(''),
      Usertype: new FormControl(''),
      Title: new FormControl(''),
      Text: new FormControl(''),
    }); 
  }
  onDialogClose(type) {
    if ((type = "new")) {
      this.opened = false;
    }
    if ((type = "remove")) {
    }
  }
  onDialogOpen(type) {
    if ((type = "new")) {
      this.opened = true;
    }
    if ((type = "remove")) {
    }
  }
}
