import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EditorResizableOptions } from "@progress/kendo-angular-editor";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import HttpClient from "src/Util/ApiHandling";
import { NotificationService } from "@progress/kendo-angular-notification";
import { getCookie,shiftLastDataInto1st } from "src/Util/Util";
import { userid } from "src/Util/const";
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public actionsLayout = "normal";
  public formGroup: FormGroup;
  public permission:Permission=new Permission();
  constructor(private _notif: NotificationService) { }
  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 150,
    maxWidth: 600,
    maxHeight: 450,
  };

  ngOnInit(): void {
    if(UserModuleObj.level)
    this.permission=UserModuleObj.level;
    this.formGroup = new FormGroup({
      privacy: new FormControl(''),
    });
    this.getprivacy();
  }
 
getprivacy()
{
  HttpClient.get(
    `/api/MasterData/GetSystemSettingId?id=2`,
    true,
    true
  ).then((result: any) => {
    this.formGroup.get("privacy").setValue(result.body);
  });
}
onSubmit()
  {
    let paramsObj={
      "id": 2,
      "titile": "Privacy",
      "body": this.formGroup.get("privacy").value,
      "description": '',
      "createdOn": new Date(),
      "cretedBy": 1,
      "modifiedBy": 0,
      "modifiedDate": new Date(),
      "isActive": true
    }
    HttpClient.fetchData(
      "/api/MasterData/UpdateSystemSetting",
      "post",
      paramsObj,
      true,
      true
    ).then((result: any) => {
      if (result.isValid == true) {
        HttpClient.insertAduitLog("System Settings","Update Privacy and Policy","Update Privacy and Policy in System Settings").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
      } else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
    });
  }
 
}
