<div class="card m-3">
    <div class="card-header" >
        <h5>PDM Planner For Asset</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Contractor And Vendor
          </button> -->
        </div>
    </div>
    <div class="card-body">
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset No.<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control" [filterable]="true">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Checklist Name<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                       
                    </div>
                     <div class="form-row">
                        
                        <div class="form-group col-5">
                            <label>Checklist ID</label>
                            <input kendoTextBox  class="form-control" disabled/>
                            
                        </div>
                        <div class="form-group col-5">
                            <label>Service Type<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>PDM ID</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Location</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                     
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Year<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Asset Type Code</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset Type Code Description</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Asset Classification</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
              
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Model</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Manufacturer</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
               
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Serial Number</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                       
                        <div class="form-group col-5">
                            <label>Assignee<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assign To<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                           
                            
                        </div>
                        <div class="form-group col-5">
                            <label>Contact No.</label>
                            <input kendoTextBox  class="form-control"/>
                            
                        </div>
                       
                  
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Status<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                        <div class="form-group col-5">
                            <label>Schedule Type<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                       
                      </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Month<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                       
                        </div>
                        <div class="form-group col-5">
                            <label>Date</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                           
                    </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Week</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                        </div>
                       
                        <div class="form-group col-5">
                           
                            <label>Day</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                        </div>
                    </div>
                    <div class="text-center mt-3 mb-4">
                        <button kendoButton primary="true" class="mr-2">Save</button>
                        <button kendoButton primary="true" class="mr-2">Reset</button>
                        <button kendoButton primary="true" class="mr-2">Delete</button>
                        <button kendoButton primary="true" class="mr-2">Import New</button>
                        <button kendoButton primary="true" class="mr-2">Import Update</button>
                        <button kendoButton primary="true" class="mr-2">Download Template</button>
                    </div>
                         
    </div>
</div>


