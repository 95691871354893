import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { shiftLastDataInto1st } from 'src/Util/Util';
import { UserModuleObj,Permission } from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-asset-qr-code-printing',
  templateUrl: './asset-qr-code-printing.component.html',
  styleUrls: ['./asset-qr-code-printing.component.scss']
})
export class AssetQrCodePrintingComponent implements OnInit {
  public printBtnObj={
    status:false,
    id:1
  }
  public opened=false;
  public hideCreatePpmPlanner = false;
  public qrCodeDialogOpen = false;
  public removeDialogOpen = false;
  public permission:Permission=new Permission();
  public resolution: number;
  public checkedArray =[]
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public commictionvalue: Date = new Date(2021, 5, 1, 22);
  public servicestartvalue: Date = new Date(2021, 5, 1, 22);
  public servicestopvalue: Date = new Date(2021, 5, 1, 22);
  public lastworksc: Date = new Date(2021, 5, 1, 22);
  public lastworkusc: Date = new Date(2021, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  public removeAsset_Id;
  public assetManufactureData;
  public assetStandDisable =false;
  public assetTypeData;
  public assetModelData;
  public assetTypeStandId;
  public modelObj = {};
  public manufactureObj = {};
  public assetTypeObj = {};
  public facilityDropDown =[];
  public blockCodeDropDown =[];
  public levelCodeDropDown = [];
  public areaCodeDropDown  = [];
  public locationCodeDropDown =[];
  public subLocationDropDown = [];
  public assetClassDropDown = []
  public assStandDropDown =[]
  public assetStatusDropDown = []
  public allBlocksValue =[];
  public allLevelsValue =[];
  public allAreasValue =[];
  public allLocationValue =[];
  public allSubLocationValue =[];
  public blockName;
  public levelName;
  public areaName;
  public locationName;
  public subLocationName;
  public assetTypeCode;
  public assetTypeDesc;
  public assetModel;
  public assetAge;
  public yearInService;
  public assetManufacture;
  public MODULEHIERARCHYID;
  public assetClassificationValue;
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public AssetClassObj ={}
  public facilityObj ={};
  public locationObj ={};
  public subLocationObj ={};
  public contractTypeObj ={};
  public attachAssetRegId;
  public fetchId;
  public statusObj ={};
  public qrCodeGenUrl =[];
  public imageUrl = environment.backendUrl+`/`;
  public PpmFlag = "No"
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationData: Array<{ code: string; id: number;name: string }>;
  public filterdSubLocationData: Array<{ code: string; id: number;name: string }>;
  public contractTypeDropDown = [];
  public qrcodePrintData=[] 
  public assetStandObj={};
  facilityAllObj: any;
  public onTabSelect(e) {
    console.log(e);
  }



  public column=[
    {field:"assetClassification",title:"Asset Classification",isFilter:true ,width:200},
    {field:"assetNumber",title:"Asset No.", isFilter:true ,width:200},
    {field:"serialNo",title:"Serial No", isFilter:true ,width:200},
    {field:"assetTypeCode",title:"Asset Type Code", isFilter:true ,width:200},
    {field:"assetTypeDescription",title:"Asset Type Description", isFilter:true ,width:200},
    {field:"facility",title:"Faciltiy", isFilter:true,width:200},
    {field:"block",title:"Block", isFilter:true,width:200},
    {field:"area",title: " Area Name", isFilter:true,width:200},
    {field:"level",title: " Level", isFilter:true,width:200},
    {field:"location",title:"Location", isFilter:true,width:200},
    {field:"subLocation",title:"Sub Location", isFilter:true,width:200},
    {field:"model",title:"Model", isFilter:true,width:200},
    {field:"manufacture",title:"Manufacturer", isFilter:true,width:200},
    {field:"contractType",title:"Contract Type", isFilter:true,width:200},
    {field:"status",title:"Status", isFilter:true,width:200},
  ]
  public gridData=[]
  public column2=[
    {field:"ProductID",title:"File Type",isFilter:true},
    {field:"ProductName",title:"File Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Attachments", isFilter:true},
    {field:"UnitPrice",title:"Downloads", isFilter:true}
  ]
  
  constructor(){
    eventListner(EVENT_NAME.  checkbox_column, (e) => { this.getAssetCheckedValue(e) });
  }

  
  ngOnInit() {
    if(UserModuleObj.qr_code){
      this.permission=UserModuleObj.qr_code;
    }
    Promise.all([
     this.getfacility(),
     this.getAssetManufacture(),
     this.getAssetModel(),
     this.getAssetTypeCode(),
     this. getAssetStatus(),
     this.getAssetClass(),
     this.getContractorTypedata("mm","contractType"),
    ])
      .then((result) => {
        this.getAssetStandard()
        .then((result)=>{
          this.getAssetRegistrationData();
        })
        this.getModuleHierarchYdata("dbo","moduleHierarchy");

        if(this.fetchId){
          this.opened = true;
          this.assetStandDisable = true;
          
        }
    })
   }
  
  // convenience getter for easy access to form fields


 

  bindAssetClassObj(result){
    this.AssetClassObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})
  }
  openPrintModel(){
    console.log("assetData=>",this.checkedArray);
    this.qrcodePrintData=this.checkedArray.map((d)=>{
      let obj={
        data:`${window.location.origin}/mobile?QRCodeType=asset&id=${d.id}`,//JSON.stringify({QRCodeType:"Asset",QRCodeObjectId:d.id}),
        assetCode:d.assetCode,
        assetNo:d.assetNumber,
        assetDescription:d.assetTypeDescription,
        email:d?.facilityObj?.email,
        phoneNo:d?.facilityObj?.phoneNo
      }
      return obj;
    })
    this.opened=true;
    setTimeout(()=>{
      this.printBtnObj.status=true;
      this.printBtnObj.id=new Date().getTime()
    },2000)
    // Promise.all(
    //   this.checkedArray.map((e)=>{
    //    return   HttpClient.get(
    //          `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${e.id}&&moduleHierarchyId=${ this.MODULEHIERARCHYID}`,
    //            true,
    //            true
    //          )
    //          })).then((result: any) => {
    //              this.qrCodeGenUrl = result;
    //              console.log(  this.qrCodeGenUrl)
    //              this.opened=true;
                //  setTimeout(()=>{
                //    this.printBtnObj.status=true;
                //    this.printBtnObj.id=new Date().getTime()
                //  },2000)
        
    //           })  
  }

 getAssetTypeCode(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetTypes", true, true)
        .then((result: any) => {
          this.assetTypeData = result;
            this.bindAssetTypeObj(result);
           this.bindAssetClassObj(result);
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetModel(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetModels", true, true)
        .then((result: any) => {
          this.assetModelData = result;
          this.bindModelObj(result)
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getAssetManufacture(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetManufacturers", true, true)
        .then((result: any) => {
          this.assetManufactureData = result;
          this.bindManufactureObj(result)
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

 

  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
        
        if(result.length>0){
          this.facilityAllObj=result.reduce((acc,d)=>{
            acc[d.id]=d;
            return acc;
          },{})
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue;
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue;
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }

          this.areaCodeDropDown = this.allAreasValue;
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          if(this.areaCodeDropDown.length>0){
            let locationValue = [];
            locationValue = this.areaCodeDropDown.map((e)=>{
                   return e.areaLocations;
            })
             
            for (let i = 0; i < locationValue.length; i++) {
              for (let j = 0; j < locationValue[i].length; j++) {
                let arr = [];
                arr =  locationValue[i][j];
                this.allLocationValue.push(arr);
              }
            }
            this.locationCodeDropDown = this.allLocationValue;
            this.filterdLocationData =  this.locationCodeDropDown.slice();
            if(this.locationCodeDropDown.length>0){
              let subLocationValue = [];
              subLocationValue = this.locationCodeDropDown.map((e)=>{
                return e.locationSubLocations;
              })
              for(let i =0; i< subLocationValue.length;i++){
                for(let j=0;j< subLocationValue[i].length ;j++){
                   let arr =[];
                   arr = subLocationValue[i][j];
                   this.allSubLocationValue.push(arr);
                }
              }
              this.subLocationDropDown = this.allSubLocationValue;
            
             this. filterdSubLocationData = this.subLocationDropDown.slice();
            }
           
          }

         resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  facilityValueChange(facilityId) {
    this.blockCodeDropDown = this.allBlocksValue.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = this.blockCodeDropDown.slice();
  }
  
  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    this.levelCodeDropDown = this.allLevelsValue.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = this.levelCodeDropDown.slice();
  }
  
  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
    this.areaCodeDropDown = this.allAreasValue.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData = this.areaCodeDropDown.slice();
  }
  
  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
     this.locationCodeDropDown = this.allLocationValue.filter((e)=>{
       return e.levelAreaId = areaId;
     })

     this.filterdLocationData =  this.locationCodeDropDown.slice();
  }

  locationValueChange(locationId){
    let selectLocationValue = this.filterdLocationData.filter((e) => {
      return e.id == locationId;
    });
    this.locationName =selectLocationValue[0].name;
    this.subLocationDropDown = this.allSubLocationValue.filter((e)=>{
      return e.areaLocationId = locationId;
    })
    this.filterdSubLocationData = this.subLocationDropDown.slice();
  }

  subLocValueChange(subLocId){

    let selectedSubLocValue = this.filterdSubLocationData.filter((e)=>{
      return e.id == subLocId;
    })

    this.subLocationName = selectedSubLocValue[0].name;
  }

  bindAssetTypeObj(result) {
    this.assetTypeObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})

    
  }
  bindManufactureObj(result) {
    this.manufactureObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindModelObj(result) {
    this.modelObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

getAssetStatus() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetStatuses", true, true)
        .then((result: any) => {
      
         this.assetStatusDropDown = result;
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetStandard(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetStandardizations", true, true).then((result: any) => {
      this.assStandDropDown = result.map((e)=>{
       let obj={id:e.id, name: this.assetTypeObj[e.assetTypeId] && this.assetTypeObj[e.assetTypeId].code}
       if(this.assetTypeObj[e.assetTypeId]){
         let assetTObj=this.assetTypeObj[e.assetTypeId];
         let asseC=this.AssetClassObj[assetTObj.assetClassificationId]
         obj["assetClassification"]=asseC;
         obj["assetType"]=assetTObj;
         
       }
       obj["modelObj"]=this.modelObj[e.assetModelId];
       obj["manufectureObj"]=this.manufactureObj[e.assetManufacturerId];

        
        this.assetStandObj[e.id]=obj;
         return obj
        })
      
        resolve(result);
      }).catch((e)=>{
        reject(e);
      })
    })
  }



  getAssetClass(){
   return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Assets/GetAssetClassifications", true,true)
    .then((result: any) => {
      this.bindAssetClassObj(result)
    //  this.assetClassDropDown= result.map((e=>{
    //       return {id:e.id,name:e.code}
    //   }))
      resolve(result)
             }).catch((e)=>{
      reject(e)
    })
   })
  }
getModuleHierarchYdata(schema, tablename){
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
    let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
              return e.NAME ==="Asset Registration"
        })
         
          this.MODULEHIERARCHYID=ModFacArr[0].ID;
       
    });
  }

  getContractorTypedata(schema, tablename){
    return new Promise((resolve, reject) => {HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
    this.contractTypeDropDown = JSON.parse(result.gridData);
       resolve(result);
    }).catch((e)=>{
      reject(e);
    })
  });
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }


  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindLocationObj(result) {
    this.locationObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }
  bindSubLocationObj(result){
    this.subLocationObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindContractObj(result){
    this.contractTypeObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindStatusObj(result){
    this.statusObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindClassiObj(result){
    this.AssetClassObj  = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }
  getAssetRegistrationData(){
    let AssetRegistrationData :any=[];
     
  return new Promise((resolve,rejects)=>{
    HttpClient.get(
      "/api/Assets/GetAssets",
      true,
      true
    ).then((result: any) => {
      
      AssetRegistrationData =  shiftLastDataInto1st(result) ||[];
          this.bindBlockObj(this.filterdBlockData);
          this.bindLevelObj(this.filterdLevelData);
          this.bindFacilityObj(this.facilityDropDown);
          this.bindAreaObj(this.filterdAreaData);
          this.bindLocationObj(this.filterdLocationData);
          this.bindSubLocationObj(this.filterdSubLocationData);
          this.bindContractObj(this.contractTypeDropDown);
          this.bindStatusObj(this.assetStatusDropDown);
  
      this.gridData = AssetRegistrationData.map((d)=>{
        if(d.assetLocations.length>0){
          d["block"] = this.blockObj[d.assetLocations[0].blockId];
          d["level"] = this.levelObj[d.assetLocations[0].levelId];
          d["facility"] =  this.facilityObj[d.assetLocations[0].facilityId];
          d["facilityObj"]=this.facilityAllObj[d.assetLocations[0].facilityId] || {};
          d["area"] = this.areaObj[d.assetLocations[0].areaId];
          d["location"] =  this.locationObj[d.assetLocations[0].locationId];
          d["subLocation"] = this.subLocationObj[d.assetLocations[0].subLocationId];
          d["contractType"] = this.contractTypeObj[d.contractTypeId];
        }
        if(d.assetInfos.length>0){
          d["status"] = this.statusObj[d.assetInfos[0].statusId]
        }
        if(d.assetStandardizationId){
          let standObj:any=this.assetStandObj[d.assetStandardizationId]
          if(standObj){
              d["assetTypeCode"] =standObj.assetType && standObj.assetType.code
              d["manufacture"] = standObj.manufectureObj //this.manufactureObj[assetClassificationData.assetManufacturerId];
              d["model"] = standObj.modelObj //this.modelObj[assetClassificationData.assetModelId];
              d["assetTypeDescription"]=standObj.assetType && standObj.assetType.description //this.assetTypeObj[assetClassificationData.assetTypeId] && this.assetTypeObj[assetClassificationData.assetTypeId].description;
              d["assetClassification"] = standObj.assetClassification && standObj.assetClassification.description //this.AssetClassObj[assetClassificationID]
          }
    }
   
  return d;
           })
           console.log("asset qr code==>",this.gridData)
      resolve(result);
    }).catch((err)=>{
      rejects(err);
    })
  })
}
closePrintModel(){
  this.opened=false;
  this.printBtnObj.status=false;
}
getAssetCheckedValue(data){
    
  if(data.status==true){
    this.checkedArray.push(data.data);
  }else if(data.status==false){
        this.checkedArray = this.checkedArray.filter((e)=>{
                 return e.id != data.data.id
        })
  }
          }
        }
 


