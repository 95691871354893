import {AssetLocationValue,  AssetRegistration,LocationTypeError ,AssetRegTypeError} from "src/app/models/asset-reg.model"
export let AssetLocationValidation = ( data: AssetLocationValue, type ) => {
    let error = new LocationTypeError(false)
    if (type === "init")
        return error;
    if (!data.blockId) {
        error.blockId = true;
    }
    if (!data.areaId) {
        error.areaId = true;
    }
    if (!data.locationId) {
        error.locationId = true;
    }
    if (!data.levelId) {
        error.levelId = true;
    }
    if (!data.subLocationId) {
        error.subLocationId = true;
    }
    if (!data.facilityId) {
        error.facilityId = true;
    }
    return error;
}

export let AssetRegValidation = (data: AssetRegistration, type) => {
    let error = new AssetRegTypeError(false)
    if (type === "init")
        return error;
    if (!data.assetStandardizationId) {
        error.assetStandardizationId = true;
    }
    if (!data.assetNumber) {
        error.assetNumber = true;
    }
    return error;
    
}