<div class="card m-3"   >
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Sub-Location</h5>
        <div>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate"  class="header-right-btn" (click)="openLocationDailog('new')">
                <span class="add_btn">+</span> Sub-Location
            </button>
        </div>
    </div>
    <div class="card-body">
        <app-table-grid 
           [data]="gridData" 
           [size]="10" 
           [column]="column"
           [deleteFlag]="false"
           [editFlag]="permission && permission.isUpdate"
           [qrcode] = "false"
           ></app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>

    <div class="form-row">
        <div class="form-group col-5">
         <label>Facility<span style="color: red;">*</span></label>
          <div style="width:100%">
         <kendo-dropdownlist  
         [data]="facilityDropDown"
         class="form-control"
         [textField]="'name'"
         [valueField]="'id'"
         [valuePrimitive]="true"
         [(ngModel)]="subLocationForm.FACILITYID"
         (valueChange)="facilityValueChange($event)"
         >
         </kendo-dropdownlist>
         <kendo-formerror *ngIf="error.FACILITYID">*Required</kendo-formerror>
     </div>
        </div>
     <div class="form-group col-5">
         <label>Block Code<span style="color: red;">*</span></label>
         <div style="width:100%">
          <kendo-dropdownlist     class="form-control"    [data]="filterdBlockData"
         [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"  [(ngModel)]="subLocationForm.BLOCKID"  [filterable]="true" (valueChange)="blockValueChange($event)" >
             </kendo-dropdownlist>
             <kendo-formerror *ngIf="error.BLOCKID">*Required</kendo-formerror>
             </div>
     </div>
 </div>
 <div class="form-row">
     <div class="form-group col-5">
         <label>Block Name</label>
         <input kendoTextBox  class="form-control" [(ngModel)]="blockName" readonly>
        
     </div>
     <div class="form-group col-5">
         <label>Level Code<span style="color: red;">*</span></label>
         <div style="width:100%">
         <kendo-dropdownlist  class="form-control"   [data]="filterdLevelData"
             [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true" [(ngModel)]="subLocationForm.BLOCKLEVELID" [filterable]="true"   (valueChange)="levelValueChange($event)">
                 </kendo-dropdownlist>
                 <kendo-formerror *ngIf="error.BLOCKLEVELID">*Required</kendo-formerror>
             </div>
     </div>
     
 </div>
 <div class="form-row">
     <div class="form-group col-5">
         <label>Level Name</label>
         <input kendoTextBox class="form-control" [(ngModel)]="levelName" readonly>
     </div>
     <div class="form-group col-5">
         <label>Area Code<span style="color: red;">*</span></label>
         <div style="width:100%">
         <kendo-dropdownlist    class="form-control"  [data]="filterdAreaData"
         [textField]="'code'" [valueField]="'id'"  [(ngModel)]="subLocationForm.LEVELAREAID"   [valuePrimitive]="true" (valueChange)="areaValueChange($event)"  [filterable]="true" >
             </kendo-dropdownlist>
             <kendo-formerror *ngIf="error.LEVELAREAID">*Required</kendo-formerror>
         </div>
     </div>
    
 </div>
 <div class="form-row">
     <div class="form-group col-5">
         <label>Area Name</label>
         <input kendoTextBox    [(ngModel)]="areaName" class="form-control" readonly>
    
     
 </div>
     <div class="form-group col-5">
         
         <label>Location Code<span style="color: red;">*</span></label>
         <div style="width:100%">
            <kendo-dropdownlist     class="form-control"
            [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true" [(ngModel)]="subLocationForm.AREALOCATIONID" [data]="filterdLocationData" (valueChange)="locationValueChange($event)" [filterable]="true" >
                </kendo-dropdownlist>
         <kendo-formerror *ngIf="error.AREALOCATIONID">*Required</kendo-formerror>
     </div>
         
     </div>
    
 </div>
 <div class="form-row">
     <div class="form-group col-5">
         <label>Location Name</label>
         <div style="width:100%">
         <input kendoTextBox [(ngModel)]="locationName" class="form-control" readonly>
         <!-- <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror> -->
     </div>
        
     </div>
     <div class="form-group col-5">
        <label>Sub-Location Name<span style="color: red;">*</span></label>
         <div style="width:100%">
        <input kendoTextBox [(ngModel)]="subLocationForm.NAME" class="form-control">
        <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror>
    </div>
  </div>
</div>

<div class="form-row">
    <div class="form-group col-5">
        <label>Sub-Location Code<span style="color: red;">*</span></label>
        <div style="width:100%">
        <input kendoTextBox  [(ngModel)]="subLocationForm.CODE" class="form-control"  >
        <kendo-formerror *ngIf="error.CODE">*Required</kendo-formerror>
    </div>
       
    </div>
    <div class="form-group col-5">
      
   </div>
 </div>

    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('new')">Cancel</button>
        <button kendoButton *ngIf="type=='new'" (click)="onReset()">Reset</button>
        <button kendoButton   *ngIf="type=='new'"  primary="true" (click)="onSubmit()">
            Save
        </button>
        <button kendoButton   *ngIf="type=='update'"  primary="true" (click)="onSubmit()">
         Update
        </button>
    </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="removeSubLocationData()">Yes</button>
      <button kendoButton (click)="onDialogClose('remove')">No</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="qrCodeDialogOpen" (close)="onDialogClose('qrcode')" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
         QR Code
        </div>
      </kendo-dialog-titlebar>
      <div style="width: 189px;height: 76px;margin:auto;display: flex;border: 1px solid gray;    padding: 5px;    border-radius: 10px;justify-content: space-between;"> 
        <div>
          <div style="text-align: center;">
            <img src="assets/logo.png"  style="height: 33px;">
            <p style="font-size: 8px;margin-bottom: 0; border: 1px solid gray;padding: 1px;">{{locationQrValue}}</p>
            
          </div>
        </div>
        <div>
          <img [src]="imageUrl+qrCodeGenUrl"  style="width: 54px;margin-top: 5px;">
        </div>
      </div>
 
 
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton primary="true">Print</button>
    </kendo-dialog-actions>
  </kendo-dialog>


  <kendo-dialog *ngIf="qrCodeDialogOpen" (close)="onDialogClose('qrcode')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        View QR
      </div>
    </kendo-dialog-titlebar>
          <app-qr-printing  type="location" [qrcodePrintData]="[{}]"></app-qr-printing>
  </kendo-dialog>