import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss']
})
export class AssetInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
