import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"

function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key].toLowerCase()]=i;
        }
        else{
       newObj[objR[i].toLowerCase()]=i;
        }
    }
    return newObj;
}

function bindBPMObj(data){
      return data.reduce((acc,d)=>{
                   acc[d.registrationNo] = d.id;

                   return acc;
      },{})
}

function bindContractTypeObj(data){
    return data.reduce((acc,d)=>{
                 acc[d.NAME] = d.ID;
                 return acc;
    },{})
}
export function addBlukContractManagement(
      contractList,contractorList,contractorTypeList,
    ) 

  {
  
    let empId=parseInt(getCookie(userid));
    let bpmObj =  bindBPMObj(contractorList);
    let contractorTypeObj = bindContractTypeObj(contractorTypeList)
    let userObjList = contractList.map((d) => {
        console.log("contract",d["Contract No"])
        let obj = {
            ID:0,
            BPMID:bpmObj[d["Contractor Code"]],
            CONTRACTNO:d["Contract No."],
            STATUSID:1,
            CONTRACTSTARTDATE:new Date(d["Contract Start Date"]),
            CONTRACTENDDATE:new Date(d["End Date"]),
            CONTRACTSUM:d["Contract Value (RM)"],
            CONTRACTTYPEID:contractorTypeObj[d["Contract Type"]],
            NOTIFICATIONFORRENEWAL:new Date(d["Notification for Renewal"]),
            SCOPEOFWORKY:d["Scope Of Work"],
            ATTACHMENTID:null,
            REMARKS:null,
            CREATEDBY:empId,
            CREATEDDATE:new Date(),
            MODIFIEDBY:empId,
            MODIFIEDDATE:new Date()
        }
      return obj;
    })
    return userObjList;
}