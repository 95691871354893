import { Component, OnInit } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {TaskAnswerList} from "src/ApiModel/Model/TaskAnswerList"
import {TaskAnswerListItemObj,TaskAnswerListItem} from "src/ApiModel/Model/TaskAnswerListItem"

import { eventListner, EVENT_NAME } from 'src/event';
@Component({
  selector: 'app-checklist-answer-list',
  templateUrl: './checklist-answer-list.component.html',
  styleUrls: ['./checklist-answer-list.component.scss']
})
export class ChecklistAnswerListComponent implements OnInit {
  public taskItemOpen=false
  constructor() { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });
    eventListner(EVENT_NAME.attachment_column, (e) => { this.addItem(e) });
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
      this.deleteItem(e);
   })
  //  eventListner(EVENT_NAME.download_attechment,(e)=>{
  //    this.downloadAttechment(e)
  //  })
  }
  public itemId=-1;
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public taskItemObj:TaskAnswerListItemObj=new TaskAnswerListItemObj();
  public taskItem:TaskAnswerListItem=new TaskAnswerListItem()
  public data=new TaskAnswerList();
  public task:any=[]
  public column=[
    {field:"NAME",title:"Name",isFilter:true,width:200},
  ]
  public taskItemColumn=[
    {field:"DESCRIPTION",title:"Description",isFilter:true,width:200},
  ]
  public taskItemGrid=[]
  ngOnInit(): void {
    // this.model.getData(ModelApi.ChekList)
    // .then((result)=>{
    //   this.getData();
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    this.taskItemObj.getData()
    this.getData();
  }
  addItem(data){
    this.itemId=data.ID;
    this.taskItem.ID=0;
    this.taskItemOpen=true;
    this.getItemData()
  }
  deleteItem(d){
    let data=d.dataItem;
     this.taskItem.deleteData(data)
     .then((result)=>{
        this.taskItemObj.alldata=this.taskItemObj.alldata.filter((d)=>d.ID!==data.ID)
        this.getItemData()
     })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
  }
  saveData(){
    if(this.type==="new"){
       this.data.saveData(this.data.NAME,true)
       .then((result)=>{
           
            this.getData()
            this.opened=false;
       })
       .catch((err)=>{
          console.log("err",err)
       })
    }
    else if(this.type=="update"){
      this.data.updateData(this.data.ID,this.data.NAME,this.data.ISACTIVE)
      .then((result)=>{
        
           this.getData()
           this.opened=false;
      })
      .catch((err)=>{
         
      })
    }
  }
  reset(){

  }

  getData(){
    this.model.getData(ModelApi.TaskAnswerList)
    .then((result)=>{
      this.task=this.model.taskAnsList.alldata.map((d)=>{
        //d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
        return d;
       })
    })
  }
  editColumn(d){
     this.data.ID=d.dataItem.ID;
     this.data.NAME=d.dataItem.NAME;
   //  this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }
 addNewItem(){
  this.taskItem.TASKANSWERLISTID=this.itemId;;
   this.taskItem.saveData()
   .then((result:any)=>{
       this.taskItem.ID=result.id;
       this.taskItemObj.alldata.push(this.taskItem)
       this.taskItem=new TaskAnswerListItem()
       this.getItemData()
   })
 }

 getItemData(){
  this.taskItemGrid=this.taskItemObj.alldata.filter((d)=>d.TASKANSWERLISTID==this.itemId)
 }
}
