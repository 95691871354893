<div class="card m-3"   >
  <!-- <h5 class="card-header">Asset Standardization</h5> -->
  <div class="card-header" style="display: flex;justify-content: space-between;">
      <h5>Asset Standardization</h5>
      <div>      
        <button *ngIf="permission && permission.isCreate" kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
          <span class="add_btn">+</span> Asset Standardization
        </button>
      </div>
  </div>
  <div class="card-body register-form-body"  >
      <app-table-grid
          [data]="gridData"
          [column]="column_asset_std"
          [size]="10"
          [editFlag] ="permission && permission.isUpdate"
          [deleteFlag]="false"
          [addAsset] ="true"
          deleteKey="deleteKey"
      ></app-table-grid>   
  </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose('asset')" [width]="560">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>
    <div class="form-row">
              <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Asset Type Code</label>
                  <div style="width:100%">
                      <kendo-dropdownlist
                     [(ngModel)]="assetStand_form.assetTypeId"
                     [data]="assetTypeArray"
                      [textField]="'code'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      class="form-control"
                    
                    >
                    </kendo-dropdownlist>
                
              </div>
              </div>
          </div>
          <div class="form-row">
            <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                <label>Type Code Description</label>
                <div style="width:100%">
                  <input kendoTextBox class="form-control" [(ngModel)]="assetTypeObj[assetStand_form.assetTypeId] && assetTypeObj[assetStand_form.assetTypeId].description" disabled>
                </div>
            </div>
          </div>
         <div class="form-row">
            <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
              <label>Model</label>
              <div style="width:100%">
              <kendo-dropdownlist
              [data]="assetModelDropDown"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              class="form-control"
              [(ngModel)]="assetStand_form.assetModelId"
            >
            </kendo-dropdownlist>
          
            </div>
          </div>
        </div>
        <div class="form-row">
            <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
              <label>Manufacturer</label>
              <div style="width:100%">
              <kendo-dropdownlist
              [data]="assetManufactureDropDown"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              class="form-control"
              [(ngModel)]="assetStand_form.assetManufacturerId"
            >
            </kendo-dropdownlist>
        
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
              <label>Status</label>
              <div style="width:100%">
              <kendo-dropdownlist
              [(ngModel)]="assetStand_form.assetStatusId"
              [data]="assetStatusDropDown"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              class="form-control"
              >
              </kendo-dropdownlist>
             
              </div>
          </div>
      </div>
     
      <kendo-dialog-actions [layout]="actionsLayout">
          <button kendoButton (click)="onDialogClose('asset')">Cancel</button>
          <button  *ngIf="type=='new'" kendoButton (click)="onReset()" primary="true">
            Reset
          </button>
          <button  *ngIf="type=='new'" kendoButton (click)="onSubmit()" primary="true">
              Save
            </button>
          
            <button *ngIf="type=='update'" kendoButton (click)="onSubmit()"  primary="true">
             Update
            </button>
      </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Delete Description
    </div>
  </kendo-dialog-titlebar>
  <p>Are you sure you want to delete?</p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="removeAssetStand()">Delete</button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="qrCodeDialogOpen" (close)="onDialogClose('qrcode')" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
     QR Code
    </div>
  </kendo-dialog-titlebar>
  <div style="width: 189px;height: 76px;margin:auto;display: flex;border: 1px solid gray;    padding: 5px;    border-radius: 10px;justify-content: space-between;"> 
    <div>
      <div style="text-align: center;">
        <img src="assets/logo.png"  style="height: 33px;">
        <p style="font-size: 8px;margin-bottom: 0; border: 1px solid gray;padding: 1px;">BGRA-T1-ACB-EDP-LP1-A1-02</p>
        <p style="font-size: 8px;margin-bottom: 0;padding: 3px;">ACB FOR EDP LEVEL P1</p>
      </div>
    </div>
    <div>
      <img [src]="imageUrl+qrCodeGenUrl"  style="width: 54px;margin-top: 5px;">
    </div>
  </div>

  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton primary="true">Print</button>
  </kendo-dialog-actions>
</kendo-dialog>