<div class="card m-3"   >
    <div class="card-header" >
        <h5>Asset Classification</h5>
        <div>      
          <button *ngIf="permission && permission.isCreate" kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> Asset Classification
          </button>
        </div>
    </div>
    <div   class="card-body register-form-body">
        
        <div>
            <app-table-grid
            [data]="gridData"
            [column]="column_asset"
            [size]="10"
            [editFlag] ="permission && permission.isUpdate"
            [deleteFlag]="false"
            deleteKey="deleteKey"
        ></app-table-grid>
        </div>
    </div>
</div>
  <kendo-dialog *ngIf="opened" (close)="onDialogClose('asset')" [width]="600">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
         
              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Classification Code</label>
                    <div style="width: 100%;">
                    <input *ngIf="type=='new'"   kendoTextBox   [(ngModel)]="assetClassificationForm.code" class="form-control"   disabled/>
                    <input *ngIf="type=='update'"   kendoTextBox [(ngModel)]=" assetClassificationForm.code" class="form-control" disabled />
                </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Asset Classification Description<span class="required">*</span></label> 
                    <div style="width: 100%;">
                    <input  kendoTextBox  [(ngModel)]="assetClassificationForm.description" class="form-control"/>
                    <kendo-formerror *ngIf="error.description">*Required</kendo-formerror>
                  
                </div>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Status </label>
                    <kendo-dropdownlist
                    [(ngModel)]=" assetClassificationForm.assetStatusId" 
                    [data]="statusDropDown"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [valuePrimitive]="true"
                  >
                  </kendo-dropdownlist>
                
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Remark </label>
                    <kendo-textarea  [(ngModel)]=" assetClassificationForm.remarks" class="form-control" [rows]="5" ></kendo-textarea>
                </div>
            </div>
       
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('asset')">Cancel</button>
      <button  *ngIf="this.type=='new'" kendoButton (click)="onReset()" primary="true">
        Reset
      </button>
      <button  *ngIf="this.type=='new'" kendoButton (click)="submitData()" primary="true">
        Save
      </button>
      <button *ngIf="this.type=='update'" kendoButton (click)="submitData()" primary="true">
       Update
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeAssetClassification()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>