


<kendo-tabstrip (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab  title="PPM Planner" [selected]="true">
        <ng-template kendoTabContent >
            <app-ppm-planner [id]="1"></app-ppm-planner>
        </ng-template>
    </kendo-tabstrip-tab>
        <!-- <kendo-tabstrip-tab title="PPM Page">
            <ng-template kendoTabContent>
                <app-ppm-page></app-ppm-page>
            </ng-template>
            </kendo-tabstrip-tab> -->
            <kendo-tabstrip-tab title="PPM for Asset">
                <ng-template kendoTabContent>
                    <app-ppm-asset [id]="1"></app-ppm-asset>
                </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="PPM for Location">
        <ng-template kendoTabContent>
            <app-ppm-on-location [id]="1"></app-ppm-on-location>
        </ng-template>
</kendo-tabstrip-tab>
    <!-- <kendo-tabstrip-tab title="Work Order Reassign">
        <ng-template kendoTabContent>
            <app-work-order-reassign></app-work-order-reassign>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Spare Part Replacemet">
        <ng-template kendoTabContent>
            <app-spare-part-replacement></app-spare-part-replacement>
        </ng-template>
    </kendo-tabstrip-tab> -->
    <!-- <kendo-tabstrip-tab title="Attachments">
        <ng-template kendoTabContent>
            <app-work-order-attactments></app-work-order-attactments>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Completion Info">
        <ng-template kendoTabContent>
            <app-completeion-info></app-completeion-info>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Work Order Inquiry">
        <ng-template kendoTabContent>
            <app-work-order-inquiry></app-work-order-inquiry>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Overall Gridview">
        <ng-template kendoTabContent>
            <app-overall-grid-view></app-overall-grid-view>
        </ng-template>
    </kendo-tabstrip-tab> -->
</kendo-tabstrip>
<div class="row mb-3"></div>


