<div class="card m-3"    >
  <div class="card-header" style="display: flex;justify-content: space-between;">
      <div style="display: flex; justify-content: flex-start;align-items: center;">
          <h5>List of Values</h5>
           <div style="margin-left: 10px;width: 600px;">
              <kendo-dropdownlist 
                      [data]="filterdData" 
                      [filterable]="true"
                      class="form-control"
                      [value]="selectedListValue"
                      textField="name"
                      valueField="id"
                      (selectionChange)="changeListValue($event)"
                      (filterChange)="handleselectedfilterFilter($event)"
                >
              </kendo-dropdownlist>
           </div>
      </div>
      <div>      
        <button kendoButton  primary="true" (click)="openRoleDailog('new')"  *ngIf="permission && permission.isCreate" class="header-right-btn"> 
          <span class="add_btn">+</span> List of Values
        </button>
      </div>
  </div>
<div class="card-body register-form-body">
      <app-table-grid
          [data]="gridData"
          [column]="gridColumn"
          [size]="5"
          [editFlag]="permission && permission.isUpdate" 
          [deleteFlag]="true"
          ></app-table-grid>       
  </div>
</div>


<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="800">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>

            <div *ngFor="let colItem of inputForm">
                  <div *ngIf="colItem.type=='text'" class="form-row" >
                      <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                          <label>{{colItem.name}}</label>
                          <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/>
                      </div>
                  </div>
                  <div *ngIf="colItem.type=='time'" class="form-row" >
                    <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                        <label>{{colItem.name}}</label>
                        <!-- <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/> -->
                        <kendo-timepicker [format]="timeFormate"  [(ngModel)]="colItem.value"  >
                        </kendo-timepicker> 
                    </div>
                </div>
                <div *ngIf="colItem.type=='date'" class="form-row" >
                  <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                      <label>{{colItem.name}}</label>
                      <!-- <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/> -->
                      <kendo-datepicker [format]="format" [(ngModel)]="colItem.value"></kendo-datepicker>
                     
                  </div>
              </div>
                  <div *ngIf="colItem.type=='list'" class="form-row" >
                      <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                          <label>{{colItem.name}}</label>
                          <kendo-dropdownlist 
                              [data]="colItem.data" 
                              class="form-control"
                              [value]="colItem.value"
                              textField="NAME"
                              valueField="ID"
                              (selectionChange)="colItem.value=$event"
                           >
                          </kendo-dropdownlist>
                          <!-- <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/> -->
                      </div>
                  </div>
             
              </div>
            <!-- <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Block Name</label>
                  <input kendoTextBox formControlName="block_name" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
              <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Block Code</label>
                  <input kendoTextBox formControlName="block_code" class="form-control"/>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Short Name</label>
                  <input kendoTextBox formControlName="short_name" class="form-control"/>
              </div>
          </div>
               <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  <label>Status</label>
                  <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                  </kendo-dropdownlist>
              </div>
          </div> -->
       <kendo-dialog-actions [layout]="actionsLayout">
          <button kendoButton (click)="onDialogClose()">Cancel</button>
          <button kendoButton  primary="true" (click)="submitFrom()">
              Save
          </button>
      </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Delete Description
    </div>
  </kendo-dialog-titlebar>
  <p>Are you sure you want to delete?</p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="removeLookup()">Delete</button>
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="removeDialog" (close)="removeDialog=false" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Delete Description
    </div>
  </kendo-dialog-titlebar>
  <p>Are you sure you want to delete?</p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="removeIsActive()">Delete</button>
  </kendo-dialog-actions>
</kendo-dialog>