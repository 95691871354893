import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-pdm-on-location',
  templateUrl: './pdm-location.component.html',
  styleUrls: ['./pdm-location.component.scss']
})
export class PdmLocationComponent implements OnInit {


  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public commictionvalue: Date = new Date(2021, 5, 1, 22);
  public servicestartvalue: Date = new Date(2021, 5, 1, 22);
  public servicestopvalue: Date = new Date(2021, 5, 1, 22);
  public lastworksc: Date = new Date(2021, 5, 1, 22);
  public lastworkusc: Date = new Date(2021, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  public assetManufactureData;
  public assetTypeData;
  public assetModelData;
  public modelObj = {};
  public manufactureObj = {};
  public assetTypeObj = {};
  public onTabSelect(e) {
    console.log(e);
  }

  public column1=[
    {field:"ProductID",title:"Registration No.",isFilter:true},
    {field:"ProductName",title:"Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Country", isFilter:true},
    {field:"UnitPrice",title:"State", isFilter:true}
  ]
  public gridData=sampleProducts;
  public column2=[
    {field:"ProductID",title:"File Type",isFilter:true},
    {field:"ProductName",title:"File Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Attachments", isFilter:true},
    {field:"UnitPrice",title:"Downloads", isFilter:true}
  ]
  public gridData2=sampleProducts;

  asset_registration: FormGroup;
  submitted = false;
  asset_classi = ['Select'];
  contract_ty = ['Select'];
  real_time = ['Select'];
  asset_s = ['Select'];
  running_hour = ['Select'];
  location_block_code = ['Select'];
  location_level_code = ['Select'];
  location_area_code = ['Select'];
  asset_mnt_ppm = ['Select'];
  asset_mnt_routine_i = ['Select'];
  asset_mnt_calibration = ['Select'];
  location_facility_code = ['Select'];
  location_inst_l_code = ['Select'];
  location_current_code = ['Select'];
 
  constructor(private formBuilder: FormBuilder) { 
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
  }
  editColumn(data){}
  
  ngOnInit() {
   
    this.asset_registration = this.formBuilder.group({
      assets_no: ['', Validators.required],
      asset_p_r: ['', Validators.required],
      asset_classi: ['', Validators.required],
      asset_type_d: ['', [Validators.required]],
      asset_t_c: ['', Validators.required],
      modal_no: ['', Validators.required],
      manuf: ['', Validators.required],
      serial_no: ['', Validators.required],
      name_pl_m: ['', [Validators.required]],
      contract_ty: ['', Validators.required],
      engine_no: ['', Validators.required],
      chasis_no: ['', [Validators.required]],

      parent_asset: ['', Validators.required],
      serice_end_date: ['', Validators.required],
      commi_date: ['', [Validators.required]],
      expected_life: ['', Validators.required],
      serv_star: ['', Validators.required],
      real_time: ['', [Validators.required]],
      asset_s: ['', Validators.required],
      opera_s: ['', Validators.required],
      asset_age: ['', [Validators.required]],
      running_hour: ['', Validators.required],
      yr_in_serv: ['', Validators.required],

      location_facility_code: ['', Validators.required],
      location_block_name: ['', Validators.required],
      location_block_code: ['', Validators.required],
      location_level_name: ['', [Validators.required]],
      location_level_code: ['', Validators.required],
      location_area_name: ['', Validators.required],
      location_area_code: ['', [Validators.required]],
      location_inst_l_name: ['', Validators.required],
      location_inst_l_code: ['', Validators.required],
      location_current_name: ['', [Validators.required]],
      location_current_code: ['', Validators.required],
      location_current_name_a: ['', Validators.required],
      location_current_code_a: ['', Validators.required],

      asset_mnt_ppm: ['', Validators.required],
      asset_mnt_routine_i: ['', Validators.required],
      asset_mnt_calibration: ['', [Validators.required]],
      asset_mnt_cumulative: ['', Validators.required],
      asset_mnt_last_work_o_no: ['', Validators.required],
      asset_mnt_last_work_o_date: ['', [Validators.required]],
      asset_mnt_last_un_work_o_no: ['', Validators.required],
      asset_mnt_last_un_work_o_date: ['', Validators.required],
      asset_mnt_total_ydt: ['', [Validators.required]],
      asset_mnt_total_rm: ['', Validators.required],
      asset_mnt_cost_rm: ['', Validators.required],

      asset_trns_mode: ['', Validators.required],
      asset_trns_facility_name: ['', Validators.required],
      asset_trns_date: ['', [Validators.required]],
      asset_trns_remark: ['', Validators.required],
      asset_trns_prev_no: ['', Validators.required],
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.asset_registration.controls; }
  
  onSubmit() {
      this.submitted = true;
  
      // stop here if form is invalid
      if (this.asset_registration.invalid) {
          return;
      }
  
      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.asset_registration.value, null, 4));
  }
  
  onReset() {
      this.submitted = false;
      this.asset_registration.reset();
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Add Contractor & Vendor"
    }
    if(type=="update"){
      this.dailogTitle="Edit Contractor & Vendor"
    }
    this.opened=true;
  }

  
  // bindManufactureObj(result) {
  //   this.manufactureObj = result.reduce((acc, d) => {
  //     acc[d.id] = d.name;
  //     return acc;
  //   }, {})
  // }

  // bindModelObj(result) {
  //   this.modelObj = result.reduce((acc, d) => {
  //     acc[d.id] = d.name;
  //     return acc;
  //   }, {})
  // }


  // bindAssetTypeObj(result) {
  //   this.assetTypeObj = result.reduce((acc, d) => {
  //     acc[d.id] = d;
  //     return acc;
  //   }, {})
  // }

 
}