<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"
>
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.filter}}
>
</kendo-grid-column>
<kendo-grid-command-column title="" width="150px">
  <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>

      <button kendoGridEditCommand    icon="pencil" style="color: lightgray;background: transparent;border:0px;" ></button>
      <button kendoGridRemoveCommand  icon="delete" style="color: #e1e7ef;background: transparent;border: 0px;"></button>
      <button kendoButton   (click)="addAttachment(dataItem)"         icon="file" style="color: lightgray;background: transparent;border: 0px;"></button>

  </ng-template>
</kendo-grid-command-column>
</kendo-grid>