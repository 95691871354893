
    <div class="form-row">
        <div class="form-group col-5">
            <label>Work Order No.</label>
            <input kendoTextBox class="form-control" [value]="workorder && workorder['workOrderNo']" disabled/>
        </div>
        <div class="form-group col-5">
            <label>Start Date/Time</label>
            <kendo-datetimepicker [format]="format"   [(value)]="respondSlaStart" disabled>
            </kendo-datetimepicker>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Chargeable<span class="required">*</span> </label> 
            <div style="width: 100%">
                <kendo-dropdownlist  
                [data]="chargeable.filterData" 
                [value]="chargeable.selectedObj" 
                class="form-control"
                [textField]="'name'" 
                [valueField]="'id'"
                disabled
            >
            </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror> -->
            </div>
        </div>
        <div class="form-group col-5">
            <label>End Date/Time</label>
            <kendo-datetimepicker [format]="format"  [(value)]="workorder.closingDateTime" disabled>
            </kendo-datetimepicker>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Completed By</label>
            <kendo-dropdownlist  
            [data]="user.filterData" 
            [value]="workorder.completedBy" 
            class="form-control"
            [textField]="'staffName'" 
            [valueField]="'id'"
            [valuePrimitive]="true"
            name="followup"
            disabled
        > 
        </kendo-dropdownlist>
           
        </div>
        <div class="form-group col-5">
            <label>Closed By </label>
            <div style="width: 100%">
                <kendo-dropdownlist  
                [data]="user.filterData" 
                [value]="workorder.closedBy" 
                class="form-control"
                [textField]="'staffName'" 
                [valueField]="'id'"
                [valuePrimitive]="true"
                disabled
            >
            </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror> -->
          </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>WO Status</label>
            <div style="width: 100%">
                <kendo-dropdownlist  
                [data]="woStatus.filterData" 
                [(ngModel)]="woStatus.selectedObj" 
                class="form-control"
                [textField]="'NAME'" 
                [valueField]="'ID'"
                 disabled
            >
            </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror> -->
          </div>        </div>
        <div class="form-group col-5">
            <label>Respond SLA Started</label>
            <kendo-datetimepicker [format]="format"  [(value)]="respondSlaStart" disabled>
            </kendo-datetimepicker>
            <!-- <input kendoTextBox class="form-control" [value]="respondSlaStart" disabled/> -->
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Respond SLA Recorded</label>
            <kendo-datetimepicker [format]="format"  [(value)]="respondSlaEnd" disabled>
            </kendo-datetimepicker>
            <!-- <input kendoTextBox class="form-control" [value]="respondSlaEnd" disabled/> -->
        </div>
        <div class="form-group col-5">
            <label>Completion SLA Record Start</label>
            <kendo-datetimepicker [format]="format"  [(value)]="completionSlaStart" disabled>
            </kendo-datetimepicker>
            <!-- <input kendoTextBox class="form-control" [value]="completionSlaStart" disabled/> -->
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-5">
            <label>Completion SLA Record Stop</label>
            <kendo-datetimepicker [format]="format"  [(value)]="completionSlaEnd" disabled>
            </kendo-datetimepicker>
            <!-- <input kendoTextBox class="form-control" [value]="completionSlaEnd" disabled/> <kendo-timepicker  [(value)]="waitingTimeEnd" disabled>
            </kendo-timepicker> -->
        </div>
        <div class="form-group col-5">
            <label>Waiting Time Start</label>
            <kendo-timepicker  [(value)]="waitingTimeStart" disabled>
            </kendo-timepicker>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Waiting Time End</label>
            <kendo-timepicker  [(value)]="waitingTimeEnd" disabled>
            </kendo-timepicker>
        </div>
        <div class="form-group col-5">
            <label>Net Waiting Time</label>
            <input kendoTextBox class="form-control" [(ngModel)]="netwaitingTime" disabled/>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Follow Up Reason</label>
            <kendo-dropdownlist  
            [data]="followupReasonDropDown" 
            [value]="followReasonId" 
            class="form-control"
            [textField]="'NAME'" 
            [valueField]="'ID'"
            [valuePrimitive]="true"
            name="followup"
            disabled
        >
        </kendo-dropdownlist>
        </div>
        <div class="form-group col-5">
           
                <label>Change WO Status</label>
                <div style="width: 100%">
                    <kendo-dropdownlist  
                    [data]="changeWoStatus" 
                    (valueChange) ="changeWoStatusCompletions($event)"
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'NAME'"
                    [(ngModel)]="workorder.ticketStatusObj"
                   
                >
                </kendo-dropdownlist>
                    <!-- <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror> -->
         
        </div>
    </div>

    <!-- <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton   primary="true" class="mr-2 px-2">
              Save
            </button>
            <button kendoButton   primary="true" class="px-2">
              Reset
            </button>
    </div> -->
   
