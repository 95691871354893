import { Component, OnInit } from '@angular/core';
import { convertDateFormate, pading } from 'src/ApiModel/Util';
import HttpClient from 'src/Util/ApiHandling';
import { convertLocalDateString, objectToQueryString } from 'src/Util/Util';

@Component({
  selector: 'app-work-order-spare-parts-return',
  templateUrl: './work-order-spare-parts-return.component.html',
  styleUrls: ['./work-order-spare-parts-return.component.scss']
})
export class WorkOrderSparePartsReturnComponent implements OnInit {

  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }



  public parentId = -1;
  public moduleId = -1;
  public noDataFound: boolean = false;

  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public facility: any;
  public fromDate: Date;
  public toDate: Date;
  public workOrderType: any;

  public workOrderTypeObj = {
    alldata: [],
    filterData: [
      { id: 0, name: "ALL" },
      { id: 1, name: "Routine Maintenance" },
      { id: 2, name: "Corrective Maintenance" },
      { id: 3, name: "Reactive Maintenance" }
    ],
    selectedObj: null,
    objectData: {}
  }

  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility"),
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
          result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }


  fetchReport() {
    console.log("start", this.fromDate);
    console.log("end", this.toDate);

    const from_date = (this.fromDate != undefined) ? convertLocalDateString(this.fromDate) : this.fromDate;
    const to_date = this.toDate != undefined ? convertLocalDateString(this.toDate) : this.toDate;

    var qString = { startDate: from_date, endDate: to_date, workOrderTypeId: this.workOrderType?.id, facilityId: this.facility?.ID }

    let path = `/api/CustomReport/GetWorkOrderSparePartReturns?${objectToQueryString(qString)}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          this.report = this.report.map((item) => {
            item["REQUESTDATE"] = (item["REQUESTDATE"] != null) ? convertDateFormate(item["REQUESTDATE"]) : item["REQUESTDATE"];
            item["APPROVEDDATE"] = (item["APPROVEDDATE"] != null) ? convertDateFormate(item["APPROVEDDATE"]) : item["APPROVEDDATE"];
            return item;
          });
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].MATERIALREQUESTNO != null;
          this.noDataFound = !this.showReport
        })
        .catch((err) => {

          reject(err)
        })
    })
  }
  public column = [
    { field: "MATERIALREQUESTNO", title: "Material RequestNO", width: 200 },
    { field: "WORKORDERNO", title: "WorkOrderNO", width: 200 },
    { field: "REQUESTDATE", title: "Request Date", width: 200 },
    { field: "FACILITY", title: "Facility", width: 200 },
    { field: "FACILITYCODE", title: "Facility Code", width: 200 },
    { field: "APPROVEDDATE", title: "Approved Date", width: 200 },
    { field: "APPROVALSTATUS", title: "Approval Status", width: 200 },
    { field: "APPROVEDBY", title: "Approved By", width: 200 },
    { field: "PARTNO", title: "Part No", width: 200 },
    { field: "PARTDESCRIPTION", title: "Part Description", width: 200 },
    { field: "QUANTITYRETUNED", title: "Quantity Returned", width: 200 },
    { field: "CONTRACTORCOMPANY", title: "ConTractor Company", width: 200 },
    { field: "CONTRACTORNAME", title: "Contractor Name", width: 200 },

  ];
  download() {
    let fileName = 'Work-Orders-Report-for-IBCC.csv';
    let columnNames = [
      "Material RequestNO",
      "WorkOrderNO",
      "Request Date",
      "Facility",
      "Facility Code",
      "ApprovedDate ",
      "Approval Status",
      "Approved By",
      "Part No",
      "Part Description",
      "Quantity Returned",
      "ConTractor Company",
      "Contractor Name"
    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["MATERIALREQUESTNO"],
        c["WORKORDERNO"],
        c["REQUESTDATE"],
        c["FACILITY"],
        c["FACILITYCODE"],
        c["APPROVEDDATE"],
        c["APPROVALSTATUS"],
        c["APPROVEDBY"],
        c["PARTNO"],
        c["PARTDESCRIPTION"],
        c["QUANTITYRETUNED"],
        c["CONTRACTORCOMPANY"],
        c["CONTRACTORNAME"]
      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
