export const LOOKUPTABLE={
    facility:"facility",
    arealocation:"arealocation",
    block:"block",
    blocklevel:"blocklevel",
    levelarea:"levelarea",
    ServicesType:"ServicesType",
    TicketConsequenceType:"TicketConsequenceType",
    ChekList:"CheckList",
    MaintenaceTask:"MaintenanceTask",
    TaskAnswerList:"TaskAnswerList",
    TaskAnswerListItem:"TaskAnswerListItem",
    TaskAnswerType:"TaskAnswerType",
    AssetType:"AssetType",
    MaintenanceFrequency:"MaintenanceFrequency",
    MaintenancePlanner:"MaintenancePlanner",
    MaintenancePlannerDetail:"MaintenancePlannerDetail",
    Status:"status",
    attachment:"attachment",
    ticketstatus:"ticketstatus",
    ticketreassignment:"ticketreassignment",
    taskItem:"TaskItem",
    subService:"SubService",
    contractType:"contractType",
    subService2:"SubService2",
    subService3:"SubService3",
    checklistsubservice:"checklistsubservice",
    ticket:"ticket"
}

export const Schema={
    dbo:"dbo",
    mm:"mm",
    PMC:"PMC"
}

export const ModelApi={
    facility:"facility",
    arealocation:"arealocation",
    block:"block",
    blocklevel:"blocklevel",
    levelarea:"levelarea",
    ServicesType:"ServicesType",
    TicketConsequenceType:"TicketConsequenceType",
    ChekList:"CheckList",
    Asset:"Asset",
    User:"User",
    Contractor:"Contractor",
    WorkerOrder:"WorkOrder",
    MaintenaceTask:"MaintenanceTask",
    TaskAnswerList:"TaskAnswerList",
    TaskAnswerListItem:"TaskAnswerListItem",
    TaskAnswerType:"TaskAnswerType",
    assetClassfication:"assetClassfication",
    AssetType:"AssetType",
    MaintenanceFrequency:"MaintenanceFrequency",
    MaintenancePlanner:"MaintenancePlanner",
    MaintenancePlannerDetail:"MaintenancePlannerDetail",
    Status:"status",
    Year:"Year",
    attachment:"attachment",
    ticketstatus:"ticketstatus",
    ticketreassignment:"ticketreassignment",
    taskItem:"TaskItem",
    subService:"SubService",
    assetStardantion:"assetStardantion",
    assetModel:"assetModel",
    manufacture:"manufacture",
    contractType:"contractType",
    subService2:"SubService2",
    subService3:"SubService3",
    checklistsubservice:"checklistsubservice",
    month:"month"
}

export const PLANNER={
    1:{
        name:"PPM",
        title:"PPM"
    },
    2:{
        name:"PDM",
        title:"PDM"
    },
    3:{
        name:"Daily Inspection",
        title:"Daily Inspection"
    }
}