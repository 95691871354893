import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-monthly-spare-part-register',
  templateUrl: './monthly-spare-part-register.component.html',
  styleUrls: ['./monthly-spare-part-register.component.scss']
})
export class MonthlySparePartRegisterComponent implements OnInit {

  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
  public monthObj ={}
  public permission:Permission=new Permission();
  public year;
  public month;
  public gridData =[]
  public bmpData=[]
  public bpmList=[]
  public bpmObj={}
  public QuantitygridData=[]
  public contractorVendDropDown=[]
  public yearDropDown = [
    { name: 2020, id: 1 },
    { name: 2021, id: 2 },
    { name: 2022, id: 3 },
    { name: 2023, id: 4 },
    { name: 2024, id: 5 },
    { name: 2025, id: 6 },
    { name: 2026, id: 7 },
    { name: 2027, id: 8 },
    { name: 2028, id: 9 },
    { name: 2029, id: 10 },
    { name: 2030, id: 11 },
    { name: 2031, id: 12 },
  ]

  public monthDropDown = [
    { name: "January", id: 1},
    { name: "February", id: 2 },
    { name: "March", id: 3 },
    { name: "April", id: 4 },
    { name: "May", id: 5 },
    { name: "June", id: 6 },
    { name: "July", id: 7 },
    { name: "August", id:8 },
    { name: "September", id: 9 },
    { name: "October", id: 10},
    { name: "November", id: 11},
    { name: "December", id: 12 },
  ]
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"facilityName",title:"Facility Name",isFilter:true,width:150},
    {field:"facilityCode",title:"Facility Code", isFilter:true,width:150},
    {field:"partNo",title:"Part No.", isFilter:true,width:150},
    {field:"partDescription",title:"Part Description", isFilter:true,width:150},
    {field:"uoM",title:"UOM", isFilter:true,width:150},
    {field:"minimumLevel",title:"Minimum Level", isFilter:true,width:150},
    {field:"sparePartType",title:"Spare Part Type", isFilter:true,width:150},
    {field:"binNo",title:"Bin No.", isFilter:true,width:150},
    {field:"currentQuantity",title:"Current Quantity", isFilter:true,width:150},
    {field:"year",title:"Year", isFilter:true,width:150},
    {field:"monthValue",title:"Month", isFilter:true,width:150},
  ];

  public column3=[
    {field:"partNo",title:" Part No.",isFilter:true,width:150},
    {field:"partDescription",title:"Part Description", isFilter:true,width:150},
    {field:"quantity",title:"Quantity", isFilter:true,width:150},
    {field:"erppurchaseCost",title:"ERP Purchase Cost / Pcs (RM)", isFilter:true,width:150},
    {field:"costPerPcs",title:"Cost / Pcs (RM)", isFilter:true,width:150},
    {field:"invoiceNo",title:"Invoice No.", isFilter:true,width:150},
    {field:"vendorName",title:"Vendor Name", isFilter:true,width:150}
  ];
  public areagrid=[
    {
      c_1:"",
      c_2:"",
      c_3:"",
      c_4:"",
      c_5:"",
      c_7:"",
      c_8:"",
      c_9:"",
      c_10:"",
      c_11:"",
      c_12:"",
    },
    {
      c_1:"",
      c_2:"",
      c_3:"",
      c_4:"",
      c_5:"",
      c_7:"",
      c_8:"",
      c_9:"",
      c_10:"",
      c_11:"",
      c_12:"",
    }
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  cont_vendor_r = ['Select'];
  
  constructor(private formBuilder: FormBuilder) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.view_column,(e)=>{this.viewColumn(e)})

   }
   editColumn(data){}
   bindBpmObj(result){
    this.bpmObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }
  getBpm() {
    HttpClient.get("/api/BPM/GetBpms", true, true)
      .then((result: any) => {
        this.contractorVendDropDown = result

        // let data = shiftLastDataInto1st(result)
        // this.bindCountryObj(this.countryDropDown);
        // this.bindStatusObj(this.statusDropDown)
        // this.gridData = data.map((d) => {
        //    d["state"] = this.stateObj[d.stateId];
        //   d["country"] = this.countryObj[d.countryId];
        //   d["status"] = this.statusObj[d.statusId];
        //   d["deleteKey"]= (this.permission.isDelete && d.status.toLowerCase()=="inactive")?true:false;
        //   return d;
        // })
       
      })
  }
   viewColumn(data){
    this.QuantitygridData=data.sparePartUpdates.map((d)=>{
     this.bindBpmObj(this.contractorVendDropDown)
      d["partDescription"]=data.partDescription;
      if(d.bpmid){
       d["vendorName"] = this.bpmObj[d.bpmid]
      }
      
      return d;
    })
    this.opened=true;
  }

  ngOnInit() {
    this.getBpm()
     if(UserModuleObj.spare_parts){
      this.permission=UserModuleObj.spare_parts;
    }
    const d = new Date();
    let month = d.getMonth();
    let  year = d.getFullYear()
    this.month = month +1
    this.year = year
    this.getDatainMonthly(this.year, this.month)
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }
  onDialogClose(){
    this.submitted = false;
    this.maintenence_status.reset();
    this.opened=false;
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Add Monthly Spare Part"
    }
    if(type=="update"){
      this.dailogTitle="Edit Monthly Spare Part"
    }
    this.opened=true;
  }
  
  bindmonthObj(result) {
    this.monthObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  getDatainMonthly(year,month){
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/Assets/GetMonthlySparePartRegister?year=${year}&&month=${month}`, true)
        .then((result: any) => {
          this.bindmonthObj(this.monthDropDown)
          this.gridData = result.map((e,index)=>{
            e["soNo"] = index+1
            e["monthValue"] = this.monthObj[e.month]
            e["currentQuantity"] = Math.abs(e.currentQuantity)
            return e;
          });
          console.log("monthy",this.gridData)
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  yearChanged(year){
    this.year =year;
    
  }
  monthChanged(month){
    this.month = month
   }

  download() {
        let fileName = 'monthlysparepart.csv';

        let columnNames = [
        "soNo",
        "binNo",
        "currentQuantity", 
        "facilityCode",
        "facilityName",
        "minimumLevel", 
        "month",
        "monthValue", 
        "partDescription", 
        "partNo", 
        "sparePartType",
        "uoM", 
        "year",];
        let header = columnNames.join(',');
        let csv = header;
        csv += '\r\n';
    
        this.gridData.map(c => {
          csv += [
          c["soNo"],
          c["binNo"],
          c["currentQuantity"], 
          c["facilityCode"],
          c["facilityName"],
          c["minimumLevel"], 
          c["month"],
          c["monthValue"], 
          c["partDescription"], 
          c["partNo"], 
          c["sparePartType"],
          c["uoM"], 
          c["year"],].join(',');
          csv += '\r\n';
        })
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      fetchData(){
        this.getDatainMonthly(this.year,this.month)
      }
      // getBpm() {
      //   HttpClient.get("/api/BPM/GetBpms", true, true)
      //     .then((result: any) => {
      //       this.bpmObj=result.reduce((acc,d)=>{
      //         acc[d.id]=d;
      //         return acc;
      //       },{})

      //       // let data = shiftLastDataInto1st(result)
      //       // this.bindCountryObj(this.countryDropDown);
      //       // this.bindStatusObj(this.statusDropDown)
      //       // this.gridData = data.map((d) => {
      //       //    d["state"] = this.stateObj[d.stateId];
      //       //   d["country"] = this.countryObj[d.countryId];
      //       //   d["status"] = this.statusObj[d.statusId];
      //       //   d["deleteKey"]= (this.permission.isDelete && d.status.toLowerCase()=="inactive")?true:false;
      //       //   return d;
      //       // })
           
      //     })
      // }
}
