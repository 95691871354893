import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EditorResizableOptions } from "@progress/kendo-angular-editor";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import HttpClient from "src/Util/ApiHandling";
import { NotificationService } from "@progress/kendo-angular-notification";

@Component({
  selector: 'app-company-working-hour',
  templateUrl: './company-working-hour.component.html',
  styleUrls: ['./company-working-hour.component.scss']
})
export class CompanyWorkingHourComponent implements OnInit {

  public actionsLayout = "normal";
  public SelectPlatformData = [{"name":"User email address","id":"1"},{"name":"Mobile application","id":"2"},];
  public UsertypeData =  [{"name":"Company User","id":"1"},{"name":"Facility User","id":"2"},{"name":"External User","id":"3"},{"name":"Super Admin","id":"4"}];
  public BlockForm: FormGroup;
  public opened=false;
 
  public permission:Permission=new Permission();
  constructor(private _notif: NotificationService) {}
  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 150,
    maxWidth: 600,
    maxHeight: 450,
  };
  public min: Date = new Date(2000, 2, 10, 6,0, 0);
  public max: Date = new Date(2000, 2, 10, 19,0, 0);
  ngOnInit(): void {
    if(UserModuleObj.level)
    this.permission=UserModuleObj.level;
    this.BlockForm = new FormGroup({
      id:new FormControl(0),
      WorkingHourStart: new FormControl(),
      WorkingHourEnd: new FormControl(),
    });
    this.getdata();
  }
getdata()
{
  HttpClient.get(
    `/api/MasterData/GetSystemSettingWorkingHour`,
    true,
    true
  ).then((result: any) => {
    this.BlockForm.get("id").setValue(result.id);
    let d1=new Date();
    if(result.workingHourStart){
    let tArray=result.workingHourStart.split(":").map((t)=>parseInt(t));
    d1.setHours(tArray[0])
    d1.setMinutes(tArray[1]);
    }
    let d2=new Date();
    if(result.workingHourEnd){
    let tArray=result.workingHourEnd.split(":").map((t)=>parseInt(t));
    d2.setHours(tArray[0])
    d2.setMinutes(tArray[1]);
    }
    
    this.BlockForm.get("WorkingHourStart").setValue(d1);
    this.BlockForm.get("WorkingHourEnd").setValue(d2);
  });
}
  onSubmit()
  {
    let d=new Date(this.BlockForm.get("WorkingHourStart").value);
    let d1=new Date(this.BlockForm.get("WorkingHourEnd").value);
    let paramsObj={
      "id": this.BlockForm.get("id").value,
      "workingHourStart":d.getHours()+":"+d.getMinutes(),
      "workingHourEnd": d1.getHours()+":"+d1.getMinutes(),
      "createdOn":new Date(),
      }
    HttpClient.fetchData(
      "/api/MasterData/AddSystemSettingWorkingHour",
      "post",
      paramsObj,
      true,
      true
    ).then((result: any) => {
      if (result.isValid == true) {
        HttpClient.insertAduitLog("System Settings","Save Working Hour","Save Working Hour in System Settings").then((res:any)=>{
          if (res.isValid == true) {

          }
        })

        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
      } else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
    });
  }
  onReset()
  {

  }
  onDialogClose(type) {
    if ((type = "new")) {
      this.opened = false;
    }
    if ((type = "remove")) {
    }
  }
  onDialogOpen(type) {
    if ((type = "new")) {
      this.opened = true;
    }
    if ((type = "remove")) {
    }
  }

}
