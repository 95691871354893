import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class AssetStandardization{ 
    id:number;
    assetTypeId:number;
    assetModelId:number;
    assetManufacturerId:number;
    createdDate:string;
    modifiedDate:string;
    assetStatusId:number
  
}

export class AssetStandardizationObj{
    alldata:Array<AssetStandardization>=[]
    filterData:Array<AssetStandardization>=[]
    selectedObj:AssetStandardization=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AssetStandardization)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/Assets/GetAssetStandardizations",true,true)
            .then((result:Array<AssetStandardization>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }
}