import { calender,CalenderError } from "src/app/models/calender.model";
export let CalenderValidation = (data: calender, type) => {
    let error = new CalenderError(false)
    if (type === "init")
        return error;
    if (!data. name) {
        error.name = true;
    }
    if(data.holidayCalendars.length<=0){
        error.holidayCalendars=true;
    }
    if(!data.year){
        error.year=true;
    }
    return error;
}