export class UserShiftDetails {
    ID:number;
    EMPLOYEEGLOBALID:number;
    CARDNUMBER:null;
    SHIFTID:number;
    DATETIMEFROM:Date;
    DATETIMETO:Date;
    INSTANCENAME:string;
    CREATEDON:Date;
    BREAKHOURS:string;
    ISALLOWREPLACEMENT:null
}

export class LeaveDetils{
    ID:number;
    USERID:number;
    LEAVEFROM:Date;
    LEAVETO:Date;
    CREATEDON:Date
}


export class UserShiftTypeError{
    EMPLOYEEGLOBALID:Boolean;
    SHIFTID:Boolean;
    DATETIMEFROM:Boolean;
    DATETIMETO:Boolean;
    constructor(status){
      this.EMPLOYEEGLOBALID = status;
      this.SHIFTID= status
      this. DATETIMETO = status
      this. DATETIMEFROM = status
    }
}

export class LeaveTypeError{
    USERID:Boolean;
   constructor(status){
      this. USERID= status;
     
    }
}
