

<div class="card m-3">
    <div class="card-header" >
        <h5>SLA Mapping For Check-List</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add SLA 
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid 
        [data]="gridData"
        [column]="column"
        [size]="5"
        [action]="true"
        [editFlag] ="false"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
         Add SLA
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                          <div class="form-group col-5">
                            <label>Service</label>
                            <kendo-dropdownlist [data]="serviceDropDwon"  class="form-control"
                [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                [(ngModel)]="subServiceObj. SERVICETYPEID"
                >
                </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Description</label>
                            <input kendoTextBox  [(ngModel)]="subServiceObj.  DESCRIPTION"  class="form-control"/>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-5">
                          <label>Respond SLA During Working Hour</label>
                          <input kendoTextBox type="number" [(ngModel)]="subServiceObj. RESPONDSLADURINGWORKINGHOURS"  class="form-control"/>
                      </div>
                      <div class="form-group col-5">
                          <label>Respond SLA After Working Hour</label>
                          <input kendoTextBox type="number"  [(ngModel)]="subServiceObj.RESPONDSLAAFTERWORKINGHOURS"   class="form-control"/>
                      </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                          <label>Completion SLA During Working Hour</label>
                          <input kendoTextBox type="number"  [(ngModel)]="subServiceObj.COMPLETIONSLADURINGWORKINGHOURS"  class="form-control"/>
                      </div>
                      <div class="form-group col-5">
                          <label>Completion SLA After Working Hour</label>
                          <input kendoTextBox type="number"  [(ngModel)]="subServiceObj.  COMPLETIONSLAAFTERWORKINGHOURS"  class="form-control"/>
                      </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                          <label>Close SLA</label>
                          <input kendoTextBox type="number"  [(ngModel)]="subServiceObj. CLOSESLA"   class="form-control"/>
                      </div>
                      <div class="form-group col-5">
                         
                      </div>
                    </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                          <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveCheckData()">
                              Save
                          </button>
                          <button kendoButton   primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                      </div>
                  
                    </div>
</kendo-dialog>