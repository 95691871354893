import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class TaskAnswerType{ 
    ID:number;
    DESCRIPTION:string;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number;
   async  saveData(description){
        this.ID=0;
        this.DESCRIPTION=description;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerType,this,"new")
    }	
   async updateData(id,description){
        this.ID=id;
        this.DESCRIPTION=description; 
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerType,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerType,obj);
    }
}

export class TaskAnswerTypeObj{
    alldata:Array<TaskAnswerType>=[]
    filterData:Array<TaskAnswerType>=[]
    selectedObj:TaskAnswerType=null;
    objectData={}
    objectByDes={};
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:TaskAnswerType)=>{
                acc[d.ID]=d;
                this.objectByDes[d.DESCRIPTION]=d.ID;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.TaskAnswerType)
            .then((result:Array<TaskAnswerType>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}