import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class Status{
    ID: number
    NAME:string
    INCLUDESTATUS:number;
    ORDERID:number;
    ISACTIVE:boolean;
    CREATEDON: Date
}
export class StatusObj{
    alldata:Array<Status>=[]
    filterData:Array<Status>=[]
    selectedObj:Status=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Status)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.Status)
            .then((result:Array<Status>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
             
                reject(err)
            })
        })

    }
}