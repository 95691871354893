import { Component, OnInit } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import { UserRegisterType } from "src/app/models/user.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { userRegisterValidation } from "src/validator/user";
import { environment } from 'src/environments/environment';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {addBlukUser} from "src/ApiModel/ImportApi/UserReg"
@Component({
  selector: "app-ragister",
  templateUrl: "./ragister.component.html",
  styleUrls: ["./ragister.component.scss"],
})
export class RagisterComponent {
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }
  public opened = false;
  public importopened = false;
  public type = "";
  public contrDisabled = true;
  public companyDisabled = false;
  public actionsLayout = "normal";
  public companyErr = false;
  public dailogTitle = "";
  public validate = true;
  public emailError=true;
  public companyEmailError = true;
  public removeDialogOpen = false;
  public statusObj = {};
  public locObj ={};
  public genderObj = {}
  public speciality =[]
  public serviceTypeErr = false
  public userRegisterForm: UserRegisterType = new UserRegisterType();
  error = userRegisterValidation(this.userRegisterForm, "init");
  submitted = false;
  titles = ["Mr", "Mrs", "Miss", "Ms"];
  public gendersDropDown = [];
  public companyDropDown =[]
  public loginUserTypeDropDown = [];
  public statusDropDown = [];
  public comErr = false;
  public facErr = false;
  public contractorObj;
  public userRoleErr = false;
  public designationDropDown = [];
  public nationalityDropDown = [];
  public locationCodeDropDown = [];
  public facilityCodeDropDown = [];
  public userTypeName
  public specialityDropDown =[];
  public contractorDropDown = [];
  public serviceDropDown = [];
  public compentecnyDropDown = [];
  public roleDropDown = [];
  public urlimg
  public exportGridData =[]
  public serviceId;
  public selectedFacilityFlag = [];
  public selectedUserRoleFlag = [];
  public selectedCompentenciesFlag = [];
  public selectedSpecialityFlag =[];
  public selectedServiceFlag = [];
  public facility_obj = {};
  public compent_Obj = {};
  public speciality_Obj = {};
  public service_Obj = {};
  public userRole_Obj = {};
  public designationObj = {};
  public loginUserObj = {};
  public nationalityObj = {};
  public gradeDropDown=[];
  public permission:Permission=new Permission();
  public listItemsc: Array<string> = [
    "Management",
    "Electrical",
    "Housekeeping",
    "Building System",
  ];
  public listItemss = [ 
    {id:1,name:"Specialization"}
  , {id:2,name:"Non-Specialization"}];

  public column2 = [
    { field: "staffName", title: "Staff Name", isFilter: true },
    { field: "userName", title: "Username", isFilter: true },
    { field: "loginUserType", title: "Login User Type", isFilter: true },
    { field: "designation", title: "Designation", isFilter: true },
    { field: "companyEmail", title: "Email", isFilter: true },
    { field: "status", title: "Status", isFilter: true },
  ];
  public gridData = [];
  public attechForm: {
    URL: string;
  };

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  public value: Date = new Date(2000, 2, 10);

  ngOnInit() {
    if(UserModuleObj.user_screen_mapping){
      this.permission=UserModuleObj.user_screen_mapping;
    }
    Promise.all([
      this.getGenderData("dbo", "gender"),
      this.getloginUserTypeData("dbo", "category"),
      this.getStatus("dbo", "status"),
      this.getNationalityData("dbo", "country"),
      this.getDesignationData("dbo", "Designation"),
      this.getServiceData("dbo", "servicesType"),
      this.getCompentecnyData("dbo", "skill"),
      this.getBlockData("dbo", "block"),
      this.getFacilityData("dbo", "facility"),
      this.getSpeciality("dbo","speciality"),
      this.getCompany("dbo","company"),
      this.getGrade("dbo","grade"),
      this.getContractorData(),
      this.getRoles(),
      
    ]).then((e) => {
      this.getAllUser()
    }).catch((error)=>{
     
    })
  }

  onReset() {
  this.openRoleDailog("new")
  }

  onDialogClose(type) {
    if ((type = "new")) {
      this.userRegisterForm = new UserRegisterType();
      this.userRegisterForm.genderId = null;
      this.selectedCompentenciesFlag = [];
      this.selectedSpecialityFlag = []
      this.selectedServiceFlag =[]
      this.speciality =[]
      this.comErr = false;
      this.facErr = false;
      this.userRoleErr = false
      this.selectedFacilityFlag = [];
      this.selectedUserRoleFlag = [];
      this.emailError =true;
      this.companyEmailError=true;
      this.serviceId = null
      this.error = userRegisterValidation(this.userRegisterForm, "init");
      this.userRegisterForm.id = 0;
      this.userRegisterForm.statusId = 1;
      this.opened = false;
    }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
    if(type="importUpdate"){
      this.importopened = false;
    }
  }
  openRoleDailog(type) {
    
    this.type = type;
    if (type == "new") {
      this.userRegisterForm = new UserRegisterType();
      this.userRegisterForm.genderId = null;
      this.selectedCompentenciesFlag = [];
      this.selectedSpecialityFlag = []
      this.selectedServiceFlag =[]
      this.speciality =[]
      this.comErr = false;
      this.companyErr = false;
      this.serviceTypeErr = false;
      this.facErr = false;
      this.userRoleErr = false
      this.selectedFacilityFlag = [];
      this.selectedUserRoleFlag = [];
      this.emailError =true;
      this.companyEmailError=true;
      this.serviceId = null
      this.error = userRegisterValidation(this.userRegisterForm, "init");
      this.userRegisterForm.id = 0;
      this.userRegisterForm.statusId = 1;
      this.dailogTitle = "Add User";
    }
    if (type == "update") {
      this.dailogTitle = "Edit User ";
    }
    this.opened = true;
  }

  editColumn(data) {
    this.getDataById(data);
    if (data.action === "edit") {
      HttpClient.get(
        "/api/Users/GetUserById?id=" + data.dataItem.id,
        true,
        true
      ).then((result: any) => {
        const data = result;
        this.userRegisterForm = data;
        this.userRegisterForm.categoryId = data["categoryId"];
        let userValue = this.loginUserTypeDropDown.filter((e)=>{
          return e.ID==this.userRegisterForm.categoryId
        })
      let userType = userValue[0].NAME
         if( userType==="Company User"){
           this.contrDisabled = true;
           this.companyDisabled = false;
           this.userRegisterForm.companyName = data["companyName"]
           
        }
         else{
          this.contrDisabled = false;
          this.companyDisabled = true;
          this.userRegisterForm.companyName =data["companyName"]
        }
        // if( this.userRegisterForm.categoryId===3){
        //   this.contrDisabled = true;
        //   this.companyDisabled = true;
        //   this.userRegisterForm.companyName ="null"
        // }
        // else{
        //   this.contrDisabled = false;
        //   this.companyDisabled = false;
        //   this.userRegisterForm.companyName = data["companyName"]
        // }
       this.userRegisterForm.services = data["services"];
        let id = this.userRegisterForm.services && this.userRegisterForm.services.map((e) => {
          return e.serviceTypeId;
        });
        if(id){
          this.serviceId = id[0];
        }
      
        this.userRegisterForm.competencies = data["competencies"];
        this.userRegisterForm.facilities = data["facilities"];
        
        this.selectedCompentenciesFlag = this.userRegisterForm.competencies && this.userRegisterForm.competencies.map(
          (d) => {
            let skillId = d.skillId;
            return {
              id: skillId,
              name: this.compent_Obj[skillId],
            };
          }
        );
  
        this.selectedFacilityFlag = this.userRegisterForm.facilities && this.userRegisterForm.facilities.map(
          (d) => {
            let facilityId = d.facilityId;
            return {
              id: facilityId,
              name: this.facility_obj[facilityId],
            };
          }
        );
           
        this.selectedSpecialityFlag = this.userRegisterForm.employeeSpecialities && this.userRegisterForm.employeeSpecialities.map(
          (d) => {
            let specialityId = d.specialityId;
            return {
              id: specialityId,
              name: this.speciality_Obj[ specialityId],
            };
          }
        );
       
        this.selectedServiceFlag =  this.userRegisterForm.services && this.userRegisterForm.services.map(
          (d) => {
            let serviceTypeId = d.serviceTypeId;
            return {
              id: serviceTypeId,
              name: this.service_Obj[ serviceTypeId],
            };
          }
        );
       

        this.selectedUserRoleFlag = this.userRegisterForm.userRoles && this.userRegisterForm.userRoles.map((d) => {
          let roleId = d.roleId;
          return {
            id: roleId,
            name: this.userRole_Obj[roleId],
          };
        });
      });
      this.opened = true;
      this.openRoleDailog("update");
  }
      if (data.action == "remove") {
        HttpClient.get(
          "/api/Users/GetUserById?id=" + data.dataItem.id,
          true,
          true
        ).then((result: any) => {
          const data = result;
          this.userRegisterForm = data;
          this.userRegisterForm.services = data["services"];
          this.userRegisterForm.isDeleted = true;
          let id = this.userRegisterForm.services.map((e) => {
            return e.serviceTypeId;
          });
          if(id.length>0){
            this.serviceId = id[0];
          }
        
          this.userRegisterForm.competencies = data["competencies"];
          this.userRegisterForm.facilities = data["facilities"];
          
          this.selectedCompentenciesFlag = this.userRegisterForm.competencies.map(
            (d) => {
              let skillId = d.skillId;
              return {
                id: skillId,
                name: this.compent_Obj[skillId],
              };
            }
          );
    
          this.selectedFacilityFlag = this.userRegisterForm.facilities.map(
            (d) => {
              let facilityId = d.facilityId;
              return {
                id: facilityId,
                name: this.facility_obj[facilityId],
              };
            }
          );
          this.selectedUserRoleFlag = this.userRegisterForm.userRoles.map((d) => {
            let roleId = d.roleId;
            return {
              id: roleId,
              name: this.userRole_Obj[roleId],
            };
          });
        });
      this.removeDialogOpen = true;
     
    }
  }

  getGenderData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.gendersDropDown = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getloginUserTypeData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.loginUserTypeDropDown = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getDesignationData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.designationDropDown = JSON.parse(result.gridData);
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

 getContractorData() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/BPM/GetBpms", true, true)
        .then((result: any) => {
          this.contractorDropDown = result.map((e) => {
            return { id: e.id, name: e.name };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getNationalityData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.nationalityDropDown = JSON.parse(result.gridData);
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getServiceData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let servicedata = [];
          servicedata = JSON.parse(result.gridData);
          this.serviceDropDown = servicedata.map((e) => {
            this.service_Obj[e.ID] = e.NAME;
            return { id: e.ID, name: e.NAME };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCompentecnyData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let compData = [];
          compData = JSON.parse(result.gridData);
          this.compentecnyDropDown = compData.map((e) => {
            this.compent_Obj[e.ID] = e.NAME;
            return { id: e.ID, name: e.NAME };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSpeciality(schema, tablename){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
      .then((result: any) => {
        let specialityData = [];
        specialityData = JSON.parse(result.gridData);
        this.specialityDropDown = specialityData.map((e) => {
          this.speciality_Obj[e.ID] = e.NAME;
          return { id: e.ID, name: e.NAME };
        });
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
  }
  getGrade(schema, tablename){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
      .then((result: any) => {
        let gradeDown = [];
        gradeDown = JSON.parse(result.gridData);
        gradeDown=gradeDown.filter(x=>x.ISACTIVE == true)
        this.gradeDropDown =  gradeDown.map((e) => {
        // this.company_Obj[e.ID] = e.NAME;
        return { id: e.ID, name: e.NAME };
        });

        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
  }
  getCompany(schema, tablename){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
      .then((result: any) => {
        let companyData = [];
        companyData = JSON.parse(result.gridData);
        this.companyDropDown =  companyData.map((e) => {
          // this.company_Obj[e.ID] = e.NAME;
          return { id: e.ID, name: e.NAME };
        });

        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
  }

  getBlockData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let BlockData = [];
          BlockData = JSON.parse(result.gridData);
          this.locationCodeDropDown = BlockData.map((e) => {
            return { id: e.ID, name: e.NAME };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getFacilityData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData);
          this.facilityCodeDropDown = facilityData.map((e) => {
            this.facility_obj[e.ID] = e.NAME;
            return { id: e.ID, name: e.NAME };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/Api/MasterData/GetRoles", true, true)
        .then((result: any) => {
          this.roleDropDown = result.map((e) => {
            this.userRole_Obj[e.id] = e.name;
            return { id: e.id, name: e.name };
          });
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  onSubmit() {
    if(this.userTypeName==="Company User"){
      this.companyErr = true;
    }
     if(this.userRegisterForm.companyName){
      this.companyErr = false
    }
    if( this.userTypeName === "Facility User"){
       if(this.selectedServiceFlag.length==0 ||this.selectedServiceFlag ==null ||this.selectedServiceFlag==undefined){
         this.serviceTypeErr = true;
       }
       else{
        this.serviceTypeErr = false;
       }
  }
    if( this.selectedCompentenciesFlag===null || this.selectedCompentenciesFlag.length==0){
                    this.comErr = true;
    }
    else if( this.selectedCompentenciesFlag && this.selectedCompentenciesFlag.length>0){
      this.comErr = false;
    }
   if( this.selectedFacilityFlag===null || this.selectedFacilityFlag.length==0){
      this.facErr = true;
    }
    else{
      this.facErr = false;
    }
    if( this.selectedUserRoleFlag===null || this.selectedUserRoleFlag.length==0){
      this.userRoleErr = true;
    }
    else{
      this.userRoleErr =false;
    }
    
    this.error = userRegisterValidation(this.userRegisterForm, "");
    this.userRegisterForm.id =
      this.type == "new" ? 0 : this.userRegisterForm.id;

    if (this.selectedFacilityFlag && this.selectedFacilityFlag.length) {
      let mainFlagObj = {};
      if (this.userRegisterForm.facilities) {
        mainFlagObj = this.userRegisterForm.facilities.reduce((acc, d) => {
          acc[d.facilityId] = d;
          return acc;
        }, {});
      }
      this.userRegisterForm.facilities = this.selectedFacilityFlag && this.selectedFacilityFlag.map((d) => {
        if (mainFlagObj[d.id]) {
          return mainFlagObj[d.id];
        } else {
          return {
            id: 0,
            employeeGlobalId: this.userRegisterForm.id,
            facilityId: d.id,
          };
        }
      });
    }
  
    if (this.selectedUserRoleFlag && this.selectedUserRoleFlag.length) {
      let mainFlagObj = {};
      if (this.userRegisterForm.userRoles) {
        mainFlagObj = this.userRegisterForm.userRoles.reduce((acc, d) => {
          acc[d.roleId] = d;
          return acc;
        }, {});
      }
      this.userRegisterForm.userRoles =  this.selectedUserRoleFlag && this.selectedUserRoleFlag.map((d) => {
        if (mainFlagObj[d.id]) {
          return mainFlagObj[d.id];
        } else {
          return {
            loggedInUserId: parseInt(getCookie(userid)),
            id: 0,
            roleId: d.id,
            rolesModulePermissions: [
              {
                loggedInUserId: parseInt(getCookie(userid)),
                id: 0,
                roleId: d.id,
                permissionValue: 0,
              },
            ],
          };
        }
      });
    }

    if ( this.selectedCompentenciesFlag && this.selectedCompentenciesFlag.length) {
      let mainFlagObj = {};
      if (this.userRegisterForm.competencies) {
        mainFlagObj = this.userRegisterForm.competencies.reduce((acc, d) => {
          acc[d.skillId] = d;
          return acc;
        }, {});
      }

      this.userRegisterForm.competencies = this.selectedCompentenciesFlag && this.selectedCompentenciesFlag.map(
        (d) => {
          if (mainFlagObj[d.id]) {
            return mainFlagObj[d.id];
          } else {
            return {
              loggedInUserId: parseInt(getCookie(userid)),
              id: 0,
              employeeGlobalId:parseInt(getCookie(userid)),
              skillId:  d.id,
              createdOn: new Date(),
           };
          }
        }
      );
    }

    if(this.selectedSpecialityFlag==null || this.selectedSpecialityFlag==undefined || this.selectedSpecialityFlag.length==0){
      this.userRegisterForm.employeeSpecialities =[]
    }

    else if (this.selectedSpecialityFlag && this.selectedSpecialityFlag.length>0) {
      let mainFlagObj = {};
      if (this.userRegisterForm.employeeSpecialities) {
        mainFlagObj = this.userRegisterForm.employeeSpecialities.reduce((acc, d) => {
          acc[d.specialityId] = d;
          return acc;
        }, {});
      }

      this.userRegisterForm.employeeSpecialities = this.selectedSpecialityFlag && this.selectedSpecialityFlag.map(
        (d) => {
          if (mainFlagObj[d.id]) {
            return mainFlagObj[d.id];
          } else {
            return {
              id:0,
              employeeGlobalId: getCookie(userid),
              specialityId:d.id,
              createdBy: getCookie(userid),
              createdDate: new Date(),
              modifiedBy:getCookie(userid),
              modifiedDate: new Date()
           };
          }
        }
      );
    }
      if(this.selectedServiceFlag && this.selectedServiceFlag.length==0 ||this.selectedServiceFlag==null || this.selectedServiceFlag==undefined){
        this.userRegisterForm.services =[]
      }

  else  if (this.selectedServiceFlag && this.selectedServiceFlag.length) {
      let mainFlagObj = {};
      if (this.userRegisterForm.services) {
        mainFlagObj = this.userRegisterForm.services.reduce((acc, d) => {
          acc[d.serviceTypeId] = d;
          return acc;
        }, {});
      }

      this.userRegisterForm.services = this.selectedServiceFlag && this.selectedServiceFlag.map(
        (d) => {
          if (mainFlagObj[d.id]) {
            return mainFlagObj[d.id];
          } else {
            return {
              id:0,
              loggedInUserId:getCookie(userid),
              serviceTypeId:d.id
           };
          }
        }
      );
    }


    if (
      !this.error.userName &&
      !this.error.locationID &&
      !this.error.categoryId &&
      !this.error.companyEmail &&
      !this.companyErr &&
      !this.error.mobileNo &&
      !this.error.staffName&&
      !this.userRoleErr&&
      !this.facErr &&
      !this.serviceTypeErr &&
      !this.comErr 
     ) {
      if (this.type == "new") {
        this.userRegisterForm.id = 0;
        this.userRegisterForm.loggedInUserId = parseInt(getCookie(userid));
        this.userRegisterForm.isDeleted = false;
        this.userRegisterForm.password = "xuzabscd";
      }
        this.addUserData();
      // this.addLocationData(JSON.stringify(this.LocationForm));
    }
  }


  addUserData() {
    if (this.type == "new") {
     const params = {
        ...this.userRegisterForm
      };
    HttpClient.fetchData("/api/Users/RegisterUser", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Users","Add Users","Add Users in Users").then((res:any)=>{
            if (res.isValid == true) {
    
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          params.id=result.id;
          this.unLockData(params);
          this. getAllUser();
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
          this.opened = false;
         })
    }
    if (this.type == "update") {
      const params = {
        ...this.userRegisterForm
      };

      HttpClient.fetchData("/api/Users/UpdateUser", "post", params, true, true).then((result: any) => {
        HttpClient.insertAduitLog("Users","Update Users","Update Users in Users").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
          this._notif.show({
            content: result.message,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this. getAllUser();
          this.opened = false;
        
        // else{
        //   this._notif.show({
        //     content: result.messageKey,
        //     type: { style: "error", icon: true },
        //   });
        // }
      })
    }
  }


  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindDesignationObj(result) {
    this.designationObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindLoginTypeObj(result) {
    this.loginUserObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindLocationTypeObj(result) {
    this.locObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindNationalityObj(result){
      this.nationalityObj =result.reduce((acc,d)=>{
        acc[d.ID] = d.NATIONALITY;
        return acc;
      }, {});
  }

  bindContractorObj(result){
    this.contractorObj =result.reduce((acc,d)=>{
      acc[d.id] = d.name;
      return acc;
    }, {});
}

  bindGenderObj(result){
    this.genderObj =result.reduce((acc,d)=>{
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
}
  getAllUser() {
    HttpClient.get("/api/Users/GetAllUser", true, true)
      .then((result: any) => {
      this.gridData = shiftLastDataInto1st(result);
      this.exportGridData = this.gridData
        this.bindStatusObj(this.statusDropDown);
        this.bindDesignationObj(this.designationDropDown);
        this.bindLoginTypeObj(this.loginUserTypeDropDown);
        this.bindLocationTypeObj(this.locationCodeDropDown);
        this.bindNationalityObj(this.nationalityDropDown);
        this.bindGenderObj(this.gendersDropDown);
        this.bindContractorObj(this.contractorDropDown);
        this.exportGridData.map((d,index) => {
          d["SNo"] = index+1;
             d["status"] = this.statusObj[d.statusId];
               d["designation"] = this.designationObj[d.designationId];
                d["loginUserType"] = this.loginUserObj[d.categoryId];
           d["location"]= this.locObj[d.locationID];
              d["gender"]= this.genderObj[d.genderId];
           d["nationality"] = this.nationalityObj[d.nationalityId];
              d["contractor"] = this.contractorObj[d.contractorId];
              d["serviceName"] =d.services && d.services.map((m)=>{
                           return this.service_Obj[m.serviceTypeId];
                       }).join("/") ;
             d["speciality"] =d.employeeSpecialities && d.employeeSpecialities.map((m)=>{
           return this.speciality_Obj[m.specialityId];
       }).join("/") ;
         d["compentencyName"] =d.competencies && d.competencies.map((m)=>{
           return this.compent_Obj[m.skillId]
       }).join("/");
       d["userRoleName"] =d.userRoles && d.userRoles.map((m)=>{
           return this.userRole_Obj[m.roleId];
     }).join("/") ; 
         d["facilitiesName"] =d.facilities && d.facilities.map((m)=>{
       return this.facility_obj[m.facilityId];
 }).join("/") ; 
 d["EmployeeSpecialities"] =d.employeeSpecialities && d.employeeSpecialities.map((m)=>{
  return this.speciality_Obj[m.specialityId];
}).join("/") ; 
           return d;
         })
        this.gridData.map((d,index) => {
         d["SNo"] = index+1;
            d["status"] = this.statusObj[d.statusId];
              d["designation"] = this.designationObj[d.designationId];
               d["loginUserType"] = this.loginUserObj[d.categoryId];
          d["location"]= this.locObj[d.locationID];
             d["gender"]= this.genderObj[d.genderId];
          d["nationality"] = this.nationalityObj[d.nationalityId];
             d["contractor"] = this.contractorObj[d.contractorId];
             d["serviceName"] =d.services && d.services.map((m)=>{
                          return this.service_Obj[m.serviceTypeId];
                      }).join("/") ;
            d["speciality"] =d.employeeSpecialities && d.employeeSpecialities.map((m)=>{
          return this.speciality_Obj[m.specialityId];
      }).join("/") ;
        d["compentencyName"] =d.competencies && d.competencies.map((m)=>{
          return this.compent_Obj[m.skillId]
      }).join("/");
      d["userRoleName"] =d.userRoles && d.userRoles.map((m)=>{
          return this.userRole_Obj[m.roleId];
    }).join("/") ; 
        d["facilitiesName"] =d.facilities && d.facilities.map((m)=>{
      return this.facility_obj[m.facilityId];
}).join("/") ; 
   d["deleteKey"]= this.permission.isDelete && d.status.toLowerCase()=="inactive"?true:false;
          return d;
        })
    
      })
      .catch((err) => {});
     
  }

  validateEmail()
  {
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.emailError=re.test(String(this.userRegisterForm.userName).toLowerCase());

  }

  validateCompanyEmail(){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.companyEmailError=re.test(String(this.userRegisterForm.companyEmail).toLowerCase());
  }


  removeUser(){
   const params={
    ...this.userRegisterForm,
   
    }
    
  }


  getDataById(data){
   
  }


  removeData(){
    const params = {
      ...this.userRegisterForm,
    };

    HttpClient.fetchData("/api/Users/UpdateUser", "post", params, true, true).then((result: any) => {
      HttpClient.insertAduitLog("Users","Delete Users","Delete Users in Users").then((res:any)=>{
        if (res.isValid == true) {

        }
      })
        this._notif.show({
          content: result.message,
          type: { style: "success", icon: true },
        });
        this.submitted = false;
        this. getAllUser();
        this.removeDialogOpen= false;
      
      // else{
      //   this._notif.show({
      //     content: result.messageKey,
      //     type: { style: "error", icon: true },
      //   });
      // }
    }) 
  }

  download() {
    let fileName = 'UserRegistrationData.csv';
    let columnNames = ["SNo","userName","userRoleName","staffName", "companyEmail","contractor","speciality","customerName","designation","gender","grade","labourCostPerHour","facilitiesName","EmployeeSpecialities","location","loggedInUserId","loginUserType","mobileNo","nationality","officeNo","compentencyName","serviceName","status"];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';

    this.exportGridData.map(c => {
      csv += [c["SNo"],c["userName"],c["userRoleName"],c["staffName"],c["companyEmail"], c["contractor"],c["speciality"],c["customerName"],c["designation"],c["gender"],c["grade"],c["labourCostPerHour"],c["facilitiesName"],c["EmployeeSpecialities"],c["location"],c["loggedInUserId"],c["loginUserType"],c["mobileNo"],c["nationality"],c["officeNo"],c["compentencyName"],c["serviceName"],c["status"]].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  loginUserChange(data){
    console.log("ssd",data)
    let userValue = this.loginUserTypeDropDown.filter((e)=>{
      return e.ID==data
    })
   this.userTypeName = userValue[0].NAME
     if( this.userTypeName=="Company User"){
       this.contrDisabled = true;
       this.companyDisabled = false;
       this.userRegisterForm.contractorId =null
        }
       else{
      this.contrDisabled = false;
      this.companyDisabled = true;
      this.error.companyName = false;
      this.userRegisterForm.companyName =null
     
     }
  }
unLockData(data){
      let obj={
        "type":"new",
        "userId": data.id,
        "username": data.userName,
        "userEmail": data.companyEmail,
        "passwordResetURL": window.location.origin+"/"+environment.reset_password_pathname
      }
      HttpClient.fetchData("/api/Users/ResetPassword","post",obj,true,true)
      .then((result:any)=>{
        HttpClient.insertAduitLog("Users","Add ResetPassword","Add ResetPassword in Users").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
        
      })
      .catch((err)=>{
      
      })
    }
import(){
this.importopened = true
}
importUpdate(){
  this.importopened = true
}

onSearchChange(searchValue: string): void {  
 this.userRegisterForm.userName = this.userRegisterForm.companyEmail
}

getBase64(file) {

 

}

// let file = document.querySelector('#files > input[type="file"]').files[0];
upload(){
 
 
}

valueChange(event){
  this.type="new";
  // console.log("user list===>",event);
  // console.log(this.con)
 let bulkUser= addBlukUser(event,this.genderObj,this.statusObj,this.contractorObj,this.designationObj,this.nationalityObj,
 this.locObj,this.facility_obj,this.userRole_Obj,this.compent_Obj,this.speciality_Obj,this.service_Obj)
  //console.log("bulk===user==>",bulkUser)

  Promise.all(
  bulkUser.map((d)=>{
    return this.addUserDataExcel(d)
  })
  )
  .then((result)=>{
    this.onDialogClose('importUpdate')
    this.getAllUser();
          this._notif.show({
            content: "Data Process Successfully",
            type: { style: "success", icon: true },
          });

  })
  .catch((err)=>{
    this.onDialogClose('importUpdate')
    this._notif.show({
      content: "Failed to Add",
      type: { style: "error", icon: true },
    });
  })

}

addUserDataExcel(userData) {
   const params = {
      ...userData
    };

    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/Users/RegisterUser", "post", params, true, true).then((result: any) => {
            params.id=result.id;
            this.unLockData(params);
            resolve(result)
        // if (result.isValid == true) {
        //   HttpClient.insertAduitLog("Users","Add Users","Add Users in Users").then((res:any)=>{
        //     if (res.isValid == true) {
    
        //     }
        //   })
        //   this._notif.show({
        //     content: result.messageKey,
        //     type: { style: "success", icon: true },
        //   });
        //   params.id=result.id;
        //   this.unLockData(params);
        //   this. getAllUser();
        //   this.opened = false;
        // } else {
          // this._notif.show({
          //   content: result.messageKey,
          //   type: { style: "error", icon: true },
          // });
        // }
        //   this.opened = false;
         })
    })

}

fileupload(){
  this.getAllUser();
  this.importopened=false

}
downloadT() {

  window.open("/assets/template/user_template.xlsx")
}
}
