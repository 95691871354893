import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { rejects } from 'assert';

import { sampleProducts } from 'src/app/Data/table';
import { SparePartReqDetails, SparePartRequest, SparePartRequestValue } from 'src/app/models/spare.model';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { SpareRequestValidation } from 'src/validator/spare';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-spare-part-request',
  templateUrl: './spare-part-request.component.html',
  styleUrls: ['./spare-part-request.component.scss']
})
export class SparePartRequestComponent implements OnInit {
  public startDate=new Date();
  public viewDialogOpen = false
  public endDate=new Date();
  public permission:Permission=new Permission();
  public gridReqDetData =[]
  public format = "dd/MM/yyyy";
  public ApproveTitle ="";
  public buttonValue=""
  public ApproveDesc =""
  public opened=false;
  public removeDialogOpen = false;
  public SparePartRequestDetailValue =[]
  public type="";
  public facName;
  public facCode1;
  public facCode;
  public actionsLayout = "normal";
  public dailogTitle="";
  public workOrderId;
  public facId;
  public WoData =[]
 public workOrderData=[]
 public facilityDropDown =[]
 public allUser  =[]
 public statusDropDown =[]
  public validate = true;
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},
]
  public  SparePartRequestValue: SparePartRequestValue = new  SparePartRequestValue()
  public spareRequestForm:SparePartRequest = new SparePartRequest()
  error =SpareRequestValidation(this.spareRequestForm,"init")
  public workOrderType={
    assets:{name:"assest",id:1,title:"Assets"},
    location:{name:"location",id:2,title:"Location"}
  }
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"materialRequestNo",title:"Material Request No.",isFilter:true,width:150},
    {field:"workOrderNo",title:"Work Order No.",isFilter:true,width:150},
    {field:"requestDate",title:"Request Date ", isFilter:true,width:150},
    {field:"facilityName",title:"Facility Name", isFilter:true,width:150},
    {field:"facCode",title:"Facility Code", isFilter:true,width:150},
     {field:"approvedData",title:"Approved Date", isFilter:true,width:150},
    {field:"approvedByname",title:"Approved By", isFilter:true,width:150},
    {field:"status",title:"Status", isFilter:true,width:150},
  ];

  
  
  public areagrid=[];
  //form data

  public facilityObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
}
public blockObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public levelObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public areaObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public locationObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public serviceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public assetDataObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public assigineeObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public assignToObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public chargeAbleObj={
  alldata:[],
  filterData:[
    {id:1,name:"Yes"},
    {id:2,name:"No"}
  ],
  selectedObj:null,
  objectData:{}
}

public returnDetailsCol=[
  {
    field: "partNo",
    title: "PartNo",
    isFilter: true,
    width: 150,
  },
  {
    field: "partDescription",
    title: "Part Description",
    isFilter: true,
    width: 150,
  },
  {
    field: "binNo",
    title: "Bin No.",
    isFilter: true,
    width: 150,
  },
  {
    field: "qoh",
    title: "Quantity On Hand",
    isFilter: true,
    width: 150,
  },
  {
    field: "physicalQuant",
    title: "Physical Quantity",
    isFilter: true,
    width: 150,
  },
  {
    field: "variance",
    title: "Variance",
    isFilter: true,
    width: 150,
  },
  {
    field: "remarks",
    title: "Remark",
    isFilter: true,
    width: 150,
  },
]

public workOrderTypeObj={
  alldata:[],
  filterData:[
     {id:1,name:"Routine Maintenance"},
     {id:2,name:"Corrective Maintenance"},
     {id:3,name:"Reactive Maintenance"}
  ],
  selectedObj:null,
  objectData:{}
}
public consequanceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public workOrderObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public statusObj={
  alldata:[],
  filterData:[
    {id:1,name:"Active"},
    {id:2,name:"InActive"}
  ],
  selectedObj:null,
  objectData:{}
}

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  cont_vendor_r = ['Select'];
  
  constructor(private _notif:NotificationService) {
    eventListner(EVENT_NAME.approved_column,(e)=>{this.approvedColumn(e)})
    eventListner(EVENT_NAME.reject_column,(e)=>{this.approvedColumn(e)})
    eventListner(EVENT_NAME.view_column,(e)=>{this.viewPartNoData(e)})
   }

   approvedColumn(data){
     if(data.type==="approve"){
       this.type = "approve"
      this.getSpareReuestDataById("mm","sparePartRequest",data.data.id)
     this.removeDialogOpen = true;
     this.ApproveTitle ="Approved Description"
     this.buttonValue ="Approved"
     this.ApproveDesc ="Are you sure you want to approved ?"
     }
      if(data.type==="reject"){
        this.type = "reject"
        this.getSpareReuestDataById("mm","sparePartRequest",data.data.id)
        // this.getSparepartReqetById("mm","sparePartRequestDetail",data.data.id)
        this.removeDialogOpen = true;
        this.ApproveTitle ="Reject Description"
        this.buttonValue="Reject"
        this.ApproveDesc ="Are you sure you want to reject ?"
      }
   }

ngOnInit() {
  if(UserModuleObj.spare_parts){
    this.permission=UserModuleObj.spare_parts;
  }
    Promise.all([
      this.getfacility("dbo","facility"),
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "block"),
      this.getLoockup("dbo", "blocklevel"),
      this.getLoockup("dbo", "levelarea"),
    
      this.getLoockup("dbo","ServicesType"),
      this.getStatus("mm", "requestStatus"),
      this.getAsset(),
      this.getAssignTo(),
      this.getAssignne(),
      this.getLoockup("PMC","TicketConsequenceType"),
      this.getLoockup("dbo", "arealocation"),
      this.getWorkOrder(),
      this.getAllUser(),
    ])
    .then((result:any)=>{
    this.getSpareRequestData()
    })
    .catch((err)=>{
     
    })
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
    this.openRoleDailog("new")
  }
  onDialogClose(type){
    if(type=="new"){
     
     this.opened=false;
    }
    if(type=="remove"){
      this.removeDialogOpen = false;
    }
    if(type=="view"){
      this.viewDialogOpen = false;
    }
    // this.submitted = false;
    // this.maintenence_status.reset();
   
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
      this.SparePartRequestValue = new SparePartRequestValue()
      this.spareRequestForm = new SparePartRequest();
      this.facName = "";
      this.facCode = "";
      this.facId =0;
      this.spareRequestForm.REQUESTSTAUSID = 1
      this.error =SpareRequestValidation(this.spareRequestForm,"init")
      this. WoData =[]
       this.dailogTitle="Add Request"
    }
    if(type=="update"){
      this.dailogTitle="Edit Request"
    }
    this.opened=true;
  }


  getPartByNo(id){
    HttpClient.get("/api/Assets/GetPartNoByWo?WONO=1",true,true)
    .then((result)=>{
         
    })
    .catch((err)=>{

    })
  }

  //get all data
  getLoockup(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
          let temp = JSON.parse(result.gridData)
          resolve(temp)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }

  getAsset(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/Assets/GetAssets",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  getAssignTo(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/BPM/GetBpms",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  getAssignne(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/Users/GetAllUser",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  valuechange(type,data){
    if(type=="workOrder"){
      this.workOrderObj.selectedObj=data;
     
      this.facilityObj.selectedObj=this.facilityObj.objectData[data.locationObj.FACILITYID]
      return new Promise((resolve, reject) => {
        HttpClient.get(
          `/api/Assets/GetPartNoByWOID/?Id=${data}`,
          true,
          
        )
          .then((result: any) => {
            let dataArray   = result
           this.WoData= dataArray .map((e)=>{
                 let value = this.facilityDropDown.filter((d)=>{
                   return d.ID == e.facilityId
                 })
              
                 e["facCode1"] =  value[0].FACILITYCODE
                 return e;
               })
             resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    if(type==="status"){
      this.statusObj.selectedObj=data;
    }
  }

  saveData(){
    let obj1=
      {
        "id": 0,
        "materialRequestNo": new Date().getTime()+"",
        "ticketId": 1,
        "requestDate":this.startDate,
        "requestStausId": this.statusObj.selectedObj.id,
        "approvedBy": 1,
        "approvedDate": new Date(),
        "facilityId":0
      }
    
      let obj={
        "schemaName":"mm",
        "tableName":"attachment",
        "userId":1,
        "oldData":JSON.stringify(obj1)
      }
    let error=false;
    if(this.workOrderObj.selectedObj && this.workOrderObj.selectedObj.id){
      obj1.facilityId=this.facilityObj.selectedObj.ID;
    }
    else{
      error=true;
    }
    this.saveSparePartRequest(obj)
  }
  saveSparePartRequest(obj){
    HttpClient.fetchData("/api/Assets/SaveSparePartRequest","post",obj,true,true)
    .then((result)=>{
      this.opened=false;
    })
    .catch((err)=>{
    })
  }
  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindStatusObj(result){
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }
  bindfacilityObj(result){
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

getSpareRequestData(){
  HttpClient.get("/api/Assets/GetSpareRequestList",true,true)
  .then((result:any)=>{
    let spareData =  shiftLastDataInto1st(result)||[];
    console.log(" spareData ", spareData )
    this.bindStatusObj(this.statusDropDown);
    this.bindfacilityObj(this.facilityDropDown);
    let sparePartWholeData =[]
    sparePartWholeData=spareData.map((e)=>{
        e["requestDate"] = this.dateFormating(e.requestDate);
           e["status"] =  this.statusObj[e.requestStausId] ;
        if(e.facilityId){
              e["facilityName"] =this.facilityObj[e.facilityId]
              let value= this.facilityDropDown.filter((d=>{
                              return d.ID == e.facilityId
                                  }));
                                  if(value.length>0){
                                    e["facCode"] =value[0].FACILITYCODE
                                  }
             }
        if(e.approvedDate===null){
              e["approvedData"] =""
             }
        else{
              e["approvedData"] = this.dateFormating(new Date(e.approvedDate))
             }
             if(e.approvedBy!==null){
              let value = this.allUser.filter((f)=>{
                             return f.id==e.approvedBy;
                                    })
                                   if(value.length>0){
                                         e["approvedByname"] = value[0].staffName
                                   }
                              } 

                              else{
                                e["approvedByname"] = null
                              }

                              e["workOrderNo"]= e.ticket && e.ticket.workOrderNo
          return e;
    })
    
    this.areagrid = sparePartWholeData.filter((e)=>{
      return e.approvalStatus ==null
    })
  })
  .catch((err)=>{
    
})
}

getWorkOrder(){
  return new Promise((resolve,rejects)=>{ HttpClient.get("/api/WorkOrder/GetWorkOrders",true,true)
  .then((result:any)=>{
   
    this.workOrderData  = result.filter((e)=>{
      if(e.workOrderNo!==null && e.workOrderNo!=="" ){
        return e;
      }
     })
    resolve(result)
}).catch((e)=>{
rejects(e)
})
})
}


WorkOrdervaluechange(data){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Assets/GetPartNoByWOID/?Id=${data}`,
      true,
     )
      .then((result: any) => {
        this.WoData= result;
        
        // let facilityId1:any
        // this.WoData.map((e)=>{
        //   let facilityId1:any = this.facilityDropDown.filter((d)=>{
        //     return d.ID===e.facilityId
        //   }); 

        //   e["facCode1"] = facilityId1[0].FACILITYCODE
        //   return e;
        // })

        let facilityId:any = this.facilityDropDown.filter((e)=>{
          return e.ID===result[0].facilityId
        });
        this.facId=  facilityId[0].ID
               this.facName = facilityId[0].NAME;
              this.facCode =facilityId[0].FACILITYCODE
        
       resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getfacility(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        let facilityData = [];
        facilityData = JSON.parse(result.gridData);
        this.facilityDropDown = facilityData;
        resolve(facilityData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getStatus(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        let statusData = [];
        statusData = JSON.parse(result.gridData);
        this.statusDropDown = statusData;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

submitData(){
  this.error =SpareRequestValidation(this.spareRequestForm,"")
  if (!this.error.TICKETID && !this.error.REQUESTDATE&& !this.error.REQUESTSTAUSID) {
    if(this.type=="new"){
      this.SparePartRequestValue.ID = 0;
      this.SparePartRequestValue.MATERIALREQUESTNO = null
      this.SparePartRequestValue.FACILITYID = this.facId;
      this.SparePartRequestValue.REQUESTSTAUSID = this.spareRequestForm.REQUESTSTAUSID;
      this.SparePartRequestValue.APPROVEDBY = null;
      this.SparePartRequestValue.APPROVEDDATE = null;
      this.SparePartRequestValue.APPROVALSTATUS = null
      this.SparePartRequestValue.REQUESTDATE = this.spareRequestForm.REQUESTDATE;
      this.SparePartRequestValue.NAMECREATEDBY =null;
      this.SparePartRequestValue.NAMEMODIFIEDBY =null;
      this.SparePartRequestValue.TICKETID = this.spareRequestForm.TICKETID;
      this.SparePartRequestValue.CREATEDBY = parseInt(getCookie(userid))
      this.SparePartRequestValue.MODIFIEDBY = parseInt(getCookie(userid))
      this.SparePartRequestValue.CREATEDDATE = new Date()
      this.SparePartRequestValue.MODIFIEDDATE = new Date()
     }
  }
    if(this.type=="approve"){
      this.SparePartRequestValue.APPROVALSTATUS = true
      this.SparePartRequestValue.APPROVEDBY =parseInt(getCookie(userid))
      this.SparePartRequestValue.APPROVEDDATE = new Date()
      this.SparePartRequestValue.REQUESTSTAUSID = 1
     }
     if(this.type=="reject"){
      this.SparePartRequestValue.APPROVALSTATUS = false
      this.SparePartRequestValue.APPROVEDBY =null
      this.SparePartRequestValue.APPROVEDDATE = null
      this.SparePartRequestValue.REQUESTSTAUSID = 2
     }
     this.saveSpareRequestDate(JSON.stringify( this.SparePartRequestValue));
}

saveSpareRequestDate(data){
  let obj={
    "schemaName":"mm",
    "tableName":"sparePartRequest",
    "userId":getCookie(userid),
    "newData":data
  }

  
   let  path="";
  if( this.type==="new"){
     path=`/api/MasterData/SaveLookupData`
  }
  else if(this.type==="approve"){
    path="/api/MasterData/UpdateLookupData"
  }
  else if(this.type==="reject"){
    path="/api/MasterData/UpdateLookupData"
  }
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
    if (result.isValid == true) {
      if( this.type==="new"){
      this.submitSparePartDetailsFrom(result.id);
      }
      else{
        this.removeDialogOpen = false;
        this.getSpareRequestData()
      }
}
  })
  .catch((err)=>{
  })
}

submitSparePartDetailsFrom(id){

  let sparePartWholeDate =[]
  sparePartWholeDate = this.WoData.map((e)=>{
   let value:SparePartReqDetails = new  SparePartReqDetails()
     value.ID =0;
     value.SPAREPARTDETAILID = e.sparePartId;
     value.SPAREPARTREQUESTID = id;
     value.SPAREPARTDETAIL = null;
     value.REMARK = e.remark;
     return value;
  })
  for(let i=0;i< sparePartWholeDate.length;i++){
    this.saveSparePartDetails(JSON.stringify(sparePartWholeDate[i]))
  }
   }
  
saveSparePartDetails(data){
  let obj={
    "schemaName":"mm",
    "tableName":"sparePartRequestDetail",
    "userId":getCookie(userid),
    "newData":data
  }
   let  path="";
  path=`/api/MasterData/SaveLookupData`
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
      if (result.isValid == true) {
        this._notif.show({
        content: result.messageKey,
        type: { style: "success", icon: true },
      });
      this.getSpareRequestData()
     this.opened=false;
    }
  })
  .catch((err)=>{
  })
}
  getSpareReuestDataById(schema,tablename,id){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
        true,
        true
      )
        .then((result: any) => {
           let Data = [];
          Data = JSON.parse(result.gridData);
          this.SparePartRequestValue = Data[0];
           resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeSpareReqData(){
    let obj={
        "schemaName":"mm",
        "tableName":"sparePartRequest",
        "userId":getCookie(userid),
        "oldData":JSON.stringify(this.SparePartRequestValue),    
        "newData":null//JSON.stringify(this.deleteItem)
      }

       let  path=`/api/MasterData/DeleteLookupData`
      HttpClient.fetchData(path,"post",obj,true,true)
      .then((result:any)=>{
        if(result.messageKey){
          this._notif.show({
            content:result.messageKey,
            type: { style: "success", icon: true },
          });
        }
        else if(result.message){
          this._notif.show({
            content:result.message,
            type: { style: "error", icon: true },
          });
        }
        // this.getspareUpdateDetails("mm","sparePartUpdate")
         this.removeDialogOpen=false;
      })
      .catch((err)=>{
      })
    }

    removeSpareReqDetaData(){
      let obj={
          "schemaName":"mm",
          "tableName":"sparePartRequestDetail",
          "userId":getCookie(userid),
          "oldData":JSON.stringify(this.SparePartRequestValue),    
          "newData":null//JSON.stringify(this.deleteItem)
        }

         let  path=`/api/MasterData/DeleteLookupData`
        HttpClient.fetchData(path,"post",obj,true,true)
        .then((result:any)=>{
          if(result.messageKey){
            this._notif.show({
              content:result.messageKey,
              type: { style: "success", icon: true },
            });
          }
          else if(result.message){
            this._notif.show({
              content:result.message,
              type: { style: "error", icon: true },
            });
          }
          // this.getspareUpdateDetails("mm","sparePartUpdate")
           this.removeDialogOpen=false;
        })
        .catch((err)=>{

        })
      }

      getAllUser() {
      return new Promise((resolve, reject) => {   HttpClient.get("/api/Users/GetAllUser", true, true)
          .then((result: any) => {
            this.allUser = result;
            resolve(result);
  
          })
        })
          .catch((err) => {
            rejects(err)
          });
      }


      viewPartNoData(data)
      {
        console.log(data)
       let value = data.sparePartRequestDetails
        this.getPartNoByWOID(data.ticketId,value)
         this.viewDialogOpen = true
      }

      getPartNoByWOID(id,data){
        HttpClient.get(`/api/Assets/GetPartNoByWOID/?Id=${id}`, true)
        .then((result: any) => {
          console.log("request",result)
        let dataValue = result.map((e)=>{
                       
                      data.map((d)=>{
                        e["remarks"] = d.remark
                       
                        // e["qoh1"] =Math.abs(d.qoh)
                      })
                      if(e.sparePartRequestDetails.length>0){
                        e["physicalQuant"] =e.sparePartRequestDetails[0].requestedQuantity 
                        e["variance"] = e.qoh-e.sparePartRequestDetails[0].requestedQuantity 
                        }
                    return e;        
         });
         this.gridReqDetData  = dataValue.filter((e)=>{
           return  e.sparePartRequestDetails && e.sparePartRequestDetails[0].sparePartRequest.approvalStatus===null;
         })
      })
      
    }
  }
