import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { GroupDescriptor, process, State } from "@progress/kendo-data-query";
import { Observable } from 'rxjs';
@Component({
  selector: 'app-er-report-daily',
  templateUrl: './er-report-daily.component.html',
  styleUrls: ['./er-report-daily.component.scss']
})
export class ErReportDailyValueComponent implements OnInit {
  public gridData:GridDataResult | any=[]
  public data:any=[];
  public permission:Permission=new Permission();
  public isAccess: boolean = false;
  public dyanamic_column=[]
  public fixedColumn={
    MAX:"max",
    MIN:"min",
    READ:"read"
  }
  public state: State = {
    skip: 0,
    group:[],
    take: 10,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }

  ngOnInit(): void {
    if(UserModuleObj.engineering_records){
      this.permission=UserModuleObj.engineering_records;
      this.isAccess = true;
      this.getERReportDaily();
    }else{
      this.isAccess = false;
    }
  }
  public column1 = [
    { field: "ertaskid", title: "Section", isFilter: true, width: 200 },
    { field: "parametrid", title: "Location", isFilter: true },
    { field: "desc", title: "Chargeman HT  ", isFilter: true },
    { field: "category", title: "Shift  ", isFilter: true, width: 100 },
    { field: "erno", title: "LV  ", isFilter: true, width: 100 },
    { field: "erno", title: "Date ", isFilter: true, width: 100 },
    { field: "erno", title: "Time  ", isFilter: true, width: 100 },
    { field: "erno", title: "Panel ID  ", isFilter: true, width: 100 },
    { field: "erno", title: "Voltage(KV)", isFilter: true, width: 100 },
    { field: "erno", title: "Current (a)", isFilter: true, width: 100 },
    { field: "erno", title: "Temp (C)", isFilter: true, width: 100 },
    { field: "erno", title: "lbs (PSi)", isFilter: true, width: 100 },
    { field: "erno", title: "PF", isFilter: true, width: 100 },
    { field: "erno", title: "Relay", isFilter: true, width: 100 },
    { field: "erno", title: "CT Ration ", isFilter: true, width: 100 },
    { field: "erno", title: "Setting OC ", isFilter: true, width: 100 },
    { field: "erno", title: "Setting EF", isFilter: true, width: 100 },
    { field: "erno", title: "Exp Date ", isFilter: true, width: 100 },
    { field: "erno", title: "Remarks", isFilter: true, width: 100 },
  

  ]

  month=["Jan","Fed","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

  convertDateFormate(d){
    let cd="";
    if(d){
      let dd=new Date(d);
      cd=`${dd.getDate()}-${this.month[dd.getMonth()]}-${dd.getFullYear()}`
    }
    return cd;
  }
  getERReportDaily(){
 
      return new Promise((resolve, reject) => {
        HttpClient.get(`/api/ER/GetERDailyReport`, true, true)
          .then((result: any) => {
            this.dyanamic_column=[...result.parameterList]
            this.gridData = result?.readingList.map((e)=>{
              let obj={
                er_no:e.erno,
                remark:e.remarks,
                status:e.erStatus,
                shift:e.shiftType,
                date:this.convertDateFormate(e.recordDateTime)
              }
             this.dyanamic_column.forEach((d)=>{
                  obj[d+this.fixedColumn.READ]=0;
                  obj[d+this.fixedColumn.MIN]=0;
                  obj[d+this.fixedColumn.MAX]=0;
              })
              if(e.erTaskParameterList != null){
              e.erTaskParameterList.forEach((p)=>{
                obj[p.parameterName+this.fixedColumn.READ]=p.actualValue;
                obj[p.parameterName+this.fixedColumn.MIN]=p.minValue;
                obj[p.parameterName+this.fixedColumn.MAX]=p.maxValue  
              })
            }
              return obj;
            });
            this.data=this.gridData;
            this.gridData = process(this.data, this.state);
            console.log(this.data)
          })
        })
    
  
  }

  download(){
    
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }

  public allData = (): Observable<any> => {
    return new Observable((obsr)=>{
      obsr.next(process(this.data,{}));
      obsr.complete()
    })
  };
}
