import {
    Component,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    OnInit
} from '@angular/core';

import { FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'dropdownlist-filter',
    template: `
      <kendo-dropdownlist
        [defaultItem]="defaultValue"
        [value]="value"
        [valuePrimitive]="isPrimitive"
        [data]="data"
        [textField]="textField"
        [valueField]="valueField"
        (valueChange)="onValueChange($event)"
        (selectionChange)="selectionChange($event)"
      >
      </kendo-dropdownlist>
    `
})
export class DropDownListFilterComponent implements AfterViewInit,OnInit {

    @Input() public isPrimitive: boolean;
    @Input() public currentFilter: any;
    @Input() public data;
    @Input() public textField;
    @Input() public valueField;
    @Input() public filterService: FilterService;
    @Input() public field: string;
    @Output() public valueChange = new EventEmitter<number[]>();

    public currentData: any;
    public showFilter = true;
    public value: number;
    public defaultValue={};
    public onValueChange(value: number): void {
        this.filterService.filter({
            filters: [{ field: this.field, operator: 'eq', value: value }],
            logic: 'and'
        });

    }
    public selectionChange(event){
        // this.filterService.filter({
        //     filters: [{ field: this.field, operator: 'eq', value: event }],
        //     logic: 'and'
        // });
        // console.log("selection filter:-",event)
    }
    public ngAfterViewInit(): void {
        this.currentData = this.data;
        const currentColumnFilter: FilterDescriptor =
            this.currentFilter.filters.find(
                (filter: FilterDescriptor) => filter.field === this.field
            );
        if (currentColumnFilter) {
            this.value = currentColumnFilter.value;
        }
    }
    ngOnInit(): void {
        this.defaultValue[this.textField]="Selecte Item";
        this.defaultValue[this.valueField]="Select Item";
        // throw new Error('Method not implemented.');
     }
}