<div class="dashboard-body">
    <div class="dashboard-content"> 
  <div class="content-wrapper">
    <div class="page-wrapper">
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Asset Classification</div>
          </div>
          <div class="ibox-body">
            <div id="example" class="chart-height">
              <kendo-chart class="chart">
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item [autoFit]="true" type="column" [data]="assetClassification" categoryField="label" field="value">

                    <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif">
                    </kendo-chart-series-item-labels>

                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="false"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </div>
        </div>
  
      </div>
      <div class="item">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Asset By Status</div>
          </div>
          <div id="example" class="chart-height">
            <kendo-chart class="chart">
              <kendo-chart-legend   position="top" font= "5px sans-serif"></kendo-chart-legend>
              <kendo-chart-series>
                <kendo-chart-series-item [autoFit]="true" type="donut" [data]="assetStatus " categoryField="label" field="value">
                  <kendo-chart-series-item-labels position="outsideEnd" font= "12px sans-serif"
                       color="#000" format="p1" margin="1px" padding="1px" >
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
  
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <div class="custom-tablesection">
    <div class="custom-dropdown">
    </div>
    <div class="custom-tab">
        <div style="padding: 1rem;"> 
            <!-- <app-table-grid  
                [column]="column"
                [data]="gridData"
                [size]="10"
                [action]="false"
             >
            </app-table-grid> -->
            <app-dashboard-table-grid 
                [data]="gridData" 
                [column]="column" 
                [size]="10" 
                [action]="false"  
                (filteredChanged)="filterChangedHandler($event)"
            >
            </app-dashboard-table-grid>
        </div>
    </div>
   </div>
        
  </div>
  </div>