import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class TaskAnswerList{ 
    ID:number;
    NAME:string;
    ISACTIVE:boolean;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number;
   async  saveData(name,isactive){
        this.ID=0;
        this.NAME=name;
        this.ISACTIVE=isactive;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerList,this,"new")
    }	
   async updateData(id,name,isactive){
        this.ID=id;
        this.NAME=name;
        this.ISACTIVE=isactive;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerList,this,id?"update":"new")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.TaskAnswerList,obj);
    }
}

export class TaskAnswerListObj{
    alldata:Array<TaskAnswerList>=[]
    filterData:Array<TaskAnswerList>=[]
    selectedObj:TaskAnswerList=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:TaskAnswerList)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.TaskAnswerList)
            .then((result:Array<TaskAnswerList>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}