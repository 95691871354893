import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
export class SubService3{ 
    ID:number;
    CHECKLISTID:string;
    DESCRIPTION:string;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    SUBSERVICE2ID:number;
    MODIFIEDBY:number;
   async  saveData(checklistId,description){
        this.ID=0;
        this.CHECKLISTID=checklistId;
        this.DESCRIPTION=description;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.subService,this,"new")
    }	
   async updateData(id,checklistId,description){
        this.ID=id;
        this.CHECKLISTID=checklistId;
        this.DESCRIPTION=description; 
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.subService,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.subService,obj);
    }
}

export class SubService3Obj{
    alldata:Array<SubService3>=[]
    filterData:Array<SubService3>=[]
    selectedObj:SubService3=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:SubService3)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.subService3)
            .then((result:Array<SubService3>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}