<div class="card m-3"  >
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>ER Report  (Monthly) 
        </h5>
        <div>
            <button kendoButton  primary="true" (click)="download()"  class="header-right-btn">
                Export
               </button> 
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10"
        [action] ="false"
      ></app-table-grid>
    </div>

</div>

<div  *ngIf="!isAccess">

    <div class="dashboard-content"> 
      <div class="content-wrapper">
        <div class="page-wrapper">
  <h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>
  
        </div>
      </div>
    </div>
  
  </div>