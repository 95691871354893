<!-- main app container -->
<!-- <div class="card m-3">
    <h5 class="card-header">User Role</h5>
    <div class="card-body register-form-body">
        <form [formGroup]="UserForm">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Role</label>
                    <input [(ngModel)]="roleDetail.Name" kendoTextBox formControlName="role" class="form-control"/>
                </div>
                <div class="form-group col-5">
                    <label>User Role</label>
                    <kendo-dropdownlist formControlName="userrole"  [data]="userroles" class="form-control">
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="form-row">
              <div class="form-group col-5">
                <label>Status </label>
                <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Competency</label>
                <kendo-dropdownlist [(ngModel)]="roleDetail.ModuleNames" formControlName="competency"  [data]="competencys" class="form-control">
                </kendo-dropdownlist>
            </div>
            </div>
            <div class="form-row">
                <div class="form-group col-6">
                    <label>Remark </label>
                    <kendo-textarea [(ngModel)]="roleDetail.Remark" formControlName="remark" class="form-control" [rows]="5" ></kendo-textarea>
                </div>
                <div class="form-group col-4">
                </div>
            </div>
            <div class="text-center mt-3 mb-4">
                <button kendoButton primary="true" (click)="onSubmitS()" class="mr-2">Save</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
        </form>
    </div>
  </div> -->
<kendo-dialog *ngIf="opened" (close)="onDialogClose('role')" [width]="800">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>
  <form [formGroup]="userForm">
    <div class="form-row">
      <div class="form-group fs-16" style="display: grid;grid-template-columns: 10% 90%;width:100%">
        <label>Role<span class="required">*</span></label>
        <div style="width:100%">
        <input kendoTextBox formControlName="name" class="form-control" />
        <kendo-formerror *ngIf="submitted && f.name.errors?.required"
        >*Required</kendo-formerror>
      </div>
    </div>
    </div>
    <div class="form-row">
      <div class="form-group  fs-16" style="display: grid;grid-template-columns: 10% 90%;width:100%">
        <label>Remark </label>
        <div style="width:100%">
        <input kendoTextBox formControlName="remark" class="form-control"/>
       
        </div>
      </div>
      <div class="form-group  fs-16" style="display: grid;grid-template-columns: 10% 90%;width:100%">
        <label>Status</label>
        <div style="width:100%">
          <kendo-dropdownlist  
          [data]="statusDropDown"class="form-control"
          [textField]="'NAME'"
          [valueField]="'ID'"
          [valuePrimitive]="true"
          formControlName ="statusId"
          
            >
          </kendo-dropdownlist>
        </div>
      </div>
      <span  *ngIf ="permissionerror" class="required">Select at least single permission</span>
    </div>
    <div class="form-row" style="background-color: #0c0b0b;">
      <div class="form-header fs-16">
        <label style="padding-left: 16px; margin-top: 3px; margin-bottom: 4px; color:#ced4d9;">
          Permission<span class="required">*</span>
        </label>
      </div>
      <app-permission-grid 
         [data]="rolePermission" 
         [column]="permissionColumn" 
         [size]="5"
      ></app-permission-grid>
    </div>
  </form>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onDialogClose('role')">Cancel</button>
    <button *ngIf="!roleId" kendoButton (click)="onReset()"  primary="true">
      Reset
    </button>
    <button *ngIf="!roleId" kendoButton (click)="submitData()"  primary="true">
      Save
    </button>
    <button *ngIf="roleId" kendoButton (click)="editRowColumn()" primary="true">
      Update
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Delete Description
    </div>
  </kendo-dialog-titlebar>
  <p>Are you sure you want to delete?</p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="removeRole()">Delete</button>
  </kendo-dialog-actions>
</kendo-dialog>
<div class="card m-3"   >
  <div class="card-header" style="display: flex;justify-content: space-between;">
    <h5>User Role </h5>
    <div>
      <button kendoButton primary="true"  *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
        <span class="add_btn">+</span> Role
      </button>
    </div>
  </div>
  <div class="card-body"    >
    <app-table-grid [data]="gridData"  [deleteFlag]="permission && permission.isDelete" [editFlag]="permission && permission.isUpdate" [column]="column" [size]="5"></app-table-grid>
    <!-- <kendo-grid [data]="gridData">
        <kendo-grid-column field="rolename" title="Role Name">
        </kendo-grid-column>
        <kendo-grid-column field="usertype" title="User Type">
        </kendo-grid-column>
        <kendo-grid-column field="competency"  title="Competency">
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Status">
        </kendo-grid-column>
      </kendo-grid> -->
  </div>
</div>


<!-- main app container -->
<!-- <div class="card m-3">
    <h5 class="card-header">
        Add Permission</h5>
    <div class="card-body">
        <div class="section">
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Location</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Level</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Block</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > Ticket Assigment</label
              >
            </div>
            <div class="wrap">
                <input type="checkbox" id="terms" kendoCheckBox />
              <label class="k-checkbox-label" for="terms"
                > All</label
              >
            </div>
            <div class="text-center">
                <button kendoButton primary="true" class="mr-2">Save</button>
                <button kendoButton primary="true" class="mr-2">Save and Add new</button>
                <button kendoButton type="reset" (click)="onReset()">Reset</button>
            </div>
          </div>
    </div>
  </div> -->