import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { convertLocalDateString, objectToQueryString } from 'src/Util/Util';

@Component({
  selector: 'app-feedback-received-percentage',
  templateUrl: './feedback-received-percentage.component.html',
  styleUrls: ['./feedback-received-percentage.component.scss']
})
export class FeedbackReceivedPercentageComponent implements OnInit {


  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public serviceObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public parentId = -1;
  public moduleId = -1;

  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public noDataFound: boolean = false;
  public facility: any;
  public feedbackDate: Date;
  public serviceTypeddl: any;
  constructor() { }

  ngOnInit(): void {


    Promise.all([
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "ServicesType"),
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
          result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        //ServicesType

        this.serviceObj.objectData = result[1].reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
        result[1].splice(0, 0, { ID: '0', NAME: 'ALL' });
        this.serviceObj.alldata = result[1];
        this.serviceObj.filterData = [];

      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }
  download() {
    let fileName = 'External-Feedback-Report.csv';
    let columnNames = [
      "Request Nummber",
      "Work Order no",
      "Facility",
      "Service Type",
      "Location",
      "Contractor",
      "Time & Date",
      "Technician",
      "Feedback received(Yes/No)",
    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["REQUESTNO"],
        c["WORKORDERNO"],
        c["FACILITY"],
        c["SERVICESTYPE"],
        c["LOCATION"],
        c["CONTRACTOR"],
        c["TIMEANDDATE"],
        c["ASSIGNEE"],
        c["FEEDBACKRECEIVED"],
      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  fetchReport() {

    let path = "";
    const from_date = (this.feedbackDate != undefined) ? convertLocalDateString(this.feedbackDate) : this.feedbackDate;

    var qString = { feedbackDate: from_date, facilityId: this.facility?.ID, servicdeTypeId: this.serviceTypeddl?.ID, }


    path = `/api/CustomReport/FeedbackReceived?${objectToQueryString(qString)}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          console.log(result);
          this.report = this.report.map((item) => {
            if (item['FEEDBACKRECEIVED'] > 0) {
              item['FEEDBACKRECEIVED'] = 'Yes'
            } else {
              item['FEEDBACKRECEIVED'] = 'No'
            }
            return item;
          });
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].REQUESTNO != null;
          this.noDataFound = !this.showReport
        })
        .catch((err) => {

          reject(err)
        })
    })
  }


  public column = [
    { field: "REQUESTNO", title: "Request Nummber", width: 200 },
    { field: "WORKORDERNO", title: "Work Order no", width: 200 },
    { field: "FACILITY", title: "Facility", width: 200 },
    { field: "SERVICESTYPE", title: "Service Type", width: 200 },
    { field: "LOCATION", title: "Location", width: 200 },
    { field: "CONTRACTOR", title: "Contractor", width: 200 },
    { field: "TIMEANDDATE", title: "Time & Date", width: 200 },
    { field: "ASSIGNEE", title: "Technician", width: 200 },
    { field: "FEEDBACKRECEIVED", title: "Feedback received(Yes/No)", width: 200 },

  ];
}

