import {ModelApi} from "src/ApiModel/const"
import { AssetRegistrationObj } from "./Model/Assets"
import { AreaLocationObj } from "./Model/AreaLocation"
import { BlockObj } from "./Model/Block"
import { LevelObj } from "./Model/BlockLevel"
import { ChecklistObj } from "./Model/CheckList"
import { ContractorVendorObj } from "./Model/Contractor"
import { FacilityObj } from "./Model/Facility"
import { AreaObj } from "./Model/LevelArea"
import { ServiceTypeObj } from "./Model/ServicesType"
import { TicketConsequanceObj } from "./Model/TicketConsequenceType"
import { UserObj } from "./Model/User"
import { WorkOrderObj } from "./Model/WorkOrder"
import {MaintenaceChecklistTaskObj} from "./Model/MaintanceChecklistTask"
import {TaskAnswerListObj} from "./Model/TaskAnswerList"
import {TaskAnswerTypeObj} from "./Model/TaskAnswerType"
import { AssetClassficationObj } from "./Model/AssetClassification"
import { AssetTypeObj } from "./Model/AssetType"
import { TaskAnswerListItemObj } from "./Model/TaskAnswerListItem"
import { MaintenaceFrequenceObj } from "./Model/MaintanceFrequency"
import { MaintenacePlannerObj } from "./Model/MaintancePlanner"
import { MaintenacePlannerDetailObj } from "./Model/MaintancePlannerDetail"
import { YearObj } from "./Model/Year"
import { StatusObj } from "./Model/Status"
import { TicketReassignmentObj } from "./Model/TicketReassignment"
import {TicketStatusObj } from "./Model/TicketStatus"
import {TaskItemObj} from "./Model/TaskItem"
import {AssetStandardizationObj} from "./Model/AssetStanderdtion"
import {ManufacturerObj} from "./Model/Manufacture"
import {AssetModelObj} from "./Model/AssetMode"
import {ContractTypeObj} from "./Model/ContractType"
import {SubServiceObj} from "./Model/SubService"
import {SubService2Obj} from "./Model/SubService2"
import {SubService3Obj} from "./Model/SubService3"
import {ChecklistSubServiceObj} from "./Model/ChecklistSubService"
import { MonthObj } from "./Model/Month"
export class Model{
  asset:AssetRegistrationObj=new AssetRegistrationObj();
  area:AreaObj=new AreaObj();
  block:BlockObj=new BlockObj();
  level:LevelObj=new LevelObj();
  checklist:ChecklistObj=new ChecklistObj();
  contractor:ContractorVendorObj=new ContractorVendorObj();
  facility:FacilityObj=new FacilityObj();
  location:AreaLocationObj=new AreaLocationObj();
  service:ServiceTypeObj=new ServiceTypeObj();
  ticketConsequance:TicketConsequanceObj=new TicketConsequanceObj();
  user:UserObj=new UserObj();
  workOrder:WorkOrderObj=new WorkOrderObj();
  maintenaceTask:MaintenaceChecklistTaskObj=new MaintenaceChecklistTaskObj()
  taskAnsList:TaskAnswerListObj=new TaskAnswerListObj();
  taskAndType:TaskAnswerTypeObj=new TaskAnswerTypeObj();
  assetClassfication:AssetClassficationObj=new AssetClassficationObj();
  assetType:AssetTypeObj=new AssetTypeObj();
  taskAnsListItem:TaskAnswerListItemObj=new TaskAnswerListItemObj();
  maintenaceFrequence:MaintenaceFrequenceObj=new MaintenaceFrequenceObj();
  planner:MaintenacePlannerObj=new MaintenacePlannerObj();
  plannerDetail:MaintenacePlannerDetailObj=new MaintenacePlannerDetailObj();
  year:YearObj=new YearObj();
  status:StatusObj=new StatusObj();
  ticketReassign:TicketReassignmentObj=new TicketReassignmentObj()
  ticketStatus:TicketStatusObj=new TicketStatusObj();
  taskItem:TaskItemObj=new TaskItemObj();
  assetStardantion:AssetStandardizationObj=new AssetStandardizationObj();
  manufacture:ManufacturerObj=new ManufacturerObj();
  assetModel:AssetModelObj=new AssetModelObj();
  contractTYpe:ContractTypeObj=new ContractTypeObj();
  subService:SubServiceObj=new SubServiceObj();
  subService2:SubService2Obj=new SubService2Obj();
  subService3:SubService3Obj=new SubService3Obj();
  checkListSubService:ChecklistSubServiceObj=new ChecklistSubServiceObj();
  month:MonthObj=new MonthObj();
  getData(...arg){
    return new Promise((resolve,reject)=>{
        Promise.all(
          arg.map((d)=>{
              if(d==ModelApi.Asset){
                  return this.asset.getData();
              }
              else if(d==ModelApi.ChekList){
                  return this.checklist.getData();
              }
              else if(d==ModelApi.Contractor){
                return this.contractor.getData();
              }
              else if(d==ModelApi.ServicesType){
                return this.service.getData();
              }
              else if(d==ModelApi.TicketConsequenceType){
                return this.ticketConsequance.getData();
              }
              else if(d==ModelApi.User){
                return this.user.getData();
              }
              else if(d==ModelApi.WorkerOrder){
                return this.workOrder.getData();
              }
              else if(d==ModelApi.arealocation){
                return this.location.getData();
              }
              else if(d==ModelApi.block){
                return this.block.getData();
              }
              else if(d==ModelApi.blocklevel){
                return this.level.getData();
              }
              else if(d==ModelApi.facility){
                return this.facility.getData();
              }
              else if(d==ModelApi.levelarea){
                return this.area.getData();
              }
              else if(ModelApi.MaintenaceTask==d){
                  return this.maintenaceTask.getData();
              }
              else if(ModelApi.TaskAnswerList==d){
                  return this.taskAnsList.getData();
              }
              else if(ModelApi.TaskAnswerType==d){
                return this.taskAndType.getData();
              }
              else if(ModelApi.assetClassfication===d){
                return this.assetClassfication.getData();
              }
              else if(ModelApi.AssetType===d){
                return this.assetType.getData();
              }
              else if(ModelApi.TaskAnswerListItem===d){
                return this.taskAnsListItem.getData();
              }
              else if(ModelApi.MaintenanceFrequency===d){
                return this.maintenaceFrequence.getData();
              }
              else if(ModelApi.MaintenancePlanner===d){
                return this.planner.getData();
              }
              else if(ModelApi.MaintenancePlannerDetail===d){
                return this.plannerDetail.getData();
              }
              else if(ModelApi.Year===d){
                return this.year.getData()
              }
              else if(ModelApi.Status===d){
                return this.status.getData()
              }
              else if(ModelApi.ticketreassignment===d){
                return this.ticketReassign.getData()
              }
              else if(ModelApi.ticketstatus==d){
                return this.ticketStatus.getData()
              }
              else if(ModelApi.taskItem==d){
                return this.taskItem.getData()
              }
              else if(ModelApi.assetStardantion==d){
                return this.assetStardantion.getData()
              }
              else if(ModelApi.assetModel==d){
                return this.assetModel.getData();
              }
              else if(ModelApi.manufacture==d){
                return this.manufacture.getData();
              }
              else if(ModelApi.contractType==d){
                return this.contractTYpe.getData();
              }
              else if(ModelApi.subService==d){
                return this.subService.getData();
              }
              else if(ModelApi.subService2==d){
                return this.subService2.getData()
              }
              else if(ModelApi.subService3===d){
                return this.subService3.getData();
              }
              else if(ModelApi.checklistsubservice==d){
                return this.checkListSubService.getData();
              }
              else if(ModelApi.month){
                return this.month.getData()
              }
          })
        )
        .then((result)=>{
           resolve(true)
        })
        .catch((err)=>{
           reject(err)
        })
    })
  }

}



