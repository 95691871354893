import { BlockType, BlockTypeError, LevelType, LevelTypeError, AreaType, AreaTypeError, LocationType, LocationTypeError, SubLocationType,  SubLocationTypeError } from "src/app/models/location.model";
export let BlockValidation = (data: BlockType, type) => {
    let error = new BlockTypeError(false)
    if (type === "init")
        return error;
    if (!data.FACILITYID) {
        error.FACILITYID = true
    }
    if (!data.CODE) {
        error.CODE = true
    }
    if (!data.NAME) {
        error.NAME = true;
    }
   return error;
}
export let LevelValidation = (data:  LevelType, type) => {
    let error = new LevelTypeError(false)
    if (type === "init")
        return error;
    if (!data.NAME) {
        error.NAME = true
    }
    if (!data.CODE) {
        error.CODE = true
    }
    if (!data.BLOCKID) {
        error.BLOCKID = true
    }
    if (!data.FACILITYID) {
        error.FACILITYID = true
    }
   return error;
}


export let AreaValidation = (data:  AreaType, type) => {
    let error = new AreaTypeError(false)
    if (type === "init")
        return error;
    if (!data.NAME) {
        error.NAME = true
    }
    if (!data.CODE) {
        error.CODE = true
    }
    if (!data.BLOCKLEVELID) {
        error.BLOCKLEVELID= true
    }
    if (!data.FACILITYID) {
        error.FACILITYID= true
    }
    if (!data.BLOCKID) {
        error.BLOCKID= true
    }
    
   return error;
}

export let LocationValidation = (data:  LocationType, type) => {
    let error = new LocationTypeError(false)
    if (type === "init")
        return error;
    if (!data.NAME) {
        error.NAME = true
    }
    if (!data.CODE) {
        error.CODE = true
    }
    if (!data.BLOCKLEVELID) {
        error.BLOCKLEVELID= true
    }
    if (!data.LEVELAREAID) {
        error.LEVELAREAID= true
    }
    if (!data.STARTSERVICEDATE) {
        error.STARTSERVICEDATE= true
    }
    if (!data.BLOCKID) {
        error.BLOCKID= true
    }
    if(!data.FACILITYID){
        error.FACILITYID= true
    }
    if(!data. STOPSERVICEDATE){
        error.STOPSERVICEDATE = true
    }
   return error;
}

export let subLocationValidation = (data: SubLocationType, type) => {
    let error = new SubLocationTypeError(false)
    if (type === "init") {
        return error;
    }
    if (!data.FACILITYID) {
        error.FACILITYID = true
    }
    if (!data.BLOCKID) {
        error.BLOCKID = true
    }
    if (!data.AREALOCATIONID) {
        error.AREALOCATIONID = true
    }
    if (!data.LEVELAREAID) {
        error.LEVELAREAID = true
    }
    if (!data.BLOCKLEVELID) {
        error.BLOCKLEVELID = true
    }
    if (!data.NAME) {
        error.NAME = true
    }
    if (!data.CODE) {
        error.CODE = true
    }
    return error;
}