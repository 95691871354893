import { Component, Input, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import { process, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"

@Component({
  selector: 'app-attachment-table-grid',
  templateUrl: './attachment-table-grid.component.html',
  })
export class AttachmentTableGridComponent implements OnInit {

  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() deleteFlag:Boolean=true;
  @Input() editFlag:Boolean=true;
  public gridData:GridDataResult;
  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
        if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = process(changes.data.currentValue, this.state);
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }
  ngOnInit(): void {
  }
  editHandler(e){
    dispatchEvent(EVENT_NAME.edit_column,e);
  }
  removeHandler(e){
    dispatchEvent(EVENT_NAME.delete_attechment,e);
  }
  addAttachment(e){
    dispatchEvent(EVENT_NAME.attachment_column,e);
  }
  downloadAtteched(data){
    dispatchEvent(EVENT_NAME.download_attechment,data);
  }
}
