class personInCharge{
    id: number;
      erparameterId: number;
      personInChargeId: number;
      shiftId: number;
      remarks: string;
      createdOn: Date
}

class paramerterName {
      id: number;
      erparameterId: number;
      parameterName: string;
      uomid: number;
      min: string;
      max: string;
      status: number;
      remarks: string;
      createdDate:Date
}

export class ERParameterMapping{
  id: number;
  erparameterId: string;
  description: string;
  categoryId: number;
  erno: string;
  location: string;
  serviceTypeId: number;
  frequencyId: number;
  effectiveStartDate: Date;
  dailyTimeErcreationdt:Date;
  emailTimeNotificationdt:any;
  dailyTimeErcreation: {};
  emailTimeNotification:{};
  erpersonInChargeModels:Array<personInCharge>
  erreadingModels:Array<paramerterName>
  assignTo: number;
  remarks: string;
  shiftTypeId: number;
  status: number;
  createdDate:Date;
  createdBy: number;
  updatedDate:Date;
  updatedBy: number
}


export class PMTypeError{
  categoryId:Boolean;
  erno:Boolean;
  effectiveStartDate:Boolean;
  frequencyId:Boolean;
  constructor(status){
     this.categoryId = status;
     this.erno = status;
     this.frequencyId = status;
     this.effectiveStartDate = status;
   }
 }