<div *ngIf="screenType=='detail'" style="background:lightgray;height: 100vh;">
       <div class="header-1" style="display: flex;justify-content: space-between;flex-direction: row !important;">
          <span  (click)="homeScreen()"><</span> 
          <span>Create Request</span> 
          <span></span>
       </div>
       <div style="margin:1rem;">
             <div style="background: #fff;box-shadow: 1px 1px 2px gray;border-radius: 0.25rem;">
                    <div style="padding: 0.5rem;border-bottom: 1px solid lightgray;font-size: 12px;text-align: center;color: gray;">
                       {{type==constData.type.location?"Location Detail":"Asset Detail"}}
                    </div>
                    <div style="margin:0.75rem 0rem 0.35rem 0rem;text-align: center;font-weight: 600;">
                         {{title}}
                    </div>
                    <div style="text-align: center;font-size: 12px;color: gray;padding-bottom: 0.75rem;">
                         {{subtitle}}	
                    </div>
             </div>
             <div style="background: #fff;box-shadow: 1px 1px 2px gray;border-radius: 0.25rem;margin-top: 1rem;">
                    <div style="padding:1rem;font-size: 12px;color: gray;">
                         <div>Request Description<span style="color: red;">*</span></div>
                         <div >
                              <textarea [(ngModel)]="workorderText" rows="4" style="width: 100%;margin-top: 0.3rem;border: 1px solid gray;">
                              </textarea> <br/>
                              <span *ngIf="error.description" style="color:red;">*Require</span>
                         </div>
                         <div style="display: grid;grid-template-columns: 40% 60%;margin-top: 1rem;">
                              <div style="display: flex;justify-content: center;flex-direction: column;"><span>Service Type<span style="color: red;">*</span></span></div>
                              <div>
                                <kendo-dropdownlist
                                [data]="model.service.alldata"
                                [textField]="'NAME'"
                                [valueField]="'ID'"
                                [value]="model.service.selectedObj"
                                (valueChange)="model.service.selectedObj=$event"
                                class="form-control"
                               >
                              </kendo-dropdownlist>
                              <span *ngIf="error.serviceType" style="color:red;">*Require</span>
                              </div>
                         </div>
                         <div *ngIf="attechments && attechments.length>0" style="display: flex;justify-content:center;flex-wrap: wrap;">
                              <div *ngFor="let img of attechments;let i=index" style="position: relative;height: 70px;width:70px;margin: 0.25rem;padding-top: 1rem;">
                                     <img [src]="img" style="height: 100%;width: 100%;object-fit: cover;"/>
                                     <div (click)="deleteAttechment(i)" style="position: absolute;top:0;right:0;background: #0B3969;color: #fff;border-radius: 50%;padding: 0.25rem;" >X</div>
                              </div>
                        </div>
                         <div style="display: flex;justify-content: center;box-shadow: 1px 1px 6px lightgray;padding: 0.5rem;margin-top: 2rem;">
                              <!-- <kendo-icon name="clip"></kendo-icon> -->
                              <!-- <button    icon="clock"></button> -->

                              <div style="color: #0B3969;font-size: 12px;font-weight: 600;" (click)="getAttechment()">Attachments
                              </div>
                         </div>
                         <span *ngIf="error.attechment" style="color:red;">*Require</span>
                         <div style="display: flex;justify-content: center;margin-top: 2rem;">
                            <div (click)="saveWorkOrder()" style="text-align: center;padding: 0.5rem 1rem;background: #0B3969;color: #fff;border-radius: 0.25rem;" >Send Request</div>
                         </div>
                    </div>
                    
             </div>
       </div>
</div>

<div *ngIf="screenType==constData.screenType.success" style="background:lightgray;height: 100vh;">
     <div class="header-1">
          Request Complete
     </div>
     <div  style="display:grid;grid-template-columns: 15% 70% 15%;background: #fff; ">
          <div style="padding:0.75rem;background: green;">
                 <div style="border:3px solid #fff; border-radius: 50%;display: flex;justify-content: center;flex-direction: column;text-align: center;color: #fff;">
                   <span style="transform: rotate(20deg);font-family: fantasy;font-weight: 600;padding:4px;"> &radic;</span>   
                 </div>
          </div>
          <div style="margin-left: 1rem;padding-top: 0.25rem;border-right: 1px solid lightgray;display: flex;justify-content: center;flex-direction: column;">
                <div style="font-weight:600; font-size: 14px;">
                    Success!
                </div>
                <div style="font-size:10px;color: gray; ">
                    Your request has been sent
                </div>
          </div>
          <div (click)="homeScreen()" style="display:flex;justify-content: center;flex-direction: column;text-align: center; font-size: 12px;color: gray;">
               Home
          </div>
     </div>
     <div style="margin:1rem;">
           <div style="background: #fff;box-shadow: 1px 1px 2px gray;border-radius: 0.25rem;">
                  <div style="padding: 0.5rem;border-bottom: 1px solid lightgray;font-size: 12px;text-align: center;color: gray;">
                     {{type==constData.type.location?"Location Detail":"Asset Detail"}}
                  </div>
                  <div style="margin:0.75rem 0rem 0.35rem 0rem;text-align: center;font-weight: 600;">
                       {{title}}
                  </div>
                  <div style="text-align: center;font-size: 12px;color: gray;padding-bottom: 0.75rem;">
                       {{subtitle}}	
                  </div>
           </div>
           <div style="background: #fff;box-shadow: 1px 1px 2px gray;border-radius: 0.25rem;margin-top: 1rem;">
                  <div style="padding:1rem;font-size: 12px;color: gray;">
                       <div style="display: flex;justify-content: center;">Request Description</div>
                       

                       <div style="display: grid;grid-template-columns: 20% 80%;">
                            <div style="display: flex;justify-content: flex-start;flex-direction: column;">
                                <div *ngIf="attechments && attechments.length>0" style="display: flex;justify-content: center;margin-top: 1rem;">
                                   <img [src]="attechments[0]" style="width: 50px;height: 50px;object-fit: contain;"/> 
                                </div>
                            </div>
                            <div style="margin:1rem;">
                                 <div style="color: rgb(22, 22, 22);font-size: 13px;">
                                      {{workorderText}}
                                 </div>
                                 <div style="margin-top: 1rem;">{{workOrderNumber}}</div>

                            </div>
                       </div>
                       <div style="text-align: center;color: gray;margin-top: 2rem;">
                            Do you want to raise a new request?
                       </div>
                       <div style="display: flex;justify-content: space-around;margin-top: 1rem;">
                            <div (click)="homeScreen()" style="border: 1px solid #0B3969;border-radius: 0.25rem;padding:0.5rem 1rem;text-align: center;color: #0B3969;">
                                 No, back to home
                             </div>
                             <div (click)="detailScreen()" style="background: #0B3969; border-radius: 0.25rem;padding:0.5rem 1rem;text-align: center;color: #fff;">
                              Yes, Proceed
                             </div>
                       </div>
                       <!-- <div *ngIf="attechments && attechments.length>0" style="display: flex;justify-content:center;flex-wrap: wrap;">
                            <div *ngFor="let img of attechments;let i=index" style="position: relative;height: 70px;width:70px;margin: 0.25rem;padding-top: 1rem;">
                                   <img [src]="img" style="height: 100%;width: 100%;object-fit: cover;"/>
                                   <div (click)="deleteAttechment(i)" style="position: absolute;top:0;right:0;background: #0B3969;color: #fff;border-radius: 50%;padding: 0.25rem;" >X</div>
                            </div>
                      </div> -->
                       <!-- <div style="display: flex;justify-content: center;box-shadow: 1px 1px 6px lightgray;padding: 0.5rem;margin-top: 2rem;">

                            <div style="color: #0B3969;font-size: 12px;font-weight: 600;" (click)="getAttechment()">Attechments</div>
                       </div>
                       <div style="display: flex;justify-content: center;margin-top: 2rem;">
                          <div (click)="saveWorkOrder()" style="text-align: center;padding: 0.5rem 1rem;background: #0B3969;color: #fff;border-radius: 0.25rem;" >Send Request</div>
                       </div> -->
                  </div>
                  
           </div>
     </div>
</div>



<div *ngIf="screenType=='home'" style="display: flex;justify-content: center;width: 100%;height: 100vh;">
    <div class="bg-blue" style="max-width: 500px;width: 100%;padding: 1.5rem;position: relative;">
          <div style="text-align: center;color: #fff;font-size: 22px;margin-top: 3rem;">
               Welcome to eWORMs!
          </div>
          <div style="text-align: start;font-size: 12px;margin-top: 3rem;margin-bottom: 1.5rem; margin-left: 0.5rem;color: #fff;">
            Please Select
          </div>
          <div *ngFor="let item of card;let i=index" class="bg-dark-blue"  style="display: grid;grid-template-columns: 25% 60% 15%;margin-top: 1.5rem;padding: 1rem 0rem;border-radius: 0.25rem;">
               <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;">
                    <div style="display: flex;justify-content: center;">
                            <!-- <div style="height: 20px;width: 20px;background: #fff;"></div> -->
                            <img [src]="item.logo"/>
                    </div>
               </div>
               <div style="margin:0.5rem 0.15rem;">
                     <div class="c-green" style="font-size: 14px;">{{item.title}}</div>
                     <!-- <div class="c-light-sea-green"  style="font-size: 10px;margin-top:0.15rem;">{{item.text}}</div> -->
                </div>
                <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;">
                    <div style="display: flex;justify-content: center;">
                            <input (change)="selectedUser($event,i)" [checked]="item.status" style="height: 20px;width: 20px;background-color: green;"  name="application_type" type="radio"/>
                    </div>
               </div>
          </div>
          <div style="position: absolute;bottom: 4rem;left: 1rem;right: 1rem;">
               <div style="width: 100%;">
                    <button class="bg-green" style="width: 100%; padding: 0.5rem;margin-top: 0rem;font-size:1rem;color: #fff;border: 0px;border-radius: 0.25rem;" (click)="detailScreen()">Continue</button>
              </div>
          </div>
          <div style="margin-top: 4rem;">
                <img style="width: 100%;height: 50px;" class="img" src="/assets/google_play1.jpeg" (click)="openAppStore('play_store')"/>
                <img style="width: 100%;height: 50px;margin-top: 2rem;" class="img" src="/assets/app_store1.jpeg" (click)="openAppStore('app_store')"/>
          </div>
    </div>
</div>

