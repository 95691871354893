import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { NotificationService } from "@progress/kendo-angular-notification";
import { sampleProducts } from "src/app/Data/table";
import { AreaType } from "src/app/models/location.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { AreaValidation } from "src/validator/location";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule";
@Component({
  selector: "app-area",
  templateUrl: "./area.component.html",
  styleUrls: ["./area.component.scss"],
})
export class AreaComponent {
  public opened = false;
  public type = "";
  public statusObj = {};
  public levelObj = {};
  public blockObj ={};
  public facilityObj ={}
  public actionsLayout = "normal";
  public dailogTitle = "";
  public validate = true;
  public blockName;
  public permission:Permission=new Permission();
  public levelName;
  public isunique=false;
  public uniqueList=[];
  public facilityDropDown = [];
  public blockDropDown = [];
  public statusDropDown = [];
  public levelCodeDropDown = [];
  public removeDialogOpen = false;
  public allBlocksValue = [];
  public allLevelsValue = [];
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public areaForm: AreaType = new AreaType();
  error = AreaValidation(this.areaForm, "init");
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},
]
  public column = [
    { field: "FACILITY", title: "Facility", isFilter: true , width:140},
    { field: "BLOCK", title: "Block Code", isFilter: true  , width:140 },
    { field: "LEVEL", title: "Level Code", isFilter: true , width:140 },
    { field: "NAME", title: "Area Name", isFilter: true  , width:140},
    { field: "CODE", title: "Area Code", isFilter: true  , width:140},
    { field: "SHORTNAME", title: "Short Name", isFilter: true , width:140 },
    { field: "STATUS", title: "Status", isFilter: true  , width:140 },
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true , width:160 },
    { field: "last_update_on", title: "Last Updated On", isFilter: true  , width:160},
  ];
  public gridData = [];

 

  // public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  public value: Date = new Date(2000, 2, 10);
  public value2: Date = new Date(2000, 3, 10);

  location_area: FormGroup;
  submitted = false;
 

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  ngOnInit() {
    if(UserModuleObj.area){
      this.permission=UserModuleObj.area;
    }
    Promise.all([this.getfacility(), this.getStatus("dbo", "status")]).then(
      (e) => {
        this.getAreaData("dbo", "levelarea");
      }
    );
  }
 
 
  editColumn(data) {
    if (data.action === "edit") {
      this.error = AreaValidation(this.areaForm, "init");
      this.areaForm.BLOCKID = data.dataItem.BLOCKID;
      this.areaForm.BLOCKLEVELID = data.dataItem.BLOCKLEVELID;
      this.areaForm.CODE = data.dataItem.CODE;
      this.areaForm.CREATEDBY = data.dataItem.CREATEDBY;
      this.areaForm.CREATEDDATE = data.dataItem.CREATEDDATE;
      this.areaForm.FACILITYID = data.dataItem.FACILITYID;
      this.areaForm.ID = data.dataItem.ID;
      this.areaForm.MODIFIEDBY = data.dataItem.MODIFIEDBY;
      this.areaForm.MODIFIEDDATE =new Date();
      this.areaForm.NAME = data.dataItem.NAME;
      this.areaForm.NAMEMODIFIEDBY = data.dataItem.NAMEMODIFIEDBY;
      this.areaForm.SHORTNAME = data.dataItem.SHORTNAME;
      this.areaForm.STATUSID = parseInt(data.dataItem.STATUSID);
      if (data.dataItem.BLOCKLEVELID) {
        let selectLevelValue = this.levelCodeDropDown.filter((e) => {
          return e.id == data.dataItem.BLOCKLEVELID;
        });

        if (selectLevelValue) {
          this.levelName = selectLevelValue[0] && selectLevelValue[0].name;
        }
      }
      if (data.dataItem.BLOCKID) {
        let selectBolckValue = this.blockDropDown.filter((e) => {
          return e.id == data.dataItem.BLOCKID;
        });
        if (selectBolckValue) {
          this.blockName = selectBolckValue[0] && selectBolckValue[0].name;
        }
      }
      this.openRoleDailog("update");
    } else if (data.action === "remove") {
      this.areaForm = data.dataItem;
      this.areaForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.removeDialogOpen = true;
    }
  }

  onReset() {
    this.submitted = false;
   // this.areaForm = new AreaType();
    this.openRoleDailog("new")
    this.error = AreaValidation(this.areaForm, "init");
    this.blockName="";
    this.levelName ="";
  }

  onDialogClose(type) {
    if ((type = "new")) {
      this.opened = false;
      this.areaForm = new AreaType();
    }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.areaForm = new AreaType();
      this.blockName="";
      this.levelName ="";
      this.filterdLevelData =[];
      this.filterdBlockData =[];
      this.error = AreaValidation(this.areaForm, "init");
      this.areaForm.ID = 0;
      this.areaForm.STATUSID = 1;
      this.dailogTitle = "Add Area";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Area";
    }
    this.opened = true;
  }

  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
            if(result.length>0){
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          if(facilityData.length>0){
          let blocksValue = [];
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockDropDown = this.allBlocksValue.filter((e)=>{
              return e.statusId==1;
            });
            this.filterdBlockData = this.blockDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue.filter((e)=>{
            return e.statusId==1;
          });
          this.filterdLevelData = this.levelCodeDropDown.slice();
          resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  facilityValueChange(facilityId) {
let blockdata =  this.blockDropDown.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = blockdata.slice();
  }

  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
   let levelData =   this.levelCodeDropDown.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = levelData.slice();
  }

  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
  }

  
  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  
  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  getAreaData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
      let AreaData = [];
      AreaData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindStatusObj(this.statusDropDown);
      this.bindBlockObj(this.filterdBlockData);
      this.bindLevelObj(this.filterdLevelData);
      this.bindFacilityObj(this.facilityDropDown);
      this.gridData = AreaData;
      this.gridData = AreaData.map((d) => {
        d["STATUS"] = this.statusObj[d.STATUSID];
        d["BLOCK"] = this.blockObj[d.BLOCKID];
        d["LEVEL"] = this.levelObj[d.BLOCKLEVELID];
        d["FACILITY"] = this.facilityObj[d.FACILITYID];
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE)
        return d;
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    this.error = AreaValidation(this.areaForm, "");
    if (
      !this.error.NAME &&
      !this.error.CODE &&
      !this.error.FACILITYID &&
      !this.error.BLOCKLEVELID &&
      !this.error.BLOCKID
    ) {
      if (this.type == "new") {
        // let levelcode:any =this.areaForm.CODE;
           this.areaForm.ID = 0,
          // this.areaForm.CODE = parseInt(levelcode);
          this.areaForm.CREATEDBY = parseInt(getCookie(userid)),
          this.areaForm.MODIFIEDBY = parseInt(getCookie(userid)),
          this.areaForm.CREATEDDATE = new Date(),
          this.areaForm.MODIFIEDDATE = new Date();
      }

      this.addArea(JSON.stringify(this.areaForm));
    }
  }

  addArea(data) {
    const paramsObj = {
      newData: data,
      schemaName: "dbo",
      tableName: "levelarea",
      userId: getCookie(userid),
    };
    if (this.type == "new") {
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Area","Add New Area","Add Area in New Area").then((res:any)=>{
            if (res.isValid == true) {
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this.getAreaData("dbo", "levelarea");
          // const statusValue = this.statusDropDown.filter((x) => {
          //   return x.ID == this.areaForm.STATUSID;
          // });

          // let obj = {
          //   ...this.areaForm,
          //   STATUS: statusValue[0].NAME,
          //   last_update_on:this.dateFormating(this.areaForm.MODIFIEDDATE)
          // };
          // this.gridData.unshift(obj);
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }

    if (this.type == "update") {
      HttpClient.fetchData(
        "/api/MasterData/UpdateLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Area","Updated Area","Updated Area in Area").then((res:any)=>{
            if (res.isValid == true) {
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getAreaData("dbo", "levelarea");
          // const statusValue = this.statusDropDown.filter((x) => {
          //   return x.ID == this.areaForm.STATUSID;
          // });
          // let obj = {
          //   ...this.areaForm,
          //   STATUS: statusValue[0].NAME,
          //   last_update_on:this.dateFormating(this.areaForm.MODIFIEDDATE)
          // };

          // this.gridData = this.gridData.map((d) => {
          //   if (d.ID == this.areaForm.ID) {
          //     d = obj;
          //   }
          //   return d;
          // });

        
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
      });
    }
  }

  removeAreaData() {
    HttpClient.fetchData(
      "/api/MasterData/DeleteLookupData",
      "post",
      this.areaForm,
      true,
      true
    )
      .then((result: any) => {
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridData = this.gridData.filter((x) => {
            return x.id != this.areaForm.ID;
          });
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((error) => {});
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
         
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  handleBlockFilter(value) {
    this.filterdBlockData = this.blockDropDown.filter(
      (s) => s.code.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleLevelFilter(value) {
    this.filterdLevelData = this.levelCodeDropDown.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  checkedUniqueCode(){
    this.isunique=false;
    let val:string=this.areaForm.CODE;
    if(!(this.uniqueList.filter((d)=>d==val.toLowerCase()).length>0)){
        this.isunique=this.gridData.filter((d)=>{
          return val.toLowerCase()==d.CODE.toLowerCase()
        }).length>0
    }
  }
}
