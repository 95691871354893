import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { sampleProducts } from 'src/app/Data/table';
import { AssetInfoValue, AssetLocationValue, AssetRegistration } from 'src/app/models/asset-reg.model';
import {eventListner,EVENT_NAME} from "src/event";
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';

@Component({
  selector: 'app-asset-registration',
  templateUrl: './asset-registration.component.html',
  styleUrls: ['./asset-registration.component.scss']
})
export class AssetRegistrationComponent implements OnInit {
  public opened=false;
  public hideCreatePpmPlanner = false;
  public removeDialogOpen = false;
  public type="";
  public importopened = false;
  public actionsLayout = "normal";
  public dailogTitle="";
  public commictionvalue: Date = new Date(2021, 5, 1, 22);
  public servicestartvalue: Date = new Date(2021, 5, 1, 22);
  public servicestopvalue: Date = new Date(2021, 5, 1, 22);
  public lastworksc: Date = new Date(2021, 5, 1, 22);
  public lastworkusc: Date = new Date(2021, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  public removeAsset_Id;
  public assetManufactureData;
  public assetTypeData;
  public assetModelData;
  public modelObj = {};
  public manufactureObj = {};
  public assetTypeObj = {};
  public facilityDropDown =[];
  public blockCodeDropDown =[];
  public levelCodeDropDown = [];
  public areaCodeDropDown  = [];
  public locationCodeDropDown =[];
  public subLocationDropDown = [];
  public assetClassDropDown = []
  public assStandDropDown =[]
  public assetStatusDropDown = []
  public allBlocksValue =[];
  public allLevelsValue =[];
  public allAreasValue =[];
  public allLocationValue =[];
  public allSubLocationValue =[];
  public blockName;
  public levelName;
  public areaName;
  public locationName;
  public subLocationName;
  public assetTypeCode;
  public assetTypeDesc;
  public assetModel;
  public assetAge;
  public yearInService;
  public assetManufacture;
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public facilityObj ={};
  public locationObj ={};
  public subLocationObj ={};
  public PpmFlag = "No"
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationData: Array<{ code: string; id: number;name: string }>;
  public filterdSubLocationData: Array<{ code: string; id: number;name: string }>;
  public contractTypeDropDown = [
    {id:1,name:"Comprehensive"},
    {id:2,name:"Comprehensive Plus"},
    {id:3,name:"non-Comprehensive"},
    {id:4,name:"Preventive Maintenance"},
  ]
  assetRegistrationForm :  AssetRegistration = new AssetRegistration
  public onTabSelect(e) {
    console.log(e);
  }

  public column1=[
    {field:"ProductID",title:"Registration No.",isFilter:true},
    {field:"ProductName",title:"Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Country", isFilter:true},
    {field:"UnitPrice",title:"State", isFilter:true}
  ]
  public gridData=[]
  public column2=[
    {field:"ProductID",title:"File Type",isFilter:true},
    {field:"ProductName",title:"File Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Attachments", isFilter:true},
    {field:"UnitPrice",title:"Downloads", isFilter:true}
  ]
  public gridData2=sampleProducts;

  asset_registration: FormGroup;
  submitted = false;
  asset_classi = ['Select'];
  contract_ty = ['Select'];
  real_time = ['Select'];
  asset_s = ['Select'];
  running_hour = ['Select'];
  location_block_code = ['Select'];
  location_level_code = ['Select'];
  location_area_code = ['Select'];
  asset_mnt_ppm = ['Select'];
  asset_mnt_routine_i = ['Select'];
  asset_mnt_calibration = ['Select'];
  location_facility_code = ['Select'];
  location_inst_l_code = ['Select'];
  location_current_code = ['Select'];
  @Input() assetId: number;
  public assetRegForm: AssetRegistration = new AssetRegistration();
  public assetInforForm : AssetInfoValue = new AssetInfoValue();
  public  assetLocationForm: AssetLocationValue = new AssetLocationValue()
  constructor(private formBuilder: FormBuilder,public _notif:NotificationService) { 
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
  }

  
  ngOnInit() {
    Promise.all([
     this.getfacility(),
     this.getAssetManufacture(),
     this.getAssetModel(),
     this.getAssetTypeCode(),
     this. getAssetStatus(),
     this.getAssetClass()
    ])
      .then((result) => {
        this.getAssetStandard();
        if(this.assetId){
          
          this.assetRegistrationForm.assetStandardizationId = this.assetId
        }
       
      })
    this.asset_registration = this.formBuilder.group({
      assets_no: ['', Validators.required],
      asset_p_r: ['', Validators.required],
      asset_classi: ['', Validators.required],
      asset_type_d: ['', [Validators.required]],
      asset_t_c: ['', Validators.required],
      modal_no: ['', Validators.required],
      manuf: ['', Validators.required],
      serial_no: ['', Validators.required],
      name_pl_m: ['', [Validators.required]],
      contract_ty: ['', Validators.required],
      engine_no: ['', Validators.required],
      chasis_no: ['', [Validators.required]],

      parent_asset: ['', Validators.required],
      serice_end_date: ['', Validators.required],
      commi_date: ['', [Validators.required]],
      expected_life: ['', Validators.required],
      serv_star: ['', Validators.required],
      real_time: ['', [Validators.required]],
      asset_s: ['', Validators.required],
      opera_s: ['', Validators.required],
      asset_age: ['', [Validators.required]],
      running_hour: ['', Validators.required],
      yr_in_serv: ['', Validators.required],

      location_facility_code: ['', Validators.required],
      location_block_name: ['', Validators.required],
      location_block_code: ['', Validators.required],
      location_level_name: ['', [Validators.required]],
      location_level_code: ['', Validators.required],
      location_area_name: ['', Validators.required],
      location_area_code: ['', [Validators.required]],
      location_inst_l_name: ['', Validators.required],
      location_inst_l_code: ['', Validators.required],
      location_current_name: ['', [Validators.required]],
      location_current_code: ['', Validators.required],
      location_current_name_a: ['', Validators.required],
      location_current_code_a: ['', Validators.required],

      asset_mnt_ppm: ['', Validators.required],
      asset_mnt_routine_i: ['', Validators.required],
      asset_mnt_calibration: ['', [Validators.required]],
      asset_mnt_cumulative: ['', Validators.required],
      asset_mnt_last_work_o_no: ['', Validators.required],
      asset_mnt_last_work_o_date: ['', [Validators.required]],
      asset_mnt_last_un_work_o_no: ['', Validators.required],
      asset_mnt_last_un_work_o_date: ['', Validators.required],
      asset_mnt_total_ydt: ['', [Validators.required]],
      asset_mnt_total_rm: ['', Validators.required],
      asset_mnt_cost_rm: ['', Validators.required],

      asset_trns_mode: ['', Validators.required],
      asset_trns_facility_name: ['', Validators.required],
      asset_trns_date: ['', [Validators.required]],
      asset_trns_remark: ['', Validators.required],
      asset_trns_prev_no: ['', Validators.required],
  
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
   
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.asset_registration.controls; }
  
  onSubmit() {
      this.submitted = true;
  
      // stop here if form is invalid
      if (this.asset_registration.invalid) {
          return;
      }
  
      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.asset_registration.value, null, 4));
  }
  
  onReset() {
      this.submitted = false;
      this.asset_registration.reset();
  }
  onDialogClose(type){
    this.opened=false;
    if(type=="importUpdate"){
          this.importopened = false
    }
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Add Contractor & Vendor"
    }
    if(type=="update"){
      this.dailogTitle="Edit Contractor & Vendor"
    }
    this.opened=true;
  }

 getAssetTypeCode(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetTypes", true, true)
        .then((result: any) => {
          this.assetTypeData = result;
           this.bindAssetTypeObj(result);
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetModel(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetModels", true, true)
        .then((result: any) => {
          this.assetModelData = result;
          
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getAssetManufacture(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetManufacturers", true, true)
        .then((result: any) => {
          this.assetManufactureData = result;
        
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

 

  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
           
        if(result.length>0){
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue;
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue;
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }

          this.areaCodeDropDown = this.allAreasValue;
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          if(this.areaCodeDropDown.length>0){
            let locationValue = [];
            locationValue = this.areaCodeDropDown.map((e)=>{
                   return e.areaLocations;
            })
             
            for (let i = 0; i < locationValue.length; i++) {
              for (let j = 0; j < locationValue[i].length; j++) {
                let arr = [];
                arr =  locationValue[i][j];
                this.allLocationValue.push(arr);
              }
            }
            this.locationCodeDropDown = this.allLocationValue;
            this.filterdLocationData =  this.locationCodeDropDown.slice();
            if(this.locationCodeDropDown.length>0){
              let subLocationValue = [];
              subLocationValue = this.locationCodeDropDown.map((e)=>{
                return e.locationSubLocations;
              })
              for(let i =0; i< subLocationValue.length;i++){
                for(let j=0;j< subLocationValue[i].length ;j++){
                   let arr =[];
                   arr = subLocationValue[i][j];
                   this.allSubLocationValue.push(arr);
                }
              }
              this.subLocationDropDown = this.allSubLocationValue;
            
             this. filterdSubLocationData = this.subLocationDropDown.slice();
            }
           
          }

         resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  facilityValueChange(facilityId) {
    this.blockCodeDropDown = this.allBlocksValue.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = this.blockCodeDropDown.slice();
  }
  
  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    this.levelCodeDropDown = this.allLevelsValue.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = this.levelCodeDropDown.slice();
  }
  
  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
    this.areaCodeDropDown = this.allAreasValue.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData = this.areaCodeDropDown.slice();
  }
  
  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
     this.locationCodeDropDown = this.allLocationValue.filter((e)=>{
       return e.levelAreaId = areaId;
     })

     this.filterdLocationData =  this.locationCodeDropDown.slice();
  }

  locationValueChange(locationId){
    let selectLocationValue = this.filterdLocationData.filter((e) => {
      return e.id == locationId;
    });
    this.locationName =selectLocationValue[0].name;
    this.subLocationDropDown = this.allSubLocationValue.filter((e)=>{
      return e.areaLocationId = locationId;
    })
    this.filterdSubLocationData = this.subLocationDropDown.slice();
  }

  subLocValueChange(subLocId){
  
    let selectedSubLocValue = this.filterdSubLocationData.filter((e)=>{
      return e.id == subLocId;
    })

    this.subLocationName = selectedSubLocValue[0].name;
  }

  bindAssetTypeObj(result) {
    this.assetTypeObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})

  }
  bindManufactureObj(result) {
    this.manufactureObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindModelObj(result) {
    this.modelObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  getAssetStatus() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetStatuses", true, true)
        .then((result: any) => {
          
         this.assetStatusDropDown = result;
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetStandard(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetStandardizations", true, true).then((result: any) => {
      
        this.assStandDropDown = result.map((e)=>{

          return {id:e.id, name: this.assetTypeObj[e.assetTypeId] && this.assetTypeObj[e.assetTypeId].code}
        })
        
        resolve(result);
      }).catch((e)=>{
        reject(e);
      })
    })
  }

  assetStandValueChange(assetStanId){
    HttpClient.get(`/api/Assets/GetAssetStandardizationById?id=${assetStanId}`, true,true)
    .then((result: any) => {
      this.bindManufactureObj(this.assetManufactureData);
      this.bindModelObj(this.assetModelData);
      this.assetTypeCode = this.assetTypeObj[result.assetTypeId]&& this.assetTypeObj[result.assetTypeId].code;
      this.assetManufacture = this.manufactureObj[result.assetManufacturerId];
      this.assetModel = this.modelObj[result.assetModelId];
      this.assetTypeDesc=this.assetTypeObj[result.assetTypeId] && this.assetTypeObj[result.assetTypeId].description;
     
    })
  }


  getAssetClass(){
   return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Assets/GetAssetClassifications", true,true)
    .then((result: any) => {
     this.assetClassDropDown= result.map((e=>{
          return {id:e.id,name:e.code}
      }))
      resolve(result)
              
  
    }).catch((e)=>{
      reject(e)
    })
   })
  }

OnSubmit(){

}

 calcDate(date1,date2) {
  var diff = Math.floor(date1.getTime() - date2.getTime());
  var day = 1000 * 60 * 60 * 24;
   let message;
  var days = Math.floor(diff/day);
  var months = Math.floor(days/31);
  var years = Math.floor(months/12);
  message += Math.abs(years) + " years ,"
  message += Math.abs(months) + " months \n"
  return message
  }


getAssetAge(data){
let today:any = new Date()
let past:any = new Date(data)
let diffInMilliSeconds = Math.abs(past -  today ) / 1000;
const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
diffInMilliSeconds -= hours * 3600;

this.assetInforForm.assetAge = this.calcDate(today,past).slice(9);
}

getYearInService(data){
  let today = new Date()
  let past = new Date(data)
  this.assetInforForm.yearInService = this.calcDate(today,past).slice(9);
  
}

getAssetRegById(){

}

editColumn(data) {
  if (data.action == "edit") {
    this.opened = true;
    this.openRoleDailog("update")
    HttpClient.get("/api/Assets/GetAssetById?id=" + data.dataItem.id, true, true)
      .then((result: any) => {})
  
}
else if (data.action == "remove") {
  this.removeAsset_Id = data.dataItem
  this.removeDialogOpen = true;
}
}

removeAsset(){
  const params = {
      "loggedInUserId": getCookie(userid),
      "id": 0,
      "employeeGlobalId": getCookie(userid),
      "instance": ""
    };
    HttpClient.fetchData("/api/Assets/DeleteAssetById", "post", params, true,true)
      .then((result:any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Delete Asset Reg","Delete Asset Reg in Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
        this.gridData = this.gridData.filter(x => {
          return x.id != this.removeAsset_Id.id;
        })
        this.gridData = JSON.parse(JSON.stringify(this.gridData));
        this.removeDialogOpen = false;
      }
      else{
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
      }).catch((error) => {
      });
  }
 
}
