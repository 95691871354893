<div class="header header-bg">
    <div class="nav-container">
      
 
        <div class="title">

            <!-- <h1> {{ this.customMsgService.translate('warehouse') }} </h1> -->
            <img src="assets/klcc.png" alt=""  width="160px">
            <h1>  <span class="menu-button mx-5 pr-5 ">
               <span class="k-icon k-i-menu" (click)="onButtonClick()"></span>
           </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Asset Management System</h1>
            <!-- <span class="vl"></span>
            <h2> {{ selectedPage }} </h2> -->
        </div> 

        <!-- <kendo-avatar
            imageSrc="assets/bell-icon.png"
            shape="circle"
        >
        </kendo-avatar>  -->
        
        <!-- <div class="mr-5 bell-notification">
            <ul class="list-unstyled mb-0">
                <li class="dropdown ml-2">
                   <a class="rounded-circle " href="#" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="avatar avatar-md avatar-indicators avatar-online">
                          <span class="notif-count">{{notifCount}}</span>
                         <img alt="avatar" src="assets/bell-icon.png" class="rounded-circle">
                      </div>
                   </a>
                   <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser" style="width: 280px;">
                      
                      <div class="">
                         <ul class="list-unstyled" *ngFor ="let item of  notifDetails">
                            <li> 
                               <a class="dropdown-item" href="JavaScript:Void(0);"><i class="k-icon k-i-bell"></i>
                                <span>{{item.details}}</span> </a>
                            </li>
                         </ul>
                      </div>
                   </div>
                </li>
             </ul>
        </div> -->
        <div class="mr-5 ">
         <button kendoButton  class="header-right-btn" primary="true" (click)="logout()"><i class="k-icon k-i-logout"></i>Sign Out</button>
         </div>
        <!-- <div class="mr-5">
            <ul class="list-unstyled mb-0">
                <li class="dropdown ml-2">
                   <a class="rounded-circle " href="#" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="avatar avatar-md avatar-indicators avatar-online">
                         <img alt="avatar" src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png" class="rounded-circle">
                      </div>
                   </a>
                   <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">
                      <div class="dropdown-item">
                         <div class="d-flex py-2">
                            <div class="avatar avatar-md avatar-indicators avatar-online">
                               <img alt="avatar" src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png" class="rounded-circle">
                            </div>
                            <div class="ml-3 lh-1">
                               <h5 class="mb-0">Annette Black</h5>
                               <p class="mb-0">annette@company.com</p>
                            </div>
                         </div>
                      </div>
                      <div class="dropdown-divider"></div>
                      <div class="">
                         <ul class="list-unstyled">
                            <li> 
                               <a class="dropdown-item" href="JavaScript:Void(0);"><i class="k-icon k-i-user"></i> Manage Profile</a>
                            </li>
                            <li> 
                               <a class="dropdown-item" href="JavaScript:Void(0);"><i class="k-icon k-i-cog"></i> Account Settings</a>
                            </li>
                         </ul>
                      </div>
                      <div class="dropdown-divider"></div>
                      <ul class="list-unstyled">
                         <li>
                            <a class="dropdown-item" href="JavaScript:Void(0);"><i class="k-icon k-i-logout"></i> Sign Out</a>
                         </li>
                      </ul>
                   </div>
                </li>
             </ul>
        </div> -->

        <!-- <div class="half">
            <label for="profile2" class="profile-dropdown">
              <input type="checkbox" id="profile2">
              <img class="pro-pic" src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png">
              <label for="profile2"></label>
              <ul class="profile-dropdown">
                <li><a href="#"><i class="k-icon k-i-user"></i>Manage Profile</a></li>
                <li><a href="#"><i class="k-icon k-i-cog"></i>Account Settings</a></li>
                <li><a href="#"><i class="k-icon k-i-logout"></i>Logout</a></li>
              </ul>
            </label>
        </div> -->
        
        <!-- <kendo-avatar
            imageSrc="assets/user.jpg"
            shape="circle"
        >
        </kendo-avatar> -->
        
        

       

    </div>
</div>
