import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"

function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key].toLowerCase()]=i;
        }
        else{
       newObj[objR[i].toLowerCase()]=i;
        }
    }
    return newObj;
}
export function addBlukCheklist(
    checkList,bpmObj,consequanceObj,serviceObj
    ) 
  {
      bpmObj=resversKey(bpmObj,"name");
      consequanceObj=resversKey(consequanceObj,"NAME");
      serviceObj=resversKey(serviceObj,"NAME");
    let empId=parseInt(getCookie(userid));
    let userObjList = checkList.map((d) => {
        let obj = {
            id:0,
            description:d["Checklist Name"],
            bpmid:bpmObj[d["Contractor/Vendor Name"]?.toLowerCase()],
            ticketConsequenceTypeId:consequanceObj[d["Consequences Category"]?.toLowerCase()],
            serviceTypeId:serviceObj[d["Service Type"]?.toLowerCase()],
            createdDate:new Date(),
            createdBy:empId,
            userId:empId,
            modifiedDate:new Date(),
            modifiedBy:empId 
        }
      return obj;
    })
    return userObjList;
}