class  assetBpms {
    id: number;
    assetId: number;
    bpmregisterId: number
}

class  assetLocations{
    id: number;
    assetId: number;
    facilityId: number;
    blockId: number;
    levelId: number;
    areaId: number;
    locationId: number;
    subLocationId: number;
    assets: [
      string
    ]
}

class  assetInfos{
    id: number;
    assetId: number;
    commissioningDate:Date;
    serviceStartDate:Date;
    serviceEndDate:Date;
    lifeSpan: number;
    statusId: number;
    assetAge: string;
    yearInService: string;
    operatingHours: string
}

class  assetMaintenances{
        id: number;
        assetId: number;
        ppmflag: true;
        ppmcreated: true;
        di: true;
        lastScheduledWorkOrderNo: string;
        lastUnScheduledWorkOrderNo: string;
        lastScheduledWorkOrderDate: Date;
        lastUnScheduledWorkOrderDate: Date;
        totalDownTime: string;
        cumulativepartsCost: number;
        cumulativeLabourCost: number;
        cumulativeContractCost: number;
        warrantyStartDate: Date;
        warrantyEndDate: Date;
        warrantyDurationInMonths: number
}

export class GenerateQrCodeForAsset {
    
        id: number;
        assetStandardizationId: number=0;
        assetNumber: string=null;
        assetCode: string=null;
        serialNo: string=null;
        chasisNo: string=null;
        engineNo: string =null;
        contractTypeId: number=0;
        createdBy: number=0;
        createdDate:Date;
        modifiedBy: number;
        modifiedDate:Date;
        moduleHierarchyId: number;
        assetBpms:Array<assetBpms>;
        assetInfos:Array<assetInfos>;
        assetLocations:Array< assetLocations>;
        assetMaintenances: Array< assetMaintenances>;
      
}

class   locationSubLocations{
        nameCreatedBy: string;
        nameModifiedBy: string;
        id: number;
        name: string;
        statusId: number;
        facilityId: number;
        blockId: number;
        blockLevelId: number;
        levelAreaId: number;
        areaLocationId: number;
        createdBy: number;
        createdDate: Date;
        modifiedBy: number;
        modifiedDate: Date
}

export class GenerateQrCodeForLocation{
    
        nameCreatedBy: string = null;
        nameModifiedBy: string = null;
        id: number =null;
        name: string=null;
        code: string=null;
        shortName: string=null;
        statusId: number=null;
        facilityId: number=null;
        blockId: number =null;
        blockLevelId: number =null;
        levelAreaId: number=null;
        createdBy: number=null;
        createdDate: Date;
        modifiedBy: number=null;
        modifiedDate: Date;
        startServiceDate: Date;
        stopServiceDate: Date;
        locationIncharge: number=null;
        companyRepresentative: number=null;
        moduleHierarchyId: number=null;
        locationSubLocations:  Array<locationSubLocations>
      }
      
