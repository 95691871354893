import { Component, OnInit } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {SubService,SubServiceObj} from "src/ApiModel/Model/SubService"
import { checklistSlaMapping } from 'src/app/models/checklist-sla';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';

@Component({
  selector: 'app-sla-mapping-for-checklist',
  templateUrl: './sla-mapping-for-checklist.component.html',
  styleUrls: ['./sla-mapping-for-checklist.component.scss']
})
export class SlaMappingForChecklistComponent implements OnInit {

  constructor() { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  public tempData:any={}
  public opened=false;
  public serviceObj ={}
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public data=new SubService();
  public gridData=[]
  public serviceDropDwon =[]
  public subServiceObj:checklistSlaMapping = new checklistSlaMapping()
  public task:any=[]
  public column=[
    {field:"serviceType",title:"Service",isFilter:true,width:200},
    {field:"DESCRIPTION",title:"Description", isFilter:true,width:200},
    {field:"RESPONDSLADURINGWORKINGHOURS",title:"Respond SLA During Working Hour", isFilter:true,width:200},
    {field:"RESPONDSLAAFTERWORKINGHOURS",title:"Respond SLA After Working Hour", isFilter:true,width:200},
    {field:"COMPLETIONSLADURINGWORKINGHOURS",title:"Completion SLA During Working Hour", isFilter:true,width:200},
    {field:"COMPLETIONSLAAFTERWORKINGHOURS",title:"Completion SLA After Working Hour", isFilter:true,width:200},
  
    {field:"CLOSESLA",title:"Close SLA", isFilter:true,width:200},]
  ngOnInit(): void {
    this.getSeriveType("dbo","ServicesType").then((e)=>{
      this.getData("pmc","SubService")
    });

  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
  }
  
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
  }
  // saveData(){
  //   if(this.type==="new"){
  //      this.data.saveData(this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
  //      .then((result)=>{
  //           console.log("save",result)
  //           this.getData()
  //           this.opened=false;
  //      })
  //      .catch((err)=>{
  //         console.log("err",err)
  //      })
  //   }
  //   else if(this.type=="update"){
  //     this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
  //     .then((result)=>{
  //          console.log("save",result)
  //          this.getData()
  //          this.opened=false;
  //     })
  //     .catch((err)=>{
  //        console.log("err",err)
  //     })
  //   }
  // }
  reset(){

  }

  // getData(){
  //   this.subServiceObj.getData()
  //   .then((result)=>{
  //     this.task=this.subServiceObj.alldata.map((d)=>{
  //       d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
  //       return d;
  //      })
  //   })
  // }
  editColumn(d){
     this.data.ID=d.dataItem.ID;
     this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
     this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    
 }
saveData(){

}


getSeriveType(schema,tablename){
  return new Promise((resolve, reject) => {
    HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
      .then((result: any) => {
        let skillData = [];
        skillData = JSON.parse(result.gridData);
         this.serviceDropDwon = skillData
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      })
  })
}



saveCheckData() {
  // this.error1 = SparePartReturnValidation(this.sparePartReturnForm,"")
  // if(!this.error1.RETURNDATE && !this.error1.SPAREPARTREQUESTID){
  //   if (this.type == "new") {
  //     this.sparePartReturnForm.ID = 0;
  //   }
     if (this.type == "new") {
      this.subServiceObj.CREATEDBY = parseInt(getCookie(userid));
      this.subServiceObj.MODIFIEDBY = parseInt(getCookie(userid));
      this.subServiceObj.CREATEDDATE =new Date()
      this.subServiceObj.MODIFIEDDATE =new Date()
      this.subServiceObj.ID= 0
    }

    this.saveSparePartUpdate(JSON.stringify(this.subServiceObj));
}

saveSparePartUpdate(data) {
  let obj = {
    schemaName: "pmc",
    tableName: "SubService",
    userId: getCookie(userid),
    newData: data,
  };
  let path = "";
  if (this.type === "new") {
    path = `/api/MasterData/SaveLookupData`;
  } else if (this.type == "update") {
    path = "/api/MasterData/UpdateLookupData";
  }
  HttpClient.fetchData(path, "post", obj, true, true)
    .then((result: any) => {
      console.log(result);
      if (result.isValid == true) {
        this.opened = false;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

bindServiceObj(result) {
  this.serviceObj = result.reduce((acc, d) => {
    acc[d.ID] = d.NAME;
    return acc;
  }, {});
}


getData(schema,tablename){
  return new Promise((resolve, reject) => {
    HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true,true)
      .then((result: any) => {
        let checklistData = [];
        checklistData = JSON.parse(result.gridData);
        checklistData = shiftLastDataInto1st(checklistData)||[];
        this.bindServiceObj(this.serviceDropDwon);
       
        this.gridData = checklistData.map((e) => {
           e["serviceType"] = this.serviceObj[e.SERVICETYPEID]
          return e;
        });
        console.log(result);
        resolve(checklistData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
}
