import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EditorResizableOptions } from "@progress/kendo-angular-editor";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule";
import HttpClient from "src/Util/ApiHandling";
import { NotificationService } from "@progress/kendo-angular-notification";
import { getCookie,shiftLastDataInto1st } from "src/Util/Util";
import { pading,convertDateFormate } from 'src/ApiModel/Util';
import { BLOCK_MARKER } from '@angular/localize/src/utils';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
public SelectPlatformData:any=[];
  public actionsLayout = "normal";
  public formGroup: FormGroup;
  public permission:Permission=new Permission();
  constructor(private _notif: NotificationService,private datePipe: DatePipe) { }
  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 150,
    maxWidth: 600,
    maxHeight: 450,
  };
  public format = "dd/MM/yyyy";
  public gridData = [];
  public column = [
    {field :"date",title:"DATE",isFilter: true, width:160},
    { field: "time", title: "TIME", isFilter: true, width:140 },
    { field: "userId", title: "ACTOR", isFilter: true, width:140 },
    { field: "module", title: "MODULE", isFilter: true, width:140 },
    { field: "action", title: "ACTION", isFilter: true, width:140 },
    { field: "description", title: "REFERENCES", isFilter: true, width:140 },
    { field: "ipaddress", title: "IP ADDRESS", isFilter: true , width:160},
    
  ];
  ngOnInit(): void {
    if(UserModuleObj.level)
    this.permission=UserModuleObj.level;
    this.formGroup = new FormGroup({
      StartPeriod: new FormControl(new Date()),
      EndPeriod: new FormControl(new Date()),
      Actor:new FormControl(0),
    });   
   this.getAllUser();
  }
  getdata()
  {
    HttpClient.get(
      `/api/MasterData/GetAuditLogs`,
      true,
      true
    ).then((result: any) => {
      let BlockData = [];
      BlockData = shiftLastDataInto1st(result)||[];
     // this.gridData =BlockData;
      this.gridData=BlockData.map((d)=>{ 
        let userdata=this.SelectPlatformData.filter((d1)=>d1.id==d.userId);
        d["userId"]= userdata.length>0?userdata[0].staffName:'';
        d["date"]=this.dateFormating(d.date);
        d["time"]=this.convertTimeFormat(d.time);
        return d;
       })
    });
  }
  convertTimeFormat(date) {
    let tArray=date.split(":").map((t)=>parseInt(t));
    return tArray[0] + ":" +tArray[1] ;
  }
  
  dateFormating(da){
    let date=new Date(da);
    return this.datePipe.transform(date, 'dd-MM-yy');
  }


  getAllUser() {
    HttpClient.get("/api/Users/GetAllUser", true, true)
      .then((result: any) => {
       this.SelectPlatformData=result;
       this.getdata();
      });
    }
    fetchReport()
    {
      let startDate=this.convertDate(this.formGroup.get("StartPeriod").value);
      let endDate=this.convertDate(this.formGroup.get("EndPeriod").value);
      let id=this.formGroup.get("Actor").value;
        HttpClient.get(
          `/api/MasterData/GetAuditLogs?startDate=${startDate}&&endDate=${endDate}&&userId=${id}`,
          true,
          true
        ).then((result: any) => {
          let BlockData = [];
          BlockData = shiftLastDataInto1st(result)||[];
         // this.gridData =BlockData;
          this.gridData=BlockData.map((d)=>{ 
            let userdata=this.SelectPlatformData.filter((d1)=>d1.id==d.userId);
            d["userId"]= userdata.length>0?userdata[0].staffName:'';
            d["date"]=this.dateFormating(d.date);
            d["time"]=this.convertTimeFormat(d.time);
            return d;
           })
        });
    }
     convertDate(dateToBeConverted) {
      let dateToBeConvert =new Date(dateToBeConverted);
      return this.datePipe.transform(dateToBeConvert, "YYYY-MM-d HH:mm:ss");
      }
      reset()
      {
        this.formGroup.reset();
        this.formGroup = new FormGroup({
          StartPeriod: new FormControl(new Date()),
          EndPeriod: new FormControl(new Date()),
          Actor:new FormControl(0),
        });  
        this.getdata();
      }
}
