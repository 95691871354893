
<div class="card m-3"  >
    <div class="card-header" >
        <h5> Warranty Coverage </h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" *ngIf="permission && permission.isCreate" class="header-right-btn">
               Add Warranty Coverage
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
       
        <app-table-grid
        [data]="gridData"
        [column]="gridColumn"
        [size]="5"
        [action] = "false"
    ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div  >
        <div class="form-row">
            <div class="form-group col-5">
                <label>Warranty Start Date</label>
                <kendo-datepicker [format]="format"  [(ngModel)]="startDate" (valueChange)="getDurationValue($event)">
                </kendo-datepicker>
            </div>
            <div class="form-group col-5">
                <label>Warranty End Date</label>
                <kendo-datepicker [format]="format" [(ngModel)]="endDate" (valueChange)="getDurationValue($event)">
                </kendo-datepicker>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Warranty Duration(Month)</label> 
                <input kendoTextBox class="form-control" [(ngModel)]="monthDiffValue" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Contractor / Vendor Registration No.</label>
                <kendo-dropdownlist  [data]="contractorVendDropDown" [(ngModel)]="registrationNo"   [textField]="'registrationNo'"
                [valueField]="'id'"
                [valuePrimitive]="true" class="form-control" (valueChange)="contactorVenValue($event)"   class="form-control">
                </kendo-dropdownlist>
            </div> 
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Contractor / Vendor Name</label> 
                <input kendoTextBox class="form-control" [(ngModel)]="contVenName" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Fax No</label> 
                <input kendoTextBox class="form-control" [(ngModel)]="faxNo" disabled/>
            </div> 
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Address</label> 
                <input kendoTextBox class="form-control" [(ngModel)]="address" disabled/>
            </div>
            <div class="form-group col-5">
            </div> 
        </div>
        <div class="table-responsive" >
            <table class="spare-part-table">
              <tr>
                <th>Contact Person</th>
                <th>Telephone No.</th>
                <th>Email</th>
              </tr>
             <tr *ngFor="let item of contactPersonData"> 
              
  
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.name" disabled/></td>
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.contactNo" disabled/></td>
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.email " disabled/></td>
               </tr> 
          </table>
        </div>
        <!-- <div class="form-row">
            <div class="form-group col-5">
                <label>Contractor / Vendor Name</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Contact Person</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div> -->
        <!-- <div class="form-row">
            <div class="form-group col-5">
                <label>Telephone No.</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Email</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div> -->
        <!-- <div class="form-row">
            <div class="form-group col-5">
                <label>Fax No.</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Address</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div> -->
        
        
    </div>
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true" (click)="saveWarranty()">
       Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
