import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
import { environment } from "src/environments/environment";
export class Attechment{ 
    ID:number;
    EMPLOYEEGLOBALID:number=1;
    MODULEHIERARCHYID:number;
    REFERENCEID:number;
    URL:string;
    FILENAME:string;
    FILETYPE:string;
    FILECONTENTS:string;
    CREATEDON:Date;
   async  saveData(){
        this.ID=0;
        this.CREATEDON=new Date();
        let obj=[{
            "id": 0,
            "employeeGlobalId": 1,
            "moduleHierarchyId": this.MODULEHIERARCHYID,
            "referenceId": this.REFERENCEID,
            "url": "",
            "fileName": this.FILENAME,
            "fileType": this.FILETYPE,
            "fileContents": this.FILECONTENTS,
            "createdOn":new Date()
          }]
       return HttpClient.fetchData("/api/MasterData/SaveAttachment","post",obj,true,true)

     //  return saveLookup(Schema.dbo,LOOKUPTABLE.attachment,this,"new")
    }	
   async deleteData(obj:any){
       return removeLookup(Schema.dbo,LOOKUPTABLE.attachment,obj);
    }
   async getAttechmentByRefrenceId(moduleId,refid){
      return new Promise((resolve,reject)=>{
          HttpClient.get("/api/MasterData/GetAttachmentsByReferenceIdAndByModuleHierarchyId?moduleHierarchyId="+moduleId+"&referenceId="+refid,true,true)
          .then((result:any)=>{
              if(result && result.length>0){
                  let obj=result[0];
            //  let obj={
            //     "id": 523,
            //     "employeeGlobalId": 1,
            //     "moduleHierarchyId": 26,
            //     "referenceId": 285,
            //     "url": "/Attachments/test.pdf",
            //     "fileName": "test",
            //     "fileType": "pdf",
            //     "fileContents": null,
            //     "createdOn": "2021-12-15T06:34:59.893"
            //   }
              this.ID=obj.id;
              this.MODULEHIERARCHYID=obj.moduleHierarchyId;
              this.FILECONTENTS=obj.fileContents;
              this.FILENAME=obj.fileName;
              this.FILETYPE=obj.fileType;
              this.REFERENCEID = refid;
            //   this.URL=environment.backendUrl + "/WebAPI" +obj.url;
            this.URL=environment.backendUrl +obj.url;
              this.FILECONTENTS=null;
            //   this.REFERENCEID=obj.referenceId;
              resolve(true)
              }
              else{
                  reject(false);
              }
          })
          .catch((err)=>{
             reject(true)
          })
      })
   }
}

export class AttechmentObj{
    alldata:Array<Attechment>=[]
    filterData:Array<Attechment>=[]
    selectedObj:Attechment=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Attechment)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(referenceId,moduleHierarchyId){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.attachment)
            .then((result:Array<Attechment>)=>{
                let temp=result.filter((d)=>d.REFERENCEID==referenceId && d.MODULEHIERARCHYID==moduleHierarchyId);
                this.alldata=temp;
                this.filterData=temp;
                resolve(true)
            })
            .catch((err)=>{
           
                reject(err)
            })
        })

    }
} 