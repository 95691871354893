<div class="card m-3"  >
    <div class="card-header" >
        <h5>Raise Work Request</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')"  *ngIf="permission && permission.isCreate" class="header-right-btn">
            Raise Work Request
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid 
        [data]="areagrid"
        [column]="column2"
        [size]="10"
        [action]="true"
        [deleteFlag]="true"
        [editFlag]="true"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose('main')" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           {{dailogTitle}}
        </div>
      </kendo-dialog-titlebar>
    <!-- <kendo-tabstrip (tabSelect)="onTabSelect($event)"> -->
        <!-- <kendo-tabstrip-tab  *ngIf="type==='new' || currentTab==workOrderType.assets.id" [title]="workOrderType.assets.title" [selected]="currentTab==workOrderType.assets.id">
          <ng-template kendoTabContent >
                <div class="py-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Request No</label>
                            <input kendoTextBox class="form-control" [value]="tempUpdateObj['workOrderNo']" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Assets<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="assetDataObj.filterData" 
                                [value]="assetDataObj.selectedObj" 
                                (valueChange)="valuechange('assets',$event)" 
                                class="form-control"
                                [textField]="'serialNo'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Description<span class="required">*</span></label>
                            <input [(ngModel)]="description" kendoTextBox  class="form-control" />
                        </div>
                        <div class="form-group col-5">
                            <label>Service Type<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="serviceObj.alldata" 
                                [value]="serviceObj.selectedObj" 
                                (valueChange)="valuechange('service',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Work Order Type<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="workOrderTypeObj.filterData" 
                                [value]="workOrderTypeObj.selectedObj" 
                                (valueChange)="valuechange('workOrderType',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Consequences Category<span class="required">*</span></label>
                                <kendo-dropdownlist  
                                [data]="consequanceObj.filterData" 
                                [value]="consequanceObj.selectedObj" 
                                (valueChange)="valuechange('consequences',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Status<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="ticketStatusObj.filterData" 
                                [value]="ticketStatusObj.selectedObj" 
                                (valueChange)="valuechange('ticketStatus',$event)" 
                                class="form-control"
                                [textField]="'NAME'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Chargeable<span class="required">*</span></label>
                            <kendo-dropdownlist  
                                [data]="chargeAbleObj.filterData" 
                                [value]="chargeAbleObj.selectedObj" 
                                (valueChange)="valuechange('chargeable',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assignee</label>
                            <kendo-dropdownlist  
                                [data]="assigineeObj.filterData" 
                                [value]="assigineeObj.selectedObj" 
                                (valueChange)="valuechange('assignee',$event)" 
                                class="form-control"
                                [textField]="'staffName'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Assign To</label>
                            <kendo-dropdownlist  
                                [data]="assignToObj.filterData" 
                                [value]="assignToObj.selectedObj" 
                                (valueChange)="valuechange('assignTo',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                            Save
                        </button>
                        <button kendoButton *ngIf="type=='new'"  primary="true" class="px-3" (click)="reset()">
                            Reset
                        </button>
                    </div>
                
                </div>
              </ng-template>
         </kendo-tabstrip-tab> -->
         <!-- <kendo-tabstrip-tab [title]="workOrderType.location.title"> -->
            <!-- <ng-template kendoTabContent > -->
                  <div class="py-4">
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Request No</label>
                              <input kendoTextBox [value]="tempUpdateObj['requestNo']" class="form-control" disabled/>
                          </div>
                          <div class="form-group col-5">
                              <label>Facility<span class="required">*</span></label>
                              <div style="width: 100%;">
                              <kendo-dropdownlist  
                                  [data]="facilityObj.filterData" 
                                  [value]="facilityObj.selectedObj" 
                                  (valueChange)="valuechange('facility',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.facility">*Required</kendo-formerror>
                              </div>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Block Code<span class="required">*</span></label> 
                              <div style="width: 100%;">
                              <kendo-dropdownlist  
                                  [data]="blockObj.filterData" 
                                  [value]="blockObj.selectedObj" 
                                  (valueChange)="valuechange('block',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.block">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Block Name</label>
                              <input kendoTextBox class="form-control" [value]="blockObj.selectedObj && blockObj.selectedObj.NAME" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Level Code<span class="required">*</span></label>
                              <div style="width: 100%;">
                              <kendo-dropdownlist  
                                [data]="levelObj.filterData" 
                                [value]="levelObj.selectedObj" 
                                (valueChange)="valuechange('level',$event)" 
                                class="form-control"
                                [textField]="'CODE'" 
                                [valueField]="'ID'" 
                                [disabled]="isUpdate"
                             >
                             </kendo-dropdownlist>
                             <kendo-formerror *ngIf="error.level">*Required</kendo-formerror>
                             </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Level Name</label>
                              <input kendoTextBox class="form-control" [value]="levelObj.selectedObj && levelObj.selectedObj.NAME" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Area Code<span class="required">*</span></label>
                              <div style="width: 100%;">
                              <kendo-dropdownlist  
                                  [data]="areaObj.filterData" 
                                  [value]="areaObj.selectedObj" 
                                  (valueChange)="valuechange('area',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.area">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Area Name</label>
                              <input kendoTextBox [value]="areaObj.selectedObj && areaObj.selectedObj.NAME" class="form-control" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Location Code<span class="required">*</span></label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="locationObj.filterData" 
                                  [value]="locationObj.selectedObj" 
                                  (valueChange)="valuechange('location',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.referenceId">*Required</kendo-formerror>
                              </div>
                          </div>
                           <div class="form-group col-5">
                              <label>Location Name</label>
                              <input kendoTextBox [value]="locationObj.selectedObj && locationObj.selectedObj.NAME" class="form-control" disabled/>
                          </div>
                      </div>
  
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Description<span class="required">*</span></label>
                              <div style="width: 100%">
                              <!-- <input kendoTextBox [(ngModel)]="description" [disabled]="isUpdate" class="form-control" /> -->
                              <kendo-textarea  [(ngModel)]="description" class="form-control" [rows]="5" ></kendo-textarea>
                              
                                <kendo-formerror *ngIf="error.description">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Service Type<span class="required">*</span></label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="serviceObj.alldata" 
                                  [value]="serviceObj.selectedObj" 
                                  (valueChange)="valuechange('service',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.serviceTypeId">*Required</kendo-formerror>
                              </div>
                          </div>
                      </div>
                      <!-- <div class="form-row">
                          <div class="form-group col-5">
                              <label>Work Order Type<span class="required">*</span></label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="workOrderTypeObj.filterData" 
                                  [value]="workOrderTypeObj.selectedObj" 
                                  (valueChange)="valuechange('workOrderType',$event)" 
                                  class="form-control"
                                  [textField]="'name'" 
                                  [valueField]="'id'" 
                              >
                              </kendo-dropdownlist>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Consequences Category<span class="required">*</span></label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="consequanceObj.filterData" 
                                  [value]="consequanceObj.selectedObj" 
                                  (valueChange)="valuechange('consequences',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.ticketConsequenceId">*Required</kendo-formerror>
                            </div>
                          </div>
                      </div> -->
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Upload Attachment<span class="required">*</span></label>
                              <div style="width: 100%">
                                <app-file-upload
                                type="img" 
                                [formate]="['jpg','png']"
                                [url]="fileData" 
                                (valueChange)="fileData=$event"
                                (extChange)="extentionFile=$event" 
                                style="width: 100%;"
                                 >
                               </app-file-upload>
                              <kendo-formerror *ngIf="error.defactPhoto">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Chargeable<span class="required">*</span></label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="chargeAbleObj.filterData" 
                                  [value]="chargeAbleObj.selectedObj" 
                                  (valueChange)="valuechange('chargeable',$event)" 
                                  class="form-control"
                                  [textField]="'name'" 
                                  [valueField]="'id'" 
                                  [disabled]="isUpdate"
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.chargable">*Required</kendo-formerror>
                              </div>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Assignee</label>
                              <div style="width: 100%">
                                    <kendo-dropdownlist  
                                        [data]="assigineeObj.filterData" 
                                        [value]="assigineeObj.selectedObj" 
                                        (valueChange)="valuechange('assignee',$event)" 
                                        class="form-control"
                                        [textField]="'staffName'" 
                                        [valueField]="'id'" 
                                        [disabled]="isUpdate"
                                    >
                                    </kendo-dropdownlist>
                                    <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Assign To</label>
                              <div style="width: 100%">
                              <kendo-dropdownlist  
                                  [data]="assignToObj.filterData" 
                                  [value]="assignToObj.selectedObj" 
                                  (valueChange)="valuechange('assignTo',$event)" 
                                  class="form-control"
                                  [textField]="'name'" 
                                  [valueField]="'id'" 
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.bpmid">*Required</kendo-formerror>
                              </div>
                          </div>
                      </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                          <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button kendoButton *ngIf="type=='new'"  primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                      </div>
                  
                    </div>
                <!-- </ng-template> -->
           <!-- </kendo-tabstrip-tab> -->
     <!-- </kendo-tabstrip> -->
</kendo-dialog>

<kendo-dialog *ngIf="attachmentOpened" (close)="onDialogClose('attachment')" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist 
                    [data]="documentFormate" 
                    class="form-control" 
                    [textField]="'name'" 
                    [valueField]="'id'"
                    [(ngModel)]="attechForm.FILETYPE"
                    [valuePrimitive]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <!-- <kendo-formerror *ngIf="attechError.FILENAME">*required</kendo-formerror> -->

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Attachment</label>
                <div style="width: 100%;">
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
              <!-- <kendo-formerror *ngIf="attechError.URL">*required</kendo-formerror> -->
              </div>
            </div>

            <div class="form-group col-5">
                <button  (click)="addAttechment()" kendoButton primary="true">
                    Add
                </button>
            </div>


        </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('attachment')">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid 
         [data]="attechmentDoc.alldata" 
         [column]="attechmentColumn" 
         [size]="5"
         >
   </app-attachment-table-grid>

</kendo-dialog>

<kendo-dialog *ngIf="viewOpen" (close)="viewOpen=false" [width]="800">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        View Spare Part
      </div>
    </kendo-dialog-titlebar>
    <div class="card-body register-form-body">
        <app-table-grid 
            [data]="spareGridData"
            [column]="sparePartColumn"
            [size]="5"
            [action]="false"
        >
        </app-table-grid>
    </div>
  </kendo-dialog>