<div class="py-4">
    <!--{{workorder|json}}
     =======================================
    {{user|json}} -->
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Work Order No.</strong></label>
            <label>{{workorder && workorder['workOrderNo']}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Start Date/Time</strong></label>
            <label>{{respondSlaStart | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Chargeable </strong></label>
            <label>{{chargeable.selectedObj && chargeable.selectedObj.name}} </label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>End Date/Time</strong></label>
            <label>{{workorder.closingDateTime | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Completed By</strong></label>
            <label>{{workorder.completedBy && getStaffName(workorder.completedBy)}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Closed By </strong></label>
            <label>{{workorder.closedBy && getStaffName(workorder.closedBy)}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>WO Status</strong></label>
            <label>{{woStatus.selectedObj && woStatus.selectedObj.NAME}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Respond SLA Started</strong></label>
            <label>{{respondSlaStart | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Respond SLA Recorded</strong></label>
            <label>{{respondSlaEnd | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Completion SLA Record Start</strong></label>
            <label>{{completionSlaStart | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Completion SLA Record Stop</strong></label>
            <label>{{completionSlaEnd | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Waiting Time Start</strong></label>
            <label>{{waitingTimeStart}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Waiting Time End</strong></label>
            <label>{{waitingTimeEnd}}</label>
        </div>
        <div class="form-group mb-0 col-5">
            <label><strong>Net Waiting Time</strong></label>
            <label>{{netwaitingTime}}</label>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group mb-0 col-5">
            <label><strong>Follow Up Reason</strong></label>
            <label>{{followReasonId && followReasonId.NAME}}</label>
        </div>
        <div class="form-group mb-0 col-5">

            <label><strong>Change WO Status</strong></label>
            <label>{{workorder.ticketStatusObj && workorder.ticketStatusObj.NAME}}</label>
        </div>
    </div>