import { Component, OnInit, Input } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { process, State } from "@progress/kendo-data-query";
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"

@Component({
  selector: 'app-license-list-grid',
  templateUrl: './license-certi.component.html',
  styleUrls: ['./license-certi.component.scss']
})
export class LicenseCertificateGridComponent implements OnInit {

  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() deleteFlag:Boolean=false;
  @Input() editFlag:Boolean=false;
 
  public gridData:GridDataResult;

  public value: Date = new Date(2000, 2, 10);


  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.deleteFlag){
      this.deleteFlag=changes.deleteFlag.currentValue
    }
    if(changes.editFlag){
      this.editFlag=changes.editFlag.currentValue
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }
  ngOnInit(): void {
  }
  editHandler(e){
    console.log("edit",e)
    dispatchEvent(EVENT_NAME.edit_column,e);
  }
  removeHandler(e){
    dispatchEvent(EVENT_NAME.remove_column,e);
  }
  
  viewColumn(e){
    dispatchEvent(EVENT_NAME.view_column,e);
  }

}
