import { Component, OnInit } from '@angular/core';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"
import { environment } from 'src/environments/environment';
import {saveLookup} from "src/ApiModel/Util"
import { WorkOrderError } from 'src/ApiModel/Model/WorkOrder';
import {convertDateFormate} from "src/ApiModel/Util";
import {ModelApi,PLANNER} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import { getQueryParams } from 'src/Util/Util';

@Component({
  selector: 'app-work-order-asset',
  templateUrl: './work-order-asset.component.html',
  styleUrls: ['./work-order-asset.component.scss']
})
export class WorkOrderAssetComponent implements OnInit {
public disabled=true;
//attechment data
public viewOpen=false;
public actionsLayout = "normal";
public attechmentDoc:AttechmentObj=new AttechmentObj();
public attechForm:Attechment=new Attechment();
public parentId=-1;
public moduleId=-1;
public attechId=-1;
public error:WorkOrderError=new WorkOrderError()
//model data
public removeDialogOpen=false;
public opened=false;  
public currentTab=1;
public dailogTitle="add work order";
public type=""
public tempUpdateObj:any={}
public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
public queryWoId=null;
public filters=[]
public workOrderType={
  assets:{name:"asset",id:1,title:"Assets"},
  location:{name:"location",id:2,title:"Location"}
}
// grid column filed 
public columnField={
  work_order_no:"workOrderNo",
  consequance:"ticketConsequence.name",
  asset:"asset",
  location:"location",
  service:"service",
} 
//grid data 
public column2=[
{field:"workOrderNo",title:"Work Order No",isFilter:true,width:200},
{field:"modifiedDate_formate",title:"Work Order Date", isFilter:true,width:200},
{field:"workOrderTypeObj.name",title:"Work Order Type", isFilter:true,width:200},
{field:"consequanceObj.NAME",title:"Consequence  Category", isFilter:true,width:200},
{field:"serviceObj.NAME",title:"Service Type", isFilter:true,width:200},
{field:"assetDataObj.assetNumber",title:"Asset No.", isFilter:true,width:200},
{field:"locationObj.NAME",title:"Location of Asset", isFilter:true,width:200},
{field:"assetTypeObj.name",title:"Asset Description", isFilter:true,width:200},
{field:"assetModelObj.name",title:"Model", isFilter:true,width:200},
{field:"assetManufectureObj.name",title:"Manufacturer", isFilter:true,width:200},
{field:"contractObj.name",title:"Contract Type", isFilter:true,width:200},
{field:"assigneeObj.staffName",title:"Assignee", isFilter:true,width:200},
{field:"assignToObj.name",title:"Assign To", isFilter:true,width:200},
{field:"ticketStatusObj.NAME",title:"Status", isFilter:true,width:200}
];
public areagrid=[

];
public sparePartColumn=[
{field:"partNo",title:"Part No.",isFilter:true,width:150},
  {field:"partDescription",title:"Part Description ", isFilter:true,width:150},
  {field:"binNo",title:"Bin No.", isFilter:true,width:150},
  {field:"qoh",title:"Quantity on hand (QOH)", isFilter:true,width:150},
  {field:"facilityObj.NAME",title:"Facility Name", isFilter:true,width:150},
  {field:"facilityObj.FACILITYCODE",title:"Facility Code", isFilter:true,width:150}
];
public spareGridData=[]
///form  data
public facilityObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
}
public blockObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public levelObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public areaObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public locationObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public serviceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public assetDataObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public ticketStatusObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public assigineeObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public assignToObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public chargeAbleObj={
  alldata:[],
  filterData:[
    {id:1,name:"Yes"},
    {id:2,name:"No"}
  ],
  selectedObj:null,
  objectData:{}
}

public workOrderTypeObj={
  alldata:[],
  filterData:[
     {id:1,name:"Routine Maintenance"},
     {id:2,name:"Corrective Maintenance"},
     {id:3,name:"Reactive Maintenance"}
  ],
  selectedObj:null,
  objectData:{}
}
public consequanceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public description="";
public attechmentColumn = [
  { field: "FILETYPE", title: "File Type", isFilter: true },
  { field: "FILENAME", title: "File Name", isFilter: true }
]
public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
public attachmentOpened: boolean=false;
public model:Model=new Model()
constructor() { 
  eventListner(EVENT_NAME.attachment_column, (e) => { this.attachementColumn(e) })
  eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
  eventListner(EVENT_NAME.remove_column,(e)=>{this.deleteColumn(e)});
  eventListner(EVENT_NAME.delete_attechment,(e)=>{
    this.deleteAttechment(e);
 })
 eventListner(EVENT_NAME.download_attechment,(e)=>{
   this.downloadAttechment(e)
 })
 eventListner(EVENT_NAME.view_column,(e)=>{this.viewColumn(e)});
}
asset_classi = ['Select'];
attachementColumn(data:any){
  this.attechForm.ID=0;
  this.attechForm.FILETYPE="pdf";
  this.attechForm.FILENAME=""
  this.attechForm.URL=""
  this.attechForm.MODULEHIERARCHYID=this.moduleId;
  this.attechForm.REFERENCEID=data.id;


this.attechError={URL:false,FILENAME:false}

// this.attechForm.EMPLOYEEGLOBALID=data.id;
this.attechId=data.id;
this.attechmentDoc=new AttechmentObj();
this.attechmentDoc.getData(data.id,this.moduleId)
.then((result:any)=>{
this.attachmentOpened = true;
this.dailogTitle = "Add Attachment"
})
.catch((err)=>{
 this.attechmentDoc.alldata=[]
})
}
downloadAttechment(data){
  window.open(environment.IMG_LOAD_SERVER+data.url)
}
deleteAttechment(d:any){
   this.attechForm.deleteData(d.dataItem)
   .then((result)=>{
    this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d1,i)=>i!==d.rowIndex);
   })
   .catch((err)=>{
     console.log(err)
   })
}
viewColumn(data){
  this.viewOpen=true;
  this.spareGridData=[];
  this.getSparePartRequest(data.id)
  .then((result:any)=>{
    this.spareGridData=result.map((d)=>{
          d["facilityObj"]=this.facilityObj.objectData[d.facilityId];
          return d;
    })
    console.log("view =>",this.spareGridData);
  })
  .catch((err)=>{
    console.log(err)
  })
}
ngOnInit(): void {
  this.queryWoId=getQueryParams("wo");
   if(this.queryWoId)
       this.filters=[{ field: "workOrderNo", operator: "contains", value: this.queryWoId }]
    
  Promise.all([
    this.getLoockup("dbo", "facility"),
    this.getLoockup("dbo", "block"),
    this.getLoockup("dbo", "blocklevel"),
    this.getLoockup("dbo", "levelarea"),
    this.getLoockup("dbo","ServicesType"),
    this.getAsset(),
    this.getAssignTo(),
    this.getAssignne(),
    this.getLoockup("PMC","TicketConsequenceType"),
    this.getLoockup("dbo", "arealocation"),
    this.getLoockup("pmc","ticketstatus")
  ])
  // this.getLoockup("dbo", "facility")
  .then((result:any)=>{
    //facility
    this.getModuleHierarchYdata("dbo","moduleHierarchy"),
       this.facilityObj.alldata=result[0];
       this.facilityObj.filterData=result[0];
       this.facilityObj.selectedObj={}
       this.facilityObj.objectData=this.facilityObj.alldata.reduce((acc,d)=>{
                  acc[d.ID]=d;
                  return acc;
       },{})
    //block
        this.blockObj.objectData=result[1].reduce((acc,d)=>{
          acc[d.ID]=d;
          return acc;
      },{})
          this.blockObj.alldata=result[1];
    //blocklevel
    this.levelObj.objectData=result[2].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.levelObj.alldata=result[2];

    //arealocation
    this.areaObj.objectData=result[3].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.areaObj.alldata=result[3];
    //ServicesType

    this.serviceObj.objectData=result[4].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.serviceObj.alldata=result[4];
       this.serviceObj.filterData=[]


    //asset
    this.assetDataObj.objectData=result[5].reduce((acc,d)=>{
      acc[d.id]=d;
      return acc;
   },{})
    this.assetDataObj.alldata=result[5];
    this.assetDataObj.filterData=result[5];
   //assin to
   this.assignToObj.objectData=result[6].reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
   this.assignToObj.alldata=result[6]
   this.assignToObj.filterData=result[6];

   //assignee

   this.assigineeObj.objectData=result[7].reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
  this.assigineeObj.alldata=result[7];
  this.assigineeObj.filterData=result[7];
   //ticket consequance
   this.consequanceObj.objectData=result[8].reduce((acc,d)=>{
    acc[d.ID]=d;
    return acc;
 },{})
  this.consequanceObj.alldata=result[8];
  this.consequanceObj.filterData=[]
//level area
this.locationObj.objectData=result[9].reduce((acc,d)=>{
acc[d.ID]=d;
return acc;
},{})
 this.locationObj.alldata=result[9];
 console.log("start")
 console.log(this.levelObj,this.areaObj,this.locationObj)
 console.log("end")
this.ticketStatusObj.alldata=result[10];
this.ticketStatusObj.filterData=result[10]
this.ticketStatusObj.objectData=result[10].reduce((acc,d)=>{
            acc[d.ID]=d;
            return acc;
},{})
  //call work order
  this.getWorkOrder()
  })
  .catch((err)=>{
    console.log(err)
  })
  //this.getWorkOrder()
  this.workOrderTypeObj.objectData=this.workOrderTypeObj.filterData.reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
 this.chargeAbleObj.objectData=this.chargeAbleObj.filterData.reduce((acc,d)=>{
  acc[d.id]=d;
  return acc;
},{})
  this.model.getData(
       ModelApi.assetStardantion,
       ModelApi.AssetType,
       ModelApi.contractType,
       ModelApi.manufacture,
       ModelApi.assetStardantion,
       ModelApi.assetModel
       )
  .then((result)=>{
    console.log(this.model);
  })
}

getLoockup(schema, tablename){
  return new Promise((resolve,reject)=>{
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
     })
     .catch((err)=>{
        reject(err)
     })
  })
}

getAsset(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Assets/GetAssets",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}

getAssignTo(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/BPM/GetBpms",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}

getAssignne(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Users/GetAllUser",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}


valuechange(type,data){
  if(type=='facility'){
    this.facilityObj.selectedObj=data
    this.blockObj.filterData=this.blockObj.alldata.filter((d)=>d.FACILITYID==data.ID)
    this.blockObj.selectedObj={};
    this.levelObj.selectedObj={};
    this.areaObj.selectedObj={};
    this.locationObj.selectedObj={};
    this.facilityObj={...this.facilityObj}      
  }
  else if(type=="block"){
      this.blockObj.selectedObj=data
      this.levelObj.filterData=this.levelObj.alldata.filter((d)=>d.BLOCKID==data.ID)
      this.levelObj.selectedObj={};
      this.areaObj.selectedObj={};
      this.locationObj.selectedObj={};
      this.blockObj={...this.blockObj}      
  }
  else if(type==="level"){
    this.levelObj.selectedObj=data
    this.areaObj.filterData=this.areaObj.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
    this.areaObj.selectedObj={};
    this.locationObj.selectedObj={};
    this.areaObj={...this.areaObj}      
  }
  else if(type==="area"){
    this.areaObj.selectedObj=data
    this.locationObj.filterData=this.locationObj.alldata.filter((d)=>d.LEVELAREAID==data.ID)
    this.locationObj.selectedObj={};
    this.locationObj={...this.locationObj}      
  }
  else if(type==="location"){
    this.locationObj.selectedObj=data
  }
  else if(type==="workOrderType"){
    this.workOrderTypeObj.selectedObj=data;
    this.consequanceObj.filterData=this.consequanceObj.alldata.filter((d)=>d.TICKETTYPEID==data.id)
  }
  else if(type==="consequences"){
    this.consequanceObj.selectedObj=data;
  }
  else if(type=="assignee"){
   this.assigineeObj.selectedObj=data;
  }
  else if(type=="assignTo"){
    this.assignToObj.selectedObj=data;
  }
  else if(type=="service"){
    this.serviceObj.selectedObj=data;
  }
  else if(type==="assets"){
    this.assetDataObj.selectedObj=data;
  }
  else if(type==="ticketStatus"){
    this.ticketStatusObj.selectedObj=data;
  }
}
saveData(){
  console.log("sfsdf",this.assigineeObj.selectedObj)
  this.error=new WorkOrderError();
  let obj={
    "id": 0,
    "userId": 1,
    "ticketStatusId": 1,
    "ticketConsequenceId": 0,
    "description": this.description,
    "serviceTypeId": 0,
    "referenceId": 0,
    "referenceType": "",
    "assignee": 0,
    "bpmid": 0,
    "createdDate": new Date(),
    "createdBy": 1,
    "modifiedDate": new Date(),
    "modifiedBy": 1
  }
  let error=false;
    if(this.locationObj.selectedObj && this.locationObj.selectedObj.ID){
      obj.referenceId=this.locationObj.selectedObj.ID;
      obj.referenceType=this.workOrderType.location.name;
    }
    else{
      error=true;
      this.error.referenceId=true
    }
  if(this.serviceObj.selectedObj && this.serviceObj.selectedObj.ID){
    obj.serviceTypeId=this.serviceObj.selectedObj.ID;
  }
  else{
    error=true;
    this.error.serviceTypeId=true;
  }
  if(this.consequanceObj.selectedObj && this.consequanceObj.selectedObj.ID){
     obj.ticketConsequenceId=this.consequanceObj.selectedObj.ID;
  }
  else{
    this.error.ticketConsequenceId=true;
    error=true;
  }
  if(this.assigineeObj.selectedObj && this.assigineeObj.selectedObj.id){
    obj.assignee=this.assigineeObj.selectedObj.id;
  }
  else{
    error=true;
    this.error.assignee=true;
  }
  if(this.assignToObj.selectedObj && this.assignToObj.selectedObj.id){
    obj.bpmid=this.assignToObj.selectedObj.id
  }
  else{
    error=true;
    this.error.bpmid=true;
  }
  if(this.ticketStatusObj.selectedObj && this.ticketStatusObj.selectedObj.ID){
    obj.ticketStatusId=this.ticketStatusObj.selectedObj.ID
  }
  else{
    error=true;
  }
  console.log(obj)
  if(!error){
      if(this.type==="new"){
        obj.id=0;
        obj["workOrderNo"]= new Date().getTime().toString()+""
        this.addWorkOrder(obj)
      }
      else if(this.type==="update"){
        obj.id=this.tempUpdateObj.id;
        this.updateWorkOrder(obj)
      }
  }
}


addWorkOrder(obj){
 HttpClient.fetchData("/api/WorkOrder/AddWorkOrder","post",obj,true,true)
 .then((result)=>{
   console.log(result)
   this.reset();
   this.opened=false;
   this.getWorkOrder()
 })
 .catch((err)=>{
   console.log(err)
 })
}

updateWorkOrder(obj){
 // HttpClient.fetchData("/api/WorkOrder/UpdateWorkOrder","post",obj,true,true)
   saveLookup("pmc","ticket",obj,"update")
  .then((result)=>{
    console.log(result)
    this.reset();
    this.opened=false;
    this.getWorkOrder()
  })
  .catch((err)=>{
    console.log(err)
  })
 }
onDialogClose(type){
  if (type == "main") {
    this.opened = false;
  }
  if (type == "attachment") {
    this.attachmentOpened = false
  }
  this.opened=false
}
onTabSelect(e){
  if(e.title===this.workOrderType.assets.title){
    this.currentTab=this.workOrderType.assets.id;
  }
  else if(e.title===this.workOrderType.assets.title){
    this.currentTab=this.workOrderType.location.id;
  }
}
openRoleDailog(type){
  this.type=type;
  if(type==='new'){
    this.currentTab=this.workOrderType.assets.id;
    this.opened=true
    this.reset()
  }
  else if(type==="viewFlag"){
    this.viewOpen=true
  }
}

reset(){
    this.tempUpdateObj={}
    this.facilityObj.selectedObj=null;
    this.blockObj.selectedObj=null;
    this.levelObj.selectedObj=null;
    this.areaObj.selectedObj=null;
    this.locationObj.selectedObj=null;
    this.serviceObj.selectedObj=null;
    this.workOrderTypeObj.selectedObj=null;
    this.consequanceObj.selectedObj=null;
    this.chargeAbleObj.selectedObj=null;
    this.assigineeObj.selectedObj=null;
    this.assignToObj.selectedObj=null;
    this.assetDataObj.selectedObj=null;
    this.description=""
} 

getWorkOrder(){
  HttpClient.get("/api/WorkOrder/GetWorkOrders",true,true)
  //this.workOrderData.getData()
  .then((result:any)=>{
     this.areagrid=result.filter((d)=>this.workOrderType.assets.name===d["referenceType"] && d.convertToWorkOrder != false).map((d)=>{ 
       d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
       d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
       d["serviceObj"]=this.serviceObj.objectData[d.serviceTypeId];
       d["consequanceObj"]=this.consequanceObj.objectData[d.ticketConsequenceId];
       d["ticketStatusObj"]=this.ticketStatusObj.objectData[d.ticketStatusId]
       if(d["consequanceObj"] && d["consequanceObj"]["TICKETTYPEID"]){
         d["workOrderTypeObj"]=this.workOrderTypeObj.objectData[d["consequanceObj"]["TICKETTYPEID"]]
       }
       if(this.workOrderType.assets.name===d["referenceType"]){
         let assetObj=this.assetDataObj.objectData[d["referenceId"]]
          d["assetDataObj"]=assetObj;
          if(assetObj?.assetLocations?.length>0){
            let loc=d["assetDataObj"].assetLocations[0];
            d["locationObj"]=this.locationObj.objectData[loc.locationId];
          }
          if(assetObj?.assetStandardizationId){
            try{
             let assetS=this.model.assetStardantion.objectData[assetObj.assetStandardizationId]
              d["assetTypeObj"]=this.model.assetType.objectData[assetS?.assetTypeId]
              d["assetModelObj"]=this.model.assetModel.objectData[assetS?.assetModelId]
              d["assetManufectureObj"]=this.model.assetType.objectData[assetS?.assetManufacturerId]
              d["contractObj"]=this.model.contractTYpe.objectData[assetObj.contractTypeId];
             }
             catch(err){
               console.log(err)
             }
           }
       }
       else{
        d["locationObj"]=this.locationObj.objectData[d["referenceId"]]
       }
       d["modifiedDate_formate"]=convertDateFormate(d["modifiedDate"])
       return d;
     });
     console.log(this.areagrid) 
  })
  .catch((err)=>{
     console.log(err)
  })
}
editColumn(d){
  let data=d.dataItem;
  this.tempUpdateObj=JSON.parse(JSON.stringify(d.dataItem));
  this.description=data["description"];
  this.assigineeObj.selectedObj=data["assigneeObj"]
  this.assignToObj.selectedObj=data["assignToObj"]
  this.serviceObj.selectedObj=data["serviceObj"]
  this.consequanceObj.selectedObj=data["consequanceObj"]
  this.workOrderTypeObj.selectedObj=data["workOrderTypeObj"]
  this.assetDataObj.selectedObj=data["assetDataObj"]
  this.locationObj.selectedObj=data["locationObj"]
  this.ticketStatusObj.selectedObj=data["ticketStatusObj"]
  if(this.workOrderType.assets.name===data["referenceType"]){
   // this.currentTab=this.workOrderType.assets.id;
   // d["assetDataObj"]=this.assetDataObj.objectData[d["referenceId"]]
 }
 else{
   let lob=data["locationObj"];
  //this.currentTab=this.workOrderType.location.id;
  this.facilityObj.selectedObj=this.facilityObj.objectData[lob.FACILITYID];
  this.blockObj.selectedObj=this.blockObj.objectData[lob.BLOCKID]
  this.levelObj.selectedObj=this.levelObj.objectData[lob.BLOCKLEVELID]
  this.areaObj.selectedObj=this.areaObj.objectData[lob.LEVELAREAID]
 // d["locationObj"]=this.locationObj.objectData[d["referenceId"]]
 }
//  this.valuechange("workOrderType",this.workOrderTypeObj.selectedObj);
//  this.valuechange("facility",this.facilityObj.selectedObj);
// //  this.valuechange("block",this..selectedObj);
// //  this.valuechange("level",this.workOrderTypeObj.selectedObj);
// //  this.valuechange("area",this.workOrderTypeObj.selectedObj);

  this.opened=true;
  this.dailogTitle="Update Work Order"
  this.type="update"
}

deleteColumn(data){
   this.tempUpdateObj=data.dataItem;
   this.removeDialogOpen=true;
}

getModuleHierarchYdata(schema, tablename){
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
  let ModuleHieracrhyData = JSON.parse(result.gridData)
    let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
            return e.NAME ==="Work Order"
      })
        this.parentId = ModFacArr[0].PARENTID
        this.moduleId=ModFacArr[0].ID;
     // console.log(ModFacArr)
  });
}
addAttechment(){
  if(!this.attechForm.URL){
    this.attechError.URL=true
  }
  else{
    this.attechError.URL=false
  }
  if(!this.attechForm.FILENAME){
    this.attechError.FILENAME=true
  }
  else{
    this.attechError.FILENAME=false
  }
  if(!this.attechError.URL && !this.attechError.FILENAME ){
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.saveData()
    .then((result:any)=>{
      this.attechForm.ID=result.id;
      this.attechmentDoc.alldata.push(this.attechForm);
      this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
      this.attechForm=new Attechment();
    })
    .catch((err)=>{
       console.log(err)
    })
}
}
getSparePartRequest(id){
 return new Promise((resolve,reject)=>{
   HttpClient.get("/api/Assets/GetPartNoByWOID?Id="+id,true,true)
   .then((result)=>{
        resolve(result)
   })
   .catch((err)=>{
     reject(err);
     console.log(err)
   })
 })
}


}
