<div  *ngIf="!isProgress && qrcodePrintData.length>0"  >
    <div style="background: white; padding: 1rem;">
  <kendo-pdf-export   #pdf paperSize="A4">
    
    <div  *ngFor ="let item of qrcodePrintData;let i=index" [ngClass]="(i!=0)?'page-break':''">
     <div style="width: 100%;display: flex;justify-content: center;margin: 1rem;">
      <div style="border: 0px solid black;border-radius: 0.25rem;width: 250px;display: grid;grid-template-columns: 170px 80px;">
         <div style="width: 100%;padding: 0.05rem;">
             <!-- <div style="display: flex;justify-content: center;"><img src="assets/logo.png"  style="height: 20px;"></div> -->
             <div style="color: black;text-align: center;font-size: 10px;padding:0.15rem 0.15rem;" >
                 <div style="padding: 0.05rem;border: 1px solid black;">SCAN HERE TO REPORT ON BUILDING ISSUES</div>
             </div>

             <div *ngIf="type=='location'" style="color: black;text-align: center;font-size: 7px;padding:0.15rem;margin-top: 0.25rem;" >
              <div style="padding: 0.05rem;border: 0px solid black;">{{item.location}}</div>
            </div>
            <div *ngIf="type=='asset'" style="color: black;text-align: center;font-size: 7px;padding:0.15rem;line-height: 0;margin-top: 0.25rem;" >
              <div style="padding: 0.05rem;border: 0px solid black;">{{item.assetNo}}</div>
            </div>
            <div *ngIf="type=='asset'" style="color: black;text-align: center;font-size: 7px;padding:0.10rem;" >
              <div style="padding: 0.05rem;border: 0px solid black;">{{item.assetDescription}}</div>
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 0.3rem;">
                <div style="display: flex;justify-content: center;flex-direction: column;">
                  <img src="assets/logo.png"  style="height: 20px;">
                </div>
                <div>
                     <div style="text-align: right;color: black;font-size:7px;line-height: 2;">{{item.email}}</div>
                      <div style="text-align: right;font-size:7px;color: black;">{{item.phoneNo}}</div>
                </div>
            </div>
             <!-- <div style="color: black;text-align: center;font-size: 16px;">{{item.assetNo}}</div> -->
         </div>
         <div style="display: flex;justify-content: center;flex-direction: column;">
            <kendo-qrcode
            style="width: 99%;"
            value={{item.data}}
          >
          </kendo-qrcode>
         </div>
         </div>
     </div>
     </div>

     </kendo-pdf-export> 
     </div>
     <div style="display: flex;justify-content: flex-end;padding: 1rem;">
        <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
          Print
        </button>
     </div>
  <!-- <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="closePrintModel()">Cancel</button>
    <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
          Print
    </button>
  </kendo-dialog-actions> -->
  </div>
  <div *ngIf="isProgress" style="margin: 3rem 1rem;display: flex;justify-content: center;">
     <div>QR Code is in progress</div>
  </div>