<div class="card m-3">
    <div class="card-header" >
        <h5>PPM Load Balancing</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Contractor And Vendor
          </button> -->
        </div>
    </div>
    <div class="card-body register-form-body">
        <div class="py-3">
            <div class="form-row">
                <!-- <div class="form-group col-5">
                    <label>Facility</label>
                    <kendo-dropdownlist  
                        [data]="model.facility.filterData" 
                        [value]="filterGrid.facility" 
                        (valueChange)="filterChange('facility',$event)" 
                        class="form-control"
                        [textField]="'NAME'" 
                        [valueField]="'FACILITYCODE'" 
                    >
                    </kendo-dropdownlist>
                </div> -->
                <div class="form-group col-5">
                    <label>Asset Classification</label>
                    <kendo-dropdownlist  
                    [data]="model.assetClassfication.filterData" 
                    [value]="filterGrid.assetClassificatioon" 
                    (valueChange)="filterChange('assetClassificatioon',$event)" 
                    class="form-control"
                    [textField]="'code'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                 <label>Planner</label>
                 <kendo-dropdownlist  
                     [data]="model.planner.filterData" 
                     [value]="filterGrid.planner" 
                     (valueChange)="filterChange('planner',$event)" 
                     class="form-control"
                     [textField]="'NAME'" 
                     [valueField]="'ID'" 
                 >
                 </kendo-dropdownlist>
             </div>
               
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Block Code</label>
                    <kendo-dropdownlist  
                        [data]="model.block.filterData" 
                        [value]="filterGrid.block" 
                        (valueChange)="filterChange('block',$event)" 
                        class="form-control"
                        [textField]="'CODE'" 
                        [valueField]="'CODE'" 
                    >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Block Name</label>
                    <input kendoTextBox [value]="filterGrid.block && filterGrid.block.NAME" class="form-control" disabled/>
                </div>
              
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Level Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                             [data]="model.level.filterData" 
                             [value]="filterGrid.level" 
                             (valueChange)="filterChange('level',$event)" 
                             class="form-control"
                             [textField]="'CODE'" 
                             [valueField]="'ID'" 
                     >
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Level Name</label>
                    <input kendoTextBox [value]="filterGrid.level && filterGrid.level.NAME" class="form-control" disabled/>
                </div>
      
                
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Area Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                    [data]="model.area.filterData" 
                    [value]="filterGrid.area" 
                    (valueChange)="filterChange('area',$event)" 
                    class="form-control"
                    [textField]="'CODE'" 
                    [valueField]="'ID'" 
                    >
                   </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Area Name</label>
                    <input kendoTextBox [value]="filterGrid.area && filterGrid.area.NAME" class="form-control" disabled/>
                </div>
       
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Location Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                     [data]="model.location.filterData" 
                     [value]="filterGrid.location" 
                     (valueChange)="filterChange('location',$event)" 
                     class="form-control"
                     [textField]="'CODE'" 
                     [valueField]="'ID'" 
                    >
                   </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Location Name</label>
                    <input kendoTextBox [value]="filterGrid.location && filterGrid.location.NAME"  class="form-control" disabled/>
                </div>
            
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Assign To</label>
                    <kendo-dropdownlist  
                    [data]="model.contractor.filterData" 
                    [value]="filterGrid.assignTo" 
                    (valueChange)="filterChange('assignTo',$event)" 
                    class="form-control"
                    [textField]="'name'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Assignee</label>
                    <kendo-dropdownlist  
                    [data]="model.user.filterData" 
                    [value]="filterGrid.assignee" 
                    (valueChange)="filterChange('assignee',$event)" 
                    class="form-control"
                    [textField]="'staffName'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>

            </div>
            <!-- <div class="form-row">
                <div class="form-group col-5">
                    <label>Area Name</label>
                    <kendo-dropdownlist  
                    [data]="model.area.filterData" 
                    [value]="filterGrid.areaName" 
                    (valueChange)="filterChange('areaName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Location Name</label>
                    <kendo-dropdownlist  
                    [data]="model.location.filterData" 
                    [value]="filterGrid.locationName" 
                    (valueChange)="filterChange('locationName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
            </div> -->
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Year</label>
                    <kendo-dropdownlist  
                    [data]="model.year.filterData" 
                    [value]="filterGrid.year" 
                    (valueChange)="filterChange('year',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Month</label>
                    <kendo-dropdownlist  
                    [data]="model.month.filterData" 
                    [value]="filterGrid.month" 
                    (valueChange)="filterChange('month',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <!-- <div class="form-group col-5">
                    <label>Type</label>
                    <input kendoTextBox value="Asset" [disabled]="true" class="form-control"/>
                </div> -->
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Total No. of Work Orders for The Year</label>
                    <input kendoTextBox [disabled]="true"  class="form-control" [value]="totalWorkOrderPInYear"/>
                </div>
                <div class="form-group col-5">
                    <label>Average Work Order Per Month</label>
                    <input kendoTextBox [disabled]="true"   class="form-control" [value]="avgWorkOrderPerMonth"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                </div>
                <div class="form-group col-5" style="width: 100%;">
                        <div style="display: flex;justify-content: flex-end;width: 100%;">
                            <button kendoButton primary="true" class="mr-2" (click)="resetFetch()">Reset</button>
                            <button kendoButton   primary="true" class="mr-2 px-3" (click)="fetchData()">
                                Fetch
                            </button>
                        </div>
                 </div>
            </div>
        </div>
        <!-- <app-table-grid
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
      ></app-table-grid> -->
      <!-- <app-table-grid
      [data]="areagrid"
      [column]="column"
      [size]="10"
      [deleteFlag]="false"
      deleteKey="deleteKey"
      [action]="false"
  ></app-table-grid> -->
  <kendo-grid [kendoGridBinding]="areagrid" [pageable]="true" [pageSize]="12">
    <kendo-grid-column title="Month" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div>
                {{dataItem.month}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Week1" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link_btn" (click)="openRessignWorkOrder('week1',dataItem)">
                {{dataItem.week1 && dataItem.week1.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Week2" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link_btn"  (click)="openRessignWorkOrder('week2',dataItem)">
                {{dataItem.week2 && dataItem.week2.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Week3" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link_btn"  (click)="openRessignWorkOrder('week3',dataItem)">
                {{dataItem.week3 && dataItem.week3.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Week4" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link_btn"  (click)="openRessignWorkOrder('week4',dataItem)">
                {{dataItem.week4 && dataItem.week4.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Week5" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="link_btn"  (click)="openRessignWorkOrder('week5',dataItem)">
                {{dataItem.week5 && dataItem.week5.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column  title="Total" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class=""  >
                {{dataItem.week1.length+dataItem.week2.length+dataItem.week3.length+dataItem.week4.length+dataItem.week5.length}}
            </div>
        </ng-template>
      </kendo-grid-column>
  </kendo-grid>
    </div>
    <!-- <div class="card-body register-form-body">
       
            
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset Classification</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Assignee</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                       
                    </div>
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assign To</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Block Code</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Block Name</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Level Code</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Level Name</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Area Code</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Area Name</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Location Code</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Location Name</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Sub Location Name</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Sub Location Code</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Year</label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Total No. of Work Orders for The Year</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Average Work Order Per Month</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                    </div>
                
                   
                    <app-table-grid
                    [data]="areagrid"
                    [column]="column"
                    [size]="10"
                    [deleteFlag]="false"
                    deleteKey="deleteKey"
                    [action]="false"
                ></app-table-grid>
                <div class="text-center mt-3 mb-4">
                    <button kendoButton primary="true" (click)="openRoleDailog('new')"class="mr-2">Fetch</button>
                   
                </div>
                         
    </div> -->
</div>
<kendo-dialog *ngIf="opened"  (close)=" onDialogClose()" [width]="1000">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         Reassign Work Order
      </div>
    </kendo-dialog-titlebar>
         
            <!-- <div>Reassign Work Order</div> -->
            <div class="card-body register-form-body">
                <div class="py-3">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>New Assign To</label>
                            <kendo-dropdownlist  
                            [data]="model.contractor.filterData" 
                            [value]="reassignObj.assignTo" 
                            (valueChange)="reassignObj.assignTo=$event" 
                            class="form-control"
                            [textField]="'name'" 
                            [valueField]="'id'" 
                        >
                        </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>New Assignee</label>
                            <kendo-dropdownlist  
                            [data]="model.user.filterData" 
                            [value]="reassignObj.assignee" 
                            (valueChange)="reassignObj.assignee=$event" 
                            class="form-control"
                            [textField]="'staffName'" 
                            [valueField]="'id'" 
                        >
                        </kendo-dropdownlist>
                        </div>
        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Target Date</label>
                            <kendo-datetimepicker [format]="format" [(value)]="reassignObj.targetDate">
                            </kendo-datetimepicker>
                        </div>
                        <div class="form-group col-5">
                            <label>Proposed Date</label>
                            <kendo-datetimepicker [format]="format" [(value)]="reassignObj.proposeDate">
                            </kendo-datetimepicker>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                        </div>
                        <div class="form-group col-5" style="width: 100%;">
                                <div style="display: flex;justify-content: flex-end;width: 100%;">
                                    <button kendoButton   primary="true" class="mr-2 px-3" (click)="reassignWO()">
                                        Save
                                    </button>
                                </div>
                         </div>
                    </div>
                 </div>
            </div>   
        <app-table-grid
        [data]="reassignWorkOrder"
        [column]="columnReassign"
        [size]="10"
        [action]="false"
      ></app-table-grid>
       
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button  *ngIf="this.type=='new'" kendoButton  primary="true">
        Reset
      </button>
      <button  *ngIf="this.type=='new'" kendoButton (click)=" onSubmit()" primary="true">
        Save
      </button>
     
    </kendo-dialog-actions>
  </kendo-dialog>
