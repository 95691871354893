export class BlockType{
  CREATEDBY: number
  CREATEDDATE: Date
  FACILITYID: number
  ID: number
  CODE:string
  SHORTNAME:string
  STATUSID:number
  MODIFIEDBY: number
  MODIFIEDDATE: Date
  NAME: string
  NAMEMODIFIEDBY:string;
  NAMECREATEDBY:string
}

export class LevelType{
  ID:number
  NAME:string
  CODE:string
  SHORTNAME:string
  STATUSID:number
  BLOCKID:number
  MODIFIEDBY: number
  MODIFIEDDATE:Date;
  CREATEDBY:number
  CREATEDDATE:Date;
  FACILITYID:number;
  NAMEMODIFIEDBY:string;
  NAMECREATEDBY:string
}


export class AreaType{
  ID:number
  NAME:string
  CODE:string
  SHORTNAME:string
  STATUSID:number
  BLOCKLEVELID:number
  BLOCKID:number
  MODIFIEDBY: number
  MODIFIEDDATE:Date;
  CREATEDBY:number
  CREATEDDATE:Date;
  FACILITYID:number;
  NAMEMODIFIEDBY:string;
  NAMECREATEDBY:string
}

export class LocationType{
  ID:number
  NAME:string
  CODE:string
  SHORTNAME:string
  BLOCKLEVELID:number
  BLOCKID:number
  LEVELAREAID:number
  MODIFIEDBY: number
  MODIFIEDDATE:Date;
  CREATEDBY:number
  CREATEDDATE:Date;
  FACILITYID:number;
  STARTSERVICEDATE:Date;
  STOPSERVICEDATE:Date;
  LOCATIONINCHARGE:number;
  COMPANYREPRESENTATIVE:number;
  STATUSID:number;
  NAMEMODIFIEDBY:string;
  NAMECREATEDBY:string
}

export class SubLocationType{
  ID:number
  NAME:string
  CODE:string
  SHORTNAME:string
  BLOCKLEVELID:number
  BLOCKID:number
  LEVELAREAID:number
  AREALOCATIONID:number
  MODIFIEDBY: number
  MODIFIEDDATE:Date;
  CREATEDBY:number
  CREATEDDATE:Date;
  FACILITYID:number;
  STATUSID:number;
  NAMEMODIFIEDBY:string;
  NAMECREATEDBY:string
 }

export class AreaTypeError{
  NAME:Boolean;
  CODE:Boolean;
  BLOCKLEVELID:boolean;
  BLOCKID:boolean;
  FACILITYID:boolean;
  constructor(status){
    this.NAME = status;
    this.CODE= status;
    this.BLOCKLEVELID= status;
    this.BLOCKID=status;
    this. FACILITYID=status
  }
}

  export class LevelTypeError{
    NAME:Boolean;
    CODE:Boolean;
    BLOCKID:boolean
    FACILITYID:boolean
    constructor(status){
      this.NAME = status;
      this.CODE= status;
      this.BLOCKID= status;
      this. FACILITYID = status
    }
  }

export class BlockTypeError{
    FACILITYID:Boolean;
    CODE:Boolean;
    NAME:Boolean;
  constructor(statuses){
      this.FACILITYID=statuses;
      this.CODE=statuses;
      this.NAME= statuses;
      
}
}
export class LocationTypeError{
  NAME:Boolean;
  CODE:Boolean;
  BLOCKLEVELID:boolean;
  BLOCKID:boolean;
  LEVELAREAID:boolean;
  STARTSERVICEDATE:boolean;
  FACILITYID:boolean;
  STOPSERVICEDATE:boolean
  constructor(status){
    this.NAME = status;
    this.CODE= status;
    this.BLOCKLEVELID= status;
    this.BLOCKID=status;
    this.LEVELAREAID=status;
    this.STARTSERVICEDATE=status;
    this.FACILITYID=status;
    this.STOPSERVICEDATE=status
  }
}

export class SubLocationTypeError{
  NAME:Boolean;
 CODE:Boolean;
 FACILITYID:boolean;
 BLOCKLEVELID:boolean;
 BLOCKID:boolean;
 LEVELAREAID:boolean;
 AREALOCATIONID:boolean
  constructor(status){
    this.NAME = status;
    this.CODE = status;
    this.BLOCKLEVELID= status;
    this.BLOCKID=status;
    this.LEVELAREAID=status;
    this.AREALOCATIONID=status;
    this.FACILITYID=status;
}
}

