import { Input, SimpleChanges } from '@angular/core';
import { Component, OnInit,OnChanges } from '@angular/core';

@Component({
  selector: 'app-qr-printing',
  templateUrl: './qr-printing.component.html',
  styleUrls: ['./qr-printing.component.scss']
})
export class QrPrintingComponent implements OnInit,OnChanges {
  @Input() qrcodePrintData:Array<{
    data:string,
    assetCode:string,
    assetNo:string,
    assetDescription:string,
    location:string,
    email:string,
    phoneNo:string
  }>=[]
  @Input() type:string;
  public isProgress=true;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.qrcodePrintData.currentValue){
      this.qrcodePrintData=changes.qrcodePrintData.currentValue;
    }
  }

  ngOnInit(): void {
  }

}
