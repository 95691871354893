import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class MaintenacePlanner{ 
    ID:number;
    NAME:string;
    SERVICETYPEID:number;
    CHECKLISTID:number;
    YEAR:string;
    FREQUENCYID:number;
    PPMSTARTDATE:Date;
    PPMENDDATE:Date;
    STATUSID:number;
    MAINTENANCETYPEID:number;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number;
    EXCLUDEWEEKEND:boolean=false;
   async  saveData(){
        this.ID=0;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        if(!this.EXCLUDEWEEKEND){
            this.EXCLUDEWEEKEND=false;
        }
       return saveLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlanner,this,"new")
    }	
   async updateData(id){
        this.ID=id;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date()
        if(!this.EXCLUDEWEEKEND){
            this.EXCLUDEWEEKEND=false;
        } 
        return saveLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlanner,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlanner,obj);
    }
}

export class MaintenacePlannerObj{
    alldata:Array<MaintenacePlanner>=[]
    filterData:Array<MaintenacePlanner>=[]
    selectedObj:MaintenacePlanner=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:MaintenacePlanner)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.MaintenancePlanner)
            .then((result:Array<MaintenacePlanner>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}