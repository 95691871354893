<div class="card m-3"  >
    <div class="card-header" >
        <h5>Asset QR Code Printing</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Contractor And Vendor
          </button> -->
        </div>
    </div>
    <div class="card-body register-form-body"  >      
                   <div class="card m-3">
                    <div class="register-form-body">
        <app-table-grid
        [data]="gridData"
        [column]=" column"
        [size]="10"
        [action]="false"
        [deleteFlag]="false"
        [checkbox] ="true"
        deleteKey="deleteKey" 
      ></app-table-grid>
        
                    </div>
                    
                        </div>  
                        <div class="form-row">
                            <button kendoButton   primary="true" class="ml-2 mr-3 px-3" (click)="openPrintModel()">
                             View & Print
                            </button>      
                         </div>
</div>



<kendo-dialog *ngIf="opened" (close)="closePrintModel()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        View QR
      </div>
      <!-- <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
        Print
      </button> -->
    </kendo-dialog-titlebar>
    <!-- <div  *ngIf="qrcodePrintData.length>0"  >
      <div style="background: white; padding: 1rem;">
    <kendo-pdf-export   #pdf paperSize="A4">
      
      <div  *ngFor ="let item of qrcodePrintData;let i=index" [ngClass]="(i!=0)?'page-break':''">
       <div style="width: 100%;display: flex;justify-content: center;margin: 1rem;">
        <div style="border: 2px solid black;border-radius: 0.25rem;width: 170px;display: grid;grid-template-columns: 55% 45%;">
           <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;padding: 0.15rem;">
               <div style="display: flex;justify-content: center;"><img src="assets/logo.png"  style="height: 20px;"></div>
               <div style="color: black;text-align: center;font-size: 8px;margin:0.5rem 0.15rem;"><span style="padding: 0.15rem;border: 1px solid black;">{{item.assetCode}}</span></div>
               <div style="color: black;text-align: center;font-size: 8px;">{{item.assetNo}}</div>
           </div>
           <div style="display: flex;justify-content: center;flex-direction: column;padding: 0.15rem;">
              <kendo-qrcode
              style="width: 97%;"
              value={{item.data}}
              errorCorrection="M"
            >
            </kendo-qrcode>
           </div>
           </div>
       </div>
       </div>

       </kendo-pdf-export> 
       </div>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="closePrintModel()">Cancel</button>
      <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
            Print
      </button>
    </kendo-dialog-actions>
    </div> -->
    <app-qr-printing *ngIf="qrcodePrintData.length>0" type="asset" [qrcodePrintData]="qrcodePrintData"></app-qr-printing>

  </kendo-dialog>
