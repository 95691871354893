import { Component, OnInit } from '@angular/core';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-checklist-container',
  templateUrl: './checklist-container.component.html',
  styleUrls: ['./checklist-container.component.scss']
})
export class ChecklistContainerComponent implements OnInit {
  public permission:Permission=new Permission();
  constructor() { }

  ngOnInit(): void {
    if(UserModuleObj.checklist___sla){
      this.permission=UserModuleObj.checklist___sla;
    }
  }

}
