<div class="er-body"  >
    <!--   -->
<div class="card m-3">
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Parameter Mapping</h5>
        <div> 
          <button kendoButton   primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
              <span class="add_btn">+</span> Parameter Mapping
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10"
      ></app-table-grid>
    </div>

</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    <div class="form-row">
        <div class="form-group col-5">
         <label>Parameter ID</label>
         <div style="width: 100%;">
             <input kendoTextBox class="form-control" [(ngModel)]="pMForm.erparameterId" disabled="disabled"/>
         </div>
         </div>
         <div class="form-group col-5"></div>
        
     </div>
    <div class="form-row">
       <div class="form-group col-5">
        <label>Parameter Description</label>
        <div style="width: 100%;">
            <input kendoTextBox class="form-control"  [(ngModel)]="pMForm.description"/>
           
        </div>
            </div>
       
        <div class="form-group col-5">
            <label>Category <span class="required">*</span></label>
            <div style ="width:100%;">
            <kendo-dropdownlist [data]="categoryDropDown" class="form-control"  [(ngModel)]="pMForm.categoryId"
            [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" (valueChange) = "categoryValueChange($event)"
            >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.categoryId">*Required</kendo-formerror>
        </div>
    </div>
    </div>
    <div class="form-row">
       
        <div class="form-group col-5">
            <label>ER No <span class="required">*</span> </label>
            <div style="width: 100%;">
                <input *ngIf="!erNoSHow" kendoTextBox class="form-control"  [(ngModel)]="pMForm.erno" />
                <kendo-dropdownlist *ngIf="erNoSHow" [data]="assetNoDropDown" class="form-control"
                [textField]="'assetNumber'" [valueField]="'assetNumber'"  [(ngModel)]="pMForm.erno" [valuePrimitive]="true" 
                (valueChange) ="assetValueChange($event)"
                >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.erno">*Required</kendo-formerror>
            </div>
            <!-- <kendo-dropdownlist [data]="spec_det" class="form-control" [(ngModel)]="contractorVendorForm.bpmskills" [textField]="'name'" [valueField]="'id'"
                [valuePrimitive]="true">
            </kendo-dropdownlist> -->
        </div>

        <div class="form-group col-5">
            <label>Facility <span class="required">*</span></label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]=" facility" disabled/>
               
            </div>
        </div>
    </div>
    <div class="form-row">
     
      
      
        <div class="form-group col-5">
            <label>Block Code </label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]=" bCode"  disabled/>
               
            </div>
        </div>
        <div class="form-group col-5">
            <label>Block Name</label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="bname"  disabled/>
               
            </div>
        </div>

    </div>
    <div class="form-row">
      
        <div class="form-group col-5">
            <label>Level Name</label>

                <div style="width: 100%;">
                    <input kendoTextBox class="form-control"  [(ngModel)]="lname" disabled/>
                
        </div>
        </div>
        <div class="form-group col-5">
            <label>Level Code</label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="lCode" disabled/>
            </div>
        </div>
    </div>
    <div class="form-row">
       
      
        <div class="form-group col-5">
            <label>Area Name </label>
            <input kendoTextBox class="form-control"  [(ngModel)]="aname" disabled/>
        </div>
        <div class="form-group col-5">
            <label>Area Code </label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control"  [(ngModel)]="aCode" disabled/>
            </div>
        </div>
    </div>
    <div class="form-row">
        
  
    <!-- </div>
    <div class="form-row"> -->
       
       
        <div class="form-group col-5" >
            <label>Location Name </label>
            <div style="width: 100%;">
                <input  kendoTextBox class="form-control"   [(ngModel)]="loname" disabled/>
            </div>
        </div>
        <div class="form-group col-5">
            <label>Location Code  </label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control"   [(ngModel)]="loCode" disabled/>
            </div>
           </div>
    </div>

    <div class="form-row">
        
  
        <!-- </div>
        <div class="form-row"> -->
           
          
        
           <div class="form-group col-5">
            <label>Sub Location Name </label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control"  [(ngModel)]="subloname" disabled/>
            </div>
        </div>
        <div class="form-group col-5">
            <label>Sub Location Code   </label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="subloCode" disabled/>
            </div>
           </div>
        </div>
        <div class="form-row">
        
  
            <!-- </div>
            <div class="form-row"> -->
               
              
          
               <div class="form-group col-5">
                <label>Location  </label>
                <div style="width: 100%;">
                    <input kendoTextBox class="form-control" [(ngModel)]="pMForm.location" [disabled] = "isLocation"/>
                </div>
            </div>
            <div class="form-group col-5">
                <label>Service Type   </label>
                <div style="width: 100%;">
                    <kendo-dropdownlist [data]="serviceDropDown" class="form-control" [(ngModel)]="pMForm.serviceTypeId"
                    [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true"
                    >
                    </kendo-dropdownlist>
                </div>
               </div>
            </div>
            <div class="form-row">
        
  
                <!-- </div>
                <div class="form-row"> -->
                   
                   
              
                   <div class="form-group col-5">
                    <label>Frequency <span class="required">*</span></label>
                    <div style="width: 100%;">
                        <kendo-dropdownlist [data]="frequencyDropDown" class="form-control"
                    [textField]="'FREQUENCY'" [valueField]="'ID'" [valuePrimitive]="true" [(ngModel)]="pMForm.frequencyId"
                     [disabled]="frequencyDisable"
                    >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.frequencyId">*Required</kendo-formerror>
                    </div>
                </div>
                <div class="form-group col-5">
                    <label>Email Time Notification </label>
                    <div style="width: 100%;">
                        <kendo-timepicker   [format]="'HH:mm:ss'" [(ngModel)]="pMForm.emailTimeNotificationdt"></kendo-timepicker>
                    </div>
                   </div>
                </div>
           
                    <div class="form-row">
        
  
                        <!-- </div>
                        <div class="form-row"> -->
                           
                           
                          
                           <div class="form-group col-5">
                            <label>Assign to</label>
                            <div style="width: 100%;">
                                <kendo-dropdownlist [data]="userDropDown" class="form-control"
                                [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [(ngModel)]="pMForm.assignTo"
                                 [disabled]="frequencyDisable"
                                >
                                </kendo-dropdownlist>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>Shift Type </label>
                            <div style="width: 100%;">
                                <kendo-dropdownlist   [data]="usrShiftDropDown"  [textField]="'NAME'"
[valueField]="'ID'"
[valuePrimitive]="true" class="form-control"    [(ngModel)]="pMForm.shiftTypeId"   ngDefaultControl
>
</kendo-dropdownlist>
                            </div>
                           </div>
                        </div>
                    
                        <div class="form-row">
        
  
                            <!-- </div>
                            <div class="form-row"> -->
                               
                               
                              
                               <div class="form-group col-5">
                                <label>Effective Start Date</label>
                                <div style="width: 100%;">
                                    <kendo-datepicker [format] = "formats"  [(ngModel)]="pMForm.effectiveStartDate">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="form-group col-5">
                               
                               </div>
                            </div>
                           <div class = "py-3 px-5">
                               <div class="form-row">
                                <app-contact-asset-grid [data]="personInChargeGrid" [column]="personInChargeColumn" [size]="10"
                            [deleteFlag]="false" (valueChange)="personInChargeGrid=$event"
                            (licenseassetValue)="personchargeChangeValue($event)">
                         </app-contact-asset-grid>
                               </div>
                           </div>
   
    <div class="py-3 px-5" >
        <div class="form-row">
            <app-contact-asset-grid
            [data]="contectGrid"
            [column]="contectColumn"
            [size]="10"
            [deleteFlag]="false"
            (valueChange)="contectGrid=$event"
            (licenseassetValue)="erReadingChangeValue($event)"
            >

            </app-contact-asset-grid>
         </div>
      </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('new')">Cancel</button>
        <button *ngIf="type=='new'" (click)="onReset()" kendoButton primary="true">
            Reset
        </button>
    <button *ngIf="type=='new'" (click)="onSubmit()" kendoButton primary="true">
        Save
    </button>
        <button *ngIf="type=='update'" (click)="onSubmit()" kendoButton primary="true">
            Update
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (click)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
<div  *ngIf="!isAccess">

    <div class="dashboard-content"> 
      <div class="content-wrapper">
        <div class="page-wrapper">
  <h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>
  
        </div>
      </div>
    </div>
  
  </div>