
<kendo-grid
[id]="id"
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
[group]="groups"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"
[selectable]="true"
(selectionChange)="onSelect($event)"
(filterChange)="handleACFilter($event)"
scrollable="none"
>
<!-- <kendo-grid-checkbox-column title="" *ngIf="checkbox" width="50">
  <ng-template kendoGridCellTemplate let-dataItem>
    <input type="checkbox" (change)="checkboxEvent($event,dataItem)" />
  </ng-template>
</kendo-grid-checkbox-column > -->
<!-- <kendo-grid-column >
  <ng-template kendoGridCellTemplate let-dataItem (change)="getDataColumn($event)"></ng-template>
</kendo-grid-column> -->
<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.isDateFilter}}
  format={{col.formate}}
 
>
<ng-template 
     *ngIf="col.parse" 
     kendoGridCellTemplate let-dataItem 
     let-rowIndex="rowIndex"
  >
      <span *ngIf="col.parse">{{col.parse(dataItem[col.field])}}</span>
</ng-template>
<ng-template
*ngIf="col.filterType=='list'"
  kendoGridFilterMenuTemplate
  let-column="column"
  let-filter="filter"
  let-filterService="filterService"
>
    <dropdownlist-filter
    [isPrimitive]="true"
    [field]="column.field"
    [currentFilter]="filter"
    [filterService]="filterService"
    [textField]="column.field"
    [valueField]="column.field"
    [data]="distinctPrimitive(column.field)"
    ></dropdownlist-filter>
</ng-template>
</kendo-grid-column>
<!-- <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
  <kendo-pager-page-sizes [pageSizes]="pagesizes"></kendo-pager-page-sizes>
</ng-template> -->

</kendo-grid>