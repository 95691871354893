import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class ContractorVendor{
    id: number;
    name: string;
    statusId: number;
    registrationNo: string;
    address1: string;
    address2: string;
    postCode: string;
    stateId: number;
    countryId: number;
    phoneNo: string="9876321231";
    faxNo: string;
    noOfUserAccess: number;
    remarks: string;
    contactpersonName: string="ram";
    designation: string;
    contactNo: string;
    email: string;
    bpmtypeId= 1;
    // bpmskills:Array<bpmskills>
    // bpmregisterContactPersons:Array<  {
    //   "id": number,
    //   "name": string,
    //   "designation":string,
    //   "contactNo": string,
    //   "email": string,
    //   "statusId": number,
    //   "isDeleted": true,
    //   "createdBy": number,
    //   "createdDate": Date,
    //   "modifiedBy": number,
    //   "modifiedDate": Date
    // }>=[]

}

export class ContractorVendorObj{
    alldata:Array<ContractorVendor>=[]
    filterData:Array<ContractorVendor>=[]
    selectedObj:ContractorVendor=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:ContractorVendor)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/BPM/GetBpms",true,true)
            .then((result:Array<ContractorVendor>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}