<form class="my-4">
    <div class="form-row">
        <div class="form-group col-5">
            <label>Work Order Type</label>
            <kendo-dropdownlist  class="form-control">
            </kendo-dropdownlist>
        </div>
        <div class="form-group col-5">
            <label>Consequence Category</label>
            <kendo-dropdownlist  class="form-control">
            </kendo-dropdownlist>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            
        </div>
        <div class="form-group col-5">
            <div class="py-3" style="text-align: right;width: 100%;">
                <button kendoButton   primary="true" class="mr-2 px-3">
                  Find
                </button>
                <button kendoButton   primary="true" class="px-3">
                  Reset
                </button>
            </div>
        </div>
    </div>

    <div class="px-4">
        <app-table-grid
            [data]="areagrid"
            [column]="column2"
            [size]="5"
            [action]="false"
        ></app-table-grid>
    </div>


   
</form>