<div class="er-body"   >
<div class="card m-3">
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>ER Capture</h5>
        <div>
            <button kendoButton  primary="true" (click)="download()"  class="header-right-btn">
                Export
               </button> 
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10"
        [deleteFlag] ="false"
        [editFlag] ="false"
        [viewFlag] ="true"
      ></app-table-grid>
    </div>

</div>

<kendo-dialog *ngIf="opened"  (close)="onDialogClose('tabType')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           ER Capture
        </div>
    </kendo-dialog-titlebar>
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <!-- <kendo-tabstrip-tab  title="ER Capture " [selected]="true">
          
          <ng-template kendoTabContent >
            <br>
            <div class="form-row">
                <div class="form-group col-5">
                     <label>ER Task ID  </label>
                     <div style="width: 100%;">
                         <input kendoTextBox class="form-control" />
                        
                     </div>
                 </div>
                 <div class="form-group col-5">
                     <label>Parameter ID </label>
                     <div style="width: 100%;">
                         <input kendoTextBox class="form-control" />
                        
                     </div>
                 </div>
             </div>
             <div class="form-row">
                 <div class="form-group col-5">
                     <label>Parameter Description  </label>
                     <kendo-dropdownlist [data]="categoryDropDown" class="form-control"
                     [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true"
                     >
                     </kendo-dropdownlist>
         
                 </div>
                 <div class="form-group col-5">
                     <label>Category </label>
                     <div style="width: 100%;">
                         <input kendoTextBox class="form-control" />
                        
                     </div>
                     <kendo-dropdownlist [data]="spec_det" class="form-control" [(ngModel)]="contractorVendorForm.bpmskills" [textField]="'name'" [valueField]="'id'"
                         [valuePrimitive]="true">
                     </kendo-dropdownlist> 
                 </div>
               
             </div>
             <div class="form-row">
                 <div class="form-group col-5">
                     <label>ER No <span class="required">*</span></label>
                     <div style="width: 100%;">
                         <input kendoTextBox class="form-control" />
                        
                     </div>
                 </div>
               
               
                 <div class="form-group col-5">
                     <label>PIC  </label>
                     <div style="width: 100%;">
                         <input kendoTextBox class="form-control" />
                        
                     </div>
                 </div>
               
             </div>
             <div class="form-row">
                 <div class="form-group col-5">
                     <label>Record Date/Time</label>
                     <div style="width: 100%;">
                        <kendo-datetimepicker> </kendo-datetimepicker>
                     </div>
                 </div>
                 <div class="form-group col-5">
                     <label>Shift</label>
         
                         <div style="width: 100%;">
                            <kendo-dropdownlist [data]="categoryDropDown" class="form-control"
                            [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true"
                            >
                            </kendo-dropdownlist>
                         
                 </div>
                 </div>
             
             </div>
             <div class="form-row">
                 <div class="form-group col-5">
                     <label>Date </label>
                     <div style="width: 100%;">
                        <kendo-datetimepicker> </kendo-datetimepicker>
                     </div>
                 </div>
               
                 <div class="form-group col-5">
                     <label>Time  </label>
                     <kendo-timepicker   [format]="'HH:mm:ss'"  ></kendo-timepicker>
                 </div>
             </div>
             <div class="form-row">
                <div class="form-group col-5">
                    <label>Location </label>
                    <div style="width: 100%;">
                        <input kendoTextBox class="form-control" />
                    </div>
                </div>
              
                <div class="form-group col-5">
                    <label>Service Type </label>
                    <div style="width: 100%;">
                        <input kendoTextBox class="form-control" />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>User ID  </label>
                    <div style="width: 100%;">
                        <input kendoTextBox class="form-control" />
                    </div>
                </div>
              
                <div class="form-group col-5">
                    
                </div>
            </div>
              </ng-template>
              </kendo-tabstrip-tab> -->
            
              <kendo-tabstrip-tab  title="Checklist " [selected]="true">
                <ng-template kendoTabContent >
                    <app-table-grid
                    [data]="checklistData"
                    [column]="column3"
                    [size]="10"
                    [action] ="false"
                   
                  ></app-table-grid></ng-template>
                
                      
                   
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab  title="Person In Charge">
                <ng-template kendoTabContent >
                    <app-table-grid
                    [data]="picGrid"
                    [column]="PICcolumn"
                    [size]="10"
                    [action] ="false"
                   
                  ></app-table-grid></ng-template>
                
                      
                   
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab  title="Attachment">
                <ng-template kendoTabContent >
                    <br>
                    <!-- <div class="form-row">
                        <div class="form-group col-5">
                            <label>File Type</label>
                            <kendo-dropdownlist 
                                [data]="documentFormate" 
                                class="form-control" 
                                [textField]="'name'" 
                                [valueField]="'id'"
                                [(ngModel)]="attechForm.FILETYPE"
                                [valuePrimitive]="true"
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>File Name</label>
                            <div style="width: 100%;">
                            <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                            <kendo-formerror *ngIf="attechError.FILENAME">*required</kendo-formerror>
            
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Attachment</label>
                            <div style="width: 100%;">
                            <app-file-upload
                               type="doc" 
                               [formate]="[attechForm.FILETYPE]"
                               [url]="attechForm.URL" 
                               (valueChange)="attechForm.URL=$event" 
                               style="width: 100%;"
                            >
                          </app-file-upload>
                           <kendo-formerror *ngIf="attechError.URL">*required</kendo-formerror> 
                          </div>
                        </div>
            
                        <div class="form-group col-5">
                            <button  (click)="addAttechment()" kendoButton primary="true">
                                Add
                            </button>
                        </div>
            
            
                    </div> -->
               
                <app-attachment-table-grid 
                     [data]="attechmentDoc.alldata" 
                     [column]="column2" 
                     [size]="10"
                        [deleteFlag]="false"
                     >
               </app-attachment-table-grid>
                </ng-template>
               
              </kendo-tabstrip-tab>
              </kendo-tabstrip>
</kendo-dialog>
</div>
<div  *ngIf="!isAccess">

    <div class="dashboard-content"> 
      <div class="content-wrapper">
        <div class="page-wrapper">
  <h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>
  
        </div>
      </div>
    </div>
  
  </div>
    
