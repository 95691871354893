import HttpClient from 'src/Util/ApiHandling';
import { Component, OnInit,OnChanges, SimpleChanges, Input, EventEmitter } from '@angular/core';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { getCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import { saveLookup } from 'src/ApiModel/Util';
import { Output } from '@angular/core';

@Component({
  selector: 'app-completeion-info',
  templateUrl: './completeion-info.component.html',
  styleUrls: ['./completeion-info.component.scss']
})
export class CompleteionInfoComponent implements OnChanges {
  public permission:Permission=new Permission();
  public startDate;
  public endDate;
  public closedby;
  public completedBy;
  public completionSlaStart
  public completionSlaEnd
  public respondSlaEnd
  public respondSlaStart
  public chargablevalue;
  public waitingTimeStart;
  public followReasonId
  public followupReasonDropDown=[]
  public waitingTimeEnd;
  public totalWaitingTime;
  public netwaitingTime
  public checlistId
    public chargableDropDown =[
      {
        id:1,name:"Yes",
        
      },
      {
        id:2,name:"No",
        
      }
    ]
 
    public changeWoStatus =[
      // {
      // ID:5,NAME:"Close"
      // },
      {
      ID:6,NAME:"Cancel"
      }
    ]
    @Output() statusChange = new EventEmitter<{}>();  
    @Input() workorder:any={
    createdDate:null,
    modifiedDate:null,
    AcceptanceDate:null,
    CompletionDateTime:null,
    closingDateTime:null,
    id:null
  }
  @Input() user={
        alldata:[],
        filterData:[],
        selectedObj:null,
        objectData:{}
  }

  @Input() woStatus={
        alldata:[],
        filterData:[],
        selectedObj:null,
        objectData:{}
  }

  @Input() chargeable={
        alldata:[],
        filterData:[],
        selectedObj:null,
        objectData:{}
  }
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
  //  throw new Error('Method not implemented.');
    if(changes.user){
      this.user=changes.user.currentValue;
    }
    if(changes.woStatus){
      this.woStatus=changes.woStatus.currentValue
    }
    if(changes.chargeable){
      this.chargeable=changes.chargeable.currentValue;
      // this.chargeable.selectedObj = parseInt("1")
      // console.log( this.chargeable)
    }
    if(changes.workorder){
      this.workorder=changes.workorder.currentValue;
      this.workorder.createdDate=new Date(this.workorder.createdDate);
      this.workorder.modifiedDate=new Date(this.workorder.modifiedDate);
      this.workorder.AcceptanceDate= this.workorder.acceptanceDate && new Date(this.workorder.acceptanceDate);
      this.workorder.CompletionDateTime=this.workorder.completionDateTime && new Date(this.workorder.completionDateTime);
      this.workorder.closingDateTime=this.workorder.closingDateTime && new Date(this.workorder.closingDateTime);
    }
  }
  public value1: Date = new Date(2019, 5, 1, 22);
  public value2: Date = new Date(2019, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  asset_classi = ['Select'];

  ngOnInit(): void {
    if(UserModuleObj.work_order){
      this.permission=UserModuleObj.work_order;
      
    }

    this.chargablevalue =1
    this.getCompletionInfo("pmc","ticket");
    this.getTicketFollowUp("pmc", "TicketFollowUp");
    this.getFoolwUpReason("pmc","FollowUpReason")
  }
  valuechange(type,data){
    
  }

  getCompletionInfo(schema, tablename){
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${this.workorder.id}`,
      true,
      true
    ).then((result: any) => {
      
    let data = JSON.parse(result.gridData);
     let cmplValue = data[0]
      this.checlistId = cmplValue.CHECKLISTID
      this.completedBy = cmplValue.COMPLETEDBY 
      if(cmplValue.CREATEDDATE!=null){
        this.respondSlaStart = new Date(cmplValue.CREATEDDATE)
      }
     if(cmplValue.ACCEPTANCEDATE!=null){
      this.respondSlaEnd = new Date(cmplValue.ACCEPTANCEDATE)
     }
     if(cmplValue.CHECKINFLAGDATETIME!=null){
      this.completionSlaStart =new Date(cmplValue.CHECKINFLAGDATETIME)
     }
    if(cmplValue.CLOSINGDATETIME!=null){
      this.completionSlaEnd  =new Date(cmplValue.CLOSINGDATETIME)
     }
    if(cmplValue.TICKETSTATUSID == 4){
      this.changeWoStatus =[
        {
        ID:5,NAME:"Close"
        },
        {
        ID:6,NAME:"Cancel"
        }
      ]
    }else{
      this.changeWoStatus =[
        {
        ID:6,NAME:"Cancel"
        }
      ]
    }
      
     
      // this.getChecklistInfo( this.checlistId)
    if(cmplValue.CHARGABLE==true){
      this.chargeable.selectedObj =  {id:1,name:"Yes"}
    }
    else{
      this.chargeable.selectedObj =  {id:2,name:"No"}
    }
    this.startDate = new Date(cmplValue.CREATEDDATE)
    this.endDate = new Date(cmplValue.CLOSINGDATETIME)
    if(cmplValue.FOLLOWUPDATETIMESTART!=null){
      this.waitingTimeStart = new Date(cmplValue.FOLLOWUPDATETIMESTART)
    }
    if(cmplValue.FOLLOWUPDATETIMESTOP!=null){
      this.waitingTimeEnd = new Date(cmplValue.FOLLOWUPDATETIMESTOP)
    }
     this.getTimeDiff(cmplValue.FOLLOWUPDATETIMESTART,cmplValue.FOLLOWUPDATETIMESTOP)
   });
  }

 getTicketFollowUp(schema, tablename){
   console.log("sadasd",)
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
  let data = JSON.parse(result.gridData);

  let ticketfollowupvalue = data.filter((e)=>{
    return e.TICKETID===this.workorder.id
  })
  if(ticketfollowupvalue.length>0){
    this.followReasonId = ticketfollowupvalue[0].FOLLOWUPREASONID
  }
  
  // if(data[0].CREATEDON!=null){
  //   this.waitingTimeStart = new Date(data[0].CREATEDON)
  // }
  // else{
  //   this.waitingTimeStart =null
  // }

  // if(data[0].MODIFIEDON!=null){
  //   this.waitingTimeEnd = new Date(data[0].MODIFIEDON)
  // }
  // else{
  //   this.waitingTimeEnd =null
  // }
 

  
})

}
getFoolwUpReason(schema,tablename){
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
   
    let data = JSON.parse(result.gridData);
    this.followupReasonDropDown = data;
  })
}

getTimeDiff(startdate,enddate){
  var timeMinStart = new Date(startdate).getMinutes();
  var timeMinEnd = new Date(enddate).getMinutes();
  var timeHourStart = new Date(startdate).getHours();
  var timeHourEnd = new Date(enddate).getHours();
  var hourDiff =  timeHourEnd - timeHourStart; //in ms
  let minDiff:any =  timeMinEnd - timeMinStart;
  if (minDiff.toString().length == 1) {
    minDiff = `0` + minDiff
}
    var duration =  hourDiff +":"+minDiff;
    this.netwaitingTime = duration
    console.log("dsfdf",this.workorder,this.user.filterData)
}

changeWoStatusCompletions(data){
 let a= this.woStatus.filterData.filter((d)=>{
    return d.NAME.toLowerCase()==data.NAME.toLowerCase();
  })
 
  if(a.length>0){
    let statusId = a[0].ID;
    if(a[0].NAME.toLowerCase()=="close"){
      this.workorder.closedBy = parseInt(getCookie(userid));
      this.workorder.closingDateTime=new Date();
      this.workorder.ticketStatusId=statusId ;
      this.workorder.ticketStatusObj=a[0]
      this.updateWorkOrder(this.workorder)
    }
    else if(a[0].NAME.toLowerCase()=="cancel"){
      // this.workorder.cancel = parseInt(getCookie(userid));
      // this.workorder.closingDateTime=new Date();
      this.workorder.ticketStatusId=statusId ;
      this.workorder.ticketStatusObj=a[0]
      this.updateWorkOrder(this.workorder)
    }
  }
}

updateWorkOrder(obj){
  // HttpClient.fetchData("/api/WorkOrder/UpdateWorkOrder","post",obj,true,true)
    saveLookup("pmc","ticket",obj,"update")
   .then((result)=>{
      this.statusChange.emit(obj.ticketStatusObj)
     })
   .catch((err)=>{
     console.log(err)
   })
  }
}

