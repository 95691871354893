
<div class="card m-3"  >
    <div class="card-header" >
        <h5> Spare Parts </h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" *ngIf="permission && permission.isCreate" class="header-right-btn">
               Add Spare Part
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
       
        <app-table-grid
        [data]="gridData"
        [column]="column3"
        [size]="10"
        [deleteFlag]="false"
        [fileFlag]="true"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
  
        <div class="form-row">
            <div class="form-group col-5">
                <label>Stock Trade <span class="required">*</span></label>
                <div style="width: 100%;">
                <kendo-dropdownlist  class="form-control" [textField]="'NAME'"
                [valueField]="'ID'"
                [(ngModel)]="sparePartForm.stockTradeId"
                [valuePrimitive]="true" [data]="stockTradeDropDow">
                </kendo-dropdownlist>
                  <kendo-formerror *ngIf="error1.stockTradeId">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Stock Type <span class="required">*</span></label>
                <div style="width: 100%;">
                <input kendoTextBox  [(ngModel)]="sparePartForm.stockType" class="form-control" />
                <kendo-formerror *ngIf="error1.stockType">*Required</kendo-formerror>
            </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Stock Category <span class="required">*</span></label>
                <div style="width: 100%;"> 
                <kendo-dropdownlist  class="form-control" [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"  [(ngModel)]="sparePartForm.stockCategoryId"  [data]=" stockCategoryDropDow">
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error1. stockCategoryId">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Stock No. <span class="required">*</span></label>
                <div style="width: 100%;">
                <input kendoTextBox  [(ngModel)]="sparePartForm.stockNo" class="form-control" />
            
                <kendo-formerror *ngIf="error1.stockNo">*Required</kendo-formerror></div>
        </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Part No. <span class="required">*</span> </label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="sparePartDetailsForm.partNo" class="form-control" />
                <kendo-formerror *ngIf="error2. partNo">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Part Description <span class="required">*</span></label>
                <div style="width: 100%;">
                <input kendoTextBox  [(ngModel)]="sparePartDetailsForm.partDescription" class="form-control" />
                <kendo-formerror *ngIf="error2. partDescription">*Required</kendo-formerror>
            </div>
            </div>
        </div>
        <div class="form-row">
      
            <div class="form-group col-5">
                <label>Manufacturer</label>
                <kendo-dropdownlist   
                [data]="assetManufactureDropDown"
                [value]="sparePartForm.manufacturerID"
                [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                (valueChange)="sparePartForm.manufacturerID=$event"
                class="form-control">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Location</label>
                <kendo-dropdownlist [data]="LocDropDown"   [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true" class="form-control" class="form-control" [(ngModel)]="sparePartDetailsForm.locationId" >
                </kendo-dropdownlist>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Unit of Measurement <span class="required">*</span></label>
                <div style="width: 100%;">
                <kendo-dropdownlist [data]="stockUOMDropDow"  [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true" class="form-control" [(ngModel)]="sparePartForm.uomId" >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error1.uomId">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Facility Name</label>
                <kendo-dropdownlist [data]=" facilityDropDown"  [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true" [(ngModel)]="sparePartDetailsForm.facilityId"    (valueChange)="facilityValueChange($event)" class="form-control">
                </kendo-dropdownlist>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Facility Code</label>
                <input kendoTextBox class="form-control" [(ngModel)]="facilityCode" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Part Source <span class="required">*</span></label>
                <div style="width: 100%;">
                <kendo-dropdownlist [data]="sparePartSourceDropDow"  [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true" class="form-control" [(ngModel)]="sparePartDetailsForm. partSourceId" >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error2. partSourceId">*Required</kendo-formerror>
               </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Bin No.</label>
                <input kendoTextBox  [(ngModel)]="sparePartDetailsForm.binNo"  class="form-control" />
            </div>
            <div class="form-group col-5">
                <label>Min Unit <span class="required">*</span></label>
                <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartDetailsForm.minUnit"  />
                <kendo-formerror *ngIf="error2. minUnit">*Required</kendo-formerror>
            </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Max Unit</label>
                <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartDetailsForm.maxUnit"  />

            </div>
            </div>
            <div class="form-group col-5">
                <label>Min Price Per Unit (RM)</label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartDetailsForm.minPricePerUnit" />
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Max Price Per Unit (RM)</label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartDetailsForm.maxPricePerUnit"  />
            </div>
            <div class="form-group col-5">
                <label>Status </label>
                <kendo-dropdownlist  
                [data]="statusDropDown" [(ngModel)]="sparePartDetailsForm.statusId"   class="form-control"
                [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                  >
                </kendo-dropdownlist>
               
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Quantity on hand (QOH)</label>
                <input kendoTextBox  [(ngModel)]="sparePartDetailsForm.qoh"   class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Lifespan Options </label>
                <kendo-dropdownlist [data]="LifeSpanDropDow" 
                [textField]="'NAME'"
                [(ngModel)]="sparePartDetailsForm.lifeOptionId"  
                      [valueField]="'ID'"
                      [valuePrimitive]="true"class="form-control">
                </kendo-dropdownlist>
            </div>
        </div>
         <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true" (click)="onReset()">
        Reset
       </button>
      <button kendoButton primary="true"  *ngIf="type=='new'" (click)="submitSpareFrom()">
       Save
      </button>
      <button kendoButton primary="true"  *ngIf="type=='update'" (click)="submitSpareFrom()">
       Update
       </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            Delete Description
        </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton>Delete</button>
    </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="attachmentOpened" (close)="attachmentOpened=false" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist 
                    [data]="documentFormate" 
                    class="form-control" 
                    [textField]="'name'" 
                    [valueField]="'id'"
                    [(ngModel)]="attechForm.FILETYPE"
                    [valuePrimitive]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <!-- <kendo-formerror *ngIf="attechError.name">*Required</kendo-formerror> -->

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Attachment</label>
                <div style="width: 100%;">
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
              <!-- <kendo-formerror *ngIf="attechError.url">*Required</kendo-formerror> -->
              </div>
            </div>

            <div class="form-group col-5">
                <button  (click)="addAttechment()" kendoButton primary="true">
                    Add
                </button>
            </div>
           </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="attachmentOpened=false">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid 
         [data]="attechmentDoc.alldata" 
         [column]="column2" 
         [size]="5"
         >
   </app-attachment-table-grid>
</kendo-dialog>