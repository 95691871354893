import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"
import { ChecklistTask } from "../Model/ChecklistTask";
function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key].toLowerCase()]=i;
        }
        else{
       newObj[objR[i].toLowerCase()]=i;
        }
    }
    return newObj;
}
export function addBlukCheklistTask(maintenaceTask,checkList) {
         let obj={};
         maintenaceTask.forEach((d)=>{
            if(!obj[d["Checklist No"]]){
                obj[d["Checklist No"]]={}
            }
            if(!obj[d["Checklist No"]][d["Section Title"]]){
                obj[d["Checklist No"]][d["Section Title"]]=[]
            }
            obj[d["Checklist No"]][d["Section Title"]].push({
                checklist:d["Checklist No"],
                secttionTitle:d["Section Title"],
                question:d["Checklist Line Item"],
                requiredAttechment:(d["Attachment"]?.toLowerCase()=="yes")?true:false,
                mandatory:(d["Mandatory"]?.toLowerCase()=="yes")?true:false,
                ansType:d["Answer Type"],
                ansList:d["Answer List"]?.split(",") || []
            })

         })
          return obj;
}