import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-inspection',
  templateUrl: './daily-inspection.component.html',
  styleUrls: ['./daily-inspection.component.scss']
})
export class DailyInspectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public onTabSelect(e) {
    console.log(e);
  }

}
