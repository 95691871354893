import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { MobileViewModule } from './mobile-view/mobile-view.module';
if (environment.production) {
  enableProdMode();
}
let path=window.location.pathname.split("/");
let isMobile=path[1]==="mobile"
platformBrowserDynamic().bootstrapModule(isMobile?MobileViewModule:AppModule)
  .catch(err => console.error(err));
