
class facilitySkills{
  id:number=0;
  facilityId:number=0;
  skillId:number=0;
}

export class FacilityType{
  id: number;
  name: string;
  skillId: any;
  facilityCode: string;
  address1: string;
  address2: string;
  postCode: string;
  stateId: number;
  countryId: number;
  phoneNo: string;
  faxNo: string;
  activeFrom: Date;
  contactPerson:string;
  contactNo:number;
  deleteContactNo:string;
  activeTo: Date;
  logo: string;
  createdBy:number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  nameCreatedBy:string;
  nameModifiedBy:string;
  email:string;
  blocks:[]
  facilitySkills:Array<facilitySkills>
  facilityContactPersons:Array<  {
    "id": number,
    "name": string,
    "designation":string,
    "contactNo": string,
    "email": string,
    "statusId": number,
    "isDeleted": true,
    "createdBy": number,
    "createdDate": Date,
    "modifiedBy": number,
    "modifiedDate": Date
  }>=[]
}

export class FacilityTypeError{
    skillId:Boolean;
    name:Boolean;
    facilityCode:boolean;
    address1:boolean;
    address2:boolean;
    postCode:boolean;
    stateId:boolean;
    countryId:boolean;
    facilitySkills:boolean;
    email:boolean;
    constructor(status){
      this.skillId = status;
      this.name = status;
      this.facilityCode= status;
      this.address1=status;
      this.address2=status;
      this.postCode=status;
      this.stateId=status;
      this.countryId=status;
      this.facilitySkills=status;
      this.email=status;
    }
  }
  