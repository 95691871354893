import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class ServiceType{
    ID: number
    NAME:string
    MODIFIEDBY: number
    MODIFIEDDAT: Date
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string;
    CREATEDBY: number
    CREATEDDATE: Date
}
export class ServiceTypeObj{
    alldata:Array<ServiceType>=[]
    filterData:Array<ServiceType>=[]
    selectedObj:ServiceType=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:ServiceType)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.ServicesType)
            .then((result:Array<ServiceType>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
             
                reject(err)
            })
        })

    }
}