import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { NotificationService } from '@progress/kendo-angular-notification';
import {ModelApi, PLANNER} from "src/ApiModel/const"
import { importPlannerDetailForAsset } from 'src/ApiModel/ImportApi/planner/asset';
import {Model} from "src/ApiModel/Model"
import {MaintenacePlannerDetail,MaintenacePlannerDetailObj} from "src/ApiModel/Model/MaintancePlannerDetail"
import { eventListner, EVENT_NAME } from 'src/event';
import { userid } from 'src/Util/const';
import { getCookie } from 'src/Util/Util';

class PPMError{
  asset:boolean=false;
  planner:boolean=false;
  assignee:boolean=false;
  assignTo:boolean=false;
}


@Component({
  selector: 'app-ppm-asset',
  templateUrl: './ppm-asset.component.html',
  styleUrls: ['./ppm-asset.component.scss']
})
export class PpmAssetComponent implements OnInit {
  @ViewChild("list", { static: false })
  public list: MultiColumnComboBoxComponent;
  @Input() id=0;
  public yearList=[
    {id:2022,name:"2022"},
    {id:2023,name:"2023"},
  ]
  public filterGrid={
    assetClassificatioon:null,
    assignee:null,
    assignTo:null,
    areaName:null,
    locationName:null,
    type:null,
    year:null,
    month:null,
    planner:null,
  }
  pobj:MaintenacePlannerDetailObj=new MaintenacePlannerDetailObj();
  importopened: boolean;
  public scheduleData={
    type:[
        {id:1,name:"Monthly - Date"},
        {id:2,name:"Monthly - Day"},
    ],
    date:[],
    day:[ 
      {id:1,name:"Sun"},
      {id:2,name:"Mon"},
      {id:3,name:"Tue"},
      {id:4,name:"Web"},
      {id:5,name:"Thr"},
      {id:6,name:"Fri"},
      {id:7,name:"Sat"}
    ],
    week:[],
    month:[]
  }

  public scheduleInfo={
    type:null,
    month:[],
    date:[],
    week:[],
    day:[]
  }
  constructor(public _notif:NotificationService) { 
    this.uiText=PLANNER[this.id]
    for(let i=1;i<31;i++){
      this.scheduleData.date.push({id:i,name:i})
      if(i<=5){
        this.scheduleData.week.push({id:i,name:i})
      }
    }
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });
  }
  ngOnDestroy(){
    this.deleteColumn=null;;
    this.editColumn=null;
   // console.log("component is destroy")
 }
  public uiText=PLANNER[this.id]
  public format = "dd/MM/yyyy";
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public data=new MaintenacePlannerDetail();
  public urlimg:any;
  public task:any=[];
  public actionsLayout = "normal";
  public column=[
    {field:"assetObj.assetNumber",title:"Asset no ", isFilter:true,width:200},
    {field:"assetObj.assetCode",title:"Asset Description", isFilter:true,width:200},
    {field:"year",title:"Year", isFilter:true,width:200},
    {field:"month",title:"Month", isFilter:true,width:200},
    {field:"plannerObj.ID",title:"PPM ID", isFilter:true,width:200},
    {field:"plannerObj.NAME",title:"PPM Name", isFilter:true,width:200},
    {field:"userObj.staffName",title:"Assignee", isFilter:true,width:200},
    {field:"contractorObj.name",title:"Assign To", isFilter:true,width:200},
  ]
  public error:PPMError=new PPMError();
  ngOnInit(): void {
   this.uiText=PLANNER[this.id]
    
    if(this.id===3){
      // this.column.push({field:"year",title:"Year", isFilter:true,width:200})
      // this.column.push({field:"month",title:"Month", isFilter:true,width:200})
      this.column.push({field:"date",title:"Day", isFilter:true,width:200})
    }
    else{
      // this.column.push({field:"month",title:"Month", isFilter:true,width:200})
    }
    this.model.getData(
      ModelApi.Asset,
      ModelApi.MaintenancePlanner,
      ModelApi.User,
      ModelApi.Contractor,
      ModelApi.assetClassfication,
      ModelApi.arealocation,
      ModelApi.levelarea,
      ModelApi.month,
      ModelApi.Year,
      ModelApi.AssetType,
      ModelApi.assetStardantion,
      ModelApi.manufacture,
      ModelApi.assetModel,
      ModelApi.Year,
      ModelApi.MaintenanceFrequency,
      ModelApi.ServicesType,
      ModelApi.ChekList
      )
    .then((result)=>{
      this.scheduleData.month=this.model.month.alldata;
      this.model.user.filterData=this.model.user.alldata.filter((d)=>d.categoryId==1)
      this.model.planner.filterData=this.model.planner.alldata.filter((d)=>d.MAINTENANCETYPEID==this.id);
      this.model.maintenaceFrequence.selectedObj=this.model.maintenaceFrequence.alldata.find((d)=>d.DESCRIPTION.toLowerCase()==="daily")
      this.manuculateAssetObj();
      this.fetchData()
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  manuculateAssetObj(){
   
    this.model.asset.alldata=this.model.asset.alldata.map((d)=>{
      let assetStan:any={};
      let assetClas:any={};
      let assetType:any={};
      let assetModel:any={};
      let assetManufacture:any={}
      
      if(d.assetStandardizationId){
      assetStan=this.model.assetStardantion.objectData[d.assetStandardizationId];
      }
      if(assetStan.assetTypeId){
         assetType=this.model.assetType.objectData[assetStan.assetTypeId]
      }
      if(assetType.ASSETCLASSIFICATIONID){
       assetClas=this.model.assetClassfication.objectData[assetType.ASSETCLASSIFICATIONID]
      }
      if(assetStan.assetModelId){
        assetModel=this.model.assetModel.objectData[assetStan.assetModelId];
      }
      if(assetStan.assetManufacturerId){
        assetManufacture=this.model.manufacture.objectData[assetStan.assetManufacturerId];
      }
      d["assetTypeObj"]=assetType;
      d["assetClassificationObj"]=assetClas;
      d["modelObj"]=assetModel;
      d["manufectureObj"]=assetManufacture;
      d["isSelected"]=false;
      return d;
    })
    this.model.asset.filterData=this.model.asset.alldata;
    console.log("manuculate asset=>",this.model.asset.filterData)
  }
  onDialogClose(type="new"){
    if(type=="new")
      this.opened=false;
    if(type=="importUpdate"){
      this.importopened = false
  }
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
     this.dailogTitle="Add "+PLANNER[this.id]?.title +" For Assets";
  }
  valuechange(type,data){
    if(type=="asset"){
      this.model.asset.selectedObj=data;
    }
    else if(type=="planner"){
      this.model.planner.selectedObj=data;
    }
    else if(type=="contractor"){
      this.model.contractor.selectedObj=data;
    }
    else if(type=="user"){
      this.model.user.selectedObj=data;
    }
    else if(type=="status"){
      this.model.status.selectedObj=data;
    }
  }
  filterChange(type,data){
    if(type=="assetClassificatioon"){
      this.filterGrid.assetClassificatioon=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee =data;
    }
    else if(type=="areaName"){
      this.filterGrid.areaName=data;
    }
    else if(type=="locationName"){
      this.filterGrid.locationName=data;
    }
    else if(type=="year"){
      this.filterGrid.year=data;
    }
    else if(type=="planner"){
      this.filterGrid.planner=data;
    }
    else if(type=="assignTo"){
      this.filterGrid.assignTo=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee=data;
    }
    else if(type=="month"){
      this.filterGrid.month=data;
    }
  }
  checkValidation(){
    let e=false;
    this.error=new PPMError();
    if(!this.model.asset.selectedObj){
       e=true;
       this.error.asset=true;
    }
    if(!this.model.planner.selectedObj){
      e=true;
      this.error.planner=true;
    }
   if(!this.model.user.selectedObj){
      e=true;
      this.error.assignee=true;
   }
   if(!this.model.contractor.selectedObj){
      e=true;
      this.error.assignTo=true;
   }
   return e;
  }
  saveData(){
    if(this.checkValidation()){
        return ;
    }
    console.log("selected=>",this.data,this.type," asset=>",this.model.asset.selectedObj)
   let assetsList:any= this.model.asset.selectedObj;
     Promise.all(assetsList.map((d)=>{
      this.data.ASSETID=d.id;
      this.data.BPMID=this.model.contractor.selectedObj.id;
      this.data.MAINTENANCEPLANNERID=this.model.planner.selectedObj.ID;
      this.data.USERID=this.model.user.selectedObj.id;
      this.data.SCHEDULETYPEID=this.scheduleInfo.type?.id;

      if(this.type==="new"){
        let month=[];
        let date=[];
        let day=[];
        let week=[]
        if(this.data.SCHEDULETYPEID==1){
           month=this.scheduleInfo.month.map((d)=>d.ID);
           date=this.scheduleInfo.date.map((d)=>d.id)
        }
        else if(this.data.SCHEDULETYPEID==2){
          month=this.scheduleInfo.month.map((d)=>d.ID);
          day=this.scheduleInfo.day.map((d)=>d.id);
          week=this.scheduleInfo.week.map((d)=>d.id);
        }
        let workorderObj={
          "maintenancePlannerId":this.data.MAINTENANCEPLANNERID ,
          "assetId": this.data.ASSETID,
          "locationId": null,
          "userId": this.model.user.selectedObj.id,
          "bpmid": this.model.contractor.selectedObj.id,
          "createdBy": getCookie(userid),
          "createdDate": new Date(),
          "modifiedBy": getCookie(userid),
          "modifiedDate": new Date() 
        }
        console.log("save workorder==>",workorderObj,JSON.stringify(workorderObj))
         this.data.addWorkOrder(this.id,workorderObj,month,date,week,day)
         return this.data.saveData()
      }
      else if(this.type=="update"){
        console.log("update data=>",this.data)
        return  this.data.updateData(this.data.ID)
      }
    }
   ))
   .then((result)=>{
   // this.fetchData()
    this.opened=false;
    this._notif.show({
      content: "Successfully saved.",
      type: { style: "success", icon: true },
    });
   console.log("workorder=>",this.model);
   this.fetchData();
    // result.forEach((d:any,i)=>{
    //   // if(d){
    //   //   this.data.saveWithWorkorder(this.id,workorderObj,month,date,week,day)
    //   // }
    // })
   })
   .catch((err)=>{
    this._notif.show({
      content: "Fail",
      type: { style: "error", icon: true },
    });
     console.log(err)
   })
    // this.data.ASSETID=this.model.asset.selectedObj.id;
    // this.data.BPMID=this.model.contractor.selectedObj.id;
    // this.data.MAINTENANCEPLANNERID=this.model.planner.selectedObj.ID;
    // this.data.USERID=this.model.user.selectedObj.id;
    // if(this.type==="new"){
    //   console.log(this.data);
    //    this.data.saveData()
    //    .then((result)=>{
    //         console.log("save",result)
    //         this.getData()
    //         this.opened=false;
    //    })
    //    .catch((err)=>{
    //       console.log("err",err)
    //    })
    // }
    // else if(this.type=="update"){
    //   this.data.updateData(this.data.ID)
    //   .then((result)=>{
    //        console.log("save",result)
    //        this.getData()
    //        this.opened=false;
    //   })
    //   .catch((err)=>{
    //      console.log("err",err)
    //   })
    // }
  }
  resetFetch(){
    this.filterGrid={
      assetClassificatioon:null,
      assignee:null,
      assignTo:null,
      areaName:null,
      locationName:null,
      type:null,
      year:null,
      month:null,
      planner:null,
    }
  }
  reset(){
     this.model.asset.selectedObj=null || []
     this.model.planner.selectedObj=null;
     this.model.user.selectedObj=null;
     this.model.contractor.selectedObj=null;
     this.model.asset.filterData=this.model.asset.filterData.map((d)=>{
       d["isSelected"]=false;
       return d;
     })
     this.scheduleInfo={
      type:null,
      month:[],
      date:[],
      week:[],
      day:[]
    }
  }

  getData(){
    // this.model.getData(ModelApi.MaintenancePlannerDetail)
    // .then((result)=>{
    //   this.task=this.model.plannerDetail.alldata.filter((d)=>{
    //       return d.ASSETID && this.model.planner.objectData[d.MAINTENANCEPLANNERID]?.MAINTENANCETYPEID==this.id   
    //   }).map((d)=>{
    //     if(d.ID){
    //       d["assetObj"]=this.model.asset.objectData[d.ASSETID];
    //       d["plannerObj"]=this.model.planner.objectData[d.MAINTENANCEPLANNERID];
    //       d["contractorObj"]=this.model.contractor.objectData[d.BPMID];
    //       d["userObj"]=this.model.user.objectData[d.USERID];
    //     }
    //     return d;
    //    })
    // })
  }
  editColumn(d){
    let d1=d.dataItem;
    this.data.ID=d1.ID
     this.model.asset.selectedObj=[d1["assetObj"]]
     this.model.planner.selectedObj=d1["plannerObj"];
     this.model.contractor.selectedObj=d1["contractorObj"];
     this.model.user.selectedObj=d1["userObj"]
     let month=[];
     let date=[];
     let day=[];
     let week=[]
    //  this.scheduleInfo.type=this.scheduleData.type.find((d)=>d.id==d1.SCHEDULETYPEID)
    //   let sObj=this.pobj.fetchObj[d1.ID];
    //   this.scheduleInfo.month=sObj.month.map((m:any)=>{
    //         return this.scheduleData.month.find((d)=>d.id==m)      
    //   })
     this.type="update";
     this.opened=true;
     this.dailogTitle="Update "+PLANNER[this.id]?.title +" For Assets";

  }

 deleteColumn(d){
  this._notif.show({
    content: "PPM/PDM/DI cannot be deleted because it is tied to asset or location",
    type: { style: "error", icon: true },
  });
    // this.data.deleteData(d.dataItem)
    // .then((result)=>{
    //   this.fetchData();
    //   this._notif.show({
    //     content: "Successfully saved.",
    //     type: { style: "success", icon: true },
    //   });
    // })
    // .catch((err)=>{
    //   this._notif.show({
    //     content: "Fail",
    //     type: { style: "error", icon: true },
    //   });
    // })
 }

 fetchData(){
  let plannerId=this.filterGrid.planner && this.filterGrid.planner.ID;
  let Year=this.filterGrid.year && this.filterGrid.year.ID;
  let Month=this.filterGrid.month && this.filterGrid.month.ID;
  let RefType="asset";
  let AssetNo=null;
  let AssetClassificationID=this.filterGrid.assetClassificatioon && this.filterGrid.assetClassificatioon.id;;
  let AssetTypeID=null;
  let FacilityID=null;
  let BlockID=null;
  let LevelID=null;
  let AreaID=null;
  let LocationID=null;
  let Assignee=this.filterGrid.assignee && this.filterGrid.assignee.id;
  let AssignTo=this.filterGrid.assignTo && this.filterGrid.assignTo.id;
  this.pobj.filterByData(plannerId,Year,Month,RefType,AssetNo,AssetClassificationID,AssetTypeID,FacilityID,BlockID,LevelID,AreaID,LocationID,Assignee,AssignTo)
  .then((result)=>{
   this.task=this.pobj.alldata.filter((d)=>{
     return this.model.asset.objectData[d.ASSETID] && this.model.planner.objectData[d.MAINTENANCEPLANNERID]?.MAINTENANCETYPEID==this.id   
 }).map((d)=>{
   if(d.ID){
    d["assetObj"]=this.model.asset.objectData[d.ASSETID];
    d["plannerObj"]=this.model.planner.objectData[d.MAINTENANCEPLANNERID];
    d["contractorObj"]=this.model.contractor.objectData[d.BPMID];
    d["userObj"]=this.model.user.objectData[d.USERID]
   }
   return d;
  })
   // console.log("pobj==>",this.pobj)
  })
}
selectedAsset(asset){
  console.log("asset",asset)
  let status=!asset.isSelected;
  let assetList:any=this.model.asset.selectedObj || [];
  assetList=assetList.filter((d)=>d.id!==asset.id);
  if(status){
    assetList.push(asset);
  }
  this.model.asset.selectedObj=assetList;
  this.model.asset.filterData=this.model.asset.filterData.map((d)=>{
    if(d.id==asset.id){
      d["isSelected"]=status;
    }
     return d
  })
}
closeEvent(event){
  console.log("event=>",event);
  event.preventDefault();
}
openMultiselect(e){
  console.log("open event=>",e,this.list)
  this.list.toggle()
}
closeMultiSelect(e){
  console.log("close event=>",e,this.list)
  this.list.toggle()
}
importData(){
  this.importopened = true
}
download() {
  if(this.id==1){
  window.open("/assets/template/ppm_for_asset.xlsx")
  }
  else if(this.id==2){
    window.open("/assets/template/PDM_for_assets.xlsx")
  }
  else if(this.id==3){
    window.open("/assets/template/DI_for_assets.xlsx")
  }
}
importSave(){
    // this.getBPMData()
    // this.getBpmContractAsset("dbo","BPMContractAsset")
    this.importopened= false;
}
uploadChange(event){
  console.log(event);
  let ppmData=event;
  if(this.id==3){
    ppmData=event.map((d)=>{
      d["Frequency"]=this.model.maintenaceFrequence.selectedObj.DESCRIPTION;
      return d;
    })
  }
  importPlannerDetailForAsset(
         ppmData,
         this.model.service.alldata,
         this.model.asset.alldata,
         [],
         [],
         this.model.maintenaceFrequence.alldata,
         this.model.user.alldata,
         this.model.contractor.alldata,
         this.model.checklist.alldata,
         this.id,
         this.scheduleData.type,
         this.model.month.alldata,
         this.scheduleData.day         
         )
    .then((result)=>{
     console.log(result)
     this._notif.show({
      content: "Successfully saved.",
      type: { style: "success", icon: true },
    });
    this.importopened=false;
      setTimeout(()=>{
        this.model.planner.getData()
        .then((result)=>{
          this.fetchData();
        })
      },2000)
    })
    .catch((err)=>{
      this.importopened=false;
      console.log(err)
      this._notif.show({
        content: "Fail",
        type: { style: "error", icon: true },
      });
    })
}
}