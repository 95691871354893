import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class Year{ 
    ID:number;
    NAME:string;
}

export class YearObj{
    alldata:Array<Year>=[]
    filterData:Array<Year>=[]
    selectedObj:Year=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Year)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
              let result:Array<Year>=[]
               for(let i=-2;i<10;i++){
                   let d=new Date();
                   d.setFullYear(d.getFullYear()+i);
                   result.push({ID:d.getFullYear(),NAME:d.getFullYear().toString()})
               }
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
        })

    }
}