import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"
import {ModelApi, PLANNER} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {MaintenacePlannerDetail} from "src/ApiModel/Model/MaintancePlannerDetail"
import HttpClient from 'src/Util/ApiHandling';
import { Router } from '@angular/router';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"


@Component({
  selector: 'app-maintenence-status',
  templateUrl: './maintenence-status.component.html',
  styleUrls: ['./maintenence-status.component.scss']
})
export class MaintenenceStatusComponent {
  @Input() id=0;
  
  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
  public model:Model=new Model();
  public permission:Permission=new Permission();
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];

  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]

  public column2=[
    {field:"workOrderNo",title:"Work Order No.",isFilter:true,width:200},
    {field:"work_order_date",title:"Work Order Date",type:"date", isFilter:true,width:200},
    {field:"workOrderType",title:"Work Order Type", isFilter:true,width:200},
    {field:"consequenceCategory",title:"Consequence  Category", isFilter:true,width:200},
    {field:"part_No",title:"Part No.", isFilter:true,width:150},
    {field:"part_desc",title:"Part Description", isFilter:true,width:200},
    {field:"quantity",title:"Quantity", isFilter:true,width:130},
    {field:"total_downtown",title:"Total Downtime", isFilter:true,width:200},
  ];
  public areagrid=[
   
  ];
// public gridData=sampleProducts;
      
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  public value: Date = new Date(2000, 2, 10);
  // public value2: Date = new Date(2000, 3, 10);

  maintenence_status: FormGroup;
  submitted = false;
  // facility = ['PTT','Menara 3'];
  // status = ['Active','Inactive'];
  
  constructor(private formBuilder: FormBuilder,private router : Router) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e.dataItem)})
   }
   editColumn(data){
     let woI=data.workOrderNo;
     let woId=data.workOrderNo;
     console.log(data)
     if(woI){
       let spt=woI.split("/")
       woI=spt[spt.length-1];
     }
      this.router.navigate(["/work-order-query"],{queryParams:{id:woI,type:"asset",wo:woId}});
   }

  ngOnInit() {
    if(UserModuleObj.fesmsasset_asset){
      this.permission=UserModuleObj.fesmsasset_asset;
    }
    this.getMaintData();
    // this.model.getData(ModelApi.WorkerOrder)
    // .then((e)=>{
    //     let x = this.model.workOrder.alldata.filter((d)=>{
    //           if(d.referenceType=="asset" && d.referenceId==this.id){
    //                     return true;
    //           }
    //           else{
    //             return false;
    //           }
    //     })
    //     if(x.length>0){
        
    //          this.getSparePartRequest(x[0].id).then((f:any)=>{
    //             this.areagrid = f;
    //          })
    //     }
    // })
}

  // convenience getter for easy access to form fields
 

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }
  onDialogClose(){
    this.submitted = false;
    this.maintenence_status.reset();
    this.opened=false;
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Add Maintenence Status"
    }
    if(type=="update"){
      this.dailogTitle="Edit Maintenence Status"
    }
    this.opened=true;
  }

  getSparePartRequest(id){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/Assets/GetPartNoByWOID?Id="+id)
      .then((result)=>{
           resolve(result)
      })
      .catch((err)=>{
        reject(err);
        console.log(err)
      })
    })
  }

  download() {
    let fileName = 'SparePrtUpdate.csv';

    let columnNames = ["binNo",
    "currentQuantity", 
    "facilityCode",
    "facilityName",
    "minimumLevel", 
    "month",
    "monthValue", 
    "partDescription", 
    "partNo", 
    "sparePartType",
    "uoM", 
    "year",];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';

    this.areagrid.map(c => {
      csv += [c["binNo"],
      c["currentQuantity"], 
      c["facilityCode"],
      c["facilityName"],
      c["minimumLevel"], 
      c["month"],
      c["monthValue"], 
      c["partDescription"], 
      c["partNo"], 
      c["sparePartType"],
      c["uoM"], 
      c["year"],].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  
  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }


  getMaintData(){
     HttpClient.get("/api/PMC/GetMaintenanceHistory", true,true)
      .then((result: any) => {

        let data = result.filter((data)=>{
                return data.referenceType === "asset" && data.referenceID == this.id
        })

          this.areagrid = data.map((e)=>{
          e["work_order_date"] = e.workOrderDate
          if(e.workorderParts!=[] && e.workorderParts.length>0){
            e["part_No"] = e.workorderParts[0].partNo
            e["part_desc"] = e.workorderParts[0].partDescription
            e["quantity"] = e.workorderParts[0].quantity
            e["total_downtown"] = e.workorderParts[0].totalDownTime + " (MM:DD:HH)"
          }
          return e;
        })

        // console.log("result",   this.areagrid)
      })
    }
      //  this.assetClassDropDown= result.map((e=>{
      //       return {id:e.id,name:e.code}
      //   }))
      //   resolve(result)
                
    
      
}
