import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class Facility{
    ID: number;
    NAME: string;
    FACILITYCODE: any;
    facilityCode: string;
    ADDRESS1: string;
    ADDRESS2: string;
    POSTCODE: string;
    STATEID: number;
    COUNTRYID: number;
    PHONENO: string;
    FAXNO: string;
    ACTIVEFROM: Date;
    ACTIVETO:string;
    LOGO: string;
    CREATEDBY:number;
    CREATEDDATE: Date;
    MODIFIEDBY: number;
    MODIFIEDDATE: Date;
    NAMECREATEDBY:string;
    NAMEMODIFIEDBY:string
}

export class FacilityObj{
    alldata:Array<Facility>=[]
    filterData:Array<Facility>=[]
    selectedObj:Facility=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Facility)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.facility)
            .then((result:Array<Facility>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
            
                reject(err)
            })
        })

    }
}