<div class="card m-3"   >
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Asset Registration</h5>
        <div> 
                  
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
                Download Template 
            </button>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="importopened=true" class="header-right-btn" style="margin-right: 0.5rem;">
               Import
            </button>
                <button kendoButton  primary="true" (click)="  download()"  *ngIf="permission && permission.isExport" class="header-right-btn">
                Export
               </button>  &nbsp;  &nbsp;  &nbsp; 
             
            
          <button kendoButton  primary="true"  *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
                Add Asset Registration
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"   >
     
        <app-table-grid  
        [id]="id"
        [column]="column"
        [data]="gridData"
        [qrcode] = "true"
        [size]="10"
        [editFlag]="permission && permission.isUpdate"
        [deleteFlag]="permission && permission.isDelete"
      ></app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab  title="Asset Register" [selected]="true">
            <ng-template kendoTabContent >
                <div class="card m-3">
                    <div class="card-header" >
                        <h5> {{dailogTitle}}</h5>
                        <div>      
                          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
                               Add Contractor And Vendor
                          </button> -->
                        </div>
                    </div>
                    <div class="card-body register-form-body">
                        <div class="form-row">
                            <div class="form-group col-5">
                                <label>Asset Standardization<span class="required">*</span></label>
                                <div style="width:100%">
                                <!-- <kendo-dropdownlist [data]="assStandDropDown"  class="form-control"
                                [textField]="'name'"
                                [valueField]="'id'"
                                [valuePrimitive]="true"
                                (valueChange)="assetStandValueChange($event)"
                                [(ngModel)]="assetRegForm.assetStandardizationId"
                                [disabled]="assetStandDisable"
                                ngDefaultControl
                                >
                                </kendo-dropdownlist> -->
                                <kendo-multicolumncombobox
                                [data]="assStandDropDown"
                                [listHeight]="300"
                                textField="name"
                                valueField="id"
                                [valuePrimitive]="true"
                                (valueChange)="assetStandValueChange($event)"
                                [(value)]="assetRegForm.assetStandardizationId"
                                [disabled]="assetStandDisable"
                              >
                                <kendo-combobox-column field="name" title="Asset Type Code" [width]="200">
                                  <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                                    <!-- <img class="contact-image" [src]="getContactImageUrl(dataItem.id)" /> -->
                                    <span>{{ dataItem.assetTypeCode && dataItem.assetTypeCode.code }}</span>
                                  </ng-template>
                                </kendo-combobox-column>
                                <kendo-combobox-column field="assetClassification" title="Asset Classification Code" [width]="200">
                                    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                                      <!-- <img class="contact-image" [src]="getContactImageUrl(dataItem.id)" /> -->
                                      <span>{{ dataItem.assetClassification && dataItem.assetClassification.code }}</span>
                                    </ng-template>
                                  </kendo-combobox-column>
                                  <kendo-combobox-column field="model" title="Model" [width]="200">
                                    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                                      <!-- <img class="contact-image" [src]="getContactImageUrl(dataItem.id)" /> -->
                                      <span>{{ dataItem.assetModel}}</span>
                                    </ng-template>
                                  </kendo-combobox-column>
                                  <kendo-combobox-column field="Manufacturer" title="Manufacturer" [width]="200">
                                    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                                      <!-- <img class="contact-image" [src]="getContactImageUrl(dataItem.id)" /> -->
                                      <span>{{ dataItem.assetManufacture}}</span>
                                    </ng-template>
                                  </kendo-combobox-column>
                                <!-- <kendo-combobox-column field="jobTitle" title="Title" [width]="200">
                                </kendo-combobox-column>
                                <kendo-combobox-column field="company" title="Company" [width]="200">
                                </kendo-combobox-column> -->
                                <!-- <ng-template kendoMultiColumnComboBoxFooterTemplate>
                                  <strong> {{ contacts.length }} records in total </strong>
                                </ng-template> -->
                              </kendo-multicolumncombobox>
                                <kendo-formerror *ngIf="error2.assetStandardizationId">*Required</kendo-formerror>
                            </div>
                        </div>
                            <div class="form-group col-5">
                              
                            </div>
                           
                        </div>
                                    <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Asset No.<span class="required">*</span></label>
                                            <div style="width:100%">
                                            <input kendoTextBox  class="form-control"    [(ngModel)]="assetRegForm.assetNumber" />
                                            <kendo-formerror *ngIf="error2.assetNumber">*Required</kendo-formerror>
                                        </div>
                                        </div>
                                        <div class="form-group col-5">
                                            <label>Asset Classification</label>
                                            <kendo-dropdownlist  [data]="assetClassDropDown" disabled [textField]="'code'"
                                            [valueField]="'id'"
                                            [valuePrimitive]="true" class="form-control" [(ngModel)]="assetClassificationValue "
                                         >
                                            </kendo-dropdownlist>
                                        </div>
                                       
                                    </div>
                                     <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Asset Id</label>
                                            <input kendoTextBox class="form-control"  [(ngModel)]="assetRegForm.assetCode" disabled/>
                                        </div>
                                        <div class="form-group col-5">
                                            <label>Asset Type code</label>
                                            <input kendoTextBox class="form-control" [(ngModel)]="assetTypeCode" ngDefaultControl disabled/>
                                            
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Asset Type Description</label>
                                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetTypeDesc" ngDefaultControl disabled/>
                                        </div>
                                        <div class="form-group col-5">
                                            <label>Model</label>
                                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetModel" ngDefaultControl  disabled/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Manufacturer</label>
                                            <input kendoTextBox   class="form-control" [(ngModel)]="assetManufacture" ngDefaultControl disabled/>
                                        </div>
                                        <div class="form-group col-5">
                                            <label>Serial No.</label>
                                            <input kendoTextBox    [(ngModel)]="assetRegForm.serialNo" ngDefaultControl class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Contract Type</label>
                                            <kendo-dropdownlist   [data]="contractTypeDropDown"  [textField]="'NAME'"
                                            [valueField]="'ID'"
                                            [valuePrimitive]="true" class="form-control"  [(ngModel)]="assetRegForm.contractTypeId" ngDefaultControl>
                                            </kendo-dropdownlist>
                                        </div>
                                        <div class="form-group col-5">
                                            <label>Chasis No</label>
                                            <input kendoTextBox [(ngModel)]="assetRegForm.chasisNo" ngDefaultControl class="form-control"/>
                                        </div>
                                        
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-5">
                                            <label>Engine No.</label>
                                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetRegForm.engineNo" ngDefaultControl/>
                                        </div>
                                        <div class="form-group col-5">
                                      </div>
                                    </div>
                                       <div class="card m-3">
                                    <div class="card-header py-2" >
                                        <h6 class="mb-0"> Asset Info</h6>
                                    </div>
                                    <div class="card-body register-form-body" >
                                             
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Commissioning Date</label>
                                                    <kendo-datetimepicker [format]="format" [(value)]="commictionvalue" [(ngModel)]="assetInforForm.commissioningDate " ngDefaultControl>
                                                    </kendo-datetimepicker>
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Service Start Date</label>
                                                    <kendo-datetimepicker [format]="format" [(value)]="servicestartvalue" [(ngModel)]="assetInforForm.serviceStartDate " ngDefaultControl (valueChange)="getAssetAge($event)">
                                                    </kendo-datetimepicker>
                                                </div>
                                                
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Service Stop Date</label>
                                                    <kendo-datetimepicker [format]="format" [(value)]="servicestopvalue"  [(ngModel)]="assetInforForm.serviceEndDate" ngDefaultControl (valueChange)="getYearInService($event)">
                                                    </kendo-datetimepicker >
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Expected Lifespan (Years)</label>
                                                    <input kendoTextBox  class="form-control" [(ngModel)]="assetInforForm.lifeSpan" ngDefaultControl/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Asset Status</label>
                                                    <kendo-dropdownlist  [data]="assetStatusDropDown"  [textField]="'name'"
                                                    [valueField]="'id'"
                                                    [valuePrimitive]="true" [(ngModel)]="assetInforForm.statusId" class="form-control" >
                                                    </kendo-dropdownlist>
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Asset Age (Year / Month)</label>
                                                    <input kendoTextBox  class="form-control" [(ngModel)]="assetInforForm.assetAge" ngDefaultControl disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Years in Service (Year / Month)</label>
                                                    <input kendoTextBox  class="form-control"  [(ngModel)]="assetInforForm.assetAge" ngDefaultControl disabled/>
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Operating Hours</label>
                                                    <input kendoTextBox  class="form-control"   [(ngModel)]="assetInforForm.operatingHours" ngDefaultControl />
                                                </div>
                                            </div>
                                        
                                        </div>
                                        
                       
                                </div>
                
                                <div class="card m-3">
                                    <div class="card-header py-2" >
                                        <h6 class="mb-0"> Location Details</h6>
                                    </div>
                                    <div class="card-body register-form-body">
                                        
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Facility<span class="required">*</span></label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist  [data]="facilityDropDown"
                                                    class="form-control"
                                                    [textField]="'name'"
                                                    [valueField]="'id'"
                                                    [valuePrimitive]="true"
                                                    [(ngModel)]="assetLocationForm.facilityId" ngDefaultControl
                                                    (valueChange)="facilityValueChange($event)">
                                                    </kendo-dropdownlist>
                                                    <kendo-formerror *ngIf="error1.facilityId">*Required</kendo-formerror>
                                                </div>
                                                </div>
                                            
                                                <div class="form-group col-5">
                                                    <label>Block Name</label>
                                                    <input kendoTextBox  class="form-control" ngDefaultControl  [(ngModel)]="blockName" disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Block Code<span class="required">*</span></label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist    class="form-control"    [data]="filterdBlockData"
                                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"     [(ngModel)]="assetLocationForm.blockId" ngDefaultControl  [filterable]="true" (valueChange)="blockValueChange($event)" >
                                                    </kendo-dropdownlist>
                                                    <kendo-formerror *ngIf="error1.blockId">*Required</kendo-formerror>
                                                </div>
                                                </div>
                                            
                                                <div class="form-group col-5">
                                                    <label>Level Name</label>
                                                    <input kendoTextBox  class="form-control" [(ngModel)]="levelName" ngDefaultControl readonly />
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Level Code<span class="required">*</span></label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist class="form-control"   [data]="filterdLevelData"
                                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"  [filterable]="true"  [(ngModel)]="assetLocationForm.levelId"  ngDefaultControl (valueChange)="levelValueChange($event)">
                                                    </kendo-dropdownlist>
                                                    <kendo-formerror *ngIf="error1.levelId">*Required</kendo-formerror>
                                                </div>
                                            </div>
                                                <div class="form-group col-5">
                                                    <label>Area Name</label>
                                                    <input kendoTextBox  class="form-control" [(ngModel)]="areaName" ngDefaultControl disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Area Code<span class="required">*</span></label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist    class="form-control"  [data]="filterdAreaData"
                                                    [textField]="'code'" [valueField]="'id'"    [valuePrimitive]="true" ngDefaultControl (valueChange)="areaValueChange($event)"  [(ngModel)]="assetLocationForm.areaId" [filterable]="true">
                                                    </kendo-dropdownlist>
                                                    <kendo-formerror *ngIf="error1.areaId">*Required</kendo-formerror>
                                                </div>
                                                </div>
                                            
                                                <div class="form-group col-5">
                                                    <label>Location Name</label>
                                                    <input kendoTextBox class="form-control" [(ngModel)]="locationName" ngDefaultControl disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Location Code<span class="required">*</span></label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist   class="form-control"
                                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true" ngDefaultControl [data]="filterdLocationData" (valueChange)="locationValueChange($event)" [(ngModel)]="assetLocationForm.locationId" [filterable]="true" >
                                                    </kendo-dropdownlist>
                                                    <kendo-formerror *ngIf="error1.locationId">*Required</kendo-formerror>
                                                </div>
                                            </div>
                                                <div class="form-group col-5">
                                                    <label>Sub Location Name</label>
                                                    <input kendoTextBox [(ngModel)]="subLocationName" class="form-control" ngDefaultControl disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Sub Location Code</label>
                                                    <div style="width:100%">
                                                    <kendo-dropdownlist   class="form-control"    [data]="filterdSubLocationData"
                                                    [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"  [(ngModel)]="assetLocationForm.subLocationId"  ngDefaultControl [filterable]="true" (valueChange)="subLocValueChange($event)">
                                                    </kendo-dropdownlist>
                                                    <!-- <kendo-formerror *ngIf="error1.subLocationId">*Required</kendo-formerror> -->
                                                </div>
                                                </div>
                                                <div class="form-group col-5">
                
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="card m-3">
                                    <div class="card-header py-2" >
                                        <h6 class="mb-0"> Asset Maintenance</h6>
                                    </div>
                                    <div class="card-body register-form-body">
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>PPM Flag</label>
                                                    <input kendoTextBox class="form-control" [(ngModel)]=" assetMaintenaceForm.ppmflag"  disabled/>
                                                </div>
                                                <div class="form-group col-5">
                                                    <div *ngIf="hideCreatePpmPlanner">
                                                        <label>Create PPM Planner </label>
                                                        <a [routerLink]="['/ppm']" [(ngModel)]=" assetMaintenaceForm.ppmcreated"  ngDefaultControl style="color: #347bd5;text-decoration: none;">Go</a>
                                                    </div>
                                                   
                                                    
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Routine Inspection (DI) Flag</label>
                                                    <input kendoTextBox class="form-control"  [(ngModel)]=" assetMaintenaceForm.di" ngDefaultControl disabled/>
                                                   
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Last Scheduled Work Order No.</label>
                                                    <input kendoTextBox class="form-control"  [(ngModel)]=" assetMaintenaceForm.lastScheduledWorkOrderNo" ngDefaultControl  disabled/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Last Unscheduled Work Order No.</label>
                                                    <input kendoTextBox class="form-control" [(ngModel)]=" assetMaintenaceForm. lastUnScheduledWorkOrderNo" ngDefaultControl disabled/>
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Last Work Scheduled Work Order Date</label>
                                                    <kendo-datetimepicker [format]="format" [(ngModel)]=" assetMaintenaceForm. lastScheduledWorkOrderDate" ngDefaultControl  [(value)]="lastworksc" disabled>
                                                    </kendo-datetimepicker>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Last Work Unscheduled Work Order Date</label>
                                                    <kendo-datetimepicker [format]="format" [(value)]="lastworkusc" [(ngModel)]=" assetMaintenaceForm.lastUnScheduledWorkOrderDate" ngDefaultControl disabled>
                                                    </kendo-datetimepicker>
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Total Downtime (YTD)</label>
                                                    <input kendoTextBox class="form-control" [(ngModel)]=" assetMaintenaceForm.  totalDownTime" ngDefaultControl  disabled>
                                                </div>
                                            </div>
                                            
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Cumulative Parts Cost (RM)</label>
                                                    <input kendoTextBox class="form-control" [(ngModel)]=" assetMaintenaceForm. cumulativepartsCost" ngDefaultControl />
                                                </div>
                                                <div class="form-group col-5">
                                                    <label>Cumulative Labour Cost (RM)</label>
                                                    <input kendoTextBox  class="form-control"  [(ngModel)]=" assetMaintenaceForm.cumulativeLabourCost" ngDefaultControl/>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-5">
                                                    <label>Cumulative Contract Cost (RM)</label>
                                                    <input kendoTextBox  class="form-control" [(ngModel)]=" assetMaintenaceForm.cumulativeContractCost" ngDefaultControl/>
                                                </div>
                                                <div class="form-group col-5">
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <!-- <div class="text-center mt-3 mb-4">
                                    <button kendoButton primary="true" class="mr-2">Save</button>
                                    <button kendoButton primary="true" class="mr-2">Download Template</button>
                                    <button kendoButton primary="true" class="mr-2">Delete</button>
                                </div> -->
                                         
                    </div>
                </div>
                
                <!-- <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="920">
                    <kendo-dialog-titlebar>
                      <div style="font-size: 18px; line-height: 1.3em;">
                         {{dailogTitle}}
                      </div>
                    </kendo-dialog-titlebar>
                          
                          
                    <kendo-dialog-actions [layout]="actionsLayout">
                      <button kendoButton (click)="onDialogClose()">Cancel</button>
                      <button kendoButton primary="true">
                            Submit
                      </button>
                    </kendo-dialog-actions>
                  </kendo-dialog> -->
             
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab *ngIf="type=='update'" title="Warranty Coverage">
            <ng-template kendoTabContent>
                <app-warranty-coverage  [id]="attachAssetRegId"></app-warranty-coverage>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Maintenance History">
            <ng-template kendoTabContent>
                <app-maintenence-status [id]="attachAssetRegId"></app-maintenence-status>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab *ngIf="type=='update'" title="Attachments">
            <ng-template kendoTabContent>
                <app-attachments [attachAssetRegId] ="attachAssetRegId"></app-attachments>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <kendo-dialog-actions >
        <button kendoButton (click)="onDialogClose('new')">Cancel</button>
        <button *ngIf="type=='new'" (click)="onReset()"  kendoButton primary="true">
          Reset
      </button>
        <button *ngIf="type=='new'" (click)="onSubmit()" kendoButton primary="true">
          Save
      </button>
      <button  *ngIf="type=='update'" (click)="onSubmit()" kendoButton primary="true">
          Update
      </button>
    </kendo-dialog-actions>
        
  </kendo-dialog>

<div class="row mb-3"></div>


 <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeAsset()">Delete</button>
    
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="qrCodeDialogOpen" (close)="onDialogClose('qrcode')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
       QR Code
      </div>
    </kendo-dialog-titlebar>
    <!-- <div style="width: 189px;height: 76px;margin:auto;display: flex;border: 1px solid gray;    padding: 5px;    border-radius: 10px;justify-content: space-between;"> 
      <div>
        <div style="text-align: center;">
          <img src="assets/logo.png"  style="height: 33px;">
          <p style="font-size: 8px;margin-bottom: 0; border: 1px solid gray;padding: 1px;">{{assetQrCodeValue}}</p>
          <p style="font-size: 6px;margin-bottom: 0;padding: 3px;"> {{locationQrValue}}</p>
        </div>
      </div>
      <div>
        <img [src]="imageUrl+qrCodeGenUrl"  style="width: 54px;margin-top: 5px;">
      </div>
    </div> -->
  
    <!-- <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton primary="true" (click)="viewQR()"  *ngIf="permission && permission.isPrint">Print</button>
    </kendo-dialog-actions> -->
    <div  *ngIf="qrcodePrintData.length>0"  >
        <div style="background: white; padding: 1rem;">
    <kendo-pdf-export   #pdf paperSize="A4">
      
        <div  *ngFor ="let item of qrcodePrintData;let i=index" [ngClass]="(i!=0)?'page-break':''">
            <div style="width: 100%;display: flex;justify-content: center;margin: 1rem;">
             <div style="border: 2px solid black;border-radius: 0.25rem;width: 170px;display: grid;grid-template-columns: 55% 45%;">
                <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;padding: 0.15rem;">
                    <div style="display: flex;justify-content: center;"><img src="assets/logo.png"  style="height: 20px;"></div>
                    <div style="color: black;text-align: center;font-size: 8px;margin:0.5rem 0.15rem;"><span style="padding: 0.15rem;border: 1px solid black;">{{item.assetCode}}</span></div>
                    <div style="color: black;text-align: center;font-size: 8px;">{{item.assetNo}}</div>
                </div>
                <div style="display: flex;justify-content: center;flex-direction: column;padding: 0.15rem;">
                   <kendo-qrcode
                   style="width: 97%;"
                   value={{item.data}}
                   errorCorrection="M"
                 >
                 </kendo-qrcode>
                </div>
                </div>
            </div>
            </div>
  
         </kendo-pdf-export> 
         </div>
      <kendo-dialog-actions [layout]="actionsLayout">
        <!-- <button kendoButton (click)="closePrintModel()">Cancel</button> -->
        <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
              Print
        </button>
      </kendo-dialog-actions>
      </div>
  </kendo-dialog>

  <kendo-dialog *ngIf="false" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=2
                style="width: 100%;"
                (valueChange)="valueChange($event)"
             >
           </app-file-model>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <button kendoButton primary="true" (click)="uploadSave()">
            Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <!-- <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [fileUploadType]=2
                   (valueChange)="refreshData($event)"
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog> -->

  <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=4
                (valueChange)=importDataChange($event)
                style="width: 100%;"
          >
           </app-file-model>
            </div>
             </div>
<kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="fileUpload()" >
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>