import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import {ModelApi, PLANNER} from "src/ApiModel/const"
import { importPlannerDetailForAsset, importPlannerDetailForLocation } from 'src/ApiModel/ImportApi/planner/asset';
import {Model} from "src/ApiModel/Model"
import {MaintenacePlannerDetail,MaintenacePlannerDetailObj} from "src/ApiModel/Model/MaintancePlannerDetail"
import { eventListner, EVENT_NAME } from 'src/event';
import { userid } from 'src/Util/const';
import { getCookie } from 'src/Util/Util';
class PPMError{
  location:boolean=false;
  planner:boolean=false;
  assignee:boolean=false;
  assignTo:boolean=false;
  level:boolean=false;
  block:boolean=false;
  area:boolean=false;
  facility:boolean=false;
}
@Component({
  selector: 'app-ppm-on-location',
  templateUrl: './ppm-location.component.html',
  styleUrls: ['./ppm-location.component.scss']
})
export class PpmLocationComponent implements OnInit {

  @Input() id=0;
  public yearList=[
    {id:2022,name:"2022"},
    {id:2023,name:"2023"},
  ]
  public filterGrid={
    assetClassificatioon:null,
    assignee:null,
    assignTo:null,
    areaName:null,
    type:null,
    year:null,
    month:null,
    facility:null,
    planner:null,
    block:null,
    level:null,
    area:null,
    location:null
  }
  pobj:MaintenacePlannerDetailObj=new MaintenacePlannerDetailObj();
  importopened: boolean;
  public scheduleData={
    type:[
        {id:1,name:"Monthly – Date"},
        {id:2,name:"Monthly – Day"},
    ],
    date:[],
    day:[ 
      {id:1,name:"Sun"},
      {id:2,name:"Mon"},
      {id:3,name:"Tue"},
      {id:4,name:"Web"},
      {id:5,name:"Thr"},
      {id:6,name:"Fri"},
      {id:7,name:"Sat"}
    ],
    week:[],
    month:[]
  }

  public scheduleInfo={
    type:null,
    month:[],
    date:[],
    week:[],
    day:[]
  }
  public error:PPMError=new PPMError();
  constructor(public _notif:NotificationService) { 
    this.uiText=PLANNER[this.id]
    for(let i=1;i<31;i++){
      this.scheduleData.date.push({id:i,name:i})
      if(i<=5){
        this.scheduleData.week.push({id:i,name:i})
      }
    }
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  ngOnDestroy(){
    this.deleteColumn=null;
    this.editColumn=null;
   // console.log("component is destroy")
 }
  public uiText=PLANNER[this.id]
  public format = "dd/MM/yyyy";
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public data=new MaintenacePlannerDetail();
  public actionsLayout = "normal";
  public urlimg:any;
  public task:any=[];
  public column=[
    {field:"locationObj.NAME",title:"NAME", isFilter:true,width:200},
    {field:"year",title:"Year", isFilter:true,width:200},
    {field:"plannerObj.ID",title:"PPM ID", isFilter:true,width:200},
    {field:"plannerObj.NAME",title:"PPM Name", isFilter:true,width:200},
    {field:"userObj.staffName",title:"Assignee", isFilter:true,width:200},
    {field:"contractorObj.name",title:"Assign To", isFilter:true,width:200},
  ]
  ngOnInit(): void {
    this.uiText=PLANNER[this.id]
    if(this.id===3){
      // this.column.push({field:"year",title:"Year", isFilter:true,width:200})
      this.column.push({field:"month",title:"Month", isFilter:true,width:200})
      this.column.push({field:"date",title:"Day", isFilter:true,width:200})
    }
    else{
      this.column.push({field:"month",title:"Month", isFilter:true,width:200})
    }
    this.model.getData(
      ModelApi.Asset,
      ModelApi.User,
      ModelApi.Contractor,
      ModelApi.MaintenancePlanner,
      ModelApi.levelarea,
      ModelApi.block,
      ModelApi.blocklevel,
      ModelApi.arealocation,
      ModelApi.facility,
      ModelApi.month,
      ModelApi.Year,
      ModelApi.MaintenanceFrequency,
      ModelApi.ChekList
      )
    .then((result)=>{
      this.reset();
      //  console.log(this.model)
       this.model.user.filterData=this.model.user.alldata.filter((d)=>d.categoryId==1)
      this.model.planner.filterData=this.model.planner.alldata.filter((d)=>d.MAINTENANCETYPEID==this.id)
      this.model.maintenaceFrequence.selectedObj=this.model.maintenaceFrequence.alldata.find((d)=>d.DESCRIPTION.toLowerCase()==="daily")
      this.getData();
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  onDialogClose(type="new"){
    if(type=="new")
      this.opened=false;
    if(type=="importUpdate"){
      this.importopened = false
  }
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
     this.dailogTitle="Add "+PLANNER[this.id]?.title +" For Location";
  }
  valuechange(type,data){
    if(type=="asset"){
      this.model.asset.selectedObj=data;
    }
    else if(type=="planner"){
      this.model.planner.selectedObj=data;
    }
    else if(type=="contractor"){
      this.model.contractor.selectedObj=data;
    }
    else if(type=="user"){
      this.model.user.selectedObj=data;
    }
    else if(type=="status"){
      this.model.status.selectedObj=data;
    }
    else if(type=='facility'){
      this.model.facility.selectedObj=data
      this.model.block.filterData=this.model.block.alldata.filter((d)=>d.FACILITYID==data.ID)
      this.model.block.selectedObj=null;
      this.model.level.selectedObj=null;
      this.model.area.selectedObj=null;
      this.model.location.selectedObj=null;
    }
    else if(type=="block"){
        this.model.block.selectedObj=data
        this.model.level.filterData=this.model.level.alldata.filter((d)=>d.BLOCKID==data.ID)
        this.model.level.selectedObj=null;
        this.model.area.selectedObj=null;
        this.model.location.selectedObj=null;
    }
    else if(type==="level"){
      this.model.level.multiSelect=data
      if(this.model.level.multiSelect.length==1){
         this.model.location.filterData=this.model.location.alldata.filter((d)=>d.BLOCKLEVELID==data[0].ID)
         this.model.location.multiSelect=null;
         this.model.location.selectedObj=null;
      }
      else if(this.model.level.multiSelect.length>1){
        this.model.location.filterGroupLevel=this.model.location.allGroupLevel.filter((d)=>{
          let len=this.model.level.multiSelect.length;
          return this.model.level.multiSelect.filter((l)=>{
            return d.groupLocation.filter((g)=>g.BLOCKLEVELID==l.ID).length>0
          }).length==len
        })
        this.model.location.multiSelect=null;
        this.model.location.selectedObj=null;
      }
      else{

      }
     // this.model.location.filterGroupLevel=data.filter((d)=>)
      // this.model.area.filterData=this.model.area.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
      // this.model.area.selectedObj=null;
      this.model.location.selectedObj=null;
     // this.areaObj={...this.areaObj}      
    }
    else if(type==="area"){
      this.model.area.selectedObj=data
      this.model.location.filterData=this.model.location.alldata.filter((d)=>d.LEVELAREAID==data.ID)
      this.model.location.selectedObj=null;
    }
    else if(type==="location"){
      if(this.model.level.multiSelect.length==1){
         this.model.location.selectedObj=data
         this.model.location.multiSelect=[]
      }
      else if(this.model.level.multiSelect.length>1){
        this.model.location.multiSelect=data
        this.model.location.selectedObj=null
      }
      else {
        this.model.location.selectedObj=null
        this.model.location.multiSelect=[]
      }
    }
  }
  filterChange(type,data){
    if(type=="assetClassificatioon"){
      this.filterGrid.assetClassificatioon=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee =data;
    }
    else if(type=="location"){
      this.filterGrid.location=data;
    }
    else if(type=="year"){
      this.filterGrid.year=data;
    }
    else if(type=="planner"){
      this.filterGrid.planner=data;
    }
    else if(type=="assignTo"){
      this.filterGrid.assignTo=data;
    }
    else if(type=="assignee"){
      this.filterGrid.assignee=data;
    }
    else if(type=='facility'){
      this.filterGrid.facility=data
      this.model.block.filterData=this.model.block.alldata.filter((d)=>d.FACILITYID==data.ID)
      this.filterGrid.block=null;
      this.filterGrid.level=null;
      this.filterGrid.area=null;
      this.filterGrid.location=null;
    }
    else if(type=="block"){
        this.filterGrid.block=data
        this.model.level.filterData=this.model.level.alldata.filter((d)=>d.BLOCKID==data.ID)
        this.filterGrid.level=null;
        this.filterGrid.area=null;
        this.filterGrid.location=null;
    }
    else if(type==="level"){
      this.filterGrid.level=data
      this.model.area.filterData=this.model.area.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
      this.filterGrid.area=null;
      this.filterGrid.location=null;
     // this.areaObj={...this.areaObj}      
    }
    else if(type==="area"){
      this.filterGrid.area=data
      this.model.location.filterData=this.model.location.alldata.filter((d)=>d.LEVELAREAID==data.ID)
      this.filterGrid.location=null;
    }
    else if(type==="location"){
      this.filterGrid.location=data
    }
    else if(type==="month"){
      this.filterGrid.month=data;
    }
  }
  checkValidation(locationList){
    let e=false;
   // this.error=new PPMError();
    // if(locationList.length<=0){
    //    e=true;
    //    this.error.location=true;
    // }
    if(!this.model.planner.selectedObj){
      e=true;
      this.error.planner=true;
    }
   if(!this.model.user.selectedObj){
      e=true;
      this.error.assignee=true;
   }
   if(!this.model.contractor.selectedObj){
      e=true;
      this.error.assignTo=true;
   }
   if(!this.model.block.selectedObj){
     e=true;
     this.error.block=true
   }
   if(!this.model.facility.selectedObj){
     e=true;
     this.error.facility=true;
   }
   if(!(this.model.level.multiSelect?.length>0)){
      e=true;
      this.error.level=true;
   }
  //  if((  (!this.model.level.multiSelect || this.model.level.multiSelect?.length<2) && this.model.location.multiSelect?.length>0) || (this.model.level.multiSelect.length>=2 && this.model.location.selectedObj) ){
  //    e=true;
  //    this.error.location=true;
  //  }
   return e;
  }
  saveData(){
    this.error=new PPMError();
    let err=this.checkValidation([]);
   if(!(this.model.level.multiSelect?.length>0)){
     this.error.level=true;
     err=true;
     //  return;
   }
    let assetsList:any=[]// this.model.location.selectedObj;
    if(this.model.level.multiSelect?.length>1){
         let d:any=this.model.location.multiSelect;
           if(d.groupLocation && d.groupLocation.length>0){
             d.groupLocation.forEach((l)=>{
               if(this.model.level.multiSelect.filter((lv)=>lv.ID==l.BLOCKLEVELID).length>0){
                 assetsList.push(l)
               }
             })
           }
    }
    else if(this.model.level?.multiSelect?.length==1 && this.model.location?.selectedObj){
      let llist:any=this.model.location?.selectedObj
       assetsList=[...llist]
    }
    if(assetsList.length==0){
      this.error.location=true;
      // this._notif.show({
      //   content: "No Location Selected",
      //   type: { style: "error", icon: true },
      // });
      err=true;
      return;
    }
    if(err){
      return ;
    }
    Promise.all(assetsList.map((d)=>{
      this.data.LOCATIONID=d.ID;
      this.data.BPMID=this.model.contractor.selectedObj.id;
      this.data.MAINTENANCEPLANNERID=this.model.planner.selectedObj.ID;
      this.data.USERID=this.model.user.selectedObj.id;
      this.data.SCHEDULETYPEID=this.scheduleInfo.type?.id;

      if(this.type==="new"){
        let month=[];
        let date=[];
        let day=[];
        let week=[]
        if(this.data.SCHEDULETYPEID==1){
           month=this.scheduleInfo.month.map((d)=>d.ID);
           date=this.scheduleInfo.date.map((d)=>d.id)
        }
        else if(this.data.SCHEDULETYPEID==1){
          month=this.scheduleInfo.month.map((d)=>d.ID);
          day=this.scheduleInfo.day.map((d)=>d.id);
          week=this.scheduleInfo.week.map((d)=>d.id);
        }
        let workorderObj={
          "maintenancePlannerId":this.data.MAINTENANCEPLANNERID ,
          "assetId": null,
          "locationId":d.ID ,
          "userId": this.model.user.selectedObj.id,
          "bpmid": this.model.contractor.selectedObj.id,
          "createdBy": getCookie(userid),
          "createdDate": new Date(),
          "modifiedBy": getCookie(userid),
          "modifiedDate": new Date()
        }
        console.log("save workorder==>",workorderObj,JSON.stringify(workorderObj))
         this.data.addWorkOrder(this.id,workorderObj,month,date,week,day)
        return  this.data.saveData()
      }
      else if(this.type=="update"){
       return  this.data.updateData(d.ID)
      }
    }))
    .then((result)=>{
      this.opened=false;
      this._notif.show({
        content: "Successfully saved.",
        type: { style: "success", icon: true },
      });
       this.fetchData()
    })
    .catch((err)=>{
      this._notif.show({
        content: "Fail",
        type: { style: "error", icon: true },
      });
    })
   // this.data.LOCATIONID=this.model.location.selectedObj.ID;



    
  }
  reset(){
     this.model.area.selectedObj=null;
     this.model.block.selectedObj=null;
     this.model.contractor.selectedObj=null;
     this.model.facility.selectedObj=null;
     this.model.level.selectedObj=null;
     this.model.level.multiSelect=null;
     this.model.location.selectedObj=null;
     this.model.location.multiSelect=null
     this.model.user.selectedObj=null;
     this.model.planner.selectedObj=null;
     this.scheduleInfo={
      type:null,
      month:[],
      date:[],
      week:[],
      day:[]
    }
  }
  resetFetch(){
    this.filterGrid={
      assetClassificatioon:null,
      assignee:null,
      assignTo:null,
      areaName:null,
      type:null,
      year:null,
      month:null,
      facility:null,
      planner:null,
      block:null,
      level:null,
      area:null,
      location:null
    }
  }

  getData(){
    // this.model.getData(ModelApi.MaintenancePlannerDetail)
    // .then((result)=>{
    //   this.task=this.model.plannerDetail.alldata.filter((d)=>{
    //       return d.LOCATIONID && this.model.planner.objectData[d.MAINTENANCEPLANNERID]?.MAINTENANCETYPEID==this.id   
    //   }).map((d)=>{
    //     if(d.ID){
    //       d["locationObj"]=this.model.location.objectData[d.LOCATIONID];
    //       d["plannerObj"]=this.model.planner.objectData[d.MAINTENANCEPLANNERID];
    //       d["contractorObj"]=this.model.contractor.objectData[d.BPMID];
    //       d["userObj"]=this.model.user.objectData[d.USERID];
    //     }
    //     return d;
    //    })
    // })
  }
  editColumn(d){
    let d1=d.dataItem;
    this.data.ID=d1.ID
     this.model.asset.selectedObj=d1["assetObj"];
     this.model.planner.selectedObj=d1["plannerObj"];
     this.model.contractor.selectedObj=d1["contractorObj"];
     this.model.user.selectedObj=d1["userObj"]
     let locObj=d1["locationObj"];
     let lev:any=[this.model.level.objectData[locObj.BLOCKLEVELID]]
     this.model.level.multiSelect=lev;
    this.model.facility.selectedObj=this.model.facility.objectData[locObj.FACILITYID];
    this.model.block.selectedObj=this.model.block.objectData[locObj.BLOCKID];
      let loc1:any=[locObj]
      this.model.location.selectedObj=loc1;
     this.type="update";
     this.opened=true;
     this.dailogTitle="Update "+PLANNER[this.id]?.title +" For Location";
  }

 deleteColumn(d){
  this._notif.show({
    content: "PPM/PDM/DI cannot be deleted because it is tied to asset or location",
    type: { style: "error", icon: true },
  });
    // this.data.deleteData(d.dataItem)
    // .then((result)=>{
    //   this.getData();
    // })
 }
 fetchData(){
   let plannerId=this.filterGrid.planner && this.filterGrid.planner.ID;
   let Year=this.filterGrid.year && this.filterGrid.year.ID;
   let Month=this.filterGrid.month && this.filterGrid.month.ID;
   let RefType="location";
   let AssetNo=null;
   let AssetClassificationID=null;
   let AssetTypeID=null;
   let FacilityID=this.filterGrid.facility && this.filterGrid.facility.ID;
   let BlockID=this.filterGrid.block && this.filterGrid.block.ID;
   let LevelID=this.filterGrid.level && this.filterGrid.level.ID;
   let AreaID=this.filterGrid.area && this.filterGrid.area.ID;
   let LocationID=this.filterGrid.location && this.filterGrid.location.ID;
   let Assignee=this.filterGrid.assignee && this.filterGrid.assignee.id;
   let AssignTo=this.filterGrid.assignTo && this.filterGrid.assignTo.id;
   this.pobj.filterByData(plannerId,Year,Month,RefType,AssetNo,AssetClassificationID,AssetTypeID,FacilityID,BlockID,LevelID,AreaID,LocationID,Assignee,AssignTo)
   .then((result)=>{
    this.task=this.pobj.alldata.filter((d)=>{
      return this.model.location.objectData[d.LOCATIONID] && this.model.planner.objectData[d.MAINTENANCEPLANNERID]?.MAINTENANCETYPEID==this.id   
  }).map((d)=>{
    if(d.ID){
      d["locationObj"]=this.model.location.objectData[d.LOCATIONID];
      d["plannerObj"]=this.model.planner.objectData[d.MAINTENANCEPLANNERID];
      d["contractorObj"]=this.model.contractor.objectData[d.BPMID];
      d["userObj"]=this.model.user.objectData[d.USERID];
    }
    return d;
   })
    // console.log("pobj==>",this.pobj)
   })
 }
 importData(){
  this.importopened = true
}
download() {
  if(this.id==1){
  window.open("/assets/template/ppm_for_location.xlsx")
  }
  else if(this.id==2){
    window.open("/assets/template/PDM_for_location.xlsx")
  }
  else if(this.id==3){
    window.open("/assets/template/DI_for_location.xlsx")
  }
}
importSave(){
    // this.getBPMData()
    // this.getBpmContractAsset("dbo","BPMContractAsset")
    this.importopened= false;
}
uploadChange(event){
  console.log(event);
  let ppmData=event;
  if(this.id==3){
    ppmData=event.map((d)=>{
      d["Frequency"]=this.model.maintenaceFrequence.selectedObj.DESCRIPTION;
      return d;
    }) 
  }
  importPlannerDetailForLocation(
         ppmData,
         this.model.service.alldata,
         this.model.asset.alldata,
         this.model.level.alldata,
         this.model.location.alldata,
         this.model.maintenaceFrequence.alldata,
         this.model.user.alldata,
         this.model.contractor.alldata,
         this.model.checklist.alldata,
         this.id,
         this.scheduleData.type,
         this.model.month.alldata,
         this.scheduleData.day         
         )
    .then((result)=>{
     console.log(result)
     this._notif.show({
      content: "Successfully saved.",
      type: { style: "success", icon: true },
    });
    setTimeout(()=>{

      this.model.planner.getData()
    .then((result)=>{
      this.fetchData();
    })

    },2000)
    })
    .catch((err)=>{
      console.log(err)
      this._notif.show({
        content: "Fail",
        type: { style: "error", icon: true },
      });
    })
}
}