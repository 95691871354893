import {LicenseTypeError,LicenseAndCertRegistration} from "src/app/models/license&cert.model"
export let licenseRegValidation = (data: LicenseAndCertRegistration, type) => {
    let error = new LicenseTypeError(false)
    if (type === "init")
        return error;
    if (!data.registrationNo) {
        error.registrationNo = true;
    }
    if (!data.categoryId) {
        error.categoryId = true;
    }
    return error;
    
}