
<div class="card m-3">
    <div class="card-header" >
        <h5>Reassignment</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
             Add
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="false"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened"  (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div class="py-4">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Workorder No</label>
                <kendo-dropdownlist  
                    [data]="model.workOrder.filterData" 
                    [value]="model.workOrder.selectedObj" 
                    (valueChange)="valuechange('workOrder',$event)" 
                    class="form-control"
                    [textField]="'workOrderNo'" 
                    [valueField]="'id'" 
                    [disabled]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Work Order Date</label>
                <kendo-datepicker [format]="format"  [disabled]="true" >
                </kendo-datepicker> 
                <!-- <div>{{convertDateFormate(workorder['createdDate'])}}</div> -->
                <!-- <kendo-datepicker [format]="format" [value]=" workorder && workorder['createdDate']"  [disabled]="true">
                </kendo-datepicker>  -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Work Order Category</label>
                <input kendoTextBox    class="form-control" [disabled]="true"/>            
            </div>
            <div class="form-group col-5">
                <label>Assignee</label>
                <div>{{workorder && workorder?.assigneeObj?.staffName }}</div>
                <!-- <input kendoTextBox [(ngModel)]="data.REASON"  [value]="model.workOrder.selectedObj && model.workOrder.selectedObj['description']"  class="form-control"/> -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>New Assignee</label>
                <kendo-dropdownlist  
                    [data]="model.user.filterData" 
                    [value]="model.user.selectedObj" 
                    (valueChange)="valuechange('user',$event)" 
                    class="form-control"
                    [textField]="'staffName'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Reason for Reassign Company User <span class="required">*</span></label>
                <div>
                <input kendoTextBox [(ngModel)]="data.USERREASON"    class="form-control"/>
                <kendo-formerror *ngIf="error.user">*Required</kendo-formerror>
                </div>
            </div>

        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Assign To</label>
                <div>{{workorder && workorder?.assignToObj?.name }}</div>
                <!-- <input kendoTextBox [(ngModel)]="data.REASON"  [value]="model.workOrder.selectedObj && model.workOrder.selectedObj['description']"  class="form-control"/> -->
            </div>
            <div class="form-group col-5">
                <label>New Assign To</label>
                <kendo-dropdownlist  
                    [data]="model.contractor.filterData" 
                    [value]="model.contractor.selectedObj" 
                    (valueChange)="valuechange('contractor',$event)" 
                    class="form-control"
                    [textField]="'name'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Reason for Reassign Facility User <span class="required">*</span></label>
                <div>
                <input kendoTextBox [(ngModel)]="data.BPMREASION"    class="form-control"/>
                <kendo-formerror *ngIf="error.bpm">*Required</kendo-formerror>
                </div>
            </div>
            <div class="form-group col-5">
                <label>Assigned Date</label>
                <kendo-datepicker [format]="format"  [disabled]="true" >
                </kendo-datepicker>
            </div>


        </div>
        <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                Save
            </button>
            <button kendoButton   primary="true" class="px-3" (click)="reset()">
                Reset
            </button>
        </div>
    
      </div>
    
  </kendo-dialog>
