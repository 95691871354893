<div class="card m-3">
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>ER Task Record Generation 
        </h5>
        <div> 
          <!-- <button kendoButton   primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
              <span class="add_btn">+</span> Parameter Mpping
          </button> -->
        </div>
    </div>
     <div class="card-body">
        <div class="form-row">
            <div class="form-group col-5">
                 <label>ER Task ID  </label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
             </div>
             <div class="form-group col-5">
                 <label>Parameter ID </label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
             </div>
         </div>
         <div class="form-row">
             <div class="form-group col-5">
                 <label>Parameter Description  </label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
     
             </div>
             <div class="form-group col-5">
                 <label>Category  </label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
                 <!-- <kendo-dropdownlist [data]="spec_det" class="form-control" [(ngModel)]="contractorVendorForm.bpmskills" [textField]="'name'" [valueField]="'id'"
                     [valuePrimitive]="true">
                 </kendo-dropdownlist> -->
             </div>
           
         </div>
         <div class="form-row">
             <div class="form-group col-5">
                 <label>ER No  <span class="required">*</span></label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
             </div>
           
           
             <div class="form-group col-5">
                 <label>PIC  </label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
             </div>
           
         </div>
         <div class="form-row">
             <div class="form-group col-5">
                 <label>Assign to</label>
                 <div style="width: 100%;">
                     <input kendoTextBox class="form-control" />
                    
                 </div>
             </div>
             <div class="form-group col-5">
                
             </div>
         
         </div>
        
      
     
      <div class="py-3 px-5" >
             <div class="form-row">
                 <app-contact-person-grid
                 [data]="contectGrid"
                 [column]="contectColumn"
                 [size]="10"
                 [deleteFlag]="false"
                 
                 >
     
                 </app-contact-person-grid>
              </div>
           </div>
     </div>

</div>

