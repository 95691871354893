<div *ngIf="auth">
<app-header-component look="flat" (toggle)="toggleDrawer(drawer)" [selectedPage]="selected"></app-header-component>
<kendo-drawer-container>
    <kendo-drawer
        #drawer
        [items]="items"
        [mode]="mode"
        [mini]="mini"
        [miniWidth]="0"
        [expanded]="true"
        [animation]="true"
        [autoCollapse]="false"
        (select)="onSelect($event)"
    >
    <ng-template kendoDrawerItemTemplate let-item>
        <div class="k-level-{{ item.level }}">
          <span class="k-icon {{ item.icon }}"></span>
        </div>
        {{ item.text }}

        <span
          *ngIf="item.expanded && item.children"
          class="k-icon k-i-arrow-chevron-down"
          style="margin-left: auto; line-height: inherit"
        ></span>

        <span
          *ngIf="!item.expanded && item.children"
          class="k-icon k-i-arrow-chevron-right"
          style="margin-left: auto; line-height: inherit"
        ></span>
      </ng-template>
    </kendo-drawer>
    <kendo-drawer-content>
      <div class="body-cont">
        <router-outlet></router-outlet>
 
          <div style="
          position: fixed;
          bottom: 0px;
          width: 100%;
          height: 35px;
          background: #fff;
          z-index: 1;
          color: #202b86;
          left: 0;
          box-shadow: -2px -7px 10px rgb(23 23 23 / 27%);
  padding: 1% 2%;
  border-top: solid 1px #313131;
          padding: 1% 2%;">
            <h6 style="float: left; width: 35%;font-size: 10px;">Copyright &copy; 2023. All Rights Reserved By Avows</h6>
            <h6 style="float: right; width: 25%;text-align: right;font-size: 10px;">
              <a href="JavaScript:Void(0);" style="color: #202b86;font-size: 10px;text-decoration: none;">Term & Condition</a> | 
                <a href="JavaScript:Void(0);" style="color: #202b86;font-size: 10px;text-decoration: none;">Privacy & Policy</a></h6>
        
       </div>
        
      </div>
  </kendo-drawer-content>
</kendo-drawer-container>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1000">
  <kendo-dialog-titlebar>
    <div>
      <h3>{{tCtitle}}</h3>
    </div>
</kendo-dialog-titlebar>
<div [innerHTML]="tCvalue"></div>
</kendo-dialog>
<kendo-dialog *ngIf="openedPolicy" (close)="onDialogClosepolicy()" [width]="1000">
  <kendo-dialog-titlebar>
    <div>
      <h3>{{privacytitle}}</h3>
    </div>
</kendo-dialog-titlebar>
<div [innerHTML]="privacyvalue"></div>
</kendo-dialog>
<div *ngIf="!auth">
     <!-- <app-login-user (loginStatus)="loginStatus($event)" style="height: 100vh;display: flex; background-repeat: no-repeat;
     background-size: 100% 100%; background-image: url('assets/login11.jpg');"></app-login-user> -->
     <app-login-user (loginStatus)="loginStatus($event)" [resetpass]="isResetPassord" [token]="token" [userName]="userName" [userId]="userId" style="height: 100vh;"></app-login-user>
</div>