import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-pdm-page',
  templateUrl: './pdm-page.component.html',
  styleUrls: ['./pdm-page.component.scss']
})
export class PdmPageComponent implements OnInit {
  public onTabSelect(e) {
    console.log(e);
  }
 
  constructor(){

  }
 
  public column2=[
    {field:"c_2",title:"Asset no", isFilter:true,width:100},
    {field:"c_3",title:"Location", isFilter:true,width:100},
    {field:"c_4",title:"PDM WO ID", isFilter:true,width:100},
    {field:"c_5",title:"Asset description", isFilter:true,width:100},
    {field:"c_6",title:"PDM WO Date", isFilter:true,width:100},
    {field:"c_7",title:"Status", isFilter:true,width:100},
   
   
  ];

  
   
  public areagrid=[
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    },
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    }
  ];
// public gridData=sampleProducts;
      
 
ngOnInit(){
      
}

}
