import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class Area{
    ID:number
    NAME:string
    CODE:string
    SHORTNAME:string
    STATUSID:number
    BLOCKID:number
    BLOCKLEVELID:number;
    MODIFIEDBY: number
    MODIFIEDDATE:Date;
    CREATEDBY:number
    CREATEDDATE:Date;
    FACILITYID:number;
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string
}

export class AreaObj{
    alldata:Array<Area>=[]
    filterData:Array<Area>=[]
    selectedObj:Area=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Area)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.levelarea)
            .then((result:Array<Area>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
             
                reject(err)
            })
        })

    }
}