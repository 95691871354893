<div class="card m-3"  	>
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>ER Report  (Daily) 
        </h5>
        <div>
            <!-- <button kendoButton  primary="true" (click)="download()"  class="header-right-btn">
                Export
               </button>  -->
        </div>
    </div>
    <div class="card-body register-form-body">
        <!-- <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10"
      ></app-table-grid> -->
      <kendo-grid
         id="erdaily_report"
        *ngIf="dyanamic_column.length>0"
        [data]="gridData"
        scrollable="scrollable"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="true"
        filterable="menu"
        (dataStateChange)="dataStateChange($event)"
      >
      <ng-template   kendoGridToolbarTemplate style="background: #000;width: 100%;">
        <div style="width: 100%;display: flex;justify-content: flex-end;padding: 0rem;">
        <button  type="button" kendoGridExcelCommand icon="file-excel">
          Export to Excel
        </button>
        </div>
      </ng-template>
          <kendo-grid-column
              field="date"
              title="Date"
              [width]="110"
              [filterable]="false"
           >
          </kendo-grid-column>
          <kendo-grid-column
              field="er_no"
              title="ER No"
              [width]="110"
              [filterable]="true"
           >
          </kendo-grid-column>
          <kendo-grid-column-group *ngFor="let col of dyanamic_column" [title]="col" [width]="220"  [headerStyle]="{'text-align':'center'}">
            <kendo-grid-column
              [field]="col+fixedColumn.READ"
              title="Reading"
              [width]="80"
              [filterable]="false"
             >
             <ng-template   kendoGridCellTemplate let-dataItem>
                  <span [ngClass]="dataItem[col+fixedColumn.READ]?'cell-highlight':''">{{dataItem[col+fixedColumn.READ]}}</span>
             </ng-template>
             </kendo-grid-column>

             <kendo-grid-column
             [field]="col+fixedColumn.MIN"
             title="Min"
             [width]="70"
             [filterable]="false"
            >
              <ng-template   kendoGridCellTemplate let-dataItem>
                <span [ngClass]="dataItem[col+fixedColumn.MIN]?'cell-highlight':''">{{dataItem[col+fixedColumn.MIN]}}</span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            [field]="col+fixedColumn.MAX"
            title="Max"
            [width]="70"
            [filterable]="false"
           >
           <ng-template   kendoGridCellTemplate let-dataItem>
            <span [ngClass]="dataItem[col+fixedColumn.MAX]?'cell-highlight':''">{{dataItem[col+fixedColumn.MAX]}}</span>
          </ng-template>
           </kendo-grid-column>

          </kendo-grid-column-group>
          <kendo-grid-column
              field="remark"
              title="Remark"
              [width]="110"
              [filterable]="true"
           >
          </kendo-grid-column>
          <kendo-grid-column
          field="status"
          title="Status"
          [width]="110"
          [filterable]="true"
          >
          </kendo-grid-column>
          <kendo-grid-column
          field="shift"
          title="Shift"
          [width]="110"
          [filterable]="true"
        >
        </kendo-grid-column>
        <kendo-grid-excel 
             fileName="erdaily.xlsx"
             [fetchData]="allData"
        ></kendo-grid-excel>
      </kendo-grid>
    </div>

</div>

<div  *ngIf="!isAccess">

    <div class="dashboard-content"> 
      <div class="content-wrapper">
        <div class="page-wrapper">
            <h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>
        </div>
      </div>
    </div>
  
  </div>