<kendo-tabstrip  >
    <kendo-tabstrip-tab  title="Checklist" [selected]="true">
        <ng-template kendoTabContent >
            <app-create-checklist></app-create-checklist>
        </ng-template>
    </kendo-tabstrip-tab>
    <!-- <kendo-tabstrip-tab title="Maintance Task">
        <ng-template kendoTabContent>
            <app-checklist-maintance-task></app-checklist-maintance-task>
        </ng-template>
    </kendo-tabstrip-tab> -->
    <kendo-tabstrip-tab title="Maintenance Task">
        <ng-template kendoTabContent>
            <app-checklist-combind></app-checklist-combind>
        </ng-template>
    </kendo-tabstrip-tab>
    <!-- <kendo-tabstrip-tab title="Answer Type">
        <ng-template kendoTabContent>
            <app-checklist-answer-type></app-checklist-answer-type>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Answer List">
        <ng-template kendoTabContent>
            <app-checklist-answer-list></app-checklist-answer-list>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="Task Item">
        <ng-template kendoTabContent>
            <app-checklist-task-item></app-checklist-task-item>
        </ng-template>
    </kendo-tabstrip-tab> -->
</kendo-tabstrip>