import { Component, Input, OnInit,OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { process, State } from "@progress/kendo-data-query";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";
import HttpClient from 'src/Util/ApiHandling';


@Component({
  selector: 'app-contact-asset-grid',
  templateUrl: './contractorasset.component.html',
  styleUrls: ['./contractorasset.component.scss']
})
export class ContratctorAssetGridComponent implements OnInit {


  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() action:Boolean=true;
  @Input() deleteFlag:Boolean=true;
  @Output() valueChange = new EventEmitter<any>();
  @Output() sumOfContractValue = new EventEmitter<any>();
  @Output() licenseassetValue = new EventEmitter<any>();
  @Output() leaveValue =  new EventEmitter<any>();
  @Output() erReadingValue = new EventEmitter<any>()
  @Output() pmLicenseInchargeValue = new EventEmitter<any>()
  @Output() error = new EventEmitter<boolean>();
  public assetNoDropDown =[];
  public staffDropDown = [];
  public assetTypeObj = {};
  public statusDropDown = [];
  public filteredAssetData =[]
  public min: Date = new Date(2000, 2, 10);
  public uomDropDown = []
  public format = "dd/MM/yyyy";
  public formats = "dd/MM/yyyy HH:mm:ss";
  public assetDes
  public sumofContractDataValue :number =0;
  public originalGridData=[];
  public designAtion =[]
  // public gridData1=[];
  public gridData=[];
  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
       this.data=changes.data.currentValue;
       if(this.data.length>0 && this.data[0].action == "licenseEdit"){
        this.updateGridData(JSON.parse(JSON.stringify(changes.data.currentValue)))
       }
       else{
        this.initGridData(JSON.parse(JSON.stringify(changes.data.currentValue)))
       }
     
      
      // this.initGridData(changes.data.currentValue)
    //    this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.action){
      this.action=changes.action.currentValue
    }
    if(changes.deleteFlag){
      this.deleteFlag=changes.deleteFlag.currentValue
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
   // this.gridData = process(this.data, this.state);
  }

  initGridData(data){
     data.filter((e)=>{
      if(e.action === "edit"){
        if(data[0].leaveFrom){
          data.map((d)=>{
            d["leaveFrom"] = new Date(d.leaveFrom);
            d["leaveTo"] = new Date(d.leaveTo);
            return d;
          })
        }
        
       return e;
      }
     })
     
    
 
    let isNew=data.filter((d)=>{
        d.action == "new"
    }).length == 0;
    if(isNew)
    data.unshift({
      id:0,
      ASSETID:"",
      assetDes:"",
      CONTRACTVALUE:"",
      action:"new",
      sumOfValue:"",
      isDeleted:false
    })

    data=data.map((d,i)=>{
      if(!d.action)
         d["action"]="edit";
      d["index"]=i;
      return d;
    })
    this.gridData=data.filter((d)=>!d.isDeleted)
  }
  
  updateGridData(data){
    this.gridData=data
  }
  ngOnInit(): void {
      this. getAssetRegistrationData();
      this.getStaff();
      this.getLoockup("dbo", "Designation");
      this.getUOM("dbo","ERUoM");
      this.getStatus("dbo","status")
//       this.gridData=this.gridData.map((d)=>{
//         d["ASSETID"]=0
//         return d;
//   })
//    this.initGridData(this.data)
  }
  
  executeAction(type,index,dataItem){
    console.log("ggggggggggggg",dataItem)
    this.sumofContractDataValue = this.gridData.reduce((acc,d)=>{
      acc=acc+parseInt(d.contractValue);
      return acc;
    },0) //parseInt(dataItem.contractValue) + this.sumofContractDataValue 
   
    
      if(type=="new"){
      let validateText=this.checkedContectError();
      if(!validateText){
        this.assetDes=null;
        this.gridData=this.gridData.map((d)=>{
              d["action"]="edit"
              return d;
        })
        this.valueChange.emit(this.gridData);
        this.licenseassetValue.emit(this.gridData);
        // this.leaveValue.emit(this.gridData);
        // this.erReadingValue.emit(this.gridData)
        // this.pmLicenseInchargeValue.emit(this.gridData)
        this.sumOfContractValue.emit(this.sumofContractDataValue)
       }
   }
   if(type=="edit-save" && dataItem.action!=='new'){
      this.checkedContectError("new")
      this.licenseassetValue.emit(this.gridData)
      this.valueChange.emit(this.gridData)
   }
   if(type=="delete"){
    this.gridData=this.gridData.filter((d,i)=>i!=index)
     this.checkedContectError("new")
     this.sumofContractDataValue = this.gridData.reduce((acc,d)=>{
      acc=acc+parseInt(d.contractValue || "0");
      return acc;
    },0)  
    //  this.sumOfContractValue.emit(this.sumofContractDataValue)
    //    this.sumofContractDataValue = this.gridData.reduce((acc,d)=>{
    //   acc=acc+parseInt(d.contractValue || "0");
    //   return acc;
    // },0) 
      // this.valueChange.emit(this.gridData)
     this.sumOfContractValue.emit(this.sumofContractDataValue)
    //  this.licenseassetValue.emit(this.gridData);
    //  this.leaveValue.emit(this.gridData);
    //  this.erReadingValue.emit(this.gridData)
     this.pmLicenseInchargeValue.emit(this.gridData)

   }
   
  } 
  
  checkedContectError(type=""){
    let error=false;
    this.gridData=this.gridData.map((d)=>{
      if(d.action!=type){
             this.column.forEach((c)=>{
                  let errormsg=c.validation(d[c.field])
                  if(errormsg){
                    error=true
                    d[c.errorField]=errormsg
                  }
                  else{
                    d[c.errorField]=errormsg
                  }
             })
            }
        return d;
    })
    this.error.emit(error);
    return error;
  }

  getAssetRegistrationData(){
    return new Promise((resolve,rejects)=>{
      HttpClient.get(
        "/api/Assets/GetAssets",
        true,
        true
      ).then((result: any) => {
         this.assetNoDropDown = result
         this.filteredAssetData = this.assetNoDropDown.slice();
         this.getAssetTypeCode()
        resolve(result);
    }).catch((e)=>{
      rejects(e)
       })
    })
  }

  getStaff(){
    return new Promise((resolve,reject)=>{
        HttpClient.get("/api/Users/GetAllUser", true, true)
          .then((result: any) => {
            this.staffDropDown = result;
            resolve(result);
          }).catch((err)=>{
            reject(err)
          })
    })
  }
  bindAssetTypeObj(result) {
    this.assetTypeObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})
}
  
  assetNoChanged(data,dataItem){
    let value=  this.assetNoDropDown.filter((e)=>{
      return e.id===data
    });
    if(value[0].assetStandardizationId){
      HttpClient.get(`/api/Assets/GetAssetStandardizationById?id=${value[0].assetStandardizationId}`, true,true)
      .then((result: any) => {
        let assetClassificationData = result;
        this.gridData[0].assetDes =  this.assetTypeObj[assetClassificationData.assetTypeId] && this.assetTypeObj[assetClassificationData.assetTypeId].description;
        // this.gridData[0].assetID =  this.assetTypeObj[assetClassificationData.assetTypeId] && this.assetTypeObj[assetClassificationData.assetTypeId].id
      })
    }
    console.log("hhhhhhhhhhhhhhhhgggggggggggg",value, this.assetNoDropDown)
    //  this.gridData[0].assetDes = value[0].assetTypeDescription
  }

  getAssetTypeCode(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetTypes", true, true)
        .then((result: any) => {
          this.bindAssetTypeObj(result);
           
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
  staffNameChange(data,dataItem){
    let value=  this.staffDropDown.filter((e)=>{
      return e.id===data
    });

    let designationValue = this.designAtion.filter((e)=>{
      return e.ID===value[0].designationId
    })
   if(designationValue != null && designationValue.length != 0){
    this.gridData[0].designation = designationValue[0].NAME
    }else{
      this.gridData[0].designation = "";
    }
    this.gridData[0].emailAddress = value[0].userName
    this.gridData[0].companyName = value[0].companyName
    //  this.gridData[0].assetDes = value[0].assetCode

  }

  getLoockup(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        this.designAtion = JSON.parse(result.gridData);
          resolve(result)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }

  getUOM(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        this.uomDropDown = JSON.parse(result.gridData);
          resolve(result)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }
  
  getStatus(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        this.statusDropDown = JSON.parse(result.gridData);
          resolve(result)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }
  
  handleAssetFilter(data){
    this.filteredAssetData = this.assetNoDropDown.filter(
      (s) => s.assetNumber.toLowerCase().indexOf(data.toLowerCase()) !== -1
    );
  }
  
  // editHandler(e){
  //   dispatchEvent(EVENT_NAME.edit_column,e);
  // }
  // removeHandler(e){
  //   dispatchEvent(EVENT_NAME.remove_column,e);
  // }

  dateToChanged(date){
    
      this.gridData[0].noOfDays =  this.dayDiff(this.gridData[0].leaveFrom, this.gridData[0].leaveTo)
      this.gridData[0].noOfHours =  this.diff_hours(this.gridData[0].leaveFrom, this.gridData[0].leaveTo)
      if(this.gridData[0].noOfHours >= 8){
        this.gridData[0].noOfDays =  this.gridData[0].noOfDays + 1;
        this.gridData[0].noOfHours = 0;
      }else{
        this.gridData[0].noOfDays = 0;
      }
  }

  dayDiff( str1, str2 ) {
    var diff = Date.parse( str2 ) - Date.parse( str1 ); 
    if(isNaN( diff )){
      return NaN;
    }
    else{
      return   Math.floor( diff / 86400000 )
    }
 
}

 diff_hours(dt2, dt1) 
 {
debugger;
  var diff =(dt1.getTime() - dt2.getTime()) / 1000;
  diff /= (60 * 60);
  if(diff < 4){
    diff = 4;
  }
  // else if(diff > 8){
  //   diff = 0;
  // }
  return Math.abs(Math.round(diff));
  
 }
 
 leaveFromChange(date){
  this.min = new Date(date) ;
  this.min.setDate(this.min.getDate())
}
}
