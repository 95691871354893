import { Component, OnInit, Input } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { process, State } from "@progress/kendo-data-query";

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  SelectableSettings,
} from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"

@Component({
  selector: 'app-holiday-add-grid',
  templateUrl: './holiday-add-grid.component.html',
  styleUrls: ['./holiday-add-grid.component.scss']
})
export class HolidayAddGridComponent implements OnInit {
  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() min:Date;
  @Input() max:Date;
  public gridData:GridDataResult;
  public value: Date = new Date(2000, 2, 10);
   public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() {

    
   }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.min){
      this.min=new Date(changes.min.currentValue)
    }
    if(changes.max){
      this.max=new Date(changes.max.currentValue
        )
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }
  ngOnInit(): void {
    
    
  }
  editHandler(e){
    
    dispatchEvent(EVENT_NAME.edit_column,e);
  }
  removeHandler(e){
    e.dataItem.date=null;
    dispatchEvent(EVENT_NAME.attach_holiday_column,this.data);
    //dispatchEvent(EVENT_NAME.remove_column,e);
  }
  onSelect(e){
    

    dispatchEvent(EVENT_NAME.attach_holiday_column,this.data);
  }
  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
    mode: 'multiple'
  };
}


