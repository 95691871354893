import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class AreaLocation{
    ID:number
    NAME:string
    CODE:string
    SHORTNAME:string
    BLOCKLEVELID:number
    BLOCKID:number
    LEVELAREAID:number
    MODIFIEDBY: number
    MODIFIEDDATE:Date;
    CREATEDBY:number
    CREATEDDATE:Date;
    FACILITYID:number;
    STARTSERVICEDATE:Date;
    STOPSERVICEDATE:Date;
    LOCATIONINCHARGE:number;
    COMPANYREPRESENTATIVE:number;
    STATUSID:number;
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string;
}

export class AreaLocationObj{
    alldata:Array<AreaLocation>=[]
    filterData:Array<AreaLocation>=[]
    selectedObj:AreaLocation=null;
    multiSelect:Array<{code:string,name:string,groupLocation:Array<AreaLocation>}>=[]
    objectData={}
    allGroupLevel:Array<{code:string,name:string,groupLocation:Array<AreaLocation>}>=[]
    filterGroupLevel:Array<{code:string,name:string,groupLocation:Array<AreaLocation>}>=[]
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AreaLocation)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.arealocation)
            .then((result:Array<AreaLocation>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               let obj=this.alldata.reduce((acc,d)=>{
                   let c=d.NAME.toLocaleLowerCase().replace(/ /u ,"");
                   if(acc[c]){
                      acc[c].groupLocation.push(d)
                   }
                   else{
                       acc[c]={code:d.CODE,name:d.NAME,groupLocation:[d]};
                   }
                   return acc;
               },{})
               for(let code in obj){
                   this.allGroupLevel.push(obj[code])
               }
               resolve(true)
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }
}