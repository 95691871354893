<div class="card m-3"   >
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Blocks</h5>
        <div> 
          <button kendoButton *ngIf="permission && permission.isCreate"  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
              <span class="add_btn">+</span> Block
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10"
        [editFlag]="permission && permission.isUpdate"
        [deleteFlag]="false"
        deleteKey="deleteKey" 
      ></app-table-grid>
    </div>

</div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Facility<span class="required">*</span></label>
                    <div style="width:100%">
                      <kendo-dropdownlist
                      [data]="facilityDropDown"
                      [textField]="'NAME'"
                      [valueField]="'ID'"
                      [valuePrimitive]="true"
                      class="form-control"
                      [(ngModel)]="BlockForm.FACILITYID"
                      [disabled]="type=='update'"
                     >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.FACILITYID">*Required</kendo-formerror>
                  </div>
                  </div>
              </div>
              
              <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Block Code<span class="required">*</span></label>
                    <div style="width:100%">
                    <input kendoTextBox *ngIf="type=='new'"  [(ngModel)]="BlockForm.CODE"  class="form-control" (blur)="checkedUniqueCode()" />
                    <input kendoTextBox *ngIf="type=='update'"  [(ngModel)]="BlockForm.CODE" disabled class="form-control"/>
                    <kendo-formerror *ngIf="error.CODE">*Required</kendo-formerror>
                    <kendo-formerror *ngIf="!error.CODE && isunique">Block code already exist.</kendo-formerror>
                    </div>
                </div>
            </div>

            <div class="form-row">
              <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                <label>Block Name<span class="required">*</span></label>
                <div style="width:100%">
                  <input kendoTextBox  [(ngModel)]="BlockForm.NAME" class="form-control"/>
                <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror>
                </div>
              </div>
          </div>

            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Short Name</label>
                    <div style="width:100%">
                    <input kendoTextBox  [(ngModel)]="BlockForm.SHORTNAME" class="form-control"/>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Status</label>
                    <div style="width:100%">
                    <kendo-dropdownlist  
                    [data]="statusDropDown" [(ngModel)]="BlockForm.STATUSID" class="form-control"
                    [textField]="'NAME'"
                    [valueField]="'ID'"
                    [valuePrimitive]="true"
                      >
                    </kendo-dropdownlist>
                    </div>
                </div>

            </div>
             <kendo-dialog-actions [layout]="actionsLayout">
            <button kendoButton (click)="onDialogClose('new')">Cancel</button>
            <button *ngIf="type=='new'" kendoButton (click)="onReset()" primary="true">
              Reset
              </button>
            <button *ngIf="type=='new'" kendoButton (click)="onSubmit()" primary="true">
             Save
            </button>
           
            <button *ngIf="type=='update'" kendoButton (click)="onSubmit()" primary="true">
             Update
          </button>
        </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (click)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeBlockData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>
