
<div class="card m-3"   >
    <div class="card-header" >
        <h5> Spare Part Update </h5>
        <div>
          <button kendoButton  primary="true" (click)="downloadTemplate()" class="header-right-btn" >
            Download Template
             </button>  &nbsp;  &nbsp; 
          <button kendoButton  primary="true"  (click)="importData()" class="header-right-btn"  *ngIf="permission && permission.isImport">
           Import
               </button> &nbsp;  &nbsp;
            <button kendoButton  primary="true" (click)="download()" class="header-right-btn"  *ngIf="permission && permission.isExport">
            Export
             </button>  &nbsp;  &nbsp;   
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn"  *ngIf="permission && permission.isCreate">
              Update Spare Part
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid
        [data]="gridData"
        [column]="column2"
        [size]="10"
        [editFlag]="false"

      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1080">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>Stock No. <span class="required">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [data]="stockDropDown"   [textField]="'stockNo'"
                [valueField]="'id'"
                [valuePrimitive]="true" class="form-control" [(ngModel)]="sparePartUpdateForm.SPAREPARTID"  (valueChange)="stockNoChanged($event)" class="form-control">
                </kendo-dropdownlist>
                  <kendo-formerror *ngIf="error.SPAREPARTID">*Required</kendo-formerror>
                </div>
            </div>
            <div class="form-group col-5">
                <label>Stock Update No.</label>
                <input kendoTextBox class="form-control" [(ngModel)]="sparePartUpdateForm.SPAREPARTUPDATEID" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Disposal Activity</label> 
                <kendo-dropdownlist  [data]="activity"  [(ngModel)]="sparePartUpdateForm.DISPOSAL"  [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true" class="form-control" class="form-control" [disabled]="disposalDisable">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Return To Supplier </label>
                <kendo-dropdownlist [data]="activity" [(ngModel)]="sparePartUpdateForm.SUPPLIERRETURN"  [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true" class="form-control" class="form-control" [disabled]="supplierDisable">
                </kendo-dropdownlist>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Date<span class="required">*</span></label>
                <div style="width:100%">
                <kendo-datetimepicker [(ngModel)]="updatedData" > </kendo-datetimepicker>
                <kendo-formerror *ngIf="dateErr">*Required</kendo-formerror>
            </div>
          </div>
            <div class="form-group col-5">
                <label>Total Spare Part Cost (RM)</label>
                <input kendoTextBox class="form-control" />
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Facility Name  </label>
                <kendo-dropdownlist   [data]="facilityDropDown"
                [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                [(ngModel)]="facilityName"
               class="form-control" disabled>
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>Facility Code</label>
                <input kendoTextBox class="form-control" [(ngModel)]="facilityCode" disabled/>
            </div>
        </div>
        <div class="form-row spare-part-table p-4">
          <div class="col-12">
            <table style="width:100%">
                <tr>
                  <th>Part No.</th>
                  <th>Part Description</th> 
                  <th>Spare Part Category</th>
                  <th>Location<span class="required">*</span></th>
                  <th>Min Unit<span class="required">*</span></th>
                  <th>Max Unit</th>
                  <th>Estimated Lifespan</th>
                  <th>Expiry Date<span class="required">*</span></th>
                  <th>Quantity<span class="required">*</span></th>
                  <th>ERP Purchase Cost / Pcs (RM)</th>
                  <th>Cost / Pcs (RM)</th>
                  <th>Invoice No.</th>
                  <th>Supplier Name</th>
                  <th>Bin No.<span class="required">*</span></th>
                  <th>Remarks</th>
                </tr>
                <tr>
                 <td><input kendoTextBox class="form-control" [(ngModel)]="sparePartUpdateForm.PARTNO" disabled/></td>
                  <!-- <td>  <kendo-dropdownlist [data]="partNoDropDown"  [textField]="'partNo'" (valueChange)="changedPartNo($event)"
                    [valueField]="'id'"
                    [valuePrimitive]="true" class="form-control" class="form-control" disabled>
                    </kendo-dropdownlist></td> -->
                  <td> <input kendoTextBox class="form-control" [(ngModel)]= "partDescription" disabled/></td>
                  <td> <kendo-dropdownlist  class="form-control"   [textField]="'NAME'"
                    [valueField]="'ID'"
                    [valuePrimitive]="true" [(ngModel)] ="spareCategoryId"  disabled [data]=" stockCategoryDropDow">
                    </kendo-dropdownlist></td>
                    <td>  <kendo-dropdownlist [data]="LocDropDown"   [textField]="'NAME'"
                        [valueField]="'ID'"
                        [valuePrimitive]="true" class="form-control" class="form-control" [(ngModel)]="locValue" (blur)="locValueChange($event)">
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="locErr">*Required</kendo-formerror>
                      </td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control" [(ngModel)]="sparePartUpdateForm.MINUNIT"/>
                          <kendo-formerror *ngIf="error.MINUNIT">*Required</kendo-formerror>
                        </td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control" [(ngModel)]="sparePartUpdateForm.MAXUNIT"/></td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control"  [(ngModel)]="sparePartUpdateForm.ESTIMATEDLIFESPAN"/></td>
                        <td> <kendo-datetimepicker  [(ngModel)]="sparePartUpdateForm.EXPIRYDATE"> </kendo-datetimepicker>
                          <kendo-formerror *ngIf="error.EXPIRYDATE">*Required</kendo-formerror>
                        </td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control"  [(ngModel)]="sparePartUpdateForm.QUANTITY"/>
                          <kendo-formerror *ngIf="error.QUANTITY">*Required</kendo-formerror>
                        </td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control" [(ngModel)]="sparePartUpdateForm.ERPPURCHASECOST"/></td>
                        <td> <input type="number"  [min]="0" kendoTextBox  class="form-control"  [(ngModel)]="sparePartUpdateForm.COSTPERPCS"/></td>
                        <td> <input    kendoTextBox  class="form-control"  [(ngModel)]="sparePartUpdateForm.INVOICENO"/></td>
                        <td> 
                          <kendo-dropdownlist [data]="contractorVendDropDown"  [(ngModel)]="sparePartUpdateForm.BPMID"   [textField]="'name'"
                          [valueField]="'id'"
                          [valuePrimitive]="true" class="form-control" class="form-control">
                          </kendo-dropdownlist>
                        </td>
                        <td> <input   kendoTextBox  class="form-control" [(ngModel)]="sparePartUpdateForm.BINNO"/>
                          <kendo-formerror *ngIf="error.BINNO">*Required</kendo-formerror>
                        </td>
                        <td> <input kendoTextBox class="form-control" [(ngModel)]="sparePartUpdateForm.REMARKS"/></td>
                </tr>
               
              </table>
            </div>
         </div>
       
        <div class="form-row" style="height: 100px;">
        </div>

        <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
      <button kendoButton primary="true" (click)="onReset()">
        Reset
       </button>
      <button kendoButton primary="true" *ngIf="type=='new'" (click)=" submitSparePartUpdateFrom()">
       Save
      </button>
      <button kendoButton primary="true" *ngIf="type=='update'" (click)="submitSparePartUpdateFrom()">
       Update
       </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)=" removeSpareUpdate()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=4
                (valueChange)=importDataChange($event)
                style="width: 100%;"
          >
           </app-file-model>
            </div>
             </div>
<kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="fileUpload()" >
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>