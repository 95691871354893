import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class ChecklistSubService{ 
    ID:number;
    CHECKLISTID:number=null;
    SUBSERVICEID:number=null;
    SUBSERVICE2ID:number=null;
    SUBSERVICE3ID:number=null;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number;
   async  saveData(){
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.checklistsubservice,this,"new")
    }	
   async updateData(){
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.checklistsubservice,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.checklistsubservice,obj);
    }
}

export class ChecklistSubServiceObj{
    alldata:Array<ChecklistSubService>=[]
    filterData:Array<ChecklistSubService>=[]
    selectedObj:ChecklistSubService=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:ChecklistSubService)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.checklistsubservice)
            .then((result:Array<ChecklistSubService>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}