import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-er-task-code-generation',
  templateUrl: './er-task-code-generation.component.html',
  styleUrls: ['./er-task-code-generation.component.scss']
})
export class ErTaskCodeGenerationComponent implements OnInit {
  public contectGrid = []

  constructor() { }
  public contectColumn=[
    { 
      field: "name", 
      title: "Parameter Name",
      errorField:"name_error",
    isFilter: false, 
    width: 140 
  },
    { 
        field: "designation", 
        title: "UOM", 
        isFilter: false, 
        width: 140,
        errorField:"designation_error",
      },
    { 
      field: "contactNo", 
      title: "Min", 
      isFilter: false, 
      width: 140,
  
    },
    { 
      field: "email", 
      title: "Max", 
      isFilter: false, 
      width: 140,
      errorField:"email_error",
      validation:(val)=>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(val && re.test(String(val).toLowerCase()))
             return ""
         else 
           return "*Invalid Email"
        },
    },
   
   
  ]
  ngOnInit(): void {
  }

}
