<div class="card m-3"  >
    <div class="card-header" >
        <h5>Location QR Code Printing</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Contractor And Vendor
          </button> -->
        </div>
    </div>
    <div class="card-body register-form-body"  >
       
               
                
                   <div class="card m-3">
                   
                    <div class="register-form-body">
                      <app-table-grid 
           [data]="gridData" 
           [column]=" column"
           [size]="10"
           [action]="false"
           [deleteFlag]="false"
           [checkbox] ="true"
           deleteKey="deleteKey" 
           ></app-table-grid>
    
        <!-- <app-table-grid
        [data]="contectGrid"
        [column]="contectColumn"
        [size]="5"
        [action]="false"
        [checkbox] ="true"
      ></app-table-grid> -->
        
        
                    </div>
                    
                        </div>   
             
                        <div class="form-row">
                          <button kendoButton   primary="true" class="ml-2 mr-3 px-3" (click)="openPrintModel()">
                           View & Print
                          </button>      
                       </div>
</div>

<!-- <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
          
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true">
            Submit
      </button>
    </kendo-dialog-actions>
  </kendo-dialog> -->
  <!-- <kendo-pdf-export #pdf  hidden>
    <img  *ngFor ="let item of qrCodeGenUrl"
     [src]="imageUrl+item" 
       width="750px"
     />
     </kendo-pdf-export> -->

     <kendo-dialog *ngIf="opened" (close)="closePrintModel()" [width]="920">
      <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
          View QR
        </div>
      </kendo-dialog-titlebar>
      <!-- <div *ngIf="qrCodeGenUrl.length>0">
      <kendo-pdf-export   #pdf [imageResolution]="360"  forcePageBreak=".page-break">
        
        <div  *ngFor ="let item of qrCodeGenUrl;let i=index" [ngClass]="(i!=0)?'page-break':''">
        <img 
         src={{imageUrl+item}} 
         width="150px"
         />
         </div>
  
         </kendo-pdf-export> 
      <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="closePrintModel()">Cancel</button>
        <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')" *ngIf="permission && permission.isPrint">
              Print
        </button>
      </kendo-dialog-actions>
      </div> -->

      <!-- <div  *ngIf="qrcodePrintData.length>0"  >
        <div style="background: white; padding: 1rem;">
      <kendo-pdf-export   #pdf paperSize="A4">
        
        <div  *ngFor ="let item of qrcodePrintData;let i=index" [ngClass]="(i!=0)?'page-break':''">
         <div style="width: 100%;display: flex;justify-content: center;margin: 1rem;">
          <div style="border: 2px solid black;border-radius: 0.25rem;width: 170px;display: grid;grid-template-columns: 55% 45%;">
             <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;padding: 0.15rem;">
                 <div style="display: flex;justify-content: center;"><img src="assets/logo.png"  style="height: 20px;"></div>
                 <div style="color: black;text-align: center;font-size: 8px;padding:0.5rem 0.15rem;" ><div style="padding: 0.15rem;border: 1px solid black;">{{item.location}}</div></div>
             </div>
             <div style="display: flex;justify-content: center;flex-direction: column;">
                <kendo-qrcode
                style="width: 99%;"
                value={{item.data}}
                errorCorrection="M"
              >
              </kendo-qrcode>
             </div>
             </div>
         </div>
         </div>
  
         </kendo-pdf-export> 
         </div>
      <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="closePrintModel()">Cancel</button>
        <button  kendoButton primary="true" (click)="pdf.saveAs('qr-code.pdf')">
              Print
        </button>
      </kendo-dialog-actions>
      </div> -->
      <app-qr-printing *ngIf="qrcodePrintData.length>0" type="location" [qrcodePrintData]="qrcodePrintData"></app-qr-printing>
    </kendo-dialog>
  