<div class="dashboard-body">
  <div class="dashboard-content"> 
<div class="content-wrapper">
  <div class="page-wrapper">
    <div class="item">
      <ul class="li_card">

        <li data-target="#myModal1" *ngFor="let item of  dashboardCount " data-toggle="modal">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col-12" (click)="ClickOnDashboardCount(item.id,item.type,item.count)">
                  <span class="count light-black" [style.color]="item.colorcode">{{item.count}}</span>
                  <h5 class="card-titleone" >{{item.type}}</h5>
                </div>
                <!--                                
                          <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i class="fas fa-chart-bar"></i>
                          </div>
                      -->
              </div>

            </div>
          </div>
        </li>


      </ul>
    </div>
    </div>
    <div class="page-wrapper1">

    <div class="item">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Asset By Maintenance Type</div>
        </div>
        <div class="ibox-body">
          <div id="example" class="chart-height">
            <kendo-chart class="chart">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-series>
                <kendo-chart-series-item [autoFit]="autofit" type="bar" [data]="WorkOrderType " categoryField="type" 
                  field="countValue" colorField="colorcode">
                  <kendo-chart-series-item-labels position="bottom"  background="transparent" color="#black" font="20px" [content]="labelContent">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend [visible]="true"></kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
      </div>

    </div>
    <div class="item">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Asset By Status</div>
        </div>
        <div id="example" class="chart-height">
          <kendo-chart class="chart">
            <kendo-chart-legend   position="top" font= "5px sans-serif"></kendo-chart-legend>
            <kendo-chart-series>
              <kendo-chart-series-item [autoFit]="autofit" type="donut" [data]="WorkOrderStatus " categoryField="type" field="countValue"
                colorField="colorcode">
                <kendo-chart-series-item-labels position="outsideEnd" font= "12px sans-serif"
                color="#000" format="p1" margin="1px" padding="1px" 
                  [content]="labelContent1">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>

            </kendo-chart-series>
          </kendo-chart>

          <!-- <kendo-chart>
            <kendo-chart-title
              text="Share of Internet Population Growth"
            ></kendo-chart-title>
            <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            <kendo-chart-area background="none"></kendo-chart-area>
            <kendo-chart-tooltip>
              <ng-template
                kendoChartSeriesTooltipTemplate
                let-value="value"
                let-category="category"
                let-series="series"
              >
                {{ category }} ({{ series.name }}): {{ value }}%
              </ng-template>
            </kendo-chart-tooltip>
            <kendo-chart-series>
              <kendo-chart-series-item
                *ngFor="let series of model; let outermost = last"
                type="donut"
                [startAngle]="150"
                [name]="series.name"
                [data]="series.data"
                field="value"
                categoryField="category"
                colorField="color"
              >
                <kendo-chart-series-item-labels
                  *ngIf="outermost"
                  position="outsideEnd"
                  background="none"
                  [content]="labelContent"
                >
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart> -->
        </div>
      </div>

    </div>
    <div class="item">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Asset By Service Type</div>
        </div>
        <div class="ibox-body">
          <div id="example" class="chart-height">
            <kendo-chart class="chart">
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [labels]="false"></kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-series>
                <kendo-chart-series-item [autoFit]="autofit" type="column" [data]="WorkOrderService "
                  categoryField="type" field="countValue" colorField="colorcode">
                  <kendo-chart-series-item-labels position="center" background="none" color="#000" font="13px sans-serif" [content]="labelContent">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<!-- <div class="custom-tablesection">
  <div class="custom-dropdown">
    <div style="position: relative;display: flex;justify-content: flex-end;">
    <span class="div_page" style="display: flex;justify-content: flex-end;">
      <label style="font-size: 14px;width: 85px;display: flex;justify-content:center;flex-direction: column;height: 100%;">Page Count</label>
      <kendo-dropdownlist [data]="countDropDown" style="width: 70px;" [value]="selectedCount" [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true"
        (valueChange)="countValueChange($event)"
        ></kendo-dropdownlist>
    </span>
    <span class="div_facility" style="margin-left: 0.25rem;"> 
      <kendo-dropdownlist 
        [data]="facilityDropDown" 
        [value]="slecteFacility" 
        [textField]="'name'"  
        [valueField]="'id'"  
        [valuePrimitive]="true"
        (valueChange)="facilityValueChange($event)"
        [filterable]="true" 
        (filterChange)="handleFacilityFilter($event)"
        style="width: 200px;"
        class="" id="customform"
        >
      </kendo-dropdownlist>
        <button kendoButton   (click)="download()" icon="export" style="color: black;background: transparent;border: 0px;"></button>
      </span>
    </div>
  </div>
  </div> -->
<!-- <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1200">
  <kendo-dialog-titlebar>
    <div class="w-75 float-left text-left" style="font-size: 18px; line-height: 1.3em;">
      {{dialogTitle}}
    </div>
    
<div class="w-25 float-left text-right">
  <button kendoButton  (click)="downloadForpopup()" icon="export" style="color: #fff;background: transparent;border: 0px;"></button>
</div>
  </kendo-dialog-titlebar>
  <div class="card-body" style="background: #fff; margin:-11px; color:#000;padding:6px;">
    <app-dashboard-table-grid *ngIf="!withRequest" [data]="gridDataByCount" [column]="countColumn" [size]="10" [action]="false"></app-dashboard-table-grid>
    <app-dashboard-table-grid *ngIf="withRequest" [data]="gridDataByCount" [column]="columnRequest" [size]="10" [action]="false"></app-dashboard-table-grid>
  </div>
</kendo-dialog>
<kendo-dialog *ngIf="woPopUpopened" (close)="onDialogClose('woPopup')" [width]="850" >
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      {{woPopupTitle}}
    </div>
  </kendo-dialog-titlebar>
  <div class = "card-body" *ngFor="let item of woPopUpData" style="background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  color: rgb(0, 0, 0);
  margin: -11px;">
      <div class = "py-4">
        <div class="form-row">
            <div class="form-group col-5">
                <label><b>Description</b></label>
                <div>  {{item.description}}</div>
              
                
            </div>
            <div class="form-group col-5">
              <label><b>Location</b></label>
          <div> {{item.locationName}} </div>
      
              
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-5">
              <label><b>Assigned</b></label>
              <div *ngIf="item.assigneeObj != null"> 
                {{ item.assigneeObj.staffName}}
              </div>
          
              
          </div>
          <div class="form-group col-5">
            <label><b>Type</b></label>
            <div *ngIf="item.consequanceObj != null">{{item.consequanceObj.NAME}}</div>
          
    
            
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-5">
            <label><b>SLA</b></label>
           <div>{{item.slaStatus}}</div>
            
        </div>
        <div class="form-group col-5">
          <label><b>Date & Time</b></label>
          <div>{{ formatDateTime(item.createdDate) }}</div>
         
  
          
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-5">
          <label>Attachment
        </label>
        <div class="card image-card"  *ngFor="let img of item.attachmentFiles">
          <div *ngIf="img.fileType.toLowerCase() ==='jpg' || img.fileType.toLowerCase() ==='png'">
            <img [src]="imageUrl+img.attchementFile" height="80px" width="120px">
          </div>
          <div>
         
            <a *ngIf="img.fileType ==='pdf'" icon="pdf"
            href="javascript:void(0)" 
            style="cursor:pointer"
            (click)="downloadPdf(imageUrl+img.attchementFile)">
            Download PDF
          </a>
          </div>
          
        </div>
      </div>
      <div class="form-group col-5">
      </div> 
  </div>
    </div>
  </div>

</kendo-dialog>
</div>
</div>
<div  *ngIf="!isAccess">

  <div class="dashboard-content"> 
    <div class="content-wrapper">
      <div class="page-wrapper">
<h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>

      </div>
    </div>
  </div>

</div> -->
<style>
  
</style>