
<div class="card m-3">
    <div class="card-header" >
        <h5>{{uiText.title}} Planner</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            {{uiText.title}} Planner
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened"  (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div class="py-4">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Name<span class="required">*</span></label>
                <input kendoTextBox [(ngModel)]="data.NAME"  class="form-control"/>
                <kendo-formerror *ngIf="validiationRequired.name">*Required</kendo-formerror>
            </div>
            <div class="form-group col-5">
              <label>Service<span class="required">*</span></label>
              <kendo-dropdownlist  
                  [data]="model.service.filterData" 
                  [value]="model.service.selectedObj" 
                  (valueChange)="valuechange('service',$event)" 
                  class="form-control"
                  [textField]="'NAME'" 
                  [valueField]="'ID'" 
              >
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="validiationRequired.serviceType">*Required</kendo-formerror>
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Checklist<span class="required">*</span></label>
                <kendo-dropdownlist 
                    [filterable]="true" 
                    [data]="model.checklist.filterData"  
                    [value]="model.checklist.selectedObj" 
                    (valueChange)="valuechange('checklist',$event)" 
                    class="form-control"
                    [textField]="'description'" 
                    [valueField]="'id'" 
                    (filterChange)="handleFilter('checklist',$event)"
                >
               </kendo-dropdownlist>
            <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror>
            </div>
            <div class="form-group col-5">
                <label>Status<span class="required">*</span></label>
                <kendo-dropdownlist  
                    [data]="model.status.filterData" 
                    [value]="model.status.selectedObj" 
                    (valueChange)="valuechange('status',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="validiationRequired.status">*Required</kendo-formerror>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Year<span class="required">*</span></label>
                <kendo-dropdownlist  
                    [data]="model.year.filterData" 
                    [value]="model.year.selectedObj" 
                    (valueChange)="valuechange('year',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
               </kendo-dropdownlist>
               <kendo-formerror *ngIf="validiationRequired.year">*Required</kendo-formerror>
            </div>
            <div class="form-group col-5">
                <label *ngIf="id==1">Frequency<span class="required">*</span></label>
                <kendo-dropdownlist
                    *ngIf="id==1"  
                    [data]="model.maintenaceFrequence.filterData" 
                    [value]="model.maintenaceFrequence.selectedObj" 
                    (valueChange)="valuechange('maintenaceFrequence',$event)" 
                    class="form-control"
                    [textField]="'DESCRIPTION'" 
                    [valueField]="'ID'" 
                 >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="validiationRequired.freq">*Required</kendo-formerror>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>First Date<span class="required">*</span></label>
                <kendo-datepicker [format]="format" [(ngModel)]="data.PPMSTARTDATE"  >
                </kendo-datepicker>  
                <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>          
            </div>
            <div class="form-group col-5">
              <label>Expired date<span class="required">*</span></label>
              <kendo-datepicker [format]="format" [(ngModel)]="data.PPMENDDATE"  >
              </kendo-datepicker>
              <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror>
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Exclude Weekend</label>
                <input type="checkbox"  kendoCheckBox [(ngModel)]="data.EXCLUDEWEEKEND"/>
                <!-- <kendo-datepicker [format]="format" [(ngModel)]="data.PPMSTARTDATE"  >
                </kendo-datepicker>  
                <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">
            </div>
        </div>
        <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                Save
            </button>
            <button *ngIf="type!='update'" kendoButton   primary="true" class="px-3" (click)="reset()">
                Reset
            </button>
        </div>
    
      </div>
    
  </kendo-dialog>
