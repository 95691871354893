<!-- main app container -->
<div class="card m-3">
    <!-- <h5 class="card-header">User Shift And Leave Details</h5> -->
    <div class="card-header" >
        <h5>User Shift And Leave Details</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> User Shift And Leave Details
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <!-- <app-table-grid
          [data]="gridData"
          [column]="column1"
          [size]="5"
        ></app-table-grid>
        <br>
        <br> -->
        <app-table-grid
        [data]="gridDataleave"
        [column]="column"
        [size]="5"
        [viewFlag]="true"
      ></app-table-grid>
    </div>
  </div>


<!-- <div class="card m-3">
    <h5 class="card-header">Summary</h5>
    <div class="card-body">
          
    </div>
</div> -->

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">

        <kendo-dialog-titlebar>
            <div style="font-size: 18px; line-height: 1.3em;">
               {{dailogTitle}}
            </div>
          </kendo-dialog-titlebar>
   
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab  title="User Shift" [selected]="true">
            <ng-template kendoTabContent >
                <div class="form-row mt-4">
                    <div class="form-group col-5">
                        <label>Staff Name<span style="color: red;">*</span></label>
                        <div style="width:100%">
                        <kendo-dropdownlist  
                     [data]="filteredShiftData" class="form-control"
                     [textField]="'name'"
                     [valueField]="'id'"
                     [valuePrimitive]="true"
                    [(ngModel)]="userShiftForm.EMPLOYEEGLOBALID"
                    [filterable]="true"
                    (filterChange)="handleStaffFilter($event)"
                    (valueChange)="staffValueChange($event)" 
                    >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf= "shifterror.EMPLOYEEGLOBALID">*Required</kendo-formerror>
                </div>
                    </div>
                    <div class="form-group col-5">
                        <label>Mobile No.</label>
                        <input kendoTextBox  class="form-control"  [(ngModel)]="mobileNo" disabled>
                        
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-group col-5">
                        <label>User Type</label>
                        <input kendoTextBox  class="form-control"  [(ngModel)]="userType" disabled>
                    </div>
                    <div class="form-group col-5">
                        <label>Designation </label>
                        <input kendoTextBox  class="form-control" [(ngModel)]="designationName" disabled>
                    </div>
                </div>
                <!-- <div class="form-row">
                    <div class="form-group col-5">
                        <label>Shift<span style="color: red;">*</span> </label>
                        <div style="width:100%">
                        <kendo-dropdownlist  
                        [data]="shiftDropDown" class="form-control"
                        [textField]="'NAME'"
                        [valueField]="'ID'"
                        [valuePrimitive]="true"
                        (valueChange)="shiftValueChange($event)" 
    
                        [(ngModel)]="userShiftForm.SHIFTID"
                        >
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="shifterror.SHIFTID">*Required</kendo-formerror>
                        </div>
                    </div>
                    <div class="form-group col-5">
                       
                    </div>
                </div> -->
                <div class="form-row">
                   <div class="form-group col-5">
                               
                                <label style="width: 45px;">Shift Time<span style="color: red;">*</span>&nbsp;&nbsp;&nbsp;</label>
                                <kendo-timepicker   [format]="'HH:mm:ss'" [(ngModel)]="userShiftForm.DATETIMETO" ></kendo-timepicker>
                                <kendo-formerror *ngIf= "shifterror.DATETIMETO">*Required</kendo-formerror>
                            </div>
                            <div class="form-group col-5">
                                <label style="width: 45px;">Shift Break Time <span style="color: red;">*</span></label>
                                <kendo-timepicker  [format]="'HH:mm:ss'" [(ngModel)]="userShiftForm.DATETIMEFROM" ></kendo-timepicker>
                                <kendo-formerror *ngIf= "shifterror.DATETIMEFROM">*Required</kendo-formerror>
                            </div>
                       
                        </div>
                   <!-- <div class="form-row">
                    <div class="form-group col-5">
                        <label>Break Time In Minutes<span style="color: red;">*</span> </label>
                        <input kendoTextBox  class="form-control"   [(ngModel)]="userShiftForm.BREAKHOURS"  disabled>
                       
                     
                        </div>
                        <div class="form-group col-5"></div>
                    </div> -->
                    <div  class="row justify-content-center my-3">
                        <button kendoButton *ngIf="type=='new'" class="mr-2 px-3"  (click)="onDialogClose('new')">Cancel</button>
                        <button kendoButton  *ngIf="type=='new'" primary="true" (click)="onReset()" class="mr-2 px-3">Reset</button>
                         <button kendoButton   primary="true" (click)="submitShiftFrom('save&new')" class="mr-2 px-3">Save&new</button>
                        <button kendoButton *ngIf="type=='new'"    primary="true" (click)="submitShiftFrom('new')" class="px-3">Save</button>
                        <!-- <button kendoButton *ngIf="type=='update'"   primary="true" (click)="submitShiftFrom()" class="px-3">Update</button> -->
                    </div>
               
        </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab  title="Leave Details" *ngIf ="leavDetailTab">
            <ng-template kendoTabContent >
             
                

                <app-contact-asset-grid [data]="leaveGrid" (valueChange)="leaveGrid=$event"  [column]="personInChargeColumn" [size]="10"
                [deleteFlag]="false" 
                (licenseassetValue)="personchargeChangeValue($event)">
             </app-contact-asset-grid>

                <div  class="row justify-content-center my-3">
                    <button kendoButton class="mr-2 px-3" (click)="onDialogClose('new')">Cancel</button>
                    <button kendoButton   primary="true" (click)="onReset()" class="mr-2 px-3">Reset</button>
                  
                    <button kendoButton   primary="true" (click)=" addLeave()" class="px-3">Save</button>
                    <!-- <button kendoButton *ngIf="type=='update'"   primary="true" (click)=" submitLeaveFrom()" class="px-3">Update</button> -->
                </div>
        </ng-template>
        </kendo-tabstrip-tab>
   
    </kendo-tabstrip>

    <!-- <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton  primary="true" (click)="onSubmit()">
        Save
      </button>
    </kendo-dialog-actions> -->
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeShiftData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  
  <kendo-dialog *ngIf="leaveopened" (close)="onDialogClose('leave')" [width]="920">

    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
          View Leave
        </div>
      </kendo-dialog-titlebar>
      <!-- <div class="form-row">
        <div class="form-group col-5">
            <label>Leave from </label>
            <kendo-datepicker [(ngModel)]="searchLeaveFrom" [format] ="format" (valueChange)="geteaveForm($event)"></kendo-datepicker>
        </div>
        <div class="form-group col-5">
            <label>Leave To </label>
            <kendo-datepicker  [(ngModel)]="searchLeaveTo"  [format] ="format"  (valueChange)="geteaveTo($event)"></kendo-datepicker>
        </div>
    </div> -->
      <app-table-grid
      [data]="gridleaveDetails"
      [column]="columnLeave"
      [size]="5"
      [editFlag] = "false"
      [deleteFlag]="true"
    ></app-table-grid>
      </kendo-dialog>