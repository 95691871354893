import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DrawerComponent, DrawerMode, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { CustomMessagesService } from './services/custom-messages.service';
import {menu} from "./Data/menu"
import {eventListner,EVENT_NAME } from 'src/event';
import {getCookie, getQueryParams, setCookie} from "src/Util/Util"
import {authorization,userid} from "src/Util/const"
import { environment } from 'src/environments/environment';
import {UserModuleObj} from "src/ApiModel/Model/UserModule"
import HttpClient from "src/Util/ApiHandling";
import { HttpClient as http } from '@angular/common/http';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public selected = 'Team';
    public items:any = menu.parents;
    public customMsgService: CustomMessagesService;
    public mode: DrawerMode = 'push';
    public mini = true;
    public auth=false;
    public itemIndex;
    public isResetPassord=false;
    public userName=""
    public token=""
    public userId=""
    public opened=false;
    public openedPolicy=false;
    public tCtitle='';
    public tCvalue='';
    public privacytitle='';
    public privacyvalue='';
    constructor(private router: Router, public msgService: MessageService,private http:http) {
        this.customMsgService = this.msgService as CustomMessagesService;
        let spt=window.location.pathname.split("/");
       // UserModuleObj.init()
        if(spt[1]===environment.reset_password_pathname){
           this.token=getQueryParams("Token");
           this.userName=getQueryParams("UserName")
           this.userId=getQueryParams("Userid")
           setCookie(authorization,this.token,1)
           setCookie(userid,this.userId,1);
           this.isResetPassord=true;
           this.auth=false;
        }
        else{
          eventListner(EVENT_NAME.authorization,(status)=>{
            // window.location.href=window.location.origin+"/dashboard"
            if(status){
             let id= getCookie(userid)
              UserModuleObj.getData(id)
              .then((result)=>{
                this.auth=true;
                // router.navigate(["/dashboard"])
              })

            }
            else{
              setCookie(authorization,"",1);
              setCookie(userid,"",1);
              this.auth=false;
            }
          })
          if(getCookie(authorization) && getCookie(userid)){
            let id= getCookie(userid)
            UserModuleObj.getData(id)
            .then((result)=>{
              this.auth=true;
            //  router.navigate(["/dashboard"])
            })
          //  this.auth=true;
          }
        }
    }

    ngOnInit() {
    
    this.getip();
    }
    getip()
    {
    this.http.get('https://jsonip.com/').subscribe((data:any)=> {
       localStorage.setItem('ip',data.ip);
      })
    }
    loginStatus(status){
        this.auth=status
    }
    ngOnDestroy() {
        // window.removeEventListener('resize', () => {});
    }

    public setDrawerConfig() {
        const pageWidth = window.innerWidth;
        if (pageWidth <= 770) {
            this.mode = 'overlay';
            this.mini = false;
        } else {
            this.mode = 'push';
            this.mini = true;
        }
    }

 
    public toggleDrawer(drawer: DrawerComponent): void {
        drawer.toggle();
    }

    public onSelect(ev: DrawerSelectEvent): void {
        this.selected = ev.item.text;
        const item = this.items.find((e, index) => {
          this.itemIndex = index;
          return e.text === ev.item.text;
        });
    
        item.expanded ? (item.expanded = false) : (item.expanded = true);
        if(ev.item.childrenList && ev.item.childrenList.length>0){
            item.expanded
            ? this.addChildren(ev.item.childrenList)
            : this.removeChildren(ev.item.childrenList);
        }
        if(ev.item.path){
        this.router.navigate([ev.item.path]);
        }
    }
    public addChildren(children) {
        this.items.splice(this.itemIndex + 1, 0, ...children);
      }
    
      public removeChildren(children: Array<any>) {
        this.items.splice(this.itemIndex + 1, children.length);
      }
    private baseImageUrl =
    "https://demos.telerik.com/kendo-ui/content/web/panelbar/";

  public imageUrl(imageName: string): string {
    return this.baseImageUrl + imageName + ".jpg";
  }
  public textvalue = `
  <p>When resizing is enabled, the minimum and maximum width and height are configurable through the <strong>EditorResizableOptions</strong> object.
  The available properties are <em>minWidth</em>, <em>minHeight</em>, <em>maxWidth</em>, and <em>maxHeight</em>. All propeties accept numeric values that
  represent pixels. This demo sets the following values:</p>
  <ul>
      <li><strong>minWidth</strong>: 300</li>
      <li><strong>minHeight</strong>: 150</li>
      <li><strong>maxWidth</strong>: 600</li>
      <li><strong>maxHeight</strong>: 450</li>
  </ul>
`;
  onDialogOpen() {
    this.getTermsandconditions();
   
  
}
onDialogOpenprivacypolicy()
{
  this.getprivacy();
}
onDialogClosepolicy()
{
  this.openedPolicy = false;
}
  onDialogClose() {
      this.opened = false;
  }

  getTermsandconditions()
  {
    HttpClient.get(
      `/api/MasterData/GetSystemSettingId?id=1`,
      true,
      true
    ).then((result: any) => {
      this.tCtitle=result.titile;
      this.tCvalue=result.body;
      this.opened = true;
    });
  }
  getprivacy()
  {
    HttpClient.get(
      `/api/MasterData/GetSystemSettingId?id=2`,
      true,
      true
    ).then((result: any) => {
      this.privacytitle=result.titile;
      this.privacyvalue=result.body;     
  this.openedPolicy = true;
    });
  }
}
