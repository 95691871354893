<div class="card m-3"   >
    <!-- <h5 class="card-header">Contractor And Vendor</h5> -->
    <div class="card-header">
        <h5>Contractor And Vendor</h5>
        <div>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
                Download Template 
            </button>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="importopened=true" class="header-right-btn" style="margin-right: 0.5rem;">
               Import
            </button>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
                <span class="add_btn">+</span> Contractor And Vendor
            </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        
        <app-table-grid  
        [data]="gridData"
        [column]="column1" 
        [size]="10"
        [editFlag] ="permission && permission.isUpdate"
        [deleteFlag]="false"
        [fileFlag]="true"
        deleteKey="deleteKey"
      ></app-table-grid>
        <!-- <app-contractor-vendor-grid 
            [data]="gridData" 
           
            [size]="10"
            
        >
        </app-contractor-vendor-grid> -->
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose('contractor')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    <div class="form-row">
       <div class="form-group col-5">
            <label>Contractor and Vendor Registration No<span class="required">*</span></label>
            <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="contractorVendorForm.registrationNo" class="form-control" />
                <kendo-formerror *ngIf="submitted && error.registrationNo">*Required</kendo-formerror>
            </div>
        </div>
        <div class="form-group col-5">
            <label>Contractor/Vendor <span class="required">*</span></label>
            <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="contractorVendorForm.name" class="form-control" (blur)="checkDublicateName()"/>
                <kendo-formerror *ngIf="submitted && error.name">*Required</kendo-formerror>
                <kendo-formerror *ngIf="isDublicate">*Name already exist.</kendo-formerror>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Status</label>
            <kendo-dropdownlist [(ngModel)]="contractorVendorForm.statusId" [data]="statusDropDown" class="form-control"
            [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true"
            >
            </kendo-dropdownlist>

        </div>
        <div class="form-group col-5">
            <label>Specialization Detail</label>
            <kendo-multiselect
            [data]="spec_det"
            textField="name"  [value]="specializationDetailFlag"
            valueField="id"
            (valueChange)="specializationDetailFlag=$event"
          ></kendo-multiselect>
            <!-- <kendo-dropdownlist [data]="spec_det" class="form-control" [(ngModel)]="contractorVendorForm.bpmskills" [textField]="'name'" [valueField]="'id'"
                [valuePrimitive]="true">
            </kendo-dropdownlist> -->
        </div>
      
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>Address 1<span class="required">*</span></label>
            <div style="width: 100%;">
                <kendo-textarea name="message" class="form-control" [(ngModel)]="contractorVendorForm.address1"
                    [rows]="5"></kendo-textarea>
                <kendo-formerror *ngIf="submitted && error.address1">*Required</kendo-formerror>
            </div>
        </div>
      
      
        <div class="form-group col-5">
            <label>Address 2</label>
            <kendo-textarea class="form-control" [rows]="5" [(ngModel)]="contractorVendorForm.address2">
            </kendo-textarea>
        </div>
      
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>PostCode<span class="required">*</span></label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="contractorVendorForm.postCode" />
                <kendo-formerror *ngIf="submitted && error.postCode">*Required</kendo-formerror>
            </div>
        </div>
        <div class="form-group col-5">
            <label>Country<span class="required">*</span></label>
            <div style="width: 100%;">
            <kendo-dropdownlist 
               [data]="countryDropDown" 
               [(ngModel)]="contractorVendorForm.countryId" 
                class="form-control"
                [textField]="'NAME'" 
                [valueField]="'ID'" 
                [valuePrimitive]="true" 
                (valueChange)="valuechange($event)"
                >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="submitted && error.countryId">*Required</kendo-formerror>
        </div>
        </div>
    
    </div>
    <div class="form-row">
        <div class="form-group col-5">
            <label>State<span class="required">*</span></label>
            <div style="width: 100%;">
            <kendo-dropdownlist [data]="stateDropDown" [(ngModel)]="contractorVendorForm.stateId" class="form-control"
                [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true">
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="submitted && error.stateId">*Required</kendo-formerror>
            </div>
        </div>
      
        <div class="form-group col-5">
            <label>FAX No.</label>
            <input kendoTextBox class="form-control" [(ngModel)]="contractorVendorForm.faxNo" />
        </div>
    </div>
    <div class="form-row">
        
  
    <!-- </div>
    <div class="form-row"> -->
       
        <div class="form-group col-5">
            <label>No. of User Access</label>
            <div style="width: 100%;">
                <input kendoTextBox class="form-control" [(ngModel)]="contractorVendorForm.noOfUserAccess" />
            </div>
        </div>
       <div class="form-group col-5"></div>
    </div>


    <div class="form-row">
        <div class="form-group col-11 p-3">
            <label>Remark </label>
            <div style="width: 100%;">
                <kendo-textarea class="form-control" [rows]="5" [(ngModel)]="contractorVendorForm.remarks">
                </kendo-textarea>
            </div>
        </div>
    </div>
    <div class="py-3 px-5" >
        <div class="form-row">
            <app-contact-person-grid
            [data]="contectGrid"
            [column]="contectColumn"
            [size]="10"
            [deleteFlag]="false"
            (valueChange)="contectGrid=$event"
            >

            </app-contact-person-grid>
         </div>
      </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('contractor')">Cancel</button>
        <button *ngIf="type=='new'" (click)="onReset()" kendoButton primary="true">
            Reset
        </button>
    <button *ngIf="type=='new'" (click)="onSubmit()" kendoButton primary="true">
        Save
    </button>
        <button *ngIf="type=='update'" (click)="onSubmit()" kendoButton primary="true">
            Update
        </button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="attachmentOpened" (close)="onDialogClose('attachment')" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist 
                    [data]="documentFormate" 
                    class="form-control" 
                    [textField]="'name'" 
                    [valueField]="'id'"
                    [(ngModel)]="attechForm.FILETYPE"
                    [valuePrimitive]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <kendo-formerror *ngIf="attechError.FILENAME">*required</kendo-formerror>

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Attachment</label>
                <div style="width: 100%;">
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
              <!-- <kendo-formerror *ngIf="attechError.URL">*required</kendo-formerror> -->
              </div>
            </div>

            <div class="form-group col-5">
                <button  (click)="addAttechment()" kendoButton primary="true">
                    Add
                </button>
            </div>


        </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('attachment')">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid 
         [data]="attechmentDoc.alldata" 
         [column]="column2" 
         [size]="10"
         >
   </app-attachment-table-grid>

</kendo-dialog>

<kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            Delete Description
        </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="removeBpm()">Delete</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         Import
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [fileUploadType]=5
                   (valueChange)="refreshData($event)"
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>