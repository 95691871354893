import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";

export class ChecklistError{ 
    checkListNo:boolean=false;
    description:boolean=false;
    bpmid:boolean=false;
    ticketConsequenceTypeId:boolean=false;
    serviceTypeId:boolean=false;
    createdDate:boolean=false;
    respondSladuringWorkingHours:boolean=false;
    respondSlaafterWorkingHours:boolean=false;
    completionSladuringWorkingHours:boolean=false;
    completionSlaafterWorkingHours:boolean=false;
    closeSla:boolean=false;	
    workordertypeid:boolean=false
}
export class Checklist{ 
    id:number;
    checkListNo:string;
    description:string;
    bpmid:number;
    ticketConsequenceTypeId:number;
    serviceTypeId:number;
    createdDate:Date;
    createdBy:number;
    respondSladuringWorkingHours:number;
    respondSlaafterWorkingHours:number;
    completionSladuringWorkingHours:number;
    completionSlaafterWorkingHours:number;
    closeSla:number;	
}

export class ChecklistObj{
    alldata:Array<Checklist>=[]
    filterData:Array<Checklist>=[]
    selectedObj:Checklist=null;
    objectData={}
    objectByChecklistNo={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Checklist)=>{
                acc[d.id]=d;
                this.objectByChecklistNo[d.checkListNo]=d.id;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            //HttpClient.get("/api/PMC/getchecklistall",true,true)
            getLoockup(Schema.PMC,LOOKUPTABLE.ChekList)
            .then((result:Array<any>)=>{
               let temp=result.map((d)=>{
                   let c=new Checklist();
                   c.id=d.ID;
                   c.bpmid=d.BPMID;
                   c.checkListNo=d.CHECKLISTNO;
                   c.description=d.DESCRIPTION;
                   c.serviceTypeId=d.SERVICETYPEID;
                   c.ticketConsequenceTypeId=d.TICKETCONSEQUENCETYPEID;
                   return c 
               });
               this.alldata=temp;
               this.filterData=temp;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}