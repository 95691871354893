import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
const ModuleHierarchy = { 
           "root": { "name": "Root", "id": 1 }, 
           "user_management": { "name": "User Management", "id": 2 }, 
           "general_master": { "name": "General Master", "id": 3 }, 
           "fems": { "name": "FEMS", "id": 4 }, 
           "system_setting": { "name": "System Setting", "id": 5 }, 
           "user_screen_mapping": { "name": "User Screen Mapping", "id": 6 }, 
           "asset": { "name": "Asset", "id": 19 }, 
           "asset_classification": { "name": "Asset Classification", "id": 8 }, 
           "asset_type": { "name": "Asset Type", "id": 9 }, 
           "asset_standardization": { "name": "Asset Standardization", "id": 10 }, 
           "facility": { "name": "Facility", "id": 12 }, 
           "sub_location": { "name": "Sub-location", "id": 13 }, 
           "location": { "name": "Location", "id": 14 }, 
           "contractor": { "name": "Contractor", "id": 15 }, 
           "block": { "name": "Block", "id": 16 }, 
           "level": { "name": "Level", "id": 17 }, 
           "area": { "name": "Area", "id": 18 }, 
           "asset_registration": { "name": "Asset Registration", "id": 20 }, 
           "spare_parts": { "name": "Spare Parts", "id": 21 }, 
           "spare_parts_reg": { "name": "Spare Parts Reg", "id": 22 }, 
           "workorder": { "name": "Workorder", "id": 23 }, 
           "contract_management_for_facility_user": { "name": "Contract Management For Facility User", "id": 24 } ,
           "dashboard" : {"name":"Dashboard", "id":25},
           "license_and_certificate" : {"name":"License License and Certificate", "id":31},
           "engineering_records" : {"name":"ER Capture", "id":35},
        //    "er_capture" : {"name":"ER Capture", "id":33},
        //    "er_parameter_mapping" : {"name":"ER Parameter Mapping", "id":34}
           
           
    }


 export class Permission{
    isCreate:boolean=false; 
    isDelete:boolean=false;
    isExport:boolean=false;
    isImport:boolean=false;
    isPrint:boolean=false;
    isRead:boolean=false;
    isUpdate:boolean=false;
    constructor(){
        this.isCreate=false;
        this.isDelete=false;
        this.isExport=false;
        this.isImport=false;
        this.isPrint=false;
        this.isRead=false;
        this.isUpdate=false;
    }
}

export class UserModuleObj{
    // user_role:Permission=new Permission();
    // user_reg:Permission=new Permission();
    // block:Permission=new Permission();
    // level:Permission=new Permission();
    // area:Permission=new Permission();
   static root:Permission=new Permission();          
   static general_master:Permission=new Permission(); 
   static user_management:Permission=new Permission();
   static system_setting:Permission=new Permission(); 
   static facility:Permission=new Permission();     
   static block:Permission=new Permission();           
   static level:Permission=new Permission();            
   static location:Permission=new Permission();             
   static area:Permission=new Permission();                    
   static asset:Permission=new Permission();                    
   static fesmsasset_asset:Permission=new Permission();         
   static asset_registration:Permission=new Permission();                   
   static asset_classification:Permission=new Permission();                 
   static asset_type:Permission=new Permission();                       
   static asset_standardization:Permission=new Permission();                    
   static sub_location:Permission=new Permission();                     
   static spare_parts:Permission=new Permission();
   static contractor:Permission = new Permission();
   static spare_parts_reg:Permission=new Permission();
   static work_order:Permission=new Permission();
   static qr_code:Permission=new Permission();
   static working_calendar:Permission=new Permission();
   static checklist___sla:Permission=new Permission();
   static list_of_values:Permission=new Permission();
   static contract_management_for_facility_user:Permission=new Permission();
   static user_screen_mapping:Permission=new Permission();
   static dashboard:Permission=new Permission();
   static license_and_certificate:Permission=new Permission();
   static engineering_records:Permission=new Permission();
   static er_capture:Permission=new Permission();
   static er_parameter_mapping:Permission=new Permission();


   static init(){
    this.root=new Permission();          
    this.general_master=new Permission(); 
    this.user_management=new Permission();
    this.system_setting=new Permission(); 
    this.facility=new Permission();     
    this.block=new Permission();           
    this.level=new Permission();            
    this.location=new Permission();             
    this.area=new Permission();                    
    this.asset=new Permission();                    
    this.fesmsasset_asset=new Permission();         
    this.asset_registration=new Permission();                   
    this.asset_classification=new Permission();                 
    this.asset_type=new Permission();                       
    this.asset_standardization=new Permission();                    
    this.sub_location=new Permission();                     
    this.spare_parts=new Permission();
    this.contractor = new Permission();
    this.spare_parts_reg=new Permission();
    this.work_order=new Permission();
    this.qr_code=new Permission();
    this.working_calendar=new Permission();
    this.checklist___sla=new Permission();
    this.list_of_values=new Permission();
    this.contract_management_for_facility_user=new Permission();
    this.user_screen_mapping=new Permission();
    this.dashboard=new Permission();
    this.license_and_certificate = new Permission();
    this.engineering_records = new Permission();
    // this.er_capture = new Permission();
    // this.er_parameter_mapping = new Permission();

   }
   static getData(id){
        return new Promise((resolve,reject)=>{
            Promise.all([
                getLoockup("dbo","ModuleHierarchy"),
                HttpClient.get(
                    "/api/Users/GetUserById?id=" +id,
                    true,
                    true
                  ) 
            ])
              .then((result: any) => {
                 let moduleHObj={}
                 let permissionObj={}

                 permissionObj= result[1].userRoles?.reduce((acc,d)=>{
                      if(d.rolesModulePermissions && d.rolesModulePermissions.length>0){
                        d.rolesModulePermissions.forEach((d1)=>{
                            acc[d1.moduleHierarchyId]=d1.modulePermission;
                        }) 
                      }
                      return acc;
                 },{})
                 console.log(permissionObj)
                 let obj=result[0].reduce((acc,d1)=>{
                            let key=d1.NAME.replace(/[ &-]/g,"_").toLowerCase();
                            if(d1.ACTION=="fesmsasset"){
                                key="fesmsasset_"+key;
                            }
                            this[key]=permissionObj[d1.ID]
                            acc[key]={name:d1.NAME,id:d1.ID};
                            return acc;
                        },{})
                      
                        for(let k in this){
                            console.log(k,this[k])
                        }
                        resolve(true)
                })
        })

    }
}