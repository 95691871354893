import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { NotificationService } from "@progress/kendo-angular-notification";
import { LevelType } from "src/app/models/location.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { LevelValidation } from "src/validator/location";

@Component({
  selector: "app-levels",
  templateUrl: "./levels.component.html",
  styleUrls: ["./levels.component.scss"],
})
export class LevelsComponent {
  public onTabSelect(e) {}
  public opened = false;
  public removeDialogOpen = false;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public statusObj = {};
  public facilityObj={};
  public blockNameObj ={};
  public isunique=false;
  public uniqueList=[];
  public statusDropDown = [];
  public facilityDropDown = [];
  public filterdBlockData: Array<{ NAME: string; ID: number }>;
  public blockDropDown = [];
  public allBlocksValue = [];
  public permission:Permission=new Permission();
  //public userModule=new UserModuleObj();
  public levelForm: LevelType = new LevelType();
  error = LevelValidation(this.levelForm, "init");
  public column1 = [
    { field: "FACILITY", title: "Facility Name", isFilter: true, width:160},
    { field: "BLOCK", title: "Block Name", isFilter: true,  width:140},
    { field: "NAME", title: "Level Name", isFilter: true,  width:140 },
    { field: "CODE", title: "Level Code", isFilter: true,width:140},
    { field: "SHORTNAME", title: "Short Name", isFilter: true,width:140},
    { field: "STATUS", title: "Status", isFilter: true,width:130 },
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true,  width:160  },
    { field: "last_update_on", title: "Last Updated On", isFilter: true,  width:160  },
  ];
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  public gridData = [];

  levels: FormGroup;
  submitted = false;

  block = [
    { id: 2, name: "tower1" },
    { id: 3, name: "tower2" },
  ];

  // constructor(private formBuilder: FormBuilder) {
  //   eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
  // }
  // editColumn(data){}
  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  ngOnInit() {
   
    if(UserModuleObj.level)
       this.permission=UserModuleObj.level;
    // this.userModule.getData()
    Promise.all([
      this.getStatus("dbo", "status"),
      this.getfacility(),
     
    ]).then((e) => {
      this.getLevelData("dbo", "blocklevel");
    });
  }

  onReset() {
    this.submitted = false;
    this.openRoleDailog("new")
    //this.levelForm = new LevelType();
  }

  onDialogClose(type) {
    if ((type = "new")) {
      this.opened = false;
      this.levelForm = new LevelType();
    }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.levelForm = new LevelType();
      this.isunique = false
      this.error = LevelValidation(this.levelForm, "init");
      this.levelForm.ID = 0;
      this.levelForm.STATUSID=1;
      this.filterdBlockData=[]
      this.dailogTitle = "Add Level";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Level";
    }
    this.opened = true;
  }

  editColumn(data) {
 
    if (data.action === "edit") {
      this.error = LevelValidation(this.levelForm, "init");
      this.levelForm.ID = data.dataItem.ID;
      this.levelForm.NAME= data.dataItem.NAME;
      this.levelForm.CODE= data.dataItem.CODE;
      this.levelForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.levelForm.SHORTNAME= data.dataItem.SHORTNAME;
      this.levelForm.FACILITYID= data.dataItem.FACILITYID;
      this.levelForm.BLOCKID = data.dataItem.BLOCKID;
      this.levelForm.CREATEDBY= data.dataItem.CREATEDBY;
      this.levelForm.CREATEDDATE= data.dataItem.CREATEDDATE;
      this.levelForm.MODIFIEDBY= data.dataItem.MODIFIEDBY;
      this.levelForm.MODIFIEDDATE= new Date();
      this.levelForm.NAMECREATEDBY= data.dataItem.NAMECREATEDBY;
      this.levelForm.NAMEMODIFIEDBY= data.dataItem.NAMEMODIFIEDBY;
     this.openRoleDailog("update");
    } else if (data.action === "remove") {
     
      this.levelForm = data.dataItem;
      this.levelForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.removeDialogOpen = true;
    }
  }

  onSubmit() {
    this.submitted = true;
    this.error = LevelValidation(this.levelForm, "");
    if (
      !this.error.NAME &&
      !this.error.CODE &&
      !this.error.FACILITYID &&
      !this.error.BLOCKID
    ) {
      if (this.type == "new") {
        let levelcode: any = this.levelForm.CODE;
        this.levelForm.ID = 0,
        this.levelForm.CODE = levelcode;
        this.levelForm.CREATEDBY = parseInt(getCookie(userid)),
        this.levelForm.MODIFIEDBY = parseInt(getCookie(userid)),
        this.levelForm.CREATEDDATE = new Date(),
        this.levelForm.MODIFIEDDATE = new Date();
      }

      this.addFacilty(JSON.stringify(this.levelForm));
    }
  }

  addFacilty(data) {
    const paramsObj = {
      newData: data,
      schemaName: "dbo",
      tableName: "blocklevel",
      userId: getCookie(userid),
    };
    if (this.type == "new") {
      
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Locations","Add Levels","Add Levels inLocations").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
  
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;

           this.getLevelData("dbo", "blocklevel");
          // const statusValue = this.statusDropDown.filter((x) => {
          //   return x.ID == this.levelForm.STATUSID;
          // });
          
          // let obj = {
          //   ...this.levelForm,
          //   STATUS: statusValue[0].NAME,
          //   last_update_on:this.dateFormating(this.levelForm.MODIFIEDDATE)
          // };
          // this.gridData.unshift(obj);
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }

    if (this.type == "update") {
     
      HttpClient.fetchData(
        "/api/MasterData/UpdateLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
         
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Locations","Update Levels","Update Levels inLocations").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getLevelData("dbo", "blocklevel");
        //   const statusValue = this.statusDropDown.filter((x) => {
        //     return x.ID == this.levelForm.STATUSID;
        //   });
        //   let obj = {
        //     ...this.levelForm,
        //     STATUS: statusValue[0].NAME,
        //     last_update_on:this.dateFormating(this.levelForm.MODIFIEDDATE)
        //   };

        //  this.gridData = this.gridData.map((d) => {
        //     if (d.ID == this.levelForm.ID) {
        //       d = obj;
        //     }
        //     return d;
        //   });
        //   this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
      });
    }
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getfacility(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
              .then((result: any) => {
                if(result.length>0){
                let facilityData = [];
                this.facilityDropDown=result;
                facilityData = result;
                if(facilityData.length>0){
                let blocksValue =[];
                blocksValue = facilityData.map(e=>{
                           return e.blocks;
                })
                 for(let i=0;i<blocksValue.length;i++){
                   for(let j=0;j<blocksValue[i].length;j++){
                        let arr =[];
                        arr = blocksValue[i][j];
                        this.allBlocksValue.push(arr);
                   }
                  }

                
                 this.blockDropDown = this.allBlocksValue.filter((e)=>{
                  return  e.statusId==1;
                 });
                
                 this.filterdBlockData = this.blockDropDown.slice();
                
                }
              }
               resolve(result)
              }).catch((err)=>{
                reject(err)
              })
     })
  }

  facilityValueChange(facilityId){
  
   let blockData = this.blockDropDown.filter((e)=>{
           return e.facilityId == facilityId;
         })
     this.filterdBlockData = blockData.slice();
     console.log()
     this.levelForm.BLOCKID=null;
  }

  

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindFacilityObj(result){
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindBlockObj(result){
    this.blockNameObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  getLevelData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let LevelData = [];
      LevelData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindStatusObj(this.statusDropDown);
      this.bindFacilityObj(this.facilityDropDown);
      this.bindBlockObj(this.filterdBlockData);
      this.gridData = LevelData.map((d) => {
        d["STATUS"] = this.statusObj[d.STATUSID];
        d["FACILITY"]=this.facilityObj[d.FACILITYID];
        d["BLOCK"] = this.blockNameObj[d.BLOCKID];
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE);
        return d;
      });
    });
  }

  removeLevelData() {
    HttpClient.fetchData(
      "/api/MasterData/DeleteLookupData",
      "post",
      this.levelForm,
      true,
      true
    )
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Locations","Delete Levels","Delete Levels inLocations").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridData = this.gridData.filter((x) => {
            return x.id != this.levelForm.ID;
          });
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((error) => {});
  }

  handleBlockFilter(value) {
    this.filterdBlockData = this.blockDropDown.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  checkedUniqueCode(){
    this.isunique=false;
    let val:string=this.levelForm.CODE;
    if(!(this.uniqueList.filter((d)=>d==val.toLowerCase()).length>0)){
        this.isunique=this.gridData.filter((d)=>{
          return val.toLowerCase()==d.CODE.toLowerCase()
        }).length>0
    }
  }

}
