<div class="p-4">
    <form>
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist  class="form-control" [textField]="'name'" [valueField]="'id'"
                    [valuePrimitive]="true">
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <input kendoTextBox class="form-control" />
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-7">
                <label>Attachment</label>
                <kendo-upload [autoUpload]="false" >
                    <ng-template kendoUploadFileInfoTemplate let-files>
                        <div>Name: {{ files[0].name }}</div>
                    </ng-template>
                </kendo-upload>
            </div>
            <div class="form-group col-3">
                <div class="py-3" style="text-align: right;width: 100%;">
                    <button kendoButton   primary="true" class="px-4">
                      Save
                    </button>
                </div>
            </div>
        </div>
    </form>
    <app-attachment-table-grid [data]="gridData" [column]="column2" [size]="5"></app-attachment-table-grid>
</div>