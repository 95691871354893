import { Component, OnInit } from '@angular/core';
import {ChecklistTask,Question} from "src/ApiModel/Model/ChecklistTask"
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {MaintenaceChecklistTask,MaintenaceChecklistTaskObj} from "src/ApiModel/Model/MaintanceChecklistTask"
import {TaskAnswerListItem} from "src/ApiModel/Model/TaskAnswerListItem"
import { eventListner, EVENT_NAME } from 'src/event';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { TaskItem } from 'src/ApiModel/Model/TaskItem';
import { NotificationService } from '@progress/kendo-angular-notification';
import { addBlukCheklistTask } from 'src/ApiModel/ImportApi/MaintanceTask';
class QuestionAnsList{
  item:string;
  ansType:any;
  ansList:Array<string>;
  requiredAttechment:boolean;
  mandatoryField:boolean;
  id:number=0;
  ansListItem=[];
  ansListObj=[];
  ansListId=0;
}

class QuestionAnsListError{
  checkList=false;
  item=false;
  ansType=false;
  ansList=false;
  DESCRIPTION=false;
  itemLength=false;
}





@Component({
  selector: 'app-checklist-combind',
  templateUrl: './checklist-combind.component.html',
  styleUrls: ['./checklist-combind.component.scss']
})
export class ChecklistCombindComponent implements OnInit {

  constructor(public _notif:NotificationService) { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  public group=[{field:"checkListObj.description"},{field:"taskObj.DESCRIPTION"}]
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle=""
  public model:Model=new Model();
  public data=new MaintenaceChecklistTask();
  public questionObj=new QuestionAnsList();
  public questionList:Array<QuestionAnsList>=new Array<QuestionAnsList>()
  public task:any=[]
  public ansList=[];
  public checklistData:ChecklistTask=new ChecklistTask();
  public error=new QuestionAnsListError()
  public column=[
    {field:"checkListObj.description",title:"Checklist",isFilter:true,width:200},
    {field:"taskObj.DESCRIPTION",title:"Section Tittle",isFilter:true,width:200},
    {field:"DESCRIPTION",title:"Checklist Line Item ", isFilter:true,width:200},
    {field:"ATTACHMENTREQUIRED",title:"Attachment Required",isFilter:true,width:200},
    {field:"ansTypeObj.DESCRIPTION",title:"Task Answer",isFilter:true,width:200},
    {field:"ansList",title:"Answer List",isFilter:true,width:200},
  ]
  public actionsLayout = "normal";
  public tempTaskObj:any=null;
  public permission:Permission=new Permission();
  ngOnInit(): void {
    if(UserModuleObj.contractor){
      this.permission=UserModuleObj.contractor;
    }
    this.model.getData(ModelApi.ChekList,ModelApi.TaskAnswerType)
    .then((result)=>{
      this.getData();
    })
    .catch((err)=>{
    
    })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
     this.model.checklist.selectedObj=null;
      this.data=new MaintenaceChecklistTask();
      this.questionObj=new QuestionAnsList();
      this.questionList=new Array<QuestionAnsList>()
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
    else if(type==="answertype"){
      this.questionObj.ansType=data;
    }
  }
  valuechangeArray(type,data,index){
     if(type==="answertype"){
      this.questionList[index].ansType=data;
    }
  }
  saveData(type=null){
    if(this.checkValidation()){
      return ;
    }
    this.checklistData=new ChecklistTask();
    this.checklistData.task.DESCRIPTION=this.data.DESCRIPTION;
    this.checklistData.task.CHECKLISTID=this.model.checklist.selectedObj.id;
    this.checklistData.item=this.questionList.map((d:QuestionAnsList)=>{
      let d1=new Question();
      d1.taskItem.ATTACHMENTREQUIRED=d.requiredAttechment;
      d1.taskItem.ID=0;
      d1.taskItem.ANSWERTYPEID=d.ansType.ID;
      d1.taskItem.DESCRIPTION=d.item;
      d1.answerItem=d.ansList.map((d)=>{
        let t1=new TaskAnswerListItem();
        t1.DESCRIPTION=d;
        return t1;
      });
      return d1;
    })
   
    this.checklistData.saveData()
    .then((result)=>{
  
       this.reset();
          this.onDialogClose()
          this.getData()
          this._notif.show({
            content: "Successfully saved.",
            type: { style: "success", icon: true },
          });
    })
    .catch(err=>{
      this._notif.show({
        content: "Failed to save.",
        type: { style: "error", icon: true },
      });
    })
    // if(this.type==="new"){
    //    this.data.saveData(this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
    //    .then((result)=>{
    //         console.log("save",result)
    //         this.getData()
    //         this.opened=false;
    //    })
    //    .catch((err)=>{
    //       console.log("err",err)
    //    })
    // }
    // else if(this.type=="update"){
    //   this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
    //   .then((result)=>{
    //        console.log("save",result)
    //        this.getData()
    //        this.opened=false;
    //   })
    //   .catch((err)=>{
    //      console.log("err",err)
    //   })
    // }
  }
  uploadData(type=null){
    // if(this.checkValidation()){
    //   return ;
    // }
    this.checklistData=new ChecklistTask();
    this.checklistData.task.ID=this.data.ID;
    this.checklistData.task.DESCRIPTION=this.data.DESCRIPTION;
    this.checklistData.task.CHECKLISTID=this.model.checklist.selectedObj.id;
    this.checklistData.item=this.questionList.map((d:QuestionAnsList)=>{
      let d1=new Question();
      d1.taskItem.ATTACHMENTREQUIRED=d.requiredAttechment;
      d1.taskItem.ID=d.id;
      d1.taskItem.ANSWERTYPEID=d.ansType.ID;
      d1.taskItem.DESCRIPTION=d.item;
      d1.answer.ID=d.ansListId;
      d1.answer.NAME=d.item;
      let deleteAns=d.ansListObj.filter((ansL)=>{
        //[a-1,a-4]
        return d.ansList.filter((ansLI:any)=>ansL.DESCRIPTION == ansLI).length==0
      })
      deleteAns.forEach((d)=>{
        let del=new TaskAnswerListItem();
        //del.ID=d.ID;
        del.deleteData({ID:d.ID});
      })
      console.log("need to delete==>",deleteAns)
      d1.answerItem=d.ansList.map((ansL)=>{
        let t1=new TaskAnswerListItem();
        //t1.TASKANSWERLISTID=this.tempData?.TASKANSWERLISTID;
        t1.DESCRIPTION=ansL;
        let findAns=d.ansListObj.find((ans)=>ans.DESCRIPTION==ansL);
        t1.ID=findAns?.ID;
        return t1;
      });
      return d1;
    })


    console.log("update task",this.checklistData)
   
    this.checklistData.updateData()
    .then((result)=>{
       this.reset();
       this.onDialogClose()
       this.getData()
       this._notif.show({
        content: "Successfully saved.",
        type: { style: "success", icon: true },
      });
    })
    .catch(err=>{
      this._notif.show({
        content: "Failed to save.",
        type: { style: "error", icon: true },
      });
    })
    // if(this.type==="new"){
    //    this.data.saveData(this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
    //    .then((result)=>{
    //         console.log("save",result)
    //         this.getData()
    //         this.opened=false;
    //    })
    //    .catch((err)=>{
    //       console.log("err",err)
    //    })
    // }
    // else if(this.type=="update"){
    //   this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
    //   .then((result)=>{
    //        console.log("save",result)
    //        this.getData()
    //        this.opened=false;
    //   })
    //   .catch((err)=>{
    //      console.log("err",err)
    //   })
    // }
  }
  reset(){
    this.questionObj.ansList=[];
    this.questionObj.ansType=null;
    this.questionObj.mandatoryField=false;
    this.questionObj.mandatoryField=false;
    this.questionObj.item="";
    this.questionList=[];
    this.model.checklist.selectedObj=null;
    this.data.DESCRIPTION="";
  }

  getData(){
    this.model.getData(
      ModelApi.ChekList,
      ModelApi.MaintenaceTask,
      ModelApi.TaskAnswerList,
      ModelApi.TaskAnswerListItem,
      ModelApi.taskItem
      )
      .then((result)=>{
        console.log("model==>",this.model);
         let ansItemObj= this.model.taskAnsListItem.alldata.reduce((acc,d)=>{
              if(!acc[d.TASKANSWERLISTID]){
                acc[d.TASKANSWERLISTID]=[];
              }
              acc[d.TASKANSWERLISTID].push({DESCRIPTION:d.DESCRIPTION,ID:d.ID});
              return acc;
         },{})

            this.task=  this.model.taskItem.alldata.filter((d)=>{
            let taskObj=this.model.maintenaceTask.objectData[d.TASKID];
            return taskObj && taskObj.CHECKLISTID;
          }).map((d)=>{
              let taskObj=this.model.maintenaceTask.objectData[d.TASKID];
              d["checkListObj"]=this.model.checklist.objectData[taskObj.CHECKLISTID];
              d["taskObj"]=taskObj;
              d["ansTypeObj"]=this.model.taskAndType.objectData[d.ANSWERTYPEID];
              if(ansItemObj[d.TASKANSWERLISTID] && ansItemObj[d.TASKANSWERLISTID].length>0){
                d["ansList"]=ansItemObj[d.TASKANSWERLISTID].map((d)=>d.DESCRIPTION).join(",");
                d["ansListObj"]=ansItemObj[d.TASKANSWERLISTID]
              }
              else{
                d["ansList"]="";
                d["ansListObj"]=[]
              }
              return d;
          }) 
         // console.log(dataList) 
      })
      .catch((err)=>{
         console.log(err);
      })
    // this.model.getData(ModelApi.MaintenaceTask)
    // .then((result)=>{
    //   this.task=this.model.maintenaceTask.alldata.map((d)=>{
    //     d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
    //     return d;
    //    })
    // })
  }
  editColumn(d){
    this.dailogTitle="Update Maintenance Task"
    this.model.checklist.selectedObj=d.dataItem["checkListObj"]
    this.tempTaskObj=JSON.parse(JSON.stringify(d.dataItem))
    console.log(d.dataItem)
     this.data.DESCRIPTION=d.dataItem?.taskObj?.DESCRIPTION;
     this.data.ID=d.dataItem?.taskObj?.ID;
     let obj=new QuestionAnsList();
     obj.id=d.dataItem.ID;
     obj.item=d.dataItem?.DESCRIPTION;
     obj.ansType=d.dataItem?.ansTypeObj;
     obj.requiredAttechment=d.dataItem?.ATTACHMENTREQUIRED;
     obj.ansList=d.dataItem?.ansListObj.map((d)=>d.DESCRIPTION);
     obj.ansListObj=d.dataItem?.ansListObj || []
     obj.ansListId=d.dataItem?.TASKANSWERLISTID || 0;
    this.questionList.push(obj);
     //this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }
 addDeleteAnsList(e){
   console.log(e)
   this.questionObj.ansList=e;
 }
 addItem(){
   this.error=new QuestionAnsListError();
   let e=false;
   if(!this.questionObj.item){
     e=true;
     this.error.item=true
   }
   if(!this.questionObj.ansType){
     e=true;
     this.error.ansType=true;
   }
   if(this.questionObj.ansType.ID==5 && !( this.questionObj.ansList && this.questionObj.ansList.length>0)){
     e=true;
     this.error.ansList=true;
   }
   if(e)
      return e;
   if(this.questionObj.ansType && this.questionObj.ansType.ID!==5){
     this.questionObj.ansList=[]
   }
   this.questionList.unshift(this.questionObj);
   this.questionObj=new QuestionAnsList();
 }
 removeItem(index){
   let deltaskItem=new TaskItem();
   let obj=this.questionList[index];
   deltaskItem.ID=obj.id;
   if(obj.id){
       deltaskItem.deleteData({ID:obj.id})
       this.task=this.task.filter((d)=>d.ID!=obj.id)
   }
   this.questionList=this.questionList.filter((d,i)=>i!==index)
 }
 checkValidation(){
   this.error=new QuestionAnsListError()
   let e=false;
   if(!this.data.DESCRIPTION){
       e=true;
       this.error.DESCRIPTION=true;
   }
   if(!(this.model.checklist.selectedObj && this.model.checklist.selectedObj.id)){
     e=true
     this.error.checkList=true;
   }
   if(this.questionList.length<=0){
      e=true;
      this.error.itemLength=true
   }
   return e;
 }
 public importopened=false;

 downloadTemplate(){
  window.open("/assets/template/Maintenance_Task.xlsx")
}
importChange(e){

   let obj=addBlukCheklistTask(e,null);
   console.log("bulk task==>",obj,e);
   let checklistList=[];
   for(let checklistNo in obj){
      for(let sectionTitle in obj[checklistNo]){
         checklistList.push(this.addImportData(checklistNo,sectionTitle,obj[checklistNo][sectionTitle]));
      }
   }
   console.log(checklistList);
   Promise.all(checklistList.map((c:ChecklistTask)=>{
    return c.saveData();
   }))
   .then((result)=>{
    this.getData();
    this.importopened=false;
    this._notif.show({
      content: "Successfully saved.",
      type: { style: "success", icon: true },
    });
   })
   .catch((err)=>{
    this._notif.show({
      content: "Failed to save.",
      type: { style: "error", icon: true },
    });
   })
 }
 addImportData(checklistNo,secttionTitle,questionList){
  let checklistData=new ChecklistTask();
  checklistData.task.DESCRIPTION=secttionTitle;
  checklistData.task.CHECKLISTID=this.model.checklist.objectByChecklistNo[checklistNo];   
  checklistData.item=questionList.map((d)=>{
    let d1=new Question();
    d1.taskItem.ATTACHMENTREQUIRED=d.requiredAttechment;
    d1.taskItem.ID=0;
    d1.taskItem.ANSWERTYPEID=this.model.taskAndType.objectByDes[d.ansType];
    d1.taskItem.DESCRIPTION=d.question;
    d1.answerItem=d.ansList.map((d)=>{
      let t1=new TaskAnswerListItem();
      t1.DESCRIPTION=d;
      return t1;
    });
    return d1;
  })

  return checklistData;
   
    // console.log(this.checklistData);

  // this.checklistData.saveData()
  // .then((result)=>{

  //    this.reset();
  //       this.onDialogClose()
  //       this.getData()
        // this._notif.show({
        //   content: "Successfully saved.",
        //   type: { style: "success", icon: true },
        // });
  // })
  // .catch(err=>{
    // this._notif.show({
    //   content: "Failed to save.",
    //   type: { style: "error", icon: true },
    // });
  // })
  // if(this.type==="new"){
  //    this.data.saveData(this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
  //    .then((result)=>{
  //         console.log("save",result)
  //         this.getData()
  //         this.opened=false;
  //    })
  //    .catch((err)=>{
  //       console.log("err",err)
  //    })
  // }
  // else if(this.type=="update"){
  //   this.data.updateData(this.data.ID,this.model.checklist.selectedObj.id,this.data.DESCRIPTION)
  //   .then((result)=>{
  //        console.log("save",result)
  //        this.getData()
  //        this.opened=false;
  //   })
  //   .catch((err)=>{
  //      console.log("err",err)
  //   })
  // }
}
}
