<div class="card m-3">
    <div class="card-header" >
        <h5>Daily Inspection Location Planner</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Contractor And Vendor
          </button> -->
        </div>
    </div>
    <div class="card-body">
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Year<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control" >
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Daily Inspection ID</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                       
                    </div>
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Service Type<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Checklist Name<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                       
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Checklist ID</label>
                            <input kendoTextBox  class="form-control" disabled/>
                            
                        </div>
                        <div class="form-group col-5">
                            <label>Group by<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                     
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Facility<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi"  class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Block Code<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi"  [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                   
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Block Name</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Level Code<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi"  [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                      
              
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Level Name</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Area Code<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi"  [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                       
               
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Area Name</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Location Code<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi"  [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                      
                    
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Location Name</label>
                            <input kendoTextBox  class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Assignee<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                      
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Assign To<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                       
                        <div class="form-group col-5">
                            <label>Schedule Type<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                   
                  
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                           
                            <label>Status<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" class="form-control">
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Month<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="asset_classi" [filterable]="true" class="form-control">
                            </kendo-dropdownlist>
                            
                        </div>
                       
                       
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Date</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                        </div>
                        <div class="form-group col-5">
                            <label>Week</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                        </div>
                       
                       
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Day</label>
                            <kendo-multiselect
                            [data]="asset_classi"
                           
                          ></kendo-multiselect>
                        </div>
                        <div class="form-group col-5">
                          
                        </div>
                     
                    </div>
                    <div class="text-center mt-3 mb-4">
                        <button kendoButton primary="true" class="mr-2">Save</button>
                        <button kendoButton primary="true" class="mr-2">Reset</button>
                        <button kendoButton primary="true" class="mr-2">Delete</button>
                        <button kendoButton primary="true" class="mr-2">Import New</button>
                        <button kendoButton primary="true" class="mr-2">Import Update</button>
                        <button kendoButton primary="true" class="mr-2">Download Template</button>
                       
                    </div>
                         
    </div>
</div>


