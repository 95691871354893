import { UserRegisterType, UserRegisterTypeError } from "src/app/models/user.model";

function validateEmail(email)
{
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}
export let userRegisterValidation = (data: UserRegisterType, type) => {
    let error = new UserRegisterTypeError(false)
    if (type === "init") {
        return error;
    }
    if (!data.staffName) {
        error.staffName = true
    }
    if (!data.companyEmail && !validateEmail(data.companyEmail)) {
        error.companyEmail = true
    }
    if (!data.companyName) {
        error.companyName = true
    }
    if (!data.locationID) {
        error.locationID = true
    }
    if (!data. categoryId) {
        error. categoryId = true
    }
    if (!data.userName) {
        error.userName = true
    }
    if (!data.mobileNo) {
        error.mobileNo = true
    }
    if (!data.competencies) {
        error.competencies= true
    }
    if (!data.facilities) {
        error.facilities= true
    }
    if (!data.userRoles) {
        error.userRoles= true
    }
    return error;
}