
<!-- 
Role Screen Permission Mapping -->

<div class="card m-3">
    <div class="card-header" style="display: flex;justify-content: space-between;">
      <h5>Role Screen Permission Mapping </h5>
      <div>
        <button kendoButton primary="true" class="header-right-btn">
          Add Role
        </button>
      </div>
    </div>
    <div class="card-body">
        <app-roles-permission-table-grid [data]="gridData" [column]="permissionColumn1" [size]="5"></app-roles-permission-table-grid>
        
      
      <!-- <kendo-grid [data]="gridData">
          <kendo-grid-column field="rolename" title="Role Name">
          </kendo-grid-column>
          <kendo-grid-column field="usertype" title="User Type">
          </kendo-grid-column>
          <kendo-grid-column field="competency"  title="Competency">
          </kendo-grid-column>
          <kendo-grid-column field="status" title="Status">
          </kendo-grid-column>
        </kendo-grid> -->
    </div>
  </div>
  

<!-- <div class="card m-3">
  <h5 class="card-header"></h5>
  <div class="card-header" style="display: flex;justify-content: space-between;">
    <h5>User Role </h5>
    <div>
      <button kendoButton primary="true"  class="header-right-btn">
        Add Role
      </button>
    </div>
  <div class="card-body">
      
    <app-table-grid [data]="gridData" [column]="column" [size]="5"></app-table-grid> -->

          <!-- <kendo-grid
          [columnMenu]="{ filter: true }"
          [kendoGridBinding]="gridView"
          kendoGridSelectBy="id"
          [selectedKeys]="mySelection"
          [pageSize]="6"
          [pageable]="true"
          [sortable]="true"
         
          >
          

                  <kendo-grid-column
                      field="fullName"
                      [title]="customMsgService.translate('contactName1')"
                      [width]="220"
                  >
                      <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="customer-name"> {{ dataItem.fullName }} </div>
                      </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="Assign Permissions" width="350" filter="boolean">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [checked]="dataItem.Discontinued"/> Add
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Delete
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Modification
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> Print
                        <input type="checkbox" [checked]="dataItem.Discontinued" style="margin-left: 8px;"/> All
                    </ng-template>
                    
                </kendo-grid-column>
           
            
          
                  <kendo-grid-column field="phone1" [title]="customMsgService.translate('phone1')" [width]="130"> </kendo-grid-column>
                  <kendo-grid-column field="address1" [title]="customMsgService.translate('address1')" [width]="200"> </kendo-grid-column>
      
           
          </kendo-grid> -->
    
      <!-- <div class="text-center" style="margin-top: 20px;">
        <button kendoButton primary="true" class="mr-2">Add Roles</button>
        <button kendoButton primary="true" class="mr-2">Save</button>
        <button kendoButton primary="true" class="mr-2">Save and Add new</button>
        <button kendoButton type="reset" >Reset</button>
    </div> -->
  <!-- </div>
</div>
<div class="card m-3">
    <div class="card-header" style="display: flex;justify-content: space-between;">
      <h5>User Role </h5>
      <div>
        <button kendoButton primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
          Add Role
        </button>
      </div>
    </div>
    <div class="card-body">
      <app-table-grid [data]="gridData" [column]="column" [size]="5"></app-table-grid> -->
      <!-- <kendo-grid [data]="gridData">
          <kendo-grid-column field="rolename" title="Role Name">
          </kendo-grid-column>
          <kendo-grid-column field="usertype" title="User Type">
          </kendo-grid-column>
          <kendo-grid-column field="competency"  title="Competency">
          </kendo-grid-column>
          <kendo-grid-column field="status" title="Status">
          </kendo-grid-column>
        </kendo-grid> -->
    <!-- </div>
  </div> -->