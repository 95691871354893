import { Component, Input, OnInit,OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { process, State } from "@progress/kendo-data-query";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";


@Component({
  selector: 'app-contact-person-grid',
  templateUrl: './contact-person-grid.component.html',
  styleUrls: ['./contact-person-grid.component.scss']
})
export class ContactPersonGridComponent implements OnInit {


  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() action:Boolean=true;
  @Input() deleteFlag:Boolean=true;
  @Output() valueChange = new EventEmitter<any>();
  @Output() error = new EventEmitter<boolean>();

  public originalGridData=[];
  // public gridData1=[];
  public gridData=[];
  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
       this.data=changes.data.currentValue;
       this.initGridData(JSON.parse(JSON.stringify(changes.data.currentValue)))
       // this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.action){
      this.action=changes.action.currentValue
    }
    if(changes.deleteFlag){
      this.deleteFlag=changes.deleteFlag.currentValue
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
   // this.gridData = process(this.data, this.state);
  }
  initGridData(data){
    let isNew=data.filter((d)=>d.action=="new").length==0;
    if(isNew)
    data.unshift({
      id:0,
      name:"",
      designation:"",
      contactNo:"",
      action:"new",
      isDeleted:false
    })

    data=data.map((d,i)=>{
      if(!d.action)
         d["action"]="edit";
      d["index"]=i;
      return d;
    })
    this.gridData=data.filter((d)=>!d.isDeleted)
  }
  
  ngOnInit(): void {
   // this.initGridData(this.data)
  }
  executeAction(type,index,dataItem){
    if(type=="new"){
      let validateText=this.checkedContectError()
      if(!validateText){
        this.gridData=this.gridData.map((d)=>{
              d["action"]="edit"
              return d
        })
        this.valueChange.emit(this.gridData)
    }
   }
   if(type=="edit-save" && dataItem.action!=='new'){
      this.checkedContectError("new")
      this.valueChange.emit(this.gridData)
   }
   if(type=="delete"){
     this.gridData=this.gridData.filter((d,i)=>i!=index)
     this.checkedContectError("new")
     this.valueChange.emit(this.gridData)
   }
   
  } 
  
  checkedContectError(type=""){
    let error=false;
    this.gridData=this.gridData.map((d)=>{
      if(d.action!=type){
             this.column.forEach((c)=>{
                  let errormsg=c.validation(d[c.field])
                  if(errormsg){
                    error=true
                    d[c.errorField]=errormsg
                  }
                  else{
                    d[c.errorField]=errormsg
                  }
             })
            }
        return d;
    })
    this.error.emit(error);
    return error;
  }
  // editHandler(e){
  //   dispatchEvent(EVENT_NAME.edit_column,e);
  // }
  // removeHandler(e){
  //   dispatchEvent(EVENT_NAME.remove_column,e);
  // }

}
