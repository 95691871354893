<!-- main app container -->
<div class="card m-3"   >
	<!-- <h5 class="card-header">User Registration</h5> -->
	<div class="card-header" >
        <h5>User Registration</h5> 
        <div> 
			<button kendoButton  primary="true"  (click)="downloadT()"  class="header-right-btn">
				Download Template
				   </button> &nbsp;  &nbsp;
			<button kendoButton  primary="true" *ngIf="permission && permission.isImport" (click)=" import()" class="header-right-btn">
			Import
			   </button> &nbsp;  &nbsp;
			<button kendoButton  primary="true"  *ngIf="permission && permission.isExport" (click)="download()" class="header-right-btn">
			Export
		   </button>  &nbsp;  &nbsp;  &nbsp; 
          <button kendoButton  primary="true" *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
			<span class="add_btn">+</span> User
          </button>
        </div>
    </div>
	<div class="card-body register-form-body"  >
			<app-table-grid
			[data]="gridData"
			[column]="column2"
			[size]="10"
			[editFlag]="permission && permission.isUpdate"
			[deleteFlag]="false"
           deleteKey="deleteKey"
		  ></app-table-grid>
	</div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div>
		<div class="form-row">
			<div class="form-group col-5">
				<label>Staff Name<span class="required">*</span></label>
				<div style="width:100%">
				<input kendoTextBox [(ngModel)]="userRegisterForm.staffName" type="text" class="form-control"  />
				<kendo-formerror *ngIf="error.staffName">*Required</kendo-formerror>
			</div>
			</div>
			<div class="form-group col-5">
				<label>Username (email)</label>
				<div style="width:100%">
				<input kendoTextBox [(ngModel)]="userRegisterForm.userName" disabled class="form-control"  (blur)=" validateEmail()" />
				<kendo-formerror *ngIf=!emailError>Invalid Email</kendo-formerror>
			
			</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-5">
				<label>Gender</label>
		<kendo-dropdownlist [(ngModel)]="userRegisterForm.genderId"  [data]="gendersDropDown" class="form-control" [textField]="'NAME'"
				[valueField]="'ID'"
				[valuePrimitive]="true"></kendo-dropdownlist>
		
			</div>
			<div class="form-group col-5">
				<label>Office No.</label>
				<input kendoTextBox  [(ngModel)]="userRegisterForm.officeNo"  class="form-control"   maxlength="11"/>
				
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-5">
				<label>Company Email<span class="required">*</span></label>
				<div style="width:100%">
				<input kendoTextBox [(ngModel)]="userRegisterForm.companyEmail"  class="form-control"  (blur)=" validateCompanyEmail()" (input)="onSearchChange($event.target.value)"/>
				<kendo-formerror *ngIf="error.companyEmail">*Required</kendo-formerror>
				<kendo-formerror *ngIf=!companyEmailError>* Invalid Email ID</kendo-formerror>
			</div>
			</div>
			<div class="form-group col-5">
				<label>Mobile No.<span class="required">*</span> </label>
				<div style="width:100%">
				<input kendoTextBox [(ngModel)]="userRegisterForm.mobileNo"  class="form-control"   maxlength="11" />
				<kendo-formerror *ngIf="error.mobileNo">*Required</kendo-formerror>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-5">
				<label>Login User Type<span class="required">*</span></label>
				<div style="width:100%">

				<kendo-dropdownlist [data]="loginUserTypeDropDown" [(ngModel)]="userRegisterForm.categoryId"    class="form-control" [textField]="'NAME'"
				[valueField]="'ID'"
				[valuePrimitive]="true" (valueChange)="loginUserChange($event)"></kendo-dropdownlist>
				<kendo-formerror *ngIf="error.categoryId">*Required</kendo-formerror>
			</div>
				<!-- <label>Date of Joining</label>
				<kendo-datepicker [(value)]="value"></kendo-datepicker> -->
			</div>
			<div class="form-group col-5">
				<label>Status</label>
				<kendo-dropdownlist [data]="statusDropDown" [(ngModel)]="userRegisterForm.statusId" class="form-control" [textField]="'NAME'"
				[valueField]="'ID'"
				[valuePrimitive]="true"></kendo-dropdownlist>
			</div>
		</div>
		<div class="form-row ">
			<div class="form-group col-5">
				<label>Designation</label>
				<kendo-dropdownlist [(ngModel)]="userRegisterForm.designationId" [data]="designationDropDown" class="form-control" [textField]="'NAME'"
				[valueField]="'ID'"
				[valuePrimitive]="true" ></kendo-dropdownlist>
				
			</div>
			<div class="form-group col-5">
				<label>Nationality</label>
				<kendo-dropdownlist  [(ngModel)]="userRegisterForm.nationalityId" [data]="nationalityDropDown" class="form-control"  [textField]="'NATIONALITY'"
				[valueField]="'ID'"
				[valuePrimitive]="true"></kendo-dropdownlist>
				
			</div>
		</div>
		<div class="form-row ">
			<div class="form-group col-5">
				<label>Contractor</label>
				<div style="width:100%">
				<kendo-dropdownlist  [(ngModel)]="userRegisterForm.contractorId" [data]="contractorDropDown" class="form-control" [textField]="'name'" [valueField]="'id'"
				[valuePrimitive]="true" [disabled]="contrDisabled"></kendo-dropdownlist>
				
				</div>
			</div>
			<div class="form-group col-5">
				<label>Grade</label>
				<kendo-dropdownlist  [(ngModel)]="userRegisterForm.grade" [data]="gradeDropDown" class="form-control" [textField]="'name'" [valueField]="'name'"
				[valuePrimitive]="true" ></kendo-dropdownlist>
				<!-- <input kendoTextBox [(ngModel)]="userRegisterForm.grade"  class="form-control"   /> -->
				</div>
		</div>
		<div class="form-row">
		
			<div class="form-group col-5">
				<label>Competency<span class="required">*</span></label>
				<div style="width:100%">
				<kendo-multiselect
				[data]=" compentecnyDropDown"
				textField="name" 
				valueField="id"
				[value]="selectedCompentenciesFlag"
               (valueChange)="selectedCompentenciesFlag=$event"
			   ></kendo-multiselect >
			   <kendo-formerror *ngIf="comErr">*Required</kendo-formerror>
			</div>
			</div>
			<div class="form-group col-5">
				<label>Speciality</label>
				<kendo-multiselect
			 	 [data]="specialityDropDown"
				 textField="name"
				 valueField="id"
				 [value]="selectedSpecialityFlag"
				 (valueChange)="selectedSpecialityFlag=$event"
			   ></kendo-multiselect >
				<!-- <kendo-dropdownlist formControlName="Speciality"  [data]="Specialitys" class="form-control" ></kendo-dropdownlist> -->
			</div>
		</div>
		<div class="form-row">
			
			<div class="form-group col-5">
				<label>Services</label>
				<div style="width:100%">
				<kendo-multiselect textField="name"
				[data]="serviceDropDown "
				valueField="id"
				[value]="selectedServiceFlag"
				(valueChange)="selectedServiceFlag=$event"
			   ></kendo-multiselect>
			   <kendo-formerror *ngIf="serviceTypeErr">*Required</kendo-formerror>
			</div>
			</div>
			<div class="form-group col-5">
				<label>Labour Cost Per Hour</label>
				<input type="number" kendoTextBox [(ngModel)]="userRegisterForm.labourCostPerHour" class="form-control"  />
			</div>
		</div>
		<div class="form-row">
		
			<div class="form-group col-5">
				<label>Location<span class="required">*</span></label>
				<div style="width:100%">
				<kendo-dropdownlist [(ngModel)]="userRegisterForm.locationID"   [data]="locationCodeDropDown"  [textField]="'name'"
				[valueField]="'id'"
				[valuePrimitive]="true" class="form-control"></kendo-dropdownlist>	
				<kendo-formerror *ngIf="error.locationID">*Required</kendo-formerror>
				</div>			
			</div>
			<div class="form-group col-5">
				<label>Company Name<span class="required">*</span></label>
				<div style="width:100%">
					<kendo-dropdownlist  [(ngModel)]="userRegisterForm.companyName" [data]="companyDropDown" class="form-control" [textField]="'name'" [valueField]="'name'"
				[valuePrimitive]="true" [disabled]="companyDisabled"></kendo-dropdownlist>
				<kendo-formerror *ngIf="companyErr">*Required</kendo-formerror>
			</div>
			</div>
		</div>
		<div class="form-row">
			
			<div class="form-group col-5">
			</div>
		</div>
		<div class="mt-4 mb-4">
			<h2 class="font-weight-bold pl-5">Facility details</h2>
		</div>
		<div class="form-row">
		
				<div class="form-group col-5">
					<label>Customer Name</label>
					<input kendoTextBox [(ngModel)]="userRegisterForm.customerName"  disable class="form-control"   />
				</div>
		<div class="form-group col-5">

		</div>
	</div>
		<div class="form-row">
			<div class="form-group col-5">
				<label>Facility<span class="required">*</span></label>
				<div style="width:100%">
				<kendo-multiselect
				[data]="facilityCodeDropDown"
				textField="name" 
				valueField="id"
				[value]="selectedFacilityFlag"
               (valueChange)="selectedFacilityFlag=$event"
			   
			  ></kendo-multiselect> 
			  <kendo-formerror *ngIf="facErr">*Required</kendo-formerror>
			</div>
			<!-- (valueChange)="specializationDetailFlag=$event" -->
			<!-- [value]="specializationDetailFlag" -->
			</div>
			<div class="form-group col-5">
				<label>User Role<span class="required">*</span></label>
				<div style="width:100%">
				<kendo-multiselect
				[data]="roleDropDown"
				textField="name" 
				valueField="id"
				[value]="selectedUserRoleFlag"
				(valueChange)="selectedUserRoleFlag=$event"
			
			  ></kendo-multiselect>
			   <kendo-formerror *ngIf="userRoleErr">*Required</kendo-formerror>
			</div>
			 
				<!-- <input kendoTextBox formControlName="User_Role" class="form-control" /> -->
			</div>
		</div>
	
		<!-- <div class="form-row">
			<div class="form-group col-5">
				<label>All Facilities</label>
				<input kendoTextBox class="form-control" />
			</div>
			<div class="form-group col-5">
				<label>Selected Facilities</label>
				<input kendoTextBox  class="form-control"/>
			</div>
		</div> -->
		<!-- <div class="form-row">
			<div class="form-group col-5">
				<label>Select User Role</label>
				<input kendoTextBox class="form-control"  />
			</div>
			<div class="form-group col-5"></div>
		</div> -->
	</div>
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
      <button kendoButton  *ngIf="type=='new'"  primary="true" (click)="onReset()">
                Reset
      </button>
      <button  *ngIf="type=='new'"  kendoButton primary="true" (click)="onSubmit()">
            Save
      </button>
	  <button *ngIf="type=='update'" kendoButton primary="true" (click)="onSubmit()">
		Update
  </button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeData()" >Yes</button>
      <button kendoButton  (click)="onDialogClose('remove')">No</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="false && importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [url]="urlimg"
				   [fileUploadType]=1
				   (valueChange)=valueChange($event)
                   style="width: 100%;"
				   
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=5
                style="width: 100%;"
                (valueChange)=valueChange($event)
                >
           </app-file-model>
            </div>
            </div>
          <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="importSave()">
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>