import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { BPMAssets, ContractorManagementOfFacility, ContractorManagementOfFacilityError } from 'src/app/models/cmf';
import { environment } from 'src/environments/environment';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st} from 'src/Util/Util';
import { UserModuleObj,Permission } from "src/ApiModel/Model/UserModule";
import { addBlukSparePartUpdate } from "src/ApiModel/ImportApi/sparePart"
import { addBlukContractManagement } from 'src/ApiModel/ImportApi/contract';
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"

@Component({
  selector: 'app-contract-management-for-facility-user',
  templateUrl: './contract-management-for-facility-user.component.html',
  styleUrls: ['./contract-management-for-facility-user.component.scss']
})
export class ContractManagementForFacilityUserComponent implements OnInit {
  model: any = {};
  public opened = false;
  public Renewopened = false;
  public removeDialogOpen = false;
  public viewAssetDialogOpen = false;
  public urlimg
  public viewDialogOpen = false;
  public contactPersonValue =[];
  public assetNumber
  public permission:Permission=new Permission();
  public type = "";
  public faxNo;
  public contractPhoneno;
  public contractmail;
  public actionsLayout = "normal";
  public importopened =false;
  public assetDes;
  public dailogTitle = "";
  public assetId;
  public removeAss_Id_Data;
  public MODULEHIEARCHYID
  public statusObj = {};
  public locObj ={};
  public bpmObj ={}
  public renewableStartDate;
  public renewableEndDate;
  public service_Obj = {};
  public locationCodeDropDown = [];
  public compent_Obj = {};
  public contTypeObj={}
  public statusDropDown = []
  public contractCodeDropDown =[]
  public assetNoDropDown =[]
  public designationObj = {}
  public contractorTypeDropDown =[]
  public historyData =[]
  public contractAssetData =[]
  public contractorChangedData = []
  public designationDropDown = [];
  public facilityData =[]
  public contractorName;
  public conratorUserData =[]
  public contectGrid=[]
  public gridAssetDetData =[]
  public attachmentOpened = false;
  public contractorAsstes: BPMAssets = new  BPMAssets();
  public contractorManFacilityForm : ContractorManagementOfFacility = new ContractorManagementOfFacility();
  public error:ContractorManagementOfFacilityError=new ContractorManagementOfFacilityError()
  public isNew=true;
  public isRenewal=false;
  moduleId: any;
  public onTabSelect(e) {
    console.log(e);
  }
  public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
  public attechmentDoc=new AttechmentObj()
  public attechForm:Attechment=new Attechment();
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public attechId:number=-1;
  public employeId=parseInt(getCookie("userId")||"1")
  public assetDetailsCol=[
    {
      field: "assetNo",
      title: "Asset No.",
      isFilter: true,
      width: 150,
    },
    {
      field: "assetCode1",
      title: "Asset Type Code Description",
      isFilter: true,
      width: 150,
    },
    {
      field: "contractValue",
      title: "Contract Value (RM)",
      isFilter: true,
      width: 150,
    },
 ]

  public contectColumn=[
    { 
      field: "assetId", 
      title: "Asset No.",
      type:"dropdown",
      errorField:"name_error",
      validation:(val)=>{
          if(val)
            return ""
        
      }, 
    isFilter: false, 
    width: 140 
  },
    { 
        field: "assetDes", 
        title: "Asset Type Code Description", 
        isFilter: false, 
        type:"disabledData",
        width: 140,
        errorField:"designation_error",
        validation:(val)=>{
          if(val)
            return ""
         }, 
      },
    { 
      field: "contractValue", 
      title: "Contract Value (RM)", 
      type:"input",
      isFilter: false, 
      width: 140,
      errorField:"contract_error",
      validation: (val) => {
        if (val)
          return ""
        else
          return "*Required"
      },
    },
 
  ]

  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},
]
public column2 = [
  { field: "FILETYPE", title: "File Type", isFilter: true },
  { field: "FILENAME", title: "File Name", isFilter: true },
];

  constructor(private _notif:NotificationService) {
    eventListner(EVENT_NAME.view_column, (e) => {
      this.viewColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.removeColumn(e);
    });
    eventListner(EVENT_NAME.renew_column, (e) => {
      this.renewData(e);
    });
    eventListner(EVENT_NAME.attachment_column, (e) => {
      this.attachementColumn(e);
    });
    eventListner(EVENT_NAME.view_asset_column, (e) => {
      this.ViewAssetColumn(e);
    });
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
      this.deleteAttechment(e);
   })
   eventListner(EVENT_NAME.download_attechment,(e)=>{
     this.downloadAttechment(e)
   })
   eventListner(EVENT_NAME.edit_column,(e)=>{
         this.editColumn(e)
   })
   }

   editColumn(data){
      console.log(data)
      this.dailogTitle="Edit Contract Management"
      let dataItem=data.dataItem;
      this.isNew=false;
      this.isRenewal=false;
      this.type="update";
      let endDate=dataItem.contractEndDate && new Date(dataItem.contractEndDate)
      if(endDate){
        let dd=new Date();
        dd.setDate(dd.getDate()+100);
        if(endDate.getTime()<=dd.getTime()){
          this.isRenewal=true;
        }
      }

      this.contractorManFacilityForm.ID=dataItem.bpmContractID;
      this.contractorManFacilityForm.BPMID=dataItem.bpmid;
      this.contractorManFacilityForm.CONTRACTNO=dataItem.contractNo;
      this.contractorManFacilityForm.CONTRACTSTARTDATE= dataItem.contractStartDate && new Date(dataItem.contractStartDate);
      this.contractorManFacilityForm.CONTRACTENDDATE=dataItem.contractEndDate && new Date(dataItem.contractEndDate);
      this.contractorManFacilityForm.CONTRACTSUM=dataItem.contractSum;
      this.contractorManFacilityForm.STATUSID=dataItem.statusID;
      this.contractorManFacilityForm.CONTRACTTYPEID=dataItem.contractTypeId;
      this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL=dataItem.notificationForRenewal && new Date(dataItem.notificationForRenewal);
      this.contractorManFacilityForm.SCOPEOFWORKY=dataItem.scopeOfWork;
      this.contractorManFacilityForm.CREATEDBY=parseInt(getCookie(userid)),
      this.contractorManFacilityForm.CREATEDDATE=new Date(),
      this.contractorManFacilityForm.MODIFIEDBY=parseInt(getCookie(userid)),
      this.contractorManFacilityForm.MODIFIEDDATE=new Date(),
      this.contractorManFacilityForm.REMARKS=dataItem.remarks;

      //this.contractorManFacilityForm

      this.selectContractor(dataItem.bpmid)
      this.opened=true;

   }
   attachementColumn(data: any) {
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.REFERENCEID=data.bpmContractID;
  
  
  this.attechError={URL:false,FILENAME:false}
  
  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  this.attechId=data.bpmContractID;
  this.attechmentDoc=new AttechmentObj();
  this.attechmentDoc.getData(data.bpmContractID,this.moduleId)
  .then((result:any)=>{
    this.attachmentOpened = true;
    this.dailogTitle = "Add Attachment"
  //this.attachmentOpened = true;
  //this.dailogTitle = "Add Attachment"
  })
  .catch((err)=>{
   this.attechmentDoc.alldata=[]
  })
  }

  deleteAttechment(e) {
    this.attechForm.deleteData(e.dataItem)
    .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
    })
    .catch((err)=>{
     
    })
  }

  downloadAttechment(data) {
    window.open(environment.backendUrl +data.URL)
  }
  ngOnInit(): void {
    if(UserModuleObj.contract_management_for_facility_user){
      this.permission=UserModuleObj.contract_management_for_facility_user;
    }
    Promise.all([
      this.getContractorTypedata("mm","contractType"),
      this.getStatus("dbo","status"),
      this.getDesignationData("dbo", "Designation"),
      this.getCompentecnyData("dbo", "skill"),
      this.getServiceData("dbo", "servicesType"),
      this.getBlockData("dbo", "block"),
      this.getBpm(),
      this.getAssetRegistrationData(),
      this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
      
    ]).then((e)=>{
      this.getBPMData()
      this.getBpmContractAsset("dbo","BPMContractAsset")
      this.getAllUser()
    })
    }

   onDialogClose(type){
    if(type=="asset"){
      this.opened = false
    }
    if(type=="importUpdate"){
        this.importopened = false
    }
    if(type=="view"){
      this.viewDialogOpen = false
  }
    if(type=="remove"){
    this.removeDialogOpen = false
}
if(type=="renew"){
  this.Renewopened = false
}
if(type=="viewAsset"){
  this.viewAssetDialogOpen = false
}
  }

  onReset(){
    this.openRoleDailog("new")
  }

  openRoleDailog(type){
    this.type=type
    if(type==="new"){
      this.dailogTitle="Add Contract Management"
      this.isNew=true
      this.opened = true;
      this.contractorManFacilityForm = new  ContractorManagementOfFacility ()
      this.contractorName= ""
      this.contractorManFacilityForm.STATUSID = 1
      this.faxNo=""
      this.contectGrid=[]
      console.log("constract",this.historyData); 
    }
   
  }

 public  facilityCol =[
    {field:"staffName",title:"Staff Name",isFilter:true ,width:200},
    {field:"userName",title:"Username",isFilter:true ,width:200},
    {field:"companyEmail",title:"Company Email",isFilter:true ,width:200},
    {field:"mobileNo",title:"Mobile No.",isFilter:true ,width:200},
    {field:"designation",title:"Designation",isFilter:true ,width:200},
    {field:"compentencyName",title:"Competency",isFilter:true ,width:200},
    {field:"serviceName",title:"Services",isFilter:true ,width:200},
    {field:"location",title:"Location",isFilter:true ,width:200},
  ]

  public  historyCol =[
    {field:"contractorCode",title:"Contractor Code",isFilter:true ,width:200},
    {field:"contractorName",title:"Contractor Name",isFilter:true ,width:200},
    {field:"contractNo",   title:"Contract No.",isFilter:true ,width:200},
    {field:"contractSum",title:"Contract Sum",isFilter:true ,width:200},
    {field:"contractStartDate",title:"Contract Start Date",isFilter:true ,width:200},
    {field:"contractEndDate",title:"Contract End Date",isFilter:true ,width:200},
    {field:"scopeOfWork",title:"Scope Of Work",isFilter:true ,width:200},
    {field:"newRenDate",title:"Notification for Renewal",isFilter:true ,width:200},
    {field:"contractTypeName",title:"Contract Type",isFilter:true ,width:200},
    // {field:"assetCode",title:"Asset Code",isFilter:true ,width:200},
    // {field:"locationCode",title:"Location Code",isFilter:true ,width:200},
    // {field:"locationName",title:"locationName",isFilter:true ,width:200},
    {field:"remarks",title:"Remark",isFilter:true ,width:200},
  ]


  
  getBpm() {
   new Promise((resolve,reject)=>{
    return HttpClient.get("/api/BPM/GetBpms", true, true)
    .then((result: any) => {
      this.contractCodeDropDown = result;
      this.contractorChangedData = result
       resolve(result)
    }).catch((e)=>{
      reject(e)
    })
   })
  }

  selectContractor(id){
 let value =   this.contractorChangedData.filter((e)=>{
      return e.id===id
    })
    this.contractorName = value[0].name;
    this.faxNo = value[0].faxNo
    this.contactPersonValue = value[0].bpmregisterContactPersons
    console.log(value[0].bpmregisterContactPersons)
  }

  getContractorTypedata(schema, tablename){
    return new Promise((resolve, reject) => {HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
    this.contractorTypeDropDown  = JSON.parse(result.gridData);
       resolve(result);
    }).catch((e)=>{
      reject(e);
    })
  });
  }


  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAssetRegistrationData(){
  return new Promise((resolve,rejects)=>{
    HttpClient.get(
      "/api/Assets/GetAssets",
      true,
      true
    ).then((result: any) => {
       this.assetNoDropDown = result
      resolve(result);
  }).catch((e)=>{
    rejects(e)
     })
  })
}

padding(n){
  if(n<10)
    return "0"+n;
  return n;
}
convertLocalDateString(d){
  if(!d){
    return null;
  }
  let ndate=new Date(d);
  return `${ndate.getFullYear()}-${this.pading(ndate.getMonth()+1)}-${this.pading(ndate.getDate())}T00:00:00`
}
checkValidation(){
  let error=false;
  if(!this.contractorManFacilityForm.BPMID){
    this.error.BPMID=true;
    error=true;
  }

  if(!this.contractorManFacilityForm.CONTRACTNO){
    this.error.CONTRACTNO=true;
    error=true;
  }

  if(!this.contractorManFacilityForm.CONTRACTSTARTDATE){
    this.error.CONTRACTSTARTDATE=true;
    error=true;
  }

  if(!this.contractorManFacilityForm.CONTRACTENDDATE){
    this.error.CONTRACTENDDATE=true;
    error=true;
  }

  if(!this.contractorManFacilityForm.STATUSID){
    this.error.STATUSID=true;
    error=true;
  }

  if(!this.contractorManFacilityForm.CONTRACTTYPEID){
    this.error.CONTRACTTYPEID=true;
    error=true;
  }


  if(!this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL){
    this.error.NOTIFICATIONFORRENEWAL=true;
    error=true;
  }
  return error;
}
submitSparePartUpdateFrom(){
  // this.error = SparePartUpdateValidation(this.sparePartUpdateForm,"");
  // this.sparePartUpdateForm.SPAREPARTUPDATEID=0
  //  this.sparePartUpdateForm.CREATEDBY=parseInt(getCookie(userid))
   if(this.type==="new"){
    this.contractorManFacilityForm.ID =0
    this.contractorManFacilityForm.CREATEDDATE = new Date()
    this.contractorManFacilityForm.CREATEDBY = parseInt(getCookie(userid))
    this.contractorManFacilityForm.MODIFIEDBY = parseInt(getCookie(userid))
    this.contractorManFacilityForm.MODIFIEDDATE = new Date()
    this.contractorManFacilityForm.ATTACHMENTID = 1;
    this.contractorManFacilityForm.CONTRACTSTARTDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTSTARTDATE)
    this.contractorManFacilityForm.CONTRACTENDDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTENDDATE)
    this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL=this.convertLocalDateString(this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL)
  }
  if(this.type==="renew"){
    this.contractorManFacilityForm.CONTRACTSTARTDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTSTARTDATE)
    this.contractorManFacilityForm.CONTRACTENDDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTENDDATE)
    this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL=this.convertLocalDateString(this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL)
  }
   this.saveSparePartUpdate(JSON.stringify(this.contractorManFacilityForm))
  }
  
saveSparePartUpdate(data){
  this.error=new ContractorManagementOfFacilityError();
  if(this.checkValidation()){
    return;
  }
  let obj={
    "schemaName":"dbo",
    "tableName":"BPMcontract",
    "userId":getCookie(userid),
    "newData":data
  }
   let  path="";
  if( this.type==="new"){
     path=`/api/MasterData/SaveLookupData`
  }
  else if(this.type=="update"){
    path="/api/MasterData/UpdateLookupData"
  }
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
  if (result.isValid == true) {
     this.submitContractAssetsFrom(result.id);
     this._notif.show({
      content: result.messageKey,
      type: { style: "success", icon: true },
    });
    this.opened=false;
    this.getBPMData()
    // this.getBpmContractAsset("dbo","BPMContractAsset")

    }
  })
  .catch((err)=>{
    console.log(err);
  })
}

submitContractAssetsFrom(bpmConId){
 debugger;
  // this.error = SparePartUpdateValidation(this.sparePartUpdateForm,"");
  // this.sparePartUpdateForm.SPAREPARTUPDATEID=0
  //  this.sparePartUpdateForm.CREATEDBY=parseInt(getCookie(userid))
   if(this.type==="new"){
    let contractorAsstesWholeDate = [];
    let contractorAsstes =[]
    contractorAsstesWholeDate =this.contectGrid.map((e) => {
      let value: BPMAssets = new  BPMAssets();
      value.ID = 0;
      value.CREATEDDATE = new Date()
      value.CREATEDBY = parseInt(getCookie(userid))
      value.MODIFIEDBY = parseInt(getCookie(userid))
      value.MODIFIEDDATE = new Date()
      value.BPMCONTRACTID= bpmConId;
      // value.ASSETID = e.ASSETID
      value.ASSETID = e.assetId;
      // value.CONTRACTVALUE = parseInt(e.CONTRACTVALUE)
      value.CONTRACTVALUE = parseInt(e.contractValue);
      return value;
    });
    for (let i = 0; i <contractorAsstesWholeDate.length; i++) {
    this.saveContractAssetsForm(JSON.stringify(contractorAsstesWholeDate[i]));
    }
  //  console.log("sfdfsdgds", contractorAsstesWholeDate)
  }
 
  //  this.saveContractAssetsForm(JSON.stringify())
  }
  
saveContractAssetsForm(data){
  let obj={
    "schemaName":"dbo",
    "tableName":"BPMContractAsset",
    "userId":getCookie(userid),
    "newData":data
  }
  console.log(obj)
   let  path="";
  if( this.type==="new"){
     path=`/api/MasterData/SaveLookupData`
  }
  else if(this.type=="update"){
    path="/api/MasterData/UpdateLookupData"
  }
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
   
    console.log(result)
    if (result.isValid == true) {
     

    //   this._notif.show({
    //     content: result.messageKey,
    //     type: { style: "success", icon: true },
    //   });

    //  this.opened=false;
    }
  })
  .catch((err)=>{
    console.log(err);
  })
}
importData(){
this.importopened = true
}
importUpdData(){
  this.importopened = true
}


pading(n){
  if(n>9)
    return n;
  else
   return "0"+n
}

dateFormating(da){
  let df=new Date(da);
 return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
}

bindBPMObj(result){
  this.bpmObj = result.reduce((acc, d) => {
    acc[d.id] = d.registrationNo;
    return acc;
  }, {});
}

bindContTypeObj(result){
  this.contTypeObj = result.reduce((acc, d) => {
    acc[d.ID] = d.NAME;
    return acc;
  }, {});
}


getBPMData(){
  return new Promise((resolve, reject) => {
    HttpClient.get(
     "/api/BPM/GetBpmContractAssets",
      true,
      true
    )
      .then((result: any) => {
        console.log("contractor data",result)
        let statusData = [];
        this.bindBPMObj(this.contractCodeDropDown)
        this.bindContTypeObj(this.contractorTypeDropDown)
        statusData = shiftLastDataInto1st(result)||[];
        this.historyData=statusData.map((e,index)=>{
                e["soNo"] =index+1
                e["contractStartDate"] = this.dateFormating(new Date(e.contractStartDate))
                e["contractEndDate"] = this.dateFormating(new Date(e.contractEndDate))
                e["newRenDate"] =  this.dateFormating(new Date(e.notificationForRenewal))
                e["renewKey"] =false && this.getPriorMonthFromExpiryMonth(new Date(e.contractEndDate))
                e["contractorCode"] =this.bpmObj[e.bpmid]
                if(e.assets.length>0){
                  e["assetCode"] = e.assets[0].assetCode
                  e["locationCode"] = e.assets[0].locationCode
                  e["locationName"] = e.assets[0].locationName
                }
                 let value =   this.contractorChangedData.filter((d)=>{
                  return d.id==e.bpmid
                })
              
                if(value.length>0){
                  e["contractorName"] =value[0].name
                }
               
               return e;
        })
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


getPriorMonthFromExpiryMonth(date){
    var d = new Date(date);
    var currentDate= new Date();
    let currentmonthvalue = currentDate.getMonth()
    let before1monthExpiry = d.getMonth()-1
    
      if(currentmonthvalue==before1monthExpiry){
         return true
      }

      else{
        return false
      }
}

viewColumn(data){
  console.log("ssd",data)
     this.viewDialogOpen = true;
    let value = this.conratorUserData.filter((e)=>{
      return e.contractorId==data.bpmid
    });
    this.facilityData =value
   
}

bindDesignationObj(result) {
  this.designationObj = result.reduce((acc, d) => {
    acc[d.ID] = d.NAME;
    return acc;
  }, {});
}

bindLocationTypeObj(result) {
  this.locObj = result.reduce((acc, d) => {
    acc[d.id] = d.name;
    return acc;
  }, {});
}

getAllUser() {
  HttpClient.get("/api/Users/GetAllUser", true, true)
    .then((result: any) => {
      this.bindLocationTypeObj(this.locationCodeDropDown);
      this.bindDesignationObj(this.designationDropDown);
      this.conratorUserData = result.map((e)=>{
        e["compentencyName"] =e.competencies && e.competencies.map((m)=>{
          return this.compent_Obj[m.skillId]
      }).join("/");
       e["location"]= this.locObj[e.locationID];
        e["designation"] = this.designationObj[e.designationId];
        e["serviceName"] =e.services && e.services.map((m)=>{
          return this.service_Obj[m.serviceTypeId];
      }).join("/") ;
        return e;
      });
      
        console.log( this.conratorUserData)
    })
    .catch((err) => {});
}

getDesignationData(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        this.designationDropDown = JSON.parse(result.gridData);
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getCompentecnyData(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        let compData = [];
         compData = JSON.parse(result.gridData);
           compData.map((e) => {
          this.compent_Obj[e.ID] = e.NAME;
          return { id: e.ID, name: e.NAME };
        });
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getServiceData(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        let servicedata = [];
        servicedata = JSON.parse(result.gridData);
        servicedata.map((e) => {
          this.service_Obj[e.ID] = e.NAME;
          return { id: e.ID, name: e.NAME };
        });
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getBlockData(schema, tablename) {
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
        let BlockData = [];
        BlockData = JSON.parse(result.gridData);
        this.locationCodeDropDown = BlockData.map((e) => {
          return { id: e.ID, name: e.NAME };
        });
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}
removeColumn(e){
  
  this.removeDialogOpen = true;
  let assetObj =this.contractAssetData.filter((d)=>{
    return d.BPMCONTRACTID == e.dataItem.bpmContractID
  });
  let assetId;
  if(assetObj.length>0){
    assetId = assetObj[0].ASSETID
  }
  this.getBpmContractDataByID("dbo","BPMContract",e.dataItem.bpmContractID)
  this.getBpmContractAssetsByID("dbo","BPMContractAsset",assetId)
}


getBpmContractDataByID(schema,tablename,id){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
      true,
      true
    )
      .then((result: any) => {
       this.contractorManFacilityForm=JSON.parse(result.gridData);
         resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getBpmContractAssetsByID(schema,tablename,id){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
      true,
      true
    )
      .then((result: any) => {
       this.contractorAsstes =JSON.parse(result.gridData);
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

removeContractorData(){
  let contractData = this.contractorManFacilityForm[0]
  let obj={
    "schemaName":"dbo",
    "tableName":"BPMcontract",
    "userId":getCookie(userid),
    "oldData":JSON.stringify(contractData),    
    "newData":null
  }
   let  path=`/api/MasterData/DeleteLookupData`
  HttpClient.fetchData(path,"post",obj,true,true)
  .then((result:any)=>{
    if(result.messageKey){
      console.log(result);
      this._notif.show({
        content:result.messageKey,
        type: { style: "success", icon: true },
      });
    }
    else if(result.message){
      this._notif.show({
        content:result.message,
        type: { style: "error", icon: true },
      });
    }
    this.getBPMData()
     this.removeDialogOpen=false;
  })
  .catch((err)=>{
    console.log(err);
  })
}


getBpmContractAsset(schema,tablename){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    )
      .then((result: any) => {
    
       this.contractAssetData = JSON.parse(result.gridData);
        resolve(result)
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getAttechment(RefId) {
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/MasterData/GetLookupData?Schema=dbo&Table=attachment",true,true)
    .then((result:any)=>{
        let attechmentData=JSON.parse(result.gridData)
        let temp=attechmentData.filter((d)=>d.REFERENCEID==RefId && d.MODULEHIERARCHYID==this.moduleId);
        resolve(temp);
    })
    .catch((err)=>{
        reject(err);
    })
  })
}



deleteLookupAttechment(data) {
  let obj = {
    schemaName: "dbo",
    tableName: "attachment",
    userId: 1,
    oldData: JSON.stringify(data),
  };
  return new Promise((resolve, reject) => {
    HttpClient.fetchData(
      "/api/MasterData/DeleteLookupData",
      "post",
      obj,
      true,
      true
    )
      .then((response) => {
        resolve(response);
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName)
        // this.opened=false;
      })
      .catch((err) => {
        reject(err);
      });
  });
}

addAttechment() {
  if(!this.attechForm.URL){
    this.attechError.URL=true
  }
  else{
    this.attechError.URL=false
  }
  if(!this.attechForm.FILENAME){
    this.attechError.FILENAME=true
  }
  else{
    this.attechError.FILENAME=false
  }
  if(!this.attechError.URL && !this.attechError.FILENAME ){
    this.attechForm.FILECONTENTS=this.attechForm.URL;
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.saveData()
    .then((result:any)=>{
      this.attechForm.ID=result.id;
      this.attechmentDoc.getData(this.attechId,this.moduleId)
      .then((result)=>{
        this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        this.attechForm=new Attechment();
      })
      // this.attechmentDoc.alldata.push(this.attechForm);
      // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
      // this.attechForm=new Attechment();
    })
    .catch((err)=>{
      
    })
  }
}

saveAttechment(data) {
  let obj = {
    schemaName: "dbo",
    tableName: "attachment",
    userId: 1,
    newData: JSON.stringify(data),
  };
  return new Promise((resolve, reject) => {
    HttpClient.fetchData(
      "/api/MasterData/SaveLookupData",
      "post",
      obj,
      true,
      true
    )
      .then((response) => {
        resolve(response);
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName)
        // this.opened=false;
      })
      .catch((err) => {
        reject(err);
      });
  });
}

getModuleHierarchYdata(schema, tablename) {
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
    let ModuleHieracrhyData = JSON.parse(result.gridData);
        console.log( "sadasdamo",ModuleHieracrhyData)
    let ModFacArr = ModuleHieracrhyData.filter((e) => {
      return e.NAME === "Contract Management For Facility User";
    });
    // this.facilityParentId = ModFacArr[0].PARENTID
    this.MODULEHIEARCHYID = ModFacArr[0].ID;
    this.moduleId=ModFacArr[0].ID;
  });
}

renewData(data){
  this.type="renew"
  this.Renewopened = true;
  this.dailogTitle = "Renew Date"
  this.getBpmContractDataByID("dbo","BPMContract",data.ID)
}

updateRenewbledate(){
    //  let obj ={
    //   ID: this.contractorManFacilityForm[0].ID,
    //   BPMID:this.contractorManFacilityForm.BPMID,
    //   CONTRACTNO:this.contractorManFacilityForm.CONTRACTNO,
    //   CONTRACTSTARTDATE:this,
    //   CONTRACTENDDATE:this.renewableEndDate,
    //   CONTRACTSUM:this.contractorManFacilityForm[0].CONTRACTSUM,
    //   CONTRACTTYPEID:this.contractorManFacilityForm[0].CONTRACTTYPEID,
    //   NOTIFICATIONFORRENEWAL:this.contractorManFacilityForm[0]. NOTIFICATIONFORRENEWAL,
    //   SCOPEOFWORKY:this.contractorManFacilityForm[0].SCOPEOFWORKY,
    //   ATTACHMENTID:this.contractorManFacilityForm[0].ATTACHMENTID,
    //   REMARKS:this.contractorManFacilityForm[0].REMARKS,
    //   CREATEDBY:getCookie(userid),
    //   CREATEDDATE:new Date(),
    //   MODIFIEDBY:getCookie(userid),
    //   MODIFIEDDATE:new Date(),
    //  }

  //  console.log(this.contractorManFacilityForm)
  this.contractorManFacilityForm.CONTRACTSTARTDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTSTARTDATE)
    this.contractorManFacilityForm.CONTRACTENDDATE=this.convertLocalDateString(this.contractorManFacilityForm.CONTRACTENDDATE)
    this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL=this.convertLocalDateString(this.contractorManFacilityForm.NOTIFICATIONFORRENEWAL)
console.log("contractor==>",this.contractorManFacilityForm);
     this.saveRenewUpdate(JSON.stringify(this.contractorManFacilityForm))
}

saveRenewUpdate(data){
  let obj={
    "schemaName":"dbo",
    "tableName":"BPMcontract",
    "userId":getCookie(userid),
    "newData":data
  }
  HttpClient.fetchData("/api/MasterData/UpdateLookupData","post",obj,true,true)
  .then((result:any)=>{
  if (result.isValid == true) {
    this._notif.show({
      content: "Success",
      type: { style: "success", icon: true },
    });
       this.Renewopened = false
       this.opened=false;
       this.getBPMData()
    }
  })
  .catch((err)=>{
    this._notif.show({
      content: "Fail",
      type: { style: "error", icon: true },
    });
    console.log(err);
  })
}
importSave(){
  this.getBPMData()
  this.getBpmContractAsset("dbo","BPMContractAsset")
  this.importopened= false;
}


getContractorMan(){
  HttpClient.get("/api/BPM/GetBpmContractAssets",true)
  .then((result:any)=>{

  })
}


download() {
  let fileName = 'contractManagementFacilityUser.csv';

  let columnNames = [
  "soNo",
  "bpmid", 
  "bpmName", 
  "contractNo",
  "contractorName",
  "contractorCode",
  "contractStartDate", 
  "contractEndDate",
  "contractSum",
  "contractTypeId", 
  "contractTypeName", 
  "notificationForRenewal",
  "scopeOfWork", 
  "attachmentId",
  "remarks",
  "locationName",
  "assetCode", 
  "locationCode", 
  "nameCreatedBy",
  "nameModifiedBy"
  
];
  let header = columnNames.join(',');
  let csv = header;
  csv += '\r\n';

  this.historyData.map(c => {
    csv += [
      c["soNo"],
      c["bpmid"], 
      c["bpmName"], 
      c["contractNo"],
      c["contractorName" ],
      c["contractorCode"],
      c["contractStartDate"], 
      c["contractEndDate"],
      c["contractSum"],
      c["contractTypeId"], 
      c["contractTypeName"], 
      c["notificationForRenewal"],
      c["scopeOfWork"], 
      c["attachmentId"],
      c["remarks"],
      c["locationName"],
      c["assetCode"], 
      c["locationCode"],
      c["nameCreatedBy"],
      c["nameModifiedBy"]
    ].join(',');
    csv += '\r\n';
  })
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  var link = document.createElement("a");
  if (link.download !== undefined) {
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
onSearchChange(value){
  this.contractorManFacilityForm.CONTRACTSUM = value
}

getSumOfContract(data){
  this.contractorManFacilityForm.CONTRACTSUM = data

}
ViewAssetColumn(data){
  this.gridAssetDetData = data.assets.map((e)=>{
    this.assetNoChanged(e.assetModelUI.id);
    e["assetNo"] =   this.assetNumber ;
    e["assetCode1"] = e.assetModelUI.assetCode
   e["contractValue"] = e.contractValue
   return e;
  })
 this.viewAssetDialogOpen = true
}

assetNoChanged(data){
  let value=  this.assetNoDropDown.filter((e)=>{
    return e.id===data
  });
   this.assetNumber = value[0].assetNumber
}

valueChange(event){
  this.type="new"
   let obj = addBlukContractManagement(event,this.contractCodeDropDown,this.contractorTypeDropDown)
   console.log("contractor code",obj,event) 
 obj.forEach((data)=>{
    this.saveSparePartUpdate(JSON.stringify(data))

  })
}
handleFilter(type,e){
  if(type=="contractor-code"){
    this.contractCodeDropDown=this.contractorChangedData.filter((d)=>d.name.toLowerCase().indexOf(e.toLowerCase()) !== -1)
  }
}
downloadTemplate(){
  window.open("/assets/template/Contract_Management.xlsx")
}
}




