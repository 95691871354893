import { Component, OnInit } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import {
  SparePartDetailsValue,
  SparePartReturn,
  SparePartReturnDetails,
} from "src/app/models/spare.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { userid } from "src/Util/const";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { SparePartReturnDetailValidation, SparePartReturnValidation } from "src/validator/spare";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: "app-spare-part-return",
  templateUrl: "./spare-part-return.component.html",
  styleUrls: ["./spare-part-return.component.scss"],
})
export class SparePartReturnComponent implements OnInit {
  public opened = false;
  public format = "dd/MM/yyyy HH:mm";
  public sparePartDetForm = true;
  public viewDialogOpen = false;
  public afterEditDisabled =false;
  public loginUserTypeDropDown =[]
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public validate = true;
  public editFlag = false;
  public permission:Permission=new Permission();
  public woDataValue =[]
  public contectGrid = [];
  public gridData = [];
  public facilityCode;
  public facilityObj = {};
  public spareRequestList = [];
  public facilityDropDown = [];
  public workOrderData = [];
  public reqObj = {};
  public WoData = [];
  public partNo;
  public partDes;
  public data23: any = [];
  public binNo;
  public WOID;
  public SparePartID;
  public SPAREPARTRETURNID;
  public SparePartQuantity;
  public WODescData = [];
  public facilityDetailFlag;
  public gridReturnDetData =[]
  public sparePartReturnForm: SparePartReturn = new SparePartReturn();
  public sparePartDetailsForm: SparePartReturnDetails =
    new SparePartReturnDetails();
    error1= SparePartReturnValidation(this.sparePartReturnForm,"init")
    error2=SparePartReturnDetailValidation (this.sparePartDetailsForm,"init")
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];

  public column2 = [
    {
      field: "requestId",
      // field: "materialRequestNo",
      title: "Material Request No",
      isFilter: true,
      width: 200,
    },
    {
      field: "workOrderNo",
      title: "Work Order No.",
      isFilter: true,
      width: 200,
    },
    {
      field: "facilityName",
      title: "Facility Name",
      isFilter: true,
      width: 200,
    },
    { field: "RETURNDATE", title: "Returned Date", isFilter: true, width: 200 },
    
  ];

  public returnDetailsCol=[
    {
      field: "partNo",
      title: "PartNo",
      isFilter: true,
      width: 150,
    },
    {
      field: "PartDesc",
      title: "Part Description",
      isFilter: true,
      width: 150,
    },
    {
      field: "binNo",
      title: "Bin No.",
      isFilter: true,
      width: 150,
    },
    {
      field: "quantityDisposal",
      title: "Disposal Quantity",
      isFilter: true,
      width: 150,
    },
    {
      field: "quantityRetuned",
      title: "Quantity Return",
      isFilter: true,
      width: 150,
    },
    {
      field: "remarks",
      title: "Remark",
      isFilter: true,
      width: 150,
    },
  ]

  public areagrid = [
    {
      c_1: "",
      c_2: "",
      c_3: "",
      c_4: "",
      c_5: "",
      c_7: "",
      c_8: "",
      c_9: "",
      c_10: "",
      c_11: "",
      c_12: "",
      c_13: "",
      c_14: "",
      c_15: "",
      c_17: "",
      c_18: "",
      c_19: "",
      c_20: "",
    },
    {
      c_1: "",
      c_2: "",
      c_3: "",
      c_4: "",
      c_5: "",
      c_7: "",
      c_8: "",
      c_9: "",
      c_10: "",
      c_11: "",
      c_12: "",
      c_13: "",
      c_14: "",
      c_15: "",
      c_17: "",
      c_18: "",
      c_19: "",
      c_20: "",
    },
  ];

  public monthDayList = [
    { full: "January", short: "Jan", day: "Sun" },
    { full: "February", short: "Feb", day: "Mon" },
    { full: "March", short: "Mar", day: "Tue" },
    { full: "April", short: "Apr", day: "Wed" },
    { full: "May", short: "May", day: "Thr" },
    { full: "June", short: "Jun", day: "Fri" },
    { full: "July", short: "Jul", day: "Sat" },
    { full: "Augest", short: "Aug", day: "Sun" },
    { full: "September", short: "Sep", day: "" },
    { full: "October", short: "Oct", day: "" },
    { full: "November", short: "Nov", day: "" },
    { full: "December", short: "Dec", day: "" },
  ];

  public contectColumn = [
    {
      field: "partNo",
      title: "Part No.",
      isFilter: false,
      width: 140,
    },
    {
      field: "partDesc",
      title: "Part Description",
      isFilter: false,
      width: 140,
    },
    {
      field: "binNo",
      title: "Bin No.",
      isFilter: false,
      width: 140,
    },
    {
      field: "quantityret",
      title: "Quantity Return",
      isFilter: false,
      width: 140,
      errorField: "quantity_error",
      validation: (val) => {
        const re = /^[0-9]+$/;
        if (val && re.test(val)) return "";
        else return "*Please Enter Number";
      },
    },
    {
      field: "disposalQuant",
      title: "Disposal Quantity",
      isFilter: false,
      width: 140,
      errorField: "disposal_error",
      validation: (val) => {
        const re = /^[0-9]+$/;
        if (val && re.test(val)) return "";
        else return "*Please Enter Number";
      },
    },
    {
      field: "remark",
      title: "Remarks",
      isFilter: false,
      width: 140,
    },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  submitted = false;
  cont_vendor_r = ["Select"];

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.view_column, (e) => {
      this.viewColumn(e);
    });
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
  }

  viewColumn(data) {
    this.viewDialogOpen = true;

    this.getSparePartReturnById(data.id,"view")
    
  }

  ngOnInit() {
    if(UserModuleObj.spare_parts){
      this.permission=UserModuleObj.spare_parts;
    }
    Promise.all([
     
      this.getloginUserTypeData("dbo", "category"),
      this.getfacility("dbo", "facility"),
      this.getSparePartRequest(),
      this.getWorkOrder(),
    ]).then((e) => {
      this.getAllUser(),
      this.getSparePartReturn();
    });
  }

  onReset() {
    this.openRoleDailog("new")
  }
  onDialogClose(type) {
    if(type=="new"){
      this.submitted = false;
       this.opened = false;
    }
     if(type=="view"){
      this.viewDialogOpen = false
    }
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.sparePartReturnForm = new SparePartReturn();
      this.sparePartDetailsForm = new SparePartReturnDetails();
      this.WoData =[]
      this.facilityCode =""
      this.error1 = SparePartReturnValidation(this.sparePartReturnForm,"init")
      this.dailogTitle = "Add Spare Part Return";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Spare Part Return";
    }
    this.opened = true;
  }

  getSparePartRequest() {
    return new Promise((resolve, rejects) => {
      HttpClient.get("/api/Assets/GetSpareRequestList", true, true)
        .then((result: any) => {
          let sparereuestlistData =[]
          if(result.length>0){
            sparereuestlistData = result;
          }
          this.spareRequestList = sparereuestlistData.filter((e)=>{
            return e.approvalStatus ===true
          })
         
          resolve(result);
        })
        .catch((err) => {
          rejects(err);
        });
    });
  }
  
  getWorkOrder() {
    return new Promise((resolve, rejects) => {
      HttpClient.get("/api/WorkOrder/GetWorkOrders", true, true)
        .then((result: any) => {
          resolve(result);
          this.workOrderData  = result.filter((e)=>{
            if(e.workOrderNo!==null && e.workOrderNo!=="" ){
              return e;
            }
      })
  })
        .catch((e) => {
          rejects(e);
        });
    });
  }

  getfacility(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData);
          this.facilityDropDown = facilityData;
          resolve(facilityData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  chnagedMaterialNo(data) {
    let dataValue = this.spareRequestList.filter((e) => {
      return e.id === data;
    });
   
    if(dataValue.length>0){
      this.sparePartReturnForm.FACILITYID = dataValue[0].facilityId;
      this.sparePartDetailsForm.WORKORDERID = dataValue[0].ticketId
    }
    this.getFacilityCode( this.sparePartReturnForm.FACILITYID );
    this.chnagedWorkOrderNo(this.sparePartDetailsForm.WORKORDERID)

  }

  getFacilityCode(data) {
    let facilityValue = this.facilityDropDown.filter((e) => {
      return e.ID === data;
    });
    if(facilityValue.length>0){
      this.facilityCode = facilityValue && facilityValue[0].FACILITYCODE;
    }
  }

  chnagedWorkOrderNo(data) {
    this.WOID = data;
   return new Promise((resolve, reject) => {
      HttpClient.get(`/api/Assets/GetPartNoByWOID/?Id=${data}`, true)
        .then((result: any) => {

          result = result.filter((e) => {
            return e.sparePartReturnDetails.length != 0;
          });
          this.WoData = result;
          let rowCount = 0;

          this.WODescData = result.map((e) => {
            if(e.sparePartReturnDetails.length != 0){
              let value: SparePartDetailsValue = new SparePartDetailsValue();
              this.SparePartID = e.sparePartId;
              value.SPAREPARTDETAILID = e.sparePartId;
              value.PARTNO = e.partNo;
              value.DESCRIPTION = e.partDescription;
              value.BINNO = e.binNo
              this.SPAREPARTRETURNID = e.sparePartReturnDetails[0].id;
              value.QUANTITYRETUNED = e.sparePartRequestDetails[rowCount].requestedQuantity;
              // if(e.sparePartRequestDetails.length != 0 && e.sparePartRequestDetails[0].sparePartRequest.sparePartReturns.length != 0 && e.sparePartRequestDetails[0].sparePartRequest.sparePartReturns[0].sparePartReturnDetails.length != 0){
              //   value.QUANTITYRETUNED = e.sparePartRequestDetails[0].sparePartRequest.sparePartReturns[0].sparePartReturnDetails[0].quantityRetuned;
              // this.SPAREPARTRETURNID = e.sparePartRequestDetails[0].sparePartRequest.sparePartReturns[0].sparePartReturnDetails[0].id;
              // }else{
              //   value.QUANTITYRETUNED = e.sparePartRequestDetails[0].requestedQuantity;
              // }
              value.QUANTITYDISPOSAL = e.QUANTITYDISPOSAL;
              value.QUANTITYDISPOSAL = e.QUANTITYRETUNED;
              value.ID = 0;
              value.WORKORDERID = data;
              value.REMARKS = e.REMARKS;
              rowCount++;
              return value;
            }

          });
          // resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  submitSpareReturnFrom() {
    if(this.sparePartReturnForm.FACILITYID===null){
      this.sparePartReturnForm.FACILITYID =0
    }
    this.error1 = SparePartReturnValidation(this.sparePartReturnForm,"")
    if(!this.error1.RETURNDATE && !this.error1.SPAREPARTREQUESTID){
      if (this.type == "new") {
        this.sparePartReturnForm.ID = 0;
      }
      if(this.SPAREPARTRETURNID != null && this.SPAREPARTRETURNID != undefined){
      this.SparePartQuantity = this.WODescData[0].QUANTITYRETUNED;
      this.updateSparePartDetailsQty(this.SparePartID, this.SPAREPARTRETURNID, this.SparePartQuantity);
      }else{
      this.saveSparePartUpdate(JSON.stringify(this.sparePartReturnForm));
      }
    }
  }

  saveSparePartUpdate(data) {
    let obj = {
      schemaName: "mm",
      tableName: "sparePartReturn",
      userId: getCookie(userid),
      newData: data,
    };
    this.SparePartQuantity = this.WODescData[0].QUANTITYRETUNED;
    this.updateSparePartDetailsQty(this.SparePartID, this.SPAREPARTRETURNID, this.SparePartQuantity);


    // let path = "";
    // if (this.type === "new") {
    //   path = `/api/MasterData/SaveLookupData`;
    // } else if (this.type == "update") {
    //   path = "/api/MasterData/UpdateLookupData";
    // }
    // this.SparePartQuantity = this.WODescData[0].QUANTITYRETUNED;
    //   this.updateSparePartDetailsQty(this.SparePartID, this.SPAREPARTRETURNID, this.SparePartQuantity);

    // HttpClient.fetchData(path, "post", obj, true, true)
    //   .then((result: any) => {
    //     if (result.isValid == true) {
    //       this.submitSparePartDetailsFrom(result.id);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  pading(n) {
    if (n > 9) return n;
    else return "0" + n;
  }

  dateFormating(da) {
    let df = new Date(da);
    return `${this.pading(df.getDate())}-${
      this.monthDayList[df.getMonth()].short
    }-${df.getFullYear()}`;
  }

  bindfacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindReqObj(result) {
    this.reqObj = result.reduce((acc, d) => {
      acc[d.id] = d.materialRequestNo;
      return acc;
    }, {});
  }

  getSparePartReturn() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetSpareReturnList", true, true)
        .then((result: any) => {
          result = shiftLastDataInto1st(result)||[];
          this.bindfacilityObj(this.facilityDropDown);
          debugger;
          // this.bindReqObj(this.spareRequestList);
          this.gridData = result.map((e) => {
            e["RETURNDATE"] = this.dateFormating(new Date(e.returnDate));
            e["facilityName"] = this.facilityObj[e.facilityId];
            e["requestId"] = this.reqObj[e.sparePartRequestId];
            if (e.sparePartReturnDetails.length > 0) {
              e["disposalQua"] = e.sparePartReturnDetails[0].quantityDisposal;
              e["returnedQua"] = e.sparePartReturnDetails[0].quantityRetuned;
              e["remark"] = e.sparePartReturnDetails[0].remarks;
            }
            if (e.sparePartRequest != null && e.sparePartRequest.ticket != null) {
              e["workOrderNo"] = e.sparePartRequest.ticket.workOrderNo;
              e["requestId"] = e.sparePartRequest.materialRequestNo;
              // e["NAME"] = e.facilityId;
            }
            if(e["requestId"] == null || e["requestId"] == ''){
              e["requestId"] = e.sparePartRequest.materialRequestNo;
            }
            return e;
          });
          console.log(result);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  submitSparePartDetailsFrom(id) {
    // this.error = SparePartUpdateValidation(this.sparePartUpdateForm,"");
    // this.sparePartUpdateForm.SPAREPARTUPDATEID=0
    //  this.sparePartUpdateForm.CREATEDBY=parseInt(getCookie(userid))
    let sparePartWholeDate = [];
    let rowCount =0;
    sparePartWholeDate = this.WoData.map((e) => {
      let value: SparePartReturnDetails = new SparePartReturnDetails();
      if(e.sparePartid){
        value.ID = e.sparePartid;
      }
      else{
        value.ID = 0
      }
      value.QUANTITYDISPOSAL = e.QUANTITYDISPOSAL;
      value.QUANTITYRETUNED = e.QUANTITYRETUNED;
      value.REMARKS = e.REMARKS;
      value.WORKORDERID = this.WOID;
      this.SparePartID = e.sparePartId;
      value.RETURNDATE = new Date(e.createdDate);
      value.SPAREPARTDETAILID = e.id;
      value.SPAREPARTRETURNID = id;
      this.SPAREPARTRETURNID = id;
      if(this.SparePartQuantity==undefined){
        this.SparePartQuantity = e.sparePartRequestDetails[rowCount].requestedQuantity;
      }
      return value;
    });
      let filteredSpairPartDetilData = sparePartWholeDate.filter((e)=>{
        if( e.QUANTITYDISPOSAL!==undefined || e.QUANTITYRETUNED!==undefined){
          return e;
        }
         })
         if(this.SPAREPARTRETURNID == null || this.SPAREPARTRETURNID == undefined)
         {
          for (let i = 0; i < filteredSpairPartDetilData.length; i++) {
            this.saveSparePartDetails(JSON.stringify(filteredSpairPartDetilData[i]));
          }
         }else{
          this.updateSparePartDetailsQty(this.SparePartID, this.SPAREPARTRETURNID, this.SparePartQuantity);
         }
  }

  saveSparePartDetails(data) {
    let obj = {
      schemaName: "mm",
      tableName: "sparePartReturnDetail",
      userId: getCookie(userid),
      newData: data,
    };
    let path = "";
    if (this.type === "new") {
      path = `/api/MasterData/SaveLookupData`;
    } else if (this.type == "update") {
      path = "/api/MasterData/UpdateLookupData";
    }
    HttpClient.fetchData(path, "post", obj, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getSparePartReturn();
          this.updateSparePartDetailsQty(this.SparePartID, result.id, this.SparePartQuantity);
          this.opened = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateSparePartDetailsQty(id, returnID,quantity) {

    let path =`/api/Assets/UpdateSpareReturnQuantity?ID=` + id +'&returnID='+ returnID +'&quantity=' + quantity ;

    HttpClient.get(path, true, true,)
      .then((result: any) => {
        this.getSparePartRequest();
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
        this.getSparePartReturn();
        this.opened = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSparePartReturnById(id,data,) {
    debugger;
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/Assets/GetSpareReturnList?Id=${id}`, true, true)
        .then((result: any) => {
            let value = result && result[0].sparePartReturnDetails;
            if(data==="view"){
              this.gridReturnDetData = value.map((e)=>{
                HttpClient.get(`/api/Assets/GetPartNoByWOID/?Id=${e.workOrderId}`, true)
                   .then((result2: any) => {
                    debugger;
                    result2 = result2.filter((e) => {
            return e.sparePartReturnDetails.length != 0;
          });

                           result2.map((d)=>{
                             e["partNo"] = d.partNo
                             e["PartDesc"]  = d.partDescription
                             e["binNo"]  =d.binNo
                             return d;
                         })
                   })
                  
                   return e;
               });
            }

            if(data==="update"){
              this.WoData = value.map((e)=>{
                HttpClient.get(`/api/Assets/GetPartNoByWOID/?Id=${e.workOrderId}`, true)
                   .then((result2: any) => {
                           result2.map((d)=>{
                             e["partNo"] = d.partNo
                             e["partDescription"]  = d.partDescription
                             e["binNo"] = d.binNo
                            return d;
                         })
                   })
                   e["QUANTITYRETUNED"] = e.quantityRetuned
                   e["QUANTITYDISPOSAL"] = e.quantityDisposal
                   e["REMARKS"] = e.remarks
                   e["sparePartid"] =e.id
                  return e;
               });
            }
          resolve(result);
         
              })
          
        })
        
  }

  getAllUser() {
    HttpClient.get("/api/Users/GetAllUser", true, true)
      .then((result: any) => {
         let userData = result.filter((e)=>{
           return e.id===parseInt(getCookie(userid))
         })
         let companyId = userData[0].categoryId
         let user = this.loginUserTypeDropDown.filter((e)=>{
          return e.ID === companyId ;
        })
        if(user.length>0){
          if(user[0].NAME==="Company User"){
            this.editFlag =true
      }
        }
     })
  }
  
  getloginUserTypeData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.loginUserTypeDropDown = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  editColumn(data){
    this.afterEditDisabled = true;
    this.sparePartReturnForm = data.dataItem
    this.sparePartReturnForm.ID = data.dataItem.id
    this.sparePartReturnForm.SPAREPARTREQUESTID = data.dataItem.sparePartRequestId
    // this.chnagedMaterialNo(data.dataItem.sparePartRequestId)
    this.getSparePartReturnById(data.dataItem.id,"update")
    this.sparePartReturnForm.RETURNDATE = new Date(data.dataItem.returnDate)
    this.sparePartReturnForm.FACILITYID = data.dataItem.facilityId
    this.getFacilityCode( this.sparePartReturnForm.FACILITYID );
    console.log("data.dataItem.sparePartReturnDetails",data.dataItem.sparePartReturnDetails)
    this.sparePartDetailsForm.WORKORDERID =  data.dataItem.sparePartReturnDetails[0].workOrderId
     this.WOID = this.sparePartDetailsForm.WORKORDERID
    this.openRoleDailog("update");
  }

  
}
