import { Component, Input, OnInit,OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { distinct, GroupDescriptor, process, State,filterBy } from "@progress/kendo-data-query";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  RowArgs,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { SelectableSettings } from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"
@Component({
  selector: 'app-dashboard-table-grid',
  templateUrl: './dashboardWO-table-grid.component.html',
  styleUrls: ['./dashboardWO-table-grid.component.scss']
})
export class dashboardTableGridComponent implements OnChanges {
  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  @Input() action:Boolean=true;
  @Input() checkbox:Boolean =false;
  @Input() deleteFlag:Boolean=true;
  @Input() editFlag:Boolean=true;
  @Input() fileFlag:Boolean=false;
  @Input() approved:Boolean=false;
  @Input() qrcode:Boolean=false;
  @Input() addAsset:Boolean=false;
  @Input() unlockFlag:Boolean=false;
  @Input() reject:Boolean=false;
  @Input() viewFlag:Boolean=false;
  @Input() renewFlag:Boolean = false;
  @Input() assetFlag:Boolean = false;
  @Input() deleteKey:string="";
  @Input() renewKey:string ="";
  @Input() sparePartFlag:Boolean=false;
  @Input() groups: GroupDescriptor[] = [];
  @Input() filterList={};
  @Input() id="";
  @Output() filteredChanged: EventEmitter<number> = new EventEmitter();
  public mySelection: number[] = [2, 4];
  public filiterData : any;
  public selectableSettings: SelectableSettings ={
    checkboxOnly:true,
    mode: "single",
    drag:false,
  };
 


  publicBackupData=[];
  public gridData:GridDataResult|any;
  public pagesizes = [{text: 'One', value: 1}, {text: 'Two', value: 2}, {text: 'All', value : 'all'}];
  public state: State = {
    skip: 0,
    group:[],
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {

    if(changes.size)
    {
      this.state.skip = 0;
      this.state["take"]=changes.size.currentValue;
      this.gridData = process(this.data,this.state);
    }
    if(changes.data)
    {
      this.data  = changes.data.currentValue;
        this.state.skip = 0;
        this.state.take = this.size;
         let objectData = JSON.parse( JSON.stringify(this.state));
         delete objectData.filter;
        this.gridData  = process(changes.data.currentValue, objectData);
        //this.gridData = changes.data.currentValue;
    }
    if(changes.column){
      this.column=changes.column.currentValue;
    }
    if(changes.action){
      this.action=changes.action.currentValue
    }
    if(changes.deleteFlag){
      this.deleteFlag=changes.deleteFlag.currentValue
    }
    if(changes.fileFlag){
      this.fileFlag=changes.fileFlag.currentValue
    }
    if(changes.assetFlag){
      this.assetFlag=changes.assetFlag.currentValue
    }
    if(changes.approved){
      this.approved=changes.approved.currentValue
    }
    if(changes.reject){
      this.reject=changes.reject.currentValue
    }
    if(changes.deleteKey){
    
      this.deleteKey=changes.deleteKey.currentValue
    }
    if(changes.renewKey){
    
      this.renewKey=changes.renewKey.currentValue
    }
    if(changes.checkbox){
      this.checkbox=changes.checkbox.currentValue
    }
    if(changes.qrcode){
      this.qrcode=changes.qrcode.currentValue
    }
    if(changes.addAsset){
      this.addAsset= changes.addAsset.currentValue
    }
    if(changes.unlockFlag){
      this.unlockFlag= changes.unlockFlag.currentValue
    }
    if(changes.editFlag){
      this.editFlag= changes.editFlag.currentValue
    }
    if(changes.viewFlag){
      this.viewFlag= changes.viewFlag.currentValue
    }
    if(changes.renewFlag){
      this.renewFlag = changes.renewFlag.currentValue
    }
    if(changes.sparePartFlag){
      this.sparePartFlag=changes.sparePartFlag.currentValue;
    }
    if(changes.groups){
      this.groups=changes.groups.currentValue;
      this.state.group=this.groups;
    }
    if(changes.filterList){
      this.filterList=changes.filterList.currentValue;
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if(this.state.filter.filters.length != 0){
       this.filiterData  = this.state.filter.filters[0];
      if(this.filiterData.filters.length >= 2){
       this.filiterData.filters[1].operator = "lte";
      }
      else{
        this.state.filter.logic = "and";
      }
    }else{
      this.state.filter.logic = "and";
    }
    this.gridData = process(this.data, this.state);
  }
  
  ngOnInit(): void {
    if(this.groups && this.groups.length>0){
      this.state.group=this.groups;
     // this.state.take=null;
    }
  }
  editHandler(e){
    dispatchEvent(EVENT_NAME.edit_column,e);
  }
  removeHandler(e){
    dispatchEvent(EVENT_NAME.remove_column,e);
  }
  addAttachment(e){
    dispatchEvent(EVENT_NAME.attachment_column,e);
  }
 addQrCode(e){
    dispatchEvent(EVENT_NAME.qrCode_attachment,e);
  }

  addAssetReg(e){
    dispatchEvent(EVENT_NAME.addAssetReg_attachment,e); 
  }
  unlockData(e){
    dispatchEvent(EVENT_NAME.unlock_data,e); 
  }

  viewData(e){
    dispatchEvent(EVENT_NAME.view_column,e); 
  }

  viewAssetData(e){
    dispatchEvent(EVENT_NAME.view_asset_column,e);
  }

  approvedData(type,e){
    let obj ={
      type:type,
      data:e
    }
    dispatchEvent(EVENT_NAME.approved_column,obj); 
  }

  rejectData(type,e){
    let obj ={
      type:type,
      data:e
    }
    dispatchEvent(EVENT_NAME.reject_column,obj); 
  }

  checkboxEvent(e,d){
     let obj ={
         status:e.target.checked,
         data:d
     }
    dispatchEvent(EVENT_NAME.checkbox_column,obj);
  }

  public onSelect(e) {
    dispatchEvent(EVENT_NAME.wo_id,e.selectedRows[0].dataItem.id)
  }
  changeDate(e){
    dispatchEvent(EVENT_NAME.renew_column,e); 
  }

  handleACFilter(data){
    console.log(data.logic);
    // if(data.logic == "and"){
    //   data.logic = "";
    // }
    if(data.filters.length != 0){
    if(data.filters[0].filters.length >= 0 && data.filters[0].filters[0].field == "acceptanceDate"){
      data.filters[0].logic = "or";
    }
    else{
      data.logic = "and";
    }
  }else{
    data.logic = "and";
  }
    // if(data.filters.length >= 2){
    //   data.filters = data.filters.splice(1,1);
    //  }
    let filteredArray:any =[]
     data.filters.map((e)=>{
          return e.filters.map((y)=>{
           if(!filteredArray.includes({"field":y.field ,"value":y.value})){
              return  filteredArray.push({"field":y.field ,"value":y.value})
           }
          })
    })
   
this.filteredChanged.emit(filteredArray)
  
    //  data.map((e)=>{

    //  })
  }


  public distinctPrimitive(fieldName: string): unknown[] {
    
    function getFieldData(item,f){
      return  f.split(".").reduce((acc,k)=>{
        acc=acc[k];
        return acc;
       },item)
    }
    let tobj= distinct(this.data, fieldName).map((item) => getFieldData(item,fieldName));
    return tobj;
  }

}
