import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileMainComponent } from './mobile-main/mobile-main.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MainContainerComponent } from './main-container/main-container.component';
import { AssetContainerComponent } from './asset-container/asset-container.component';
import { AssetRegisterComponent } from './asset-container/asset-register/asset-register.component';
import { AssetInfoComponent } from './asset-container/asset-info/asset-info.component';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  declarations: [
    MobileMainComponent,
    MainContainerComponent,
    AssetContainerComponent,
    AssetRegisterComponent,
    AssetInfoComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    BrowserAnimationsModule,
    ButtonsModule
  ],
  bootstrap:[MobileMainComponent]
})
export class MobileViewModule { }
