
class  bpmskills{
  id:number=0;
  bpmId:number=0;
  skillId:number=0;
}

export class ContractorVendor{
    id: number;
    name: string;
    statusId: number;
    registrationNo: string;
    address1: string;
    address2: string;
    postCode: string;
    stateId: number;
    countryId: number;
    phoneNo: string="9876321231";
    faxNo: string;
    noOfUserAccess: number;
    remarks: string;
    contactpersonName: string="ram";
    designation: string;
    contactNo: string;
    email: string;
    bpmtypeId= 1;
    bpmskills:Array<bpmskills>
    bpmregisterContactPersons:Array<  {
      "id": number,
      "name": string,
      "designation":string,
      "contactNo": string,
      "email": string,
      "statusId": number,
      "isDeleted": true,
      "createdBy": number,
      "createdDate": Date,
      "modifiedBy": number,
      "modifiedDate": Date
    }>=[]
   constructor(){
        this.address1="";
        this.address2="";
        this.remarks=""
        this.faxNo=""
    }
}

export class ContractorVendorError{
     name:boolean;
     registrationNo:boolean;
     address1:boolean;
     postCode:boolean;
     phoneNo:boolean;
     noOfUserAccess:boolean;
     remarks:boolean;
     contactpersonName:boolean;
     email:boolean;
     designation:boolean;
     stateId:boolean;
     countryId:boolean;
     bpmtypeId:boolean;
       constructor(status){
       this.name=status;
       this.registrationNo=status;
       this.address1=status;
       this.postCode=status;
       this.noOfUserAccess=status;
       this.remarks=status;
       this.designation=status;
       this.contactpersonName=status;
       this.email=status;
       this.countryId=status;
       this.stateId=status;
       this. bpmtypeId= status;
     }
  }