class facilities{
  id:number=0;
  employeeGlobalId:number=0;
  facilityId:number=0;
}

class userRoles{
  loggedInUserId:number=0;
  id:number=0;
  roleId:number=0;
  rolesModulePermissions: Array<rolesModulePermissions>
}

class  rolesModulePermissions{
  loggedInUserId: number=0;
  id:number= 0;
  roleId:number= 0;
  permissionValue:number= 0
}
class competencies
{
  loggedInUserId:  number= 0;
  id: number= 0;
  employeeGlobalId: number=0;
  skillId: number = 0;
  createdOn: Date
}

class  services
{
   id: number=0;
  loggedInUserId:number =0;
  serviceTypeId:number =0
}

class employeeSpecialities 
  {
    id: number = 0;
    employeeGlobalId:number= 0;
    specialityId:number= 0;
    createdBy:number= 0;
    createdDate: Date;
    modifiedBy:number= 0;
    modifiedDate:Date;
  }
export class UserRegisterType{
    
        loggedInUserId: number;
        id:  number;
        userName: string;
        password: string;
        staffName: string;
        genderId: number;
        officeNo: string;
        companyEmail: string;
        mobileNo: string;
        categoryId:  number;
        statusId: number
        designationId: number;
        nationalityId:  number;
        contractorId:  number;
        grade: string;
        customerName:string = "KLCCUH";
        labourCostPerHour:number;
        locationID: number;
        companyName: string;
        isDeleted: boolean;
        userRoles: Array< userRoles>;
        competencies:Array<competencies>;
        services:Array<services>;
        facilities:Array<facilities>;
        employeeSpecialities :Array< employeeSpecialities >;
      
}

export class UserRegisterTypeError{
    staffName:Boolean;
    companyEmail:Boolean;
    mobileNo:boolean;
    categoryId:boolean;
    competencies:boolean;
    locationID:boolean;
    companyName:boolean;
    facilities:boolean;
    userRoles:boolean;
    userName:boolean
  constructor(status){
      this.staffName = status;
      this.companyEmail = status;
      this.mobileNo= status;
      this.categoryId=status;
      this.competencies=status;
      this.locationID=status;
      this.companyName=status;
      this.facilities=status;
      this.userRoles=status;
      this.userName=status
    }
  }