import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  constructor() { }
  @Input() url: string;
  @Input() formate:Array<string>;
  @Input() maxSize:number;
  @Input() type:string="";

  @Output() valueChange = new EventEmitter<string>();
  @Output() extChange=new EventEmitter<string>();
  fileBaseUrl=environment.IMG_LOAD_SERVER;
  error=""
  ngOnInit(): void {
  }
  uploadFile(){
   HttpClient.uploadFile(this.formate,this.maxSize,(err,data)=>{
     if(!err && data && data.data){
       this.error=null
         this.valueChange.emit(data.data);
         this.extChange.emit(data.ext);
     }
     else{
       this.error=err;
       this.valueChange.emit(null)
       this.extChange.emit(null);
     }
   },this.type=="img")
  }
  closeUploadFile(){
    this.error=null;
    this.valueChange.emit(null)
    this.extChange.emit(null);
  }
  ngOnChanges(changes: any): void {
    if(changes.url){
      this.url=changes.url.currentValue
    }
    if(changes.formate){
      this.formate=changes.formate.currentValue
    }
    if(changes.maxSize){
      this.maxSize=changes.maxSize.currentValue
    }
    if(changes.type){
      this.type=changes.type.currentValue
    }
  }
}
