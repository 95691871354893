

<div class="card m-3">
    <div class="card-header" >
        <h5>Maintenance Task</h5>
        <div> 
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
                Download Template 
            </button>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="importopened=true" class="header-right-btn" style="margin-right: 0.5rem;">
               Import
            </button>     
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Maintenance Task
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid 
        [data]="task"
        [column]="column"
        [size]="15"
        [action]="true"
        [deleteFlag]="false"
        [groups]="group"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1224">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           {{dailogTitle}}
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                          <div class="form-group col-10">
                            <label>Checklist<span class="required">*</span></label>
                            <div style="width: 100%;">
                            <kendo-dropdownlist  
                                [data]="model.checklist.filterData" 
                                [value]="model.checklist.selectedObj" 
                                (valueChange)="valuechange('checklist',$event)" 
                                class="form-control"
                                [textField]="'description'" 
                                [valueField]="'id'"  
                            >
                            </kendo-dropdownlist>
                            <kendo-formerror *ngIf="error.checkList">*Required</kendo-formerror>
                            </div>
                        </div>
                        </div>
                        <div class="form-row">
                        <div class="form-group col-10">
                            <label>Section Tittle<span class="required">*</span></label>
                            <div style="width: 100%;">
                            <input kendoTextBox [(ngModel)]="data.DESCRIPTION"  class="form-control"/>
                            <kendo-formerror *ngIf="error.DESCRIPTION">*Required</kendo-formerror>
                           </div>
                        </div>
                      </div>
                      <div class="form-row spare-part-table p-4">
                        <div class="form-group col-12" style="display: flex;justify-content: center;">
                      <table style="border: 1px;width: 100%;">
                          <thead>
                            <tr>
                                <th style="width: 350px;">Checklist Line Item<span class="required">*</span></th>
                                <th style="width: 100px;">Attachment</th>
                                <th style="width: 100px;">Mandatory</th>
                                <th style="width: 150px;">Answer Type <span class="required">*</span></th>
                                <th style="width: 250px;">Answer List<span class="required">*</span></th>
                                <th style="width: 70px;">Action</th>
                            </tr>
                            <tr>
                                <td>
                                    <input kendoTextBox [(ngModel)]="questionObj.item"  class="form-control"/>
                                    <kendo-formerror *ngIf="error.item">*Required</kendo-formerror>
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="questionObj.requiredAttechment" #notification kendoCheckBox />
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="questionObj.mandatoryField" #notification kendoCheckBox />
                                </td>
                                <td>
                                    <kendo-dropdownlist  
                                        [data]="model.taskAndType.filterData" 
                                        [value]="questionObj.ansType" 
                                        (valueChange)="valuechange('answertype',$event)" 
                                        class="form-control"
                                        [textField]="'DESCRIPTION'" 
                                        [valueField]="'ID'" 
                                    >
                                    </kendo-dropdownlist>
                                    <kendo-formerror *ngIf="error.ansType">*Required</kendo-formerror>
                              
                                </td>
                                
                                <td>
                                    <kendo-multiselect
                                    [data]="[]"
                                    [value]="questionObj.ansList"
                                    [allowCustom]="true"
                                    (valueChange)="questionObj.ansList=$event"
                                    [disabled]="questionObj.ansType && questionObj.ansType.ID!==5" 
                                  >
                                  </kendo-multiselect>
                                  <kendo-formerror *ngIf="error.ansList">*Required</kendo-formerror>
                                </td>
                                <td>
                                    <button kendoButton  primary="ansList"  class="header-right-btn" (click)="addItem()">
                                        Add
                                   </button>
                                </td>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let item of questionList;let i=index">
                                <td>
                                    <input kendoTextBox 
                                        [(ngModel)]="item.item"                                      
                                        [disabled]="false" 
                                        class="form-control"
                                    />
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="item.requiredAttechment" #notification kendoCheckBox />
                                </td>
                                <td>
                                    <input type="checkbox" [(ngModel)]="item.mandatoryField" #notification kendoCheckBox />
                                </td>
                                <td>
                                    <kendo-dropdownlist  
                                    [data]="model.taskAndType.filterData" 
                                    [value]="item.ansType" 
                                    (valueChange)="valuechangeArray('answertype',$event,i)" 
                                    class="form-control"
                                    [textField]="'DESCRIPTION'" 
                                    [valueField]="'ID'"
                                    [disabled]="false" 
                                >
                                </kendo-dropdownlist>
                               </td>
                                <td>
                                    <kendo-multiselect
                                    [data]="item.ansListItem"
                                    [value]="item.ansList"
                                    [allowCustom]="true"
                                    (valueChange)="item.ansList=$event"
                                    [disabled]="item.ansType && item.ansType.ID!==5" 
                                  >
                                  </kendo-multiselect>
                                </td>
                                <td>
                                    <button kendoButton  primary="true"  class="header-right-btn" (click)="removeItem(i)">
                                        Delete
                                   </button>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                      <kendo-formerror *ngIf="error.itemLength">*Required</kendo-formerror>
                      </div>
                      </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton  *ngIf="type=='update'"  primary="true" class="mr-2 px-3" (click)="uploadData('add')">
                           Update
                        </button>
                            <button *ngIf="type=='new'" kendoButton   primary="true" class="mr-2 px-3" (click)="saveData('add')">
                                Save & Add New
                            </button>
                          <button *ngIf="type=='new'" kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button *ngIf="type=='new'" kendoButton   primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                          <button  kendoButton   primary="true" class="ml-2 px-3" (click)="opened=false;">
                            Cancel
                        </button>
                      </div>
                  
                    </div>
</kendo-dialog>
<!-- <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [fileUploadType]=1
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog> -->


  <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         Import File
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                   type="doc" 
                   [formate]="['xlsx','csv']" 
                   [fileUploadType]=1
                   (valueChange)=importChange($event)
                   style="width: 100%;"
                   
                >
              </app-file-model>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>