import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class Block{
    CREATEDBY: number
    CREATEDDATE: Date
    FACILITYID: number
    ID: number
    CODE:string
    SHORTNAME:string
    STATUSID:number
    MODIFIEDBY: number
    MODIFIEDDATE: Date
    NAME: string
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string
}

export class BlockObj{
    alldata:Array<Block>=[]
    filterData:Array<Block>=[]
    selectedObj:Block=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Block)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.block)
            .then((result:Array<Block>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}