import { UserShiftDetails,LeaveDetils,UserShiftTypeError,LeaveTypeError} from "src/app/models/user-shift.model";
export let UserShiftDetailsValidation = (data: UserShiftDetails, type) => {
    let error = new UserShiftTypeError(false)
    if (type === "init")
        return error;
    if (!data.EMPLOYEEGLOBALID) {
        error.EMPLOYEEGLOBALID = true
    }
    if (!data.SHIFTID) {
        error.SHIFTID = true
    }
    if (!data.DATETIMEFROM) {
        error.DATETIMEFROM = true
    }
    if (!data.DATETIMETO) {
        error.DATETIMETO = true
    }
   return error;
}

export let UserLeaveDetailsValidation = (data: LeaveDetils, type) => {
    let error = new LeaveTypeError(false)
    if (type === "init")
        return error;
    if (!data.USERID) {
        error.USERID = true
    }
   return error;
}