import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-predective-maintenance',
  templateUrl: './predective-maintenance.component.html',
  styleUrls: ['./predective-maintenance.component.scss']
})
export class PredectiveMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public onTabSelect(e) {
    console.log(e);
  }

}
