
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';

import { sampleProducts } from 'src/app/Data/table';
import { AssetInfoValue, AssetLocationValue, AssetMaintenancesValue, AssetRegistration } from 'src/app/models/asset-reg.model';
import { environment } from 'src/environments/environment';
import {eventListner,EVENT_NAME} from "src/event";
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, getQueryParams, shiftLastDataInto1st } from 'src/Util/Util';
import { AssetLocationValidation, AssetRegValidation } from 'src/validator/assetReg';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"
import { addBlukAssetManagement } from 'src/ApiModel/ImportApi/asset';
import { Model } from 'src/ApiModel/Model';
import { ModelApi } from 'src/ApiModel/const';


@Component({
  selector: 'app-asset-register',
  templateUrl: './asset-register.component.html',
  styleUrls: ['./asset-register.component.scss']
})
export class AssetRegisterComponent implements OnInit {
  public id="asset_reg"
  public opened=false;
  public hideCreatePpmPlanner = false;
  public qrCodeDialogOpen = false;
  public removeDialogOpen = false;
  public urlimg
  public type="";
  public permission:Permission=new Permission();
  public assetCodeValue ;
  public actionsLayout = "normal";
  public dailogTitle="";
  public commictionvalue: Date = new Date(2021, 5, 1, 22);
  public servicestartvalue: Date = new Date(2021, 5, 1, 22);
  public servicestopvalue: Date = new Date(2021, 5, 1, 22);
  public lastworksc: Date = new Date(2021, 5, 1, 22);
  public lastworkusc: Date = new Date(2021, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  public importopened = false;
  public removeAsset_Id;
  public assetManufactureData;
  public assetStandDisable =false;
  public assetTypeData;
  public assetModelData;
  public assetTypeStandId;
  public modelObj = {};
  public manufactureObj = {};
  public assetTypeObj = {};
  public facilityDropDown =[];
  public blockCodeDropDown =[];
  public levelCodeDropDown = [];
  public areaCodeDropDown  = [];
  public locationCodeDropDown =[];
  public subLocationDropDown = [];
  public assetClassDropDown = []
  public assStandDropDown =[]
  public assetStatusDropDown = []
  public allBlocksValue =[];
  public allLevelsValue =[];
  public allAreasValue =[];
  public allLocationValue =[];
  public allSubLocationValue =[];
  public blockName;
  public levelName;
  public areaName;
  public locationName;
  public subLocationName;
  public assetTypeCode;
  public assetTypeDesc;
  public assetModel;
  public assetAge;
  public yearInService;
  public assetManufacture;
  public MODULEHIERARCHYID;
  public assetClassificationValue;
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public AssetClassObj ={}
  public newAssetClassObj ={}
  public facilityObj ={};
  public locationObj ={};
  public subLocationObj ={};
  public contractTypeObj ={};
  public attachAssetRegId;
  public fetchId;
  public statusObj ={};
  public assetQrCodeValue ;
  public locationQrValue;
  public qrCodeGenUrl;
  public imageUrl = environment.backendUrl+`/`;
  public PpmFlag = "No"
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationData: Array<{ code: string; id: number;name: string }>;
  public filterdSubLocationData: Array<{ code: string; id: number;name: string }>;
  public contractTypeDropDown = [];
  public qrcodePrintData=[]
  public model=new Model();
  public onTabSelect(e) {
    console.log(e);
  }



  public column=[
    {field:"assetCode",title:"Asset ID",isFilter:true ,width:200},
    {field:"assetClassification",title:"Asset Classification",isFilter:true ,width:200},
    {field:"assetNumber",title:"Asset No.", isFilter:true ,width:200},
    {field:"serialNo",title:"Serial No", isFilter:true ,width:200},
    {field:"assetTypeCode",title:"Asset Type Code", isFilter:true ,width:200},
    {field:"assetTypeDescription",title:"Asset Type Description", isFilter:true ,width:200},
    {field:"facility",title:"Faciltiy", isFilter:true,width:200},
    {field:"block",title:"Block", isFilter:true,width:200},
    {field:"area",title: " Area Name", isFilter:true,width:200},
    {field:"level",title: " Level", isFilter:true,width:200},
    {field:"location",title:"Location", isFilter:true,width:200},
    {field:"subLocation",title:"Sub Location", isFilter:true,width:200},
    {field:"model",title:"Model", isFilter:true,width:200},
    {field:"manufacture",title:"Manufacturer", isFilter:true,width:200},
    {field:"contractType",title:"Contract Type", isFilter:true,width:200},
    {field:"status",title:"Status", isFilter:true,width:200},
  ]
  public gridData=[]
  public column2=[
    {field:"ProductID",title:"File Type",isFilter:true},
    {field:"ProductName",title:"File Name", isFilter:true},
    {field:"FirstOrderedOn",title:"Attachments", isFilter:true},
    {field:"UnitPrice",title:"Downloads", isFilter:true}
  ]
  public gridData2=sampleProducts;
  submitted = false;
 @Input() assetId: number;
  public assetRegForm: AssetRegistration = new AssetRegistration();
  public  assetInforForm : AssetInfoValue = new AssetInfoValue();
  public  assetLocationForm: AssetLocationValue = new AssetLocationValue();
  public assetMaintenaceForm:AssetMaintenancesValue = new AssetMaintenancesValue();
  error1 = AssetLocationValidation(this. assetLocationForm ,"init")
  error2 =AssetRegValidation(this.assetRegForm ,"init")
  constructor(private formBuilder: FormBuilder,public _notif:NotificationService) { 
    eventListner(EVENT_NAME.edit_column+this.id ,(e)=>{this.editColumn && this.editColumn(e)});
    eventListner(EVENT_NAME.remove_column+this.id,(e)=>{this.editColumn && this.editColumn(e)});
    eventListner(EVENT_NAME.qrCode_attachment+this.id, (e) => { this.qrCodeGeneration && this.qrCodeGeneration(e) })
    this.fetchId=getQueryParams("id");
       
  }
  ngOnDestroy(){
   // this.deleteColumn=null;
    this.editColumn=null;
    this.qrCodeGeneration=null;
    eventListner(EVENT_NAME.edit_column,(e)=>{});
    eventListner(EVENT_NAME.remove_column,(e)=>{});
    eventListner(EVENT_NAME.qrCode_attachment, (e) => {})
   // console.log("component is destroy")
 }
  
  ngOnInit() {
    if(UserModuleObj.fesmsasset_asset){
      this.permission=UserModuleObj.fesmsasset_asset;
    }

    this.model.getData(ModelApi.AssetType,ModelApi.assetModel,ModelApi.manufacture,ModelApi.assetStardantion)
    .then((result)=>{
      console.log("model==>",this.model);
    })
    // this.assetRegForm.contractTypeId=1;
    // this.assetInforForm.statusId= 2;
    Promise.all([
    this.getAssetClass(),
     this.getfacility(),
     this.getAssetManufacture(),
     this.getAssetModel(),
     this.getAssetTypeCode(),
     this. getAssetStatus(),
     this.getContractorTypedata("mm","contractType"),
    ])
      .then((result) => {
         this.bindBlockObj(this.filterdBlockData);
           this.bindLevelObj(this.filterdLevelData);
           this.bindFacilityObj(this.facilityDropDown);
           this.bindAreaObj(this.filterdAreaData);
           this.bindLocationObj(this.filterdLocationData);
           this.bindSubLocationObj(this.filterdSubLocationData);
           this.bindContractObj(this.contractTypeDropDown);
           this.bindStatusObj(this.assetStatusDropDown);
        this.getAssetStandard();
        this.getModuleHierarchYdata("dbo","moduleHierarchy");
        this.getAssetRegistrationData();
       
        if(this.fetchId){
         this.opened = true;
          this.assetStandDisable = true;
          this.assetRegForm.assetStandardizationId = parseInt(this.fetchId)
          this.assetStandValueChange(this.assetRegForm.assetStandardizationId)
        }
    })
   }
  
  // convenience getter for easy access to form fields


  onReset() {
      this.openRoleDailog("new");
  }
  
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
   
      this.error1 = AssetLocationValidation(this. assetLocationForm ,"init")
      this.error2 =AssetRegValidation(this.assetRegForm ,"init")
      this.assetMaintenaceForm.ppmflag = "No"
      this.assetRegForm= new AssetRegistration();
      this.assetInforForm = new AssetInfoValue();
      this.assetLocationForm = new AssetLocationValue();
      this.assetMaintenaceForm = new AssetMaintenancesValue();
      this.assetRegForm = new AssetRegistration();
      let value=  this.assetStatusDropDown.filter((e)=>{
        return  e.name==="Active"
       })
      this.assetInforForm.statusId = value[0].id;
      this.filterdBlockData =[];
      this.filterdLevelData =[];
      this.filterdAreaData =[];
      this.filterdLocationData =[];
      this.filterdSubLocationData =[];
      this.blockName = "";
      this.assetClassificationValue = null
      this.levelName ="";
      this.areaName ="";
      this.locationName ="";
      this.subLocationName ="";
     this.assetTypeCode ="";
      this.assetTypeDesc ="";
      this.assetModel="";
      this.assetAge ="";
     this.yearInService="";
     this.assetManufacture="";

  
       this.dailogTitle="Add Asset Registration"
    }
    if(type=="update"){
      this.dailogTitle="Edit Asset Registration"
    }
    this.opened=true;
  }

 getAssetTypeCode(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetTypes", true, true)
        .then((result: any) => {
          this.assetTypeData = result;
            this.bindAssetTypeObj(result);
           this.bindAssetClassObj(result);
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetModel(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetModels", true, true)
        .then((result: any) => {
          this.assetModelData = result;
          this.bindModelObj(result)
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getAssetManufacture(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetManufacturers", true, true)
        .then((result: any) => {
          this.assetManufactureData = result;
          this.bindManufactureObj(result);
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
        
        if(result.length>0){
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue;
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue;
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }

          this.areaCodeDropDown = this.allAreasValue;
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          if(this.areaCodeDropDown.length>0){
            let locationValue = [];
            locationValue = this.areaCodeDropDown.map((e)=>{
                   return e.areaLocations;
            })
             
            for (let i = 0; i < locationValue.length; i++) {
              for (let j = 0; j < locationValue[i].length; j++) {
                let arr = [];
                arr =  locationValue[i][j];
                this.allLocationValue.push(arr);
              }
            }
            this.locationCodeDropDown = this.allLocationValue;
            this.filterdLocationData =  this.locationCodeDropDown.slice();
            if(this.locationCodeDropDown.length>0){
              let subLocationValue = [];
              subLocationValue = this.locationCodeDropDown.map((e)=>{
                return e.locationSubLocations;
              })
              for(let i =0; i< subLocationValue.length;i++){
                for(let j=0;j< subLocationValue[i].length ;j++){
                   let arr =[];
                   arr = subLocationValue[i][j];
                   this.allSubLocationValue.push(arr);
                }
              }
              this.subLocationDropDown = this.allSubLocationValue;
            
             this. filterdSubLocationData = this.subLocationDropDown.slice();
            }
           
          }

         resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  facilityValueChange(facilityId) {
    this.blockCodeDropDown = this.allBlocksValue.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = this.blockCodeDropDown.slice();
  }
  
  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    this.levelCodeDropDown = this.allLevelsValue.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = this.levelCodeDropDown.slice();
  }
  
  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0]?.name;
    this.areaCodeDropDown = this.allAreasValue.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData = this.areaCodeDropDown.slice();
  }
  
  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
     this.locationCodeDropDown = this.allLocationValue.filter((e)=>{
       return e.levelAreaId == areaId;
     })

     this.filterdLocationData =  this.locationCodeDropDown.slice();
  }

  locationValueChange(locationId){
    let selectLocationValue = this.filterdLocationData.filter((e) => {
      return e.id == locationId;
    });
    this.locationName =selectLocationValue[0].name;
    this.subLocationDropDown = this.allSubLocationValue.filter((e)=>{
      return e.areaLocationId == locationId;
    })
    this.filterdSubLocationData = this.subLocationDropDown.slice();

  }

  subLocValueChange(subLocId){

    let selectedSubLocValue = this.filterdSubLocationData.filter((e)=>{
      return e.id == subLocId;
    })

    this.subLocationName = selectedSubLocValue[0].name;
  }

  bindAssetTypeObj(result) {
    this.assetTypeObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})

    
  }
  bindManufactureObj(result) {
    this.manufactureObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindModelObj(result) {
    this.modelObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

 

  getAssetStatus() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetStatuses", true, true)
        .then((result: any) => {
      
         this.assetStatusDropDown = result;

         
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetStandard(){
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetStandardizations", true, true).then((result: any) => {
      this.assStandDropDown = result.map((e)=>{
         let assetTypeO=this.assetTypeObj[e.assetTypeId] || {};
         let assetClassificationO={}
          if(assetTypeO && assetTypeO.id){
             assetClassificationO=this.newAssetClassObj[assetTypeO.assetClassificationId];
          }
         return {
             id:e.id, 
             name:assetTypeO && assetTypeO.code,
             assetModel:this.modelObj[e.assetModelId],
             assetTypeCode:assetTypeO,
             assetClassification:assetClassificationO,
             assetManufacture:this.manufactureObj[e.assetManufacturerId]
            }
            
        })
       this.assStandDropDown=this.assStandDropDown.filter((d)=>d.assetTypeCode && d.assetTypeCode.id); 

       console.log("asset st=>",this.assStandDropDown,this.newAssetClassObj)
     resolve(result);
      }).catch((e)=>{
        reject(e);
      })
    })
  }

  assetStandValueChange(assetStanId){
  
    HttpClient.get(`/api/Assets/GetAssetStandardizationById?id=${assetStanId}`, true,true)
    .then((result: any) => {
      //this.bindManufactureObj(this.assetManufactureData);
      //this.bindModelObj(this.assetModelData);
      this.assetTypeStandId = result.assetTypeId
      let assetTypeSnadId=   this.assetTypeData.filter((e)=>{
        return e.id===this.assetTypeStandId;
      })
      this.assetRegForm.assetStandardizationId = assetStanId
      this.assetClassificationValue = assetTypeSnadId[0].assetClassificationId;
      this.assetTypeCode = this.assetTypeObj[result.assetTypeId]&& this.assetTypeObj[result.assetTypeId].code;
      this.assetManufacture = this.manufactureObj[result.assetManufacturerId];
      this.assetModel = this.modelObj[result.assetModelId];
      this.assetTypeDesc=this.assetTypeObj[result.assetTypeId] && this.assetTypeObj[result.assetTypeId].description;
      
    })
  }


  getAssetClass(){
   return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Assets/GetAssetClassifications", true,true)
    .then((result: any) => {
     
     this.assetClassDropDown= result
    this.bindClassiObj(result);
      resolve(result)
             }).catch((e)=>{
      reject(e)
    })
   })
  }


 calcDate(date1,date2) {
  var diff = Math.floor(date1.getTime() - date2.getTime());
  var day = 1000 * 60 * 60 * 24;
   let message;
  var days = Math.floor(diff/day);
  var months = Math.floor(days/31);
  var years = Math.floor(months/12);
  message += Math.abs(years) + " years ,"
  message += Math.abs(months) + " months"
  return message
  }


getAssetAge(data){
let today:any = new Date()
let past:any = new Date(data)
let diffInMilliSeconds = Math.abs(past -  today ) / 1000;
const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
diffInMilliSeconds -= hours * 3600;
this.assetInforForm.assetAge = this.calcDate(today,past).slice(9);
}

getYearInService(data){
  let today = new Date()
  let past = new Date(data)
  this.assetInforForm.yearInService = this.calcDate(today,past).slice(9);
  
}

getAssetRegById(){

}

editColumn(data) {
  if (data.action == "edit"){
  if(this.assetMaintenaceForm.ppmflag ==="No"){
    this.hideCreatePpmPlanner = true;
  }
    this.attachAssetRegId = data.dataItem.id
    this.getAssetRegistrationById(data.dataItem.id)
    this.opened = true;
    this.openRoleDailog("update")
   
  
}
else if (data.action == "remove") {
  this.removeAsset_Id = data.dataItem.id
  this.removeDialogOpen = true;
}
}

removeAsset(){
  const params = {
      "loggedInUserId": getCookie(userid),
      "id":  this.removeAsset_Id,
      "employeeGlobalId": getCookie(userid),
      "instance": ""
    };
    HttpClient.fetchData("/api/Assets/DeleteAssetById", "post", params, true,true)
      .then((result:any) => {
        HttpClient.insertAduitLog("Engineering Records","Delete Assets","Delete Assets Engineering Records").then((res:any)=>{
          if (res.isValid == true) {   
          }
        })
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
        this.gridData = this.gridData.filter(x => {
          return x.id != this.removeAsset_Id;
        })
        this.gridData = JSON.parse(JSON.stringify(this.gridData));
        this.removeDialogOpen = false;
      }
      else{
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
      }).catch((error) => {
      });
  }
  onDialogClose(type) {
    if (type == "new") {
      this.opened = false;
    }
    if (type == "attachment") {
      // this.attachmentOpened = false
    }
    if(type=="qrcode"){
      this.qrCodeDialogOpen = false;
    }

    if(type=="importUpdate"){
      this.importopened = false
    }
  }
  convertLocalDateString(d):any{
    if(!d)
       return null;
    function padding(n){
      if(n<10){
          return "0"+n 
      }
      return n;
    }
    let ndate=new Date(d);
    return `${ndate.getFullYear()}-${padding(ndate.getMonth()+1)}-${padding(ndate.getDate())}T${padding(ndate.getHours())}:${padding(ndate.getMinutes())}:${padding(ndate.getSeconds())}`
  }

  onSubmit(){
    this.error1 = AssetLocationValidation(this. assetLocationForm ,"")
    this.error2 =AssetRegValidation(this.assetRegForm ,"")
    this.assetInforForm.commissioningDate=this.convertLocalDateString(this.assetInforForm.commissioningDate);
    this.assetInforForm.serviceStartDate=this.convertLocalDateString(this.assetInforForm.serviceStartDate);
    this.assetInforForm.serviceEndDate=this.convertLocalDateString(this.assetInforForm.serviceEndDate);

    if(!this.error1.areaId &&
        !this.error1.blockId &&
        !this.error1.facilityId &&
        !this.error1.levelId &&
        !this.error1.locationId &&
        !this.error2.assetNumber &&
        !this.error2.assetStandardizationId 
      ){
    this.assetRegForm.id= (this.type=="new")?0:this.assetRegForm.id
        let assetInforArray =[];
        assetInforArray.push(this.assetInforForm);
        let assetLocationArray =[]
        assetLocationArray.push(this.assetLocationForm)
        this.assetRegForm.createdBy = parseInt(getCookie(userid));
        this.assetRegForm.modifiedBy = parseInt(getCookie(userid));
        this.assetRegForm.createdDate = new Date();
        this.assetRegForm.modifiedDate = new Date();
        this.assetRegForm.assetCode="";
        this.assetRegForm.moduleHierarchyId=this.MODULEHIERARCHYID;
        const paramsObj ={
          ...this.assetRegForm,
          assetBpms:[],
          assetInfos:assetInforArray,
          assetLocations:assetLocationArray,
          assetMaintenances:[]

      }

      if (this.type == "new") {
      HttpClient.fetchData(
        "/api/Assets/AddAsset",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Add Assets","Add Assets Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
              this.getAssetRegistrationData()
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getAssetRegistrationData()
          this.submitted = false;
           this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    } 

    if(this.type=="update"){
      HttpClient.fetchData(
        "/api/Assets/UpdateAsset",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Engineering Records","Update Assets","Update Assets Engineering Records").then((res:any)=>{
            if (res.isValid == true) {   
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getAssetRegistrationData()
          this.submitted = false;
         
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }
  }
  }

  getModuleHierarchYdata(schema, tablename){
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
    let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
              return e.NAME ==="Asset Registration"
        })
         
          this.MODULEHIERARCHYID=ModFacArr[0].ID;
       
    });
  }

  getContractorTypedata(schema, tablename){
    return new Promise((resolve, reject) => {HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    
    this.contractTypeDropDown = JSON.parse(result.gridData);
       resolve(result);
    }).catch((e)=>{
      reject(e);
    })
  });
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }


  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindLocationObj(result) {
    this.locationObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }
  bindSubLocationObj(result){
    this.subLocationObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindContractObj(result){
    this.contractTypeObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindStatusObj(result){
    this.statusObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindClassiObj(result){
    this.AssetClassObj  = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
    this.newAssetClassObj=result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {});
  }

  bindAssetClassObj(result){
    this.AssetClassObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {})
  }
  getAssetRegistrationData(){
    let AssetRegistrationData :any=[];
     
  return new Promise((resolve,rejects)=>{
    HttpClient.get(
      "/api/Assets/GetAssets",
      true,
      true
    ).then((result: any) => {
          AssetRegistrationData =  shiftLastDataInto1st(result) ||[];
          // this.bindBlockObj(this.filterdBlockData);
          // this.bindLevelObj(this.filterdLevelData);
          // this.bindFacilityObj(this.facilityDropDown);
          // this.bindAreaObj(this.filterdAreaData);
          // this.bindLocationObj(this.filterdLocationData);
          // this.bindSubLocationObj(this.filterdSubLocationData);
          // this.bindContractObj(this.contractTypeDropDown);
          // this.bindStatusObj(this.assetStatusDropDown);
         
      this.gridData = AssetRegistrationData.map((d,index)=>{
            d["soNo"] = index+1
        if(d.assetLocations.length>0){
          d["block"] = this.blockObj[d.assetLocations[0].blockId];
          d["level"] = this.levelObj[d.assetLocations[0].levelId];
          d["facility"] =  this.facilityObj[d.assetLocations[0].facilityId];
          d["area"] = this.areaObj[d.assetLocations[0].areaId];
          d["location"] =  this.locationObj[d.assetLocations[0].locationId];
          d["subLocation"] = this.subLocationObj[d.assetLocations[0].subLocationId];
          d["contractType"] = this.contractTypeObj[d.contractTypeId];
        }
        if(d.assetInfos.length>0){
          d["status"] = this.statusObj[d.assetInfos[0].statusId]
          d["assetAge"] = d.assetInfos[0].assetAge && d.assetInfos[0].assetAge.replace(',', ''," ","","g")
          d["commissioningDate"] =  d.assetInfos[0].commissioningDate
          d["lifeSpan"] =  d.assetInfos[0].lifeSpan
          d["operatingHours"] =  d.assetInfos[0].operatingHours
          d["serviceEndDate"] =  d.assetInfos[0].serviceEndDate
          d["serviceStartDate"] =  d.assetInfos[0].serviceStartDate
          d["yearInService"] =  d.assetInfos[0].yearInService && d.assetInfos[0].yearInService.replace(',', ''," ","","g")
        }
        let assetClassificationData = d.assetStandardizations;
        this.assetTypeStandId = d.assetStandardizations.assetTypeId;
          let assetTypeSnadId=   this.assetTypeData.filter((e)=>{
               return e.id===this.assetTypeStandId;
      })
        let assetClassificationID = assetTypeSnadId[0] && assetTypeSnadId[0].assetClassificationId;
         this.bindAssetClassObj(this.assetClassDropDown)
              d["assetTypeCode"] =d.assetTypeCode;
              d["manufacture"] = this.manufactureObj[assetClassificationData.assetManufacturerId];
              d["model"] = this.modelObj[assetClassificationData.assetModelId];
              d["assetTypeDescription"]= d.assetTypeDescription;
              d["assetClassification"] = this.AssetClassObj[assetClassificationID]


    //     if(d.assetStandardizationId){
    //       HttpClient.get(`/api/Assets/GetAssetStandardizationById?id=${d.assetStandardizationId}`, true,true)
    //     .then((result: any) => {
    //       let assetClassificationData = result;
    //       this.assetTypeStandId = result.assetTypeId
    //       let assetTypeSnadId=   this.assetTypeData.filter((e)=>{
    //            return e.id===this.assetTypeStandId;
    //   })
    //      let assetClassificationID = assetTypeSnadId[0] && assetTypeSnadId[0].assetClassificationId;
      
    //     // this.bindClassiObj(this.assetClassDropDown)
    //     //  this.bindManufactureObj(this.assetManufactureData);
    //     //  this.bindModelObj(this.assetModelData);
    //       this.bindAssetClassObj(this.assetClassDropDown)
    //           d["assetTypeCode"] =this.assetTypeObj[assetClassificationData.assetTypeId] && this.assetTypeObj[assetClassificationData.assetTypeId].code;
    //           d["manufacture"] = this.manufactureObj[assetClassificationData.assetManufacturerId];
    //           d["model"] = this.modelObj[assetClassificationData.assetModelId];
    //           d["assetTypeDescription"]= this.assetTypeObj[assetClassificationData.assetTypeId] && this.assetTypeObj[assetClassificationData.assetTypeId].description;
    //           d["assetClassification"] = this.AssetClassObj[assetClassificationID]
    //   });
    // }
   
  return d;
           })

      resolve(result);
    }).catch((err)=>{
      rejects(err);
    })
  })
}
     
  getAssetRegistrationById(id){
    HttpClient.get("/api/Assets/GetAssetById?id=" +id, true, true)
    .then((result: any) => {
      let data = result;
      this.assetRegForm.id=data.id
       this.assetRegForm.assetStandardizationId =data.assetStandardizationId;
       if( this.assetRegForm.assetStandardizationId){
        this.assetStandValueChange(this.assetRegForm.assetStandardizationId)
       }
       this.assetRegForm.assetNumber = data.assetNumber;
       this.assetRegForm.assetCode = data.assetCode;
       this.assetRegForm.chasisNo = data.chasisNo;
       this.assetRegForm.contractTypeId = data.contractTypeId;
       this.assetRegForm.engineNo=data.engineNo;
       this.assetRegForm.serialNo = data.serialNo;
       if(data.assetInfos.length>0){
        this.assetInforForm.commissioningDate = new Date(data.assetInfos[0].commissioningDate);
        this.assetInforForm.lifeSpan =  data.assetInfos[0].lifeSpan;
        this.assetInforForm.assetId = data.assetInfos[0].assetId;
        this.assetInforForm.operatingHours =  data.assetInfos[0].operatingHours;
        this.assetInforForm.serviceEndDate = new Date( data.assetInfos[0].serviceEndDate);
        this.assetInforForm.serviceStartDate = new Date( data.assetInfos[0].serviceStartDate);
        this.assetInforForm.yearInService =  data.assetInfos[0].yearInService;
        this.assetInforForm.statusId =  data.assetInfos[0].statusId;
        this.assetInforForm.assetAge =  data.assetInfos[0].assetAge;
       }
    if(data.assetLocations.length>0){
      this.assetLocationForm.areaId = data.assetLocations[0].areaId;
      this.assetLocationForm.assetId = data.assetLocations[0].assetId;
      this.assetLocationForm.blockId = data.assetLocations[0].blockId;
      this.assetLocationForm.facilityId = data.assetLocations[0].facilityId;
      this.assetLocationForm.levelId = data.assetLocations[0].levelId;
      this.assetLocationForm.locationId = data.assetLocations[0].locationId;
      this.assetLocationForm.subLocationId = data.assetLocations[0].subLocationId;
      if (this.assetLocationForm.levelId) {
        let selectLevelValue = this.levelCodeDropDown.filter((e) => {
          return e.id == this.assetLocationForm.blockId;
        });
        if (selectLevelValue) {
          this.levelName = selectLevelValue[0]?.name;
        }
      }
      if ( this.assetLocationForm.areaId) {
        let selectareaValue = this.areaCodeDropDown.filter((e) => {
          return e.id ==  this.assetLocationForm.areaId;
        });
        if (selectareaValue) {
          this.areaName = selectareaValue[0].name;
        }
      }
      if ( this.assetLocationForm.blockId ) {
        let selectBolckValue = this.blockCodeDropDown.filter((e) => {
          return e.id == this.assetLocationForm.blockId ;
        });
        if (selectBolckValue) {
          this.blockName = selectBolckValue[0].name;
        }
      }
      if (this.assetLocationForm.locationId) {
        let selectLocationValue = this.locationCodeDropDown.filter((e) => {
          return e.id == this.assetLocationForm.locationId;
        });
        if (selectLocationValue) {
          this.locationName = selectLocationValue[0].name;
        }
      }
      if (this.assetLocationForm.subLocationId) {
        let selectSubLocValue = this.subLocationDropDown.filter((e) => {
          return e.id == this.assetLocationForm.subLocationId;
        });
        if (selectSubLocValue) {
          this.subLocationName = selectSubLocValue[0].name;
        }
      }
    }

  if(data.assetMaintenances.length>0){
        this.assetMaintenaceForm.assetId = data.assetMaintenances[0].assetId;
        this.assetMaintenaceForm.ppmflag = data.assetMaintenances[0].ppmflag;
        if(this.assetMaintenaceForm.ppmflag==="No"){
          this.hideCreatePpmPlanner = true;
        }
        this.assetMaintenaceForm.ppmcreated = data.assetMaintenances[0].ppmcreated;
        this.assetMaintenaceForm.di = data.assetMaintenances[0].di;
        this.assetMaintenaceForm.lastScheduledWorkOrderNo = data.assetMaintenances[0].lastScheduledWorkOrderNo;
        this.assetMaintenaceForm. lastUnScheduledWorkOrderNo = data.assetMaintenances[0]. lastUnScheduledWorkOrderNo;
        this.assetMaintenaceForm. lastScheduledWorkOrderDate = new Date(data.assetMaintenances[0]. lastScheduledWorkOrderDate);
        this.assetMaintenaceForm.lastUnScheduledWorkOrderDate = new Date(data.assetMaintenances[0].lastUnScheduledWorkOrderDate);
        this.assetMaintenaceForm.  totalDownTime = data.assetMaintenances[0].totalDownTime;
        this.assetMaintenaceForm.cumulativepartsCost = data.assetMaintenances[0].cumulativepartsCost;
        this.assetMaintenaceForm.cumulativeLabourCost = data.assetMaintenances[0].cumulativeLabourCost;
        this.assetMaintenaceForm.cumulativeContractCost = data.assetMaintenances[0].cumulativeContractCost;

    }

    })
  }

  qrCodeGeneration(data){
    if(data.id){
      this.getAssetRegistrationById(data.id)
    }
    let assetInforArray =[];
    assetInforArray.push(this.assetInforForm);
    let assetLocationArray =[]
    assetLocationArray.push(this.assetLocationForm)
    this.assetRegForm.createdBy = parseInt(getCookie(userid));
    this.assetRegForm.modifiedBy = parseInt(getCookie(userid));
    this.assetRegForm.createdDate = new Date();
    this.assetRegForm.modifiedDate = new Date();
    this.assetRegForm.assetNumber ="";
    this.assetRegForm.id= data.id;
    this.assetRegForm.assetCode="";
    this.assetRegForm.contractTypeId = 1;
    this.assetRegForm.moduleHierarchyId=this.MODULEHIERARCHYID;
    const paramsObj ={
      ...this.assetRegForm,
      assetBpms:[],
      assetInfos:assetInforArray,
      assetLocations:assetLocationArray,
      assetMaintenances:[]

  }
  HttpClient.fetchData("/api/MasterData/GenerateQRCodeForAsset", "post", paramsObj , true, true)
    .then((result: any) => {
          if(result===true){
            HttpClient.get(
            `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${data.id}&&moduleHierarchyId=${this.assetRegForm.moduleHierarchyId}`,
              true,
              true
            ).then((result: any) => {
              this.qrCodeGenUrl = result
            })
          }
    });
      this.qrCodeDialogOpen= true;
      let obj={
        data:`${window.location.origin}/mobile?QRCodeType=asset&id=${data.id}`,
        assetCode:data.assetCode,
        assetNo:data.assetNumber 
      }
      this.qrcodePrintData=[obj]
      this.assetQrCodeValue =data.assetCode
      this.locationQrValue = data.facility+`-`+  data.block + `-`+ data.level + `-` + data.area +`-` + data.location + `-` + data.subLocation
    }

    viewQR(){
      window.open(this.imageUrl+this.qrCodeGenUrl)
    }

    import(){
      this.importopened = true
      }
      importUpdate(){
        this.importopened = true
      }
  
      download() {
        let fileName = 'AssetRegistrationData.csv';
        let columnNames = [
          "soNo",
"assetClassification",
"assetCode",
"assetNumber", 
"assetTypeCode",
"assetTypeDescription", 
"area",
"block", 
"chasisNo", 
"commissioningDate", 
"contractType", 
"createdDate", 
"engineNo",
"facility", 
"level", 
"lifeSpan",
"location",
"manufacture", 
"model",
"modifiedDate",
"operatingHours", 
"serialNo", 
"serviceEndDate",
"serviceStartDate",
"status", 
"subLocation",
"yearInService",
"assetAge", 
        ];
        let header = columnNames.join(',');
        let csv = header;
        csv += '\r\n';
      this.gridData.map(c => {
          csv += [
        c["soNo"],
        c["assetClassification"],
        c["assetCode"],
        c["assetNumber"], 
        c["assetTypeCode"],
        c["assetTypeDescription"], 
        c["area"],
       
        c["block"], 
        c["chasisNo"], 
        c["commissioningDate"], 
        c["contractType"], 
        c["createdDate"], 
        c["engineNo"],
        c["facility"], 
        c["level"], 
        c["lifeSpan"],
        c["location"],
        c["manufacture"], 
        c["model"],
        c["modifiedDate"],
        c["operatingHours"], 
        c["serialNo"], 
        c["serviceEndDate"],
        c["serviceStartDate"],
        c["status"], 
        c["subLocation"],
        c["yearInService"],
        c["assetAge"], 
          ].join(',');
          csv += '\r\n';
        })
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      uploadSave(){
        this.getAssetRegistrationData();
        this.importopened = false
      }

      valueChange(event){
        this.type="new"
       let obj = addBlukAssetManagement(
           event,
           this.MODULEHIERARCHYID,
           this.model.assetStardantion.alldata,
           this.assetStatusDropDown,
           this.facilityDropDown,
           this.blockCodeDropDown,
           this.levelCodeDropDown,
           this.areaCodeDropDown,
           this.locationCodeDropDown,
           this.subLocationDropDown,
           this.model.assetType.objectData,
           this.model.assetModel.objectData,
           this.model.manufacture.objectData
           )
       console.log("asset model",obj,event)
       Promise.all(
       obj.map((data)=>{
         return  HttpClient.fetchData(
                    "/api/Assets/AddAsset",
                    "post",
                    data,
                    true,
                    true)
         })).then((result: any) => {
          // if (result.length>0) {
          //   // HttpClient.insertAduitLog("Engineering Records","Add Assets when value change","Update Assets Engineering Records").then((res:any)=>{
          //   //   if (res.isValid == true) {   
          //   //   }
          //   // })
          //   this._notif.show({
          //     content: result.messageKey,
          //     type: { style: "success", icon: true },
          //   });
          //   this.submitted = false;
          //    this.opened = false;
          // } else {
          //   this._notif.show({
          //     content: result.messageKey,
          //     type: { style: "error", icon: true },
          //   });
          // }

          this.getAssetRegistrationData();
          this._notif.show({
            content: "Successfully Save.",
            type: { style: "success", icon: true },
          });
          this.submitted = false;
           this.opened = false;
           this.importopened=false;


        })
      
        // })
      }
      downloadTemplate(){
        window.open("/assets/template/asset.xlsx")
      }
      importDataChange(e){
        console.log(e);
        this.valueChange(e);
       // let r=addBlukAssetManagement(e,6,this.assStandDropDown,this.assetClassDropDown,this)
        // if(e.status){
        //   this.getAssetRegistrationData();
        //   // this.getA
        // }
      }
}

