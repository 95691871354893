import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-file-model',
  templateUrl: './file-model.component.html',
  styleUrls: ['./file-model.component.scss']
})
export class FileModelComponent implements OnInit {

  constructor() { }
  @Input() url: string;
  @Input() formate:Array<string>;
  @Input() maxSize:number;
  @Input() type:string="img";
  @Input() fileUploadType:number

  @Output() valueChange = new EventEmitter<string>();
  fileBaseUrl=environment.IMG_LOAD_SERVER;
  error=""
  ngOnInit(): void {
  }
  uploadFile(){
   HttpClient.importFile(this.formate,this.fileUploadType,(err,data)=>{
     if(!err){
       this.error=null
         this.valueChange.emit(data);
     }
     else{
       this.error=err;
       this.valueChange.emit(null)
     }
   })
  }
  closeUploadFile(){
    this.error=null;
    this.valueChange.emit(null)
  }
  ngOnChanges(changes: any): void {
    if(changes.url){
      this.url=changes.url.currentValue
    }
    if(changes.formate){
      this.formate=changes.formate.currentValue
    }
    if(changes.maxSize){
      this.maxSize=changes.maxSize.currentValue
    }
    if(changes.type){
      this.type=changes.type.currentValue
    }
  }
}
