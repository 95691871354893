import { Component, OnInit } from '@angular/core';
import { ModelApi } from 'src/ApiModel/const';
import { Model } from 'src/ApiModel/Model';
import { AssetClassfication } from 'src/ApiModel/Model/AssetClassification';
import { Status } from 'src/ApiModel/Model/Status';
import { WorkOrder } from 'src/ApiModel/Model/WorkOrder';
import { AssetStandardization, AssetType } from 'src/app/models/asset.model';

@Component({
  selector: 'app-schedule-wo-summery',
  templateUrl: './schedule-wo-summery.component.html',
  styleUrls: ['./schedule-wo-summery.component.scss']
})
export class ScheduleWoSummeryComponent implements OnInit {

  constructor() { }
  public month=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  public model=new Model()
  public gridData=[]
  public workorder:any={
    ppm:[],
    di:[],
    pdm:[],
    month:[]
  }
  public graphCount=[];

  public column=[
    {field:"wo",title:"Work Order No",isFilter:false ,width:200},
    {field:"ts.NAME",title:"Status",isFilter:true ,width:200},
  ]
  public mcolumn=[
    {field:"month",title:"Month",isFilter:false ,width:200},
    {field:"count",title:"Workorder",isFilter:true ,width:200},
  ]
  ngOnInit(): void {
    this.model.getData(ModelApi.WorkerOrder,ModelApi.TicketConsequenceType,ModelApi.ticketstatus,ModelApi.MaintenancePlanner)
    .then((result)=>{
        this.manuculateAssetData(this.model.workOrder.alldata);
     //  console.log(this.model)
    })
    .catch((err)=>{

    })
  }
  manuculateAssetData(assetData:Array<WorkOrder>){
    let statusObj={}
    this.model.ticketStatus.alldata.forEach((d)=>{
      if(d.NAME.toUpperCase()=="ACTIVE" || d.NAME.toUpperCase()=="INACTIVE"){}
      else{
      let obj={
        label:d.NAME,
        ppm:0,
        pdm:0,
        di:0
      }
      statusObj[d.ID]=obj;
    }
    })
    let monthObj={}
    this.month.forEach((dm)=>{
       monthObj[dm]=0;
    })
    let cd=new Date();
   assetData.filter((d)=>d.ppmid).forEach((d)=>{
          let md=new Date(d.createdDate);
          if(cd.getFullYear()===md.getFullYear())
             monthObj[this.month[md.getMonth()]]=monthObj[this.month[md.getMonth()]]+1;
          let tc=this.model.ticketConsequance.objectData[d.ticketConsequenceId];
          let ts=this.model.ticketStatus.objectData[d.ticketStatusId];
          let planner=this.model.planner.objectData[d.ppmid]
          let obj={
            id:d.id,
            wo:d.workOrderNo,
            wsId:d.ticketStatusId,
            ts,
            tc,
            planner
          }
          if(statusObj[d.ticketStatusId]){
          if(planner.MAINTENANCETYPEID==1){
            statusObj[d.ticketStatusId]["ppm"]=statusObj[d.ticketStatusId]["ppm"]+1;
            this.workorder.ppm.push(obj)
          }
          else if(planner.MAINTENANCETYPEID==2){
            this.workorder.pdm.push(obj)
            statusObj[d.ticketStatusId]["pdm"]=statusObj[d.ticketStatusId]["pdm"]+1;
          }
          else if(planner.MAINTENANCETYPEID==3){
            statusObj[d.ticketStatusId]["di"]=statusObj[d.ticketStatusId]["di"]+1;
            this.workorder.di.push(obj)
          }
        }
    })
   //this.graphCalculate(this.gridData);
   for(let i in statusObj){
    // if(statusObj[i].label.toLowerCase()!="active" || statusObj[i].label.toLowerCase()!="inactive" )
     this.graphCount.push(statusObj[i]);
   }
   this.graphCount=  this.graphCount.map((d)=>{
    d["color"]="#92d050"
    return d;
   }) //[...this.graphCount];
   this.workorder.ppm= [...this.workorder.ppm]
   //this.workorder={...this.workorder};
   this.workorder.month=new Array(12)
   let monthIndex=this.month.reduce((acc,d,i)=>{
    acc[d]=i
    return acc;
   },{})
  for(let m in monthObj){
    this.workorder.month[monthIndex[m]]={
      month:m,
      count:monthObj[m],
      color:"#92d050"
    }
  }
   console.log(monthObj,this.workorder.month);
  }
  graphCalculate(data:any){
    
  }
  filterChangedHandler(e){

  }
}
