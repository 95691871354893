<div class="card m-3">
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <div style="display: flex; justify-content: flex-start;">
            <h5>{{template.title}}</h5>
             <div style="margin-left: 10px;width: 200px;">
                <!-- <kendo-dropdownlist 
                        [data]="listValue" 
                        [filterable]="true"
                        class="form-control"
                        [value]="selectedListValue"
                        textField="name"
                        valueField="id"
                        (selectionChange)="changeListValue($event)"
                  >
                </kendo-dropdownlist> -->
             </div>
        </div>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            {{template.add_btn}}
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid
            [data]="gridData"
            [column]="gridColumn"
            [size]="5"
        ></app-table-grid>
    </div>
  </div>


  <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="580">
    <kendo-dialog-titlebar>
      <div *ngIf="type=='new'" style="font-size: 18px; line-height: 1.3em;">
         {{template.add_dailog_title}}
      </div>
      <div *ngIf="type=='update'" style="font-size: 18px; line-height: 1.3em;">
        {{template.edit_dailog_title}}
     </div>
    </kendo-dialog-titlebar>
              <div *ngFor="let colItem of inputForm;let i=index">
                    <div *ngIf="colItem.type=='text'" class="form-row" >
                        <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                            <label>{{colItem.label || colItem.name}}</label>
                            <div style="width:100%">
                              <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/>
                              <kendo-formerror *ngIf="colItem.error">*required</kendo-formerror>
                              </div>
                        </div>
                    </div>
                    <div *ngIf="colItem.type=='diable'" class="form-row" >
                      <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                          <label>{{colItem.label || colItem.name}}</label>
                          <input kendoTextBox [(ngModel)]="colItem.value" disabled class="form-control"/>
                      </div>
                  </div>
                    <div *ngIf="colItem.type=='list'" class="form-row" >
                        <div class="form-group" style="display: grid;grid-template-columns: 40% 60%;width:100%;">
                            <label>{{colItem.label}}</label>
                            <kendo-dropdownlist 
                                [data]="listData[colItem.table]" 
                                class="form-control"
                                [value]="colItem.value"
                                [textField]="colItem.props.textField"
                                [valueField]="colItem.props.valueField"
                                (selectionChange)="formDropDownChange($event,i,colItem)"
                                [filterable]="colItem.props.valueField"
                             >
                            </kendo-dropdownlist>
                            <!-- <input kendoTextBox [(ngModel)]="colItem.value" class="form-control"/> -->
                        </div>
                    </div>

                </div>
              <!-- <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Block Name</label>
                    <input kendoTextBox formControlName="block_name" class="form-control"/>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Block Code</label>
                    <input kendoTextBox formControlName="block_code" class="form-control"/>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Short Name</label>
                    <input kendoTextBox formControlName="short_name" class="form-control"/>
                </div>
            </div>
                 <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Status</label>
                    <kendo-dropdownlist formControlName="status"  [data]="status" class="form-control">
                    </kendo-dropdownlist>
                </div>
            </div> -->
         <kendo-dialog-actions [layout]="actionsLayout">
            <button kendoButton (click)="onDialogClose()">Cancel</button>
            <button kendoButton  primary="true" (click)="submitFrom()">
                Save
            </button>
        </kendo-dialog-actions>
  </kendo-dialog>


  <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        {{template.delete_dailog_title}}
      </div>
    </kendo-dialog-titlebar>
    <p>{{template.delete_description}}</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeLookup()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>