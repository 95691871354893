
<div class="card m-3">
    <div class="card-header" >
        <h5>PDM Planner</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
             PDM Planner
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <div class="form-row">
            <div class="form-group col-5">
                   <label>Year</label>
                    <kendo-dropdownlist [data]="year_data" class="form-control">
                    </kendo-dropdownlist>
         </div>
        </div>
        <app-table-grid
        [data]="areagrid"
        [column]="column2"
        [size]="5"
        [action]="false"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened"  (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
     
        <kendo-tabstrip-tab title="PDM Planner for Asset"  [selected]="true">
            <ng-template kendoTabContent>
                <app-pdm-asset></app-pdm-asset>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="PDM Planner for Location">
            <ng-template kendoTabContent>
                <app-pdm-on-location></app-pdm-on-location>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
    <!-- <form [formGroup]="maintenence_status" >
        <div class="form-row">
            <div class="form-group col-5">
                <label>Work Order No.</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Work Order Date</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Work Order Type</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Consequence  Category</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Part No.</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
           
            <div class="form-group col-5">
                <label>Part Description</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Quantity</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Total Downtime</label>
                <input kendoTextBox class="form-control" disabled/>
            </div>
        </div>
        
    </form> -->
<!--<kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true">
            Save
      </button>
    </kendo-dialog-actions> -->
  </kendo-dialog>
