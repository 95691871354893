import { MaintenacePlanner , MaintenacePlannerObj } from "src/ApiModel/Model/MaintancePlanner";
import { MaintenacePlannerDetail , MaintenacePlannerDetailObj } from "src/ApiModel/Model/MaintancePlannerDetail";
import { ServiceType } from "src/ApiModel/Model/ServicesType";
import { User } from "src/ApiModel/Model/User";
import { ContractorVendor } from "src/ApiModel/Model/Contractor";
import {MaintenaceFrequence} from "src/ApiModel/Model/MaintanceFrequency"
import { AssetRegistration } from "src/ApiModel/Model/Assets";
import { Level } from "src/ApiModel/Model/BlockLevel";
import { AreaLocation } from "src/ApiModel/Model/AreaLocation";
import {Checklist} from "src/ApiModel/Model/CheckList"
import { convertListToObj} from "src/ApiModel/Util"
import {Year} from "src/ApiModel/Model/Year"
import {Month} from "src/ApiModel/Model/Month"
import { userid } from "src/Util/const";
import { getCookie } from "src/Util/Util";
class ImportPlanner{
    planner:MaintenacePlanner;
    plannerDetail:Array<MaintenacePlannerDetail>
    ScheduleType:number
    month:Array<number>
    day:Array<string>
    date:Array<number>
    weekend:Array<number>
    importData(){
        let month=[];
        let date=[];
        let day=[];
        let week=[]
        if(this.ScheduleType==1){
           month=this.month
           date=this.date;
        }
        else if(this.ScheduleType==2){
          month=this.month;
          day=this.day;
          week=this.weekend
        }
        return new Promise((resolve,reject)=>{
            this.planner.saveData()
            .then((result:any)=>{
                let plannerId=result.id;
                this.plannerDetail=this.plannerDetail.map((d)=>{
                    d.MAINTENANCEPLANNERID=plannerId;
                    return d;
                })
                Promise.all([
                    this.plannerDetail.map((p)=>{
                        let workorderObj={
                            "maintenancePlannerId":plannerId ,
                            "assetId": p.ASSETID,
                            "locationId": p.LOCATIONID,
                            "userId": p.USERID,
                            "bpmid": p.BPMID,
                            "createdBy": getCookie(userid),
                            "createdDate": new Date(),
                            "modifiedBy": getCookie(userid),
                            "modifiedDate": new Date()
                          }
                          p.addWorkOrder(this.planner.MAINTENANCETYPEID,workorderObj,month,date,week,day)
                          return p.saveData();
                    })
                ])
                .then((result:any)=>{
                    resolve(result);
                })
                .catch((err)=>{
                 resolve(false)
                })
            })
        })
    }
}
function convertLocalDateString(d):any{
    function pading(n){
        if(n<=9)
         return "0"+n;
        return n;
    }
    let ndate=new Date(d);
    return `${ndate.getFullYear()}-${pading(ndate.getMonth()+1)}-${pading(ndate.getDate())}T00:00:00`
  }

export function importPlannerDetailForAsset(
    data:Array<{Facility:string,"PPM Name":string,"Service Type":string,"Checklist Name":string,"Level Code":string,"Location Code":string,Frequency:string,"First Date":string,"Expired Date":string,"Assignee":string,"Assign To":string,"Asset No":string,"Schedule Type":string,"Month":string,"Week":string,"Day":string,"Date":string}>,
    serviceTypeList:Array<ServiceType>,
    assetList:Array<AssetRegistration>,
    levelList:Array<Level>,
    locationList:Array<AreaLocation>,
    frequancyList:Array<MaintenaceFrequence>,
    assigneeList:Array<User>,
    assignToList:Array<ContractorVendor>,
    checkList:Array<Checklist>,
    planneerType:number,
    scheduleTypeList:Array<any>,
    monthList:Array<any>,
    daysList:Array<any>
    ){
    let serviceObj=convertListToObj(serviceTypeList,"NAME","ID",true);
    // let levelListObj=convertListToObj(levelList,"CODE","ID",true);
    // let locationObj=convertListToObj(locationList,"CODE","ID",true);
    let assetListObj=convertListToObj(assetList,"assetNumber","id",true);
    let frequancyObj=convertListToObj(frequancyList,"DESCRIPTION","ID",true);
    let assineeObj=convertListToObj(assigneeList,"staffName","id",true);
    let assignToObj=convertListToObj(assignToList,"name","id",true);
    let checkListObj=convertListToObj(checkList,"description","id",true)
    let scheduleObj=convertListToObj(scheduleTypeList,"name","id",true)
    let monthObj=convertListToObj(monthList,"NAME","ID",true);
    let dayObj=convertListToObj(daysList,"name","id",true);
    let pData=data.map((d)=>{
        let p=new MaintenacePlanner();
        p.STATUSID=1;
        p.CHECKLISTID=checkListObj[d["Checklist Name"]?.trim().toLowerCase()];
        p.EXCLUDEWEEKEND=planneerType!=2;
        p.FREQUENCYID=frequancyObj[d["Frequency"]?.trim().toLowerCase()];
        p.MAINTENANCETYPEID=planneerType;
        p.NAME=d["PPM Name"];
        p.PPMENDDATE=convertLocalDateString(d["Expired Date"])
        p.PPMSTARTDATE=convertLocalDateString(d["First Date"])
        p.SERVICETYPEID=serviceObj[d["Service Type"]?.trim().toLowerCase()]
        p.YEAR=new Date().getFullYear().toString();
        let assetGroup=d["Asset No"]?.trim().toLowerCase().split(",");
        let pDetail=new Array<MaintenacePlannerDetail>();
        pDetail= assetGroup.map((a)=>{
            let pd=new MaintenacePlannerDetail();
            pd.ASSETID=assetListObj[a.trim()];
            pd.BPMID=assignToObj[d["Assign To"]?.trim().toLowerCase()];
            pd.USERID=assineeObj[d.Assignee?.trim().toLowerCase()];
            return pd;
       })
      let iPlanner=new ImportPlanner();
      iPlanner.planner=p;
      iPlanner.plannerDetail=pDetail;
      if(planneerType==2){
        let month:any=d?.Month.toString()?.split(",") || []
        let week:any=d?.Week.toString()?.split(",") || []
        let day:any=d?.Day.toString()?.split(",").map((dy)=>dayObj[dy]) || [];
        let date:any=d?.Date.toString()?.split(",") || [];
        iPlanner.ScheduleType=scheduleObj[d["Schedule Type"]?.trim().toLowerCase()];
        iPlanner.month=month;
        iPlanner.date=date;
        iPlanner.weekend=week;
        iPlanner.day=day;
      }
      else{
        iPlanner.ScheduleType=null;
        iPlanner.month=[];
        iPlanner.date=[];
        iPlanner.weekend=[];
        iPlanner.day=[];
      }

      return iPlanner;
    })

    console.log("import planner==>",pData)
    return new Promise((resolve,reject)=>{
        Promise.all([
            pData.map((d)=>{
                return d.importData();
            })
        ])
        .then((result)=>{
            resolve(result)
        })
        .catch((err)=>{
            resolve(false)
        })
    })
}



export function importPlannerDetailForLocation(
    data:Array<{Facility:string,"PPM Name":string,"Service Type":string,"Checklist Name":string,"Level Code":string,"Location Code":string,Frequency:string,"First Date":string,"Expired Date":string,"Assignee":string,"Assign To":string,"Asset No":string,"Schedule Type":string,"Month":string,"Week":string,"Day":string,"Date":string}>,
    serviceTypeList:Array<ServiceType>,
    assetList:Array<AssetRegistration>,
    levelList:Array<Level>,
    locationList:Array<AreaLocation>,
    frequancyList:Array<MaintenaceFrequence>,
    assigneeList:Array<User>,
    assignToList:Array<ContractorVendor>,
    checkList:Array<Checklist>,
    planneerType:number,
    scheduleTypeList:Array<any>,
    monthList:Array<any>,
    daysList:Array<any>
    ){
    let serviceObj=convertListToObj(serviceTypeList,"NAME","ID",true);
     let levelListObj=convertListToObj(levelList,"CODE","ID",true);
     let locationObj=convertListToObj(locationList,"CODE","ID",true);
    //let assetListObj=convertListToObj(assetList,"assetNumber","id",true);
    let frequancyObj=convertListToObj(frequancyList,"DESCRIPTION","ID",true);
    let assineeObj=convertListToObj(assigneeList,"staffName","id",true);
    let assignToObj=convertListToObj(assignToList,"name","id",true);
    let checkListObj=convertListToObj(checkList,"description","id",true)
    let scheduleObj=convertListToObj(scheduleTypeList,"name","id",true)
    let monthObj=convertListToObj(monthList,"NAME","ID",true);
    let dayObj=convertListToObj(daysList,"name","id",true);
    let pData=data.map((d)=>{
        let p=new MaintenacePlanner();
        p.STATUSID=1;
        p.CHECKLISTID=checkListObj[d["Checklist Name"]?.trim().toLowerCase()];
        p.EXCLUDEWEEKEND=true;
        p.FREQUENCYID=frequancyObj[d["Frequency"]?.trim().toLowerCase()];
        p.MAINTENANCETYPEID=planneerType;
        p.NAME=d["PPM Name"];
        p.PPMENDDATE=convertLocalDateString(d["Expired Date"])
        p.PPMSTARTDATE=convertLocalDateString(d["First Date"])
        p.SERVICETYPEID=serviceObj[d["Service Type"]?.trim().toLowerCase()]
        p.YEAR=new Date().getFullYear().toString();
        let assetGroup=d["Asset No"]?.trim().toLowerCase().split(",");
        let pDetail=new Array<MaintenacePlannerDetail>();

        let levelCodes:Array<number>=d["Level Code"]?.split(",")?.map((l)=>{
            return levelListObj[l?.trim()?.toLowerCase()]
        })
        let locationCode:Array<string>=d["Location Code"]?.split(",");
        let locationGroup:Array<AreaLocation>=locationList.filter((dl)=>{
            return levelCodes.filter((lv)=>lv==dl.BLOCKLEVELID).length>0 && locationCode.filter((lo)=>lo?.toLowerCase()==dl.CODE?.toLowerCase()).length>0
        })
        pDetail= locationGroup.map((a)=>{
            let pd=new MaintenacePlannerDetail();
            //pd.ASSETID=assetListObj[a.trim()];
            pd.LOCATIONID=a.ID;
            pd.BPMID=assignToObj[d["Assign To"]?.trim().toLowerCase()];
            pd.USERID=assineeObj[d.Assignee?.trim().toLowerCase()];
            return pd;
       })
      let iPlanner=new ImportPlanner();
      iPlanner.planner=p;
      iPlanner.plannerDetail=pDetail;
      if(planneerType==2){
        let month:any=d?.Month.toString()?.split(",") || []
        let week:any=d?.Week.toString()?.split(",") || []
        let day:any=d?.Day.toString()?.split(",").map((dy)=>dayObj[dy]) || [];
        let date:any=d?.Date.toString()?.split(",") || [];
        iPlanner.ScheduleType=scheduleObj[d["Schedule Type"]?.trim().toLowerCase()];
        iPlanner.month=month;
        iPlanner.date=date;
        iPlanner.weekend=week;
        iPlanner.day=day;
      }
      else{
        iPlanner.ScheduleType=null;
        iPlanner.month=[];
        iPlanner.date=[];
        iPlanner.weekend=[];
        iPlanner.day=[];
      }

    //   let month:any=d.Month?.toString()?.split(",") || []
    //   let week:any=d.Week?.toString()?.split(",") || []
    //   let day:any=d.Day?.toString()?.split(",").map((dy)=>dayObj[dy]) || [];
    //   let date:any=d.Date?.toString()?.split(",") || [];
    //   iPlanner.ScheduleType=scheduleObj[d["Schedule Type"]?.trim().toLowerCase()];
    //   iPlanner.month=month;
    //   iPlanner.date=date;
    //   iPlanner.weekend=week;
    //   iPlanner.day=day;
      return iPlanner;
    })

    console.log("import planner==>",pData)
    return new Promise((resolve,reject)=>{
        Promise.all([
            pData.map((d)=>{
                return d.importData();
            })
        ])
        .then((result)=>{
            resolve(result)
        })
        .catch((err)=>{
            resolve(false)
        })
    })
}


