import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EditorResizableOptions } from "@progress/kendo-angular-editor";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule";
import HttpClient from "src/Util/ApiHandling";
import { NotificationService } from "@progress/kendo-angular-notification";
@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  public actionsLayout = "normal";
  public formGroup: FormGroup;
  public permission:Permission=new Permission();
  constructor(private _notif: NotificationService) { }
  public resizable: boolean | EditorResizableOptions = {
    minWidth: 300,
    minHeight: 650,
    maxWidth: 600,
    maxHeight: 1051,
  };

  ngOnInit(): void {
    if(UserModuleObj.level)
    this.permission=UserModuleObj.level;
    this.formGroup = new FormGroup({
      termsandconditions: new FormControl(''),
    });
    this.getTermsandconditions();
  }
getTermsandconditions()
{
  HttpClient.get(
    `/api/MasterData/GetSystemSettingId?id=1`,
    true,
    true
  ).then((result: any) => {
    this.formGroup.get("termsandconditions").setValue(result.body);
  });
}
onSubmit()
  {
    let paramsObj={
      "id": 1,
      "titile": "Terms & Conditions",
      "body": this.formGroup.get("termsandconditions").value,
      "description": '',
      "createdOn": new Date(),
      "cretedBy": 1,
      "modifiedBy": 0,
      "modifiedDate":  new Date(),
      "isActive": true
    }
    HttpClient.fetchData(
      "/api/MasterData/UpdateSystemSetting",
      "post",
      paramsObj,
      true,
      true
    ).then((result: any) => {
      if (result.isValid == true) {
        HttpClient.insertAduitLog("System Setting","Save Terms & Conditions","save Terms & Conditions in System Setting").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
      } else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
      }
    });
  }

}
