<form class="my-4">
    <div class="form-row">
        <div class="form-group col-5">
            <label>Work Order No.</label>
            <input kendoTextBox class="form-control" disabled/>
        </div>
        <div class="form-group col-5">
            <label>Work Order Date/Time</label>
            <input kendoTextBox class="form-control" disabled/>
        </div>
    </div>

    <div class="px-4">
        <app-table-grid
            [data]="areagrid"
            [column]="column2"
            [size]="5"
            [action]="false"
        ></app-table-grid>
    </div>


   
</form>