<div class="card m-3"  >
	<div class="card-header">
		<h5>Reset Password</h5>
	</div>
	<div class="card-body register-form-body"   >
		<div class="mt-3">
			<app-table-grid 
			   [data]="contectGrid" 
			   [column]="contectColumn" 
			   [size]="15" 
			   [action]="true"
			   [deleteFlag]="false"
			   [editFlag]="false" 
			   [unlockFlag]="true"
			   (valueChange)="contectGrid=$event"
			 >
			</app-table-grid>
		</div>
		</div>
	</div>