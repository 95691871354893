import { Component, OnInit } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {TaskItem,TaskItemObj} from "src/ApiModel/Model/TaskItem"
import { eventListner, EVENT_NAME } from 'src/event';
@Component({
  selector: 'app-checklist-task-item',
  templateUrl: './checklist-task-item.component.html',
  styleUrls: ['./checklist-task-item.component.scss']
})
export class ChecklistTaskItemComponent implements OnInit {

  constructor() { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public data:TaskItem=new TaskItem();
  public taskItemObj:TaskItemObj=new TaskItemObj();
  public task:any=[]
  public column=[
    {field:"checkListObj.checkListNo",title:"Checklist No",isFilter:true,width:200},
    {field:"taskObj.DESCRIPTION",title:"Task", isFilter:true,width:200},
    {field:"DESCRIPTION",title:"Task Item", isFilter:true,width:200},
    {field:"answerTypeObj.DESCRIPTION",title:"Answer Type", isFilter:true,width:200},
    {field:"answerListObj.NAME",title:"Anser List", isFilter:true,width:200},
  ]
  ngOnInit(): void {
    this.model.getData(ModelApi.ChekList,ModelApi.MaintenaceTask,ModelApi.TaskAnswerList,ModelApi.TaskAnswerType)
    .then((result)=>{
      this.model.maintenaceTask.filterData=[];
      this.getData();
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
     if(type==="new"){
      this.reset();
     }
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.data.DESCRIPTION
      this.model.checklist.selectedObj=data;
      this.model.maintenaceTask.filterData=this.model.maintenaceTask.alldata.filter((d)=>{
           return data.id===d.CHECKLISTID
      })
    }
    else if(type==='answertype'){
      this.model.taskAndType.selectedObj=data;
    }
    else if(type==="answerlist"){
      this.model.taskAnsList.selectedObj=data;
      console.log(data);
    }
    else if(type=="task"){
      this.model.maintenaceTask.selectedObj=data;
    }
  }
  saveData(){
    if(this.type==="new"){
      this.data.TASKID=this.model.maintenaceTask.selectedObj?.ID;
      this.data.ANSWERTYPEID=this.model.taskAndType.selectedObj?.ID;
      this.data.TASKANSWERLISTID=this.model.taskAnsList.selectedObj?.ID;
       this.data.saveData()
       .then((result)=>{
            console.log("save",result)
            this.getData()
            this.opened=false;
            this.reset()
           // this.data=new TaskItem();
       })
       .catch((err)=>{
          console.log("err",err)
       })
    }
    else if(this.type=="update"){
      this.data.TASKID=this.model.maintenaceTask.selectedObj?.ID;
      this.data.ANSWERTYPEID=this.model.taskAndType.selectedObj?.ID;
      this.data.TASKANSWERLISTID=this.model.taskAnsList.selectedObj?.ID;
      this.data.updateData()
      .then((result)=>{
           console.log("save",result)
           this.getData()
           this.opened=false;
           this.reset()
      })
      .catch((err)=>{
         console.log("err",err)
      })
    }
  }
  reset(){
    this.model.checklist.selectedObj=null;
    this.model.maintenaceTask.selectedObj=null;
    this.model.taskAndType.selectedObj=null;
    this.model.taskAnsList.selectedObj=null;
    this.data=new TaskItem();
  }

  getData(){
     this.taskItemObj.getData()
    .then((result)=>{
      this.task=this.taskItemObj.alldata.map((d)=>{
        d["taskObj"]=this.model.maintenaceTask.objectData[d.TASKID];
        d["answerTypeObj"]=this.model.taskAndType.objectData[d.ANSWERTYPEID]
        d["answerListObj"]=this.model.taskAnsList.objectData[d.TASKANSWERLISTID]
        d["checkListObj"]=this.model.checklist.objectData[d["taskObj"].CHECKLISTID]
        return d;
       })
    })
  }
  editColumn(d){
    this.data=new TaskItem();
     //this.data.ID=d.dataItem.ID;
     let temp=d.dataItem;
     this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
     this.model.checklist.selectedObj=temp["checkListObj"];
     this.model.maintenaceTask.selectedObj=temp["taskObj"]
     this.model.taskAndType.selectedObj=temp["answerTypeObj"]
     this.model.taskAnsList.selectedObj=temp["answerListObj"]
     this.data.ID=temp["ID"]
     this.data.DESCRIPTION=temp["DESCRIPTION"]
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }
}
