import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { shiftLastDataInto1st } from 'src/Util/Util';
import { UserModuleObj,Permission } from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-location-qr-code-printing',
  templateUrl: './location-qr-code-printing.component.html',
  styleUrls: ['./location-qr-code-printing.component.scss']
})
export class LocationQrCodePrintingComponent implements OnInit {
  public printBtnObj={
    status:false,
    id:1
  }
  public opened = false;
  public removeDialogOpen = false;
  public qrCodeDialogOpen = false;
  public permission:Permission=new Permission();
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public checkedArray =[];
  public blockCodeDropDown = [];
  public levelCodeDropDown = [];
  public areaCodeDropDown = [];
  public facilityDropDown =[];
  public statusDropDown = [];
  public allBlocksValue = [];
  public allLevelsValue = [];
  public allAreasValue = [];
  public loationIncharge =[];
  public companyRe =[]
  public statusObj = {};
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public companyRObj = {}
  public facilityObj ={};
  public facilityAllObj={};
  public imageUrl = environment.backendUrl+`/`;
  public qrCodeGenUrl =[];
  public assetLocationHierarchyId
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationInc: Array<{ name: string; id: number }>;
  public filterdCompanyRe: Array<{ name: string; id: number }>;
 
  public isunique=false;
  public uniqueList = [];
  public blockName ;
  public levelName;
  public areaName;
  public statusValue = true;
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  submitted = false;
  
  public column = [
    { field: "FACILITY", title: "Facility", isFilter: true,width:160 },
    { field: "BLOCKCODE", title: "Block Code", isFilter: true,width:140 },
    { field: "LEVELCODE", title: "Level Code", isFilter: true,width:140  },
    { field: "AREACODE", title: "Area Code", isFilter: true,width:140 },
    { field: "NAME", title: "Location Name", isFilter: true ,width:160},
    { field: "CODE", title: "Location Code", isFilter: true ,width:160},
    { field: "STATUS", title: "Status", isFilter: true ,width:140},
    { field: "START_SERVICE_DATE", title: "Active From Date", isFilter: true,width:140 },
    { field: "STOP_SERVICE_DATE", title: "Active To Date", isFilter: true,width:140 },
    { field: "AUTHORIZEDPERSON", title: "Authorized Person", isFilter: true , width:160},
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true , width:160},
    { field: "last_update_on", title: "Last Updated On", isFilter: true , width:160},
  ];
 
  public gridData = [];
  qrcodePrintData: { data: string; assetCode: any; assetNo: any; location: string; }[];
 
  constructor() {
    eventListner(EVENT_NAME.  checkbox_column, (e) => { this.getLocationCheckedValue(e) });
  }
  ngOnInit(): void {
    if(UserModuleObj.qr_code){
      this.permission=UserModuleObj.qr_code;
    }
    Promise.all([
      this.getfacility(),
      this. getAllUser(),
      this.getStatus("dbo", "status"),
    ]).then((e) => {
      this.getLocationData("dbo", "arealocation");
      this.getModuleHierarchYdata("dbo","moduleHierarchy")
    });
    
    // new Date(dateStr).toISOString()
}

  


  getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {
          if(result.length>0){
            this.facilityAllObj=result.reduce((acc,d)=>{
                        acc[d.id]=d;
                        return acc;
            },{})
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue;
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue;
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }
          this.areaCodeDropDown = this.allAreasValue;
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  facilityValueChange(facilityId) {
    this.blockCodeDropDown = this.allBlocksValue.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = this.blockCodeDropDown.slice();
  }

  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    this.levelCodeDropDown = this.allLevelsValue.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = this.levelCodeDropDown.slice();
  }

  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
    this.areaCodeDropDown = this.allAreasValue.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData = this.areaCodeDropDown.slice();
  }

  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
  }

  handleBlockFilter(value) {
    this.filterdBlockData = this.blockCodeDropDown.filter(
      (s) => s.code.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleLevelFilter(value) {
    this.filterdLevelData = this.levelCodeDropDown.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  handleAreaFilter(value) {
    this.filterdAreaData = this.areaCodeDropDown.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  
  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindCompanyRep(result){
    this.companyRObj = result.reduce((acc,d)=>{
      acc[d.id] = d.name;
      return acc;
    },{})
  }
  getLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      console.log("location",result)
      let LocationData = [];
      LocationData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindStatusObj(this.statusDropDown);
      this.bindBlockObj(this.filterdBlockData);
      this.bindLevelObj(this.filterdLevelData);
      this.bindFacilityObj(this.facilityDropDown);
      this.bindAreaObj(this.filterdAreaData);
      this.bindCompanyRep(this.filterdCompanyRe);
      this.gridData = LocationData.map((d,index) => {
        d["SNo"] = index+1
        d["LOCATIONCODE"] = d.CODE;
        d["LOCATIONNAME"] = d.NAME;
        d["STATUS"] = this.statusObj[d.STATUSID];
        d["BLOCKCODE"] = this.blockObj[d.BLOCKID];
        d["LEVELCODE"] = this.levelObj[d.BLOCKLEVELID];
        d["FACILITY"] = this.facilityObj[d.FACILITYID];
        d["AREACODE"] =this.areaObj[d.LEVELAREAID];
        d["AUTHORIZEDPERSON"] = this.companyRObj[d.COMPANYREPRESENTATIVE];
        d["START_SERVICE_DATE"] = this.dateFormating(d.STARTSERVICEDATE);
        if(d.STOPSERVICEDATE==null){
          d["STOP_SERVICE_DATE"] ="";
        }
        else{
          d["STOP_SERVICE_DATE"] = this.dateFormating(d.STOPSERVICEDATE)
        }
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE)
        return d;
      });

      console.log("location",this.gridData)
    });
  }



  getAllUser(){
    return new Promise((resolve, reject) => {
      HttpClient.get(
       "/api/Users/GetAllUser",
        true,
        true
      )
        .then((result: any) => {
          let data = result;
          let LocInc;
          let CompanyRe;
           LocInc = data.filter((e)=>{
            return e.categoryId==3
          })
          this.loationIncharge = LocInc.map((e)=>{
            return {id:e.id,name:e.staffName}
          })
          this.filterdLocationInc =this.loationIncharge.slice()
           CompanyRe = data.filter((e)=>{
            return e.categoryId==1
          })
            this.companyRe= CompanyRe.map((e)=>{
            return {id:e.id,name:e.staffName}
          })
          this.filterdCompanyRe =  this.companyRe.slice();
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  handleCompanyFilter(value){
    this.filterdCompanyRe = this.companyRe.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  handleLocationFilter(value){
    this.filterdLocationInc = this.loationIncharge.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

 

  download() {
    let fileName = 'LocationData.csv';
    let columnNames = ["SNo","FACILITY","BLOCKCODE","LEVELCODE", "AREACODE", "LOCATIONCODE","LOCATIONNAME","SHORTNAME","LOCATIONINCHARGE","START_SERVICE_DATE","STOP_SERVICE_DATE","STATUS","NAMEMODIFIEDBY"];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    console.log("facility");
    this.gridData.map(c => {
      csv += [c["SNo"],c["FACILITY"],c["BLOCKCODE"],c["LEVELCODE"],c ["AREACODE"], c["LOCATIONCODE"],c["LOCATIONNAME"],c["SHORTNAME"],c["LOCATIONINCHARGE"],c["START_SERVICE_DATE"],c["STOP_SERVICE_DATE"],c["STATUS"],c["NAMEMODIFIEDBY"]].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

 

getModuleHierarchYdata(schema, tablename){
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
    
  let ModuleHieracrhyData = JSON.parse(result.gridData)
    let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
            return e.NAME ==="Location"
      })
        this.assetLocationHierarchyId = ModFacArr[0].ID
     
  });
}
closePrintModel(){
  this.opened=false;
  this.printBtnObj.status=false;
}
getLocationCheckedValue(data){
    
  if(data.status==true){
    this.checkedArray.push(data.data);
  }
  else if(data.status==false){
        this.checkedArray = this.checkedArray.filter((e)=>{
                 return e.id != data.data.id
        })
  }
  //  Promise.all(
  //   this.checkedArray.map((e)=>{
  //    return   HttpClient.get(
  //          `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${e.id}&&moduleHierarchyId=${ this.assetLocationHierarchyId }`,
  //            true,
  //            true
  //          )
  //  })).then((result: any) => {
  //              this.qrCodeGenUrl = result;
  //              console.log(  this.qrCodeGenUrl)
      
  //           })
  
          }

          openPrintModel(){
                console.log("locationdata=>",this.checkedArray);
                this.qrcodePrintData=this.checkedArray.map((d)=>{
                  let fobj=this.facilityAllObj[d.FACILITYID];
                  let obj={
                    data:`${window.location.origin}/mobile?QRCodeType=location&id=${d.ID}`,//JSON.stringify({QRCodeType:"Location",QRCodeObjectId:d.ID}),
                    assetCode:d.assetCode,
                    assetNo:d.assetNumber,
                    email:fobj.email,
                    phoneNo:fobj.phoneNo,
                    location:d.LEVELCODE + `-` + d.LOCATIONNAME 
                  }
                  console.log("facility obj ", this.facilityAllObj[d.FACILITYID])
                  return obj;
                })
                this.opened=true;
                setTimeout(()=>{
                  this.printBtnObj.status=true;
                  this.printBtnObj.id=new Date().getTime()
                },2000)  
          }
        
}
