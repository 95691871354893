import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import {ModelApi,PLANNER} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {MaintenacePlanner} from "src/ApiModel/Model/MaintancePlanner"
import { eventListner, EVENT_NAME } from 'src/event';
import { pading,convertDateFormate } from 'src/ApiModel/Util';

class PlannerError{
  name:boolean=false;
  serviceType:boolean=false;
  checklist:boolean=false;
  year:boolean=false;
  freq:boolean=false;
  firstDate:boolean=false;
  lastDate:boolean=false;
  status:boolean=false;
  constructor(){
    this.name=false;
    this.serviceType=false;
    this.checklist=false;
    this.year=false;
    this.freq=false;
    this.firstDate=false;
    this.lastDate=false;
    this.status=false;
  }
}
@Component({
  selector: 'app-ppm-planner',
  templateUrl: './add-ppm-planner.component.html',
  styleUrls: ['./add-ppm-planner.component.scss']
})
export class PpmPlannerComponent implements OnInit {
  @Input() id=0;
  constructor(public _notif:NotificationService) { 
    eventListner(EVENT_NAME.edit_column, (e) => { 
      this.editColumn && this.editColumn(e) 
    });
    eventListner(EVENT_NAME.remove_column, (e) => { 
      this.deleteColumn && this.deleteColumn(e) 
    });
  }
  ngOnDestroy(){
     this.deleteColumn=null;;
     this.editColumn=null;
    // console.log("component is destroy")
  }
  public format = "dd/MM/yyyy";
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Task"
  public model:Model=new Model();
  public data=new MaintenacePlanner();
  public uiText=PLANNER[this.id]
  public validiationRequired=new PlannerError();
  public task:any=[]
  public scheduleData={
    type:[
        {id:1,name:"Monthly - Date"},
        {id:2,name:"Monthly - Day"},
    ],
    date:[],
    day:[{id:1,name:"Sun"},{id:2,name:"Mon"}],
    week:[],
    month:[]
  }

  public scheduleInfo={
    type:null,
    month:[],
    date:[],
    week:[],
    day:[]
  }
  public column=[
    {field:"NAME",title:"Name", isFilter:true,width:200},
    {field:"checkListObj.checkListNo",title:"Checklist No",isFilter:true,width:200},
    {field:"yearObj.NAME",title:"Year", isFilter:true,width:200},
    {field:"frequencyObj.DESCRIPTION",title:"Frequency", isFilter:true,width:200},
    {field:"startDate",title:"First Date", isFilter:true,width:200},
    {field:"endDate",title:"Expired date", isFilter:true,width:200},
    {field:"statusObj.NAME",title:"Status", isFilter:true,width:200},
  ]
  ngOnInit(): void {
     console.log("compoenet is active")
     this.uiText=PLANNER[this.id]
     for(let i=1;i<31;i++){
       this.scheduleData.date.push({id:i,name:i})
       if(i<=5){
         this.scheduleData.week.push({id:i,name:i})
       }
     }
    this.model.getData(
      ModelApi.ChekList,
      ModelApi.ServicesType,
      ModelApi.MaintenanceFrequency,
      ModelApi.ChekList,
      ModelApi.Year,
      ModelApi.Status,
      ModelApi.month
      )
    .then((result)=>{
      this.scheduleData.month=this.model.month.alldata;
      console.log("sfsdf",this.model)
     // this.model.maintenaceFrequence.selectedObj.DESCRIPTION
      this.getData();
      if(this.id==3) // 3 is for daily infection
      {
        this.model.maintenaceFrequence.selectedObj=this.model.maintenaceFrequence.alldata.find((d)=>d.DESCRIPTION.toLowerCase()==="daily")
        this.model.maintenaceFrequence.filterData=this.model.maintenaceFrequence.alldata.filter((d)=>d.DESCRIPTION.toLowerCase()==="daily")
      }
      else{
        this.model.maintenaceFrequence.filterData=this.model.maintenaceFrequence.alldata.filter((d)=>d.DESCRIPTION.toLowerCase()!=="daily")
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
     this.dailogTitle="Add "+PLANNER[this.id]?.title;
     this.reset();
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
    else if(type=="service"){
      this.model.service.selectedObj=data;
    }
    else if(type=="year"){
      this.model.year.selectedObj=data;
    }
    else if(type=="maintenaceFrequence"){
      this.model.maintenaceFrequence.selectedObj=data;
    }
    else if(type=="status"){
      this.model.status.selectedObj=data;
    }
  }

  checkValidation(){
    let error=false;
    if(!this.model.checklist.selectedObj?.id){
      error=true;
      this.validiationRequired.checklist=true
    }
    if(this.id===1 && !this.model.maintenaceFrequence.selectedObj?.ID){
      error=true;
      this.validiationRequired.freq=true;
    }
    if(!this.model.service.selectedObj?.ID){
      error=true;
      this.validiationRequired.serviceType=true;
    }
    if(!this.model.status.selectedObj?.ID){
      error=true;
      this.validiationRequired.status=true;
    }
    if(!this.model.year.selectedObj?.NAME){
      error=true;
      this.validiationRequired.year=true;
    }
    if(!this.data.NAME){
      error=true;
      this.validiationRequired.name=true;
    }
    if(!this.data.PPMSTARTDATE){
      error=true;
      this.validiationRequired.firstDate=true;
    }
    if(!this.data.PPMENDDATE){
      error=true;
      this.validiationRequired.lastDate=true;
    }
    return error;
  }
  convertLocalDateString(d){
    let ndate=new Date(d);
    return `${ndate.getFullYear()}-${pading(ndate.getMonth()+1)}-${pading(ndate.getDate())}T00:00:00`
  }
  saveData(){
    if(!this.checkValidation()){
      let startDate:any=this.convertLocalDateString(this.data.PPMSTARTDATE);
      let endDate:any=this.convertLocalDateString(this.data.PPMENDDATE);

      this.data.CHECKLISTID=this.model.checklist.selectedObj?.id;
      this.data.FREQUENCYID=this.model.maintenaceFrequence.selectedObj?.ID;
      this.data.MAINTENANCETYPEID=this.id;
      this.data.SERVICETYPEID=this.model.service.selectedObj?.ID;
      this.data.STATUSID=this.model.status.selectedObj?.ID;
      this.data.YEAR=this.model.year.selectedObj?.NAME;
      this.data.PPMSTARTDATE=startDate;
      this.data.PPMENDDATE=endDate;
        if(this.type==="new"){
          console.log(this.data);
          this.data.saveData()
          .then((result)=>{
            this._notif.show({
              content: "Successfully saved.",
              type: { style: "success", icon: true },
            });
                this.getData()
                this.opened=false;
          })
          .catch((err)=>{
            this._notif.show({
              content: "Fail",
              type: { style: "error", icon: true },
            });
              console.log("err",err)
          })
        }
        else if(this.type=="update"){
          this.data.updateData(this.data.ID)
          .then((result)=>{
            this._notif.show({
              content: "Successfully saved.",
              type: { style: "success", icon: true },
            });
               console.log("save",result)
               this.getData()
               this.opened=false;
          })
          .catch((err)=>{
            this._notif.show({
              content: "Fail",
              type: { style: "error", icon: true },
            });
             console.log("err",err)
          })
        }
      }
  }
  reset(){
      this.data=new MaintenacePlanner();
      this.model.service.selectedObj=null
     this.model.checklist.selectedObj=null;
     this.model.status.selectedObj=null;
     this.model.year.selectedObj=null;
     this.model.maintenaceFrequence.selectedObj=null;
  }

  getData(){
    this.model.getData(ModelApi.MaintenancePlanner)
    .then((result)=>{
      this.task=this.model.planner.alldata.filter((d)=>d.MAINTENANCETYPEID==this.id).map((d)=>{
        if(d.ID){
        d["serviceObj"]=this.model.service.objectData[d.SERVICETYPEID];  
        d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
        d["statusObj"]=this.model.status.objectData[d.STATUSID];
        d["frequencyObj"]=this.model.maintenaceFrequence.objectData[d.FREQUENCYID];
        d["yearObj"]=this.model.year.objectData[d.YEAR];
        d["startDate"]=convertDateFormate(d.PPMSTARTDATE)
        d["endDate"]=convertDateFormate(d.PPMENDDATE);
        }
        return d;
       })
    })
  }
  editColumn(d){
    console.log(d.dataItem)
    let dd=d.dataItem;
     this.data.ID=dd.ID;
     this.data.NAME=dd.NAME;
     this.model.service.selectedObj=dd["serviceObj"]
     this.model.checklist.selectedObj=dd["checkListObj"];
     this.model.status.selectedObj=dd["statusObj"];
     this.model.year.selectedObj=dd["yearObj"];
     this.model.maintenaceFrequence.selectedObj=dd["frequencyObj"];
     this.data.PPMSTARTDATE=new Date(dd["PPMSTARTDATE"]);
     this.data.PPMENDDATE=new Date(dd["PPMENDDATE"]);
     this.data.EXCLUDEWEEKEND=dd["EXCLUDEWEEKEND"]
    //  this.data.NAME
    //  this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
    //  this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result:any)=>{
      if(result.message){
        this._notif.show({
          content: "PPM/PDM/DI cannot be deleted because it is tied to asset or location",
          type: { style: "error", icon: true },
        });
    }
    else{
      this._notif.show({
        content: "Successfully Delete.",
        type: { style: "success", icon: true },
      });
      this.getData();
    }
    })
    .catch((err)=>{
      this._notif.show({
        content: "PPM/PDM/DI cannot be deleted because it is tied to asset or location",
        type: { style: "error", icon: true },
      });
    })
 }
 handleFilter(key,e){
   if(key==="checklist"){
   this.model.checklist.filterData= this.model.checklist.alldata.filter(
      (s) => s.description.toLowerCase().indexOf(e.toLowerCase()) !== -1
    );
   }
 }
 uploadChange(event){
 // this.type="new"
//  let obj = addBlukContractManagement(event,this.contractCodeDropDown,this.contractorTypeDropDown)
//    console.log("contractor code",obj,event) 
//  obj.forEach((data)=>{
//     this.saveSparePartUpdate(JSON.stringify(data))

//   })
}
}
