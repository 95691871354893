import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
export class TicketReassignment{
    ID: number
    TICKETID:number
    TICKETSTATUSID:number;
    REASSIGNMENTDATE:Date;
    REASON:string;
    USERREASON:string;
    BPMREASION:string;
    ASSIGNEE:number;
    BPMID:number;
    NEWASSIGNEE:number;
    NEWBPMID:number;
    CREATEDDATE:Date;
    MODIFIEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDBY:number;
    PROPOSEDDATE:Date | any;
    TARGETDATE:Date |any;
    async saveData(){
        this.ID=0;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.ticketreassignment,this,"new")
    }	
   async updateData(id){
        this.ID=id;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.ticketreassignment,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.ticketreassignment,obj);
    }
}
export class TicketReassignmentObj{
    alldata:Array<TicketReassignment>=[]
    filterData:Array<TicketReassignment>=[]
    selectedObj:TicketReassignment=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:TicketReassignment)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.ticketreassignment)
            .then((result:Array<TicketReassignment>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
             
                reject(err)
            })
        })

    }
}