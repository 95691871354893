import { Component, OnInit } from '@angular/core';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"
import { environment } from 'src/environments/environment';
import {convertDateFormate, saveLookup,removeLookup} from "src/ApiModel/Util"
import { WorkOrderError } from 'src/ApiModel/Model/WorkOrder';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { NotificationService } from '@progress/kendo-angular-notification';
import { getCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';

@Component({
  selector: 'app-rise-work-request',
  templateUrl: './rise-work-request.component.html',
  styleUrls: ['./rise-work-request.component.scss']
})
export class RiseWorkRequestComponent implements OnInit {
 //attechment data
 public fileData=null;
 public extentionFile=null;
  public viewOpen=false;
  public actionsLayout = "normal";
  public attechmentDoc:AttechmentObj=new AttechmentObj();
  public attechForm:Attechment=new Attechment();
  public permission:Permission=new Permission();
  public parentId=-1;
  public moduleId=-1;
  public attechId=-1;
  public imageUrl = environment.backendUrl;
  public error:WorkOrderError=new WorkOrderError()
//model data
public removeDialogOpen=false;
  public opened=false;  
  public currentTab=1;
  public dailogTitle="add work order";
  public isUpdate = false;
  public type=""
  public tempUpdateObj:any={}
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public workOrderType={
    assets:{name:"assest",id:1,title:"Assets"},
    location:{name:"location",id:2,title:"Location"}
  }
// grid column filed 
  public columnField={
    work_order_no:"workOrderNo",
    consequance:"ticketConsequence.name",
    asset:"asset",
    location:"location",
    service:"service",
  } 
//grid data
public column2=[
  {field:"requestNo",title:"Request No",isFilter:true,width:200},
  // {field:"c_2",title:"Work Order Date", isFilter:true,width:200},
  {field:"workOrderTypeObj.name",title:"Work Order Type", isFilter:true,width:200},
  {field:"consequanceObj.NAME",title:"Consequence  Category", isFilter:true,width:200},
  // {field:"assetDataObj.serialNo",title:"Asset No.", isFilter:true,width:200},
  {field:"locationObj.NAME",title:"Location of Asset", isFilter:true,width:200},
  {field:"serviceObj.NAME",title:"Service Type", isFilter:true,width:200},
  // {field:"c_8",title:"Checklist ID", isFilter:true,width:200},
  // {field:"c_9",title:"Checklist Name",isFilter:true,width:200},
  // {field:"assetDataObj.name",title:"Asset Description", isFilter:true,width:200},
  // {field:"c_11",title:"Model", isFilter:true,width:200},
  // {field:"c_12",title:"Manufacturer", isFilter:true,width:200},
  // {field:"c_13",title:"Contract Type", isFilter:true,width:200},
  {field:"assigneeObj.staffName",title:"Assignee", isFilter:true,width:200},
  {field:"assignToObj.name",title:"Assign To", isFilter:true,width:200},
  {field:"modifiedDate_formate",title:"Work Order Date", isFilter:true,width:200},
   {field:"ticketStatusObj.NAME",title:"Status", isFilter:true,width:200}
];
public areagrid=[
  
];
public sparePartColumn=[
  {field:"partNo",title:"Part No.",isFilter:true,width:150},
    {field:"partDescription",title:"Part Description ", isFilter:true,width:150},
    {field:"binNo",title:"Bin No.", isFilter:true,width:150},
    {field:"qoh",title:"Quantity on hand (QOH)", isFilter:true,width:150},
    {field:"facilityObj.NAME",title:"Facility Name", isFilter:true,width:150},
    {field:"facilityObj.FACILITYCODE",title:"Facility Code", isFilter:true,width:150}
];
public spareGridData=[]
///form  data
  public facilityObj={
      alldata:[],
      filterData:[],
      selectedObj:null,
      objectData:{}
  }
  public blockObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public levelObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public areaObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public locationObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public serviceObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public assetDataObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public ticketStatusObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public assigineeObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }

  public assignToObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public chargeAbleObj={
    alldata:[],
    filterData:[
      {id:1,name:"Yes"},
      {id:2,name:"No"}
    ],
    selectedObj:null,
    objectData:{}
  }

  public workOrderTypeObj={
    alldata:[],
    filterData:[
       {id:1,name:"Routine Maintenance"},
       {id:2,name:"Corrective Maintenance"},
       {id:3,name:"Reactive Maintenance"}
    ],
    selectedObj:null,
    objectData:{}
  }
  public consequanceObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }
  public description="";
  public attechmentColumn = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true }
  ]
  public documentFormate=[{id:"pdf",name:"pdf"},{id:"doc",name:"doc"}];
  public attachmentOpened: boolean=false;
  constructor(private _notif:NotificationService ) { 
    eventListner(EVENT_NAME.attachment_column, (e) => { this.attachementColumn(e) })
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column,(e)=>{this.deleteColumn(e)});
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
      this.deleteAttechment(e);
   })
   eventListner(EVENT_NAME.download_attechment,(e)=>{
     this.downloadAttechment(e)
   })
   eventListner(EVENT_NAME.view_column,(e)=>{this.viewColumn(e)});
  }
  asset_classi = ['Select'];
  attachementColumn(data:any){
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.REFERENCEID=data.id;


  this.attechError={URL:false,FILENAME:false}

// this.attechForm.EMPLOYEEGLOBALID=data.id;
this.attechId=data.id;
this.attechmentDoc=new AttechmentObj();
this.attechmentDoc.getData(data.id,this.moduleId)
.then((result:any)=>{
  this.attachmentOpened = true;
  this.dailogTitle = "Add Attachment"
  this.isUpdate = false;
})
.catch((err)=>{
   this.attechmentDoc.alldata=[]
})
  }
  downloadAttechment(data){
    window.open(environment.IMG_LOAD_SERVER+data.URL)
  }
  deleteAttechment(d:any){
     this.attechForm.deleteData(d.dataItem)
     .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d1,i)=>i!==d.rowIndex);
     })
     .catch((err)=>{
       console.log(err)
     })
  }
  viewColumn(data){
    this.viewOpen=true;
    this.spareGridData=[];
    this.getSparePartRequest(data.id)
    .then((result:any)=>{
      this.spareGridData=result.map((d)=>{
            d["facilityObj"]=this.facilityObj.objectData[d.facilityId];
            return d;
      })
      console.log("view =>",this.spareGridData);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  ngOnInit(): void {
    if(UserModuleObj.work_order){
      this.permission=UserModuleObj.work_order;
    }
    Promise.all([
      this.getLoockup("dbo", "facility"),
      this.getLoockup("dbo", "block"),
      this.getLoockup("dbo", "blocklevel"),
      this.getLoockup("dbo", "levelarea"),
      this.getLoockup("dbo","ServicesType"),
      this.getAsset(),
      this.getAssignTo(),
      this.getAssignne(),
      this.getLoockup("PMC","TicketConsequenceType"),
      this.getLoockup("dbo", "arealocation"),
      this.getLoockup("pmc","ticketstatus")
    ])
    // this.getLoockup("dbo", "facility")
    .then((result:any)=>{
      //facility
      this.getModuleHierarchYdata("dbo","moduleHierarchy"),
         this.facilityObj.alldata=result[0];
         this.facilityObj.filterData=result[0];
         this.facilityObj.selectedObj={}
         this.facilityObj.objectData=this.facilityObj.alldata.reduce((acc,d)=>{
                    acc[d.ID]=d;
                    return acc;
         },{})
      //block
          this.blockObj.objectData=result[1].reduce((acc,d)=>{
            acc[d.ID]=d;
            return acc;
        },{})
            this.blockObj.alldata=result[1];
      //blocklevel
      this.levelObj.objectData=result[2].reduce((acc,d)=>{
        acc[d.ID]=d;
        return acc;
     },{})
         this.levelObj.alldata=result[2];

      //arealocation
      this.areaObj.objectData=result[3].reduce((acc,d)=>{
        acc[d.ID]=d;
        return acc;
     },{})
         this.areaObj.alldata=result[3];
      //ServicesType

      this.serviceObj.objectData=result[4].reduce((acc,d)=>{
        acc[d.ID]=d;
        return acc;
     },{})
         this.serviceObj.alldata=result[4];
         this.serviceObj.filterData=[]


      //asset
      this.assetDataObj.objectData=result[5].reduce((acc,d)=>{
        acc[d.id]=d;
        return acc;
     },{})
      this.assetDataObj.alldata=result[5];
      this.assetDataObj.filterData=result[5];
     //assin to
     this.assignToObj.objectData=result[6].reduce((acc,d)=>{
      acc[d.id]=d;
      return acc;
   },{})
     this.assignToObj.alldata=result[6]
     this.assignToObj.filterData=result[6];

     //assignee

     this.assigineeObj.objectData=result[7].reduce((acc,d)=>{
      acc[d.id]=d;
      return acc;
   },{})
    this.assigineeObj.alldata=result[7];
    this.assigineeObj.filterData=result[7].filter((e)=>{
      return e.categoryId==1;
    })
    console.log(" this.assigineeObj.filterData", this.assigineeObj.filterData)
     //ticket consequance
     this.consequanceObj.objectData=result[8].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
    this.consequanceObj.alldata=result[8];
    this.consequanceObj.filterData=[]
//level area
this.locationObj.objectData=result[9].reduce((acc,d)=>{
  acc[d.ID]=d;
  return acc;
},{})
   this.locationObj.alldata=result[9];
   console.log("start")
   console.log(this.levelObj,this.areaObj,this.locationObj)
   console.log("end")
 this.ticketStatusObj.alldata=result[10];
 this.ticketStatusObj.filterData=result[10]
 this.ticketStatusObj.objectData=result[10].reduce((acc,d)=>{
              acc[d.ID]=d;
              return acc;
},{})
    //call work order
    this.getWorkOrder()
    })
    .catch((err)=>{
      console.log(err)
    })
    //this.getWorkOrder()
    this.workOrderTypeObj.objectData=this.workOrderTypeObj.filterData.reduce((acc,d)=>{
      acc[d.id]=d;
      return acc;
   },{})
   this.chargeAbleObj.objectData=this.chargeAbleObj.filterData.reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})

  }

  getLoockup(schema, tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
          let temp = JSON.parse(result.gridData)
          resolve(temp)
       })
       .catch((err)=>{
          reject(err)
       })
    })
  }

  getAsset(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/Assets/GetAssets",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  getAssignTo(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/BPM/GetBpms",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

  getAssignne(){
    return new Promise((resolve,reject)=>{
      HttpClient.get("/api/Users/GetAllUser",true,true)
      .then((result)=>{
        resolve(result)
      })
      .catch((err)=>{
        reject(err);
      })
    })
  }

 
  valuechange(type,data){
    if(type=='facility'){
      this.facilityObj.selectedObj=data
      this.blockObj.filterData=this.blockObj.alldata.filter((d)=>d.FACILITYID==data.ID)
      this.blockObj.selectedObj={};
      this.levelObj.selectedObj={};
      this.areaObj.selectedObj={};
      this.locationObj.selectedObj={};
      this.facilityObj={...this.facilityObj}      
    }
    else if(type=="block"){
        this.blockObj.selectedObj=data
        this.levelObj.filterData=this.levelObj.alldata.filter((d)=>d.BLOCKID==data.ID)
        this.levelObj.selectedObj={};
        this.areaObj.selectedObj={};
        this.locationObj.selectedObj={};
        this.blockObj={...this.blockObj}      
    }
    else if(type==="level"){
      this.levelObj.selectedObj=data
      this.areaObj.filterData=this.areaObj.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
      this.areaObj.selectedObj={};
      this.locationObj.selectedObj={};
      this.areaObj={...this.areaObj}      
    }
    else if(type==="area"){
      this.areaObj.selectedObj=data
      this.locationObj.filterData=this.locationObj.alldata.filter((d)=>d.LEVELAREAID==data.ID)
      this.locationObj.selectedObj={};
      this.locationObj={...this.locationObj}      
    }
    else if(type==="location"){
      this.locationObj.selectedObj=data
    }
    else if(type==="workOrderType"){
      this.workOrderTypeObj.selectedObj=data;
      this.consequanceObj.filterData=this.consequanceObj.alldata.filter((d)=>d.TICKETTYPEID==data.id)
    }
    else if(type==="consequences"){
      this.consequanceObj.selectedObj=data;
    }
    else if(type=="assignee"){
     this.assigineeObj.selectedObj=data;
    }
    else if(type=="assignTo"){
      this.assignToObj.selectedObj=data;
    }
    else if(type=="service"){
      this.serviceObj.selectedObj=data;
    }
    else if(type==="assets"){
      this.assetDataObj.selectedObj=data;
    }
    else if(type==="ticketStatus"){
      this.ticketStatusObj.selectedObj=data;
    }
    else if(type==="chargeable"){
      this.chargeAbleObj.selectedObj=data;
    }
  }
  padding(n){
    if(n<10)
      return "0"+n;
    return n;
  }
  saveData(){
    this.attechForm=new Attechment();
    this.attechForm.EMPLOYEEGLOBALID=1;
    this.attechForm.FILENAME=new Date().getTime()+""
    this.attechForm.FILECONTENTS=""
    this.attechForm.FILETYPE=""
    this.attechForm.MODULEHIERARCHYID=this.moduleId;
    this.attechForm.REFERENCEID=-1
    this.attechForm.URL=""
    this.error=new WorkOrderError();
    let d=new Date();
    let df=`${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}T${this.padding(d.getHours())}:${this.padding(d.getMinutes())}:${this.padding(d.getSeconds())}.${d.getMilliseconds()}Z`
    let obj={
      "id": 0,
      "userId": parseInt(getCookie(userid)),
      "ticketStatusId": 1,
      "ticketConsequenceId": 0,
      "description": this.description,
      "serviceTypeId": 0,
      "referenceId": 0,
      "referenceType": "",
      "assignee": 0,
      "bpmid": 0,
      "createdDate":df,
      "createdBy": parseInt(getCookie(userid)),
      "modifiedDate": df,
      "modifiedBy":  parseInt(getCookie(userid)),
      "defectPhoto":"",
      "chargeable":null,
      "acceptanceDate":df,
      "TicketTypeID":3,
      "RequestSourceID":3,
      "ConvertToWorkOrder":false
    }
    let error=false;
    if(this.fileData){
      let index=this.fileData.search(",");
      this.fileData= this.fileData.substring(index+1);
      this.attechForm.FILECONTENTS=this.fileData;
      this.attechForm.FILETYPE=this.extentionFile;
      //obj.defectPhoto=this.fileData
    }
    else{
      error=true;
      this.error.defactPhoto=true
    }
    if(this.chargeAbleObj.selectedObj && this.chargeAbleObj.selectedObj.id){
     obj.chargeable=this.chargeAbleObj.selectedObj.id;
    }
    else{
      this.error.chargable=true
      error=true;
    }
      if(this.locationObj.selectedObj && this.locationObj.selectedObj.ID){
        obj.referenceId=this.locationObj.selectedObj.ID;
        obj.referenceType=this.workOrderType.location.name;
      }
      else{
        error=true;
        this.error.referenceId=true
      }
    if(this.serviceObj.selectedObj && this.serviceObj.selectedObj.ID){
      obj.serviceTypeId=this.serviceObj.selectedObj.ID;
    }
    else{
      error=true;
      this.error.serviceTypeId=true;
    }
    if(true || this.consequanceObj.selectedObj && this.consequanceObj.selectedObj.ID){
       obj.ticketConsequenceId=7;
    }
    else{
      this.error.ticketConsequenceId=true;
      error=true;
    }
    if(this.assigineeObj.selectedObj && this.assigineeObj.selectedObj.id){
      obj.assignee=this.assigineeObj.selectedObj.id;
    }
    else{
      error=true;
      this.error.assignee=true;
    }
    if(this.assignToObj.selectedObj && this.assignToObj.selectedObj.id){
      obj.bpmid=this.assignToObj.selectedObj.id
    }
    else{
      error=true;
      this.error.bpmid=true;
    }
    if(true || this.ticketStatusObj.selectedObj && this.ticketStatusObj.selectedObj.ID){
      obj.ticketStatusId=1
    }
    else{
      error=true;
    }
    if(this.facilityObj.selectedObj && this.facilityObj.selectedObj.ID){

    }
    else{
      this.error.facility=true;
      error=true;
    }

    if(this.areaObj.selectedObj && this.areaObj.selectedObj.ID){

    }
    else{
      this.error.area=true;
      error=true;
    }

    if(this.blockObj.selectedObj && this.blockObj.selectedObj.ID){

    }
    else{
      this.error.block=true;
      error=true;
    }
    if(this.levelObj.selectedObj && this.levelObj.selectedObj.ID){

    }
    else{
      this.error.level=true;
      error=true;
    }


    if(this.description){

    }
    else{
      this.error.description = true;
      error = true;
    }
    
    if(!error){
        if(this.type==="new"){
          obj.id=0;
          obj["workOrderNo"]= new Date().getTime().toString()+""
          console.log("workorder",obj)
          this.addWorkOrder(obj)
        }
        else if(this.type==="update"){
          obj.id=this.tempUpdateObj.id;
          this.updateWorkOrder(obj)
        }
    }
  }
  

  addWorkOrder(obj){
   HttpClient.fetchData("/api/WorkOrder/AddWorkOrder","post",obj,true,true)
   .then((result:any)=>{
    if(result.isValid==true){
      this.attechForm.REFERENCEID=result.id;
      this.attechForm.EMPLOYEEGLOBALID = parseInt(getCookie(userid))
      this.attechForm.saveData()
      .then((res)=>{
         console.log(res)
      })
      .catch((err)=>{
        console.log(err)
      })
      this._notif.show({
        content: result.messageKey,
        type: { style: "success", icon: true },
      });
    }
    else{
      this._notif.show({
        content: result.messageKey,
        type: { style: "error", icon: true },
      });
    }
     console.log(result)
     this.reset();
     this.opened=false;
     this.getWorkOrder()
   })
   .catch((err)=>{
     console.log(err)
   })
  }

  updateWorkOrder(obj){
   // HttpClient.fetchData("/api/WorkOrder/UpdateWorkOrder","post",obj,true,true)
     saveLookup("pmc","ticket",obj,"update")
    .then((result)=>{
      console.log(result)
      this.reset();
      this.opened=false;
      this.getWorkOrder()
    })
    .catch((err)=>{
      console.log(err)
    })
   }
  onDialogClose(type){
    if (type == "main") {
      this.opened = false;
    }
    if (type == "attachment") {
      this.attachmentOpened = false
    }
    this.opened=false
  }
  onTabSelect(e){
    if(e.title===this.workOrderType.assets.title){
      this.currentTab=this.workOrderType.assets.id;
    }
    else if(e.title===this.workOrderType.assets.title){
      this.currentTab=this.workOrderType.location.id;
    }
  }
  openRoleDailog(type){
    this.type=type;
    if(type==='new'){
      console.log("this.areagrid",this.areagrid)
      this.dailogTitle="Raise Work Order Request"
      this.isUpdate = false;
      this.currentTab=this.workOrderType.assets.id;
      this.opened=true
      this.reset()
    }
    else if(type==="viewFlag"){
      this.viewOpen=true
    }
  }

  reset(){
      this.tempUpdateObj={}
      this.facilityObj.selectedObj=null;
      this.blockObj.selectedObj=null;
      this.levelObj.selectedObj=null;
      this.areaObj.selectedObj=null;
      this.locationObj.selectedObj=null;
      this.serviceObj.selectedObj=null;
      this.workOrderTypeObj.selectedObj=null;
      this.consequanceObj.selectedObj=null;
      this.chargeAbleObj.selectedObj=null;
      this.assigineeObj.selectedObj=null;
      this.assignToObj.selectedObj=null;
      this.assetDataObj.selectedObj=null;
      this.attechForm.FILETYPE = null
      this.fileData = null
      this.description=""
  } 

  getWorkOrder(){
    HttpClient.get("/api/WorkOrder/GetWorkOrders?ConvertWorkorder=false",true,true)
    .then((result:any)=>{
      //
       this.areagrid=result.filter((d)=>this.workOrderType.location.name===d["referenceType"] &&d.convertToWorkOrder===false).map((d)=>{ 
         d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
         d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
         d["serviceObj"]=this.serviceObj.objectData[d.serviceTypeId];
         d["consequanceObj"]=this.consequanceObj.objectData[d.ticketConsequenceId];
         d["ticketStatusObj"]=this.ticketStatusObj.objectData[d.ticketStatusId];
         d["workOrderTypeObj"]=this.workOrderTypeObj.objectData[d.ticketTypeId]
         d["chargeAbleObj"]=this.chargeAbleObj.objectData[d.chargable || 2];
        //  if(d["consequanceObj"] && d["consequanceObj"]["TICKETTYPEID"]){
        //    d["workOrderTypeObj"]=this.workOrderTypeObj.objectData[d.TicketTypeID]
        //  }
         if(this.workOrderType.assets.name===d["referenceType"]){
            d["assetDataObj"]=this.assetDataObj.objectData[d["referenceId"]]
         }
         else{
          d["locationObj"]=this.locationObj.objectData[d["referenceId"]]
         }
         d["modifiedDate_formate"]=convertDateFormate(d["modifiedDate"])
         return d;
       });
    })
    .catch((err)=>{
       console.log(err)
    })
    
  }
  editColumn(d){
    let data=d.dataItem;
    this.tempUpdateObj=JSON.parse(JSON.stringify(d.dataItem));
    this.description=data["description"];
    this.assigineeObj.selectedObj=data["assigneeObj"]
    this.assignToObj.selectedObj=data["assignToObj"]
    this.serviceObj.selectedObj=data["serviceObj"]
    this.consequanceObj.selectedObj=data["consequanceObj"]
    this.workOrderTypeObj.selectedObj=data["workOrderTypeObj"]
    this.assetDataObj.selectedObj=data["assetDataObj"]
    this.locationObj.selectedObj=data["locationObj"]
    this.ticketStatusObj.selectedObj=data["ticketStatusObj"]
    this.chargeAbleObj.selectedObj=data["chargeAbleObj"]
    if(this.workOrderType.assets.name===data["referenceType"]){
     // this.currentTab=this.workOrderType.assets.id;
     // d["assetDataObj"]=this.assetDataObj.objectData[d["referenceId"]]
   }
   else{
     let lob=data["locationObj"];
    //this.currentTab=this.workOrderType.location.id;
    this.facilityObj.selectedObj=this.facilityObj.objectData[lob.FACILITYID];
    this.blockObj.selectedObj=this.blockObj.objectData[lob.BLOCKID]
    this.levelObj.selectedObj=this.levelObj.objectData[lob.BLOCKLEVELID]
    this.areaObj.selectedObj=this.areaObj.objectData[lob.LEVELAREAID]
   // d["locationObj"]=this.locationObj.objectData[d["referenceId"]]
   }
   this.attechForm.getAttechmentByRefrenceId(this.moduleId,data.id)
   .then((result)=>{
     console.log(this.attechForm)
     this.fileData= this.attechForm.URL;
   })
   .catch((err)=>{
    console.log(err);
   })
    this.opened=true;
    this.dailogTitle="Update Work Order"
    this.isUpdate = true;
    this.type="update"
  }

  deleteColumn(data){
     this.tempUpdateObj=data.dataItem;
     this.areagrid=this.areagrid.filter((d)=>d.id!=this.tempUpdateObj.id);
     this.removeDialogOpen=true;
     removeLookup("pmc","ticket",this.tempUpdateObj)
     .then((result)=>{
       this
      this._notif.show({
        content: "Request Remove successfully",
        type: { style: "success", icon: true },
      });
     })
     .catch((err)=>{
      this._notif.show({
        content: "Failed to Remove",
        type: { style: "error", icon: true },
      });
     })
  }

  getModuleHierarchYdata(schema, tablename){ 
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
    let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
              return e.NAME ==="Work Order"
        })
          this.parentId = ModFacArr[0].PARENTID
          this.moduleId=ModFacArr[0].ID;
       // console.log(ModFacArr)
    });
  }
  addAttechment(){
    if(!this.attechForm.URL){
      this.attechError.URL=true
    }
    else{
      this.attechError.URL=false
    }
    if(!this.attechForm.FILENAME){
      this.attechError.FILENAME=true
    }
    else{
      this.attechError.FILENAME=false
    }
    if(!this.attechError.URL && !this.attechError.FILENAME ){
      this.attechForm.MODULEHIERARCHYID=this.moduleId;
      this.attechForm.saveData()
      .then((result:any)=>{
        this.attechForm.ID=result.id;
        this.attechForm.MODULEHIERARCHYID=this.moduleId;
        this.attechmentDoc.alldata.push(this.attechForm);
        this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        this.attechForm=new Attechment();
      })
      .catch((err)=>{
         console.log(err)
      })
  }
 }
 getSparePartRequest(id){
   return new Promise((resolve,reject)=>{
     HttpClient.get("/api/Assets/GetPartNoByWOID?Id="+id)
     .then((result)=>{
          resolve(result)
     })
     .catch((err)=>{
       reject(err);
       console.log(err)
     })
   })
 }
}
