import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie } from 'src/Util/Util';
import { environment } from 'src/environments/environment';
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"
import { NotificationService } from '@progress/kendo-angular-notification';



@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  public removeDialogOpen = false;
  public attachmentOpened = false;
  public getAtachmetAfterUploading = []
  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
  public attechId:number=-1;
  public MODULEHIERARCHYID;
  public REFERENCEID;
  public employeId=parseInt(getCookie("userId")||"1")
  public documentFormate=[
    ];
    @Input() attachAssetRegId:number
    @Input() attachLicenseId:number
    @Input() licenseFormat : []
    public attechmentDoc=new AttechmentObj()
    public attechForm:Attechment=new Attechment();
    public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"FILETYPE",title:"File Type",isFilter:true},
    {field:"FILENAME",title:"File Name", isFilter:true},
    // {field:"col_3",title:"Attachment", isFilter:true}
  ];
  public gridData=[
    {
      col_1:"",
      col_2:"",
      // col_3:"",
    }
  ];
// public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder,
    private _notif: NotificationService) {
    eventListner(EVENT_NAME.delete_attechment,(e)=>{
      this.deleteAttechment(e);
   })
   eventListner(EVENT_NAME.download_attechment,(e)=>{
     this.downloadAttechment(e)
   })
   }

  ngOnInit() {

    if(this.licenseFormat){
          this.documentFormate = this.licenseFormat
    }
    else{
    this.documentFormate =[
    {id:"pdf",name:"pdf"},
    {id:"doc",name:"doc"},
    {id:"jpg",name:"jpg"},
    {id:"png",name:"png"}
      ]
    }
    this.getModuleHierarchYdata("dbo","moduleHierarchy").then((e)=>{
        if(this.attachAssetRegId){
          this.attachementColumn({id:this.attachAssetRegId});
        }
    

        if(this.attachLicenseId){
          this.attachementColumn({id:this.attachLicenseId});
        }
       
      // this.getAttechment(this.attachAssetRegId)

      // .then((result:any)=>{
      //   this.attechmentDoc=[...result];
        
      // })
      // .catch((err)=>{
      //    this.attechmentDoc=[]
      // })
    });
   
}
attachementColumn(data:any){
  this.attechForm.ID=0;
  this.attechForm.FILETYPE="pdf";
  this.attechForm.FILENAME=""
  this.attechForm.URL=""
  this.attechForm.MODULEHIERARCHYID=this.MODULEHIERARCHYID;
  this.attechForm.REFERENCEID=data.id;
  this.REFERENCEID = data.id;


this.attechError={URL:false,FILENAME:false}

// this.attechForm.EMPLOYEEGLOBALID=data.id;
this.attechId=data.id;
this.attechmentDoc=new AttechmentObj();
this.attechmentDoc.getData(data.id,this.MODULEHIERARCHYID)
.then((result:any)=>{

  // this.attachmentOpened = true;
   this.dailogTitle = "Add Attachment"
//this.attachmentOpened = true;
//this.dailogTitle = "Add Attachment"
})
.catch((err)=>{
 this.attechmentDoc.alldata=[]
})
}
// attachementColumn(data:any) {
//   this.attechForm={ 
//         ID:0,
//         REFERENCEID:this.attachAssetRegId,
//         FILETYPE:"pdf",
//         FILENAME:"",
//         URL:"",
//         EMPLOYEEGLOBALID:this.employeId,
//         CREATEDON:new Date(),
//         MODULEHIERARCHYID:this.MODULEHIERARCHYID,
//       }
  
//    this.attechError={URL:false,FILENAME:false}

//  // this.attechForm.EMPLOYEEGLOBALID=data.id;
//   this.attechId=data.id;
 
// }

addAttechment(){
  if(!this.attechForm.URL){
    this.attechError.URL=true
  }
  else{
    this.attechError.URL=false
  }
  if(!this.attechForm.FILENAME){
    this.attechError.FILENAME=true
  }
  else{
    this.attechError.FILENAME=false
  }
  if(!this.attechError.URL && !this.attechError.FILENAME ){
    this.attechForm.FILECONTENTS=this.attechForm.URL;
    this.attechForm.MODULEHIERARCHYID=this.MODULEHIERARCHYID;
    this.attechForm.REFERENCEID = this.REFERENCEID;
    this.attechForm.saveData()
    .then((result:any)=>{
        
      HttpClient.insertAduitLog("Attachment","Save Attachment File","Save Attachment File in Attachment").then((res:any)=>{
        if (res.isValid == true) {

        }
      })
      if (result[0].isValid === true) {
        this._notif.show({
          content:result.messageKey,
          type: { style: "success", icon: true },
        });
      
        this.opened = false;
       
      }
      else {
        this._notif.show({
          content: result.messageKey,
          type: { style: "error", icon: true },
        });
        this.opened = true;
      }
     if(result.length > 0){
      this.attechForm.ID=result[0].id;
     }
      this.attechmentDoc.getData(this.attechId,this.MODULEHIERARCHYID)
      .then((result)=>{
       // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
        this.attechForm=new Attechment();
      })

      this.getModuleHierarchYdata("dbo","moduleHierarchy").then((e)=>{
        if(this.attachAssetRegId){
          this.attachementColumn({id:this.attachAssetRegId});
        }
    

        if(this.attachLicenseId){
          this.attachementColumn({id:this.attachLicenseId});
        }
       
    });

      // this.attechmentDoc.alldata.push(this.attechForm);
      // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
      // this.attechForm=new Attechment();
    })
    .catch((err)=>{
      
    })
}
}

deleteAttechment(e){
  this.attechForm.deleteData(e.dataItem)
  .then((result)=>{
    this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
  })
  .catch((err)=>{
 
  })
  // this.deleteLookupAttechment(e.dataItem)
  // .then((result)=>{
  //   this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
  // })
  // .catch((err)=>{
  //   console.log(err)
  // })
 // console.log(e)
}

downloadAttechment(data){
  window.open(environment.backendUrl+data.URL)
}

  // convenience getter for easy access to form fields
  
 onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }

  onDialogClose(){
    this.opened=false;
   
  }

  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
      this.attachmentOpened = true;
      //this.dailogTitle = "Add Attachment"
      //this.attachementColumn({id:1})
       this.dailogTitle="Add Attachments"
    }
    if(type=="update"){
      this.dailogTitle="Edit Attachments"
    }
    this.opened=true;
  }

  getModuleHierarchYdata(schema, tablename){
  return new Promise((resolve,rejects)=>{
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
   let ModuleHieracrhyData = JSON.parse(result.gridData);
   let ModFacArr;
        if(this.attachAssetRegId){
          ModFacArr =  ModuleHieracrhyData.filter((e)=>{
            return e.NAME ==="Asset Registration"
      });
    }
      if(this.attachLicenseId){
        ModFacArr =  ModuleHieracrhyData.filter((e)=>{
          return e.NAME ==="License and Certificate"
    });
 }  console.log("  ModFacArr",  ModFacArr, ModuleHieracrhyData,this.attachLicenseId)
     this.MODULEHIERARCHYID = ModFacArr[0].ID;
          resolve(result)
    }).catch((e)=>{
      rejects(e)
})
 })
  }

  saveAttechment(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"attachment",
      "userId":getCookie(userid),
      "newData":JSON.stringify(data)
    }

    return new Promise((resolve,reject)=>{
      HttpClient.fetchData("/api/MasterData/SaveLookupData","post",obj,true,true)
      .then((response:any)=>{
      
        HttpClient.insertAduitLog("Attachment","Save Attachment","Save Attachment in Attachment").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
        
        this.opened = false;
         resolve(response)
      }).catch((err)=>{
        console.log(err);
        reject(err)
      })
        
         
        //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
        // this.opened=false;
      
      
    })
  }

  // addAttechment(){
  //   if(!this.attechForm.URL){
  //     this.attechError.URL=true
  //   }
  //   else{
  //     this.attechError.URL=false
  //   }
  //   if(!this.attechForm.FILENAME){
  //     this.attechError.FILENAME=true
  //   }
  //   else{
  //     this.attechError.FILENAME=false
  //   }
  //   if(!this.attechError.URL && !this.attechError.FILENAME ){
  //     this.saveAttechment(this.attechForm)
  //     .then((result)=>{
  //       this.attechmentDoc.push(this.attechForm);
  //       this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
  //       this.attechForm={ 
  //         ID:0,
  //         REFERENCEID:this.attechId,
  //         FILETYPE:"pdf",
  //         FILENAME:"",
  //         URL:"",
  //         EMPLOYEEGLOBALID:1,
  //         CREATEDON:new Date(),
  //         MODULEHIERARCHYID:this.MODULEHIERARCHYID,
  //       }
  //       this.opened = false;
  //     })
     
  //     .catch((err)=>{
  //        console.log(err)
  //     })
  // }
 //}

 getAttechment(RefId){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/MasterData/GetLookupData?Schema=dbo&Table=attachment",true,true)
    .then((result:any)=>{
    
        let attechmentData=JSON.parse(result.gridData)
        let temp=attechmentData.filter((d)=>d.REFERENCEID==RefId && d.MODULEHIERARCHYID==this.MODULEHIERARCHYID);
        this.attechmentDoc.alldata = temp
        resolve(temp);
    })
    .catch((err)=>{
        reject(err);
    })
  })
}


// // deleteLookupAttechment(data){
// //   let obj={
// //     "schemaName":"dbo",
// //     "tableName":"attachment",
// //     "userId":1,
// //     "oldData":JSON.stringify(data)
// //   }
// //   return new Promise((resolve,reject)=>{
// //     HttpClient.fetchData("/api/MasterData/DeleteLookupData","post",obj,true,true)
// //     .then((response)=>{
// //        console.log(response);
// //        resolve(response)
// //       //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
// //       // this.opened=false;
// //     })
// //     .catch((err)=>{
// //       console.log(err);
// //       reject(err)
// //     })
// //   })
  
// }

}
