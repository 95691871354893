import { Component, Input, OnInit } from '@angular/core';
import { process, State } from "@progress/kendo-data-query";
import {EVENT_NAME,dispatchEvent} from "src/event"
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
  RowClassArgs 
} from "@progress/kendo-angular-grid";
@Component({
  selector: 'app-permission-grid',
  templateUrl: './permission-grid.component.html',
  styleUrls: ['./permission-grid.component.scss']
})
export class PermissionGridComponent implements OnInit {
  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  public gridData:GridDataResult;
  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = changes.data.currentValue //process(changes.data.currentValue, this.state);
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    // this.gridData = process(this.data, this.state);
      dispatchEvent(EVENT_NAME.permission_change,this.gridData);
  }
  ngOnInit(): void {
  }
  editHandler(e){
   // dispatchEvent(EVENT_NAME.edit_column,e);
  }
  isRowSelected(e){
    return e.dataItem.parentId==0
  }
  public rowCallback(context) {
    const isEven = context.index % 2 == 0;
    return {
        even: isEven,
        odd: !isEven
    };
}
}
