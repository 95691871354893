import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"

function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key]]=i;
        }
        else{
       newObj[objR[i]]=i;
        }
    }
    return newObj;
}

function bindassetStandardObj(data){
      return data.reduce((acc,d)=>{
                   acc[d.name] = d.id;

                   return acc;
      },{})
}

function bindFaciltiy(data){
    return data.reduce((acc,d)=>{
                 acc[d.name] = d.id;
                 return acc;
    },{})
}
function bindBlock(data){
    return data.reduce((acc,d)=>{
                 acc[d.code] = d.id;
                 return acc;
    },{})
}
function bindLevel(data){
    return data.reduce((acc,d)=>{
                 acc[d.code] = d.id;
                 return acc;
    },{})
}
function bindArea(data){
    return data.reduce((acc,d)=>{
                 acc[d.code] = d.id;
                 return acc;
    },{})
}
function bindLocation(data){
    return data.reduce((acc,d)=>{
                 acc[d.code] = d.id;
                 return acc;
    },{})
}
function bindSubLocation(data){
    return data.reduce((acc,d)=>{
                 acc[d.name] = d.id;
                 return acc;
    },{})
}
function bindAssetStatus(data){
    return data.reduce((acc,d)=>{
                 acc[d.name] = d.id;
                 return acc;
    },{})
}
export function addBlukAssetManagement(
      assetList,
      moduleHierachyId,
      assetStandList,
      assetStatusList,
      faciltiyList,
      blockList,
      levelList,
      areaList,
      locationList,
      subLocationList,
      assetType,
      modal,
      manufacture
    ) 
{
  
    let empId=parseInt(getCookie(userid));
    //let assetStandObj =  bindassetStandardObj(assetStandList);
    let faciltiyListTypeObj =  bindFaciltiy(faciltiyList);
    let blockListTypeObj =  bindBlock(blockList);
    let levelListTypeObj =   bindLevel(levelList)
    let areaListTypeObj = bindArea(areaList)
    let locationListTypeObj = bindLocation(locationList)
    let subLocationListTypeObj = bindSubLocation(subLocationList)
    let assetStatusTypeObj =  bindAssetStatus(assetStatusList)
    let assetTypeObj=resversKey(assetType,"CODE");
    let modelObj=resversKey(modal,"name");
    let manufactureObj=resversKey(manufacture,"name")

    let userObjList = assetList.map((d) => {
        let assetSdId=null;
        let assetstd=(assetStandList || []).filter((d1)=>{
            return (assetTypeObj[d["Asset Type Code"]]==d1.assetTypeId && modelObj[d["Model"]]==d1.assetModelId && manufactureObj[d["Manufacturer"]]==d1.assetManufacturerId)
        })
        if(assetstd.length>0){
            assetSdId=assetstd[0].id;
        }
        let obj = {
            id: 0,
            assetStandardizationId:assetSdId,
            assetNumber:d["Asset Number"],
            assetCode:null,
            serialNo: d["Serial Number"],
            chasisNo:d["Chasis No"],
            engineNo:d["Engine No"],
            contractTypeId: 1,
            createdBy:  empId,
            createdDate:new Date(),
            modifiedBy: empId,
            modifiedDate:new Date(),
            moduleHierarchyId:moduleHierachyId,
            assetBpms:[],
            assetInfos:[{
                id: 0,
                commissioningDate:new Date(d["Commissioning Date"]),
                serviceStartDate:new Date(d["Service Start Date"]),
                serviceEndDate:new Date(d["Service Stop Date"]),
                lifeSpan: d["Expected Lifespan (Years)"],
                statusId: 1,
                assetAge:null,
                yearInService: null,
                operatingHours:d["Operating hours"]
            }
            ],
            assetLocations: [{
                id: 0,
                facilityId:faciltiyListTypeObj[d["Facility"]],
                blockId:  blockListTypeObj[d["Block Code"]],
                levelId:levelListTypeObj[d["Level Code"]],
                areaId: areaListTypeObj[d["Area Code"]],
                locationId:  locationListTypeObj[d["Location Code"]],
                subLocationId:subLocationListTypeObj[d["Sub Location Code"]]
            }],
            assetMaintenances:[
                {
                    id:0,
                    ppmflag:false,
                    ppmcreated:true,
                    di:false,
                    lastScheduledWorkOrderNo:null,
                    lastUnScheduledWorkOrderNo:null,
                    lastScheduledWorkOrderDate:null,
                    lastUnScheduledWorkOrderDate:null,
                    totalDownTime:null,
                    cumulativepartsCost:d["Cumulative Parts Cost (RM)"],
                    cumulativeLabourCost:d["Cumulative Labour Cost (RM)"],
                    cumulativeContractCost:d["Cumulative Contract Cost (RM)"],
                    warrantyStartDate:new Date(d["Warranty Start Date"]),
                    warrantyEndDate:new Date(d["Warranty End Date"]),
                    warrantyDurationInMonths:0
                }
            ]
        }
      return obj;
    })
    return userObjList;
}