import { Component, Input, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"


@Component({
  selector: 'app-warranty-coverage',
  templateUrl: './warranty-coverage.component.html',
  styleUrls: ['./warranty-coverage.component.scss']
})
export class WarrantyCoverageComponent implements OnChanges {

  constructor( private _notif: NotificationService) { }
 /**** List of veriable *** */
 @Input() id:any;
 public startDate=new Date();
 public endDate=new Date();
 public permission:Permission=new Permission();
 public format = "dd/MM/yyyy";
 public deleteItem:any={}
 public actionsLayout = "normal";
 public removeDialogOpen=false;
 public contactPersonData =[]
 public contractorVendDropDown =[]
 public  monthDiffValue;
 public contVenName;
 public faxNo;
 public address;
 public registrationNo;
 public listValue=[
    {
      id:1,
      name:"Warreny",
      tableName:"AssetWarranty",
      schema:"mm"
    }
  ]
  public selectedListValue:any={}
  public gridColumn=[]
  public formData=[]
  public gridData=[]
  public fkObj={}
  public inputForm=[]
  public dailogTitle="Add List of Values";
  public opened=false;
  public type=""
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  ngOnInit(): void {
    if(UserModuleObj.fesmsasset_asset){
      this.permission=UserModuleObj.fesmsasset_asset;
    }
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)})
    this.getBpm()
   // this.getLookupItem()
   //this.getLookupData("mm","AssetWarranty")
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    //throw new Error('Method not implemented.');
    if(changes.id){
      this.id=changes.id.currentValue;
      this.changeListValue(this.listValue[0])
    }
  }
  editColumn(data){
    console.log(data);
    if(data.action==="edit"){
      let dataBindObj={};
        for(let key in data.dataItem){
            dataBindObj[key]=data.dataItem[key];
        }
        //  this.formData=data.dataItem;
        this.inputForm=this.formData.map((d)=>{
            if(dataBindObj[d.name]!=undefined){
                 if(d.type=="text" || d.type=="lable"){
                   d.value=dataBindObj[d.name];
                 }
                 else if(d.type=="list"){
                   let v=d.data.filter((ft)=>{
                     return ft.ID==dataBindObj[d.name];
                   })
                   if(v.length>0){
                     d.value=v[0];
                   }
                   else{
                     d.value={};
                   }
                 }
            }
            return d;
        })
         this.openRoleDailog("update");
    }
    else if(data.action==="remove"){
      let dataBindObj={};
      for(let key in data.dataItem){
          dataBindObj[key]=data.dataItem[key];
      }
      //  this.formData=data.dataItem;
      this.deleteItem= data.dataItem;
      // this.formData.map((d)=>{
      //     if(dataBindObj[d.name]!=undefined){
      //          if(d.type=="text" || d.type=="lable"){
      //            d.value=dataBindObj[d.name];
      //          }
      //          else if(d.type=="list"){
      //            let v=d.data.filter((ft)=>{
      //              return ft.ID==dataBindObj[d.name];
      //            })
      //            if(v.length>0){
      //              d.value=v[0];
      //            }
      //            else{
      //              d.value={};
      //            }
      //          }
      //     }
      //     return d;
      // })
    //   this.formData=data.dataItem;
       this.removeDialogOpen=true;
    }
  }
  openRoleDailog(type){
    this.type=type;
       if(type==="new"){
         this.dailogTitle="Add Warranty Coverage "
         this.inputForm=JSON.parse(JSON.stringify(this.formData))
       
       }
       else if(type=="update"){
        this.dailogTitle="Edit Warranty Coverage "
       }
       this.opened=true
  }
  onDialogClose(){
     this.opened=false
  }
  changeListValue(e){
     this.selectedListValue={...e};
     this.getLookupData(e.schema,e.tableName)
  }
  getLookupItem(){
    HttpClient.get("/api/MasterData/GetLookupItems",true)
    .then((result:any)=>{
       this.listValue=result.map((d)=>{
          return     {
            id:d.id,
            name:d.description,
            tableName:d.name,
            schema:"dbo"
          }
       })
    })
    .catch((err)=>{

    })

  }

  getFKData(schema,tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
      .then((result:any)=>{
         resolve(result)
      })
      .catch((err)=>{
         reject(err);
      })
    })
  }

  getBpm() {
    HttpClient.get("/api/BPM/GetBpms", true, true)
      .then((result: any) => {
        console.log("getbpm",result)
          this.contractorVendDropDown = result
      })
  }

  getLookupData(schema,tablename){
   HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
   .then((result:any)=>{
        let gridData=[]
        if(result.gridData){
          gridData=JSON.parse(result.gridData);
          gridData = gridData.filter((e) => {
            return e.ASSETID == this.id;
          });
        }
        if(result.fkInformarions){
            let fktem=JSON.parse(result.fkInformarions)
            fktem=fktem.filter((d)=>d["FKTABLENAME"])
            Promise.all(
              fktem.map((d)=>{
                // return this.getFKData("dbo",d["FKTABLENAME"])
                return this.getFKData(d["FKSCHEMA"],d["FKTABLENAME"])
              })
            )
            .then((fkData:Array<any>)=>{
              console.log("fkresult=>",fkData)
               fktem=fktem.map((d,i)=>{
                let data=[]
                if(fkData[i] && fkData[i].gridData){
                  data= JSON.parse(fkData[i].gridData);
                    let dataObj={}
                    d["data"]=data.map((d)=>{
                      let id=d.id || d.ID;
                      let name=d.NAME || d.CODE || d.DESCRIPTION
                      dataObj[id]=name;
                      return {
                        ID:id,
                        NAME:name
                      }
                    })
                    d["dataObj"]=dataObj;
                  }
                  else{
                    d["data"]=[]
                    d["dataObj"]={}
                  }
                  return d;
               })
               this.manuculateLookUpData(gridData,fktem);
            })
            .catch((err)=>{
               this.manuculateLookUpData([],[])
            })
        }
        else{
          this.manuculateLookUpData(gridData,[])
        }
   })
   .catch((err)=>{
     console.log(err)
   })
  }
  uc(text){
    return text.toUpperCase();
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
  saveWarranty(){
    let s=new Date(this.startDate);
    let e=new Date(this.endDate);
     let m=this.monthDiff(s,e);
     let BPMREGISTERID = this.registrationNo;
     console.log(m);
     let obj={"ID":0,"ASSETID":this.id,"WARRANTYSTARTDATE":s,"WARRANTYENDDATE":e,"WARRANTYDURATIONINMONTHS":m,"BPMREGISTERID": BPMREGISTERID}
     this.saveForm(obj);

  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }
  

  manuculateLookUpData(gridData,fkResult){
    let fkGlobleObj=fkResult.reduce((acc,d)=>{
             acc[this.uc(d.FKCOLUMNNAME)]=d;
             return acc;
    },{})
    this.gridData=gridData;
    this.gridColumn=[];
    this.formData=[];
        if(this.gridData.length>0){
          for(let col in this.gridData[0]){
            if(this.uc(col)=="ID"){
             this.formData.push({name:this.uc(col),type:"lable",value:0})
            }
           
            else if(this.uc(col)=="CREATEDDATE" || this.uc(col)=="CREATEDON" || this.uc(col)=="MODIFIEDDATE" || this.uc(col)=="CREATEDBY" || this.uc(col)=="MODIFIEDBY" || this.uc(col)=="MODIFIEDAT" || this.uc(col)=="NAMECREATEDBY" || this.uc(col)=="NAMEMODIFIEDBY" || this.uc(col)=="ASSETID"){
              // this.formData.push({name:col,type:"lable",value:new Date()})
            }
            else if(fkGlobleObj[this.uc(col)]){
              this.formData.push({name:this.uc(col),type:"list",value:{},data:fkGlobleObj[this.uc(col)]["data"]})
              this.gridColumn.push({field:this.uc(col+"_NAME"),title:this.uc(col),width:200})
            }
            else{
              this.formData.push({name:this.uc(col),type:"text",value:""})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),isFilter:true,width:200})
            }
          }
        }
        if(fkResult.length>0){
          this.gridData=gridData.map((d)=>{
            if(d.WARRANTYSTARTDATE){
                  d["WARRANTYSTARTDATE"] = this.dateFormating(new Date(d.WARRANTYSTARTDATE))
            }
            if(d.WARRANTYENDDATE){
              d["WARRANTYENDDATE"] = this.dateFormating(new Date(d.WARRANTYENDDATE))
        }
              fkResult.forEach((fk) => {
                let key=this.uc(fk.FKCOLUMNNAME)
                 if(d[key]!=undefined){
                   let val=d[key];
                   d[key+"_NAME"]=fkGlobleObj[key]["dataObj"][val];
                 }
              });
              return d;
          });
        }
        console.log(this.gridData,fkGlobleObj)
  }
  submitFrom(){
    console.log(this.inputForm)
    let d=this.inputForm.reduce((acc,inp)=>{
        if(inp.type=="text" || inp.type=="lable"){
           acc[inp.name]=inp.value;
        }
        else if(inp.type=="list"){
          acc[inp.name]=inp.value.ID;
        }
        return acc;
    },{})
    console.log(d);
    this.saveForm(d)
  }
  saveForm(data){
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":1,
      "newData":JSON.stringify(data)
    }
     let  path="";
    if( this.type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(this.type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      if(result.messageKey){
        this._notif.show({
          content:result.messageKey,
          type: { style: "success", icon: true },
        });
      }
      else if(result.message){
        this._notif.show({
          content:result.message,
          type: { style: "error", icon: true },
        });
      }
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.opened=false;
    })
    .catch((err)=>{
      console.log(err);
    })
  }


  getDurationValue(data){
    this.monthDiffValue = this.monthDiff(this.startDate,this.endDate)
  }
  
  removeLookup(){
  //   let d=this.inputForm.reduce((acc,inp)=>{
  //     if(inp.type=="text" || inp.type=="lable"){
  //        acc[inp.name]=inp.value;
  //     }
  //     else if(inp.type=="list"){
  //       acc[inp.name]=inp.value.ID;
  //     }
  //     return acc;
  // },{})
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":1,
      "oldData":JSON.stringify(this.deleteItem),    
      "newData":null//JSON.stringify(this.deleteItem)
    }
     let  path=`/api/MasterData/DeleteLookupData`
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      if(result.messageKey){
        this._notif.show({
          content:result.messageKey,
          type: { style: "success", icon: true },
        });
      }
      else if(result.message){
        this._notif.show({
          content:result.message,
          type: { style: "error", icon: true },
        });
      }
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.removeDialogOpen=false;
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  contactorVenValue(id){
    let value  = this.contractorVendDropDown.filter((e)=>{
      return e.id==id;
    })

    this.contVenName = value[0].name;
    this.faxNo = value[0].faxNo;
    this.address = value[0].address1 + value[0].address2
    
    this.contactPersonData = value[0].bpmregisterContactPersons
  }

}










// import { Component, OnInit } from '@angular/core';
// import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
// import { sampleProducts } from 'src/app/Data/table';
// import {eventListner,EVENT_NAME} from "src/event"

// @Component({
//   selector: 'app-warranty-coverage',
//   templateUrl: './warranty-coverage.component.html',
//   styleUrls: ['./warranty-coverage.component.scss']
// })
// export class WarrantyCoverageComponent implements OnInit {

//   public opened=false;
//   public type="";
//   public actionsLayout = "normal";
//   public dailogTitle="";
//   public validate = true;
//   public warrentystartdate: Date = new Date(2021, 5, 1, 22);
//   public warrentyenddate: Date = new Date(2021, 5, 1, 22);
//   public format = "dd/MM/yyyy HH:mm";
//   // public leavegridData: any[] = leave_list;
//   // public summarygridData: any[] = summary_list;



//   public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
//     { text: "1", value: 1, inStock: false },
//     { text: "2", value: 2, inStock: false },
//     { text: "3", value: 3, inStock: false },
//     { text: "4", value: 4, inStock: false },
//     { text: "5", value: 5, inStock: false },
//   ];
//   public column2=[
//     {field:"c_v_regis",title:"Contractor / Vendor Registration No.",isFilter:true,width:300},
//     {field:"c_v_name",title:"Contractor / Vendor Name", isFilter:true,width:250},
//     {field:"contact_person",title:"Contact Person", isFilter:true,width:180},
//     {field:"tel_num",title:"Telephone No.", isFilter:true,width:160},
//     {field:"email",title:"Email", isFilter:true,width:150},
//     {field:"fax_no",title:"Fax No.", isFilter:true,width:150},
//     {field:"address",title:"Address", isFilter:true,width:150}
//   ];
//   public areagrid=[
//     {
//       c_v_regis:"--",
//       c_v_name:"--",
//       contact_person:"--",
//       tel_num:"--",
//       email:"--",
//       fax_no:"",
//       address:"",
//     },
//     {
//       c_v_regis:"--",
//       c_v_name:"--",
//       contact_person:"--",
//       tel_num:"--",
//       email:"--",
//       fax_no:"",
//       address:"",
//     }
//   ];
// // public gridData=sampleProducts;

//   public itemDisabled(itemArgs: { dataItem: any; index: number }) {
//     return !itemArgs.dataItem.inStock;
//   }

//   public value: Date = new Date(2000, 2, 10);
//   public value2: Date = new Date(2000, 3, 10);

//   maintenence_status: FormGroup;
//   submitted = false;
//   cont_vendor_r = ['Select'];
//   // facility = ['PTT','Menara 3'];
//   // status = ['Active','Inactive'];
  
//   constructor(private formBuilder: FormBuilder) {
//     eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
//    }
//    editColumn(data){}

//   ngOnInit() {
//     this.maintenence_status = this.formBuilder.group({
//       war_date: ['', Validators.required],
//       war_end_date: ['', Validators.required],
//       war_duration: ['',Validators.required],
//       cont_vendor_r: ['',Validators.required],

//     }, {
//         // validator: MustMatch('password', 'confirmPassword')
//     });
// }

//   // convenience getter for easy access to form fields
//   get f() { return this.maintenence_status.controls; }

//   onSubmit() {
//       this.submitted = true;

//       // stop here if form is invalid
//       if (this.maintenence_status.invalid) {
//           return;
//       }

//       // display form values on success
//       alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
//   }

//   onReset() {
//       this.submitted = false;
//       this.maintenence_status.reset();
//   }
//   onDialogClose(){
//     this.submitted = false;
//     this.maintenence_status.reset();
//     this.opened=false;
   
//   }
//   openRoleDailog(type){
//     this.type=type;
//     if(type=="new"){
//        this.dailogTitle="Add Warranty Coverage"
//     }
//     if(type=="update"){
//       this.dailogTitle="Edit Warranty Coverage"
//     }
//     this.opened=true;
//   }

// }
