import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { sampleProducts } from 'src/app/Data/table';
import { SparePart, SpareTakeActivity } from 'src/app/models/spare.model';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { SpareTakeActivityValidation } from 'src/validator/spare';
import { UserModuleObj,Permission } from "src/ApiModel/Model/UserModule"
import { of } from 'rxjs';

@Component({
  selector: 'app-stock-take-activity',
  templateUrl: './stock-take-activity.component.html',
  styleUrls: ['./stock-take-activity.component.scss']
})
export class StockTakeActivityComponent implements OnInit {

  public opened=false;
  public quantityErr = false;
  public removeDialogOpen = false;
  public type="";
  public stockCateObj ={}
  public actionsLayout = "normal";
  public permission:Permission=new Permission();
  public dailogTitle="";
  public partDesc;
  public stockNoid ;
  public partDescription;
  public stockCatId;
  public validate = true;
  public stockDropDown =[]
  public quantity ;
  public stockCategoryDropDow  =[]
  public partNoDropDown =[]
  public gridData =[]
  public locObj ={}
  public stockNoObj ={}
  public sparePartListData =[]
  public currentDate :any
  public format = "MM/dd/yyyy HH:mm";
  public LocDropDown =[
    {id:1,name:"PTT"}
  ]
  public sparePartActivityForm :SpareTakeActivity = new SpareTakeActivity();
error = SpareTakeActivityValidation(this.sparePartActivityForm,"init");
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"STOCKADJUSTMENTID",title:"Stock Adjustment ID",isFilter:true,width:150},
    {field:"stockNoValue",title:"Stock No. ", isFilter:true,width:150},
    {field:"date",title:"Date", isFilter:true,width:150},
    {field:"PARTNO",title:"Part No.", isFilter:true,width:150},
    {field:"location",title:"Location", isFilter:true,width:150},
    {field:"stockCategory",title:"Stock Category", isFilter:true,width:150},
    {field:"PHYSICALQUANTITY",title:"Physical Quantity", isFilter:true,width:150},
    {field:"VARIANCE",title:"Variance", isFilter:true,width:150},
    {field:"ADJUSTEDQUANTITY",title:"Adjusted Quantity", isFilter:true,width:150},
    {field:"REMARK",title:"Remark", isFilter:true,width:150}
  ];

  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]

  public areagrid=[
    {
      c_1:"",
      c_2:"",
      c_3:"",
      c_4:"",
      c_5:"",
      c_7:"",
      c_8:"",
      c_9:"",
      c_10:"",
      c_11:"",
      c_12:"",
    },
    {
      c_1:"",
      c_2:"",
      c_3:"",
      c_4:"",
      c_5:"",
      c_7:"",
      c_8:"",
      c_9:"",
      c_10:"",
      c_11:"",
      c_12:"",
    }
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  cont_vendor_r = ['Select'];
  
  constructor(private formBuilder: FormBuilder,private _notif:NotificationService) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)});
   }


   editColumn(data) {
    if (data.action == "edit"){
      this.sparePartActivityForm.ID=data.dataItem.ID
      this.getSpareUpdateById("mm","spareTake", this.sparePartActivityForm.ID)
      this.openRoleDailog("update")
      }
  else if (data.action == "remove") {
    this.sparePartActivityForm.ID=data.dataItem.ID
    this.getSpareUpdateById("mm","spareTake", this.sparePartActivityForm.ID)
    this.removeDialogOpen = true;
  }
   }
   ngOnInit() {
  this.currentDate = new Date();
    if(UserModuleObj.spare_parts){
      this.permission=UserModuleObj.spare_parts;
    }
    
    Promise.all([
      this. getSpareList(),
      this.getLocationData("dbo", "arealocation"),
      // this.getsparePartUpdatedData("mm","sparePartUpdate"),
      this.getStockCategory("mm","stockCategory"),
     ]).then((e)=>{
      this.getStockTakeData("mm","spareTake")
     })
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
     this.openRoleDailog("new");
  }
  onDialogClose(type){
    if(type=="new"){
      this.opened=false;
    }
   if(type=="remove"){
     this.removeDialogOpen = false
   }
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
     this.sparePartActivityForm = new SpareTakeActivity ()
     this.partDesc = ""
     this.quantity = ""
     this.stockCatId =0
     this.quantityErr=false
     this.error = SpareTakeActivityValidation(this.sparePartActivityForm,"init");
       this.dailogTitle="Add Stock Take Activity"
    }
    if(type=="update"){
      this.dailogTitle="Edit Stock Take Activity"
    }
    this.opened=true;
  }
  
  getSpareList(){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        "/api/Assets/GetSpareList",
        true,
        true
      )
        .then((result: any) => {
         
        this.stockDropDown  = result;
  
        this.sparePartListData = result;
        this.partNoDropDown = result.map((e)=>{
          if(e.sparePartDetails.length>0){
            return {id:e.sparePartDetails[0].id,partNo:e.sparePartDetails[0].partNo}
          }
        
        })
         
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStockCategory(schema,tablename){
    
    return new Promise((resolve,reject)=>{
     HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
       true,
       true
     ).then((result: any) => {
      this. stockCategoryDropDow = JSON.parse(result.gridData)
       resolve(result);
       
     }).catch((e)=>{
       reject(e)
     })
    })
   
  }

  getLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      
       this.LocDropDown =  JSON.parse(result.gridData)
    });
  }

  changedPartNo(data){
      let value=   this.sparePartListData.filter((e)=>{
      if( e.sparePartDetails.length>0){
        return e.sparePartDetails[0].id===data;
      }
      })
 this.partDescription = value[0].sparePartDetails[0].partDescription
  }

  stockValueChanged(data){
   
    let stockCategoryValue = this.stockDropDown.filter((e)=>{
    return  e.id===data
    })
    this.stockCatId =  stockCategoryValue && stockCategoryValue[0].stockCategoryId;
    this.stockNoid = data;
    
      this.quantity =  stockCategoryValue && stockCategoryValue[0].sparePartDetails[0].qoh
  
    this.getsparePartUpdatedData("mm","sparePartUpdate")
   let value = this.sparePartListData.filter((e)=>{
      return e.id===data
    });
   this.sparePartActivityForm.PARTNO = value[0].sparePartDetails[0].partNo
   this.partDesc = value[0].sparePartDetails[0].partDescription
  }

  getsparePartUpdatedData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
        let data =JSON.parse(result.gridData);
       let spartPartUpdate = data.filter((e)=>{
        return e.SPAREPARTID === this.stockNoid;
        })
      var mostRecentDate = new Date(Math.max.apply(null, spartPartUpdate.map( e => {
        return new Date(e.CREATEDDATE);
     })));
     var mostRecentObject = spartPartUpdate.filter( e => { 
      var d = new Date( e.CREATEDDATE ); 
      return d.getTime() == mostRecentDate.getTime();
  })[0];
       
       this.sparePartActivityForm.UPDATIONDATE = new Date( mostRecentObject.CREATEDDATE)
        
       
    });
  }

  
  onSearchChange(searchValue: string): void {  
    this.sparePartActivityForm.VARIANCE =  this.quantity -  this.sparePartActivityForm.PHYSICALQUANTITY
    this.sparePartActivityForm.ADJUSTEDQUANTITY = this.sparePartActivityForm.PHYSICALQUANTITY
  }

  submitSpTakeActivityFrom(){
   
    if(!this.quantity){
      this.quantityErr=true
}
    this.error = SpareTakeActivityValidation(this.sparePartActivityForm,"");
    if(!this.error.PHYSICALQUANTITY && !this.error.STOCKNO &&!this.error.LOCATIONID){
      this.sparePartActivityForm.UPDATIONDATE = new Date()
      // this.sparePartActivityForm.STOCKNO = parseInt( this.sparePartActivityForm.STOCKNO )
   // if(! this.leaveerror.USERID){
     if (this.type == "new") {
          this.sparePartActivityForm.ID=0
          this.sparePartActivityForm.STOCKADJUSTMENTID = null
          this.sparePartActivityForm.CreatedBy = parseInt(getCookie(userid))
          this.sparePartActivityForm.ModifiedBy =parseInt(getCookie(userid))
          this.sparePartActivityForm.UPDATIONDATE = new Date(this.currentDate);
          this.sparePartActivityForm.CreatedDate = new Date()
          this.sparePartActivityForm.ModifiedDate = new Date()
        }
      this. saveSpareTakeActi(JSON.stringify(this.sparePartActivityForm))
    }
  }
  
 saveSpareTakeActi(data){
      let userId=getCookie(userid);
      let obj={
        "schemaName":"mm",
        "tableName":"spareTake",
        "userId":userId,
        "newData":data
      }
       let  path="";
      if( this.type==="new"){
         path=`/api/MasterData/SaveLookupData`
      }
      else if(this.type=="update"){
        path="/api/MasterData/UpdateLookupData"
      }
      HttpClient.fetchData(path,"post",obj,true,true)
      .then((result:any)=>{
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.getStockTakeData("mm","spareTake")
         this.opened=false;
        }
      })
      .catch((err)=>{
   
      })
    }


    pading(n){
      if(n>9)
        return n;
      else
       return "0"+n
    }
  
    dateFormating(da){
      let df=new Date(da);
     return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
    }

    bindLocbj(result){
      this.locObj = result.reduce((acc, d) => {
        acc[d.ID] = d.NAME;
        return acc;
      }, {});
    }

    bindstockNobj(result){
      this.stockNoObj = result.reduce((acc, d) => {
        acc[d.id] = d.stockNo;
        return acc;
      }, {});
    }

    bindstockCategbj(result){
      this.stockCateObj = result.reduce((acc, d) => {
        acc[d.ID] = d.NAME;
        return acc;
      }, {});
    }

    getStockTakeData(schema,tablename){
     
        return new Promise((resolve, reject) => {
          HttpClient.get(
            `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
            true,
            true
          )
         
            .then((result: any) => {
            this.bindLocbj(this.LocDropDown);
            this.bindstockNobj(this.stockDropDown);
            this.bindstockCategbj(this.stockCategoryDropDow)
        let stockTakeData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
         this.gridData =  stockTakeData.map((e)=>{
           if(e.ID!=null){
            if(e.UPDATIONDATE!=null){
              e["date"] =  this.dateFormating(new Date(e.UPDATIONDATE));
            }
            e["location"] = this.locObj[e.LOCATIONID];
           e["stockNoValue"] = this.stockNoObj[parseInt(e.STOCKNO)];
        this.stockValueChanged(parseInt(e.STOCKNO));
           e["stockCategory"] = this.stockCateObj[this.stockCatId];
           return e;
          }
          else{
            this.gridData =[]
          }
        
          })
           
               
                   
              resolve(result);
            })
           
            .catch((err) => {
              reject(err);
            });
        });
      }

      getSpareUpdateById(schema,tablename,id){
        return new Promise((resolve, reject) => {
          HttpClient.get(
            `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
            true,
            true
          )
            .then((result: any) => {
            let data=  JSON.parse(result.gridData)
              
             this.sparePartActivityForm = data[0]
             if(data[0].UPDATIONDATE){
              this.sparePartActivityForm.UPDATIONDATE = new Date(data[0].UPDATIONDATE)
             }
            
             this.sparePartActivityForm.STOCKNO =  parseInt(data[0].STOCKNO)
             this.stockValueChanged( this.sparePartActivityForm.STOCKNO )
            
            //   this.updatedData = new Date( data[0].CREATEDDATE)
            //  this.stockNoChanged(this.sparePartUpdateForm.SPAREPARTID)
            resolve(result);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    

        
  removeSpareTake(){
    //   let d=this.inputForm.reduce((acc,inp)=>{
    //     if(inp.type=="text" || inp.type=="lable"){
    //        acc[inp.name]=inp.value;
    //     }
    //     else if(inp.type=="list"){
    //       acc[inp.name]=inp.value.ID;
    //     }
    //     return acc;
    // },{})
      let obj={
        "schemaName":"mm",
        "tableName":"spareTake",
        "userId":getCookie(userid),
        "oldData":JSON.stringify(this.sparePartActivityForm),    
        "newData":null//JSON.stringify(this.deleteItem)
      }

  
       let  path=`/api/MasterData/DeleteLookupData`
      HttpClient.fetchData(path,"post",obj,true,true)
      .then((result:any)=>{
        if(result.messageKey){
          this._notif.show({
            content:result.messageKey,
            type: { style: "success", icon: true },
          });
        }
        else if(result.message){
          this._notif.show({
            content:result.message,
            type: { style: "error", icon: true },
          });
        }
         this.getStockTakeData("mm","spareTake")
         this.removeDialogOpen=false;
      })
      .catch((err)=>{
    
      })
    }
    
}
