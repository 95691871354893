// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  proxy:false,
  proxyUrl:"/api",
   backendUrl:"https://eworms.klccuhibcc.com/WebApi", // Prod
  //  backendUrl:"http://47.254.237.237:81", // UAT
  // backendUrl:"http://20.77.96.67:85", // SIT
  IMG_UPLOAD_SERVER:"http://img.varoshakart.in/api/upload",
  IMG_LOAD_SERVER:"http://img.varoshakart.in/img/gallary",
  bucket:"aworms",
  reset_password_pathname:"update-password",
  play_store:"https://play.google.com/store/apps/details?id=com.klccContractor",
  app_store:"https://www.apple.com/app-store",
  external_user_id:"info@avowstech.com",
  external_user_password:"Welcome@123",
  external_user_bpm_id:null,
// deaplink_request_workorder_android:"https://play.google.com/store/apps/details?id=com.klccuser",
deaplink_request_workorder_android:"https://klccuser.page.link/?link=https://play.google.com/store/apps/details?{param1}&apn=com.klccuser&isi=1627689247&ibi=com.klcccustomer", // UAT deap link
  // deaplink_request_workorder_ios:"https://apps.apple.com/us/app/eworms-facility/id1602360064"
  deaplink_request_workorder_ios:"https://klccuser.page.link/?link=https://play.google.com/store/apps/details?{param1}&apn=com.klccuser&isi=1627689247&ibi=com.klcccustomer" // UAT deap link

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
