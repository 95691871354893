import { userid } from "src/Util/const"
import { getCookie } from "src/Util/Util"

function resversKey(objR,key=null){
    let newObj={};
    for(let i in objR){
        if(key){
            if(objR[i][key])
            newObj[objR[i][key].toLowerCase()]=i;
        }
        else{
       newObj[objR[i].toLowerCase()]=i;
        }
    }
    return newObj;
}
function bindSpareContractObj(data){
      return data.reduce((acc,d)=>{
                   acc[d.name] = d.id;

                   return acc;
      },{})
}
function bindSparePartIDObj(data){
    return data.reduce((acc,d)=>{
                 acc[d.stockNo] = d.id;
                 return acc;
    },{})
}
export function addBlukSparePartUpdate(
      sparePartList,sparePartUpdateList,contractorList,
    ) 
  {
    
    let empId=parseInt(getCookie(userid));
    let spartPartUpdateObj =  bindSparePartIDObj(sparePartUpdateList);
    let spartPartContractObj = bindSpareContractObj(contractorList)
    let userObjList = sparePartList.map((d) => {
        let obj = {
            "SPAREPARTID":spartPartUpdateObj[d["Stock No"]],
            "PARTNO":d["Part No."],
            "MINUNIT":d["Min Unit"],
            "MAXUNIT":d["Max Unit"],
            "DISPOSAL":(d["Disposal Activity"]?.toLowerCase()=="yes")?true:false,
            "SUPPLIERRETURN":(d["Return To Supplier"]?.toLowerCase()=="yes")?true:false,
            "EXPIRYDATE":new Date(d["Expiry Date"]),
            "QUANTITY":d["Quantity "],
            "ERPPURCHASECOST":d["ERP Purchase Cost / Pcs (RM) "],
            "BPMID": spartPartContractObj[d["Supplier Name"]],
            "SPAREPARTUPDATEID":d["Stock Update No."],
            "CREATEDBY":empId,
            "ESTIMATEDLIFESPAN":d["Estimated Lifespan"],
            "COSTPERPC":d["Cost / Pcs (RM)"],
            "INVOICENO":d["Invoice No. "],
            "BINNO":d["Bin No."],
            "REMARKS":d["Remarks"],
            "CREATEDDATE":new Date(),
            "ID":0
        }
      return obj;
    })
    return userObjList;
}