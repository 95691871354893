import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-work-order-inquiry',
  templateUrl: './work-order-inquiry.component.html',
  styleUrls: ['./work-order-inquiry.component.scss']
})
export class WorkOrderInquiryComponent implements OnInit {

  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;

  public column2=[
    {field:"c_1",title:"Work Order No.",isFilter:true,width:200},
    {field:"c_2",title:"Asset No.", isFilter:true,width:200},
    {field:"c_3",title:"Asset Description", isFilter:true,width:200},
    {field:"c_4",title:"Block Code", isFilter:true,width:200},
    {field:"c_5",title:"Block Name", isFilter:true,width:200},
    {field:"c_6",title:"Level Code", isFilter:true,width:200},
    {field:"c_7",title:"Level Name",isFilter:true,width:200},
    {field:"c_8",title:"Area Code", isFilter:true,width:200},
    {field:"c_9",title:"Area Name", isFilter:true,width:200},
    {field:"c_10",title:"Location Code", isFilter:true,width:200},
    {field:"c_11",title:"Location Name", isFilter:true,width:200},
    {field:"c_12",title:"Service Type", isFilter:true,width:200},
    {field:"c_13",title:"Checklist ID",isFilter:true,width:200},
    {field:"c_14",title:"Checklist Name", isFilter:true,width:200},
    {field:"c_15",title:"Assignee", isFilter:true,width:200},
    {field:"c_16",title:"Assign To", isFilter:true,width:200},
    {field:"c_17",title:"Status", isFilter:true,width:200},
    {field:"c_18",title:"Completed By", isFilter:true,width:200},
    {field:"c_19",title:"Closed By", isFilter:true,width:200},
  ];
  public areagrid=[
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--",
      c_17:"--",
      c_18:"--",
      c_19:"--",
    },
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--",
      c_17:"--",
      c_18:"--",
      c_19:"--",
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
