<div class="card m-3"   >
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Location</h5>
        <div>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
                Download Template 
            </button>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="importopened=true" class="header-right-btn" style="margin-right: 0.5rem;">
               Import
            </button>
            <button kendoButton *ngIf="permission && permission.isExport"  primary="true" (click)=" download()" class="header-right-btn">
                Export
               </button>  &nbsp;  &nbsp;  &nbsp;
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" class="header-right-btn" (click)="openLocationDailog('new')">
                <span class="add_btn">+</span> Location
            </button>
        </div>
    </div>
    <div class="card-body"  >
        <app-table-grid 
           [data]="gridData" 
           [size]="10" 
           [column]="column"
           [deleteFlag]="false"
           [editFlag]="permission && permission.isUpdate"
           [qrcode] = "true"
           ></app-table-grid>
    </div>
</div>



<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
  
        <div class="form-row">
               <div class="form-group col-5">
                <label>Facility<span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  
                [data]="facilityDropDown" class="form-control"
                [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                [(ngModel)]="LocationForm.FACILITYID"
                (valueChange)="facilityValueChange($event)" >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.FACILITYID">*Required</kendo-formerror>
            </div>
               </div>
            <div class="form-group col-5">
                <label>Block Code<span style="color: red;">*</span></label>
                <div style="width:100%">
                 <kendo-dropdownlist  [data]="filterdBlockData" [(ngModel)]="LocationForm.BLOCKID"  class="form-control"
                [textField]="'code'" [valueField]="'id'" (valueChange)="blockValueChange($event)" [valuePrimitive]="true" (filterChange)="handleBlockFilter($event)"  [filterable]="true" >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.BLOCKID">*Required</kendo-formerror>
                    </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Block Name</label>
                <input kendoTextBox  [(ngModel)]= "blockName" class="form-control" readonly>
               
            </div>
            <div class="form-group col-5">
                <label>Level Code<span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [data]="filterdLevelData" [(ngModel)]="LocationForm.BLOCKLEVELID"  class="form-control"
                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"  (valueChange)="levelValueChange($event)" [filterable]="true" (filterChange)="handleLevelFilter($event)" >
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="error.BLOCKLEVELID">*Required</kendo-formerror>
                    </div>
            </div>
            
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Level Name</label>
                <input kendoTextBox  [(ngModel)]= "levelName" class="form-control" readonly>
            </div>
            <div class="form-group col-5">
                <label>Area Code<span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [data]="filterdAreaData" [(ngModel)]="LocationForm.LEVELAREAID"  class="form-control"
                [textField]="'code'" [valueField]="'id'"  (valueChange)="areaValueChange($event)" [valuePrimitive]="true"   [filterable]="true" (filterChange)="handleAreaFilter($event)">
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.LEVELAREAID">*Required</kendo-formerror>
                </div>
            </div>
           
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Area Name</label>
                <input kendoTextBox   [(ngModel)]= "areaName"  class="form-control" readonly>
           
            
        </div>
            <div class="form-group col-5">
                
                <label>Location Code<span style="color: red;">*</span></label>
                <div style="width:100%">
                <input kendoTextBox class="form-control"  [(ngModel)]="LocationForm.CODE" (blure)=" checkedUniqueCode()">
                <kendo-formerror *ngIf="error.CODE">*Required</kendo-formerror>
                <kendo-formerror *ngIf="!error.CODE && isunique">Location code already exist.</kendo-formerror>
            </div>
                
            </div>
           
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Location Name<span style="color: red;">*</span></label>
                <div style="width:100%">
                <input kendoTextBox class="form-control"  [(ngModel)]="LocationForm.NAME" >
                <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror>
            </div>
               
            </div>
            <div class="form-group col-5">
                <label>Status</label>
                <kendo-dropdownlist  [data]="statusDropDown"  class="form-control"
                [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"
                [(ngModel)]="LocationForm.STATUSID"
                (valueChange)="statusValueChange($event)"
                ></kendo-dropdownlist>
                
            </div>
          
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Start Service Date<span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-datepicker     [(ngModel)]="LocationForm.STARTSERVICEDATE" ></kendo-datepicker>
                <kendo-formerror *ngIf="error.STARTSERVICEDATE">*Required</kendo-formerror>
               
            </div>
            </div>
            <div class="form-group col-5">
              <label>Stop Service Date</label>
              <div style="width:100%">
                <kendo-datepicker  [(ngModel)]="LocationForm.STOPSERVICEDATE" [disabled]="statusValue"></kendo-datepicker>
                <kendo-formerror *ngIf="!statusValue && error.STOPSERVICEDATE">*Required</kendo-formerror>
               </div>
           </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Location Incharge</label>
                <kendo-dropdownlist
                 [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                class="form-control"
                [(ngModel)]="LocationForm.LOCATIONINCHARGE"
                [data]="filterdLocationInc"
                [filterable]="true" (filterChange)="handleLocationFilter($event)"
               >
              </kendo-dropdownlist>
             </div>
            <div class="form-group col-5">
               
                    <label>Company Representative</label>
                    <kendo-dropdownlist
                    [data]="filterdCompanyRe"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [valuePrimitive]="true"
                    class="form-control"
                    [(ngModel)]="LocationForm.COMPANYREPRESENTATIVE"
                    [filterable]="true" (filterChange)="handleCompanyFilter($event)"
                   >
                  </kendo-dropdownlist>
                 </div>
            
        </div>

  <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="onDialogClose('new')">Cancel</button>
            <button kendoButton *ngIf="type=='new'" (click)="onReset()"  primary="true">
               Reset
            </button>
            <button kendoButton *ngIf="type=='new'" (click)="onSubmit()"  primary="true">
                Save
            </button>
            <button kendoButton *ngIf="type=='update'"  (click)="onSubmit()" primary="true">
               Update
            </button>
    </kendo-dialog-actions>
    
</kendo-dialog>


<kendo-dialog *ngIf="qrCodeDialogOpen" (close)="onDialogClose('qrcode')" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
         QR Code
        </div>
      </kendo-dialog-titlebar>
      <div style="width: 189px;height: 76px;margin:auto;display: flex;border: 1px solid gray;    padding: 5px;    border-radius: 10px;justify-content: space-between;"> 
        <div>
          <div style="text-align: center;">
            <img src="assets/logo.png"  style="height: 33px;">
            <p style="font-size: 8px;margin-bottom: 0; border: 1px solid gray;padding: 1px;">{{locationQrValue}}</p>
           
          </div>
        </div>
        <div>
          <img [src]="imageUrl+qrCodeGenUrl"  style="width: 54px;margin-top: 5px;">
        </div>
      </div> 
 
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton primary="true"  *ngIf="permission && permission.isPrint" (click)="viewQR()">Print</button>
    </kendo-dialog-actions>
  </kendo-dialog>


  <!-- <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [fileUploadType]=4
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog> -->

  <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [fileUploadType]=4
                (valueChange)=importDataChange($event)
                style="width: 100%;"
          >
           </app-file-model>
            </div>
             </div>
<kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="fileUpload()" >
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="qrCodeDialogOpen" (close)="qrCodeDialogOpen=false" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        View QR
      </div>
    </kendo-dialog-titlebar>

    <div  *ngIf="qrcodePrintData.length>0"  >
      <div style="background: white; padding: 1rem;">
    <kendo-pdf-export   #pdf paperSize="A4">
      
      <div  *ngFor ="let item of qrcodePrintData;let i=index" [ngClass]="(i!=0)?'page-break':''">
       <div style="width: 100%;display: flex;justify-content: center;margin: 1rem;">
        <div style="border: 2px solid black;border-radius: 0.25rem;width: 170px;display: grid;grid-template-columns: 55% 45%;">
           <div style="display: flex;justify-content: center;flex-direction: column;width: 100%;padding: 0.15rem;">
               <div style="display: flex;justify-content: center;"><img src="assets/logo.png"  style="height: 20px;"></div>
               <div style="color: black;text-align: center;font-size: 8px;padding:0.5rem 0.15rem;" ><div style="padding: 0.15rem;border: 1px solid black;">{{item.location}}</div></div>
               <!-- <div style="color: black;text-align: center;font-size: 16px;">{{item.assetNo}}</div> -->
           </div>
           <div style="display: flex;justify-content: center;flex-direction: column;">
              <kendo-qrcode
              style="width: 99%;"
              value={{item.data}}
              errorCorrection="M"
            >
            </kendo-qrcode>
           </div>
           </div>
       </div>
       </div>

       </kendo-pdf-export> 
       </div>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="qrCodeDialogOpen=false">Cancel</button>
      <button  kendoButton primary="true" (click)="pdf.saveAs('location-qr-code.pdf')">
            Print
      </button>
    </kendo-dialog-actions>
    </div>

  </kendo-dialog>