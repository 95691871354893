import { ERParameterMapping , PMTypeError } from "../app/models/er.model"
export let PMValidation = (data: ERParameterMapping, type) => {
    let error = new PMTypeError (false)
    if (type === "init")
        return error;
    if (!data.categoryId) {
        error.categoryId = true;
    }
    if (!data.erno) {
        error.erno = true;
    }
   
    if (!data.frequencyId) {
        error.frequencyId = true;
    }
   
    if (!data.effectiveStartDate) {
        error.effectiveStartDate = true;
    }
   
   
    return error;
}