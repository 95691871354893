import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { getCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-list-value',
  templateUrl: './list-value.component.html',
  styleUrls: ['./list-value.component.scss']
})
export class ListValueComponent implements OnInit {

  constructor( private _notif: NotificationService,private datePipe: DatePipe) { }
 /**** List of veriable *** */
 public timeFormate="HH:mm:ss"
 public format = "dd/MM/yyyy";
 public deleteItem:any={}
 public actionsLayout = "normal";
 public filterdData =[]
 public removeDialogOpen=false;
 public removeDialog=false;
 public deleteFlag=false;
 public permission:Permission=new Permission();
 public monthDayList = [
  { full: "January", short: "Jan", day: "Sun" },
  { full: "February", short: "Feb", day: "Mon" },
  { full: "March", short: "Mar", day: "Tue" },
  { full: "April", short: "Apr", day: "Wed" },
  { full: "May", short: "May", day: "Thr" },
  { full: "June", short: "Jun", day: "Fri" },
  { full: "July", short: "Jul", day: "Sat" },
  { full: "Augest", short: "Aug", day: "Sun" },
  { full: "September", short: "Sep", day: "" },
  { full: "October", short: "Oct", day: "" },
  { full: "November", short: "Nov", day: "" },
  { full: "December", short: "Dec", day: "" },
]
 public listValue=[
    {
      id:1,
      name:"speciality",
      tableName:"speciality",
      schema:"dbo"
    },
    {
      id:2,
      name:"AssetType",
      tableName:"AssetType",
      schema:"dbo"
    }
  ]
  public selectedListValue:any={}
  public gridColumn=[]
  public formData=[]
  public gridData=[]
  public fkObj={}
  public inputForm=[]
  public dailogTitle="Add List of Values";
  public opened=false;
  public type=""
  ngOnInit(): void {
    if(UserModuleObj.list_of_values){
      this.permission=UserModuleObj.list_of_values;
    }
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)})
    this.getLookupItem()
  }

  editColumn(data){
    console.log(data);
    if(data.action==="edit"){
      let dataBindObj={};
        for(let key in data.dataItem){
            dataBindObj[key]=data.dataItem[key];
        }
        //  this.formData=data.dataItem;
        this.inputForm=this.formData.map((d)=>{
            if(dataBindObj[d.name]!=undefined){
                 if(d.type=="text" || d.type=="lable"){
                   d.value=dataBindObj[d.name];
                 }
                 else if(d.type==="time"){
                  let d1=new Date();
                  if(dataBindObj[d.name]){
                  let tArray=dataBindObj[d.name].split(":").map((t)=>parseInt(t));
                  d1.setHours(tArray[0])
                  d1.setMinutes(tArray[1]);
                  d1.setSeconds(tArray[2])
                  }
                  d.value=d1;
                }else if(d.type==="date")
                {
                  d.value= this.StringDate(dataBindObj[d.name]);
                }
                 else if(d.type=="list"){
                   let v=d.data.filter((ft)=>{
                     return ft.ID==dataBindObj[d.name];
                   })
                   if(v.length>0){
                     d.value=v[0];
                   }
                   else{
                     d.value={};
                   }
                 }
            }
            return d;
        })
         this.openRoleDailog("update");
    }
    else if(data.action==="remove"){
      let dataBindObj={};
      for(let key in data.dataItem){
          dataBindObj[key]=data.dataItem[key];
      }
      //  this.formData=data.dataItem;
      this.deleteItem= data.dataItem;
      // this.formData.map((d)=>{
      //     if(dataBindObj[d.name]!=undefined){
      //          if(d.type=="text" || d.type=="lable"){
      //            d.value=dataBindObj[d.name];
      //          }
      //          else if(d.type=="list"){
      //            let v=d.data.filter((ft)=>{
      //              return ft.ID==dataBindObj[d.name];
      //            })
      //            if(v.length>0){
      //              d.value=v[0];
      //            }
      //            else{
      //              d.value={};
      //            }
      //          }
      //     }
      //     return d;
      // })
    //   this.formData=data.dataItem;
      this.removeDialogOpen=true;
       //this.removeDialog=true;
    }
    else if(data.action==="remove2"){
      let dataBindObj={};
      for(let key in data.dataItem){
          dataBindObj[key]=data.dataItem[key];
      }
      this.inputForm=this.formData.map((d)=>{
        if(dataBindObj[d.name]!=undefined){
          if(d.type=="text" || d.type=="lable"){
            d.value=dataBindObj[d.name];
          }
          else if(d.type==="time"){
           let d1=new Date();
           if(dataBindObj[d.name]){
           let tArray=dataBindObj[d.name].split(":").map((t)=>parseInt(t));
           d1.setHours(tArray[0])
           d1.setMinutes(tArray[1]);
           d1.setSeconds(tArray[2])
           }
           d.value=d1;
         }
         else if(d.type==="date")
                {
                  d.value= this.StringDate(dataBindObj[d.name]);
                }
          else if(d.type=="list"){
               let v=d.data.filter((ft)=>{
                 if(d.name == 'ISACTIVE')
                 {
                  return ft.ID==false;
                 }
                })
               if(v.length>0){
                 d.value=v[0];
               }
               else{
                 d.value={};
               }
             }
        }
        return d;
    })
       this.removeDialog=true;
    }
  }
  openRoleDailog(type){
    this.type=type;
       if(type==="new"){
         this.dailogTitle=`Add list (${this.selectedListValue["name"]})`
         this.inputForm=JSON.parse(JSON.stringify(this.formData))
       
       }
       else if(type=="update"){
        this.dailogTitle=`Update list (${this.selectedListValue["name"]})`
       }
       this.opened=true
  }
  onDialogClose(){
     this.opened=false
  }
  changeListValue(e){
  
     this.selectedListValue={...e};
     this.getLookupData(e.schema,e.tableName)
  }
  getLookupItem(){
    HttpClient.get("/api/MasterData/GetLookupItems",true)
    .then((result:any)=>{

       this.listValue=result.map((d)=>{
          return     {
            id:d.id,
            name:d.description,
            tableName:d.name,
            schema:d.schemaName
          }
       })
      //  this.listValue.push( {
      //   id:13,
      //   name:'Status Master',
      //   tableName:'Status Master',
      //   schema:'Status Master',
      // })
       this.filterdData = this.listValue.slice()
    })
    .catch((err)=>{

    })

  }

  getFKData(schema,tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
      .then((result:any)=>{
         resolve(result)
      })
      .catch((err)=>{
         reject(err);
      })
    })
  }

  getLookupData(schema,tablename){
   HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
   .then((result:any)=>{
        let gridData=[]
        if(result.gridData){
          gridData=JSON.parse(result.gridData);
         }
        if(result.fkInformarions){
            let fktem=JSON.parse(result.fkInformarions)
            fktem=fktem.filter((d)=>d["FKTABLENAME"])
            Promise.all(
              fktem.map((d)=>{
                return this.getFKData(d["FKSCHEMA"],d["FKTABLENAME"])
              })
            )
            .then((fkData:Array<any>)=>{
             
               fktem=fktem.map((d,i)=>{
                let data=[]
                if(fkData[i] && fkData[i].gridData){
                  data= JSON.parse(fkData[i].gridData);
                    let dataObj={}
                    d["data"]=data.map((d)=>{
                      let id=d.id || d.ID || d.EMPLOYEEGLOBALID;
                      let name=d.NAME || d.CODE || d.DESCRIPTION || d.FIRSTNAME;
                      dataObj[id]=name;
                      return {
                        ID:id,
                        NAME:name
                      }
                    })
                    d["dataObj"]=dataObj;
                  }
                  else{
                    d["data"]=[]
                    d["dataObj"]={}
                  }
                  return d;
               })
               this.manuculateLookUpData(gridData,fktem);
            })
            .catch((err)=>{
               this.manuculateLookUpData([],[])
            })
        }

        else{
          this.manuculateLookUpData(gridData,[])
        }
   })
   .catch((err)=>{
     console.log(err)
   })
  }
  uc(text){
    return text.toUpperCase();
  }

  manuculateLookUpData(gridData,fkResult){
    let fkGlobleObj=fkResult.reduce((acc,d)=>{
             acc[this.uc(d.FKCOLUMNNAME)]=d;
             return acc;
    },{})
    this.deleteFlag=false;
    this.gridData=gridData;
    this.gridColumn=[];
    this.formData=[];
        if(this.gridData.length>0){
          for(let col in this.gridData[0]){
            if(this.uc(col)=="ID"){
             this.formData.push({name:this.uc(col),type:"lable",value:0})
            }
            else if(this.uc(col)=="ISACTIVE"){
              let aData=[
                {
                  ID:true,
                  NAME:"True"
                },
                {
                  ID:false,
                  NAME:"False"
                }
              ]
              this.formData.push({name:this.uc(col),type:"list",value:{},data:aData})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),width:200})
              this.deleteFlag=true;
            }
            else if(this.uc(col)=="TIMEFROM" || this.uc(col)=="TIMETO"){              
              this.formData.push({name:this.uc(col),type:"time",value:""})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),isFilter:true,width:200})
            }
            else if(this.uc(col)=="DATEFROM" || this.uc(col)=="DATETO"){              
              this.formData.push({name:this.uc(col),type:"date",value:""})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),isFilter:true,width:200})
            }
            else if(this.uc(col)=="CREATEDDATE" || this.uc(col)=="CREATEDON" || this.uc(col)=="MODIFIEDDATE" || this.uc(col)=="CREATEDBY" || this.uc(col)=="MODIFIEDBY" || this.uc(col)=="MODIFIEDAT" || this.uc(col)=="NAMECREATEDBY" || this.uc(col)=="NAMEMODIFIEDBY"){
                        this.formData.push({name:col,type:"lable",value:new Date()})
            }
            else if(this.uc(col)=="DATEFROM"|| this.uc(col)=="DATETO"){
              this.formData.push({name:this.uc(col),type:"date",value:""})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),isFilter:true,width:200})
           }           
            else if(fkGlobleObj[this.uc(col)]){
              this.formData.push({name:this.uc(col),type:"list",value:{},data:fkGlobleObj[this.uc(col)]["data"]})
              this.gridColumn.push({field:this.uc(col+"_NAME"),title:this.uc(col),width:200})
            }
            else{
              this.formData.push({name:this.uc(col),type:"text",value:""})
              this.gridColumn.push({field:this.uc(col),title:this.uc(col),isFilter:true,width:200})
            }
          }
        }
        if(fkResult.length>0){
          gridData=gridData.map((d,i)=>{
              fkResult.forEach((fk) => {
                let key=this.uc(fk.FKCOLUMNNAME)
                 if(d[key]!=undefined){
                   let val=d[key];
                   d[key+"_NAME"]=fkGlobleObj[key]["dataObj"][val];
                 }
                 
              });
              // if(d.ISACTIVE!=undefined){
              //   d["deleteKey"]= (d.ISACTIVE==true)?true:false;
              // }
               if(d.DATEFROM!=undefined){
                d["DATEFROM"]= this.dateFormating(d.DATEFROM);
              }
               if(d.DATETO!=undefined){
                d["DATETO"]= this.dateFormating(d.DATETO);
              }

              return d;
          });
       
        }
  }
  submitFrom(){
    
    let d=this.inputForm.reduce((acc,inp)=>{
        if(inp.type=="text" || inp.type=="lable" ){
           acc[inp.name]=inp.value;
        }
        else if(inp.type=="time"){
          let d=new Date(inp.value);
          acc[inp.name]=d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
        }
        else if(inp.type=="date")
        {
          acc[inp.name] = inp.value == null?"":new Date(inp.value)
        }
        else if(inp.type=="list"){
          acc[inp.name]=inp.value.ID;
        }
        return acc;
    },{})
  
    this.saveForm(d)
  }
  saveForm(data){
    data["CREATEDATE"]=new Date();
    data["MODIFIEDDATE"]=new Date();
    data["CREATEDBY"]=1;
    data["MODIFIEDBY"]=1;
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":1,
      "newData":JSON.stringify(data)
    }
     let  path="";
    if( this.type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(this.type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      if(result.messageKey){
        HttpClient.insertAduitLog("List of Values",this.type+" "+this.selectedListValue.tableName,this.type+" "+this.selectedListValue.tableName+" List of Values").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content:result.messageKey,
          type: { style: "success", icon: true },
        });
      }
      else if(result.message){
        this._notif.show({
          content:result.message,
          type: { style: "error", icon: true },
        });
      }
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.opened=false;
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  removeIsActive()
  {
    this.type ="update";
    this.submitFrom();
    this.removeDialog=false;
  }
  removeLookup(){
  //   let d=this.inputForm.reduce((acc,inp)=>{
  //     if(inp.type=="text" || inp.type=="lable"){
  //        acc[inp.name]=inp.value;
  //     }
  //     else if(inp.type=="list"){
  //       acc[inp.name]=inp.value.ID;
  //     }
  //     return acc;
  // },{})
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":parseInt(getCookie(userid)),
      "oldData":JSON.stringify(this.deleteItem),    
      "newData":null//JSON.stringify(this.deleteItem)
    }
     let  path=`/api/MasterData/DeleteLookupData`
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      if(result.messageKey){
        HttpClient.insertAduitLog("List of Values","Delete "+this.selectedListValue.tableName,"Delete "+this.selectedListValue.tableName+" List of Values").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content:result.messageKey,
          type: { style: "success", icon: true },
        });
      }
      else if(result.message){
        this._notif.show({
          content:result.message,
          type: { style: "error", icon: true },
        });
      }
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.removeDialogOpen=false;
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  handleselectedfilterFilter(value){
     this.filterdData = this.listValue.filter(
        (s) => s. name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    
  }
 
  pading(n) {
    if (n > 9)
      return n;
    else
      return "0" + n
  }
  dateFormating(da){
    let date=new Date(da);
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }
  StringDate(dateStr){
    const [day, month, year] = dateStr.split("-")
    return new Date(year, month - 1, day)
  }
}
