
<div class="card m-3">
    <div class="card-header" >
        <h5>Attachments</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Attachments
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <!-- <form>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>File Type</label>
                    <kendo-dropdownlist [data]="statusDropDown" class="form-control" [textField]="'name'" [valueField]="'id'"
                        [valuePrimitive]="true">
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>File Name</label>
                    <input kendoTextBox class="form-control" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Attachment</label>
                    <kendo-upload [autoUpload]="false" [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl">
                        <ng-template kendoUploadFileInfoTemplate let-files>
                            <div>Name: {{ files[0].name }}</div>
                        </ng-template>
                    </kendo-upload>
                </div>
    
                <div class="form-group col-5">
    
                </div>
    
    
            </div>
        </form> -->
        <app-attachment-table-grid 
        [data]="attechmentDoc.alldata" 
        [column]="column2" 
        [size]="5"
        >
  </app-attachment-table-grid>
        
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            <div class="form-group col-12">
                <label>File Type</label>
                <kendo-dropdownlist 
                [data]="documentFormate" 
                class="form-control" 
                [textField]="'name'" 
                [valueField]="'id'"
                [(ngModel)]="attechForm.FILETYPE"
                [valuePrimitive]="true"
            >
            </kendo-dropdownlist>
            </div>
            <div class="form-group col-12">
                <label>File Name</label>
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
            </div>
            <div class="form-group col-12">
                <label>Attachment</label>
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
            </div>
         </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true" (click)="addAttechment()">
            Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            Delete Description
        </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton >Delete</button>
    </kendo-dialog-actions>
</kendo-dialog>