import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-spare-part-replacement',
  templateUrl: './spare-part-replacement.component.html',
  styleUrls: ['./spare-part-replacement.component.scss']
})
export class SparePartReplacementComponent implements OnInit {


  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;

  public column2=[
    {field:"c_1",title:"Part No.",isFilter:true,width:120},
    {field:"c_2",title:"Part Description", isFilter:true,width:200},
    {field:"c_3",title:"Estimated Life Span", isFilter:true,width:200},
    {field:"c_4",title:"Estimated Life Option", isFilter:true,width:200},
    {field:"c_5",title:"Estimated Life Expiry Date", isFilter:true,width:250},
    {field:"c_6",title:"Quantity", isFilter:true,width:120}
  ];
  public areagrid=[
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--"
    },
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--"
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
