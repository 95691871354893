import { Component, OnInit } from '@angular/core';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import {Checklist,ChecklistObj,ChecklistError} from "src/ApiModel/Model/CheckList"
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {ChecklistSubService,ChecklistSubServiceObj} from "src/ApiModel/Model/ChecklistSubService"
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import { getCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import {addBlukCheklist} from "src/ApiModel/ImportApi/Checklist"
import { removeLookup } from 'src/ApiModel/Util';
import { NotificationService } from '@progress/kendo-angular-notification';
class SLAError{
  subservice:boolean=false;
}

@Component({
  selector: 'app-create-checklist',
  templateUrl: './create-checklist.component.html',
  styleUrls: ['./create-checklist.component.scss']
})
export class CreateChecklistComponent implements OnInit {
//model data
public slaOpreationType="insert"
public actionsLayout = "normal";
public slaError=new SLAError();
public slaData=new ChecklistSubService()
public permission:Permission=new Permission();
public slaModel=false;
public opened=false;  
public currentTab=1;
public data:Checklist=new Checklist();
public dailogTitle="Add Checklist";
public type=""
public urlimg=""
public model:Model=new Model();
public checklistSubserviceObj=new ChecklistSubServiceObj();
public error:ChecklistError=new ChecklistError();
public workOrderType={
  assets:{name:"assest",id:1,title:"Assets"},
  location:{name:"location",id:2,title:"Location"}
}
// grid column filed 
public columnField={
  work_order_no:"workOrderNo",
  consequance:"ticketConsequence.name",
  asset:"asset",
  location:"location",
  service:"service",
} 

//grid data
public column2=[ 
{field:"checkListNo",title:"Checklist No",isFilter:true,width:200},
{field:"description",title:"Description", isFilter:true,width:200},
{field:"assignToObj.name",title:"Assign To", isFilter:true,width:200},
{field:"consequanceObj.NAME",title:"Consequence  Category", isFilter:true,width:200},
{field:"serviceObj.NAME",title:"Service", isFilter:true,width:200},
// {field:"respondSladuringWorkingHours",title:"RespondSladuringWorkingHours", isFilter:true,width:200},
// {field:"respondSlaafterWorkingHours",title:"RespondSlaafterWorkingHours", isFilter:true,width:200},
// {field:"completionSladuringWorkingHours",title:"CompletionSladuringWorkingHours", isFilter:true,width:200},
// {field:"completionSlaafterWorkingHours",title:"CompletionSlaafterWorkingHours", isFilter:true,width:200}

];
public areagrid=[

];


///form  data
public checkListNo=""
public description=""
public facilityObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
}
public blockObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public levelObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public areaObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public locationObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public serviceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public assetDataObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public assigineeObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}

public assignToObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
public chargeAbleObj={
  alldata:[],
  filterData:[
    {id:1,name:"Yes"},
    {id:2,name:"No"}
  ],
  selectedObj:null,
  objectData:{}
}

public workOrderTypeObj={
  alldata:[],
  filterData:[
     {id:1,name:"Routine Maintenance"},
     {id:2,name:"Corrective Maintenance"},
     {id:3,name:"Reactive Maintenance"}
  ],
  selectedObj:null,
  objectData:{}
}
public consequanceObj={
  alldata:[],
  filterData:[],
  selectedObj:null,
  objectData:{}
}
  tempUpdateObj: any;
  importopened: boolean=false;
constructor(public _notif:NotificationService) { 
  eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
  eventListner(EVENT_NAME.addAssetReg_attachment, (e) => { this.checklistMapping(e) })
  eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) })
}
asset_classi = ['Select'];
ngOnInit(): void {
  if(UserModuleObj.checklist___sla){
    this.permission=UserModuleObj.checklist___sla;
  }
  
  Promise.all([
    this.getLoockup("dbo", "facility"),
    this.getLoockup("dbo", "block"),
    this.getLoockup("dbo", "blocklevel"),
    this.getLoockup("dbo", "levelarea"),
    this.getLoockup("dbo","ServicesType"),
    this.getAsset(),
    this.getAssignTo(),
    this.getAssignne(),
    this.getLoockup("PMC","TicketConsequenceType"),
    this.getLoockup("dbo", "arealocation")
  ])
  // this.getLoockup("dbo", "facility")
  .then((result:any)=>{
    //facility
       this.facilityObj.alldata=result[0];
       this.facilityObj.filterData=result[0];
       this.facilityObj.selectedObj={}
    //block
        this.blockObj.objectData=result[1].reduce((acc,d)=>{
          acc[d.ID]=d;
          return acc;
      },{})
          this.blockObj.alldata=result[1];
    //blocklevel
    this.levelObj.objectData=result[2].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.levelObj.alldata=result[2];

    //arealocation
    this.locationObj.objectData=result[3].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.locationObj.alldata=result[3];
    //ServicesType

    this.serviceObj.objectData=result[4].reduce((acc,d)=>{
      acc[d.ID]=d;
      return acc;
   },{})
       this.serviceObj.alldata=result[4];
       this.serviceObj.filterData=[]


    //asset
    this.assetDataObj.objectData=result[5].reduce((acc,d)=>{
      acc[d.id]=d;
      return acc;
   },{})
    this.assetDataObj.alldata=result[5];
    this.assetDataObj.filterData=result[5];
   //assin to
   this.assignToObj.objectData=result[6].reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
   this.assignToObj.alldata=result[6]
   this.assignToObj.filterData=result[6];

   //assignee

   this.assigineeObj.objectData=result[7].reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
  this.assigineeObj.alldata=result[7];
  this.assigineeObj.filterData=result[7];
   //ticket consequance
   this.consequanceObj.objectData=result[8].reduce((acc,d)=>{
    acc[d.ID]=d;
    return acc;
 },{})
  this.consequanceObj.alldata=result[8];
  this.consequanceObj.filterData=[]
//level area
this.areaObj.objectData=result[9].reduce((acc,d)=>{
acc[d.ID]=d;
return acc;
},{})
 this.areaObj.alldata=result[9];

  //call work order
  this.getWorkOrder()

  //bind static object
  this.workOrderTypeObj.objectData=this.workOrderTypeObj.filterData.reduce((acc,d)=>{
    acc[d.id]=d;
    return acc;
 },{})
  })
  .catch((err)=>{
    console.log(err)
  })
  this.model.getData(ModelApi.subService,ModelApi.subService2,ModelApi.subService3)
  .then((result)=>{
      console.log(this.model);
  })
}

deleteColumn(d){
  //this.data.deleteData(d.dataItem)
  removeLookup("PMC","CheckList",d.dataItem)
  .then((result:any)=>{
    if(result.message){
      this._notif.show({
        content: "Checklist cannot be deleted because it is tied to Maintenance Task.",
        type: { style: "error", icon: true },
      });
  }
  else{
    this._notif.show({
      content: "Successfully Delete.",
      type: { style: "success", icon: true },
    });
    this.getWorkOrder();
    //this.getData();
  }
  })
  .catch((err)=>{
    this._notif.show({
      content: "Checklist cannot be deleted because it is tied to Maintenance Task",
      type: { style: "error", icon: true },
    });
  })
}



checklistMapping(data){
  this.slaError=new SLAError()
  this.slaData=new ChecklistSubService();
  this.model.checklist.selectedObj=data;
  this.model.subService.selectedObj=null;
  this.model.subService2.selectedObj=null;
  this.model.subService3.selectedObj=null;
  this.slaData.CHECKLISTID=data.ID;
  this.slaData.ID=0;
  this.slaOpreationType="insert";
  this.checklistSubserviceObj.getData()
  .then((result)=>{
    let cobj=this.checklistSubserviceObj.alldata.filter((d)=>d.CHECKLISTID==data.id)
    if(cobj.length>0){
      this.slaData.ID=cobj[0].ID;
      this.slaOpreationType="update";
      this.model.subService.selectedObj=this.model.subService.objectData[cobj[0].SUBSERVICEID];
      this.model.subService2.selectedObj=this.model.subService2.objectData[cobj[0].SUBSERVICE2ID];
      this.model.subService3.selectedObj=this.model.subService3.objectData[cobj[0].SUBSERVICE3ID];
    }

  })
 this.slaModel=true;
}
getLoockup(schema, tablename){
  return new Promise((resolve,reject)=>{
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
     })
     .catch((err)=>{
        reject(err)
     })
  })
}

getAsset(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Assets/GetAssets",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}

getAssignTo(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/BPM/GetBpms",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}

getAssignne(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Users/GetAllUser",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}


valuechange(type,data){
  if(type=='facility'){
    this.facilityObj.selectedObj=data
    this.blockObj.filterData=this.blockObj.alldata.filter((d)=>d.FACILITYID==data.ID)
    this.blockObj.selectedObj={};
    this.levelObj.selectedObj={};
    this.areaObj.selectedObj={};
    this.locationObj.selectedObj={};
    this.facilityObj={...this.facilityObj}      
  }
  else if(type=="block"){
      this.blockObj.selectedObj=data
      this.levelObj.filterData=this.levelObj.alldata.filter((d)=>d.BLOCKID==data.ID)
      this.levelObj.selectedObj={};
      this.areaObj.selectedObj={};
      this.locationObj.selectedObj={};
      this.blockObj={...this.blockObj}      
  }
  else if(type==="level"){
    this.levelObj.selectedObj=data
    this.areaObj.filterData=this.areaObj.alldata.filter((d)=>d.BLOCKLEVELID==data.ID)
    this.areaObj.selectedObj={};
    this.locationObj.selectedObj={};
    this.areaObj={...this.areaObj}      
  }
  else if(type==="area"){
    this.areaObj.selectedObj=data
    this.locationObj.filterData=this.locationObj.alldata.filter((d)=>d.LEVELAREAID==data.ID)
    this.locationObj.selectedObj={};
    this.locationObj={...this.locationObj}      
  }
  else if(type==="location"){
    this.locationObj.selectedObj=data
  }
  else if(type==="workOrderType"){
    this.workOrderTypeObj.selectedObj=data;
    this.consequanceObj.filterData=this.consequanceObj.alldata.filter((d)=>d.TICKETTYPEID==data.id)
  }
  else if(type==="consequences"){
    this.consequanceObj.selectedObj=data;
  }
  else if(type=="assignee"){
   this.assigineeObj.selectedObj=data;
  }
  else if(type=="assignTo"){
    this.assignToObj.selectedObj=data;
  }
  else if(type=="service"){
    this.serviceObj.selectedObj=data;
    
  }
  else if(type==="assets"){
    this.assetDataObj.selectedObj=data;
  }
  else if(type==="subService"){
    this.model.subService.selectedObj=data;
    this.model.subService2.filterData=this.model.subService2.alldata.filter((e)=>{
      return e.SUBSERVICEID == data.ID
  })
    
  }
  else if(type==="subService2"){
    this.model.subService2.selectedObj=data;
    this.model.subService3.filterData=this.model.subService3.alldata.filter((e)=>{
        return e.SUBSERVICE2ID == data.ID
    })
  }
  else if(type==="subService3"){
    this.model.subService3.selectedObj=data;
  }
}
saveData(){
  this.error=new ChecklistError();
  let error=false;
 
  let obj={
    "id": 0,
    "userId": 1,
    "ticketConsequenceTypeId": 0,
    "description": this.data.description,
    "serviceTypeId": 0,
    "bpmid": 0,
    "createdDate": new Date(),
    "createdBy": 1,
    "modifiedDate": new Date(),
    "modifiedBy": 1,
    respondSladuringWorkingHours:this.data.respondSladuringWorkingHours,
    respondSlaafterWorkingHours:this.data.respondSlaafterWorkingHours,
    completionSladuringWorkingHours:this.data.completionSladuringWorkingHours,
    completionSlaafterWorkingHours:this.data.completionSlaafterWorkingHours
  }
  if(!this.data.description){
    this.error.description = true
    error = true;
  }

  if(this.serviceObj.selectedObj && this.serviceObj.selectedObj.ID){
    obj.serviceTypeId=this.serviceObj.selectedObj.ID;
  }
  else{
    this.error.serviceTypeId = true
    error=true;
  }
  if(this.workOrderTypeObj.selectedObj && this.workOrderTypeObj.selectedObj.id){
    
  }
  else{
    this.error.workordertypeid = true
    error =true
  }
  if(this.consequanceObj.selectedObj && this.consequanceObj.selectedObj.ID){
     obj.ticketConsequenceTypeId=this.consequanceObj.selectedObj.ID;
  }
  else{
    this.error.ticketConsequenceTypeId = true
    error=true;
  }
  if(this.assignToObj.selectedObj && this.assignToObj.selectedObj.id){
    obj.bpmid=this.assignToObj.selectedObj.id
  }
  // if(this.chargeAbleObj.selectedObj && this.chargeAbleObj.selectedObj.id){
  // }
  // else{
   
  //   error=true;
  // }
  if(this.data.respondSladuringWorkingHours<=0){
    this.error.respondSlaafterWorkingHours=true;
  }
  if(this.data.respondSladuringWorkingHours<0){
    this.error.respondSladuringWorkingHours=true
  }
  if(!error){
    this.addWorkOrder(obj)
  }
  
}

addWorkOrder(objData){
  let  path="";
  if( this.type==="new"){
    objData["id"]=0
     path=`/api/MasterData/SaveLookupData`
  }
  else if(this.type=="update"){
    objData["id"]=this.tempUpdateObj.id;
    path="/api/MasterData/UpdateLookupData"
  }
    let obj={
      "schemaName":"PMC",
      "tableName":"CheckList",
      "userId":parseInt(getCookie(userid)),
      "newData":JSON.stringify(objData)
    }

    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      console.log(result);
       this.opened=false;
       this._notif.show({
        content: "Successfully saved.",
        type: { style: "success", icon: true },
      });
       this.getWorkOrder()
    })
    .catch((err)=>{
      console.log(err);
      this._notif.show({
        content: "Fail",
        type: { style: "error", icon: true },
      });
    })
}


importChecklistData(objData){
  let  path="";
    objData["id"]=0
     path=`/api/MasterData/SaveLookupData`
    let obj={
      "schemaName":"PMC",
      "tableName":"CheckList",
      "userId":parseInt(getCookie(userid)),
      "newData":JSON.stringify(objData)
    }

    return new Promise((resolve,reject)=>{
      HttpClient.fetchData(path,"post",obj,true,true)
      .then((result:any)=>{
        // console.log(result);
        //  this.opened=false;
        //  this._notif.show({
        //   content: "Successfully saved.",
        //   type: { style: "success", icon: true },
        // });
        //  this.getWorkOrder()
        resolve(result)
      })
      .catch((err)=>{
        resolve(err);
        // console.log(err);
        // this._notif.show({
        //   content: "Fail",
        //   type: { style: "error", icon: true },
        // });
      })
    })

}
onDialogClose(type=""){
  this.opened=false
}
onTabSelect(e){
  if(e.title===this.workOrderType.assets.title){
    this.currentTab=this.workOrderType.assets.id;
  }
  else if(e.title===this.workOrderType.assets.title){
    this.currentTab=this.workOrderType.location.id;
  }
}
openRoleDailog(type){
  this.type=type;
  if(type==='new'){
    this.facilityObj.selectedObj=null;
    this.checkListNo = null
    this.blockObj.selectedObj=null;
    this.levelObj.selectedObj=null;
    this.areaObj.selectedObj=null;
    this.locationObj.selectedObj=null;
    this.serviceObj.selectedObj=null;
    this.workOrderTypeObj.selectedObj=null;
    this.consequanceObj.selectedObj=null;
    this.chargeAbleObj.selectedObj=null;
    this.assigineeObj.selectedObj=null;
    this.assignToObj.selectedObj=null;
    this.assetDataObj.selectedObj=null;
    this.description=""
    this.data=new Checklist();
    this.dailogTitle="Add Checklist"
    this.currentTab=this.workOrderType.assets.id;
    this.opened=true
  }
}

reset(){
    this.facilityObj.selectedObj=null;
    this.checkListNo = null;
    this.blockObj.selectedObj=null;
    this.levelObj.selectedObj=null;
    this.areaObj.selectedObj=null;
    this.locationObj.selectedObj=null;
    this.serviceObj.selectedObj=null;
    this.workOrderTypeObj.selectedObj=null;
    this.consequanceObj.selectedObj=null;
    this.chargeAbleObj.selectedObj=null;
    this.assigineeObj.selectedObj=null;
    this.assignToObj.selectedObj=null;
    this.assetDataObj.selectedObj=null;
    this.description=""
    this.data=new Checklist();
}

getWorkOrder(){
  HttpClient.get("/api/PMC/getchecklistall",true,true)
  .then((result:any)=>{
     //console.log(result)
     this.areagrid=result.map((d)=>{
        d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
        d["serviceObj"]=this.serviceObj.objectData[d.serviceTypeId];
        d["consequanceObj"]=this.consequanceObj.objectData[d.ticketConsequenceTypeId];
        return d;
     });
  })
  .catch((err)=>{
     console.log(err)
  })
}
editColumn(d){
  let data=d.dataItem;
  this.data = new Checklist();
  this.tempUpdateObj=JSON.parse(JSON.stringify(d.dataItem));
  this.checkListNo=data["checkListNo"]
  this.data.description=data["description"];
  //this.assigineeObj.selectedObj=data["assigneeObj"]
  this.assignToObj.selectedObj=data["assignToObj"]
  this.serviceObj.selectedObj=data["serviceObj"]
  this.consequanceObj.selectedObj=data["consequanceObj"]
  if(this.consequanceObj.selectedObj && this.consequanceObj.selectedObj.TICKETTYPEID)
     this.workOrderTypeObj.selectedObj=this.workOrderTypeObj.objectData[this.consequanceObj.selectedObj.TICKETTYPEID]
  //this.assetDataObj.selectedObj=data["assetDataObj"]
  //this.locationObj.selectedObj=data["locationObj"]
//   if(this.workOrderType.assets.name===data["referenceType"]){
//     this.currentTab=this.workOrderType.assets.id;
//    // d["assetDataObj"]=this.assetDataObj.objectData[d["referenceId"]]
//  }
//  else{
//    let lob=data["locationObj"];
//   this.currentTab=this.workOrderType.location.id;
//   this.facilityObj.selectedObj=this.facilityObj.objectData[lob.FACILITYID];
//   this.blockObj.selectedObj=this.blockObj.objectData[lob.BLOCKID]
//   this.levelObj.selectedObj=this.levelObj.objectData[lob.BLOCKLEVELID]
//   this.areaObj.selectedObj=this.areaObj.objectData[lob.LEVELAREAID]
//  // d["locationObj"]=this.locationObj.objectData[d["referenceId"]]
//  }
  this.opened=true;
  this.dailogTitle="Update Checklist"
  this.type="update"
}

resetSlaData(){
  this.model.checklist.selectedObj=null;
  this.model.subService.selectedObj=null;
  this.model.subService2.selectedObj=null;
  this.model.subService3.selectedObj=null;
  this.slaData=new ChecklistSubService();
}

saveSlaData(){
   let error=false;
   this.slaError=new SLAError()
   this.slaData.CHECKLISTID=this.model.checklist.selectedObj.id;
  if(this.model.subService.selectedObj && this.model.subService.selectedObj.ID){
     this.slaData.SUBSERVICEID=this.model.subService.selectedObj.ID
  }
  else{
    error=true;
    this.slaError.subservice=true;
  }
  if(this.model.subService2.selectedObj && this.model.subService2.selectedObj.ID){
    this.slaData.SUBSERVICE2ID=this.model.subService2.selectedObj.ID
 }

 if(this.model.subService3.selectedObj && this.model.subService3.selectedObj.ID){
  this.slaData.SUBSERVICE3ID=this.model.subService3.selectedObj.ID
  }
 if(error){
   return true;
 }
    this.slaData.saveData()
    .then((result)=>{
      console.log(result)
      this.resetSlaData()
      this._notif.show({
        content: "Successfully saved.",
        type: { style: "success", icon: true },
      });
      this.slaModel=false;
    })
    .catch((err)=>{
      this._notif.show({
        content: "Fail",
        type: { style: "error", icon: true },
      });
      console.log(err)
    })
  
}
import(){
  this.importopened = true
  }
  importUpdate(){
    this.importopened = true
  }
  valueChange(event){
    //console.log(this.consequanceObj,this.serviceObj,this.assignToObj.objectData)
   // console.log("check list===>",event);
   this.type="new"
    let bulkCheck=addBlukCheklist(event,this.assignToObj.objectData,this.consequanceObj.objectData,this.serviceObj.objectData);
    console.log("bluck checklist",bulkCheck);

    Promise.all(
      bulkCheck.map((data)=>{
        return this.importChecklistData(data);
      })
    )
    .then((result)=>{
         this.importopened=false;
         this._notif.show({
          content: "Successfully saved.",
          type: { style: "success", icon: true },
        });
         this.getWorkOrder()
    })
    .catch((err)=>{

    })
    //console.log(this.con)
  //  let bulkUser= addBlukUser(event,this.genderObj,this.statusObj,this.contractorObj,this.designationObj,this.nationalityObj,
  //   this.locObj,this.facility_obj,this.userRole_Obj,this.compent_Obj,this.speciality_Obj,this.service_Obj)
  //   console.log("bulk===user==>",bulkUser)
    // let reader = new FileReader();
    // reader.onload = function(){
    //   let output: any = document.getElementById('blah');
    //   output.src = reader.result;
    // }
    // if(event.slice(8)){
    //   reader.readAsDataURL(event.slice(8));
    // }
  
  }
  downloadTemplate(){
    window.open("/assets/template/checklist.xlsx")
  }
}
