<div class="card m-3"  >
  <!-- <h5 class="card-header">Blocks</h5> -->
  <div class="card-header" style="display: flex;justify-content: space-between;">
      <h5>Holiday Calendar</h5>
      <div> 
        <button kendoButton  primary="true" (click)="openRoleDailog('new')" *ngIf="permission && permission.isCreate" class="header-right-btn">
          <span class="add_btn">+</span> Calendar
        </button>
      </div>
  </div>
  <div class="card-body register-form-body">
    <app-holiday-list-grid
      [data]="calenderGridData"
      [column]="column2"
      [size]="10"
      [editFlag] ="permission && permission.isUpdate"
      [deleteFlag]="permission && permission.isDelete"
      > 
    </app-holiday-list-grid>
  </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="800">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>
      <div class="form-row px-5">
          <div class="form-group px-5" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
            <label>Name<span style="color: red;">*</span></label>
            <div style="width:100%">
              <input kendoTextBox  [(ngModel)]="calenderFormData.name" class="form-control"/>
              <kendo-formerror *ngIf="error.name">*Required</kendo-formerror>
            </div>
          </div>
      </div>
      <div class="form-row px-5">
        <div class="form-group px-5" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
          <label>Year<span style="color: red;">*</span></label>
          <div>
            <kendo-dropdownlist
            [data]="yearDropDown"
            [textField]="'name'"
            [(ngModel)]="calenderFormData.year"
            [valueField]="'name'"
            [valuePrimitive]="true"
            [filterable]="true"
            (valueChange)="onValueChange($event)"
            [disabled]="type!='new'"
          >
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="error.year">*Required</kendo-formerror>
         </div>
        </div>
    </div>
    <div class="form-row px-5">
      <div class="form-group px-5" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
        <label>Remark</label>
        <div style="width:100%">
          <input kendoTextBox  [(ngModel)]="calenderFormData.remark" class="form-control"/>
        </div>
      </div>
  </div>
    <div  *ngIf="calenderFormData.year" class="card-body register-form-body">
      <kendo-formerror *ngIf="error.holidayCalendars" style="margin: 0.5rem;font-size: 1rem;">Please select atleast one date</kendo-formerror> 
    <!-- <app-holiday-add-grid
    [data]="holidayGridData"
    [column]="column"
    [min]="minDate"
    [max]="maxDate"
    [size]="10"
    ></app-holiday-add-grid> -->
     <app-holiday-add-grid
    [data]="holidayGridData"
    [column]="column"
    [min]="minDate"
    [max]="maxDate"
    [size]="10"
    ></app-holiday-add-grid>
  </div>   
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onDialogClose('new')">Cancel</button>
    <button *ngIf="type=='new'" (click)="onReset()" kendoButton  primary="true">
      Reset
  </button>
    <button *ngIf="type=='new'" (click)="onSubmit()" kendoButton  primary="true">
        Save
    </button>
    <button *ngIf="type=='update'" (click)="onSubmit()" kendoButton  primary="true">
        Update
    </button>
  </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Delete Description
    </div>
  </kendo-dialog-titlebar>
  <p>Are you sure you want to delete?</p>
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="removeCalender()">Yes</button>
    <button  (click)="onDialogClose('remove')" kendoButton  primary="true">
      No
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="viewDialogOpen"  (close)="onDialogClose('view')" [width]="1024">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
     Holiday List
    </div>
  </kendo-dialog-titlebar>
  <app-table-grid
  [data]="holidayViewGrid"
  [column]="viewColumnArray"
  [size]="10"
  [action]="false"
></app-table-grid>
</kendo-dialog>