import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-daily-ins',
  templateUrl: './add-daily-ins.component.html',
  styleUrls: ['./add-daily-ins.component.scss']
})
export class AddDailyInspectionComponent implements OnInit {
  public onTabSelect(e) {
    console.log(e);
  }
  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
 year_data= ['2021'];
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"c_3",title:"Asset no", isFilter:true,width:200},
    {field:"c_4",title:"Asset description", isFilter:true,width:200},
    {field:"c_5",title:"Location", isFilter:true,width:100},
    {field:"c_6",title:"Daily Inspection ID", isFilter:true,width:200},
   
  ];
  public areagrid=[
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    },
    {
      c_1:"--",
      c_2:"--",
      c_3:"--",
      c_4:"--",
      c_5:"--",
      c_6:"--",
      c_7:"--",
      c_8:"--",
      c_9:"--",
      c_10:"--",
      c_11:"--",
      c_12:"--",
      c_13:"--",
      c_14:"--",
      c_15:"--",
      c_16:"--"
    }
  ];
// public gridData=sampleProducts;
      
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  // public value: Date = new Date(2000, 2, 10);

  maintenence_status: FormGroup;
  submitted = false;
  // status = ['Active','Inactive'];
  
  constructor(private formBuilder: FormBuilder) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
   }
   editColumn(data){}

  ngOnInit() {
    this.maintenence_status = this.formBuilder.group({
      // facility: ['', Validators.required],
    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }
  
  onDialogClose(){
   
    this.opened=false;
   
  }

  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Daily Inspection Planner"
    }
    if(type=="update"){
      this.dailogTitle="Edit Maintenence Status"
    }
    this.opened=true;
  }


}
