import { Component, OnInit } from '@angular/core';
import { ModelApi } from 'src/ApiModel/const';
import { Model } from 'src/ApiModel/Model';
import { WorkOrder } from 'src/ApiModel/Model/WorkOrder';

@Component({
  selector: 'app-unschedule-wo-summery',
  templateUrl: './unschedule-wo-summery.component.html',
  styleUrls: ['./unschedule-wo-summery.component.scss']
})
export class UnscheduleWoSummeryComponent implements OnInit {

  constructor() { }
  public month=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
  public model=new Model()
  public gridData=[]
  public workorder:any={
    ppm:[],
    di:[],
    pdm:[],
    corrective:[],
    proactive:[],
    alarm:[],
    request:[],
    month:[]
  }
  public graphCount=[];
  public column=[
    {field:"wo",title:"Work Order No",isFilter:false ,width:200},
    {field:"ts.NAME",title:"Status",isFilter:true ,width:200},
  ]
  public mcolumn=[
    {field:"month",title:"Month",isFilter:false ,width:200},
    {field:"count",title:"Workorder",isFilter:true ,width:200},
  ]
  ngOnInit(): void {
    this.model.getData(ModelApi.WorkerOrder,ModelApi.TicketConsequenceType,ModelApi.ticketstatus,ModelApi.MaintenancePlanner)
    .then((result)=>{
        this.manuculateAssetData(this.model.workOrder.alldata);
    })
    .catch((err)=>{

    })
  }
  manuculateAssetData(assetData:Array<WorkOrder>){
    let statusObj={}
    this.model.ticketStatus.alldata.forEach((d)=>{
      if(d.NAME.toUpperCase()=="ACTIVE" || d.NAME.toUpperCase()=="INACTIVE"){}
      else{
      let obj={
        label:d.NAME,
        corrective:0,
        proactive:0,
        alarm:0,
        request:0
      }
      statusObj[d.ID]=obj;
    }
    })
    let monthObj={}
    this.month.forEach((dm)=>{
       monthObj[dm]=0;
    })
    let cd=new Date();
   assetData.filter((d)=>!d.ppmid).forEach((d)=>{
          let md=new Date(d.createdDate);
          if(cd.getFullYear()===md.getFullYear())
             monthObj[this.month[md.getMonth()]]=monthObj[this.month[md.getMonth()]]+1;
          let tc=this.model.ticketConsequance.objectData[d.ticketConsequenceId];
          let ts=this.model.ticketStatus.objectData[d.ticketStatusId];
          let obj={
            id:d.id,
            wo:d.workOrderNo,
            wsId:d.ticketStatusId,
            ts,
            tc
            }
            if(statusObj[d.ticketStatusId]){
          if(d.ticketConsequenceId==4){
            statusObj[d.ticketStatusId]["corrective"]=statusObj[d.ticketStatusId]["corrective"]+1
            this.workorder.corrective.push(obj)
          }
          else if(d.ticketConsequenceId==5){
            statusObj[d.ticketStatusId]["proactive"]=statusObj[d.ticketStatusId]["proactive"]+1
            this.workorder.proactive.push(obj)
          }
          else if(d.ticketConsequenceId==6){
            statusObj[d.ticketStatusId]["alarm"]=statusObj[d.ticketStatusId]["alarm"]+1
            this.workorder.alarm.push(obj)
          }
          else if(d.ticketConsequenceId==7){
            statusObj[d.ticketStatusId]["request"]=statusObj[d.ticketStatusId]["request"]+1
            this.workorder.request.push(obj)
          } 
        }        
    })
   //this.graphCalculate(this.gridData);
   for(let i in statusObj){
     this.graphCount.push(statusObj[i]);
   }
   this.workorder.month=new Array(12)
   let monthIndex=this.month.reduce((acc,d,i)=>{
    acc[d]=i
    return acc;
   },{})
  for(let m in monthObj){
    this.workorder.month[monthIndex[m]]={
      month:m,
      count:monthObj[m],
      color:"#92d050"
    }
  }
   console.log(monthObj,this.workorder.month);
   this.graphCount=this.graphCount.map((d)=>{
    d["color"]="#92d050"
    return d;
   })//[...this.graphCount];
   this.workorder.corrective=[...this.workorder.corrective];
   //this.workorder={...this.workorder};
  // console.log(statusObj,this.workorder);
  }
  graphCalculate(data:any){
    
  }
  filterChangedHandler(e){

  }

}
