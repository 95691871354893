import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import { AssetType, AssetTypeError } from 'src/app/models/asset.model';
import { eventListner, EVENT_NAME } from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { assetClassificationValidation, assetTypeCodeClassificationValidation } from "src/validator/assetClassification";
import { NotificationService } from "@progress/kendo-angular-notification";
import { shiftLastDataInto1st } from 'src/Util/Util';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-asset-type-code-classification',
  templateUrl: './asset-type-code-classification.component.html',
  styleUrls: ['./asset-type-code-classification.component.scss']
})
export class AssetTypeCodeClassificationComponent {

  public onTabSelect(e) {
    if (e.title === "Basic Details")
      this.currentTab = "basic"
    else if (e.title == "Uptime Target")
      this.currentTab = "uptime"  }
  public currentTab = "basic"
  public assetClassification = []
  public assetClassificationObj = {}
  public uniqueList=[];
  public opened = false;
  public type = "";
  public assetTypeDis = false;
  public asset_cl_description;
  public actionsLayout = "normal";
  public isunique=false;
  public dailogTitle = "";
  public filterdClassificationData: Array<{ code: string; id: number; }>;
  public column_asset_type = [
    { field: "code", title: "Code", isFilter: true },
    { field: "description", title: "Description", isFilter: true },
    { field: "a_c_name", title: "Asset Classification Code", isFilter: true },
    { field: "a_m_name", title: "Maintenence Flag", isFilter: true },
    { field: "expectedLifeSpan", title: "Expected Life Span", isFilter: true },
  ]
  public gridDataassettype = [];
  public removeDialogOpen = false;
  public permission:Permission=new Permission();
  // public column_asset_type=[
  //   {field:"ProductID",title:"Parameters",isFilter:true},
  //   {field:"ProductName",title:"Variation Rate", isFilter:true},
  //   {field:"FirstOrderedOn",title:"Effective From Date", isFilter:true}
  // ]
  // public gridDataassettype=sampleProducts;
  // constructor() { }
  ///*****From veriable **** */
  selectedAsset: { id: number, code: string,description:string } = null;
  selectedMaintanceFlag=[];
  formData: AssetType = new AssetType();
 
  error = assetTypeCodeClassificationValidation(this.formData, "init")
  ///****Form Veriable ended *** */
  Asset_type_cl: FormGroup;
  assetStatus = "0"
  submitted = false;
  maintenence_f = [
    { name: 'PPM', id: 1 },
    { name: 'RI', id: 2 },
    { name: 'PDM', id: 3 }
  ];
  maintenence_obj = {}
  constructor(private formBuilder: FormBuilder,
    private _notif: NotificationService
  ) {
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.remove_column, (e) => { this.editColumn(e) })
  }

  editColumn(data) {
    if (data.action === "edit") {
      this.assetTypeDis = true
      this.formData = data.dataItem;
      this.selectedAsset = { id: 0, code: "",description:"" }
      if(this.formData.assetTypeMaintenanceFlags.length>0){
        this.selectedMaintanceFlag = this.formData.assetTypeMaintenanceFlags.map((d)=>{
          let maintenanceId = d.maintenanceFlagId
           return {
             id:maintenanceId,
             name:this.maintenence_obj[maintenanceId]
           }
         });
      }
   
      this.selectedAsset["code"] = this.assetClassificationObj[this.formData.assetClassificationId] && this.assetClassificationObj[this.formData.assetClassificationId].code
      this.selectedAsset["id"] = this.formData.assetClassificationId
      this.selectedAsset.description=this.assetClassificationObj[this.formData.assetClassificationId] && this.assetClassificationObj[this.formData.assetClassificationId].description
      // this.selectAssetsStatus["name"] = this.assetClassificationObj[this.formData.maintenanceFlagId]
      // this.selectAssetsStatus["id"] = this.formData.maintenanceFlagId;
      this.openRoleDailog("update");
    }
    else if (data.action === "remove") {
      this.formData = data.dataItem;
      this.removeDialogOpen = true;
    }
  }

  ngOnInit() {
    if(UserModuleObj.asset){
      this.permission=UserModuleObj.asset;
    }
    // this.getAssetType()
    this.getAssetsClassification();
    this.Asset_type_cl = this.formBuilder.group({
      asset_code: ['', Validators.required],
      asset_dis: ['', Validators.required],
      asset_cla_dis: ['', [Validators.required]],
      asset_type_code: ['', Validators.required],
      maintenence_f: ['', Validators.required],
      equip_fun_d: ['', Validators.required],
      life_ex: ['', [Validators.required]],
      exp_l_s: ['', Validators.required],
      qap_asswt_ser: ['', [Validators.required]],
      ppm_compl: ['', Validators.required],
      equip_up: ['', Validators.required],
      qap_up_tar: ['', Validators.required],
      effe_form: ['', [Validators.required]],
      effec_to: ['', Validators.required],
      asset_age_5: ['', Validators.required],
      asset_age_10: ['', [Validators.required]],
      asset_age_5g: ['', Validators.required],

    }, {
      // validator: MustMatch('password', 'confirmPassword')
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.Asset_type_cl.controls; }

  onReset() {
    this. openRoleDailog("new")
  }

  onDialogClose() {
    this.opened = false;
  }
  openRoleDailog(type) {
    this.type = type;
    this.currentTab="basic"
    if (type == "new") {
      this.dailogTitle = "Add Asset Type Code Details"
      this.formData = new AssetType();
      this.error = assetTypeCodeClassificationValidation(this.formData, "init")
      this.selectedAsset = { id: 0, code: "",description:"" }
      this.selectedMaintanceFlag = [{id: 1, name: 'PPM'}]
    }
    if (type == "update") {
      this.dailogTitle = "Edit Asset Type Code Details"
    }
    this.opened = true;
  }

  getAssetsClassification() {
    HttpClient.get("/api/Assets/GetAssetClassifications", true, true)
      .then((result: Array<any>) => {
        this.assetClassification = result.map((d) => {
          this.assetClassificationObj[d.id] = d;
          return { id: d.id, code: d.code,description:d.description}
        });
        this.filterdClassificationData =this.assetClassification.slice();
        this.getAssetsManufacture();
      })
      .catch((err) => {
      })
  }

  
  getFKData(schema,tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
      .then((result:any)=>{
         resolve(result)
      })
      .catch((err)=>{
         reject(err);
      })
    })
  }
  
  getAssetsManufacture() {
    return new Promise((resolve,reject)=>{
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=dbo&Table=MaintenanceFlag`,true)
      .then((result:any)=>{
       let  gridData=JSON.parse(result.gridData);
       this.maintenence_f = gridData.map((d) => {
        this.maintenence_obj[d.ID] = d.DESCRIPTION;
            return { id: d.ID, name: d.DESCRIPTION }
        });
       
        this.getAssetType()
          resolve(result)
        })
      .catch((err)=>{
         reject(err);
      })
    })
    // HttpClient.get("/api/MasterData/SelectAssetManufacturers", true,true)
    //   .then((result: Array<any>) => {
    //     this.maintenence_f = result.map((d) => {
    //       this.maintenence_obj[d.id] = d.name;
    //       return { id: d.id, name: d.name }
    //     });
    //     this.getAssetType()
    //   })
    //   .catch((err) => {
    //   })
  }

  submitData() {
    if (this.selectedAsset) {
      this.formData.assetClassificationId = this.selectedAsset.id;
    }
    if (this.selectedMaintanceFlag.length) {
      let mainFlagObj={}
      if(this.formData.assetTypeMaintenanceFlags){
         mainFlagObj= this.formData.assetTypeMaintenanceFlags.reduce((acc,d)=>{
                        acc[d.maintenanceFlagId]=d;
                        return acc;
                      },{})
      }
      this.formData.assetTypeMaintenanceFlags=this.selectedMaintanceFlag.map((d)=>{
              if(mainFlagObj[d.id]){
                  return mainFlagObj[d.id]
              }
              else{
                  return {
                    id:0,
                    assetTypeId:this.formData.id,
                    maintenanceFlagId:d.id
                  }
            }
      })
   //   this.formData.maintenanceFlagId = this.selectAssetsStatus.id;
    }
    this.error = assetTypeCodeClassificationValidation(this.formData, "");
    if (this.error.assetClassificationId || this.error.maintenanceFlagId) {
      this.currentTab = "basic"
    }
    // else if (this.error.age5To10Yrs || this.error.ageLessThan5Yrs || this.error.ageGreaterThan10Years) {
    //   this.currentTab = "uptime"
    // }
    else {
      this.addAssetType();
    }
  
  }

  addAssetType() {
    let path = ""
    if (this.type === "new") {
      path = "/api/Assets/AddAssetType";
    }
    else if (this.type === "update") {
      path = "/api/Assets/UpdateAssetType"
    }
    HttpClient.fetchData(path, "post", this.formData, true,true)
      .then((result: any) => {
     
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Asset Type",this.type+" Asset Type",this.type+" Asset Type").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this.currentTab = "basic"
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          if (this.type === "new") {
            this.formData.id = result.id;
            this.formData["a_c_name"] =this.assetClassificationObj[this.formData.assetClassificationId] && this.assetClassificationObj[this.formData.assetClassificationId].code
            this.formData["a_m_name"] = this.formData.assetTypeMaintenanceFlags && this.formData.assetTypeMaintenanceFlags.map((d)=>{
                                            return this.maintenence_obj[d.maintenanceFlagId] 
                                         })
            this.gridDataassettype.unshift(this.formData)
            this.gridDataassettype = [...this.gridDataassettype];
            // path="/api/Assets/AddAssetType";
          }
          else if (this.type === "update") {
            this.gridDataassettype = this.gridDataassettype.map((d) => {
              if (d.id === this.formData.id) {
                d = this.formData;
                d["a_c_name"] = this.assetClassificationObj[this.formData.assetClassificationId] && this.assetClassificationObj[this.formData.assetClassificationId].code
                d["a_m_name"] = this.formData.assetTypeMaintenanceFlags && this.formData.assetTypeMaintenanceFlags.map((d)=>{
                    return this.maintenence_obj[d.maintenanceFlagId] 
                })
                // this.maintenence_obj[this.formData.maintenanceFlagId]
              }
              return d;
            })
          }
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        this.opened = false;
      })
  }
  
  getAssetType() {
    HttpClient.get("/api/Assets/GetAssetTypes", true, true)
      .then((result: Array<AssetType>) => {
        let data = shiftLastDataInto1st(result)
        this.gridDataassettype = data.map((d) => {
          d["a_c_name"] = this.assetClassificationObj[d.assetClassificationId] && this.assetClassificationObj[d.assetClassificationId].code
          d["a_m_name"] =d.assetTypeMaintenanceFlags && d.assetTypeMaintenanceFlags.map((m)=>{
               return this.maintenence_obj[m.maintenanceFlagId];
          }).join(",")          
          return d;
        })
      })
      .catch((err) => {
       
      })
  }

  removeRole() {
    HttpClient.fetchData("/api/Assets/DeleteAssetTypeById", "post", this.formData, true,true)
    .then((result: any) => {
       if (result.isValid == true) {
        HttpClient.insertAduitLog("Asset Type","Delete Asset Type","Delete Asset Type").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridDataassettype = this.gridDataassettype.filter((d) => d.id != this.formData.id)
        }
        else{
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        this.removeDialogOpen = false;
      })
  }

  handleasset_clFilter(value) {
    
    this.filterdClassificationData = this.assetClassification.filter(
      (s) => s.code.toString().indexOf(value) !== -1
    );
  }

  checkedUniqueCode(){
    this.isunique=false;
    let val:string=this.formData.code;
    if(!(this.uniqueList.filter((d)=>d==val.toLowerCase()).length>0)){
        this.isunique=this.gridDataassettype.filter((d)=>{
          return val.toLowerCase()==d.code.toLowerCase()
        }).length>0
    }
  }
}
