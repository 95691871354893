import { Component, OnInit } from '@angular/core';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import lookup from "src/app/Data/lookup"
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {

  constructor(private router:ActivatedRoute) { }
/***** Column Defination  ** */
public columnDefination={
  "CODE":{
    formType:"text",
    formLable:"Enter Code",
    gridColum:"Code"
  }
}

 /**** List of veriable *** */
 public actionsLayout = "normal";
 public removeDialogOpen=false;
 public listValue=[
    {
      id:1,
      name:"Assets Data",
      tableName:"AssetManufacturer",
      schema:"dbo"
    },
    {
      id:2,
      name:"AssetType",
      tableName:"AssetType",
      schema:"dbo"
    }
  ]
  public selectedListValue:any={
    id:0,
    name:"newblock",
    tableName:"block",
    schema:"dbo"
  }
  public gridColumn=[]
  public formData=[]
  public gridData=[]
  public fkObj={}
  public inputForm=[]
  public dailogTitle="Add List Value";
  public opened=false;
  public type=""
  public listData={};
  public listObj={};
  public fkPrimaryKey={};
  public extraCOlumn=[]
  public isError=false;
  public template:any
  ngOnInit(): void {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)})
  
    let name=this.router.snapshot.params.name;
    this.formData=lookup[name].formData;
    this.gridColumn=lookup[name].gridColumn;
    this.listData=lookup[name].listData;
    this.fkPrimaryKey=lookup[name].fkPrimaryKey;
    this.extraCOlumn=lookup[name].extraColumn;
    this.template=lookup[name].template;
    this.selectedListValue ={
      id:0,
      name:name,
      tableName:name,
      schema:"dbo"
    }
   
    this.getLookupData("dbo",name);
  }
  editColumn(data){
  
    if(data.action==="edit"){
        let newData=data.dataItem;
        this.inputForm=JSON.parse(JSON.stringify(this.formData))
        this.inputForm=this.inputForm.map((d)=>{
            if(newData[d.key]){
              if(d.type=="text" || d.type=="lable"){
                d.value=newData[d.key]
              }
              else if(d.type=="list"){
                d.value=this.listObj[d.table][newData[d.key]]
              }
            }
            return d;
        })
         this.openRoleDailog("update");
    }
    else if(data.action==="remove"){
      let newData=data.dataItem;
      this.inputForm=JSON.parse(JSON.stringify(this.formData))
      this.inputForm=this.inputForm.map((d)=>{
          if(newData[d.key]){
            if(d.type=="text" || d.type=="lable"){
              d.value=newData[d.key]
            }
            else if(d.type=="list"){
              d.value=this.listObj[d.table][newData[d.key]]
            }
          }
          return d;
      })
       this.removeDialogOpen=true;
    }
  }
  openRoleDailog(type){
    this.type=type;
       if(type==="new"){
         this.dailogTitle=`Add list (${this.selectedListValue["name"]})`
         this.inputForm=JSON.parse(JSON.stringify(this.formData))
       }
       else if(type=="update"){
        this.dailogTitle=`Update list (${this.selectedListValue["name"]})`
       }
       this.opened=true
  }
  onDialogClose(){
     this.opened=false
  }
  changeListValue(e){
     this.selectedListValue={...e};
     this.getLookupData(e.schema,e.tableName)
  }
  formDropDownChange(e,index,item){
    console.log("selected data=>",e,item);
      this.inputForm[index].value=e;
      this.inputForm=this.inputForm.map((d)=>{
          if(d.key.search(item.table+".")==0){
            let fkKey=d.key.replace(item.table+".","");
            d["value"]=e[fkKey]
          }
          return d;
      })
  }

  getLookupItem(){
    HttpClient.get("/api/MasterData/GetLookupItems",true)
    .then((result:any)=>{
       this.listValue=result.map((d)=>{
          return     {
            id:d.id,
            name:d.description,
            tableName:d.name,
            schema:"dbo"
          }
       })
    })
    .catch((err)=>{

    })

  }
  getFKData(schema,tablename){
    return new Promise((resolve,reject)=>{
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
      .then((result:any)=>{
         resolve(result)
      })
      .catch((err)=>{
         reject(err);
      })
    })
  }

  getLookupData(schema,tablename){
   HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&Table=${tablename}`,true)
   .then((result:any)=>{
        let gridData=[]
        if(result.gridData){
          gridData=JSON.parse(result.gridData);
        }
        if(true){
            let fktem=JSON.parse(result.fkInformarions)
            fktem=fktem.filter((d)=>d["FKTABLENAME"])
            Promise.all(
              this.extraCOlumn.map((d)=>{
                return this.getFKData("dbo",d.table)
              })
            )
            .then((fkData:Array<any>)=>{
           
               this.extraCOlumn.forEach((d,i)=>{
                if(fkData[i] && fkData[i].gridData){
                  this.listData[d.table]= JSON.parse(fkData[i].gridData);
                    let dataObj={}
                    this.listData[d.table].forEach((c)=>{
                      dataObj[c[this.fkPrimaryKey[d.table]]]=c;
                    })
                    this.listObj[d.table]=dataObj;
                  }
                  else{
                    this.listData[d.table]=[]
                    this.listObj[d.table]={}
                  }
               })
               this.manuculateLookUpData(gridData);
            })
            .catch((err)=>{
           
               this.manuculateLookUpData([])
            })
        }
        else{
          this.manuculateLookUpData(gridData)
        }
   })
   .catch((err)=>{
   
   })
  }
  uc(text){
    return text.toUpperCase();
  }
  manuculateLookUpData(gridData){
    
          this.gridData=gridData.map((d)=>{
                this.extraCOlumn.forEach((e,i)=>{
                   if(d[e.key]){
                     if(e.parse)
                          d[e.key]=e.parse(d[e.key])
                     let obj={};
                       if(this.listObj[e.table][d[e.key]]){
                            e.column.forEach((c)=>{
                            obj[c] =this.listObj[e.table][d[e.key]][c];
                            })
                       }
                      d[`${e.table}`]=obj 
                   }
                }) 
                return d;
          });
  }
  submitFrom(){
    
    // let d=this.inputForm.reduce((acc,inp)=>{
    //     if(inp.type=="text" || inp.type=="lable"){
    //         if(inp.parse){
    //           acc[inp.name]=inp.parse(inp.value);
    //         }
    //         else{
    //           acc[inp.name]=inp.value;
    //         }
    //     }
    //     else if(inp.type=="list"){
    //       acc[inp.name]=inp.value[this.fkPrimaryKey[inp.table]];
    //     }
    //     if(inp.isError){
    //         let error=inp.isError(acc[inp.name])
    //         if(error){
    //           this.isError=true;
    //         }
    //         inp.error=error
    //     }
    //     return acc;
    // },{})
   let acc={}
    this.inputForm=this.inputForm.map((inp,i)=>{
      if(inp.type=="text" || inp.type=="lable"){
        if(inp.parse){
          acc[inp.name]=inp.parse(inp.value);
        }
        else{
          acc[inp.name]=inp.value;
        }
    }
    else if(inp.type=="list"){
      acc[inp.name]=inp.value[this.fkPrimaryKey[inp.table]];
    }
    if(inp.isError){
        let error=inp.isError(acc[inp.name])
        if(error){
          this.isError=true;
        }
        inp.error=error
    }
    return inp;
    })
    
    if(!this.isError)
       this.saveForm(acc)
  }
  saveForm(data){
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":1,
      "newData":JSON.stringify(data)
    }
     let  path="";
    if( this.type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(this.type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((responce)=>{
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.opened=false;
        HttpClient.insertAduitLog("LookupData",this.type +" UpdateLookupData","UpdateLookupData with Lookuptabel").then((res:any)=>{
          if (res.isValid == true) {

          }
        })
    })
    .catch((err)=>{
      
    })
  }

  
  removeLookup(){
    let d=this.inputForm.reduce((acc,inp)=>{
      if(inp.type=="text" || inp.type=="lable"){
         acc[inp.name]=inp.value;
      }
      else if(inp.type=="list"){
        acc[inp.name]=inp.value.ID;
      }
      return acc;
  },{})
    let obj={
      "schemaName":this.selectedListValue.schema,
      "tableName":this.selectedListValue.tableName,
      "userId":1,
      "oldData":JSON.stringify(d),    
      "newData":JSON.stringify(d)
    }
     let  path=`/api/MasterData/DeleteLookupData`
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((responce)=>{
       this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName) 
       this.removeDialogOpen=false;
       HttpClient.insertAduitLog("LookupData","Delete UpdateLookupData","Delete in Lookuptabel").then((res:any)=>{
        if (res.isValid == true) {

        }
      })
    })
    .catch((err)=>{
      console.log(err);
    })
  }

}
