<div>
    <div *ngIf="!url" (click)="uploadFile()"  style="display: flex;justify-content: center;border: 1px solid white; padding: 0.35rem;cursor: pointer;">
        <span class="k-icon k-i-export"></span>
        <span style="margin-left: 0.5rem;">Upload</span>
    </div>
    <div style="color: red;">{{error}}</div>
</div>
<div *ngIf="url" style="display: flex;justify-content: center;border: 1px solid white; padding: 0.35rem;cursor: pointer;position: relative;">
      <img *ngIf="type=='img'" [src]="fileBaseUrl+url" style="height: 100px;width: 100px;object-fit: contain;" />
      <img *ngIf="type!=='img'" src="/assets/document.png" style="height: 100px;width: 100px;object-fit: contain;" />
      <div style="position: absolute;top:5px;right:5px;" (click)="closeUploadFile()">
        <span class="k-icon k-i-close k-i-x" style="color: red;"></span>
      </div>
</div>