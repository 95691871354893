import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { sampleProducts } from 'src/app/Data/table';
import { environment } from 'src/environments/environment';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public conditionID;
  public assetID;
  public actionID;
  public permission:Permission=new Permission();
  public contectGrid = []
  public condiValue =[
    {id:1,name:"All"},
    {id:2,name:"Any"}
  ]
  
  public assetType =[
    {id:1,name:"Asset No."},
    {id:2,name:"Asset Type code"},
    {id:3,name:"Asset description"},
    {id:4,name:"Manufacturer"},
    {id:5,name:"Model"},
    {id:6,name:"Area Name"},
    {id:6,name:"Location Name"},
  
  ]
  
  public action= [
    {id:1,name:"contains"},
    {id:2,name:"equal"},
    {id:3,name:"not equal"},
    {id:3,name:"begin with"},
    {id:3,name:"not equal"},
    {id:4,name:"does not begin with"}
  
  ]
    constructor(
      private _notif: NotificationService
    ) { 
      this.conditionID = 1;
      this.assetID = 1;
      this.actionID = 1;
      eventListner(EVENT_NAME.unlock_data, (e) => {
        this.unLockData(e);
      });
  
    }
    unLockData(data){
      let obj={
        "userId": data.id,
        "username": data.userName,
        "userEmail": data.companyEmail,
        "passwordResetURL": window.location.origin+"/"+environment.reset_password_pathname
      }
      HttpClient.fetchData("/api/Users/ResetPassword","post",obj,true,true)
      .then((result:any)=>{
        HttpClient.insertAduitLog("Users","unLock Reset Password","unLock Users in Reset Password").then((res:any)=>{
          if (res.isValid == true) {
  
          }
        })
        console.log(result)
        if(result.message){
          this._notif.show({
            content:result.message,
            type: { style: "error", icon: true },
          });
        }
        else{
        this._notif.show({
          content:"Reset password link successfully send to mail",
          type: { style: "success", icon: true },
        });
      }
      })
      .catch((err)=>{
        console.log(err)
        this._notif.show({
          content:"Error in sending mail.",
          type: { style: "error", icon: true },
        });
      })
    }
    public contectColumn=[
      { 
        field: "customerName", 
        title: "Customer Name.",
        isFilter: true, 
      width: 200 
    },
      { 
          field: "staffName", 
          title: "Staff Name", 
          isFilter: true, 
          width: 200,
        },
      { 
        field: "userName", 
        title: "Username", 
        isFilter: true, 
        width: 250,
       },
       { 
        field: "User Type", 
        title: "User Type", 
        isFilter: false, 
        width: 140,
       },
       { 
        field: "companyEmail", 
        title: "Email.", 
        isFilter: true, 
        width: 250,
       },
       { 
        field: "status", 
        title: "Status", 
        isFilter: false, 
        width: 150,
       },
      
  
    ]
  
    ngOnInit(): void {
      if(UserModuleObj.user_screen_mapping){
        this.permission=UserModuleObj.user_screen_mapping;
      }
      this.getAssignne()
      .then((result:any)=>{
        let allData =[]
        allData=result.map((d)=>{
         d["status"]=d.statusId==1?"Active":"InActive"
          return d;
        });
        this.contectGrid =allData.filter((e)=>{
         return  e.isLocked==true;
         
        })
       
      })
      .catch((err)=>{
       
      })
    }

    getAssignne(){
      return new Promise((resolve,reject)=>{
        HttpClient.get("/api/Users/GetAllUser",true,true)
        .then((result)=>{
          resolve(result)
        })
        .catch((err)=>{
          reject(err);
        })
      })
    }
  
}
