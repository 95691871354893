export class ContractorManagementOfFacility{
    ID:number;
    BPMID:number;
    CONTRACTNO:string;
    STATUSID:number;
    CONTRACTSTARTDATE:Date | any;
    CONTRACTENDDATE:Date | any;
    CONTRACTSUM:number;
    CONTRACTTYPEID:number;
    NOTIFICATIONFORRENEWAL:Date | any;
    SCOPEOFWORKY:string;
    ATTACHMENTID:number;
    REMARKS:string;
    CREATEDBY:number;
    CREATEDDATE:Date;
    MODIFIEDBY:number;
    MODIFIEDDATE:Date
}

export class ContractorManagementOfFacilityError{
    ID:boolean=false;
    BPMID:boolean=false;
    CONTRACTNO:boolean=false;
    STATUSID:boolean=false;
    CONTRACTSTARTDATE:boolean=false;
    CONTRACTENDDATE:boolean=false;
    CONTRACTSUM:boolean=false;
    CONTRACTTYPEID:boolean=false;
    NOTIFICATIONFORRENEWAL:boolean=false;
    SCOPEOFWORKY:boolean=false;
    ATTACHMENTID:boolean=false;
    REMARKS:boolean=false;
    CREATEDBY:boolean=false;
    CREATEDDATE:boolean=false;
    MODIFIEDBY:boolean=false;
    MODIFIEDDATE:boolean=false
}

export class BPMAssets{
ID:number;
BPMCONTRACTID:number;
ASSETID:number;
CONTRACTVALUE:number;
CREATEDBY:number;
CREATEDDATE:Date;
MODIFIEDBY:number;
MODIFIEDDATE:Date;
}
