<div class="card m-3">
    <div class="card-header" >
        <h5>Asset Registration</h5>
         
               <div>
              <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
                <span class="add_btn">+</span> User
              </button>
            </div>
      
  
    <div class="card-body register-form-body">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Asset Standardization<span class="required">*</span></label>
                <kendo-dropdownlist [data]="assStandDropDown"  class="form-control"
                [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                (valueChange)="assetStandValueChange($event)"
                [(ngModel)]="assetRegistrationForm.assetStandardizationId"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
              
            </div>
           
        </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset No.<span class="required">*</span></label>
                            <input kendoTextBox  class="form-control"    [(ngModel)]="assetRegistrationForm.assetNumber"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Asset Classification<span class="required">*</span></label>
                            <kendo-dropdownlist  [data]="assetClassDropDown"  [textField]="'name'"
                            [valueField]="'id'"
                            [valuePrimitive]="true" class="form-control"
                         >
                            </kendo-dropdownlist>
                        </div>
                       
                    </div>
                     <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset ID</label>
                            <input kendoTextBox class="form-control" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Asset Type code</label>
                            <input kendoTextBox class="form-control" [(ngModel)]="assetTypeCode" disabled/>
                            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Asset Type Description</label>
                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetTypeDesc" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Model</label>
                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetModel"  disabled/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Manufacturer</label>
                            <input kendoTextBox   class="form-control" [(ngModel)]="assetManufacture" disabled/>
                        </div>
                        <div class="form-group col-5">
                            <label>Serial No.</label>
                            <input kendoTextBox    [(ngModel)]="assetRegistrationForm.serialNo"  class="form-control"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Contract Type</label>
                            <kendo-dropdownlist   [data]="contractTypeDropDown"  [textField]="'name'"
                            [valueField]="'id'"
                            [valuePrimitive]="true" class="form-control"  [(ngModel)]="assetRegistrationForm.contractTypeId" disabled>
                            </kendo-dropdownlist>
                        </div>
                        <div class="form-group col-5">
                            <label>Chasis No</label>
                            <input kendoTextBox [(ngModel)]="assetRegistrationForm.chasisNo" class="form-control"/>
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Engine No.</label>
                            <input kendoTextBox  class="form-control"  [(ngModel)]="assetRegistrationForm.engineNo"/>
                        </div>
                        <div class="form-group col-5">
                            
                        </div>
                    </div>
                
         

                <div class="card m-3">
                    <div class="card-header py-2" >
                        <h6 class="mb-0"> Asset Info</h6>
                    </div>
                    <div class="card-body register-form-body" >
                             
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Commissioning Date</label>
                                    <kendo-datetimepicker [format]="format" [(value)]="commictionvalue" [(ngModel)]="assetInforForm.commissioningDate ">
                                    </kendo-datetimepicker>
                                </div>
                                <div class="form-group col-5">
                                    <label>Service Start Date</label>
                                    <kendo-datetimepicker [format]="format" [(value)]="servicestartvalue" [(ngModel)]="assetInforForm.serviceStartDate " (valueChange)="getAssetAge($event)">
                                    </kendo-datetimepicker>
                                </div>
                                
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Service Stop Date</label>
                                    <kendo-datetimepicker [format]="format" [(value)]="servicestopvalue"  [(ngModel)]="assetInforForm.serviceEndDate" (valueChange)="getYearInService($event)">
                                    </kendo-datetimepicker >
                                </div>
                                <div class="form-group col-5">
                                    <label>Expected Lifespan (Years)</label>
                                    <input kendoTextBox  class="form-control" [(ngModel)]="assetInforForm.lifeSpan"/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Asset Status</label>
                                    <kendo-dropdownlist  [data]="assetStatusDropDown"  [textField]="'name'"
                                    [valueField]="'id'"
                                    [valuePrimitive]="true" [(ngModel)]="assetInforForm.statusId" class="form-control">
                                    </kendo-dropdownlist>
                                </div>
                                <div class="form-group col-5">
                                    <label>Asset Age (Year / Month)</label>
                                    <input kendoTextBox  class="form-control" [(ngModel)]="assetInforForm.assetAge" disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Years in Service (Year / Month)</label>
                                    <input kendoTextBox  class="form-control"  [(ngModel)]="assetInforForm.yearInService" disabled/>
                                </div>
                                <div class="form-group col-5">
                                    <label>Operating Hours</label>
                                    <input kendoTextBox  class="form-control"   [(ngModel)]="assetInforForm.operatingHours" />
                                </div>
                            </div>
                        
                        </div>
                        
       
                </div>

                <div class="card m-3">
                    <div class="card-header py-2" >
                        <h6 class="mb-0"> Location Details</h6>
                    </div>
                    <div class="card-body register-form-body">
                        
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Facility<span class="required">*</span></label>
                                    <kendo-dropdownlist  [data]="facilityDropDown"
                                    class="form-control"
                                    [textField]="'name'"
                                    [valueField]="'id'"
                                    [valuePrimitive]="true"
                                    [(ngModel)]="assetLocationForm.facilityId"
                                    (valueChange)="facilityValueChange($event)">
                                    </kendo-dropdownlist>
                                </div>
                            
                                <div class="form-group col-5">
                                    <label>Block Name</label>
                                    <input kendoTextBox  class="form-control"  [(ngModel)]="blockName" disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Block Code<span class="required">*</span></label>
                                    <kendo-dropdownlist    class="form-control"    [data]="filterdBlockData"
                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"     [(ngModel)]="assetLocationForm.blockId"  [filterable]="true" (valueChange)="blockValueChange($event)" >
                                    </kendo-dropdownlist>
                                </div>
                            
                                <div class="form-group col-5">
                                    <label>Level Name</label>
                                    <input kendoTextBox  class="form-control" [(ngModel)]="levelName" readonly />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Level Code<span class="required">*</span></label>
                                    <kendo-dropdownlist class="form-control"   [data]="filterdLevelData"
                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"  [filterable]="true"  [(ngModel)]="assetLocationForm.levelId"   (valueChange)="levelValueChange($event)">
                                    </kendo-dropdownlist>
                                </div>
                            
                                <div class="form-group col-5">
                                    <label>Area Name</label>
                                    <input kendoTextBox  class="form-control" [(ngModel)]="areaName" disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Area Code<span class="required">*</span></label>
                                    <kendo-dropdownlist    class="form-control"  [data]="filterdAreaData"
                                    [textField]="'code'" [valueField]="'id'"    [valuePrimitive]="true" (valueChange)="areaValueChange($event)"  [(ngModel)]="assetLocationForm.areaId" [filterable]="true">
                                    </kendo-dropdownlist>
                                </div>
                            
                                <div class="form-group col-5">
                                    <label>Location Name</label>
                                    <input kendoTextBox class="form-control" [(ngModel)]="locationName"  disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Location Code<span class="required">*</span></label>
                                    <kendo-dropdownlist   class="form-control"
                                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true"  [data]="filterdLocationData" (valueChange)="locationValueChange($event)" [(ngModel)]="assetLocationForm.locationId" [filterable]="true" >
                                    </kendo-dropdownlist>
                                </div>
                            
                                <div class="form-group col-5">
                                    <label>Sub Location Name</label>
                                    <input kendoTextBox [(ngModel)]="subLocationName" class="form-control" disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Sub Location Code<span class="required">*</span></label>
                                    <kendo-dropdownlist   class="form-control"    [data]="filterdSubLocationData"
                                    [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"  [(ngModel)]="assetLocationForm.subLocationId"  [filterable]="true" (valueChange)="subLocValueChange($event)">
                                    </kendo-dropdownlist>
                                </div>
                                <div class="form-group col-5">

                                </div>
                            </div>
                    </div>
                </div>
                <div class="card m-3">
                    <div class="card-header py-2" >
                        <h6 class="mb-0"> Asset Maintenance</h6>
                    </div>
                    <div class="card-body register-form-body">
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>PPM Flag</label>
                                    <input kendoTextBox class="form-control" [(ngModel)]="PpmFlag" disabled/>
                                </div>
                                <div class="form-group col-5">
                                    <div *ngIf="hideCreatePpmPlanner">
                                        <label>Create PPM Planner </label>
                                        <a href="JavaScript:Void(0);" style="color: #347bd5;text-decoration: none;">Go</a>
                                    </div>
                                   
                                    
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Routine Inspection (DI) Flag</label>
                                    <input kendoTextBox class="form-control" disabled/>
                                   
                                </div>
                                <div class="form-group col-5">
                                    <label>Last Scheduled Work Order No.</label>
                                    <input kendoTextBox class="form-control" disabled/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Last Unscheduled Work Order No.</label>
                                    <input kendoTextBox class="form-control" disabled/>
                                </div>
                                <div class="form-group col-5">
                                    <label>Last Work Scheduled Work Order Date</label>
                                    <kendo-datetimepicker [format]="format" [(value)]="lastworksc" disabled>
                                    </kendo-datetimepicker>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Last Work Unscheduled Work Order Date</label>
                                    <kendo-datetimepicker [format]="format" [(value)]="lastworkusc" disabled>
                                    </kendo-datetimepicker>
                                </div>
                                <div class="form-group col-5">
                                    <label>Total Downtime (YTD)</label>
                                    <input kendoTextBox class="form-control" disabled>
                                </div>
                            </div>
                            
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Cumulative Parts Cost (RM)</label>
                                    <input kendoTextBox class="form-control"/>
                                </div>
                                <div class="form-group col-5">
                                    <label>Cumulative Labour Cost (RM)</label>
                                    <input kendoTextBox  class="form-control"/>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-5">
                                    <label>Cumulative Contract Cost (RM)</label>
                                    <input kendoTextBox  class="form-control"/>
                                </div>
                                <div class="form-group col-5">
                                </div>
                            </div>
                    </div>
                </div>
                <!-- <div class="text-center mt-3 mb-4">
                    <button kendoButton primary="true" class="mr-2">Save</button>
                    <button kendoButton primary="true" class="mr-2">Download Template</button>
                    <button kendoButton primary="true" class="mr-2">Delete</button>
                </div> -->
                         
    </div>
</div>

<!-- <kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
          
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose()">Cancel</button>
      <button kendoButton primary="true">
            Submit
      </button>
    </kendo-dialog-actions>
  </kendo-dialog> -->
  <kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            Delete Description
        </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="removeAsset()">Delete</button>
    </kendo-dialog-actions>
</kendo-dialog>

