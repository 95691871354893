import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SubLocationType } from 'src/app/models/location.model';
import { GenerateQrCodeForLocation } from 'src/app/models/qrcode-generate.model';
import { eventListner, EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { subLocationValidation } from 'src/validator/location';
import { environment } from 'src/environments/environment';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-sub-location',
  templateUrl: './sub-location.component.html',
  styleUrls: ['./sub-location.component.scss']
})
export class SubLocationComponent implements OnInit {

  public opened = false;
  public removeDialogOpen = false;
  public qrCodeDialogOpen = false;
  public permission:Permission=new Permission();
  public locationQrValue;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  submitted = false;
  public qrCodeGenUrl;
  public facilityDropDown =[];
  public blockCodeDropDown =[];
  public levelCodeDropDown = [];
  public areaCodeDropDown  = [];
  public locationCodeDropDown =[];
  public allBlocksValue =[];
  public allLevelsValue =[];
  public allAreasValue =[];
  public allLocationValue =[];
  public blockName;
  public levelName;
  public areaName;
  public locationName;
  public blockObj = {};
  public levelObj = {};
  public areaObj = {};
  public facilityObj ={};
  public locationObj ={};
  public assetSubLocationHierarchyId;
  public filterdBlockData: Array<{ code: string; id: number; name: string }>;
  public filterdLevelData: Array<{ code: string; id: number; name: string }>;
  public filterdAreaData: Array<{ code: string; id: number;name: string }>;
  public filterdLocationData: Array<{ code: string; id: number;name: string }>;
  public qrCodeLocationForm:  GenerateQrCodeForLocation = new GenerateQrCodeForLocation();
  public imageUrl = environment.backendUrl+`/`;
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]


  sub_location: FormGroup;
  public subLocationForm: SubLocationType = new SubLocationType();
  error = subLocationValidation(this.subLocationForm, "init");
  public column = [
    { field: "FACILITY", title: "Facility", isFilter: true,width:160 },
    { field: "BLOCK", title: "Block Code", isFilter: true,width:140 },
    { field: "LEVEL", title: "Level Code", isFilter: true,width:140  },
    { field: "AREA", title: "Area Code", isFilter: true,width:140 },
    { field: "LOCATION", title: "Location Code", isFilter: true,width:140 },
    { field: "NAME", title: "Sub Location Name", isFilter: true, width: 140 },
    { field: "CODE", title: "Sub Location Code", isFilter: true , width:160},
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true , width:160},
    // { field: "last_update_on", title: "Last Updated On", isFilter: true }
  ]
  status = ['Active', 'Inactive'];
  public gridData = [];
  

  constructor(
    private _notif:NotificationService
  ) { 
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.qrCode_attachment, (e) => { this.qrCodeGeneration(e) })
  }

  ngOnInit(): void {
    if(UserModuleObj.sub_location)
    this.permission=UserModuleObj.sub_location;
    this.getfacility().then((e)=>{
      this.getSubLocationData("dbo","locationsublocation");
      this.getModuleHierarchYdata("dbo","moduleHierarchy")
    })
  }

  editColumn(data) {
    if (data.action === "edit") {
      this.error = subLocationValidation(this.subLocationForm, "init");
    this.subLocationForm = data.dataItem;
  
    if (data.dataItem.BLOCKLEVELID) {
      let selectLevelValue = this.levelCodeDropDown.filter((e) => {
        return e.id == data.dataItem.BLOCKLEVELID;
      });
      if (selectLevelValue) {
        this.levelName = selectLevelValue[0].name;
      }
    }
    if (data.dataItem.LEVELAREAID) {
      let selectareaValue = this.areaCodeDropDown.filter((e) => {
        return e.id == data.dataItem.LEVELAREAID;
      });
      if (selectareaValue) {
        this.areaName = selectareaValue[0].name;
      }
    }
    if (data.dataItem.BLOCKID) {
      let selectBolckValue = this.blockCodeDropDown.filter((e) => {
        return e.id == data.dataItem.BLOCKID;
      });
      if (selectBolckValue) {
        this.blockName = selectBolckValue[0].name;
      }
    }
    if (data.dataItem.AREALOCATIONID) {
      let selectBolckValue = this.locationCodeDropDown.filter((e) => {
        return e.id == data.dataItem.AREALOCATIONID;
      });
      if (selectBolckValue) {
        this.locationName = selectBolckValue[0].name;
      }
    }
    this.openLocationDailog("update");
    }

  }

  openLocationDailog(type) {
   this.type = type;
      if (type == "new") {
        this.subLocationForm = new SubLocationType();
        this.error = subLocationValidation(this.subLocationForm, "init");
        this.blockName ="";
        this.levelName ="";
        this.areaName ="";
        this.locationName="";
        this.filterdBlockData=[];
        this.filterdAreaData=[];
        this.filterdLevelData=[];
        this.filterdLocationData=[];
        this.subLocationForm.ID = 0;
        this.dailogTitle = "Add Sub-Location";
      }
      if (type == "update") {
        this.dailogTitle = "Edit Sub-Location";
      }
      this.opened = true;
    }
  
    onReset() {
      this.submitted = false;
      this.subLocationForm = new SubLocationType();
      this.blockName ="";
      this.levelName ="";
      this.areaName ="";
      this.locationName="";
    }
  
    onDialogClose(type) {
      if ((type = "new")) {
        this.opened = false;
        this.subLocationForm = new SubLocationType();
      }
      if ((type = "remove")) {
        this.submitted = false;
        this.removeDialogOpen = false;
      }
      if(type="qrcode"){
        this.qrCodeDialogOpen = false;
      }
    }

    onSubmit() {
      this.submitted = true;
      this.error = subLocationValidation(this.subLocationForm, " ");
      if (!this.error.FACILITYID && !this.error.NAME && !this.error.CODE && !this.error.BLOCKID && !this.error.BLOCKLEVELID && !this.error.LEVELAREAID && !this.error.AREALOCATIONID) {
        if (this.type == "new") {
            this.subLocationForm.ID = 0,
            this.subLocationForm.CREATEDBY = parseInt(getCookie(userid)),
            this.subLocationForm.MODIFIEDBY = parseInt(getCookie(userid)),
            this.subLocationForm.CREATEDDATE = new Date(),
            this.subLocationForm.MODIFIEDDATE = new Date();
            this.subLocationForm.STATUSID = 1;
        }
  
        this.addsubLocationData(JSON.stringify(this.subLocationForm));
      }
    }
  
    addsubLocationData(data) {
      const paramsObj = {
        newData: data,
        schemaName: "dbo",
        tableName: "locationsublocation",
        userId: getCookie(userid),
      };

      if (this.type == "new") {
        HttpClient.fetchData(
          "/api/MasterData/SaveLookupData",
          "post",
          paramsObj,
          true,
          true
        ).then((result: any) => {
          console.log(result);
          if (result.isValid == true) {
            HttpClient.insertAduitLog("Locations","Add sub Location","Add sub Location in inLocations").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            this.submitted = false;
            this.getSubLocationData("dbo","locationsublocation");
            //  let obj = {
            //   ...this.subLocationForm,
              
            // };
            // this.gridData.unshift(obj);
            // this.gridData = JSON.parse(JSON.stringify(this.gridData));
            this.opened = false;
          } else {
            this._notif.show({
              content: result.messageKey,
              type: { style: "error", icon: true },
            });
          }
        });
      }
  
      if (this.type == "update") {
       
        HttpClient.fetchData(
          "/api/MasterData/UpdateLookupData",
          "post",
          paramsObj,
          true,
          true
        ).then((result: any) => {
          console.log(result);
          if (result.isValid == true) {
            HttpClient.insertAduitLog("Locations","Update Sub Location","Update Sub Location in inLocations").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            this.getSubLocationData("dbo","locationsublocation");
            // let obj = {
            //   ...this.subLocationForm,
            
            // };
            // this.gridData = this.gridData.map((d) => {
            //   if (d.ID == this.subLocationForm.ID) {
            //     d = obj;
            //   }
            //   return d;
            // });
            // this.gridData = JSON.parse(JSON.stringify(this.gridData));
            this.opened = false;
          }
        });
      }
    }

    removeSubLocationData(){

    }

   getfacility() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Facility/GetFacilities", true, true)
        .then((result: any) => {

        if(result.length>0){
          let facilityData = [];
          this.facilityDropDown = result;
          facilityData = result;
          let blocksValue = [];
          if(facilityData.length>0){
          blocksValue = facilityData.map((e) => {
            return e.blocks;
          });
          for (let i = 0; i < blocksValue.length; i++) {
            for (let j = 0; j < blocksValue[i].length; j++) {
              let arr = [];
              arr = blocksValue[i][j];
              this.allBlocksValue.push(arr);
            }
            this.blockCodeDropDown = this.allBlocksValue.filter((e)=>{
              return e.statusId==1;
            });
            this.filterdBlockData = this.blockCodeDropDown.slice();
          }
          let levelsValue = [];
          levelsValue = this.blockCodeDropDown.map((e) => {
            return e.blockLevels;
          });
          for (let i = 0; i < levelsValue.length; i++) {
            for (let j = 0; j < levelsValue[i].length; j++) {
              let arr = [];
              arr = levelsValue[i][j];
              this.allLevelsValue.push(arr);
            }
          }
          this.levelCodeDropDown = this.allLevelsValue.filter((e)=>{
            return e.statusId==1;
          });
          this.filterdLevelData = this.levelCodeDropDown.slice();

          let areaValue =[];
          areaValue = this.levelCodeDropDown.map((e)=>{
                   return e.levelAreas;
          });
          for (let i = 0; i <  areaValue.length; i++) {
            for (let j = 0; j <  areaValue[i].length; j++) {
              let arr = [];
              arr =  areaValue[i][j];
              this.allAreasValue.push(arr);
            }
          }

          this.areaCodeDropDown = this.allAreasValue.filter((e)=>{
            return e.statusId==1;
          });
          this.filterdAreaData =   this.areaCodeDropDown.slice();
          if(this.areaCodeDropDown.length>0){
            let locationValue = [];
            console.log(this.areaCodeDropDown)
            locationValue = this.areaCodeDropDown.map((e)=>{
                   return e.areaLocations;
            })
             
            for (let i = 0; i < locationValue.length; i++) {
              for (let j = 0; j < locationValue[i].length; j++) {
                let arr = [];
                arr =  locationValue[i][j];
                this.allLocationValue.push(arr);
              }
            }
            this.locationCodeDropDown = this.allLocationValue.filter((e)=>{
              return e.statusId==1;
            });
            this.filterdLocationData =  this.locationCodeDropDown.slice();
            console.log( this.locationCodeDropDown)
          }
         resolve(result);
        }
      }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  facilityValueChange(facilityId) {
  let blockData =  this.blockCodeDropDown.filter((e) => {
      return e.facilityId == facilityId;
    });
    this.filterdBlockData = blockData.slice();
    
  }
  
  blockValueChange(blockId) {
    let selectBolckValue = this.filterdBlockData.filter((e) => {
      return e.id == blockId;
    });
    this.blockName = selectBolckValue[0].name;
    let levelData = this.levelCodeDropDown.filter((e) => {
      return e.blockId == blockId;
    });
    this.filterdLevelData = levelData.slice();
   
  }
  
  levelValueChange(levelId) {
    let selectLevelValue = this.filterdLevelData.filter((e) => {
      return e.id == levelId;
    });
    this.levelName = selectLevelValue[0].name;
   let area = this.areaCodeDropDown.filter((e) => {
      return e.blockLevelId == levelId;
    });
    this.filterdAreaData = area.slice();
    
  }
  
  areaValueChange(areaId){
    let selectLevelValue = this.filterdAreaData.filter((e) => {
      return e.id == areaId;
    });
    this.areaName = selectLevelValue[0].name;
     let locData = this.locationCodeDropDown.filter((e)=>{
       return e.levelAreaId = areaId;
     })

     this.filterdLocationData =  locData.slice();
  }

  locationValueChange(locationId){
    let selectLocationValue = this.filterdLocationData.filter((e) => {
      return e.id == locationId;
    });
    this.locationName =selectLocationValue[0].name;
  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindBlockObj(result) {
    this.blockObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  
  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  bindLocationObj(result) {
    this.locationObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  getSubLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let LocationData = [];
      LocationData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindBlockObj(this.filterdBlockData);
      this.bindLevelObj(this.filterdLevelData);
      this.bindFacilityObj(this.facilityDropDown);
      this.bindAreaObj(this.filterdAreaData);
      this.bindLocationObj(this.filterdLocationData)
      this.gridData = LocationData.map((d) => {
        d["BLOCK"] = this.blockObj[d.BLOCKID];
        d["LEVEL"] = this.levelObj[d.BLOCKLEVELID];
        d["FACILITY"] = this.facilityObj[d.FACILITYID];
        d["AREA"] =this.areaObj[d.LEVELAREAID];
        d["LOCATION"] =this.locationObj[d.AREALOCATIONID];
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE)
       
        return d;
      });
    });
  }

  qrCodeGeneration(data){
   this.qrCodeLocationForm.id = data.ID;
   this.qrCodeLocationForm.moduleHierarchyId = this.assetSubLocationHierarchyId;
   this.qrCodeLocationForm.modifiedBy = parseInt(getCookie(userid));
   this.qrCodeLocationForm.createdDate = new Date();
   this.qrCodeLocationForm.levelAreaId= data.LEVELAREAID;
   this.qrCodeLocationForm.createdBy= parseInt(getCookie(userid));
    this.qrCodeLocationForm.locationSubLocations =[ {
      nameCreatedBy: data.NAMECREATEDBY,
      nameModifiedBy:data.NAMEMODIFIEDBY,
      id: data.ID,
      name:data.NAME,
      statusId:data.STATUSID,
      facilityId:data.FACILITYID,
      blockId: data.BLOCKID,
      blockLevelId:data.BLOCKLEVELID,
      levelAreaId: data.LEVELAREAID,
      areaLocationId: data.AREALOCATIONID,
      createdBy:parseInt(getCookie(userid)),
      createdDate: new Date(),
      modifiedBy: parseInt(getCookie(userid)),
      modifiedDate:new Date()
      
    }
  ]
 HttpClient.fetchData("/api/MasterData/GenerateQRCodeForLocation", "post",  this.qrCodeLocationForm, true, true)
  .then((result: any) => {
    console.log("locationqrcode",result)
        if(result===true){
          HttpClient.get(
          `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${data.ID}&&moduleHierarchyId=${this.qrCodeLocationForm.moduleHierarchyId}`,
            true,
            true
          ).then((result: any) => {

            console.log("qrcode",result)
            this.qrCodeGenUrl = result
            console.log("image",result)
            
          })
        }
  });
 console.log(data)
    this.qrCodeDialogOpen= true;
    this.locationQrValue = data.FACILITY+`-`+  data.BLOCK + `-`+ data.LEVEL + `-` + data.AREA +`-` + data.LOCATION + `-` + data.CODE
    console.log("sdas",data)
}

getModuleHierarchYdata(schema, tablename){
  HttpClient.get(
    `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
    true,
    true
  ).then((result: any) => {
    let ModuleHieracrhyData = JSON.parse(result.gridData)
    let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
            return e.NAME ==="Sub-location"
      })
        this.assetSubLocationHierarchyId = ModFacArr[0].ID
     
  });
}
}


