
<div class="card m-3"  >
    <div class="card-header" >
        <h5>Spare Part Return </h5>
        <div>      
          <button kendoButton  primary="true" *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Spare Part Return
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
       
        <app-table-grid
        [data]="gridData"
        [column]="column2"
        [size]="10"
        [deleteFlag] ="false"
        [editFlag] ="editFlag"
        [viewFlag] = "true"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Material Request No. <span class="required">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  
                class="form-control"
                [data]="spareRequestList"
                [textField]="'materialRequestNo'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                [(ngModel)]="sparePartReturnForm.SPAREPARTREQUESTID"
                (valueChange)="chnagedMaterialNo($event)"
                [disabled] ="afterEditDisabled"
                
                >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error1.SPAREPARTREQUESTID">*Required</kendo-formerror>
            </div>
          </div>
            <div class="form-group col-5">
              <label>Return Date <span class="required">*</span></label>
              <div style="width:100%">
              <kendo-datetimepicker  [(ngModel)]="sparePartReturnForm.RETURNDATE" [format]="format" ngDefaultControl  [disabled] ="afterEditDisabled">
              </kendo-datetimepicker>
              <kendo-formerror *ngIf="error1.RETURNDATE">*Required</kendo-formerror>
              </div>
          </div>
           
        </div>
        <div class="form-row">
            <div class="form-group col-5">
              <label>Facility Name</label>
                <kendo-dropdownlist  [textField]="'NAME'"
                [valueField]="'ID'"  [valuePrimitive]="true" [data] ="facilityDropDown" [(ngModel)]="sparePartReturnForm.FACILITYID" class="form-control" (valueChange)="getFacilityCode($event)" disabled>
                </kendo-dropdownlist>
                
            </div>
            <div class="form-group col-5">
              <label>Facility Code </label>
              <input kendoTextBox class="form-control"  [(ngModel)]="facilityCode" disabled/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
              <label>Work Order Num </label>
              <kendo-dropdownlist [data]="workOrderData" [textField]="'workOrderNo'"  [valueField]="'id'"
              [valuePrimitive]="true" class="form-control"  [(ngModel)]="sparePartDetailsForm.WORKORDERID"  (valueChange)="chnagedWorkOrderNo($event)" disabled>
              </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
             
            </div>
        </div>
        <div class="form-group col-5" >
         
      </div>
        <div>
        <div class="table-responsive" >
            <table class="spare-part-table">
              <tr>
                <th>Part No.</th>
                <th>Part Description</th>
                <th>Bin No.</th>
                <th>Quantity Return</th>
                <th>Disposal Quantity</th>
                <th>Remarks</th>
              </tr>
              <tr *ngFor="let item of WoData;let i=index">
                <!-- {{item.partNo}} -->
  
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partNo" disabled/></td>
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partDescription" disabled/></td>
                <td><input kendoTextBox class="form-control"  [(ngModel)]="item.binNo " disabled/></td>
              <td>
                <input type="number"  [min]="0" kendoTextBox class="form-control"  [(ngModel)]="  WODescData[i].QUANTITYRETUNED"/>
              </td>
              <td>
                <input type="number"  [min]="0" kendoTextBox class="form-control"   [(ngModel)]="  WoData[i].QUANTITYDISPOSAL"/>
              </td>
              <td>
                <input kendoTextBox class="form-control"  [(ngModel)]="WoData[i].REMARKS"  />
              </td>
              </tr>
          </table>
        </div>
        </div>
       
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
      <button kendoButton  *ngIf="type=='new'"  (click) ="onReset()" primary="true">
        Reset
       </button>
      <button kendoButton *ngIf="type=='new'" (click)="submitSpareReturnFrom()" primary="true">
       Save
      </button>
      <button kendoButton *ngIf="type=='update'" (click)="submitSparePartDetailsFrom(sparePartReturnForm.ID)" primary="true">
     Update
       </button>
     
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="viewDialogOpen" (click)="onDialogClose('view')" [width]="1000">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
      
      </div>
    </kendo-dialog-titlebar>
   
  <div class="card-body register-form-body">
     <app-table-grid
      [data]="gridReturnDetData"
      [column]=" returnDetailsCol"
      [size]="10"
      [action] ="false"
    ></app-table-grid>
  </div>
  </kendo-dialog>
