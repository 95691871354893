class licenseCertificateAssets{
    id: number;
    licenseCertificateId: number;
    assetId: number;
    remarks: string;
    createdDate: Date;
    createdBy: number
}

class licenseCertificateHistories {
id: number;
licenseCertificateId: number;
registrationNo: string;
licenseNo: string;
description: string;
categoryId: number;
issuingBody: string;
serviceTypeId: number;
competencyId: number;
expiryDate: Date;
createdDate: Date;
createdBy: number
}

class licenseCertificateNonAssets{
id: number;
licenseCertificateId: number;
description: string;
remarks: string;
createdDate: Date;
createdBy: number

}
export class LicenseAndCertRegistration{

nameCreatedBy: string;
nameModifiedBy: string;
id: number;
registrationNo: string;
licenseNo: string;
description: string;
categoryId: number;
companyTypeId:number;
facilityId:number;
issuingBody: string;
contractorVendorId: number;
staffId: number;
serviceTypeId: number;
competencyId: number;
grade: string;
licenseIssuingDate: Date;
expiryDate: Date;
notificationForInspection: Date;
inspectionConductedOn: Date;
nextInspectionDate: Date;
remark: string;
createdDate: Date;
createdBy: number;
modifiedDate: Date;
modifiedBy: number;
licenseCertificateAssets: Array<licenseCertificateAssets>
licenseCertificateHistories:Array<licenseCertificateHistories>;
licenseCertificateNonAssets: Array<licenseCertificateNonAssets>
}

export class LicenseTypeError{
    categoryId:Boolean;
    registrationNo:Boolean;
   constructor(status){
      this.categoryId = status;
      this.registrationNo= status;
    }
 }
