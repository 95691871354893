export class checklistSlaMapping {
    ID:number;
    DESCRIPTION:string;
    SERVICETYPEID:number;
    CREATEDDATE:Date;
    CREATEDBY:number;
    RESPONDSLADURINGWORKINGHOURS:number;
    RESPONDSLAAFTERWORKINGHOURS:number;
    COMPLETIONSLADURINGWORKINGHOURS:number;
    COMPLETIONSLAAFTERWORKINGHOURS:number;
    CLOSESLA:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number
}

export class checklistSlaMappingnError{
    RESPONDSLADURINGWORKINGHOURS:Boolean;
    RESPONDSLAAFTERWORKINGHOURS:Boolean;
    COMPLETIONSLADURINGWORKINGHOURS:Boolean;
    COMPLETIONSLAAFTERWORKINGHOURS:Boolean;
   
   constructor(status){

        this. RESPONDSLADURINGWORKINGHOURS=status;
        this. RESPONDSLAAFTERWORKINGHOURS=status;
        this. COMPLETIONSLADURINGWORKINGHOURS=status;
        this.COMPLETIONSLAAFTERWORKINGHOURS=status;
    }
}