
<kendo-tabstrip (tabSelect)="onTabSelect($event)"  >
    <kendo-tabstrip-tab  title="Work Order For Location" [selected]="selectedTab=='location'">
        <ng-template kendoTabContent >
            <app-overall-grid-view></app-overall-grid-view>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab  title="Work Order For Assets" [selected]="selectedTab=='asset'">
        <ng-template kendoTabContent >
            <app-work-order-asset></app-work-order-asset>
        </ng-template>
    </kendo-tabstrip-tab>
    <!-- <kendo-tabstrip-tab title="Reassignment">
        <ng-template kendoTabContent>
            <app-work-order-reassign></app-work-order-reassign>
        </ng-template>
    </kendo-tabstrip-tab> -->
    <!-- <kendo-tabstrip-tab title="Work Order Reassign">
        <ng-template kendoTabContent>
            <app-work-order-reassign></app-work-order-reassign>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Spare Part Replacemet">
        <ng-template kendoTabContent>
            <app-spare-part-replacement></app-spare-part-replacement>
        </ng-template>
    </kendo-tabstrip-tab> -->
    <!-- <kendo-tabstrip-tab title="Attachments">
        <ng-template kendoTabContent>
            <app-work-order-attactments></app-work-order-attactments>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Completion Info">
        <ng-template kendoTabContent>
            <app-completeion-info></app-completeion-info>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Work Order Inquiry">
        <ng-template kendoTabContent>
            <app-work-order-inquiry></app-work-order-inquiry>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Overall Gridview">
        <ng-template kendoTabContent>
            <app-overall-grid-view></app-overall-grid-view>
        </ng-template>
    </kendo-tabstrip-tab> -->
</kendo-tabstrip>
<div class="row mb-3"></div>


