import { Component, Input, OnInit,OnChanges, SimpleChanges } from '@angular/core';
import {sampleProducts} from "../../Data/table" 
import { process, State } from "@progress/kendo-data-query";
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent,
} from "@progress/kendo-angular-grid";

import {EVENT_NAME,dispatchEvent} from "src/event"
@Component({
  selector: 'app-holiday-view-grid',
  templateUrl: './holiday-view-grid.component.html',
  
})
export class HolidayViewGridComponent implements OnChanges {
  @Input() size:number=10;
  @Input() data:any=[]
  @Input() column:any=[]
  public gridData:GridDataResult;
  public state: State = {
    skip: 0,
    take: this.size,

    // Initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };
  constructor() { }
  ngOnChanges(changes: any): void {
 
    if(changes.size)
        this.state["take"]=changes.size.currentValue;
    if(changes.data)
        this.gridData  = process(changes.data.currentValue, this.state);
    if(changes.column){
      this.column=changes.column.currentValue;
    }
   // throw new Error('Method not implemented.');
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.data, this.state);
  }
  ngOnInit(): void {
  }
  editHandler(e){
    dispatchEvent(EVENT_NAME.edit_column,e);
  }
  removeHandler(e){
    dispatchEvent(EVENT_NAME.remove_column,e);
  }
}
