
<div class="card m-3"   >
    <div class="card-header" >
        <h5>Area</h5>
        <div>      
          <button kendoButton *ngIf="permission && permission.isCreate" primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> Area
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid
        [data]="gridData"
        [column]="column"
        [size]="10" 
        [deleteFlag]="false"
        [editFlag]="permission && permission.isUpdate"
      ></app-table-grid>
    </div>
  </div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
       <div class="form-row">
            <div class="form-group col-5">
                <label>Facility<span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist   [data]="facilityDropDown" class="form-control"
                [textField]="'name'"
                [valueField]="'id'"
                [valuePrimitive]="true"
                [(ngModel)]="areaForm.FACILITYID"
                (valueChange)="facilityValueChange($event)"
                ></kendo-dropdownlist>
                <kendo-formerror *ngIf="error.FACILITYID">*Required</kendo-formerror>
              </div>
            </div>
            <div class="form-group col-5">
                <label>Block Code <span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [(ngModel)]="areaForm.BLOCKID"  [data]="filterdBlockData" class="form-control"
                    [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true" (filterChange)="handleBlockFilter($event)" (valueChange)="blockValueChange($event)"></kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.BLOCKID">*Required</kendo-formerror>
                    </div>
            </div>
        </div>
        
        <div class="form-row">
            <div class="form-group col-5">
                <label>Block Name</label>
                <input kendoTextBox  [(ngModel)]="blockName" class="form-control"  readonly>
            </div>
            <div class="form-group col-5">
                <label>Level Code <span style="color: red;">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist  [(ngModel)]="areaForm.BLOCKLEVELID"  [data]="filterdLevelData " class="form-control"
                [textField]="'code'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true" (filterChange)="handleLevelFilter($event)"  (valueChange)="levelValueChange($event)" ></kendo-dropdownlist>
                <kendo-formerror *ngIf="error.BLOCKLEVELID">*Required</kendo-formerror>
              </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Level Name</label>
                <input kendoTextBox  [(ngModel)]="levelName" class="form-control" readonly>
        </div>
            <div class="form-group col-5">
                <label>Area Code<span style="color: red;">*</span></label>
                <div style="width:100%">
                <input kendoTextBox class="form-control" [(ngModel)]="areaForm.CODE"   (blur)="checkedUniqueCode()">
                <kendo-formerror *ngIf="error.CODE">*Required</kendo-formerror>
                <kendo-formerror *ngIf="!error.CODE && isunique">Area code already exist.</kendo-formerror>
                </div>
               </div>
        </div>
       
        <div class="form-row">
            <div class="form-group col-5">
                <label>Area Name<span style="color: red;">*</span></label>
                <div style="width:100%">
                <input kendoTextBox [(ngModel)]="areaForm.NAME" class="form-control" >
                <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror>
                </div>
               </div>
               <div class="form-group col-5">
                <label>Short Name</label>
                <input kendoTextBox [(ngModel)]="areaForm.SHORTNAME" class="form-control" >
               </div>
              </div>
              <div class="form-row">
            <div class="form-group col-5">
                <label>Status</label>
                <kendo-dropdownlist  [data]="statusDropDown"  [(ngModel)]="areaForm.STATUSID" class="form-control"
                [textField]="'NAME'"
                [valueField]="'ID'"
                [valuePrimitive]="true"></kendo-dropdownlist>
               </div>
               <div class="form-group col-5"></div>
            </div>
     
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
        <button kendoButton *ngIf="type=='new'" (click)="onReset()"  primary="true">
            Reset
         </button>
         <button kendoButton *ngIf="type=='new'" (click)="onSubmit()"  primary="true">
             Save
         </button>
         <button kendoButton *ngIf="type=='update'" (click)="onSubmit()"  primary="true">
            Update
         </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  
  <kendo-dialog *ngIf="removeDialogOpen" (click)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeAreaData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>
