import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class User{ 
    loggedInUserId: number;
    id:  number;
    userName: string;
    password: string;
    staffName: string;
    genderId: number;
    officeNo: string;
    companyEmail: string;
    mobileNo: string;
    categoryId:  number;
    statusId: number
    designationId: number;
    nationalityId:  number;
    contractorId:  number;
    grade: string;
    customerName:string = "KLCCUH";
    labourCostPerHour:number;
    locationID: number;
    companyName: string;
    isDeleted: boolean;
}

export class UserObj{
    alldata:Array<User>=[]
    filterData:Array<User>=[]
    selectedObj:User=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:User)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(categoryId=null){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/Users/GetAllUser",true,true)
            .then((result:Array<User>)=>{
                if(categoryId){
                    result=result.filter((d)=>d.categoryId==categoryId)
                }
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
               
                reject(err)
            })
        })

    }
}