import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roles-permission-table-grid',
  templateUrl: './roles-permission-table-grid.component.html',
  styleUrls: ['./roles-permission-table-grid.component.scss']
})
export class RolesPermissionTableGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
