
import { Component, OnInit } from '@angular/core';
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from 'src/Util/ApiHandling';
import { NotificationService } from "@progress/kendo-angular-notification";
import { AssetStandardization } from "../../models/asset.model";
import { assetStandardizationValidation } from 'src/validator/assetClassification';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { Router } from "@angular/router";
import { GenerateQrCodeForAsset } from 'src/app/models/qrcode-generate.model';
import { userid } from 'src/Util/const';
import { environment } from 'src/environments/environment';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-asset-standardization',
  templateUrl: './asset-standardization.component.html',
  styleUrls: ['./asset-standardization.component.scss']
})
export class AssetStandardizationComponent {

  public opened = false;
  public qrCodeDialogOpen = false;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public assetModelDropDown;
  public assetManufactureDropDown;
  public assetStatusDropDown;
  public assetModeuleHierarchyId;
  public statusObj = {};
  public modelObj = {};
  public manufactureObj = {};
  public gridData = [];
  public asset_stand_Id;
  public removeDialogOpen = false;
  public removeAss_Id_Data;
  public assetTypeArray = [];
  public assetTypeObj = {};
  public assetStand_form: AssetStandardization = new AssetStandardization();
  public imageUrl = environment.backendUrl+`/`;
  public qrCodeGenUrl;
  public permission:Permission=new Permission();
  public qrCodeAsssetForm:  GenerateQrCodeForAsset = new  GenerateQrCodeForAsset()
  error = assetStandardizationValidation(this.assetStand_form, "init")

  public column_asset_std = [
    { field: "assetTypeCode", title: "Asset Type Code", isFilter: true },
    { field: "typecodediscr", title: "Type Code Description", isFilter: true },
    { field: "model", title: "Model", isFilter: true },
    { field: "manufacture", title: "Manufacturer", isFilter: true },
    { field: "status", title: "Status", isFilter: true },
  ]

  submitted = false;
  status = ['Active', 'Inactive'];
  constructor(
    private _notif: NotificationService,
    private _router:Router
  ) {
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.remove_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.qrCode_attachment, (e) => { this.qrCodeGeneration(e) })
    eventListner(EVENT_NAME.addAssetReg_attachment, (e) => { this.getAssetReg(e) })
  }

  ngOnInit() {
    if(UserModuleObj.asset){
      this.permission=UserModuleObj.asset;
    }
    Promise.all([
      this.getAssetManufacture(),
      this.getAssetModel(),
      this.getAssetStatus(),
      this.getAssetType()
    ])
      .then((result) => {
        this.getAssetStandardizations();
        this.getModuleHierarchYdata("dbo","moduleHierarchy")
      })
  }

  onDialogClose(type) {
    if (type = "asset") {
      this.opened = false;
      }
    if (type = "remove") {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
    if(type="qrcode"){
      this.qrCodeDialogOpen = false;
    }
  }
  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.assetStand_form = new AssetStandardization();
      let value=  this.assetStatusDropDown.filter((e)=>{
        return  e.name==="Active"
       })
      this.assetStand_form.assetStatusId = value[0].id;
      this.error = assetStandardizationValidation(this.assetStand_form, "init")
      this.dailogTitle = "Add Asset Standardization"
    }
    if (type == "update") {
      this.dailogTitle = "Edit Asset Standardization"
    }
    this.opened = true;
  }

  onReset(){
    this.openRoleDailog("new")
    // this.assetStand_form = new AssetStandardization();
    // this.error = assetStandardizationValidation(this.assetStand_form, "init")
  }

  editColumn(data) {
    if (data.action === "edit") {
      this.assetStand_form = data.dataItem;
      this.error = assetStandardizationValidation(this.assetStand_form, "init")
      this.openRoleDailog("update");
    }
    else if (data.action === "remove") {
      this.assetStand_form = data.dataItem;
      this.removeDialogOpen = true;
    }
  }


  getAssetStatus() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetStatuses", true, true)
        .then((result: any) => {

          this.assetStatusDropDown = result;
      
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getAssetManufacture() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetManufacturers", true, true)
        .then((result: any) => {
          this.assetManufactureDropDown = result;
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  getAssetModel() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetModels", true, true)
        .then((result: any) => {
          this.assetModelDropDown = result;
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  bindManufactureObj(result) {
    this.manufactureObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindModelObj(result) {
    this.modelObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {})
  }

  bindAssetTypeObj(result) {
    this.assetTypeObj = result.reduce((acc, d) => {
      acc[d.id] = d;
      return acc;
    }, {})
  }

  getAssetStandardizations() {
    HttpClient.get("/api/Assets/GetAssetStandardizations", true, true).then((result: any) => {
      let data = shiftLastDataInto1st(result)
      this.bindStatusObj(this.assetStatusDropDown);
      this.bindManufactureObj(this.assetManufactureDropDown);
      this.bindModelObj(this.assetModelDropDown);
      this.gridData = data.map((d) => {
        d["assetTypeCode"] = this.assetTypeObj[d.assetTypeId]&& this.assetTypeObj[d.assetTypeId].code;
        d["status"] = this.statusObj[d.assetStatusId];
        d["manufacture"] = this.manufactureObj[d.assetManufacturerId];
        d["model"] = this.modelObj[d.assetModelId];
        d["deleteKey"]= (this.permission.isDelete && d.status != null && d.status.toLowerCase()=="inactive")?true:false;
        d["typecodediscr"]=this.assetTypeObj[d.assetTypeId] && this.assetTypeObj[d.assetTypeId].description;
        return d;
      })
    })
  }

  onSubmit() {
    this.submitted = true;
    this.error = assetStandardizationValidation(this.assetStand_form, "");
    
    if (!this.error.assetModelId && !this.error.assetManufacturerId && !this.error.assetStatusId && !this.error.assetTypeId) {
      this.AddAssetStand_Data();
    }
  }

  AddAssetStand_Data() {
    if (this.type == "new") {
      const params = {
        ...this.assetStand_form,
        createdDate: new Date(),
        modifiedDate: new Date(),
        id: 0
      };
      HttpClient.fetchData("/api/Assets/AddAssetStandardization", "post", params, true, true)
        .then((result: any) => {
          if (result.isValid == true) {
            HttpClient.insertAduitLog("Asset Standardization","Add Asset Standardization","Add Asset Standardization").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
           
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            // const statusValue = this.assetStatusDropDown.filter(x => {
            //   return x.id == params.assetStatusId;
            // })
            // const modelValue = this.assetModelDropDown.filter(x => {
            //   return x.id == params.assetModelId;
            // })
            // const manufValue = this.assetManufactureDropDown.filter(x => {
            //   return x.id == params.assetManufacturerId;
            // })

            // const assetCode = this.assetTypeArray.filter(x => {
            //   return x.id == params.assetTypeId;
            // })

            // let obj = {
            //   ...this.assetStand_form,
            //   status: statusValue[0].name,
            //   model: modelValue[0].name,
            //   manufacture: manufValue[0].name,
            //   assetTypeCode: assetCode[0].code,
            // }
            // this.gridData.unshift(obj)
            // this.gridData = JSON.parse(JSON.stringify(this.gridData));
            this.getAssetStandardizations()
            this.opened = false;
          }
          else {
            this._notif.show({
              content: result.messageKey,
              type: { style: "error", icon: true },
            });
          }
        });
    }
    if (this.type == "update") {
      const params = {
        ...this.assetStand_form,
        createdDate: new Date(),
        modifiedDate: new Date(),
      };
      HttpClient.fetchData("/api/Assets/UpdateAssetStandardization", "post", params, true, true)
        .then((result: any) => {
          if (result.isValid == true) {
            HttpClient.insertAduitLog("Asset Standardization","Update Asset Standardization","Update Asset Standardization").then((res:any)=>{
              if (res.isValid == true) {
    
              }
            })
            this._notif.show({
              content: result.messageKey,
              type: { style: "success", icon: true },
            });
            // const statusValue = this.assetStatusDropDown.filter(x => {
            //   return x.id == params.assetStatusId;
            // })
            // const modelValue = this.assetModelDropDown.filter(x => {
            //   return x.id == params.assetModelId;
            // })
            // const manufValue = this.assetManufactureDropDown.filter(x => {
            //   return x.id == params.assetManufacturerId;
            // })

            // const assetCode = this.assetTypeArray.filter(x => {
            //   return x.id == params.assetTypeId;
            // })

            // let obj = {
            //   ...this.assetStand_form,
            //   status: statusValue[0].name,
            //   model: modelValue[0].name,
            //   manufacture: manufValue[0].name,
            //   assetTypeCode: assetCode[0].code
            // }
            // this.gridData = this.gridData.map((d) => {
            //   if (d.id == this.assetStand_form.id) {
            //     d = obj;
            //   }
            //   return d;
            // })
            // this.gridData = JSON.parse(JSON.stringify(this.gridData));
            this.getAssetStandardizations()
            this.opened = false;

          }
          else {
            this._notif.show({
              content: result.messageKey,
              type: { style: "error", icon: true },
            });
          }
        })
    }
  }

  removeAssetStand() {
    HttpClient.fetchData("/api/Assets/DeleteAssetStandardizationById", "post", this.assetStand_form, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Asset Standardization","Delete Asset Standardization","Delete Asset Standardization").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
        
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.gridData = this.gridData.filter(x => {
            return x.id != this.assetStand_form.id;
          })
          this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.removeDialogOpen = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      }).catch((error) => {
      });
  }

  getAssetType() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetAssetTypes", true, true)
        .then((result: any) => {
          this.assetTypeArray = result;
          this.bindAssetTypeObj(result);
          resolve(result)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  qrCodeGeneration(data){
    this.qrCodeAsssetForm.id = data.id;
    this.qrCodeAsssetForm.modifiedBy = parseInt(getCookie(userid));
    this.qrCodeAsssetForm.createdBy = parseInt(getCookie(userid));
    this.qrCodeAsssetForm.createdDate = new Date();
    this.qrCodeAsssetForm.modifiedDate = new Date();
    this.qrCodeAsssetForm.moduleHierarchyId = this.assetModeuleHierarchyId;
    this.qrCodeAsssetForm.assetStandardizationId = data.id;
    this.qrCodeAsssetForm.assetBpms =[];
    this.qrCodeAsssetForm.assetInfos = [];
    this.qrCodeAsssetForm.assetLocations = [];
    this.qrCodeAsssetForm.assetMaintenances =[];
    this.qrCodeAsssetForm.assetCode = this.assetTypeObj[data.assetTypeId]&& this.assetTypeObj[data.assetTypeId].code;
  
   HttpClient.fetchData("/api/MasterData/GenerateQRCodeForAsset", "post", this.qrCodeAsssetForm, true, true)
    .then((result: any) => {
          if(result===true){
            HttpClient.get(
            `/api/MasterData/GetQRCodeByReferenceIdandModuleHierarchyId?referenceId=${this.qrCodeAsssetForm.id}&&moduleHierarchyId=${this.qrCodeAsssetForm.moduleHierarchyId}`,
              true,
              true
            ).then((result: any) => {
              this.qrCodeGenUrl = result
             
            })
          }
    });
      this.qrCodeDialogOpen= true;
  }

  getAssetReg(data){
     this._router.navigate(["asset-register"],{queryParams:{"id":data.id}})
  }

  getModuleHierarchYdata(schema, tablename){
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      
    let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr =     ModuleHieracrhyData.filter((e)=>{
              return e.NAME ==="Asset Standardization"
        })
          this.assetModeuleHierarchyId = ModFacArr[0].ID
       
    });
  }

}
