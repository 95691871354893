import { environment } from "src/environments/environment";
import {getCookie} from "src/Util/Util"
import {dispatchEvent,EVENT_NAME} from "src/event"
import {authorization,userid} from "src/Util/const"
import * as XLSX from 'xlsx';
import { ApiCount } from "src/Util/Util";
export default class HttpClient{
   static fetchData(path,method,body,isAuth=false,isLoadder=true){
       let header={}
    //    if(isLoadder){
    //     document.getElementById("loader").style.display = "block";
    //    }
       if(isAuth){
        header={
            Authorization:getCookie(authorization),
            UserId:getCookie(userid),
            "content-type":"application/json; charset=utf-8"
           }
       } 
       else{
        header={
            Authorization:"",
            UserId:"",
            "content-type":"application/json; charset=utf-8"
           } 
       }
      // header["content-type"]="application/json; charset=utf-8";
        let url="";
        let option={
            method:method,
            headers:header
            }
        if(environment.proxy){
            url=environment.proxyUrl+"?urlpath="+path; // /api?urlpath=xyz/djhj/dhgd
            option["body"]=JSON.stringify({data:JSON.stringify(body)});
        }
        else{
          url=environment.backendUrl+path;   // https://admin.xyz.com/xyz/djhj/dhgd
          option["body"]=JSON.stringify(body);
        }
        return new Promise((resolve,reject)=>{
            ApiCount.count=ApiCount.count+1;
            fetch(url,option)
            .then((res)=>{
                ApiCount.count=ApiCount.count-1;
             return res.json();
        })
            .then((result:any)=>{
                console.log("courrent count",ApiCount.count)
                if(ApiCount.count===0)
                  document.getElementById("loader").style.display = "none";
                if(result && result.message==="Unauthorized"){
                    dispatchEvent(EVENT_NAME.authorization,false)
                }
                resolve(result);
            })
            .catch((err)=>{
                if(ApiCount.count===0)
                   document.getElementById("loader").style.display = "none";
                reject(err)
            })
        })
    }
    static get(path,isAuth=false,isLoadder=true){
        let header={}
        // if(isLoadder){
        //     document.getElementById("loader").style.display = "block";
        //    }
        if(isAuth){
             header={
             Authorization:getCookie(authorization),
             UserId:getCookie(userid),
             "content-type":"application/json; charset=utf-8"
            }
        } 
        else{
         header={
             Authorization:"",
             UserId:"",
             "content-type":"application/json; charset=utf-8"
            } 
        } 
      // header["content-type"]="application/json; charset=utf-8";
        let url="";
        let option={
            method:"get",
            headers:header
            }
        if(environment.proxy){
            url=environment.proxyUrl+"?urlpath="+encodeURIComponent(path);
        }
        else{
          url=environment.backendUrl+path;
        }
        return new Promise((resolve,reject)=>{
            ApiCount.count=ApiCount.count+1;
            fetch(url,option)
            .then((res)=>{
                ApiCount.count=ApiCount.count-1;
                return res.json()
            })
            .then((result:any)=>{
                console.log("courrent count",ApiCount.count)
                if(ApiCount.count===0)
                    document.getElementById("loader").style.display = "none";
                if(result && result.message==="Unauthorized"){
                    dispatchEvent(EVENT_NAME.authorization,false)
                }
                resolve(result);
            })
            .catch((err)=>{
                if(ApiCount.count===0)
                  document.getElementById("loader").style.display = "none";
                reject(err)
            })
        })
    }
    static uploadFile1=(formate,maxSize, callback)=>{
        function getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
          }
        var fileFormate="";
        if(formate && formate.length>0){
             fileFormate="."+formate.join(",.")
        }
        else{
            formate=[]
        }
        var file=document.createElement("input");
        file.setAttribute("type","file")
        file.setAttribute("name","file")
        file.setAttribute("multiple","");
        file.setAttribute("accept",fileFormate)
        var inputBtn=document.createElement("input")
        inputBtn.setAttribute("type","submit")
        var form=document.createElement("form");
        form.appendChild(file);
        form.appendChild(inputBtn);
        file.addEventListener("change",(e:any)=>{
            document.getElementById("loader").style.display="block";
             let file =e.target.files[0]
             if(file){
             let ext=getExtension(file.name);
             let matExt= formate.length==0 || formate.filter((f)=>f.toLowerCase()==ext).length>0 
             let size=e.target.files.item(0).size;
             const fsize=(size/(1024*1024));
             
             if(!matExt){
                 callback("File Formate Not Supported.",null)
             }
             if(maxSize && fsize>maxSize){
                callback("File Size can not be more then "+maxSize+" MB",null)
             }
             else{
                    fetch(`${environment.IMG_UPLOAD_SERVER}?path=${environment.bucket}`, {
                        method: 'POST',
                        body: new FormData(form)
                    })
                    .then((res)=>res.json())
                    .then((result:any)=>{
                        document.getElementById("loader").style.display="none";
                       
                        if(result && result.length>0){
                            callback(null,result);
                        }
                        else{
                            callback(result,null);
                        }
                    })
                    .catch((err)=>{
                        document.getElementById("loader").style.display="none";
                        callback(err);
                    })   
          }  
        }
        })
        
        file.click();
    }
    static saveAttechment(type,id,data){
        let dataStr=JSON.stringify(data);
        return new Promise((resolve,reject)=>{
            fetch("/attechment",{
                method:"POST",
                headers:{
                    "content-type":"application/json; charset=utf-8"
                },
                body:JSON.stringify({type,id,data:dataStr})
            })
            .then((res)=>res.json())
            .then((result:any)=>{
                if(result.status)
                  resolve(result)
                else
                  reject(result)
            })
            .catch((err)=>{
                reject(err)
            })
        })
    }
    static getAttechment(type,id){
        return new Promise((resolve,reject)=>{
            fetch(`/attechment?type=${type}&id=${id}`)
            .then((res)=>res.json())
            .then((result:any)=>{
                if(result.status){
                    resolve(JSON.parse(result.data))
                }
                else{
                    resolve([])
                }
            })
            .catch((err)=>{
                reject(err)
            })
        })
    }
    static fetchDataWithToken(path,method,body,token:string,isAuth=false,isLoadder=false){
        let header={}
        // if(isLoadder){
        //  document.getElementById("loader").style.display = "block";
        // }
        if(token){
         header={
             Authorization:token,
             "content-type":"application/json; charset=utf-8"
            }
        } 
        else{
         header={
             Authorization:"",
             UserId:"",
             "content-type":"application/json; charset=utf-8"
            } 
        }
       // header["content-type"]="application/json; charset=utf-8";
         let url="";
         let option={
             method:method,
             headers:header
             }
         if(environment.proxy){
             url=environment.proxyUrl+"?urlpath="+path; // /api?urlpath=xyz/djhj/dhgd
             option["body"]=JSON.stringify({data:JSON.stringify(body)});
         }
         else{
           url=environment.backendUrl+path;   // https://admin.xyz.com/xyz/djhj/dhgd
           option["body"]=JSON.stringify(body);
         }
         return new Promise((resolve,reject)=>{
             fetch(url,option)
             .then((res)=>{
              return res.json();
         })
             .then((result)=>{
                 document.getElementById("loader").style.display = "none";
                 if(result && result.message==="Unauthorized"){
                     dispatchEvent(EVENT_NAME.authorization,false)
                 }
                 resolve(result);
             })
             .catch((err)=>{
                 reject(err)
             })
         })
        }
         static importFile=(formate,fileUploadType, callback,isAuth=false)=>{
             let reader
             let header={}
             if(isAuth){
                header={
                    Authorization:getCookie(authorization),
                    UserId:getCookie(userid),
                    "content-type":"application/json; charset=utf-8"
                   }
               } 
               else{
                header={
                    Authorization:"",
                    UserId:"",
                    "content-type":"application/json; charset=utf-8"
                   } 
               }
             
            function getBase64(file,cb) {
                 reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  console.log(reader.result,fileUploadType);
                  cb(null,reader.result)
                };
                reader.onerror = function (error) {
                  console.log('Error: ', error);
                  cb(error,null)
                };
             }

            function getExcelObj(file,cb){
                reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function () {
                  //console.log(reader.result,fileUploadType);
                  let workBook = null;
                  let jsonData = null;
                  const data = reader.result;
                    workBook = XLSX.read(data, { type: 'binary' });
                    jsonData = workBook.SheetNames.reduce((initial, name) => {
                        const sheet = workBook.Sheets[name];
                        initial[name] = XLSX.utils.sheet_to_json(sheet);
                        return initial;
                    }, {});
               //  console.log("excel===>",jsonData);
                  cb(null,jsonData["Sheet1"]);
                };
                reader.onerror = function (error) {
                  console.log('Error: ', error);
                  cb(error,null)
                };
            }
            function getExtension(filename) {
                var parts = filename.split('.');
                return parts[parts.length - 1];
            }
            var fileFormate="";
            if(formate && formate.length>0){
                 fileFormate="."+formate.join(",.")
            }
            else{
                formate=[]
            }
            var file=document.createElement("input");
            file.setAttribute("type","file")
            file.setAttribute("name","file")
            file.setAttribute("multiple","");
            file.setAttribute("accept",fileFormate)
            var inputBtn=document.createElement("input")
            inputBtn.setAttribute("type","submit")
            var form=document.createElement("form");
            form.appendChild(file);
            form.appendChild(inputBtn);
            file.addEventListener("change",(e:any)=>{
                // document.getElementById("loader").style.display="block";
                 let file =e.target.files[0]
                 let extStr=getExtension(file.name);
                 if(extStr==="xlsx"){
                    getExcelObj(file,(err,result)=>{
                        callback(err,result);
                    })
                  }
                  else if(extStr==="csv"){
                      callback("csv",null);
                  }
                //  getBase64(file,(err,result)=>{
                //     fetch(environment.backendUrl+"/api/masterdata/uploaddocument", {
                //         method: 'POST',
                //         headers:header,
                //         body: JSON.stringify({FileContents:result,UploadFileTypeEnum :fileUploadType})
                //     })
                //      .then((res)=>res.json())
                //     .then((result:any)=>{
                //         document.getElementById("loader").style.display="none";
                //         console.log(result);
                //         if(result && result.length>0){
                //             callback(null,result);
                //         }
                //         else{
                //             callback(result,null);
                //         }
                //     })
                //     .catch((err)=>{
                //         document.getElementById("loader").style.display="none";
                //         callback(err);
                //     }) 
                //  })
            //      if(file){
            //      let ext=getExtension(file.name);
            //      let matExt= formate.length==0 || formate.filter((f)=>f.toLowerCase()==ext).length>0 
            //      let size=e.target.files.item(0).size;
            //      const fsize=(size/(1024*1024));
            //      if(!matExt){
            //          callback("File Formate Not Supported.",null)
            //      }
            //      if(maxSize && fsize>maxSize){
            //         callback("File Size can not be more then "+maxSize+" MB",null)
            //      }
            //      else{
                        // fetch("http://20.77.96.67:81/api/masterdata/uploaddocument", {
                        //     method: 'POST',
                        //     body: new FormData(form)
                        // })
                        // .then((res)=>res.json())
                        // .then((result:any)=>{
                        //     document.getElementById("loader").style.display="none";
                        //     console.log(result);
                        //     if(result && result.length>0){
                        //         callback(null,result);
                        //     }
                        //     else{
                        //         callback(result,null);
                        //     }
                        // })
                        // .catch((err)=>{
                        //     document.getElementById("loader").style.display="none";
                        //     callback(err);
                        // })   
            //   }  
           // }
            })
            
            file.click();
        }
        static importExcelFile=(formate,fileUploadType, callback,isAuth=false)=>{
            let reader
            let header={}
            if(isAuth){
               header={
                   Authorization:getCookie(authorization),
                   UserId:getCookie(userid),
                   "content-type":"application/json; charset=utf-8"
                  }
              } 
              else{
               header={
                   Authorization:"",
                   UserId:"",
                   "content-type":"application/json; charset=utf-8"
                  } 
              }
            
           function getBase64(file,cb) {
                reader = new FileReader();
               reader.onload = function () {
                 cb(null,reader.result)
               };
               reader.onerror = function (error) {
                 console.log('Error: ', error);
                 cb(error,null)
               };
               reader.readAsDataURL(file);
            }
           var fileFormate="";
           if(formate && formate.length>0){
                fileFormate=-"."+formate.join(",.")
           }
           else{
               formate=[]
           }
           var file=document.createElement("input");
           file.setAttribute("type","file")
           file.setAttribute("name","file")
           file.setAttribute("multiple","");
           file.setAttribute("accept",fileFormate)
           var inputBtn=document.createElement("input")
           inputBtn.setAttribute("type","submit")
           var form=document.createElement("form");
           form.appendChild(file);
           form.appendChild(inputBtn);
           file.addEventListener("change",(e:any)=>{
                getBase64(e.target.files[0],(err,result)=>{
                   let fi=result.indexOf(",");
                   let fileC=result.substring(fi+1);
                   fetch(environment.backendUrl+"/api/masterdata/uploaddocument", {
                       method: 'POST',
                       headers:header,
                       body: JSON.stringify({FileContents:fileC,UploadFileTypeEnum :fileUploadType})
                   })
                    .then((res)=>res.json())
                   .then((result:any)=>{
                       document.getElementById("loader").style.display="none";
                       console.log(result);
                       if(result && result.length>0){
                           callback(null,result);
                       }
                       else{
                           callback(result,null);
                       }
                   })
                   .catch((err)=>{
                       document.getElementById("loader").style.display="none";
                       callback(err);
                   }) 
                })
           })
           
           file.click();
       }
        static uploadFile=(formate,maxSize, callback,isImg=false)=>{
            function getBase64(file,cb) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  console.log(reader.result);
                  cb(null,reader.result)
                };
                reader.onerror = function (error) {
                  console.log('Error: ', error);
                  cb(error,null)
                };
             }
            function getExtension(filename) {
                var parts = filename.split('.');
                return parts[parts.length - 1];
              }
            var fileFormate="";
            if(formate && formate.length>0){
                 fileFormate="."+formate.join(",.")
            }
            else{
                formate=[]
            }
            var file=document.createElement("input");
            file.setAttribute("type","file")
            file.setAttribute("name","file")
            file.setAttribute("multiple","");
            file.setAttribute("accept",fileFormate)
            var inputBtn=document.createElement("input")
            inputBtn.setAttribute("type","submit")
            var form=document.createElement("form");
            form.appendChild(file);
            form.appendChild(inputBtn);
            file.addEventListener("change",(e:any)=>{
               // document.getElementById("loader").style.display="block";
                 let file =e.target.files[0]
                 getBase64(file,(err,result)=>{
                     if(!isImg){
                        let index=result.search(",");
                        result=result.substring(index+1);
                     }
                     callback(err,{data:result,name:file.name,ext:getExtension(file.name)})
                 })
            })
            
            file.click();
        }
        static uploadMultipleFile=(formate,maxSize, callback,isImg=false)=>{
            function getBase64(file) {
                return new Promise((resolve,reject)=>{
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        resolve(reader.result);
                     // console.log(reader.result);
                     // cb(null,reader.result)
                    };
                    reader.onerror = function (error) {
                     // console.log('Error: ', error);
                      //cb(error,null)
                      resolve(null);
                    };
                })
             }
            function getExtension(filename) {
                var parts = filename.split('.');
                return parts[parts.length - 1];
              }
            var fileFormate="";
            if(formate && formate.length>0){
                 fileFormate="."+formate.join(",.")
            }
            else{
                formate=[]
            }
            var file=document.createElement("input");
            file.setAttribute("type","file")
            file.setAttribute("name","file")
            file.setAttribute("multiple","");
            file.setAttribute("accept",fileFormate)
            var inputBtn=document.createElement("input")
            inputBtn.setAttribute("type","submit")
            var form=document.createElement("form");
            form.appendChild(file);
            form.appendChild(inputBtn);
            file.addEventListener("change",(e:any)=>{
                // document.getElementById("loader").style.display="block";
                let files=[...e.target.files]
                Promise.all(
                    
                        files.map((f)=>{
                            return getBase64(f)
                        })
                )
                .then((result)=>{
                    document.getElementById("loader").style.display="none";
                    callback(result)
                })
               // document.getElementById("loader").style.display="block";
                //  let file =e.target.files[0]
                //  getBase64(file,(err,result)=>{
                //      if(!isImg){
                //         let index=result.search(",");
                //         result=result.substring(index+1);
                //      }
                //      callback(err,{data:result,name:file.name,ext:getExtension(file.name)})
                //  })
            })
            
            file.click();
        }
        public static async AddNotification(recieveId,details,moduleHierarchyId,url){
            let expiaryDate = new Date();
            expiaryDate.setDate(expiaryDate.getDate()+30)
              let obj ={
                "id": 0,
                "recieverId": recieveId,
                "details": details,
                "expiary": expiaryDate,
                "moduleHierarchyId":moduleHierarchyId,
                "url": url,
                "viewed":false,
                "createdDate":new Date()
            }
          return  this.fetchData("/api/MasterData/AddNotification","post",obj,true,true)
        }

  public static async insertAduitLog(module:any,action:any,description:any,ip?:any)
  {
      
    let d =  new Date();
    let obj ={
      "id": 0,
      "userId": parseInt(getCookie(userid)),
      "date":d,
      "time": d.getHours()+":"+d.getMinutes()+":"+d.getSeconds(),
      "module": module,
      "action": action,
      "description": description,
      "ipaddress":localStorage.getItem('ip'),
      "userName": ""
    }
    return this.fetchData("/api/MasterData/AddAuditLog","post",obj,true,true)

}
 }
     
