import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
export class Level{
    ID:number
    NAME:string
    CODE:string
    SHORTNAME:string
    STATUSID:number
    BLOCKID:number
    MODIFIEDBY: number
    MODIFIEDDATE:Date;
    CREATEDBY:number
    CREATEDDATE:Date;
    FACILITYID:number;
    NAMEMODIFIEDBY:string;
    NAMECREATEDBY:string
}

export class LevelObj{
    alldata:Array<Level>=[]
    filterData:Array<Level>=[]
    selectedObj:Level=null;
    objectData={}
    multiSelect:Array<Level>=[]
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Level)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.dbo,LOOKUPTABLE.blocklevel)
            .then((result:Array<Level>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}