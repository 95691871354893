const formData=[
    {
      name:"ID",
      key:"ID",
      type:"lable",
      label:"",
      value:0,
      insert:true,
      update:true  
    },
    {
        name:"FACILITYID",
        key:"FACILITYID",
        table:"Facility",
        type:"list",
        label:"Facility Name", 
        props:{
            filter:true,
            textField:"NAME",
            valueField:"ID"
        },
        insert:true,
        update:true  
    },
     {
        name:"BLOCKID",
        key:"BLOCKID",
        table:"Block",
        type:"list",
        label:"Block Name", 
        props:{
            filter:true,
            textField:"NAME",
            valueField:"ID"
        },
        insert:true,
        update:true  
    },
    {
        name:"CODE",
        key:"CODE",
        type:"text",
        label:"Level Code",
        insert:true,
        update:true    
    },
    {
        name:"NAME",
        key:"NAME",
        type:"text",
        label:"Level Name",
        insert:true,
        update:true  
    },
    {
        name:"SHORTNAME",
        key:"SHORTNAME",
        type:"text",
        label:"Short Name",
        insert:true,
        update:true  
    },
    {
        name:"STATUSID",
        key:"STATUSID",
        table:"Status",
        type:"list",
        props:{
            filter:true,
            textField:"NAME",
            valueField:"ID"
        },
        label:"Status",
        value:{},
        insert:true,
        update:true    
    },
    
    {
        name:"CREATEDBY",
        key:"CREATEDBY",
        type:"lable",
        label:"",
        value:1,
        insert:false,
        update:false  
    },
    {
        name:"MODIFIEDBY",
        key:"MODIFIEDBY",
        type:"lable",
        label:"",
        value:1,
        insert:false,
        update:false  
    },
    {
        name:"CREATEDDATE",
        key:"CREATEDDATE",
        type:"lable",
        label:"",
        value:new Date(),
        insert:false,
        update:false  
    },
    {
        name:"MODIFIEDDATE",
        key:"MODIFIEDDATE",
        type:"hidden",
        label:"",
        insert:false,
        update:false  
    }
   
]

const gridColumn=[
    { field: "NAME", title: "Level Name", isFilter: true },
    { field: "SHORTNAME", title: "Short Name", isFilter: false },
    { field: "Status.NAME", title: "Status", isFilter: false },
    { field: "MODIFIEDDATE", title: "Last Updated By", isFilter: false },
    { field: "MODIFIEDDATE", title: "Last Updated On", isFilter: false },
]

const listData={
    "Block":[],
    "Status":[],
    "Facility":[]
    
}
const fkPrimaryKey={
    "Block":"ID",
    "Status":"ID",
    "Facility":"ID"
    
}
const extraColumn=[
    {key:"BLOCKID",table:"Block",column:["ID","NAME"]},
    {key:"STATUSID",table:"Status",parse:(val)=>{return parseInt(val)},column:["ID","NAME"]},
    {key:"FACILITYID",table:"Facility",column:["ID","NAME"]},
]

const template={
    title:"Level",
    add_btn:"Add Level",
    add_dailog_title:"Add Level",
    edit_dailog_title:"Edit Level",
    delete_dailog_title:"Delete Level",
    delete_description:"Are you sure you want to delete"
}
export default {formData,gridColumn,listData,fkPrimaryKey,extraColumn,template}