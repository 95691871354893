<div class="card m-3"  >
    <div class="card-header" >
        <h5>Contract Management for Facility User</h5>
        <div>     
       
            <button kendoButton  primary="true" (click)="downloadTemplate()" *ngIf="permission && permission.isImport" class="header-right-btn">
              Download Template
                 </button> &nbsp;  &nbsp;
                 <button kendoButton  primary="true" *ngIf="permission && permission.isImport" (click)="importData()" class="header-right-btn">
                  Import
                     </button> &nbsp;  &nbsp;
              <button kendoButton  primary="true" (click)="download()" class="header-right-btn" *ngIf="permission && permission.isExport">
              Export
               </button>  &nbsp;  &nbsp;   
       
          <button kendoButton  primary="true" *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span>Contract Management for Facility User
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >

                <app-table-grid
                [data]=" historyData"
                [column]="historyCol"
                [size]="10"
                [editFlag]="true"
                [viewFlag] = "true"
                [fileFlag]="true"
                [assetFlag]="true"
                [renewFlag] = "false"
                renewKey = "renewKey"
            ></app-table-grid>
           
       
    
        <!-- <div>
            <app-table-grid
            [data]="gridData"
            [column]="column_asset"
            [size]="10"
            [deleteFlag]="false"
            deleteKey="deleteKey"
        ></app-table-grid>
        </div> -->
    </div>
</div>
  <kendo-dialog *ngIf="opened" (close)="onDialogClose('asset')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
         
    <div class="form-row">
        <div class="form-group col-5">
             <label>Contractor Code<span class="required">*</span></label>
             <div style="width: 100%;">
                <kendo-dropdownlist  
                    [filterable]="true" 
                    [data]="contractCodeDropDown" 
                    [textField]="'registrationNo'"
                    [valueField]="'id'"
                    [valuePrimitive]="true" 
                    class="form-control"
                    [(ngModel)]="contractorManFacilityForm.BPMID"
                    (valueChange)="selectContractor($event)"
                    [disabled]="!isNew"
                    (filterChange)="handleFilter('contractor-code',$event)"
              >
                                            </kendo-dropdownlist>
                 <kendo-formerror *ngIf="error.BPMID">*Required</kendo-formerror>
             </div>
         </div>
         <div class="form-group col-5">
             <label>Contractor Name <span class="required">*</span></label>
             <div style="width: 100%;">
                 <input kendoTextBox [disabled]="!isNew"  class="form-control" disabled [(ngModel)] ="contractorName"/>
                 <!-- <kendo-formerror *ngIf="error.name">*Required</kendo-formerror> -->
             </div>
         </div>
     </div>

     <div class="form-row">
        <div class="form-group col-5">
             <label>Contract No.<span class="required">*</span></label>
             <div style="width: 100%;">
                <input kendoTextBox [disabled]="!isNew"    [(ngModel)]="contractorManFacilityForm.CONTRACTNO" class="form-control" />
                 <kendo-formerror *ngIf="error.CONTRACTNO">*Required</kendo-formerror>
             </div>
         </div>
         <div class="form-group col-5">
          <label>Fax No.</label>
          <div style="width: 100%;">
             <input kendoTextBox [disabled]="!isNew"   [(ngModel)]="faxNo" class="form-control" />
              <!-- <kendo-formerror *ngIf="error.faxNo">*Required</kendo-formerror> -->
          </div>
         </div>
     </div>
     <!-- <div class="form-row">
      <div class="form-group col-5">
           <label>Fax No.</label>
           <div style="width: 100%;">
              <input kendoTextBox    [(ngModel)]="contractorManFacilityForm.CONTRACTNO" class="form-control" />
             
           </div>
       </div>
       <div class="form-group col-5">
        <label>Email</label>
        <div style="width: 100%;">
           <input kendoTextBox    [(ngModel)]="contractorManFacilityForm.CONTRACTNO" class="form-control" />
            
        </div>
       </div>
   </div> -->
     <div class="form-row">
        <div class="form-group col-5">
             <label>Contract Start Date<span class="required">*</span></label>
             <div style="width: 100%;">
                <kendo-datepicker [(ngModel)]="contractorManFacilityForm.CONTRACTSTARTDATE"  ngDefaultControl>
                </kendo-datepicker>
                 <kendo-formerror *ngIf="error.CONTRACTSTARTDATE">*Required</kendo-formerror>
             </div>
         </div>
         <div class="form-group col-5">
            <label>End Date<span class="required">*</span></label>
            <div style="width: 100%;">
              <kendo-datepicker [(ngModel)]="contractorManFacilityForm.CONTRACTENDDATE" ngDefaultControl>
            </kendo-datepicker>
                <kendo-formerror *ngIf="error.CONTRACTENDDATE">*Required</kendo-formerror>
            </div>
         </div>
     </div>
     <!-- <div class="form-row">
        <div class="form-group col-5">
             <label>Contact Person<span class="required">*</span></label>
             <div style="width: 100%;">
                <input kendoTextBox  class="form-control" />
           
             </div>
         </div>
         <div class="form-group col-5">
            <label>Alternate Contact Person<span class="required">*</span></label>
            <div style="width: 100%;">
                <input kendoTextBox  class="form-control" />
               
            </div>
         </div>
         </div> -->
         <!-- <div class="form-row">
            <div class="form-group col-5">
                 <label>Designation<span class="required">*</span></label>
                 <div style="width: 100%;">
                    <input kendoTextBox  class="form-control" />
                   
                 </div>
             </div>
             <div class="form-group col-5">
                <label>Alternate Person Designation<span class="required">*</span></label>
                <div style="width: 100%;">
                    <input kendoTextBox  class="form-control" />
               
                </div>
             </div>
     </div> -->
    
 <div class="form-row">
    
     <div class="form-group col-5">
        <label>Contract Sum (RM)</label>
        <div style="width: 100%;">
            <input kendoTextBox [disabled]="!isNew"  class="form-control" [(ngModel)]="contractorManFacilityForm.CONTRACTSUM" disabled/>
            <!-- <kendo-formerror *ngIf="submitted && error.registrationNo">*Required</kendo-formerror> -->
        </div>
     </div>
     <div class="form-group col-5">

     </div>
</div>
<div class="form-row">
    <div class="form-group col-5">
         <label>Status<span class="required">*</span></label>
         <div style="width: 100%;">
            <kendo-dropdownlist  [data]="statusDropDown"  [textField]="'NAME'"
            [valueField]="'ID'"
            [(ngModel)]="contractorManFacilityForm.STATUSID"
            [valuePrimitive]="true" 
            class="form-control"
            [disabled]="!isNew"
         >
            </kendo-dropdownlist>
             <kendo-formerror *ngIf="error.STATUSID">*Required</kendo-formerror>
         </div>
     </div>
     <div class="form-group col-5">
        <label>Contract Type<span class="required">*</span></label>
        <div style="width: 100%;">
            <kendo-dropdownlist   
            [data]="contractorTypeDropDown" 
            [(ngModel)]="contractorManFacilityForm.CONTRACTTYPEID"  
            [textField]="'NAME'"
            [valueField]="'ID'"
            [valuePrimitive]="true" 
            class="form-control" 
            ngDefaultControl 
            [disabled]="!isNew"
            >
            </kendo-dropdownlist>
       
            <kendo-formerror *ngIf="error.CONTRACTTYPEID">*Required</kendo-formerror>
        </div>
     </div>
</div>
<div class="form-row">
    <div class="form-group col-5">
         <label>Notification for Renewal<span class="required">*</span></label>
         <div style="width: 100%;">
            <kendo-datepicker [disabled]="!isNew"  [(ngModel)]="contractorManFacilityForm.NOTIFICATIONFORRENEWAL"  ngDefaultControl>
            </kendo-datepicker>
             <kendo-formerror *ngIf="error.NOTIFICATIONFORRENEWAL">*Required</kendo-formerror>
         </div>
     </div>
     <div class="form-group col-5">
        <label>Scope Of Work</label>
        <div style="width: 100%;">
            <input kendoTextBox [disabled]="!isNew" [(ngModel)]="contractorManFacilityForm.SCOPEOFWORKY" class="form-control" />

            <!-- <kendo-formerror *ngIf="error.SCOPEOFWORKY">*Required</kendo-formerror> -->
        </div>
     </div>
</div>
<div class="form-row">
    <div class="form-group col-5">
         <label>Remarks</label>
         <div style="width: 100%;">
            <input kendoTextBox [disabled]="!isNew" [(ngModel)]="contractorManFacilityForm.REMARKS" class="form-control" />

             <!-- <kendo-formerror *ngIf="submitted && error.registrationNo">*Required</kendo-formerror> -->
         </div>
     </div>
     <div class="form-group col-5">
     
     </div>
  </div>


<!-- <table class="spare-part-table">
    <tr>
      <th>Asset No.</th>
      <th>Asset Type Code Description</th>
      <th>Contract Value (RM)</th>
     
    </tr>
    <tr>
      <td> <kendo-dropdownlist  [data]="assetNoDropDown"  [textField]="'assetNumber'"
        [valueField]="'id'"
        [valuePrimitive]="true" class="form-control"
        (valueChange)="assetNoChanged($event)"
        [(ngModel)]="contractorAsstes.ASSETID"
     >
        </kendo-dropdownlist></td>
      <td><input kendoTextBox class="form-control"  [(ngModel)]="assetDes" disabled /></td>
      <td><input  type="number"  [min]="0" kendoTextBox class="form-control"    [(ngModel)]="contractorAsstes.CONTRACTVALUE" (input)="onSearchChange($event.target.value)"/></td>
   
    </tr>
</table> -->
<app-contact-asset-grid
*ngIf="isNew"
[data]="contectGrid"
[column]="contectColumn"
[size]="10"
[deleteFlag]="false"
(valueChange)="contectGrid=$event"
(sumOfContractValue)="getSumOfContract($event)"
>

</app-contact-asset-grid>
<div class="form-row spare-part-table p-4">
  <div class="col-12">
    <table style="width:100%">
        <tr>
          <th>Contact Person</th>
          <th>Designation</th>
          <th>Contact No.</th>
          <th>Email Address</th>
        </tr>
        <tr *ngFor="let item of contactPersonValue">
          <td><input kendoTextBox class="form-control"  [(ngModel)]="item.name" disabled /></td>
          <td><input kendoTextBox class="form-control"  [(ngModel)]="item.designation" disabled /></td>
          <td><input kendoTextBox class="form-control"  [(ngModel)]="item.contactNo" disabled /></td>
          <td><input kendoTextBox class="form-control"  [(ngModel)]="item.email" disabled /></td> 
        </tr>
       
      </table>
    </div>
 </div>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('asset')">Cancel</button>
      <button  *ngIf="type==='new'" (click)=onReset() kendoButton primary="true">
        Reset
      </button>
      <button  *ngIf="type==='new'" kendoButton  primary="true" (click)="submitSparePartUpdateFrom()">
        Save
      </button>
      <button  *ngIf="!isNew && isRenewal" kendoButton  primary="true" (click)="updateRenewbledate()">
        Renew
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeContractorData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         Import File
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=5
                style="width: 100%;"
                (valueChange)=valueChange($event)
                >
           </app-file-model>
            </div>
            </div>
  
          <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="importSave()">
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="viewDialogOpen" (click)="onDialogClose('view')" [width]="1000">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
      Facility User
      </div>
    </kendo-dialog-titlebar>
   
  <div class="card-body register-form-body">
     
    <app-table-grid
    [data]="facilityData"
    [column]="facilityCol"
    [size]="10"
    [action]="false"
   
></app-table-grid>
  </div>
  </kendo-dialog>

  <kendo-dialog *ngIf="attachmentOpened" (close)="attachmentOpened=false" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist 
                    [data]="documentFormate" 
                    class="form-control" 
                    [textField]="'name'" 
                    [valueField]="'id'"
                    [(ngModel)]="attechForm.FILETYPE"
                    [valuePrimitive]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <!-- <kendo-formerror *ngIf="attechError.name">*Required</kendo-formerror> -->

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Attachment</label>
                <div style="width: 100%;">
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
              <!-- <kendo-formerror *ngIf="attechError.url">*Required</kendo-formerror> -->
              </div>
            </div>

            <div class="form-group col-5">
                <button  (click)="addAttechment()" kendoButton primary="true">
                    Add
                </button>
            </div>
           </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="attachmentOpened=false">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid 
         [data]="attechmentDoc.alldata" 
         [column]="column2" 
         [size]="5"
         >
   </app-attachment-table-grid>
</kendo-dialog>

<kendo-dialog *ngIf="Renewopened" (close)="onDialogClose('renew')" [width]="1024">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>
     <div class="form-row">
      <div class="form-group col-5">
           <label>Contract Start Date<span class="required">*</span></label>
           <div style="width: 100%;">
              <kendo-datetimepicker [(ngModel)]="renewableStartDate"  ngDefaultControl>
              </kendo-datetimepicker>
               <!-- <kendo-formerror *ngIf="submitted && error.registrationNo">*Required</kendo-formerror> -->
           </div>
       </div>
       <div class="form-group col-5">
          <label>End Date<span class="required">*</span></label>
          <div style="width: 100%;">
            <kendo-datetimepicker [(ngModel)]="renewableEndDate" ngDefaultControl>
          </kendo-datetimepicker>
              <!-- <kendo-formerror *ngIf="submitted && error.registrationNo">*Required</kendo-formerror> -->
          </div>
       </div>
   </div>
    <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="onDialogClose('renew')">Cancel</button>
    <button  *ngIf="type==='renew'" (click)=onReset() kendoButton primary="true">
      Reset
    </button>
    <button  *ngIf="type==='renew'" kendoButton  primary="true" (click)="updateRenewbledate()">
      Save
    </button>
   
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-dialog *ngIf="viewAssetDialogOpen" (click)="onDialogClose('viewAsset')" [width]="1000">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
    
    </div>
  </kendo-dialog-titlebar>
 
<div class="card-body register-form-body">
   <app-table-grid
    [data]="gridAssetDetData"
    [column]=" assetDetailsCol"
    [size]="10"
    [action] ="false"
  ></app-table-grid>
</div>
</kendo-dialog>

<kendo-dialog *ngIf="false && importopened" (close)="importopened=false" [width]="580">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>

      <div class="form-row">
           <div class="form-group col-6">
              <label>Attachment</label>
              <import-attechment
                 type="doc" 
                 [formate]="['xlsx']" 
         [fileUploadType]=2
                 style="width: 100%;"
              >
            </import-attechment>
          </div>
</div>

        
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="importopened=false">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>