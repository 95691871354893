import { Component, OnInit } from "@angular/core";
import { eventListner, EVENT_NAME } from "src/event";
import { BlockType } from "../../models/location.model";
import { BlockValidation } from "../../../validator/location";
import HttpClient from "src/Util/ApiHandling";
import { getCookie,shiftLastDataInto1st } from "src/Util/Util";
import { userid } from "src/Util/const";
import { NotificationService } from "@progress/kendo-angular-notification";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: "app-blocks",
  templateUrl: "./blocks.component.html",
  styleUrls: ["./blocks.component.scss"],
})
export class BlocksComponent implements OnInit {
  public onTabSelect(e) {}
  public opened = false;
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public statusObj = {};
  public facilityObj = {};
  public removeDialogOpen = false;
  public facilityDropDown = [];
  public statusDropDown = [];
  public isunique=false;
  public uniqueList=[];
  public permission:Permission=new Permission();
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]

  public column1 = [
    {field :"FACILITY",title:"Facility Name",isFilter: true, width:160},
    { field: "NAME", title: "Block Name", isFilter: true, width:140 },
    { field: "CODE", title: "Block Code", isFilter: true, width:140 },
    { field: "SHORTNAME", title: "Short Name", isFilter: true, width:140 },
    { field: "STATUS", title: "Status", isFilter: true, width:140 },
    { field: "NAMEMODIFIEDBY", title: "Last Updated By", isFilter: true , width:160},
    { field: "last_update_on", title: "Last Updated On", isFilter: true , width:160},
  ];
  public gridData = [];
  public BlockForm: BlockType = new BlockType();
  error = BlockValidation(this.BlockForm, "init");
  submitted = false;
  public status: any;

  constructor(private _notif: NotificationService) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
  }

  ngOnInit() {
    if(UserModuleObj.block){
      this.permission=UserModuleObj.block;
    }
    Promise.all([
      this.getfacility("dbo", "facility"),
      this.getStatus("dbo", "status"),
    ]).then((e) => {
      this.getBlockData("dbo", "block");
    });
 
  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  onSubmit() {
    this.submitted = true;
    this.error = BlockValidation(this.BlockForm, "");
    if (!this.error.NAME && !this.error.CODE && !this.isunique) {
      if (this.type == "new") {
          this.BlockForm.ID = 0,
          this.BlockForm.CREATEDBY = parseInt(getCookie(userid)),
          this.BlockForm.MODIFIEDBY = parseInt(getCookie(userid)),
          this.BlockForm.CREATEDDATE = new Date(),
          this.BlockForm.MODIFIEDDATE = new Date();
      }

      this.onBlockData(JSON.stringify(this.BlockForm));
    }
  }

  onBlockData(data) {
    const paramsObj = {
      newData: data,
      schemaName: "dbo",
      tableName: "block",
      userId: getCookie(userid),
    };
    if (this.type == "new") {
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Lookup Data","Add block","Lookup Data and Add block").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
  
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.submitted = false;
          this.getBlockData("dbo", "block");
          // const statusValue = this.statusDropDown.filter((x) => {
          //   return x.ID == this.BlockForm.STATUSID;
           
          // });

          // let obj = {
          //   ...this.BlockForm,
          //   STATUS: statusValue[0].NAME,
          //   last_update_on:this.dateFormating( this.BlockForm.MODIFIEDDATE)
          // };
          // this.gridData.unshift(obj);
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        } else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      });
    }

    if (this.type == "update") {
      HttpClient.fetchData(
        "/api/MasterData/UpdateLookupData",
        "post",
        paramsObj,
        true,
        true
      ).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Lookup Data","update block","Lookup Data and update block").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this.getBlockData("dbo", "block");
          // this._notif.show({
          //   content: result.messageKey,
          //   type: { style: "success", icon: true },
          // });
          // const statusValue = this.statusDropDown.filter((x) => {
          //   return x.ID == this.BlockForm.STATUSID;
          // });
          // let obj = {
          //   ...this.BlockForm,
          //   STATUS: statusValue[0].NAME,
          //   last_update_on:this.dateFormating(this.BlockForm.MODIFIEDDATE)
          // };
          // this.gridData = this.gridData.map((d) => {
          //   if (d.ID == this.BlockForm.ID) {
          //     d = obj;
          //   }
          //   return d;
          // });
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.opened = false;
        }
      });
    }
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindFacilityObj(result){
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }


  getBlockData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
     
      let BlockData = [];
      BlockData = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
      this.bindStatusObj(this.statusDropDown);
      this.bindFacilityObj(this.facilityDropDown)
      this.gridData = BlockData.map((d) => {
        if(d.STATUSID!=null){
          d["STATUS"] = this.statusObj[d.STATUSID];
          d["deleteKey"]= (this.permission.isDelete && d.STATUS.toLowerCase()=="inactive")?true:false;
        }
      
        d["FACILITY"]=this.facilityObj[d.FACILITYID];
        d["last_update_on"]=this.dateFormating(d.MODIFIEDDATE)
        return d;
      });
    });
  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      console.log("asdkas", this.gridData)
      this.BlockForm = new BlockType();
      this.error = BlockValidation(this.BlockForm, "init");
      this.BlockForm.ID = 0;
      this.BlockForm.STATUSID=1;
       this.dailogTitle = "Add Block";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Block";
    }
    this.opened = true;
  }

  editColumn(data) {
    this.isunique=false;
    this.uniqueList=[]
    if (data.action === "edit") {
      this.error = BlockValidation(this.BlockForm, "init");
      this.BlockForm.CODE = data.dataItem.CODE;
      this.BlockForm.CREATEDBY = data.dataItem.CREATEDBY;
      this.BlockForm.CREATEDDATE = data.dataItem.CREATEDDATE;
      this.BlockForm.FACILITYID = data.dataItem.FACILITYID;
      this.BlockForm.ID = data.dataItem.ID;
      this.BlockForm.MODIFIEDBY = data.dataItem.MODIFIEDBY;
      this.BlockForm.NAMEMODIFIEDBY = data.dataItem.NAMEMODIFIEDBY
      this.BlockForm.MODIFIEDDATE = new Date();
      this.BlockForm.NAME = data.dataItem.NAME;
      this.BlockForm.SHORTNAME = data.dataItem.SHORTNAME;
      this.BlockForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.uniqueList.push(this.BlockForm.CODE.toLowerCase())
      this.openRoleDailog("update");
    } else if (data.action === "remove") {
      this.BlockForm = data.dataItem;
      this.BlockForm.STATUSID = parseInt(data.dataItem.STATUSID);
      this.removeDialogOpen = true;
    }
  }

  onReset() {
    this.submitted = false;
  
    //this.BlockForm = new BlockType();
    this.openRoleDailog("new")
  }

  onDialogClose(type) {
    this.uniqueList=[]
    this.isunique=false;
    if ((type = "new")) {
      this.opened = false;
      this.BlockForm = new BlockType();
    }
    if ((type = "remove")) {
      this.submitted = false;
      this.removeDialogOpen = false;
    }
  }

  getfacility(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData);
          this.facilityDropDown = facilityData;
          resolve(facilityData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeBlockData() {
    let data=  {
                      "ID":this.BlockForm.ID,
                      "CODE":this.BlockForm.CODE,
                      "STATUSID":this.BlockForm.STATUSID,
                      "NAME":this.BlockForm.NAME,
                      "FACILITYID":this.BlockForm.FACILITYID,
                      "SHORTNAME":this.BlockForm.SHORTNAME,
                      "NAMECREATEDBY":this.BlockForm.NAMECREATEDBY,
                      "NAMEMODIFIEDBY":this.BlockForm.NAMEMODIFIEDBY
                    }
    let obj={
      "schemaName":"dbo",
      "tableName":"block",
      "userId":1,
      "oldData":JSON.stringify(data),    
      "newData":JSON.stringify(data)
    }
    HttpClient.fetchData(
      "/api/MasterData/DeleteLookupData",
      "post",
      obj,
      true,
      true
    )
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Lookup Data","Delete block","Lookup Data and Delete block").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });

          // this.gridData = this.gridData.filter((x) => {
          //   return x.id != this.BlockForm.ID;
          // });
          // this.gridData = JSON.parse(JSON.stringify(this.gridData));
          this.getBlockData("dbo", "block");
          this.removeDialogOpen = false;
        } else {
          this._notif.show({
            content: "Record cannot be deleted as it is referred in another record",
            type: { style: "error", icon: true },
          });
        }
      })
      .catch((error) => {});
  }
    checkedUniqueCode(){
    this.isunique=false;
    let val:string=this.BlockForm.CODE;
    if(!(this.uniqueList.filter((d)=>d==val.toLowerCase()).length>0)){
        this.isunique=this.gridData.filter((d)=>{
          return val.toLowerCase()==d.CODE.toLowerCase()
        }).length>0
    }
  }
}
