
<div class="card m-3">
    <div class="card-header" >
        <h5>{{uiText?.title}} Planner For Location</h5>
        <div>  
            <button kendoButton  primary="true" (click)="importData()"  class="header-right-btn">
                Import
                   </button> &nbsp;  &nbsp;
                <button kendoButton  primary="true" (click)="download()" class="header-right-btn" >
                Download Template
                 </button>  &nbsp;  &nbsp;    
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            {{uiText?.title}} For Location
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <div class="py-3">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Facility</label>
                    <kendo-dropdownlist  
                        [data]="model.facility.filterData" 
                        [value]="filterGrid.facility" 
                        (valueChange)="filterChange('facility',$event)" 
                        class="form-control"
                        [textField]="'NAME'" 
                        [valueField]="'FACILITYCODE'" 
                    >
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                 <label>Planner</label>
                 <kendo-dropdownlist  
                     [data]="model.planner.filterData" 
                     [value]="filterGrid.planner" 
                     (valueChange)="filterChange('planner',$event)" 
                     class="form-control"
                     [textField]="'NAME'" 
                     [valueField]="'ID'" 
                 >
                 </kendo-dropdownlist>
             </div>
               
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Block Code</label>
                    <kendo-dropdownlist  
                        [data]="model.block.filterData" 
                        [value]="filterGrid.block" 
                        (valueChange)="filterChange('block',$event)" 
                        class="form-control"
                        [textField]="'CODE'" 
                        [valueField]="'CODE'" 
                    >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Block Name</label>
                    <input kendoTextBox [value]="filterGrid.block && filterGrid.block.NAME" class="form-control" disabled/>
                </div>
              
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Level Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                             [data]="model.level.filterData" 
                             [value]="filterGrid.level" 
                             (valueChange)="filterChange('level',$event)" 
                             class="form-control"
                             [textField]="'CODE'" 
                             [valueField]="'ID'" 
                     >
                    </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Level Name</label>
                    <input kendoTextBox [value]="filterGrid.level && filterGrid.level.NAME" class="form-control" disabled/>
                </div>
      
                
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Area Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                    [data]="model.area.filterData" 
                    [value]="filterGrid.area" 
                    (valueChange)="filterChange('area',$event)" 
                    class="form-control"
                    [textField]="'CODE'" 
                    [valueField]="'ID'" 
                    >
                   </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Area Name</label>
                    <input kendoTextBox [value]="filterGrid.area && filterGrid.area.NAME" class="form-control" disabled/>
                </div>
       
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Location Code<span class="required">*</span></label>
                    <kendo-dropdownlist  
                     [data]="model.location.filterData" 
                     [value]="filterGrid.location" 
                     (valueChange)="filterChange('location',$event)" 
                     class="form-control"
                     [textField]="'CODE'" 
                     [valueField]="'ID'" 
                    >
                   </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Location Name</label>
                    <input kendoTextBox [value]="filterGrid.location && filterGrid.location.NAME"  class="form-control" disabled/>
                </div>
            
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Assign To</label>
                    <kendo-dropdownlist  
                    [data]="model.contractor.filterData" 
                    [value]="filterGrid.assignTo" 
                    (valueChange)="filterChange('assignTo',$event)" 
                    class="form-control"
                    [textField]="'name'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Assignee</label>
                    <kendo-dropdownlist  
                    [data]="model.user.filterData" 
                    [value]="filterGrid.assignee" 
                    (valueChange)="filterChange('assignee',$event)" 
                    class="form-control"
                    [textField]="'staffName'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>

            </div>
            <!-- <div class="form-row">
                <div class="form-group col-5">
                    <label>Area Name</label>
                    <kendo-dropdownlist  
                    [data]="model.area.filterData" 
                    [value]="filterGrid.areaName" 
                    (valueChange)="filterChange('areaName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Location Name</label>
                    <kendo-dropdownlist  
                    [data]="model.location.filterData" 
                    [value]="filterGrid.locationName" 
                    (valueChange)="filterChange('locationName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
            </div> -->
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Year</label>
                    <kendo-dropdownlist  
                    [data]="model.year.filterData" 
                    [value]="filterGrid.year" 
                    (valueChange)="filterChange('year',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Month</label>
                    <kendo-dropdownlist  
                    [data]="model.month.filterData" 
                    [value]="filterGrid.month" 
                    (valueChange)="filterChange('month',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <!-- <div class="form-group col-5">
                    <label>Type</label>
                    <input kendoTextBox value="Asset" [disabled]="true" class="form-control"/>
                </div> -->
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                </div>
                <div class="form-group col-5" style="width: 100%;">
                        <div style="display: flex;justify-content: flex-end;width: 100%;">
                            <button kendoButton primary="true" class="mr-2" (click)="resetFetch()">Reset</button>
                            <button kendoButton   primary="true" class="mr-2 px-3" (click)="fetchData()">
                                Fetch
                            </button>
                        </div>
                 </div>
            </div>
        </div>
        <app-table-grid
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened"  (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div class="card-body">
        <div class="form-row">
           <div class="form-group col-5">
               <label>Facility<span class="required">*</span></label>
               <div style="width: 100%;">
                 <kendo-dropdownlist  
                   [data]="model.facility.filterData" 
                   [value]="model.facility.selectedObj" 
                   (valueChange)="valuechange('facility',$event)" 
                   class="form-control"
                   [textField]="'NAME'" 
                   [valueField]="'FACILITYCODE'" 
                   [disabled]="type=='update'" 
               >
               </kendo-dropdownlist>
               <kendo-formerror *ngIf="error.facility">*Required</kendo-formerror>
               </div>
           </div>
           <div class="form-group col-5">
            <label>Planner</label>
            <div style="width: 100%;">
            <kendo-dropdownlist  
                [data]="model.planner.filterData" 
                [value]="model.planner.selectedObj" 
                (valueChange)="valuechange('planner',$event)" 
                class="form-control"
                [textField]="'NAME'" 
                [valueField]="'ID'" 
            >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.planner">*Required</kendo-formerror>
            </div>
        </div>
          
       </div>
       <div class="form-row">
           <div class="form-group col-5">
               <label>Block Code</label>
               <div style="width: 100%;" >
               <kendo-dropdownlist  
                   [data]="model.block.filterData" 
                   [value]="model.block.selectedObj" 
                   (valueChange)="valuechange('block',$event)" 
                   class="form-control"
                   [textField]="'CODE'" 
                   [valueField]="'CODE'" 
                   [disabled]="type=='update'" 
               >
           </kendo-dropdownlist>
           <kendo-formerror *ngIf="error.block">*Required</kendo-formerror>
           </div> 
           </div>
           <div class="form-group col-5">
               <label>Block Name</label>
               <div style="width: 100%;">
               <input kendoTextBox [value]="model.block.selectedObj && model.block.selectedObj.NAME" class="form-control" disabled/>
               </div>
           </div>
         
       </div>
       <div class="form-row">
           <div class="form-group col-11">
               <label style="margin-right: 0.75rem;">Level Code<span class="required">*</span></label>
               <div style="width: 100%;">
               <kendo-multiselect  
                        [data]="model.level.filterData" 
                        [value]="model.level.multiSelect" 
                        (valueChange)="valuechange('level',$event)" 
                        class="form-control"
                        [textField]="'CODE'" 
                        [valueField]="'ID'" 
                        [disabled]="type=='update'" 
                >
               </kendo-multiselect>
               <kendo-formerror *ngIf="error.level">*Required</kendo-formerror>
               </div>
           </div>
           <!-- <div class="form-group col-5">
               <label>Level Name</label>
               <input kendoTextBox [value]="model.level.selectedObj && model.level.selectedObj.NAME" class="form-control" disabled/>
           </div> -->
 
           
       </div>
       <!-- <div class="form-row">
           <div class="form-group col-5">
               <label>Area Code<span class="required">*</span></label>
               <kendo-dropdownlist  
               [data]="model.area.filterData" 
               [value]="model.area.selectedObj" 
               (valueChange)="valuechange('area',$event)" 
               class="form-control"
               [textField]="'CODE'" 
               [valueField]="'ID'" 
               [disabled]="type=='update'" 
               >
              </kendo-dropdownlist>
           </div>
           <div class="form-group col-5">
               <label>Area Name</label>
               <input kendoTextBox [value]="model.area.selectedObj && model.area.selectedObj.NAME" class="form-control" disabled/>
           </div>
  
       </div> -->
       <div class="form-row">
           <div *ngIf="model.level.multiSelect?.length>1" class="form-group col-11">
               <label style="margin-right: 0.75rem;">Location Name<span class="required">*</span></label>
               <div style="width: 100%;">
               <kendo-dropdownlist  
                [data]="model.location.filterGroupLevel" 
                [value]="model.location.multiSelect" 
                (valueChange)="valuechange('location',$event)" 
                class="form-control"
                [textField]="'name'" 
                [valueField]="'code'" 
                [disabled]="type=='update'" 
                [virtual]="{itemHeight: 28}"
                style="margin-right: 0.75rem;"
               >
              </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.location">*Required</kendo-formerror>
              </div>
           </div>
           <div *ngIf="!(model.level.multiSelect?.length>1)" class="form-group col-11">
            <label style="margin-right: 0.75rem;">Location Name<span class="required">*</span></label>
            <div style="width: 100%;">
            <kendo-multiselect 
             [data]="model.location.filterData" 
             [value]="model.location.selectedObj" 
             (valueChange)="valuechange('location',$event)" 
             class="form-control"
             [textField]="'NAME'" 
             [valueField]="'ID'" 
             [disabled]="type=='update'" 
             [virtual]="{itemHeight: 28}"
             style="margin-right: 0.75rem;"
            >
           </kendo-multiselect>
           <kendo-formerror *ngIf="error.location">*Required</kendo-formerror>
           </div>
        </div>
           <!-- <div class="form-group col-5">
               <label>Location Name</label>
               <input kendoTextBox [value]="model.location.selectedObj && model.location.selectedObj.NAME"  class="form-control" disabled/>
           </div> -->
       
       </div>
       <div class="form-row">
           <div class="form-group col-5">
               <label>Assignee<span class="required">*</span></label>
               <div style="width: 100%;">
               <kendo-dropdownlist  
                   [data]="model.user.filterData" 
                   [value]="model.user.selectedObj" 
                   (valueChange)="valuechange('user',$event)" 
                   class="form-control"
                   [textField]="'staffName'" 
                   [valueField]="'id'" 
               >
               </kendo-dropdownlist>
               <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror>

               </div>
           </div>
           <div class="form-group col-5">
            <label>Assign To<span class="required">*</span></label>
            <div style="width: 100%;">
            <kendo-dropdownlist  
                [data]="model.contractor.filterData" 
                [value]="model.contractor.selectedObj" 
                (valueChange)="valuechange('contractor',$event)" 
                class="form-control"
                [textField]="'name'" 
                [valueField]="'id'" 
            >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.assignTo">*Required</kendo-formerror>

            </div>
        </div>
     
       </div>
       <div class="form-row">
        <div class="form-group col-5">
            <label>Contact No.</label>
            <input kendoTextBox [value]="model.user.selectedObj && model.user.selectedObj.mobileNo" [disabled]="true" class="form-control"/>
        </div>
        <div class="form-group col-5"></div>
     </div>
     <div *ngIf="id==2 && type!='update'" class="form-row">
            
        <div class="form-group col-5">
            <label>Schedule Type</label>
            <kendo-dropdownlist  
            [data]="scheduleData.type" 
            [value]="scheduleInfo.type" 
            (valueChange)="scheduleInfo.type=$event" 
            class="form-control"
            [textField]="'name'" 
            [valueField]="'id'" 
        >
        </kendo-dropdownlist>
        </div>
        <div class="form-group col-5">
            <label>Month</label>
            <kendo-multiselect  
            [data]="model.month.filterData" 
            [value]="scheduleInfo.month" 
            (valueChange)="scheduleInfo.month=$event" 
            class="form-control"
            [textField]="'NAME'" 
            [valueField]="'ID'" 
           >
          </kendo-multiselect>
        </div>
    </div>
    <div *ngIf="scheduleInfo.type && scheduleInfo.type.id==1" class="form-row">
        <div class="form-group col-5">
            <label>Date</label>
            <kendo-multiselect  
            [data]="scheduleData.date" 
            [value]="scheduleInfo.date" 
            (valueChange)="scheduleInfo.date=$event" 
            class="form-control"
            [textField]="'name'" 
            [valueField]="'id'" 
           >
          </kendo-multiselect>
        </div>
        <div class="form-group col-5">
         </div>
    </div>
    <div *ngIf="scheduleInfo.type && scheduleInfo.type.id==2" class="form-row">
        <div class="form-group col-5">
            <label>Week</label>
            <kendo-multiselect  
            [data]="scheduleData.week" 
            [value]="scheduleInfo.week" 
            (valueChange)="scheduleInfo.week=$event" 
            class="form-control"
            [textField]="'name'" 
            [valueField]="'id'" 
           >
          </kendo-multiselect>
        </div>
        <div class="form-group col-5">
            <label>Day</label>
            <kendo-multiselect  
            [data]="scheduleData.day" 
            [value]="scheduleInfo.day" 
            (valueChange)="scheduleInfo.day=$event" 
            class="form-control"
            [textField]="'name'" 
            [valueField]="'id'" 
           >
          </kendo-multiselect>
        </div>
    </div>
       <div class="text-center mt-3 mb-4">
           <button kendoButton primary="true" class="mr-2" (click)="saveData()">Save</button>
           <!-- <button kendoButton primary="true" class="mr-2">Download Template</button> -->
           <button kendoButton primary="true" class="mr-2" (click)="reset()">Reset</button>
       </div>  
</div>

  </kendo-dialog>
  <!-- <kendo-dialog *ngIf="false" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=5
                style="width: 100%;"
                (valueChange)=uploadChange($event)
                >
           </app-file-model>
            </div>
            </div>
  
          <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <button kendoButton primary="true" (click)="importSave()">
            Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog> -->

  <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				   [fileUploadType]=6
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>


  <kendo-dialog *ngIf="importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=5
                style="width: 100%;"
                (valueChange)=uploadChange($event)
                >
           </app-file-model>
            </div>
            </div>
          <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="importSave()">
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>