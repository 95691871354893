import { Component, OnInit } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';

@Component({
  selector: 'app-work-order-spare-parts',
  templateUrl: './work-order-spare-parts.component.html',
  styleUrls: ['./work-order-spare-parts.component.scss']
})
export class WorkOrderSparePartsComponent implements OnInit {

  public facilityObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }



  public parentId = -1;
  public moduleId = -1;

  public report: any[];
  public format = "dd/MM/yyyy";
  public showReport: boolean = false;
  public noDataFound: boolean = false;

  public facility: any;

  constructor() { }

  ngOnInit(): void {
    Promise.all([
      this.getLoockup("dbo", "facility"),
    ])
      .then((result: any) => {
        //facility
        this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
        result[0].splice(0, 0, { ID: '0', NAME: 'ALL' });
          this.facilityObj.alldata = result[0];
        this.facilityObj.filterData = result[0];
        this.facilityObj.selectedObj = {}
        this.facilityObj.objectData = this.facilityObj.alldata.reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
      })
      .catch((err) => {
        console.log(err)
      })
  }

  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData)
      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Work Order"
      })
      this.parentId = ModFacArr[0].PARENTID
      this.moduleId = ModFacArr[0].ID;
      // console.log(ModFacArr)
    });
  }



  public column = [
    { field: "STOCKTRADE", title: "Stock Trade", width: 200 },
    { field: "STOCKTYPE", title: "StockType", width: 200 },
    { field: "STOCKCATEGORY", title: "StockCategory", width: 200 },
    { field: "PARTNO", title: "Part No", width: 200 },
    { field: "PARTDESCRIPTION", title: "Part Description", width: 200 },
    { field: "UOM", title: "UoM", width: 200 },
    { field: "FACILITY", title: "Facility", width: 200 },
    { field: "FACILITYCODE", title: "Facility Code", width: 200 },
    { field: "PARTSOURCE", title: "Part Source", width: 200 },
    { field: "BINNO", title: "Bin No", width: 200 },
    { field: "MINUNIT", title: "Min Unit", width: 200 },
    { field: "MAXUNIT", title: "Max Unit", width: 200 },
    { field: "MINPRICEPERUNIT", title: "MinPricePerUnit", width: 200 },
    { field: "MAXPRICEPERUNIT", title: "MaxPricePerUnit", width: 200 },
    { field: "STATUS", title: "Status", width: 200 },
    { field: "QOH", title: "QOH", width: 200 },
    { field: "LIFESPANOPTION", title: "LifeSpan Option", width: 200 },

  ];
  download() {
    let fileName = 'Work-Orders-Report-for-IBCC.csv';
    let columnNames = [
      "Stock Trade",
      "Stock Type",
      "Stock Category",
      "Part No",
      "Part Description",
      "UoM",
      "Facility",
      "Facility Code",
      "Part Source",
      "Bin No",
      "Min Unit",
      "Max Unit",
      "MinPricePerUnit",
      "MaxPricePerUnit",
      "Status",
      "QOH",
      "LifeSpan Option",

    ];
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';
    this.report.map(c => {
      csv += [
        c["STOCKTRADE"],
        c["STOCKTYPE"],
        c["STOCKCATEGORY"],
        c["PARTNO"],
        c["PARTDESCRIPTION"],
        c["UOM"],
        c["FACILITY"],
        c["FACILITYCODE"],
        c["PARTSOURCE"],
        c["BINNO"],
        c["MINUNIT"],
        c["MAXUNIT"],
        c["MINPRICEPERUNIT"],
        c["MAXPRICEPERUNIT"],
        c["STATUS"],
        c["QOH"],
        c["LIFESPANOPTION"]

      ].join(',');
      csv += '\r\n';
    })
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  fetchReport() {
    let path = "";

    const queryString = `?facilityId=${this.facility?.ID || 0}`

    path = `/api/CustomReport/GetWorkOrderSpareParts${queryString}`;

    return new Promise((resolve, reject) => {
      HttpClient.get(path, true, true)
        .then((result: any) => {
          resolve(true);
          this.report = JSON.parse(result);
          console.log(result);
          this.showReport = this.report.length > 0 && this.report[0].FACILITY != null;
          this.noDataFound = !this.showReport;
        })
        .catch((err) => {

          reject(err)
        })
    })
  }

}
