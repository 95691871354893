import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { sampleProducts } from 'src/app/Data/table';
import {eventListner,EVENT_NAME} from "src/event"

@Component({
  selector: 'app-work-order-attactments',
  templateUrl: './work-order-attactments.component.html',
  styleUrls: ['./work-order-attactments.component.scss']
})
export class WorkOrderAttactmentsComponent implements OnInit {


  public opened=false;
  public type="";
  public actionsLayout = "normal";
  public dailogTitle="";
  public validate = true;
  // public leavegridData: any[] = leave_list;
  // public summarygridData: any[] = summary_list;
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public column2=[
    {field:"col_1",title:"File Type",isFilter:true},
    {field:"col_2",title:"File Name", isFilter:true},
    // {field:"col_3",title:"Attachment", isFilter:true}
  ];
  public gridData=[
    {
      col_1:"",
      col_2:"",
      // col_3:"",
    }
  ];
// public gridData=sampleProducts;

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  
  constructor(private formBuilder: FormBuilder) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
   }
   editColumn(data){}

  ngOnInit() {
    this.maintenence_status = this.formBuilder.group({
      // facility: ['', Validators.required],

    }, {
        // validator: MustMatch('password', 'confirmPassword')
    });
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.maintenence_status.invalid) {
          return;
      }

      // display form values on success
      alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.maintenence_status.value, null, 4));
  }

  onReset() {
      this.submitted = false;
      this.maintenence_status.reset();
  }
  onDialogClose(){
    this.submitted = false;
    this.maintenence_status.reset();
    this.opened=false;
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
       this.dailogTitle="Add Attachments"
    }
    if(type=="update"){
      this.dailogTitle="Edit Attachments"
    }
    this.opened=true;
  }



}
