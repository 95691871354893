import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { sampleProducts } from 'src/app/Data/table';
import { SparePartUpdate } from 'src/app/models/spare.model';
import {eventListner,EVENT_NAME} from "src/event"
import HttpClient from 'src/Util/ApiHandling';
import { userid } from 'src/Util/const';
import { getCookie, shiftLastDataInto1st } from 'src/Util/Util';
import { SparePartUpdateValidation } from 'src/validator/spare';
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule";
import { addBlukSparePartUpdate } from "src/ApiModel/ImportApi/sparePart"

@Component({
  selector: 'app-spare-part-update',
  templateUrl: './spare-part-update.component.html',
  styleUrls: ['./spare-part-update.component.scss']
})
export class SparePartUpdateComponent implements OnInit {

  public opened=false;
  public disposalDisable = false
  public supplierDisable = false
  public removeDialogOpen = false;
  public importopened=false;
  public permission:Permission=new Permission();
  public type="";
  public spareCategoryId;
  public actionsLayout = "normal";
  public dailogTitle="";
  public updatedData
  public dateErr = false;
  public stockDropDown =[]
  public locationName;
  public facilityDropDown= []
  public sparePartListData  =[]
  public partNoDropDown =[]
  public contractorVendDropDown =[]
  public urlimg
  public contractObj={}
  public locErr = false;
  public partDescription;
  public facilityCode
  public validate = true;
  public facilityName;
  public disposal;
  public partNoObj;
  public locObj;
  public locValue;
  public gridData =[]
  public stockNoObj ={}
  public stockCateObj ={}
  public checkQOH
  public LocDropDown =[]
  public stockCategoryDropDow =[]
  public expdate: Date = new Date(2021, 5, 1, 22);
  public format = "dd/MM/yyyy HH:mm";
  public sparePartUpdateForm :SparePartUpdate = new SparePartUpdate();
error = SparePartUpdateValidation(this.sparePartUpdateForm,"init");
 public sparePartData = []
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  public activity=[
    {id:true,name:"yes"},
    {id:false,name:"no"}
  ]
  public column2=[
    {field:"SPAREPARTUPDATEID",title:"Stock Update No", isFilter:true,width:150},
    {field:"stockNoValue",title:"Stock No.",isFilter:true,width:150},
    {field:"PARTNO",title:"Part No.",isFilter:true,width:150},
    {field:"partDesc1",title:"Part Description",isFilter:true,width:150},
    {field:"LOCATIONVALUE",title:"Location Name",isFilter:true,width:150},
    {field:"STOCKCATEGORY",title:"Spare Part Category",isFilter:true,width:150},
    {field:"MINUNIT",title:"Min Unit", isFilter:true,width:150},
    {field:"MAXUNIT",title:"Max Unit ", isFilter:true,width:150},
    {field:"ESTIMATEDLIFESPAN",title:"Estimated Lifespan", isFilter:true,width:150},
    {field:"expiryDate",title:"Expiry Date", isFilter:true,width:150},
    {field:"QUANTITY",title:"Quantity", isFilter:true,width:150},
    {field:"ERPPURCHASECOST",title:"ERP Purchase Cost / Pcs (RM)", isFilter:true,width:150},
    {field:"COSTPERPCS",title:"Cost / Pcs (RM)", isFilter:true,width:150},
    {field:"INVOICENO",title:"Invoice No.", isFilter:true,width:150},
    {field:"SUPPLIERNAME",title:"Supplier Name", isFilter:true,width:200},
    {field:"SUPPLIERRETURN",title:"Supplier Return", isFilter:true,width:200},
    {field:"DISPOSALVALUE",title:"Disposal Activity", isFilter:true,width:200},
    {field:"BINNO",title:"Bin No.", isFilter:true,width:150},
    {field:"REMARKS",title:"Remarks", isFilter:true,width:150}
  ];
 

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  cont_vendor_r = ['Select'];
  
  constructor(private formBuilder: FormBuilder,private _notif:NotificationService) {
    eventListner(EVENT_NAME.edit_column,(e)=>{this.editColumn(e)})
    eventListner(EVENT_NAME.remove_column,(e)=>{this.editColumn(e)});
   }
   editColumn(data) {
    this.sparePartUpdateForm.ID=data.dataItem.ID
    this.getSpareUpdateById("mm","sparePartUpdate", this.sparePartUpdateForm.ID)
    if (data.action == "edit"){
     this.openRoleDailog("update")
     
    
  }
  else if (data.action == "remove") {
   
    this.removeDialogOpen = true;
  }
  }
   public contectColumn=[
    { 
      field: "PartNo", 
      title: "Part No.",
      type :"dropDown",
      isFilter: false, 
      width: 140 
  },
    { 
        field: "PartDescription", 
        title: "Part Description", 
        isFilter: false, 
        width: 140,
        
      },
    { 
      field: "SparePartCategory", 
      title: "Spare Part Category", 
      isFilter: false, 
      width: 140,
     
    },
    { 
      field: "Location", 
      title: "Location", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "MinUnit", 
      title: "Min Unit", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "MaxUnit", 
      title: "Max Unit", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "EstimatedLifespan", 
      title: "Estimated Lifespan", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "ExpiryDate", 
      title: "Expiry Date", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "Quantity", 
      title: "Quantity", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "ERP", 
      title: "ERP Purchase Cost / Pcs (RM)", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "Cost", 
      title: "Cost / Pcs (RM)", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "InvoiceNo", 
      title: "Invoice No.", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "SupplierName", 
      title: "Supplier Name", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "BinNo", 
      title: "Bin No.", 
      isFilter: false, 
      width: 140,
    },
    { 
      field: "Remarks", 
      title: "Remarks", 
      isFilter: false, 
      width: 140,
    },
  ]

  ngOnInit() {
    if(UserModuleObj.spare_parts){
      this.permission=UserModuleObj.spare_parts;
    }
    
   Promise.all([
     this.getfacility("dbo","facility"),
    this.getSpareList(),
    this.getBpm(),
    this.getStockCategory("mm","stockCategory"),
    this.getLocationData("dbo", "arealocation"),
   ]).then((e)=>{
        this.getspareUpdateDetails("mm","sparePartUpdate")
   })
}

  // convenience getter for easy access to form fields
  get f() { return this.maintenence_status.controls; }

  onSubmit() {
  }

  onReset() {
      this.openRoleDailog("new")
  }
  onDialogClose(type){
    if(type=="new"){
      this.opened=false;
    }
  if(type=="update"){
    this.removeDialogOpen=false
  }
  if(type=="importUpdate"){
    this.importopened = false;
  }
   
  }
  openRoleDailog(type){
    this.type=type;
    if(type=="new"){
      this.sparePartUpdateForm = new SparePartUpdate();
      this.partDescription =""
      this.facilityCode = ""
      this.facilityName =0
      this.locValue = null
      this.updatedData =null
      this.spareCategoryId =0
      this.locErr = false;
      this.dateErr = false
      this.error = SparePartUpdateValidation(this.sparePartUpdateForm,"init")

       this.dailogTitle="Update Spare Part"
    }
    if(type=="update"){
      this.dailogTitle="Edit Spare Part"
    }
    this.opened=true;
  }

  submitSparePartUpdateFrom(){
  
    if(this.locValue===undefined || this.locValue===null){
          this.locErr=true
    }
    else{
      this.locErr=false
    }
     if(this.updatedData===undefined || this.updatedData===null){
         this.dateErr = true;
     }
     else{
      this.dateErr = false;
     }
    this.error = SparePartUpdateValidation(this.sparePartUpdateForm,"");
    if( !this.dateErr && !this.locErr &&  !this.error.BINNO && !this.error.EXPIRYDATE && !this.error.MINUNIT  && !this.error.QUANTITY  && !this.error.SPAREPARTID ){
    this.sparePartUpdateForm.SPAREPARTUPDATEID=0
     this.sparePartUpdateForm.CREATEDBY=parseInt(getCookie(userid))
    // if(! this.leaveerror.USERID){
      if (this.type == "new") {
          this.sparePartUpdateForm.CREATEDDATE = new Date();
          this.sparePartUpdateForm.ID=0
      }
      this.saveSparePartUpdate(JSON.stringify(this.sparePartUpdateForm))
    }
    
  }
saveSparePartUpdate(data){
    let obj={
      "schemaName":"dbo",
      "tableName":"sparePartUpdate",
      "userId":getCookie(userid),
      "newData":data
    }
     let  path="";
    if( this.type==="new"){
       path=`/api/MasterData/SaveLookupData`
    }
    else if(this.type=="update"){
      path="/api/MasterData/UpdateLookupData"
    }
    HttpClient.fetchData(path,"post",obj,true,true)
    .then((result:any)=>{
      if (result.isValid == true) {
        this._notif.show({
          content: result.messageKey,
          type: { style: "success", icon: true },
        });
        this.getspareUpdateDetails("mm","sparePartUpdate")
       this.opened=false;
      }
    })
    .catch((err)=>{
    })
  }

  // bindPartNoObj(result){
  //   this.partNoObj = result.reduce((acc, d) => {
  //     acc[d.id] = d.partNo;
  //     return acc;
  //   }, {});
  // }

  bindLocaObj(result){
    this.locObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindBpmObj(result){
    this.contractObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
   return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }

  bindstockNobj(result){
    this.stockNoObj = result.reduce((acc, d) => {
      acc[d.id] = d.stockNo;
      return acc;
    }, {});
  }
  
  bindstockCategbj(result){
    this.stockCateObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }
  
  getspareUpdateDetails(schema,tablename){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
        let data = shiftLastDataInto1st(JSON.parse(result.gridData))||[];
         this.bindstockNobj(this.stockDropDown); 
         this.bindBpmObj(this.contractorVendDropDown)
          // this.bindPartNoObj(this.partNoDropDown)
          this.bindLocaObj(this.LocDropDown)
          this.bindstockCategbj(this. stockCategoryDropDow)
          this.gridData = data.map((e,index)=>{
            e["SONO"] = index+1
            if(e.EXPIRYDATE!=null){
              e["expiryDate"] = this.dateFormating(e.EXPIRYDATE);
            }
             e["SUPPLIERNAME"] = this.contractObj[e.BPMID]
            e["stockNoValue"] = this.stockNoObj[e.SPAREPARTID]
         
           let   value = this.partNoDropDown.filter((d)=>{
                return d.partNo == e.PARTNO
              })
                 if(value.length>0){
              e["partDesc1"] = value[0].desc
                 }
                 else{
                  e["partDesc1"] =""
                 }
            if(e.SPAREPARTID){
             this.stockNoChanged(e.SPAREPARTID);
             e["STOCKCATEGORY"] = this.stockCateObj[this.spareCategoryId]
             } 
             e["LOCATIONVALUE"] =this.locObj[this.locValue]
            if(e.DISPOSAL===false){
              e["DISPOSALVALUE"] = "NO"
            }
            else{
              e["DISPOSALVALUE"] = "YES"
            }

            if(e.SUPPLIERRETURN===true){
              e["SUPPLIERRETURN"] = "YES"
            }
            else{
              e["SUPPLIERRETURN"] = "NO"
            }
            // e["LOCATION"] = this.locObj[e.LOCATIONID];
            // let value =   this.sparePartListData.filter((e)=>{
            //   if( e.sparePartDetails.length>0){
            //     return e.sparePartDetails[0].id===e.PARTNO;
            //   }
              
            //   })
            //   e["partDesc"] = value[0].sparePartDetails[0].partDescription
            return e;
          })

            
          // let leaveDetails =[]
          // this.bindUserObj(this.staffNameDropDown);
          // leaveDetails = JSON.parse(result.gridData);
          // this.gridDataleave = leaveDetails.map((d)=>{
          //      d["staffName"] = this.userObj[d.USERID]
          //      d["leave_form"] = this.dateFormating(d.LEAVEFROM)
          //      d["leave_to"] = this.dateFormating(d.LEAVETO)
          //      d["days"]= this.getNumberOfDays(d.LEAVEFROM,d.LEAVETO)
          //      d["hours"] = this.diff_hours(d.LEAVEFROM,d.LEAVETO)
          //      return d;
          // })
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getsparePartUpdateId(schema,tablename,id){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
        true,
        true
      ).then((result: any) => {
          // let shiftValue =  JSON.parse(result.gridData);
          // let timefromhour = shiftValue[0].TIMEFROM.slice(0,2)
          // let  timefrommin =  shiftValue[0].TIMEFROM.slice(3,5)
          // let  timefromsec =  shiftValue[0].TIMEFROM.slice(7,9)
          // let timetohour = shiftValue[0].TIMETO.slice(0,2)
          // let  timetomin =  shiftValue[0].TIMETO.slice(3,5)
          // let  timetosec =  shiftValue[0].TIMETO.slice(7,9)
          // this.userShiftForm.DATETIMEFROM = new Date(2020,11,1,timefromhour,timefrommin,timefromsec)
          // this.userShiftForm.DATETIMETO = new Date(2020,11,1,timetohour,timetomin,timetosec )
          // this.userShiftForm.BREAKHOURS = shiftValue[0].BREAKTIMEINMINUTES
          // this.userShiftForm.INSTANCENAME = shiftValue[0].INSTANCENAME
          // this.userShiftForm.CARDNUMBER = shiftValue[0].CARDNUMBER
          // this.userShiftForm.ISALLOWREPLACEMENT = shiftValue[0]. ISALLOWREPLACEMENT
          resolve(result)
        })
        .catch((err) => {
          reject(err);
        });
    }); 
  }


  getSpareList(){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        "/api/Assets/GetSpareList",
        true,
        true
      )
        .then((result: any) => {
        this.sparePartListData = result;
        this.stockDropDown  =result;
        let partilteredData =[]
          partilteredData=result.map((e)=>{
          if(e.sparePartDetails.length>0 ){
            return {id:e.sparePartDetails[0].id,partNo:e.sparePartDetails[0].partNo,desc:e.sparePartDetails[0].partDescription}
          }
        })

        this.partNoDropDown =  partilteredData.filter((e)=>{
          if(e!=undefined){
             return e;
           }
        })
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getfacility(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData);
          this.facilityDropDown = facilityData;
          resolve(facilityData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getStockCategory(schema,tablename){
  return new Promise((resolve,reject)=>{
     HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
       true,
       true
     ).then((result: any) => {
      this. stockCategoryDropDow = JSON.parse(result.gridData)

       resolve(result);
       
     }).catch((e)=>{
       reject(e)
     })
    })
   
  }


 getLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      
       this.LocDropDown =  JSON.parse(result.gridData)
    });
  }

  stockNoChanged(data){
      
      let facilityValue
      let value= this.stockDropDown.filter((e)=>{
          return e.id===data
        });
          
        if(value[0].sparePartDetails.length>0){
              this.facilityName = value[0].sparePartDetails[0].facilityId
            }
            if(value[0].sparePartDetails[0] && value[0].sparePartDetails[0].facilityId>0){
              facilityValue =   this.facilityDropDown.filter((e=>{
                return e.ID == value[0].sparePartDetails[0].facilityId
              }));
            }
         this.facilityCode = facilityValue && facilityValue[0].FACILITYCODE   
        let SparePartvalue= this.sparePartListData.filter((e)=>{
          return e.id===data;
        }) 
        let partValue;
              if( SparePartvalue[0].sparePartDetails.length>0){
                partValue  = this.partNoDropDown.filter((e)=>{
                  return e.id==SparePartvalue[0].sparePartDetails[0].id
            })
              }

              
          this.sparePartUpdateForm.PARTNO = partValue && partValue[0].partNo
          this.partDescription = partValue && partValue[0].desc
          this.spareCategoryId=SparePartvalue[0].stockCategoryId
          this.sparePartUpdateForm.MINUNIT = SparePartvalue[0].sparePartDetails[0] && SparePartvalue[0].sparePartDetails[0].minUnit
          this.sparePartUpdateForm.MAXUNIT = SparePartvalue[0].sparePartDetails[0] && SparePartvalue[0].sparePartDetails[0].maxUnit
          this.locValue=  SparePartvalue[0].sparePartDetails[0]&& SparePartvalue[0].sparePartDetails[0].locationId
          this.checkQOH =  SparePartvalue[0].sparePartDetails[0]&& SparePartvalue[0].sparePartDetails[0].qoh
          if(this.checkQOH==0){
            this.disposalDisable = true;
            this.supplierDisable = true
            this.sparePartUpdateForm.DISPOSAL = null
            this.sparePartUpdateForm.SUPPLIERRETURN = null
          }
    
          else{
            this.disposalDisable = false;
            this.supplierDisable = false
          }
        //   let locationId;
        //   if( SparePartvalue[0].sparePartDetails[0].locationId!=null){
        //     locationId = this.LocDropDown.filter((l)=>{
        //       return l.ID == SparePartvalue[0].sparePartDetails[0].locationId
        // })
        //   }
        //  this.locationName = locationId &&locationId[0].NAME

        //   console.log(" locationId", locationId )

  }
  
  changedPartNo(data){
     let value  = this.partNoDropDown.filter((e)=>{
       e.partNo === data
     })
   if(value.length>0){
       this.partDescription = value[0].desc
     }
  //  let value=   this.sparePartListData.filter((e)=>{
  //     if( e.sparePartDetails.length>0){
  //       return e.sparePartDetails[0].id==data;
  //     }
  //     })
      
    }

  getBpm() {
    HttpClient.get("/api/BPM/GetBpms", true, true)
      .then((result: any) => {
          this.contractorVendDropDown = result
      })
  }
  getSpareUpdateById(schema,tablename,id){
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}&&recId=${id}`,
        true,
        true
      )
        .then((result: any) => {
        let data=  JSON.parse(result.gridData)
         this.sparePartUpdateForm = data[0]
         this.sparePartUpdateForm.EXPIRYDATE = new Date(data[0].EXPIRYDATE)
          this.updatedData = new Date( data[0].CREATEDDATE)
         this.stockNoChanged(this.sparePartUpdateForm.SPAREPARTID)
        resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeSpareUpdate(){
   
      let obj={
        "schemaName":"mm",
        "tableName":"sparePartUpdate",
        "userId":getCookie(userid),
        "oldData":JSON.stringify(this.sparePartUpdateForm),    
        "newData":null//JSON.stringify(this.deleteItem)
      }
       let  path=`/api/MasterData/DeleteLookupData`
      HttpClient.fetchData(path,"post",obj,true,true)
      .then((result:any)=>{
        if(result.messageKey){
          this._notif.show({
            content:result.messageKey,
            type: { style: "success", icon: true },
          });
        }
        else if(result.message){
          this._notif.show({
            content:result.message,
            type: { style: "error", icon: true },
          });
        }
        this.getspareUpdateDetails("mm","sparePartUpdate")
         this.removeDialogOpen=false;
      })
      .catch((err)=>{
      })
    }

    
      download() {
        let fileName = 'SparePrtUpdate.csv';

        let columnNames = [
        "SONO",
        "ID", 
        "BINNO",
        "BPMID",
        "STOCKCATEGORY",
        "COSTPERPCS",
        "DISPOSALVALUE",
        "ERPPURCHASECOST",
        "ESTIMATEDLIFESPAN",
        "EXPIRYDATE",
        "INVOICENO",
        "LOCATIONVALUE",
        "MAXUNIT",
        "MINUNIT", 
        "PARTNO", 
        "PARTNOValue", 
        "QUANTITY",
        "REMARKS",
        "SPAREPARTID",
        "SUPPLIERNAME",
        "SPAREPARTUPDATEID",
        "SUPPLIERRETURN", 
        "expiryDate",
        "CREATEDBY",
        "CREATEDDATE",
        "MODIFIEDBY", 
        "MODIFIEDDATE", 
        "NAMECREATEDBY", 
        "NAMEMODIFIEDBY",
      ];
        let header = columnNames.join(',');
        let csv = header;
        csv += '\r\n';
    
        this.gridData.map(c => {
          csv += [
          c["SoNo"],
          c["ID"], 
          c["BINNO"],
          c["BPMID"],
          c["STOCKCATEGORY"],
          c["COSTPERPCS"],
          c["DISPOSALVALUE"],
          c["ERPPURCHASECOST"],
          c["ESTIMATEDLIFESPAN"],
          c["EXPIRYDATE"],
          c["INVOICENO"],
          c["LOCATIONVALUE"],
          c["MAXUNIT"],
          c["MINUNIT"], 
          c["PARTNO"], 
          c["PARTNOValue"], 
          c["QUANTITY"],
          c["REMARKS"],
          c["SPAREPARTID"],
          c["SUPPLIERNAME"],
          c["SPAREPARTUPDATEID"],
          c["SUPPLIERRETURN"], 
          c["expiryDate"],
          c["CREATEDBY"],
          c["CREATEDDATE"],
          c["MODIFIEDBY"], 
          c["MODIFIEDDATE"], 
          c["NAMECREATEDBY"], 
          c["NAMEMODIFIEDBY"],].join(',');
          csv += '\r\n';
        })
        var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    

    importData(){
        this.importopened =true;
    }

    locValueChange(data){
      if(!this.locValue){
        this.locErr = true
      }
   }
   valueChange(event){
    this.type="new"
    let obj = addBlukSparePartUpdate(event,this.stockDropDown,this.contractorVendDropDown)
    obj.forEach((data)=>{
    this.saveSparePartUpdate(JSON.stringify(data))
    })
  }
  
  fileUpload(){
    this.getspareUpdateDetails("mm","sparePartUpdate");
    this.importopened= false
  }

  downloadTemplate(){
    window.open("/assets/template/sparepart_update.xlsx")
  }
  importDataChange(event){
    this.type="new";
    let obj = addBlukSparePartUpdate(event,this.stockDropDown,this.contractorVendDropDown)
    Promise.all(obj.map((data)=>this.saveSparePartUpdate(JSON.stringify(data))))
    .then((result)=>{
      this.importopened=false;
    })
    .catch((err)=>{
      
    })
  }
  
}

