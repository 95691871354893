
<div class="card m-3"  >
    <div class="card-header" >
        <h5> Spare Part Request</h5>
        <div>      
          <!-- <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Request
          </button> -->
        </div>
    </div>
    <div class="card-body register-form-body"  >
       
        <app-table-grid
        [data]="areagrid"
        [column]="column2"
        [size]="10"
        [approved] ="true"
        [reject] ="true"
        [editFlag] ="false"
        [viewFlag] ="true"
        [deleteFlag] ="false"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Material Request No. </label>
                <input kendoTextBox class="form-control" [(ngModel)]="spareRequestForm.MATERIALREQUESTNO" disabled/>
            </div>
            <div class="form-group col-5">
                <label>Work Order No. <span class="required">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist [data]="workOrderData" [textField]="'workOrderNo'"  [valueField]="'id'"
                [valuePrimitive]="true" class="form-control" [(ngModel)]="spareRequestForm.TICKETID"  (valueChange)="WorkOrdervaluechange($event)">
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.TICKETID">*Required</kendo-formerror>
            </div>
        </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Request Date <span class="required">*</span></label> 
                <div style="width:100%">
                <kendo-datepicker [format]="format" [(ngModel)]="spareRequestForm. REQUESTDATE" >
                </kendo-datepicker>
                <kendo-formerror *ngIf="error.REQUESTDATE">*Required</kendo-formerror>
            </div>
            </div>
            <div class="form-group col-5">
                <label>Status <span class="required">*</span> </label>
                <div style="width:100%">
               
            <kendo-dropdownlist  
            [data]="statusDropDown"   [(ngModel)]="spareRequestForm.REQUESTSTAUSID"   class="form-control"
            [textField]="'NAME'"
            [valueField]="'ID'"
            [valuePrimitive]="true"
              >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.REQUESTSTAUSID">*Required</kendo-formerror>
        </div>
            </div>
        </div>
        <!-- <div class="form-row">
            <div class="form-group col-5">
                <label>Approved By</label>
                <input kendoTextBox class="form-control"  disabled/>
            </div>
            <div class="form-group col-5">
                <label>Approved Date</label>
                <input kendoTextBox class="form-control" disabled />
            </div>
        </div> -->
        <div class="form-row">
            <div class="form-group col-5">
                <label>Facility Name   </label>
                <input kendoTextBox class="form-control" [(ngModel)]="facName" disabled />

            </div>
            <div class="form-group col-5">
                <label>Facility Code  </label>
                <input kendoTextBox class="form-control" [(ngModel)]="facCode" disabled />
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Approved By   </label>
                <input kendoTextBox class="form-control"     [(ngModel)]="spareRequestForm.APPROVEDBY" disabled />

            </div>
            <div class="form-group col-5">
                <label>Approved Date  </label>
                <kendo-datepicker [format]="format"   [(ngModel)]="spareRequestForm. APPROVEDDATE"  disabled>
                </kendo-datepicker>
            </div>
        </div>
    </div>
          
    <div class="table-responsive" >
        <table class="spare-part-table">
          <tr>
            <th>Part No.</th>
            <th>Part Description</th>
            <th>Bin No.</th>
            <th>Quantity on hand (QOH)</th>
            <th>Facility Name</th>
            <!-- <th>Facility Code</th> -->
            <!-- <th>Physical Quantity</th>
            <th>Variance</th> -->
           <th>Remarks</th>
          </tr>
          <tr *ngFor="let item of WoData;let i=index">
            <!-- {{item.partNo}} -->

            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partNo" disabled/></td>
            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partDescription" disabled/></td>
            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.binNo " disabled/></td>
          
            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.qoh" disabled/></td>
           <td> <kendo-dropdownlist  
            [data]="facilityDropDown"  [(ngModel)]="item.facilityId" disabled   class="form-control"
            [textField]="'NAME'"
            [valueField]="'ID'"
            [valuePrimitive]="true"
              >
            </kendo-dropdownlist>
        
            </td>
          
            <!-- <td><input kendoTextBox class="form-control"  [(ngModel)]="facCode1" disabled/></td> -->
            <!-- <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partNo" disabled/></td>
        
         
            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partNo" disabled/></td>
         
        
            <td><input kendoTextBox class="form-control"  [(ngModel)]="item.partNo" disabled/></td>-->
        

            <td><input kendoTextBox class="form-control" [(ngModel)]="WoData[i].remark"/></td> 
          
          </tr>
      </table>
    </div>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('new')">Cancel</button>
      <button kendoButton primary="true" (click)="onReset()">
        Reset
       </button>
      <button kendoButton primary="true" (click)="submitData()">
       Save
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (click)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        {{ApproveTitle}}
      </div>
    </kendo-dialog-titlebar>
    <p>   {{ApproveDesc}}</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="submitData()" >{{buttonValue}}</button>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="viewDialogOpen" (click)="onDialogClose('view')" [width]="1000">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
      
      </div>
    </kendo-dialog-titlebar>
   
  <div class="card-body register-form-body">
     
      <app-table-grid
      [data]="gridReqDetData"
      [column]="returnDetailsCol"
      [size]="10"
      [action] ="false"
    ></app-table-grid>
  </div>
  </kendo-dialog>