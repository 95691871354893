import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableGridComponent } from './table-grid/table-grid.component';
import { GridModule,ExcelModule  } from "@progress/kendo-angular-grid";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PermissionGridComponent } from './permission-grid/permission-grid.component';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { BarcodesModule } from "@progress/kendo-angular-barcodes";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolesPermissionTableGridComponent } from './roles-permission-table-grid/roles-permission-table-grid.component';
import { ContractorVendorGridComponent } from './contractor-vendor-grid/contractor-vendor-grid/contractor-vendor-grid.component';
import { AttachmentTableGridComponent } from './attachment-table-grid/attachment-table-grid-component';
import { CalenderGridComponent } from './calender-grid-table/calender-grid';
import { HolidayAddGridComponent } from './holiday-add-grid/holiday-add-grid.component';
import { HolidayListGridComponent } from './holiday-list-grid/holiday-list-grid.component'; 
import { HolidayViewGridComponent } from './holiday-view-grid/holiday-view-grid-component';
import { FacilityRegistrationGridComponent } from './facility-registration-grid/facility-registration-grid.component';
import { ContactPersonGridComponent } from './contact-person-grid/contact-person-grid.component';
import { UserRegistrationGridComponent } from './user-registration-grid/user-registration-grid.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MainteneceHistoryGridComponent } from './maintenece-history-grid/maintenece-history-grid.component';
import { FileModelComponent } from './file-model/file-model.component'
import {  ContratctorAssetGridComponent } from './contractorasset-grid-table/contractorasset.component'
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ImportAttechmentComponent } from './import-attechment/import-attechment.component';
import { dashboardTableGridComponent } from './dashboardWO-table-grid/dashboardWO-table-grid.component';
import { QrPrintingComponent } from './qr-printing/qr-printing.component';
import { DashboardrequestTableGridComponent } from './dashboardrequest-table-grid/dashboardrequest-table-grid.component';
import { LicenseCertificateGridComponent } from './licens-certificate-grid/license-certi.component';
import {DropDownListFilterComponent} from "./dropdownfilter/dropdownlist-filter.component"
@NgModule({
  declarations: [
    TableGridComponent,
    PermissionGridComponent,
    RolesPermissionTableGridComponent,
    ContractorVendorGridComponent,
    AttachmentTableGridComponent,
    CalenderGridComponent,
    HolidayAddGridComponent,
    HolidayListGridComponent,
    HolidayViewGridComponent,
    FacilityRegistrationGridComponent,
    ContactPersonGridComponent,
    UserRegistrationGridComponent,
    FileUploadComponent,
    MainteneceHistoryGridComponent,
    FileModelComponent,
    ContratctorAssetGridComponent,
    ImportAttechmentComponent,
    dashboardTableGridComponent,
    QrPrintingComponent,
    DashboardrequestTableGridComponent,
    LicenseCertificateGridComponent,
    DropDownListFilterComponent
  ],
  imports: [
    CommonModule,
    GridModule,
    ButtonsModule,
    FormsModule, 
    ReactiveFormsModule,
    DateInputsModule,
    DropDownsModule,
    ExcelModule,
    PDFExportModule,
    BarcodesModule
  ],
  exports: [
    TableGridComponent,
    PermissionGridComponent,
    RolesPermissionTableGridComponent,
    ContractorVendorGridComponent,
    AttachmentTableGridComponent,
    CalenderGridComponent,
    HolidayAddGridComponent,
    HolidayListGridComponent,
    HolidayViewGridComponent,
    ContactPersonGridComponent,
    UserRegistrationGridComponent,
    FileUploadComponent,
    MainteneceHistoryGridComponent,
    FileModelComponent,
    ContratctorAssetGridComponent,
    ImportAttechmentComponent,
    dashboardTableGridComponent,
    QrPrintingComponent,
    DashboardrequestTableGridComponent,
    LicenseCertificateGridComponent,
    DropDownListFilterComponent
  ]
})
export class ToolsModule { }
