import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ppm',
  templateUrl: './ppm.component.html',
  styleUrls: ['./ppm.component.scss']
})
export class PpmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public onTabSelect(e) {
    console.log(e);
  }
}
