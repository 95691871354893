import {MaintenaceChecklistTask} from "./MaintanceChecklistTask"
import {TaskAnswerList} from "./TaskAnswerList"
import {TaskAnswerListItem} from "./TaskAnswerListItem"
import {TaskItem} from "./TaskItem"

export class Question{
    taskItem:TaskItem=new TaskItem();
    answer:TaskAnswerList=new TaskAnswerList();
    answerItem:Array<TaskAnswerListItem>=new Array<TaskAnswerListItem>();
    saveData(taskId){
        return new Promise((resolve,reject)=>{
            this.taskItem.TASKID=taskId;
        if(this.taskItem.ANSWERTYPEID==5){
                    this.answer.NAME=this.taskItem.DESCRIPTION;
                    this.answer.saveData(this.answer.NAME,true)
                    .then((result:any)=>{
                        let ansId=result.id;
                        this.taskItem.TASKANSWERLISTID=ansId; 
                        this.answerItem=this.answerItem.map((d)=>{
                            d.TASKANSWERLISTID=ansId;
                            return d;
                        })
                        Promise.all(
                            this.answerItem.map((d)=>{
                                return d.saveData();
                            })
                        )
                        .then((result)=>{
                            this.taskItem.saveData()
                            .then((result1)=>{
                                resolve(result1);
                            })
                        })
                    })
       }
       else{
            this.taskItem.saveData()
            .then((result1)=>{
                resolve(result1);
            })
       }
      })
    }

    updateData(taskId){
      return new Promise((resolve,reject)=>{
        this.taskItem.TASKID=taskId;
        if(this.taskItem.ANSWERTYPEID==5){
            this.answer.NAME=this.taskItem.DESCRIPTION;
                this.answer.updateData(this.answer.ID,this.answer.NAME,true)
                .then((result:any)=>{
                    let ansId=result.id;
                    this.taskItem.TASKANSWERLISTID=ansId; 
                    this.answerItem=this.answerItem.map((d)=>{
                        d.TASKANSWERLISTID=ansId;
                        return d;
                    })
                    Promise.all(
                        this.answerItem.map((d)=>{
                            return d.saveData();
                        })
                    )
                    .then((result)=>{
                        this.taskItem.saveData()
                        .then((result1)=>{
                            resolve(result1);
                        })
                    })
                })
                .catch((err)=>{

                })
        }
        else{
            this.taskItem.saveData()
            .then((result1)=>{
                resolve(result1);
            })
       }
      })
    }
}

export class ChecklistTask{
    task:MaintenaceChecklistTask=new MaintenaceChecklistTask();
    item:Array<Question>=new Array<Question>();
    saveData(){
        return new Promise((resolve,reject)=>{
            this.task.saveData(this.task.CHECKLISTID,this.task.DESCRIPTION)
            .then((result:any)=>{
              let taskId=result.id;
              Promise.all(
                  this.item.map((d)=>{
                     return d.saveData(taskId);
                  })
              )
              .then((result)=>{
                  resolve(result);
              })
            })
        })
    }
    updateData(){
        return new Promise((resolve,reject)=>{
            this.task.updateData(this.task.ID,this.task.CHECKLISTID,this.task.DESCRIPTION)
            .then((result:any)=>{
              let taskId=this.task.ID;
              Promise.all(
                  this.item.map((d)=>{
                     return d.saveData(taskId);
                  })
              )
              .then((result)=>{
                  resolve(result);
              })
            })
        })
    } 
   
}
