import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class Month{ 
    ID:number;
    NAME:string;
}

export class MonthObj{
    alldata:Array<Month>=[]
    filterData:Array<Month>=[]
    selectedObj:Month=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:Month)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }
    getData(){
        let m=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        return new Promise((resolve,reject)=>{
              let result:Array<Month>=m.map((d,i)=>{
                  return {ID:(i+1),NAME:d};
              })
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
        })

    }
}