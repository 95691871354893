import {AssetType,AssetTypeError,AssetStandardization,AssetStandardizationError, AssetClassification, AssetClassificationError} from "src/app/models/asset.model"
export let assetTypeCodeClassificationValidation=(data:AssetType,type)=>{
  let error= new AssetTypeError(false)
     if(type==="init")
        return error;
   if(!data.code){
       error.code=true
   }
   if(!data.expectedLifeSpan){
       error.expectedLifeSpan=true;
   }
   if(!data.age5To10Yrs){
       error.age5To10Yrs=true
   }
   if(!data.ageLessThan5Yrs){
       error.ageLessThan5Yrs=true
   }
   if(!data.ageGreaterThan10Years){
       error.ageGreaterThan10Years=true
   }
   if(!data.expectedLifeSpan){
      error.expectedLifeSpan=true;
   }
   if(!data.assetTypeMaintenanceFlags || (data.assetTypeMaintenanceFlags.length<=0)){
      error.maintenanceFlagId=true;
   }
   if(!data.description){
        error.description=true;
   }
   if(!data.assetClassificationId){
      error.assetClassificationId=true
   }
   return error;
}

export let assetStandardizationValidation=(data:AssetStandardization,type)=>{
    let error= new AssetStandardizationError(false)
       if(type==="init")
          return error;
     if(!data.assetManufacturerId){
         error.assetManufacturerId=true
     }
     if(!data.assetModelId){
         error.assetModelId=true;
     }
     if(!data.assetTypeId){
         error.assetTypeId=true
     }
     if(!data.assetStatusId){
         error.assetStatusId=true
     }
  return error;
  }


  export let assetClassificationValidation = (data:AssetClassification,type)=>{
    let error= new AssetClassificationError(false)
    if(type==="init")
       return error;
    if(!data.description){
      error.description=true
  }
  return error;
  }