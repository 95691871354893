

<div class="card m-3"   >
    <div class="card-header" >
        <h5>Check List</h5>
        <div>
            <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
                Download Template 
            </button>
            <button kendoButton  primary="true" *ngIf="permission && permission.isImport" (click)=" import()" class="header-right-btn">
                Import
                   </button> &nbsp;  &nbsp;    
          <button kendoButton  primary="true"  *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Checklist
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"   >
        <app-table-grid 
        [data]="areagrid"
        [column]="column2"
        [size]="10"
        [action]="permission && permission.isUpdate"
        [addAsset] ="true"
      ></app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           {{dailogTitle}}
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Checklist ID</label>
                              <input kendoTextBox [(ngModel)]="checkListNo" disabled class="form-control"/>
                          </div>
                          <div class="form-group col-5">
                            <label>Contractor/Vendor Name

                            </label>
                            <kendo-dropdownlist  
                                [data]="assignToObj.filterData" 
                                [value]="assignToObj.selectedObj" 
                                (valueChange)="valuechange('assignTo',$event)" 
                                class="form-control"
                                [textField]="'name'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                          <!-- <div class="form-group col-5">
                              <label>Facility<span class="required">*</span></label>
                              <kendo-dropdownlist  
                                  [data]="facilityObj.filterData" 
                                  [value]="facilityObj.selectedObj" 
                                  (valueChange)="valuechange('facility',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                          </div> -->
                      </div>
                      <!-- <div class="form-row">
                          <div class="form-group col-5">
                              <label>Block Code<span class="required">*</span></label> 
                              <kendo-dropdownlist  
                                  [data]="blockObj.filterData" 
                                  [value]="blockObj.selectedObj" 
                                  (valueChange)="valuechange('block',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                          </div>
                          <div class="form-group col-5">
                              <label>Block Name</label>
                              <input kendoTextBox class="form-control" [value]="blockObj.selectedObj && blockObj.selectedObj.NAME" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Level Code<span class="required">*</span></label>
                              <kendo-dropdownlist  
                              [data]="levelObj.filterData" 
                              [value]="levelObj.selectedObj" 
                          (valueChange)="valuechange('level',$event)" 
                          class="form-control"
                          [textField]="'CODE'" 
                          [valueField]="'ID'" 
                      >
                      </kendo-dropdownlist>
                          </div>
                          <div class="form-group col-5">
                              <label>Level Name</label>
                              <input kendoTextBox class="form-control" [value]="levelObj.selectedObj && levelObj.selectedObj.NAME" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Area Code<span class="required">*</span></label>
                              <kendo-dropdownlist  
                                  [data]="areaObj.filterData" 
                                  [value]="areaObj.selectedObj" 
                                  (valueChange)="valuechange('area',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                          </div>
                          <div class="form-group col-5">
                              <label>Area Name</label>
                              <input kendoTextBox [value]="areaObj.selectedObj && areaObj.selectedObj.NAME" class="form-control" disabled/>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Location Code<span class="required">*</span></label>
                              <kendo-dropdownlist  
                                  [data]="locationObj.filterData" 
                                  [value]="locationObj.selectedObj" 
                                  (valueChange)="valuechange('location',$event)" 
                                  class="form-control"
                                  [textField]="'CODE'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                          </div>
                          <div class="form-group col-5">
                              <label>Location Name</label>
                              <input kendoTextBox [value]="locationObj.selectedObj && locationObj.selectedObj.NAME" class="form-control" disabled/>
                          </div>
                      </div> -->
  
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label> Checklist Name<span class="required">*</span></label>
                              <div style="width: 100%;">
                              <input kendoTextBox [(ngModel)]="data.description" class="form-control" />
                              <kendo-formerror *ngIf="error.description">*Required</kendo-formerror>
                              </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Service Type<span class="required">*</span></label>
                              <div style="width: 100%;">
                              <kendo-dropdownlist  
                                  [data]="serviceObj.alldata" 
                                  [value]="serviceObj.selectedObj" 
                                  (valueChange)="valuechange('service',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.serviceTypeId">*Required</kendo-formerror>
                          </div>
                      </div>
                      </div>
                      <div class="form-row">
                          <div class="form-group col-5">
                              <label>Work Order Type<span class="required">*</span></label>
                              <div style="width:100%">
                              <kendo-dropdownlist  
                                  [data]="workOrderTypeObj.filterData" 
                                  [value]="workOrderTypeObj.selectedObj" 
                                  (valueChange)="valuechange('workOrderType',$event)" 
                                  class="form-control"
                                  [textField]="'name'" 
                                  [valueField]="'id'" 
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.workordertypeid">*Required</kendo-formerror>
                            </div>
                          </div>
                          <div class="form-group col-5">
                              <label>Consequences Category<span class="required">*</span></label>
                              <div style="width:100%">
                              <kendo-dropdownlist  
                                  [data]="consequanceObj.filterData" 
                                  [value]="consequanceObj.selectedObj" 
                                  (valueChange)="valuechange('consequences',$event)" 
                                  class="form-control"
                                  [textField]="'NAME'" 
                                  [valueField]="'ID'" 
                              >
                              </kendo-dropdownlist>
                              <kendo-formerror *ngIf="error.ticketConsequenceTypeId">*Required</kendo-formerror>
                          </div>
                        </div>  
                      </div>
                      <div class="py-3" style="text-align: center;width: 100%;">
                          <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button kendoButton   primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                      </div>
                  
                    </div>
</kendo-dialog>


<kendo-dialog *ngIf="slaModel" (close)="slaModel=false" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
          Checklist SLA Mapping
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                          <label>Checklist Name<span class="required">*</span></label>
                          <kendo-dropdownlist  
                                [data]="model.checklist.filterData" 
                                [value]="model.checklist.selectedObj" 
                                (valueChange)="valuechange('checklist',$event)" 
                                class="form-control"
                                [textField]="'description'" 
                                [valueField]="'id'"
                                [disabled]="true" 
                          >
                         </kendo-dropdownlist>
                        </div>

                        <div class="form-group col-5">
                            <label>Checklist Id<span class="required">*</span></label>
                            <input kendoTextBox [(ngModel)]="model.checklist.selectedObj && model.checklist.selectedObj.checkListNo" disabled class="form-control"/>
                          </div>
                     </div>
                      <div class="form-row">
                          <div class="form-group col-10">
                            <label>Sub Service<span class="required">*</span></label>
                            <div style="width: 100%;">
                                <kendo-dropdownlist  
                                [data]="model.subService.filterData" 
                                [value]="model.subService.selectedObj" 
                                (valueChange)="valuechange('subService',$event)" 
                                class="form-control"
                                [textField]="'DESCRIPTION'" 
                                [valueField]="'ID'" 
                            >
                            </kendo-dropdownlist>
                                <kendo-formerror *ngIf="slaError.subservice">*Required</kendo-formerror>
                            </div>

                          </div>
                       </div>
                       <div class="form-row">
                        <div class="form-group col-10">
                          <label>Sub Service 2</label>
                          <kendo-dropdownlist  
                              [data]="model.subService2.filterData" 
                              [value]="model.subService2.selectedObj" 
                              (valueChange)="valuechange('subService2',$event)" 
                              class="form-control"
                              [textField]="'DESCRIPTION'" 
                              [valueField]="'ID'" 
                          >
                          </kendo-dropdownlist>
                        </div>
                     </div>
                     <div class="form-row">
                        <div class="form-group col-10">
                          <label>Sub Service 3</label>
                          <kendo-dropdownlist  
                              [data]="model.subService3.filterData" 
                              [value]="model.subService3.selectedObj" 
                              (valueChange)="valuechange('subService3',$event)" 
                              class="form-control"
                              [textField]="'DESCRIPTION'" 
                              [valueField]="'ID'" 
                          >
                          </kendo-dropdownlist>
                        </div>
                     </div>
                      <div class="form-row">
                        <div class="form-group col-5">
                            <label>Respond SLA During Working Hour</label>
                            <input kendoTextBox type="number" [value]="model.subService.selectedObj && model.subService.selectedObj.RESPONDSLADURINGWORKINGHOURS" disabled class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Respond SLA 
                                After Working 
                                Hour</label>
                                <input kendoTextBox type="number" [value]="model.subService.selectedObj && model.subService.selectedObj.RESPONDSLAAFTERWORKINGHOURS" disabled class="form-control"/>
                            </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Completion SLA During Working Hour</label>
                            <input kendoTextBox type="number" [value]="model.subService.selectedObj && model.subService.selectedObj.COMPLETIONSLADURINGWORKINGHOURS" disabled class="form-control"/>
                        </div>
                        <div class="form-group col-5">
                            <label>Completion 
                                SLA After 
                                Working Hour</label>
                                <input kendoTextBox type="number" [value]="model.subService.selectedObj && model.subService.selectedObj.COMPLETIONSLAAFTERWORKINGHOURS" disabled class="form-control"/>
                            </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveSlaData()">
                            Save
                        </button>
                    </div>
                   </div>
    </kendo-dialog>
    <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
        <kendo-dialog-titlebar>
          <div style="font-size: 18px; line-height: 1.3em;">
             Import File
          </div>
        </kendo-dialog-titlebar>
    
            <div class="form-row">
                 <div class="form-group col-6">
                    <label>Attachment</label>
                    <app-file-model
                       type="doc" 
                       [formate]="['xlsx','csv']" 
                       [url]="urlimg"
                       [fileUploadType]=1
                       (valueChange)=valueChange($event)
                       style="width: 100%;"
                       
                    >
                  </app-file-model>
                </div>
     </div>
      
              
        <kendo-dialog-actions [layout]="actionsLayout">
          <button kendoButton (click)="importopened=false">Cancel</button>
        </kendo-dialog-actions>
      </kendo-dialog>

      <!-- <kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
        <kendo-dialog-titlebar>
          <div style="font-size: 18px; line-height: 1.3em;">
             {{dailogTitle}}
          </div>
        </kendo-dialog-titlebar>
    
            <div class="form-row">
                
                
                <div class="form-group col-6">
                    <label>Attachment</label>
                    <app-file-model
                    type="doc" 
                    [formate]="['xlsx','csv']" 
                    [url]="urlimg"
                    [fileUploadType]=4
                    (valueChange)=importDataChange($event)
                    style="width: 100%;"
              >
               </app-file-model>
                </div>
                 </div>
    <kendo-dialog-actions [layout]="actionsLayout">
          <button kendoButton (click)="importopened=false">Cancel</button>
          <button kendoButton primary="true" (click)="fileUpload()" >
                Save
          </button>
        </kendo-dialog-actions>
      </kendo-dialog> -->