<kendo-grid
[data]="gridData"
[pageSize]="state.take"
[skip]="state.skip"
[sort]="state.sort"
[filter]="state.filter"
[sortable]="true"
[pageable]="true"
filterable="menu"
[group]="groups"
(dataStateChange)="dataStateChange($event)"
(edit)="editHandler($event)"
(remove)="removeHandler($event)"
[selectable]="true"
(selectionChange)="onSelect($event)"
(filterChange)="handleACFilter($event)"
scrollable="none"
>
<ng-template *ngIf="exportFlag"  kendoGridToolbarTemplate style="background: #000;">
  <div style="width: 100%;display: flex;justify-content: flex-end;padding: 0rem;">
  <button  type="button" kendoGridExcelCommand icon="file-excel">
    Export to Excel
  </button>
  </div>
</ng-template>
<kendo-grid-checkbox-column title="" *ngIf="checkbox" width="50">
  <ng-template kendoGridCellTemplate let-dataItem>
    <input type="checkbox" (change)="checkboxEvent($event,dataItem)" />
  </ng-template>
</kendo-grid-checkbox-column>

<kendo-grid-column
  *ngFor="let col of column"
  field={{col.field}}
  title={{col.title}}
  width={{col.width}}
  [filterable]="col.isFilter"
  filter={{col.isDateFilter}}
  format={{col.formate}}
>
<ng-template 
     *ngIf="col.parse" 
     kendoGridCellTemplate let-dataItem 
     let-rowIndex="rowIndex"
>
      <span >{{col.parse(dataItem[col.field])}}</span>
</ng-template>
<ng-template
*ngIf="col.filterType=='list'"
  kendoGridFilterMenuTemplate
  let-column="column"
  let-filter="filter"
  let-filterService="filterService"
>
    <dropdownlist-filter
    [isPrimitive]="true"
    [field]="column.field"
    [currentFilter]="filter"
    [filterService]="filterService"
    [textField]="column.field"
    [valueField]="column.field"
    [data]="distinctPrimitive(column.field)"
    ></dropdownlist-filter>
</ng-template>
</kendo-grid-column>
<kendo-grid-excel fileName="data.xlsx"></kendo-grid-excel>
<kendo-grid-command-column *ngIf="action" title="" width="200">
  <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
    <button kendoGridEditCommand  *ngIf="renewFlag ||dataItem[renewKey]==true"  (click)="changeDate(dataItem)"  icon="clock" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoGridEditCommand *ngIf="editFlag"   icon="edit" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoGridEditCommand *ngIf="approved"  (click)=" approvedData('approve',dataItem)"  icon="check" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoGridEditCommand *ngIf="reject"  (click)="rejectData('reject',dataItem)"   style="color: #e1e7ef;background: transparent;border:0px;font-size: 20px;" >x</button>
      <button *ngIf="fileFlag" kendoButton   (click)="addAttachment(dataItem)"         icon="file" style="color: lightgray;background: transparent;border: 0px;"></button>
      <button *ngIf="deleteFlag || dataItem[deleteKey]==true" kendoGridRemoveCommand  icon="delete" style="color: lightgray;background: transparent;border: 0px;"></button>
      <button kendoButton    (click)="addQrCode(dataItem)"   *ngIf="qrcode" icon="qr-code" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoButton    (click)="addAssetReg(dataItem)"   *ngIf="addAsset" icon="plus" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoButton    (click)="unlockData(dataItem)"   *ngIf="unlockFlag" icon="lock" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoButton    (click)="viewData(dataItem)"   *ngIf="viewFlag" icon="eye" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoButton    (click)="viewData(dataItem)"   *ngIf="sparePartFlag" icon="wrench" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
      <button kendoButton    (click)="viewAssetData(dataItem)"  *ngIf="assetFlag"  icon="document-manager" style="color: #e1e7ef;background: transparent;border:0px;" ></button>
    </ng-template>
</kendo-grid-command-column>
</kendo-grid>