

<div class="card m-3">
    <div class="card-header" >
        <h5>Task Item</h5>
        <div>      
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
               Add Task Item
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid 
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
      ></app-table-grid>
    </div>
</div>
<kendo-dialog *ngIf="opened" (close)="onDialogClose()" [width]="1024">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           Task Item
        </div>
      </kendo-dialog-titlebar>
                  <div class="py-4">
                      <div class="form-row">
                          <div class="form-group col-10">
                            <label>Checklist No</label>
                            <kendo-dropdownlist  
                                [data]="model.checklist.filterData" 
                                [value]="model.checklist.selectedObj" 
                                (valueChange)="valuechange('checklist',$event)" 
                                class="form-control"
                                [textField]="'checkListNo'" 
                                [valueField]="'id'" 
                            >
                            </kendo-dropdownlist>
                        </div>
                      </div>
                      <div class="form-row">
                      <div class="form-group col-10">
                          <label>Maintenace Task</label>
                          <kendo-dropdownlist  
                          [data]="model.maintenaceTask.filterData" 
                          [value]="model.maintenaceTask.selectedObj" 
                          (valueChange)="valuechange('task',$event)" 
                          class="form-control"
                          [textField]="'DESCRIPTION'" 
                          [valueField]="'ID'" 
                      >
                      </kendo-dropdownlist>
                      </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>Task Item</label>
                            <input kendoTextBox [(ngModel)]="data.DESCRIPTION"   class="form-control"/>
                        </div>
                      </div>
                    <div class="form-row">
                        <div class="form-group col-10">
                            <label>Answer Type</label>
                            <kendo-dropdownlist  
                            [data]="model.taskAndType.filterData" 
                            [value]="model.taskAndType.selectedObj" 
                            (valueChange)="valuechange('answertype',$event)" 
                            class="form-control"
                            [textField]="'DESCRIPTION'" 
                            [valueField]="'ID'" 
                        >
                        </kendo-dropdownlist>
                        </div>
                      </div>
                      <div *ngIf="model.taskAndType.selectedObj && model.taskAndType.selectedObj.ID==5" class="form-row">
                        <div class="form-group col-10">
                            <label>Answer List</label>
                            <kendo-dropdownlist  
                            [data]="model.taskAnsList.filterData" 
                            [value]="model.taskAnsList.selectedObj" 
                            (valueChange)="valuechange('answerlist',$event)" 
                            class="form-control"
                            [textField]="'NAME'" 
                            [valueField]="'ID'" 
                        >
                        </kendo-dropdownlist>
                        </div>
                      </div>
                      <!-- <div *ngIf="model.taskAndType.selectedObj && model.taskAndType.selectedObj.ID==4" class="form-row">
                        <div class="form-group col-10">
                            <label>Free Text</label>
                            <input kendoTextBox   class="form-control"/>
                        </div>
                      </div> -->
                      <div class="py-3" style="text-align: center;width: 100%;">
                          <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                              Save
                          </button>
                          <button kendoButton   primary="true" class="px-3" (click)="reset()">
                              Reset
                          </button>
                      </div>
                  
                    </div>
</kendo-dialog>