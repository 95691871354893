export default {
    authorization:"authorization",
    edit_column:"edit_column",
    permission_change:"permission_change",
    remove_column:"remove_column",
    attachment_column:"attachment_column",
    attach_holiday_column:"attach_holiday_column",
    view_column:"view_column",
    delete_attechment:"delete_attechment",
    download_attechment:"download_attechment",
    qrCode_attachment:"qrCode_attachment",
    addAssetReg_attachment:"addAssetReg_attachment",
    unlock_data:"unlock_data",
    checkbox_column:"checkbox_column",
    approved_column:"approved_column",
    reject_column:"reject_column",
    renew_column:"renew_column",
    view_asset_column:" view_asset_column",
    work_order_query:"work_order_query",
    wo_id:"wo_id",
    request_id:"request_id",
    download_pdf:"download_pdf"
}