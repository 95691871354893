import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";

export class WorkOrderError{  
    id:boolean=false;
    userId:boolean=false;
    ticketStatusId:boolean=false;
    ticketConsequenceId:boolean=false
    description:boolean=false
    workOrderNo:boolean=false;
    serviceTypeId:boolean=false;
    referenceId:boolean=false;
    referenceType:boolean=false;
    assignee:boolean=false
    bpmid:boolean=false;
    defactPhoto:boolean=false;
    chargable:boolean=false;
    facility:boolean=false;
    block:boolean=false;
    level:boolean=false;
    area:boolean=false;
}
export class WorkOrder{  
    id:number;
    userId:number;
    ticketStatusId:number;
    ticketConsequenceId:number
    description:string
    workOrderNo:string;
    serviceTypeId:number;
    referenceId:number;
    referenceType:string;
    assignee:number
    bpmid:number;
    createdDate:Date;
    createdBy:number;
    AcceptanceDate:Date;
    AcceptedBy:number
    CompletedBy:number
    CheckInFlagDateTime:Date
    CompletionDateTime:Date
    ClosedBy:number
    closingDateTime:Date
    modifiedDate:Date;
    modifiedBy:number;
    ppmid:number

}

export class WorkOrderObj{
    alldata:Array<WorkOrder>=[]
    filterData:Array<WorkOrder>=[]
    selectedObj:WorkOrder=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:WorkOrder)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/WorkOrder/GetWorkOrders",true,true)
            //getLoockup(Schema.PMC,LOOKUPTABLE.ticket)
            .then((result:Array<WorkOrder>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
             
                reject(err)
            })
        })

    }
    getByFilter(assetClasificationId,plannerId,locationId,assignTo,assignee,year,month,blockId,levelId,areaId){
      let params=[];
      if(assetClasificationId){
          params.push("assetClasificationId="+assetClasificationId)
      }
      if(plannerId){
          params.push("plannerId="+plannerId)
      }
      if(locationId){
          params.push("locationId="+locationId)
      }
      if(assignTo){
          params.push("assignTo="+assignTo)
      }
      if(assignee){
          params.push("assignee="+assignee)
      }
      if(year){
          params.push("year="+year)
      }
      if(month){
          params.push("month="+month)
      }
      if(blockId){
          params.push("blockId="+blockId)
      }
      if(levelId){
          params.push("levelId="+levelId)
      }
      if(areaId){
          params.push("areaId="+areaId)
      }
      let path=""
      if(params.length>0){
        path="?"+params.join("&");
      }
      return new Promise((resolve,reject)=>{
        HttpClient.get("/api/PMC/GetPPMLoadBalancing"+path,true,true)
        .then((result:Array<WorkOrder>)=>{
        //   this.alldata=result;
        //   this.filterData=result;
        //   this.convertObj();
          resolve(result)
       })
       .catch((err)=>{
           reject(err)
       })
      })
    }
}