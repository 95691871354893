import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class AssetClassfication{ 
    code:string;
    description:string
    assetStatusId: number
    remarks:string
    id:number
  
}

export class AssetClassficationObj{
    alldata:Array<AssetClassfication>=[]
    filterData:Array<AssetClassfication>=[]
    selectedObj:AssetClassfication=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:AssetClassfication)=>{
                acc[d.id]=d;
                return acc;
        },{})
    }
    getData(){
        return new Promise((resolve,reject)=>{
            HttpClient.get("/api/Assets/GetAssetClassifications",true,true)
            .then((result:Array<AssetClassfication>)=>{
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }
}