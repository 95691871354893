<div class="card m-3"   >
    <!-- <h5 class="card-header">Levels</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Levels</h5>
        <div> 
          <button *ngIf="permission && permission.isCreate" kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> Level
          </button>
        </div>
    </div>
    <div   class="card-body register-form-body">
        <app-table-grid
        [data]="gridData"
        [column]="column1"
        [size]="10" 
        [editFlag]="permission && permission.isUpdate"
        [deleteFlag]="false"
        ></app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Facility<span class="required">*</span></label>
                    <div style="width:100%">
                    <kendo-dropdownlist  
                    [data]="facilityDropDown" class="form-control"
                    [textField]="'name'"
                    [valueField]="'id'"
                    [valuePrimitive]="true"
                    [(ngModel)]="levelForm.FACILITYID"
                    (valueChange)="facilityValueChange($event)" 
                    [disabled]="type=='update'"
                    >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.FACILITYID">*Required</kendo-formerror>
                </div>
                  </div>
              </div>

              <div class="form-row">
                  <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Block Name<span class="required">*</span></label>
                    <div style="width:100%">
                        <kendo-dropdownlist  
                          [data]="filterdBlockData" 
                          [(ngModel)]="levelForm.BLOCKID"  
                          class="form-control"
                          [textField]="'name'" 
                          [valueField]="'id'" 
                          [valuePrimitive]="true"   
                          [filterable]="true" 
                          (filterChange)="handleBlockFilter($event)"
                          [disabled]="type=='update'"
                        >
                        </kendo-dropdownlist>
                        <kendo-formerror *ngIf="error.BLOCKID">*Required</kendo-formerror>
                </div>
              </div>
              </div>

              <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Level Code<span class="required">*</span></label>
                    <div style="width:100%">
                    <input 
                       kendoTextBox  
                       class="form-control" 
                       [(ngModel)]="levelForm.CODE"
                       (blur)="checkedUniqueCode()"
                       />
                    <kendo-formerror *ngIf="error.CODE">*Required</kendo-formerror>
                    <kendo-formerror *ngIf="!error.CODE && isunique">Level code already exist.</kendo-formerror>
                </div>
                </div>
             </div>

            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                  
                    <label>Level Name<span class="required">*</span></label>
                    <div style="width:100%">
                    <input kendoTextBox class="form-control" [(ngModel)]="levelForm.NAME"/>
                    <kendo-formerror *ngIf="error.NAME">*Required</kendo-formerror>
                    </div>
                </div>
                </div>
           
          <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Short Name</label>
                    <input kendoTextBox [(ngModel)]="levelForm.SHORTNAME" class="form-control"/>
                    
                </div>
            </div>
            <div class="form-row">
                <div class="form-group" style="display: grid;grid-template-columns: 30% 70%;width:100%;">
                    <label>Status</label>
                    <kendo-dropdownlist  [(ngModel)]="levelForm.STATUSID"  [data]="statusDropDown" class="form-control"
                    [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                </div>
            </div>
            
        <kendo-dialog-actions [layout]="actionsLayout">
            <button kendoButton (click)="onDialogClose('new')">Cancel</button>
            <button kendoButton *ngIf="type=='new'" (click)="onReset()"  primary="true">
               Reset
            </button>
            <button kendoButton *ngIf="type=='new'" (click)="onSubmit()"  primary="true">
                Save
            </button>
            <button kendoButton *ngIf="type=='update'" (click)="onSubmit()"  primary="true">
               Update
            </button>
        </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeLevelData()">Delete</button>
    </kendo-dialog-actions>
  </kendo-dialog>