export const menu = {
  parents: [
    {
      text: "Dashboard",
      icon: "k-i-grid",
      key: "dashboard",
      children: true,
      selected: false,
      expanded: false,
      childrenList: [
        {
          text: "Asset",
          icon: "k-i-globe",
          key: "dashboardWorkOrder",
          expanded: false,
          children: false,
          selected: false,
          level: 1           ,
          path: "/dashboard",
        },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
        // {
        //   text: "Asset",
        //   icon: "k-i-globe",
        //   key: "asset1",
        //   expanded: false,
        //   children: false,
        //   selected: false,
        //   level: 1,
        //   path: "/asset_summery",
        // },
        // {
        //   text: "Schedule WO",
        //   icon: "k-i-globe",
        //   key: "schedulewo",
        //   expanded: false,
        //   children: false,
        //   selected: false,
        //   level: 1,
        //   path: "/schedule_wo_summery",
        // },
        // {
        //   text: "Unschedule WO",
        //   icon: "k-i-globe",
        //   key: "unschedulewo",
        //   expanded: false,
        //   children: false,
        //   selected: false,
        //   level: 1,
        //   path: "/unschedule_wo_summery",
        // },
        {
          text: "Inventory",
          icon: "k-i-globe",
          key: "inventory",
          expanded: false,
          children: false,
          selected: false,
          level: 1,
          path: "/inventory_summery",
        }
      ]
    },

    {
      text: "General Master",
      icon: "k-i-toc-section-level",
      key: "general_master",
      expanded: false,
      children: true,
      selected: false,
      childrenList: [

        {
          text: "Locations",
          icon: "k-i-globe",
          key: "location",
          expanded: false,
          children: true,
          selected: false,
          level: 1,
          childrenList: [

            {
              text: "Block",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/blocks"
            },
            {
              text: "Level",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/levels"
            },
            {
              text: "Area",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/area"
            },
            {
              text: "Location",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/location"
            },
            {
              text: "Sub Location",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/sub-location"
            },
          ]
        },
        {
          text: "Facility",
          icon: "k-i-set-column-position",
          key: "facility",
          expanded: false,
          children: true,
          selected: false,
          level: 1,
          childrenList: [
            {
              text: "Facility Registration",
              icon: "k-i-arrow-60-right",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/facility-registeration"
            },

          ]
        },
        // {
        //   text: "Asset Metadata",
        //   icon: "k-i-set-column-position",
        //   key: "assets_metadeta",
        //   expanded: false,
        //   children: true,
        //   level: 1,
        //   selected: false,
        //   childrenList: [
        //     {
        //       text: "Asset Classification",
        //       icon: "k-i-arrow-60-right",
        //       children: false,
        //       selected: false,
        //       expanded: false,
        //       level: 2,
        //       path: "/asset-classification"
        //     },
        //     {
        //       text: "Asset Type Code Details",
        //       icon: "k-i-arrow-60-right",
        //       children: false,
        //       selected: false,
        //       expanded: false,
        //       level: 2,
        //       path: "/asset-type-code-classification"
        //     },
        //     {
        //       text: "Asset Standardization",
        //       icon: "k-i-arrow-60-right",
        //       children: false,
        //       selected: false,
        //       expanded: false,
        //       level: 2,
        //       path: "/asset-standardization"
        //     },
        //   ]
        // },
        {
          text: "List of Values",
          icon: "k-i-grid",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/list-value"
        },
        {
          text: "Contractor & Vendor",
          icon: "k-i-track-changes-accept",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/contractor-vendor"
        },
        {
          text: "Working Calendar",
          icon: "k-i-track-changes-accept",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/calendar"
        },
        {
          text: "System Setting ",
          icon: "k-i-set-column-position",
          key: "sysytem-setting_metadeta",
          expanded: false,
          children: true,
          level: 1,
          selected: false,
          childrenList: [
            {
              text: "Terms And Conditions",
              icon: "k-i-track-changes-accept",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/terms-and-conditions"
            },
            {
              text: "Privacy",
              icon: "k-i-track-changes-accept",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/privacy"
            },
            {
              text: "Notification",
              icon: "k-i-track-changes-accept",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/notification"
            },
            {
              text: "Company Working Hour",
              icon: "k-i-track-changes-accept",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/company-working-hour"
            },
            {
              text: "Audit Log",
              icon: "k-i-track-changes-accept",
              children: false,
              selected: false,
              expanded: false,
              level: 2,
              path: "/audit-log"
            },
          ]
        },
      ]
    },


    {
      text: "User Module",
      icon: "k-i-user",
      key: "user_module",
      expanded: false,
      children: true,
      selected: false,
      childrenList: [
        {
          text: "User Role",
          icon: "k-i-arrow-60-right",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/userrole"
        },
        {
          text: "User Registration",
          icon: "k-i-arrow-60-right",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/register"
        },
        {
          text: "Reset Password",
          icon: "k-i-arrow-60-right",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/reset-password"
        },
        {
          text: "User Shift and Leave",
          icon: "k-i-arrow-60-right",
          children: false,
          selected: false,
          expanded: false,
          level: 1,
          path: "/user-shift"
        },
      ]
    },

    {
      text: "ASSET",
      icon: "k-i-file-flash",
      key: "fems",
      expanded: false,
      children: true,
      selected: false,
      childrenList: [
        {
          text: "Asset Register",
          icon: "k-i-arrow-60-right",
          children: false,
          selected: false,
          expanded: false,
          level: 2,
          path: "/asset-register"
        }
      ]
    },

    // {
    //   text: "FEMS",
    //   icon: "k-i-file-flash",
    //   key: "fems",
    //   expanded: false,
    //   children: true,
    //   selected: false,
    //   childrenList: [

    //     {
    //       text: "Assets",
    //       icon: "k-i-group-section",
    //       key: "assetForFEMS",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [

    //         {
    //           text: "Asset Register",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/asset-register"
    //         }
    //       ]
    //     },
    //     {
    //       text: "Planner",
    //       icon: "k-i-table-align-middle-left",
    //       key: "planner",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [

    //         {
    //           text: "Planned Preventive Maintenance",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/ppm"
    //         },
    //         {
    //           text: "Daily Inspection",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/daily-inspection"
    //         }
    //         ,
    //         {
    //           text: "Predective Maintenance",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/predective-maintenance"
    //         }
    //         ,
    //         {
    //           text: "PPM Load Balancing",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/ppm-load-balancing"
    //         }
    //       ]
    //     },
    //     {
    //       text: "Work Orders",
    //       icon: "k-i-page-properties",
    //       key: "work_orderForFEMS",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [
    //         {
    //           text: "Work Order Query",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/work-order-query"
    //         },
    //         {
    //           text: "Raise Work Request",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/raise-work-request"
    //         }
    //       ]
    //     },

    //     {
    //       text: "Checklist & SLA",
    //       icon: "k-i-track-changes-accept-all",
    //       key: "checklist_sla",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [

    //         {
    //           text: "Create Checklist",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/checklist"
    //         },
    //       ]
    //     },

    //     {
    //       text: "Spare Parts",
    //       icon: "k-i-gears",
    //       key: "spare_parts",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [

    //         {
    //           text: "Spare Parts",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/spare-parts"
    //         },
    //         {
    //           text: "Spare Part Update",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/spare-part-update"
    //         },
    //         {
    //           text: "Stock Take Activity",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/stock-take-activity"
    //         },
    //         {
    //           text: "Spare Part Request",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/spare-part-request"
    //         },
    //         {
    //           text: "Spare Part Return",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/spare-part-return"
    //         },
    //         {
    //           text: "Monthly Spare Part Register",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/monthly-spare-part-register"
    //         }
    //       ]
    //     },
    //     {
    //       text: "Engineering Records",
    //       icon: "k-i-group-section",
    //       key: "er",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [

    //         {
    //           text: "Parameter Mapping",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/parameter-mapping"
    //         },
    //         {
    //           text: "Capture",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/er-capture"
    //         },
    //         {
    //           text: "Daily Report",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/er-report-daily"
    //         },
    //         {
    //           text: "Monthly Report",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/er-report-monthly"
    //         }
    //       ]
    //     },
    //     {
    //       text: "Contract Management for Facility User",
    //       icon: "k-i-track-changes-enable",
    //       children: false,
    //       selected: false,
    //       expanded: false,
    //       level: 1,
    //       path: "/contract-management-for-facility-user"
    //     },
    //     {
    //       text: "License & Certificate",
    //       icon: "k-i-file-txt k-i-tx",
    //       children: false,
    //       selected: false,
    //       expanded: false,
    //       level: 1,
    //       path: "/license-certifciate"
    //     },
    //     {
    //       text: "QR Code",
    //       icon: "k-i-qr-code",
    //       key: "qr_code",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [
    //         {
    //           text: "Asset QR Code Printing",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/asset-qr-code-printing"
    //         },
    //         {
    //           text: "Location QR Code Printing",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/location-qr-code-printing"
    //         },
    //         {
    //           text: "Download App QR Code",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/download-app"
    //         }
    //       ]
    //     },

    //     {
    //       text: "Reports",
    //       icon: "k-i-page-properties",
    //       key: "reports",
    //       expanded: false,
    //       children: true,
    //       selected: false,
    //       level: 1,
    //       childrenList: [
    //         {
    //           text: "WO Outstanding Report",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/avgWOOutstanding"
    //         },
    //         {
    //           text: "Closed Work Order Summary",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/closedwosummary"
    //         },
    //         {
    //           text: "Work Orders Report for Contractor",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/reportForContractor"
    //         },
    //         {
    //           text: "Work Orders Report for IBCC",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/workOrderReportForIBCC"
    //         },
    //         {
    //           text: "Work Orders Report for IBCC - Vendor Performance",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/workOrderReportForIBCCVendorPerformance"
    //         },
    //         {
    //           text: "Work Orders Report for IBCC - Spare Parts",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/workOrderReportForIBCCSpareParts"
    //         },
    //         {
    //           text: "Work Orders Report for IBCC - Spare Parts Request",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/workOrderIBCCSparePartsRequests"
    //         },
    //         {
    //           text: "Work Orders Report for IBCC - Spare Parts Returns",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/workOrderIBCCSparePartsReturns"
    //         },
    //         {
    //           text: "Report for External Feedback (Rating)",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/external_feedback"
    //         },
    //         {
    //           text: "Percentage of feedback received",
    //           icon: "k-i-arrow-60-right",
    //           children: false,
    //           selected: false,
    //           expanded: false,
    //           level: 2,
    //           path: "/feedback_received"
    //         }
    //       ]
    //     }
    //   ]
    // },

  ]
};