<div class="card m-3">
    <div class="card-header" >
        <h5>Daily Inspection Page</h5>
      
    </div>
    <div class="card-body register-form-body">
       
        <app-table-grid
        [data]="areagrid"
        [column]="column2"
        [size]="5"
        [action]="false"
      ></app-table-grid>
    </div>
  </div>