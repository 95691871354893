import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { CustomMessagesService } from '../services/custom-messages.service';
import { locales } from 'src/app/resources/locales';
import { getCookie, setCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import { Router } from '@angular/router';
import {dispatchEvent,EVENT_NAME } from 'src/event';
import HttpClient from 'src/Util/ApiHandling';

@Component({
    selector: 'app-header-component',
    templateUrl: './header.commponent.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit{
    @Output() public toggle = new EventEmitter();

    @Input() public selectedPage: string;
  
    public customMsgService: CustomMessagesService;
    public selectedLanguage = { locale: 'English', localeId: 'en-US' };
    public locales = locales;
    public notifDetails = [];
    public notifCount=0 
    public popupSettings = { width: '150' };
    public themes = [
        {
            href: 'assets/kendo-theme-default/dist/all.css',
            text: 'Default'
        },
        {
            href: 'assets/kendo-theme-bootstrap/dist/all.css',
            text: 'Bootstrap'
        },
        {
            href: 'assets/kendo-theme-material/dist/all.css',
            text: 'Material'
        }
    ];
    public selectedTheme = this.themes[0];

    constructor(public messages: MessageService, @Inject(LOCALE_ID) public localeId: string, public intlService: IntlService,public router:Router) {
        this.localeId = this.selectedLanguage.localeId;
        this.setLocale(this.localeId);

        this.customMsgService = this.messages as CustomMessagesService;
        this.customMsgService.language = this.selectedLanguage.localeId;
    }

    ngOnInit() {
        this.getNotificationById(getCookie(userid))
    }

    public changeTheme(theme) {
        this.selectedTheme = theme;
        const themeEl: any = document.getElementById('theme');
        themeEl.href = theme.href;
    }

    public changeLanguage(item): void {
        this.customMsgService.language = item.localeId;
        this.setLocale(item.localeId);
    }

    public setLocale(locale): void {
        (this.intlService as CldrIntlService).localeId = locale;
    }

    public onButtonClick(): void {
        this.toggle.emit();
    }

    logout(){
        dispatchEvent(EVENT_NAME.authorization,false);
      }
      
   getNotificationById(userid){
        HttpClient.get(`/api/MasterData/GetNotificationByUserID?id=${userid}`, true)
        .then((result: any) => {
            this.notifDetails = result.notifications
            this.notifCount = result.notificationCount
    })
      }
}
