import {LOOKUPTABLE,Schema} from "../const"
import {getLoockup,saveLookup,removeLookup} from "src/ApiModel/Util"
import HttpClient from "src/Util/ApiHandling";
export class MaintenacePlannerDetail{ 
    ID:number;
    MAINTENANCEPLANNERID:number;
    ASSETID:number;
    LOCATIONID:number;
    USERID:number;
    BPMID:number;
    CREATEDDATE:Date;
    CREATEDBY:number;
    MODIFIEDDATE:Date;
    MODIFIEDBY:number;
    SCHEDULETYPEID:number;
   async  saveData(){
        this.ID=0;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
       return saveLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlannerDetail,this,"new")
    }	
   async updateData(id){
        this.ID=id;
        this.CREATEDDATE=new Date();
        this.CREATEDBY=1;
        this.MODIFIEDBY=1;
        this.MODIFIEDDATE=new Date() 
        return saveLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlannerDetail,this,"update")
    }
   async deleteData(obj:any){
       return removeLookup(Schema.PMC,LOOKUPTABLE.MaintenancePlannerDetail,obj);
    }
    addWorkOrder(type,obj,month=[],dt,week,days){
        //let obj=this.fetchObj[id];
        let bodyObj={}
        let url=""
        if(type==1)//ppm
        {
          bodyObj={...obj}
          url="/api/WorkOrder/AddWorkOrderByPlannerForPPM"
        }
        else if(type==2)//pdm
        {
         bodyObj={
             maintenancePlannerDetailModel:obj,
             month:month || [],
             dt:dt||[],
             week:week||[],
             days:days||[],
             year:[]
         }
         url="/api/WorkOrder/AddWorkOrderByPlannerForPDM"
        }
        else if(type==3)//DI
        {
          bodyObj={...obj}  
         url="/api/WorkOrder/AddWorkOrderByPlannerForDaily"
        }
        console.log("workorder ==>save",bodyObj,JSON.stringify(bodyObj))
        return new Promise((resolve,reject)=>{
            HttpClient.fetchData(url,"post",bodyObj,true,true)
            .then((result)=>{
                resolve(result)
               console.log("workorder create=>",result);
            })
            .catch((err)=>{
                resolve(err)
                console.log(err," workorder not Created",err)
            })
        })
     }  
    saveWithWorkorder(type,obj,month,dt,week,days){
        return new Promise((resolve,reject)=>{
            Promise.all([
                //this.saveData(),
                this.addWorkOrder(type,obj,month,dt,week,days)
            ])
            .then((r)=>{
                resolve(r)
            })
            .catch((err)=>{
                reject(err)
            })
        })
    }
    updateWithWorkorder(id,type,obj,month,dt,week,days){
        return new Promise((resolve,reject)=>{
            Promise.all([
                this.updateData(id),
                this.addWorkOrder(type,obj,month,dt,week,days)
            ])
            .then((r)=>{
                resolve(r)
            })
            .catch((err)=>{
                reject(err)
            })
        })
 }
}

export class MaintenacePlannerDetailObj{
    alldata:Array<MaintenacePlannerDetail>=[]
    filterData:Array<MaintenacePlannerDetail>=[]
    fetchObj:{}
    selectedObj:MaintenacePlannerDetail=null;
    objectData={}
    convertObj(){
        this.objectData=this.alldata.reduce((acc:any,d:MaintenacePlannerDetail)=>{
                acc[d.ID]=d;
                return acc;
        },{})
    }

    getData(){
        return new Promise((resolve,reject)=>{
            getLoockup(Schema.PMC,LOOKUPTABLE.MaintenancePlannerDetail)
            .then((result:Array<MaintenacePlannerDetail>)=>{
                result=result.map((d:any)=>{
                    //this.fetchObj[d.id]=d;
                    let d1=new Date();
                    d["month"]="February"
                    d["year"]=d1.getFullYear();
                    d["date"]=d1.getDate();
                    return d;
                })
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })

    }


    filterByData(id,Year,Month,RefType,AssetNo,AssetClassificationID,AssetTypeID,FacilityID,BlockID,LevelID,AreaID,LocationID,Assignee,AssignTo){
        let query={id,Year,Month,RefType,AssetNo,AssetClassificationID,AssetTypeID,FacilityID,BlockID,LevelID,AreaID,LocationID,Assignee,AssignTo}
        let q="";
        let count=0;
        for(let key in query){
            if(query[key] && count==0){
                q+=`?${key}=${query[key]}`
                count++;
            }
            else if(query[key]){
                q+=`&${key}=${query[key]}`  
            }
        }
        let m=["","Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        console.log("query====>",q,query);
        return new Promise((resolve,reject)=>{
            //getLoockup(Schema.PMC,LOOKUPTABLE.MaintenancePlannerDetail)
            HttpClient.get("/api/PMC/GetPlannerByFilter"+q,true,true)
            .then((result:Array<any>)=>{
                this.fetchObj={};
                result=result.map((p:any)=>{
                    let d=p.maintenancePlannerDetailModel;
                    this.fetchObj[d.id]=p;
                    let obj=new MaintenacePlannerDetail();
                    obj.ASSETID=d.assetId;
                    obj.BPMID=d.bpmid;
                    obj.CREATEDBY=d.createdBy;
                    obj.CREATEDDATE=d.createdDate;
                    obj.ID=d.id;
                    obj.LOCATIONID=d.locationId;
                    obj.MAINTENANCEPLANNERID=d.maintenancePlannerId;
                    obj.MODIFIEDBY=d.modifiedBy;
                    obj.MODIFIEDDATE=d.modifiedDate;
                    obj.USERID=d.userId;
                    obj.SCHEDULETYPEID=d.scheduleTypeId
                    let d1=new Date();
                    obj["month"]=m[p["plannerMonth"]]
                    obj["year"]=p["plannerYear"]
                    obj["date"]=d1.getDate();
                    return obj;
                })
               this.alldata=result;
               this.filterData=result;
               this.convertObj();
               resolve(true)
            })
            .catch((err)=>{
              
                reject(err)
            })
        })
    }
    addWorkOrder(id,type,month=[],dt,week,days){
       let obj=this.fetchObj[id];
       let bodyObj={}
       let url=""
       if(type==1)//ppm
       {
           bodyObj={...obj}
         url="/api/WorkOrder/AddWorkOrderByPlannerForPPM"
       }
       else if(type==2)//pdm
       {
        bodyObj={
            maintenancePlannerDetailModel:obj,
            month,
            dt,
            week,
            days
        }
        url="/api/WorkOrder/AddWorkOrderByPlannerForPDM"
       }
       else if(type==3)//DI
       {
         bodyObj={...obj}  
        url="/api/WorkOrder/AddWorkOrderByPlannerForDaily"
       }
       HttpClient.fetchData(url,"post",bodyObj,true,true)
       .then((result)=>{

       })
       .catch((err)=>{
           console.log(err," workorder not Created")
       })
    }
}