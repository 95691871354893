
<div class="card m-3">
    <div class="card-header" >
        <h5>{{uiText?.title}} Planner For Asset </h5>
        <div>  
          <button kendoButton  primary="true" (click)="importData()"  class="header-right-btn">
            Import
               </button> &nbsp;  &nbsp;
            <button kendoButton  primary="true" (click)="download()" class="header-right-btn" >
            Download Template
             </button>  &nbsp;  &nbsp;     
          <button kendoButton  primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
             {{uiText?.title}} For Asset
          </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <div class="py-3">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Asset Classification</label>
                    <kendo-dropdownlist  
                    [data]="model.assetClassfication.filterData" 
                    [value]="filterGrid.assetClassificatioon" 
                    (valueChange)="filterChange('assetClassificatioon',$event)" 
                    class="form-control"
                    [textField]="'code'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Assign To</label>
                    <kendo-dropdownlist  
                    [data]="model.contractor.filterData" 
                    [value]="filterGrid.assignTo" 
                    (valueChange)="filterChange('assignTo',$event)" 
                    class="form-control"
                    [textField]="'name'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Assignee</label>
                    <kendo-dropdownlist  
                    [data]="model.user.filterData" 
                    [value]="filterGrid.assignee"  
                    (valueChange)="filterChange('assignee',$event)" 
                    class="form-control"
                    [textField]="'staffName'" 
                    [valueField]="'id'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Planner</label>
                    <kendo-dropdownlist  
                    [data]="model.planner.filterData" 
                    [value]="filterGrid.planner" 
                    (valueChange)="filterChange('planner',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
            </div>
            <!-- <div class="form-row">
                <div class="form-group col-5">
                    <label>Area Name</label>
                    <kendo-dropdownlist  
                    [data]="model.area.filterData" 
                    [value]="filterGrid.areaName" 
                    (valueChange)="filterChange('areaName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Location Name</label>
                    <kendo-dropdownlist  
                    [data]="model.location.filterData" 
                    [value]="filterGrid.locationName" 
                    (valueChange)="filterChange('locationName',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
            </div> -->
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Year</label>
                    <kendo-dropdownlist  
                    [data]="model.year.filterData" 
                    [value]="filterGrid.year" 
                    (valueChange)="filterChange('year',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <div class="form-group col-5">
                    <label>Month</label>
                    <kendo-dropdownlist  
                    [data]="model.month.filterData" 
                    [value]="filterGrid.month" 
                    (valueChange)="filterChange('month',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                </div>
                <!-- <div class="form-group col-5">
                    <label>Type</label>
                    <input kendoTextBox value="Asset" [disabled]="true" class="form-control"/>
                </div> -->
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                </div>
                <div class="form-group col-5" style="width: 100%;">
                        <div style="display: flex;justify-content: flex-end;width: 100%;">
                          <button kendoButton primary="true" class="mr-2" (click)="resetFetch()">Reset</button>
                            <button kendoButton   primary="true" class="mr-2 px-3" (click)="fetchData()">
                                Fetch
                            </button>
                        </div>
                 </div>
            </div>
        </div>
        <app-table-grid
        [data]="task"
        [column]="column"
        [size]="5"
        [action]="true"
      ></app-table-grid>
    </div>
  </div>

  <kendo-dialog *ngIf="opened"  (close)="onDialogClose()" [width]="920">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    <div class="py-4">
        <div class="form-row">
            <div class="form-group col-5 asset-list">
                <label>Asset<span class="required">*</span></label>
                <div>
            <kendo-multicolumncombobox
            #list
            [data]="model.asset.filterData"
            [listHeight]="300"
            textField="xyz"
            valueField="id"
            [disabled]="type=='update'"
            (close)="closeEvent($event)"
            (focus)="openMultiselect($event)"
            (blur)="closeMultiSelect($event)"
            style="height: 30px;"
          >
          <kendo-combobox-column
        field=""
        title=""
        [width]="30"
      >
        <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
          <input
            type="checkbox"
            [checked]="dataItem.isSelected"
            (click)="selectedAsset(dataItem)"
          />
        </ng-template>
      </kendo-combobox-column>
          <kendo-combobox-column field="name" title="Asset Number" [width]="150">
            <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
              <span>{{ dataItem.assetNumber }}</span>
            </ng-template>
          </kendo-combobox-column>
            <kendo-combobox-column field="name" title="Asset Type Code" [width]="150">
              <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                <span>{{ dataItem.assetTypeObj && dataItem.assetTypeObj.CODE }}</span>
              </ng-template>
            </kendo-combobox-column>
            <kendo-combobox-column field="name" title="Asset Type Code Description" [width]="200">
                <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                  <span>{{ dataItem.assetTypeObj && dataItem.assetTypeObj.DESCRIPTION }}</span>
                </ng-template>
              </kendo-combobox-column>
            <kendo-combobox-column field="assetClassification" title="Asset Classification" [width]="150">
                <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                  <span>{{ dataItem.assetClassificationObj && dataItem.assetClassificationObj.code }}</span>
                </ng-template>
              </kendo-combobox-column>
              <kendo-combobox-column field="model" title="Model" [width]="100">
                <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                  <span>{{ dataItem.modelObj && dataItem.modelObj.name}}</span>
                </ng-template>
              </kendo-combobox-column>
              <kendo-combobox-column field="Manufacturer" title="Manufacturer" [width]="100">
                <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                  <span>{{ dataItem.manufectureObj && dataItem.manufectureObj.name}}</span>
                </ng-template>
              </kendo-combobox-column>
              <kendo-combobox-column field="Manufacturer" title="Serial Number" [width]="100">
                <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                  <span>{{ dataItem.serialNo}}</span>
                </ng-template>
              </kendo-combobox-column>
          </kendo-multicolumncombobox>
          <kendo-formerror *ngIf="error.asset">*Required</kendo-formerror>
          </div>
            </div>
            <div class="form-group col-5">
                <label>Asset Number</label> 
                <kendo-multiselect
                [data]="model.asset.filterData" 
                [value]="model.asset.selectedObj" 
                (valueChange)="valuechange('asset',$event)" 
                class="form-control"
                [textField]="'assetNumber'" 
                [valueField]="'id'"
                [disabled]="true" 
            >
            </kendo-multiselect>
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Planner<span class="required">*</span></label>
                <div style="width: 100%;">
                <kendo-dropdownlist  
                    [data]="model.planner.filterData" 
                    [value]="model.planner.selectedObj" 
                    (valueChange)="valuechange('planner',$event)" 
                    class="form-control"
                    [textField]="'NAME'" 
                    [valueField]="'ID'" 
                >
                </kendo-dropdownlist>
                <kendo-formerror *ngIf="error.planner">*Required</kendo-formerror>
                </div>
            </div>
            <div class="form-group col-5">
                <label>Assign To<span class="required">*</span></label>
                <div style="width: 100%;">
                <kendo-dropdownlist  
                [data]="model.contractor.filterData" 
                [value]="model.contractor.selectedObj" 
                (valueChange)="valuechange('contractor',$event)" 
                class="form-control"
                [textField]="'name'" 
                [valueField]="'id'" 
            >
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.assignTo">*Required</kendo-formerror>
            </div>
            </div>
        </div>
        <div class="form-row">
                <div class="form-group col-5">
                    <label>Assignee<span class="required">*</span></label>
                    <div style="width: 100%;">
                    <kendo-dropdownlist  
                        [data]="model.user.filterData" 
                        [value]="model.user.selectedObj" 
                        (valueChange)="valuechange('user',$event)" 
                        class="form-control"
                        [textField]="'staffName'" 
                        [valueField]="'id'" 
                    >
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.assignee">*Required</kendo-formerror>
                    </div>
                </div>
                <div class="form-group col-5">
                    <label>Contact No.</label>
                    <input kendoTextBox [value]="model.user.selectedObj && model.user.selectedObj.mobileNo" [disabled]="true" class="form-control"/>
                </div>
        </div>
        <div *ngIf="id==2 && type!='update'" class="form-row">
            
          <div class="form-group col-5">
              <label>Schedule Type</label>
              <kendo-dropdownlist  
              [data]="scheduleData.type" 
              [value]="scheduleInfo.type" 
              (valueChange)="scheduleInfo.type=$event" 
              class="form-control"
              [textField]="'name'" 
              [valueField]="'id'" 
          >
          </kendo-dropdownlist>
          </div>
          <div class="form-group col-5">
              <label>Month</label>
              <kendo-multiselect  
              [data]="scheduleData.month" 
              [value]="scheduleInfo.month" 
              (valueChange)="scheduleInfo.month=$event" 
              class="form-control"
              [textField]="'NAME'" 
              [valueField]="'ID'" 
             >
            </kendo-multiselect>
          </div>
      </div>
      <div *ngIf="scheduleInfo.type && scheduleInfo.type.id==1" class="form-row">
          <div class="form-group col-5">
              <label>Date</label>
              <kendo-multiselect  
              [data]="scheduleData.date" 
              [value]="scheduleInfo.date" 
              (valueChange)="scheduleInfo.date=$event" 
              class="form-control"
              [textField]="'name'" 
              [valueField]="'id'" 
             >
            </kendo-multiselect>
          </div>
          <div class="form-group col-5">
           </div>
      </div>
      <div *ngIf="scheduleInfo.type && scheduleInfo.type.id==2" class="form-row">
          <div class="form-group col-5">
              <label>Week</label>
              <kendo-multiselect  
              [data]="scheduleData.week" 
              [value]="scheduleInfo.week" 
              (valueChange)="scheduleInfo.week=$event" 
              class="form-control"
              [textField]="'name'" 
              [valueField]="'id'" 
             >
            </kendo-multiselect>
          </div>
          <div class="form-group col-5">
              <label>Day</label>
              <kendo-multiselect  
              [data]="scheduleData.day" 
              [value]="scheduleInfo.day" 
              (valueChange)="scheduleInfo.day=$event" 
              class="form-control"
              [textField]="'name'" 
              [valueField]="'id'" 
             >
            </kendo-multiselect>
          </div>
      </div>
        <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton   primary="true" class="mr-2 px-3" (click)="saveData()">
                Save
            </button>
            <button kendoButton   primary="true" class="px-3" (click)="reset()">
                Reset
            </button>
        </div>
    
      </div>
    
  </kendo-dialog>


  <kendo-dialog *ngIf="importopened" (close)="onDialogClose('importUpdate')" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
            
            
            <div class="form-group col-6">
                <label>Attachment</label>
                <app-file-model
                type="doc" 
                [formate]="['xlsx','csv']" 
                [url]="urlimg"
                [fileUploadType]=5
                style="width: 100%;"
                (valueChange)=uploadChange($event)
                >
           </app-file-model>
            </div>
            </div>
          <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="onDialogClose('importUpdate')">Cancel</button>
      <!-- <button kendoButton primary="true" (click)="importSave()">
            Save
      </button> -->
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog *ngIf="false && importopened" (close)="importopened=false" [width]="580">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>

        <div class="form-row">
             <div class="form-group col-6">
                <label>Attachment</label>
                <import-attechment
                   type="doc" 
                   [formate]="['xlsx']" 
				           [fileUploadType]=6
                   style="width: 100%;"
                >
              </import-attechment>
            </div>
 </div>
  
          
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="importopened=false">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>