<div class="licensce-body"   >
<div class="card m-3">
    <div class="card-header">
        <h5>License & Certificate Details</h5>
        <div>
            <button kendoButton  primary="true" (click)="download()"   class="header-right-btn">
                Export
               </button>  &nbsp;  &nbsp;  &nbsp; 
            <button kendoButton primary="true" (click)="openRoleDailog('new')" class="header-right-btn">
                Register
            </button>
        </div>
    </div>
    <div class="card-body register-form-body">
        <app-table-grid [data]="gridData" [column]="column" renewKeyValue = "renewKeyValue" [size]="5" [action]="true" [deleteFlag]="false"
        deleteKey="deleteKey">
        </app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    <div class="py-4">
        <div class="form-row">
            <div class="form-group col-5">
                <label>Registration
                    No/Reference
                    No.
                    <span class="required">*</span></label>
                <div style="width:100%">
                    <input kendoTextBox [(ngModel)]="licenseRegForm.registrationNo" class="form-control" />
                    <kendo-formerror *ngIf="error.registrationNo">*Required</kendo-formerror>
                </div>
            </div>
            <div class="form-group col-5">
                <label>License No.</label>
                <input kendoTextBox [(ngModel)]="licenseRegForm.licenseNo" class="form-control" />

                <!-- <kendo-formerror *ngIf="validiationRequired.serviceType">*Required</kendo-formerror> -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>License and Certificate Description</label>
                <input kendoTextBox [(ngModel)]="licenseRegForm.description" class="form-control" />
                <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
            </div>
            <div class="form-group col-5">
                <label>Category<span class="required">*</span></label>
                <div style="width:100%">
                    <kendo-dropdownlist [data]="categoryDropDown" [textField]="'name'" [valueField]="'id'"
                        [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.categoryId"
                        (valueChange)="categoryValueChange($event)" ngDefaultControl>
                    </kendo-dropdownlist>
                    <kendo-formerror *ngIf="error.categoryId">*Required</kendo-formerror>
                </div>
            </div>
        </div>
        <div *ngIf="isCompany">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Company Type</label>
                    <kendo-dropdownlist [data]="companyType" [textField]="'name'" [valueField]="'id'"
                        [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.companyTypeId"
                        (valueChange)="companyTypeChange($event)" ngDefaultControl>
                    </kendo-dropdownlist>
                    <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                </div>
                <div class="form-group col-5">
                    <label>Contractor and Vendor Reg No/Facility Code<span class="required">*</span></label>
                    <kendo-dropdownlist [data]="contractorVenDropDown" [textField]="'registrationNo'"
                        [valueField]="'id'" [valuePrimitive]="true" class="form-control"
                        (valueChange)="contaractorValueChange($event)" [(ngModel)]="licenseRegForm.contractorVendorId"
                        ngDefaultControl>
                    </kendo-dropdownlist>
                    <!-- <kendo-formerror *ngIf="validiationRequired.status">*Required</kendo-formerror> -->
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Contractor company name/ facility name </label>
                    <input kendoTextBox [(ngModel)]="facilityName" disabled class="form-control" />
                    <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                </div>
                <div class="form-group col-5">
                </div>

            </div>
        </div>
        <div class="personal" *ngIf="isPersonal">
            <div class="form-row">
                <div class="form-group col-5">
                    <label>Staff Name</label>
                    <kendo-dropdownlist [data]="staffDropDown" [textField]="'staffName'" [valueField]="'id'"
                        [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.staffId"
                        (valueChange)="staffNameChange($event)" ngDefaultControl>
                    </kendo-dropdownlist>
                    <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                </div>
                <div class="form-group col-5">
                    <label>Designation <span class="required">*</span></label>
                    <input kendoTextBox [(ngModel)]="designationName" disabled class="form-control" />
                    <!-- <kendo-formerror *ngIf="validiationRequired.status">*Required</kendo-formerror> -->
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-5">
                    <label>

                        Company Name

                    </label>
                    <input kendoTextBox [(ngModel)]="companyName" disabled class="form-control" />
                    <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                </div>
                <div class="form-group col-5">
                </div>

            </div>
        </div>
      
        <div class="form-row">
            <div class="form-group col-5">
                <label>Issuing Body</label>
                <input kendoTextBox class="form-control" [(ngModel)]="licenseRegForm.issuingBody" />
                <!-- <kendo-formerror *ngIf="validiationRequired.year">*Required</kendo-formerror> -->
            </div>
            <div class="form-group col-5">
                <label>Service Type</label>
                <kendo-dropdownlist [data]="serviceDropDown" [textField]="'NAME'" [valueField]="'ID'"
                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.serviceTypeId"
                    ngDefaultControl>
                </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="validiationRequired.freq">*Required</kendo-formerror> -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Competency</label>
                <kendo-dropdownlist [data]="compentencyDropDown" [textField]="'NAME'" [valueField]="'ID'"
                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.competencyId"
                    ngDefaultControl>
                </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">
                <label>Facility</label>
                <kendo-dropdownlist [data]="facilityDropDown" [textField]="'NAME'" [valueField]="'ID'"
                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.facilityId"
                    ngDefaultControl>
                </kendo-dropdownlist>
                <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Class/Grade</label>
                <input kendoTextBox [(ngModel)]="licenseRegForm.grade" class="form-control" />
                <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">
                <label>License Issuing
                    Date</label>
                <kendo-datepicker [(ngModel)]="licenseRegForm.licenseIssuingDate" [format]="format"   (valueChange) = "licenseValueChange(licenseRegForm.licenseIssuingDate)">
                </kendo-datepicker>
                <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Expiry Date</label>
                <kendo-datepicker [(ngModel)]="licenseRegForm.expiryDate" [format]="format"  [min]="min">
                </kendo-datepicker>
                <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">
                <label>Notification for
                    Inspection
                </label>
                <kendo-datepicker [(ngModel)]="licenseRegForm.notificationForInspection"  [min]="min" [format]="format" >
                </kendo-datepicker>
                <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-5">
                <label>Inspection
                    Conducted On</label>
                <kendo-datepicker [format]="format"  [(ngModel)]="licenseRegForm.inspectionConductedOn"  [min]="min" (valueChange) = "inspectionConductionChange(licenseRegForm.inspectionConductedOn)">
                </kendo-datepicker>
                <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">
                <label>Next Inspection
                    Date
                </label>
                <kendo-datepicker [format]="format"  [(ngModel)]="licenseRegForm.nextInspectionDate" [min]="mininspect">
                </kendo-datepicker>
                <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Remark</label>
                <input kendoTextBox [(ngModel)]="licenseRegForm.remark" class="form-control" />
                <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
            </div>
            <div class="form-group col-5">

                <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
            </div>
        </div>
        <div class="py-3" style="text-align: center;width: 100%;">
            <button kendoButton primary="true" class="mr-2 px-3" (click)="onSubmit()">
                Save
            </button>
            <button *ngIf="type!='update'" kendoButton (click)="onReset()" primary="true" class="px-3">
                Reset
            </button>
        </div>

    </div>

</kendo-dialog>

<kendo-dialog *ngIf="Updateopened" (close)=" onDialogClose('update')" [width]="920">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
           {{updatedTitle}}
        </div>
    </kendo-dialog-titlebar>
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab title="Registration" [selected]="true">
            <ng-template kendoTabContent>
                <div class="py-4">
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Registration
                                No/Reference
                                No.
                                <span class="required">*</span></label>
                            <div style="width:100%">
                                <input kendoTextBox [(ngModel)]="licenseRegForm.registrationNo" [disabled]="ReferenceNoDisable" class="form-control" />
                                <kendo-formerror *ngIf="error.registrationNo">*Required</kendo-formerror>
                            </div>
                        </div>
                        <div class="form-group col-5">
                            <label>License No.</label>
                            <input kendoTextBox [(ngModel)]="licenseRegForm.licenseNo" class="form-control" />
            
                            <!-- <kendo-formerror *ngIf="validiationRequired.serviceType">*Required</kendo-formerror> -->
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>License and Certificate Description</label>
                            <input kendoTextBox [(ngModel)]="licenseRegForm.description" class="form-control" />
                            <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                        </div>
                        <div class="form-group col-5">
                            <label>Category<span class="required">*</span></label>
                            <div style="width:100%">
                                <kendo-dropdownlist [data]="categoryDropDown" [textField]="'name'" [valueField]="'id'"
                                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.categoryId"
                                    (valueChange)="categoryValueChange($event)" ngDefaultControl>
                                </kendo-dropdownlist>
                                <kendo-formerror *ngIf="error.categoryId">*Required</kendo-formerror>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isCompany">
                        <div class="form-row">
                            <div class="form-group col-5">
                                <label>Company Type</label>
                                <kendo-dropdownlist [data]="companyType" [textField]="'name'" [valueField]="'id'"
                                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.companyTypeId"
                                    (valueChange)="companyTypeChange($event)" ngDefaultControl>
                                </kendo-dropdownlist>
                                <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                            </div>
                            <div class="form-group col-5">
                                <label>Contractor and Vendor Reg No/Facility Code<span class="required">*</span></label>
                                <kendo-dropdownlist [data]="contractorVenDropDown" [textField]="'registrationNo'"
                                    [valueField]="'id'" [valuePrimitive]="true" class="form-control"
                                    (valueChange)="contaractorValueChange($event)" [(ngModel)]="licenseRegForm.contractorVendorId"
                                    ngDefaultControl>
                                </kendo-dropdownlist>
                                <!-- <kendo-formerror *ngIf="validiationRequired.status">*Required</kendo-formerror> -->
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-5">
                                <label>Contractor company name/ facility name </label>
                                <input kendoTextBox [(ngModel)]="facilityName" disabled class="form-control" />
                                <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                            </div>
                            <div class="form-group col-5">
                            </div>
            
                        </div>
                    </div>
                    <div class="personal" *ngIf="isPersonal">
                        <div class="form-row">
                            <div class="form-group col-5">
                                <label>Staff Name</label>
                                <kendo-dropdownlist [data]="staffDropDown" [textField]="'staffName'" [valueField]="'id'"
                                    [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.staffId"
                                    (valueChange)="staffNameChange($event)" ngDefaultControl>
                                </kendo-dropdownlist>
                                <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                            </div>
                            <div class="form-group col-5">
                                <label>Designation <span class="required">*</span></label>
                                <input kendoTextBox [(ngModel)]="designationName" disabled class="form-control" />
                                <!-- <kendo-formerror *ngIf="validiationRequired.status">*Required</kendo-formerror> -->
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-5">
                                <label>
            
                                    Company Name
            
                                </label>
                                <input kendoTextBox [(ngModel)]="companyName" disabled class="form-control" />
                                <!-- <kendo-formerror *ngIf="validiationRequired.checklist">*Required</kendo-formerror> -->
                            </div>
                            <div class="form-group col-5">
                            </div>
            
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Issuing Body</label>
                            <input kendoTextBox class="form-control" [(ngModel)]="licenseRegForm.issuingBody" />
                            <!-- <kendo-formerror *ngIf="validiationRequired.year">*Required</kendo-formerror> -->
                        </div>
                        <div class="form-group col-5">
                            <label>Service Type</label>
                            <kendo-dropdownlist [data]="serviceDropDown" [textField]="'NAME'" [valueField]="'ID'"
                                [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.serviceTypeId"
                                ngDefaultControl>
                            </kendo-dropdownlist>
                            <!-- <kendo-formerror *ngIf="validiationRequired.freq">*Required</kendo-formerror> -->
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Competency</label>
                            <kendo-dropdownlist [data]="compentencyDropDown" [textField]="'NAME'" [valueField]="'ID'"
                                [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.competencyId"
                                ngDefaultControl>
                            </kendo-dropdownlist>
                            <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
                        </div>
                        <div class="form-group col-5">
                            <label>Facility</label>
                            <kendo-dropdownlist [data]="facilityDropDown" [textField]="'NAME'" [valueField]="'ID'"
                                [valuePrimitive]="true" class="form-control" [(ngModel)]="licenseRegForm.facilityId"
                                ngDefaultControl>
                            </kendo-dropdownlist>
                            <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
                        </div>
                    </div>
            
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Class/Grade</label>
                            <input kendoTextBox [(ngModel)]="licenseRegForm.grade" class="form-control" />
                            <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
                        </div>
                        <div class="form-group col-5">
                            <label>License Issuing
                                Date</label>
                            <kendo-datepicker [(ngModel)]="licenseRegForm.licenseIssuingDate"  [format]="format" ngDefaultControl>
                            </kendo-datepicker>
                            <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
                        </div>
                    </div>
            
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Expiry Date</label>
                            <kendo-datepicker [(ngModel)]="licenseRegForm.expiryDate"  [format]="format" ngDefaultControl>
                            </kendo-datepicker>
                            <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
                        </div>
                        <div class="form-group col-5">
                            <label>Notification for
                                Inspection
                            </label>
                            <kendo-datepicker [(ngModel)]="licenseRegForm.notificationForInspection"   [format]="format" [disabled]="notiDateDisabled" >
                            </kendo-datepicker>
                            <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
                        </div>
                    </div>
            
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Inspection
                                Conducted On</label>
                            <kendo-datepicker [(ngModel)]="licenseRegForm.inspectionConductedOn"  [format]="format" ngDefaultControl>
                            </kendo-datepicker>
                            <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
                        </div>
                        <div class="form-group col-5">
                            <label>Next Inspection
                                Date
                            </label>
                            <kendo-datepicker [(ngModel)]="licenseRegForm.nextInspectionDate"   [format]="format"ngDefaultControl>
                            </kendo-datepicker>
                            <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-5">
                            <label>Remark</label>
                            <input kendoTextBox [(ngModel)]="licenseRegForm.remark" class="form-control" />
                            <!-- <kendo-formerror *ngIf="validiationRequired.firstDate">*Required</kendo-formerror>           -->
                        </div>
                        <div class="form-group col-5">
            
                            <!-- <kendo-formerror *ngIf="validiationRequired.lastDate">*Required</kendo-formerror> -->
                        </div>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton primary="true" class="mr-2 px-3" (click)="onSubmit()">
                          Update
                        </button>
                        <button *ngIf="type!='update'" kendoButton (click)="onReset()" primary="true" class="px-3">
                            Reset
                        </button>
                    </div>
            
                </div>
                
            </ng-template>
        </kendo-tabstrip-tab>
        <!-- <kendo-tabstrip-tab title="PPM Page">
                <ng-template kendoTabContent>
                    <app-ppm-page></app-ppm-page>
                </ng-template>
                </kendo-tabstrip-tab> -->
        <kendo-tabstrip-tab title="Asset">
            <ng-template kendoTabContent>
                <div class="card m-3">
                    <div class="card-header" >
                        <h5>Asset</h5>
                        <div>      
                          <button kendoButton  primary="true" (click)="openRoleDailog('newAsset')" class="header-right-btn">
                               Add Asset
                          </button>
                        </div>
                    </div>
                    <div class="card-body register-form-body">
                     
                        <app-license-list-grid  [data]="assetData"  [column]="contectColumn" [size]="5" [editFlag]="true" [deleteFlag]="true">
                        </app-license-list-grid>
                    </div>
                  </div>
                  <kendo-dialog *ngIf="tabTemplateOpened" (close)="onDialogClose('tabType')" [width]="1000">
                    <kendo-dialog-titlebar>
                      <div style="font-size: 18px; line-height: 1.3em;">
                     {{assetTitle}}
                      </div>
                    </kendo-dialog-titlebar>
                <div class="card m-3">
                    <div class="card-body">
                        <app-contact-asset-grid [data]="contectGrid" [column]="contectColumn" [size]="10"
                            [deleteFlag]="false" (valueChange)="contectGrid=$event"
                            (licenseassetValue)="getValueOfAsset($event)">

                        </app-contact-asset-grid>
                    </div>
                </div>
                <div class="py-3" style="text-align: center;width: 100%;">
                    <button kendoButton *ngIf="type === 'newAsset'" primary="true" class="mr-2 px-3" (click)="addAsset()">
                        Save
                    </button>
                    <!-- <button kendoButton  *ngIf="type === 'updateAsset'" primary="true" class="mr-2 px-3" (click)="updateAsset()">
                        Update
                    </button> -->

                </div>
                  </kendo-dialog>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Non-Asset">
              
                
            <ng-template kendoTabContent>
                <div class="card m-3">
                    <div class="card-header" >
                        <h5>Non-Asset</h5>
                        <div>      
                          <button kendoButton  primary="true" (click)="openRoleDailog('newnonAsset')" class="header-right-btn">
                           Add Non-Asset
                          </button>
                        </div>
                    </div>
                    <div class="card-body register-form-body">
                     
                        <app-license-list-grid  [data]="nonAssetData" [column]="NonAssetColumn" [size]="5"  [editFlag]="true"  [deleteFlag]="true">
                        </app-license-list-grid>
                    </div>
                  </div>
                  <kendo-dialog *ngIf="tabTemplateOpened" (close)="onDialogClose('tabType')" [width]="1000">
                    <kendo-dialog-titlebar>
                      <div style="font-size: 18px; line-height: 1.3em;">
                        {{assetTitle}}
                      </div>
                    </kendo-dialog-titlebar>

                <div class="card m-3">
                    <div class="card-body">
                        <app-contact-asset-grid [data]="nonAssetGrid" [column]="NonAssetColumn" [size]="10"
                            [deleteFlag]="false" (valueChange)="nonAssetGrid=$event"
                            (licenseassetValue)="getNonAsset($event)">

                        </app-contact-asset-grid>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button *ngIf="type === 'newnonAsset'" kendoButton primary="true" (click)="addNonAsstValue()" class="mr-2 px-3">
                            Save
                        </button>
                        <!-- <button kendoButton *ngIf="type === 'updatenonAsset'" primary="true" class="mr-2 px-3" (click)=" updateNonAsset()">
                            Update
                        </button> -->
                    </div>
                </div>
                </kendo-dialog>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Person In Charge">
            <ng-template kendoTabContent>
                <div class="card m-3">
                    <div class="card-header" >
                        <h5>Person In Charge</h5>
                        <div>      
                          <button kendoButton  primary="true" (click)="openRoleDailog('newPIC')" class="header-right-btn">
                               Add Person In Charge
                          </button>
                        </div>
                    </div>
                    <div class="card-body register-form-body">
                     
                        <app-license-list-grid  [data]="personInChargeData" [column]="personInChargeColumn" [size]="5" [editFlag]="true"  [deleteFlag]="true">
                        </app-license-list-grid>
                    </div>
                  </div>
                

                <kendo-dialog *ngIf="tabTemplateOpened" (close)="onDialogClose('tabType')" [width]="1000">
                    <kendo-dialog-titlebar>
                      <div style="font-size: 18px; line-height: 1.3em;">
                        {{assetTitle}} 
                      </div>
                    </kendo-dialog-titlebar>
                <div class="card m-3">
                    <div class="card-header">
                        <h5></h5>
                    </div>

                    <div class="card-body">
                        <app-contact-asset-grid [data]="personInChargeGrid" [column]="personInChargeColumn" [size]="10"
                            [deleteFlag]="false" (valueChange)="personInChargeGrid=$event"
                            (licenseassetValue)="personchargeValue($event)">

                        </app-contact-asset-grid>
                    </div>
                    <div class="py-3" style="text-align: center;width: 100%;">
                        <button kendoButton *ngIf="type === 'newPIC'" (click)="addPersonInCharge()" primary="true" class="mr-2 px-3">
                            Save
                        </button>
                        <!-- <button kendoButton   *ngIf="type === 'updatePIC'" (click)="updatePersonInCharge()" primary="true" class="mr-2 px-3">
                           Update
                        </button> -->
                        <!-- <button kendoButton primary="true" (click)="picreset()" class="mr-2 px-3">
                            Reset
                        </button>
                        <button kendoButton primary="true" class="mr-2 px-3">
                            Delete
                        </button> -->

                    </div>

                </div>
                </kendo-dialog>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Attachments">
            <ng-template kendoTabContent>
                <app-attachments [attachLicenseId] ="licenseId" [licenseFormat]="licenseImage" ></app-attachments>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="History">
            <ng-template kendoTabContent>
                <app-table-grid [data]="historyGridData" [column]="Historycolumn" [size]="10" [action]="false">
                </app-table-grid>
            </ng-template>
        </kendo-tabstrip-tab>
        <!-- <kendo-tabstrip-tab title="Work Order Reassign">
            <ng-template kendoTabContent>
                <app-work-order-reassign></app-work-order-reassign>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Spare Part Replacemet">
            <ng-template kendoTabContent>
                <app-spare-part-replacement></app-spare-part-replacement>
            </ng-template>
        </kendo-tabstrip-tab> -->
        <!-- <kendo-tabstrip-tab title="Attachments">
            <ng-template kendoTabContent>
                <app-work-order-attactments></app-work-order-attactments>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Completion Info">
            <ng-template kendoTabContent>
                <app-completeion-info></app-completeion-info>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Work Order Inquiry">
            <ng-template kendoTabContent>
                <app-work-order-inquiry></app-work-order-inquiry>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Overall Gridview">
            <ng-template kendoTabContent>
                <app-overall-grid-view></app-overall-grid-view>
            </ng-template>
        </kendo-tabstrip-tab> -->
    </kendo-tabstrip>
</kendo-dialog>

<kendo-dialog *ngIf="removeDialogOpen" (close)="removeDialogOpen=false" [width]="500">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            Delete Description
        </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button *ngIf = "type=='remove'" kendoButton (click)="removeLicense()">Delete</button>
        <button kendoButton *ngIf="type === 'removeWIthTab'" (click)="removeTabLicense()">Delete</button>
    </kendo-dialog-actions>
</kendo-dialog>
<div class="row mb-3"></div>
</div>

<div  *ngIf="!isAccess">

    <div class="dashboard-content"> 
      <div class="content-wrapper">
        <div class="page-wrapper">
  <h3 style="color: #fff;text-align: center;">You don’t have the access rights to view this page</h3>
  
        </div>
      </div>
    </div>
  
  </div>