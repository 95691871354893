import { ContractorVendor, ContractorVendorError } from "src/app/models/contractor-vendor.model";
export let ContractorValidation = (data: ContractorVendor, type) => {
    let error = new ContractorVendorError(false)
    if (type === "init")
        return error;
    if (!data. name) {
        error.name = true;
    }
    if (!data.registrationNo) {
        error.registrationNo = true
    }
    if (!data.address1) {
        error. address1 = true;
    }
    if (!data.postCode) {
        error.postCode = true
    }
    if (!data.phoneNo) {
        error.phoneNo = true;
    }
    if (!data.noOfUserAccess) {
        error.noOfUserAccess = true
    }
    if (!data.remarks) {
        error.remarks = true
    }
    if (!data.designation) {
        error.designation = true
    }
    if (!data.contactpersonName) {
        error.contactpersonName = true
    }
    if (!data.email) {
        error.email = true
    }
    if (!data.bpmtypeId) {
        error.bpmtypeId = true
    }
    if (!data.stateId) {
        error.stateId = true
    }
    if (!data.countryId) {
        error.countryId = true
    }
    return error;
}