<div class="card m-3"   >
    <!-- <h5 class="card-header">Blocks</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Facility Registration</h5>
        <div>
          <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="downloadTemplate()" class="header-right-btn" style="margin-right: 0.5rem;">
            Download Template 
        </button>
        <button kendoButton primary="true" *ngIf="permission && permission.isCreate" (click)="importopened=true" class="header-right-btn" style="margin-right: 0.5rem;">
           Import
        </button> 
          <button kendoButton  primary="true" *ngIf="permission && permission.isCreate" (click)="openRoleDailog('new')" class="header-right-btn">
            <span class="add_btn">+</span> Facility Registration
          </button>
        </div>
    </div>
    <div class="card-body register-form-body"  >
     
        <app-table-grid  
        [data]="gridData"
        [column]="column"
        [size]="10"
        [deleteFlag]="false"
        [fileFlag]="true"
        [editFlag]="permission && permission.isUpdate"

      ></app-table-grid>
    </div>
</div>

<kendo-dialog *ngIf="opened" (close)="onDialogClose('new')" [width]="1024">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
         {{dailogTitle}}
      </div>
    </kendo-dialog-titlebar>
    
    <div class="card-body register-form-body">
  
          <div class="form-row">
              <div class="form-group col-5">
                  <label>Service<span class="required">*</span></label>
                  <div style="width:100%">
                  <kendo-multiselect
                    [data]="service"
                    [value]="selectedServiceFlag"
                    textField="name"  valueField="id" 
                    (valueChange)="selectedServiceFlag=$event"
                   ></kendo-multiselect >
                   <kendo-formerror *ngIf="error.facilitySkills">*Required</kendo-formerror>
              </div>
            </div>
          <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                  <label>Facility Name<span class="required">*</span></label>
                  <div style="width:100%">
                  <input kendoTextBox    [(ngModel)]="facilityForm.name" class="form-control"/>
                  <kendo-formerror *ngIf="error.name">*Required</kendo-formerror>
              </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group col-5">
                  <label>Facility Code<span class="required">*</span></label>
                  <div style="width:100%">
                  <input kendoTextBox  [(ngModel)]="facilityForm.facilityCode" class="form-control"/>
                  <kendo-formerror *ngIf="error.facilityCode">*Required</kendo-formerror>
                  </div>
              </div>
            <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                  <label>Address 1<span class="required">*</span></label>
                  <div style="width:100%">
                  <input kendoTextBox  class="form-control"  [(ngModel)]="facilityForm.address1"/>
                  <kendo-formerror *ngIf="error.address1">*Required</kendo-formerror>
                  </div>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group col-5">
                  <label>Address 2<span class="required">*</span></label>
                  <div style="width:100%">
                  <input kendoTextBox  class="form-control" [(ngModel)]="facilityForm.address2"/>
                  <kendo-formerror *ngIf="error.address2">*Required</kendo-formerror>
                  </div>
              </div>
            <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                  <label>Post Code<span class="required">*</span></label>
                  <div style="width:100%">
                  <input kendoTextBox  class="form-control"  [(ngModel)]="facilityForm.postCode"/>
                  <kendo-formerror *ngIf="error.postCode">*Required</kendo-formerror>
                  </div>
              </div>
          </div>
          <div class="form-row">
            <div class="form-group col-5">
              <label>Country<span class="required">*</span></label>
              <div style="width:100%">
              <kendo-dropdownlist 
                  [data]="countryDropDown" 
                  [(ngModel)]="facilityForm.countryId" 
                  (valueChange)="valuechange($event)" 
                  class="form-control"
                  [textField]="'NAME'" 
                  [valueField]="'ID'" 
                  [valuePrimitive]="true"
                  >
               </kendo-dropdownlist>
          <kendo-formerror *ngIf="error.countryId">*Required</kendo-formerror>
          </div>
          </div>
            
            <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                <label>State<span class="required">*</span></label>
                <div style="width:100%">
                <kendo-dropdownlist [data]="stateDropDown" [(ngModel)]="facilityForm.stateId" class="form-control"
                [textField]="'NAME'" [valueField]="'ID'" [valuePrimitive]="true">
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="error.stateId">*Required</kendo-formerror>
            </div>
            </div>
          </div>
          <div class="form-row">
              <div class="form-group col-5">
                  <label>Phone No.</label>
                  <input kendoTextBox  class="form-control"  [(ngModel)]="facilityForm.phoneNo" maxlength="11"/>
                  </div>
            <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                  <label>Fax No.</label>
                  <input kendoTextBox  class="form-control"  [(ngModel)]="facilityForm.faxNo"/>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group col-5">
                  <label>Active From</label>
                  <kendo-datetimepicker [(ngModel)]="facilityForm.activeFrom"> </kendo-datetimepicker>
                  <!-- <kendo-datepicker [(value)]="value"></kendo-datepicker> -->
              </div>
            <!-- </div>
            <div class="form-row"> -->
              <div class="form-group col-5">
                  <label>Active To</label>
                  <kendo-datetimepicker  [(ngModel)]="facilityForm.activeTo"> </kendo-datetimepicker>
                  <!-- <kendo-datepicker [(value)]="value2"></kendo-datepicker> -->
              </div>
          </div>
          <div class="form-row">
            <div class="form-group col-5">
                <label>Logo</label>
                <app-file-upload
                   type="img" 
                   [formate]="['jpg','png']"
                   [url]="facilityForm.logo" 
                   (valueChange)="facilityForm.logo=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
                <!-- <kendo-upload [autoUpload]="false"  [(ngModel)]="facilityForm.logo" >
                  <ng-template kendoUploadFileInfoTemplate let-files>
                      <div>Name: {{ files[0].name }}</div>
                  </ng-template>
                </kendo-upload> -->
            </div>
              <div class="form-group col-5">
                  <label>Email</label>
                  <div style="width: 100%">
                  <input kendoTextBox   [(ngModel)]="facilityForm.email" class="form-control" />
                  
                   <kendo-formerror *ngIf="error.email">This is not valid email id</kendo-formerror>
                  
                </div>
            </div>
          </div>
          <div class="form-row p-4">
              <app-contact-person-grid
              [data]="contectGrid"
              [column]="contectColumn"
              [size]="10"
              [deleteFlag]="false"
              (valueChange)="contectGrid=$event"
              >

              </app-contact-person-grid>
           </div>
           <div class="form-row" style="height:80px">
          </div>
        </div>

          <kendo-dialog-actions [layout]="actionsLayout">
              <button kendoButton (click)="onDialogClose('new')">Cancel</button>
              <button *ngIf="type=='new'" (click)="onReset()" kendoButton primary="true">
                Reset
            </button>
              <button *ngIf="type=='new'" (click)="onSubmit()" kendoButton primary="true">
                Save
            </button>
            <button  *ngIf="type=='update'" (click)="onSubmit()" kendoButton primary="true">
                Update
            </button>
          </kendo-dialog-actions>
  </kendo-dialog>
  <kendo-dialog *ngIf="removeDialogOpen" (close)="onDialogClose('remove')" [width]="500">
    <kendo-dialog-titlebar>
      <div style="font-size: 18px; line-height: 1.3em;">
        Delete Description
      </div>
    </kendo-dialog-titlebar>
    <p>Are you sure you want to delete?</p>
    <kendo-dialog-actions [layout]="actionsLayout">
      <button kendoButton (click)="removeFacility()">Yes</button>
      <button kendoButton  (click)="onDialogClose('remove')">No</button>
    </kendo-dialog-actions>
  </kendo-dialog>
  
  <kendo-dialog *ngIf="attachmentOpened" (close)="attachmentOpened=false" [width]="800">
    <kendo-dialog-titlebar>
        <div style="font-size: 18px; line-height: 1.3em;">
            {{dailogTitle}}
        </div>
    </kendo-dialog-titlebar>
    
        <div class="form-row">
            <div class="form-group col-5">
                <label>File Type</label>
                <kendo-dropdownlist 
                    [data]="documentFormate" 
                    class="form-control" 
                    [textField]="'name'" 
                    [valueField]="'id'"
                    [(ngModel)]="attechForm.FILETYPE"
                    [valuePrimitive]="true"
                >
                </kendo-dropdownlist>
            </div>
            <div class="form-group col-5">
                <label>File Name</label>
                <div style="width: 100%;">
                <input kendoTextBox [(ngModel)]="attechForm.FILENAME" class="form-control" />
                <!-- <kendo-formerror *ngIf="attechError.name">*required</kendo-formerror> -->

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Attachment</label>
                <div style="width: 100%;">
                <app-file-upload
                   type="doc" 
                   [formate]="[attechForm.FILETYPE]"
                   [url]="attechForm.URL" 
                   (valueChange)="attechForm.URL=$event" 
                   style="width: 100%;"
                >
              </app-file-upload>
              <!-- <kendo-formerror *ngIf="attechError.url">*required</kendo-formerror> -->
              </div>
            </div>

            <div class="form-group col-5">
                <button  (click)="addAttechment()" kendoButton primary="true">
                    Add
                </button>
            </div>


        </div>
    <kendo-dialog-actions [layout]="actionsLayout">
        <button kendoButton (click)="attachmentOpened=false">Cancel</button>
        <!-- <button (click)="saveAttechment()" kendoButton primary="true">
            Save
        </button> -->
    </kendo-dialog-actions>
    <app-attachment-table-grid 
         [data]="attechmentDoc.alldata" 
         [column]="column2" 
         [size]="10"
         >
   </app-attachment-table-grid>

</kendo-dialog>


<kendo-dialog *ngIf="importopened" (close)="importopened=false" [width]="580">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
       {{dailogTitle}}
    </div>
  </kendo-dialog-titlebar>

      <div class="form-row">
           <div class="form-group col-6">
              <label>Attachment</label>
              <import-attechment
                 type="doc" 
                 [formate]="['xlsx']" 
                 [fileUploadType]=3
                 (valueChange)="refreshData($event)"
                 style="width: 100%;"
              >
            </import-attechment>
          </div>
</div>

        
  <kendo-dialog-actions [layout]="actionsLayout">
    <button kendoButton (click)="importopened=false">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>