import { Component, OnChanges, OnInit } from '@angular/core';
import { calender,CalenderError } from 'src/app/models/calender.model';
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from 'src/Util/ApiHandling';
import { getCookie } from 'src/Util/Util';
import { userid } from 'src/Util/const';
import { NotificationService } from "@progress/kendo-angular-notification";
import {CalenderValidation} from "src/validator/calender"
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent {

  public onTabSelect(e) {
  }
  public opened = false;
  public permission:Permission=new Permission();
  public type = "";
  public actionsLayout = "normal";
  public dailogTitle = "";
  public statusObj = {};
  public removeDialogOpen = false;
  public removeDialogOpen1 = false;
  public viewDialogOpen = false;
  public minDate = new Date();
  public maxDate = new Date();
  public yearVal;
  public selectedRowArray = [];
  public holidayRemoveId;
  public calenderFormData: calender = new calender();
  public holidayListHide = false;
  public checked = true;
  public disabled = false;
  public holidayGrid =[]
  public monthDayList=[
    {full:"January",short:"Jan",day:"Sun"},
    {full:"February",short:"Feb",day:"Mon"},
    {full:"March",short:"Mar",day:"Tue"},
    {full:"April",short:"Apr",day:"Wed"},
    {full:"May",short:"May",day:"Thr"},
    {full:"June",short:"Jun",day:"Fri"},
    {full:"July",short:"Jul",day:"Sat"},
    {full:"Augest",short:"Aug",day:"Sun"},
    {full:"September",short:"Sep",day:""},
    {full:"October",short:"Oct",day:""},
    {full:"November",short:"Nov",day:""},
    {full:"December",short:"Dec",day:""},


  ]
  public column = [
    { field: "name", type: "text", title: "Holiday Name List", isFilter: true },
    { field: "date", type: "date", title: "Date" }
  ]

  public viewColumnArray = [
    { field: "name", type: "text", title: "Holiday Name List", isFilter: true },
    { field: "year",  title: "Year",width:120 },
    { field: "day",  title: "Day",width:120 },
    { field: "dateString", title: "Date",width:150 },

  ]
  public yearDropDown = [
    { name: 2020, id: 1 },
    { name: 2021, id: 2 },
    { name: 2022, id: 3 },
    { name: 2023, id: 4 },
    { name: 2024, id: 5 },
    { name: 2025, id: 6 },
    { name: 2026, id: 7 },
    { name: 2027, id: 8 },
    { name: 2028, id: 9 },
    { name: 2029, id: 10 },
    { name: 2030, id: 11 },
    { name: 2031, id: 12 },
  ]
  public column2 = [
    { field: "name", title: "Calendar Name List", isFilter: true },
    { field: "year", title: "Year", isFilter: true },
    { field: "remark", title: "Remark", isFilter: true }
  ]
  public error:CalenderError=CalenderValidation(this.calenderFormData,"init"); 
  public calenderGridData = [];
  public holidayGridData = [];
  public globalHolidayGrid:any=[]; 
  public globalHolidayObj={};
  public holidayViewGrid=[]
  submitted = false;
  public status: any;
  statusData = [{ id: 1, name: "Active" },
  { id: 2, name: "Inactive" }];
  constructor(
    private _notif: NotificationService
  ) {
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.remove_column, (e) => { this.editColumn(e) })
    eventListner(EVENT_NAME.attach_holiday_column, (e) => { this.holidayColumn(e) })
    eventListner(EVENT_NAME.view_column, (e) => { this.viewColumn(e) })
    }

  ngOnInit() {
    if(UserModuleObj.working_calendar){
      this.permission=UserModuleObj.working_calendar;
    }
    this.getCalenderList();
  }

  onDialogClose(type) {
    if (type == "new") {
     
      this.opened = false;
    }
    if (type = "remove") {
      this.removeDialogOpen = false;
    }
    if (type = "view") {
      this.viewDialogOpen = false;
    }

  }

  openRoleDailog(type) {
    this.type = type;
    if (type == "new") {
      this.holidayListHide = false;
      this.calenderFormData = new calender();
      this.dailogTitle = "Add Calendar"
    }
    if (type == "update") {
      this.dailogTitle = "Edit Calendar"
    }
    this.opened = true;
  }

  onReset(){
    this.openRoleDailog("new")
   // this.calenderFormData = new calender();
  }
  editColumn(data) {
    if (data.action == "edit") {
      this.opened = true;
      this.disabled = true;
      this.openRoleDailog("update");
        this.getHolidayCalenderByID(data.dataItem)
      .then((result:any)=>{
      
  let unassignHoliday=this.globalHolidayGrid.filter((d)=>{
                 return result.holidayCalendars.filter((f)=>f.holidayId==d.holidayId).length==0
              })
              unassignHoliday=unassignHoliday.map((d)=>{
                d["calendarId"]=data.id;
                return d;
              })
              this.calenderFormData.name = data.dataItem["name"];
              this.calenderFormData.id = data.dataItem["id"];
              this.calenderFormData.year = data.dataItem["year"];
              this.calenderFormData.remark=data.dataItem["remark"] || ""
              this.holidayListHide = true;
              this.holidayGridData=[...result.holidayCalendars,...unassignHoliday];  
              this.manuculateDate(result.year)
      })
    }
    else if (data.action == "remove") {
      this.calenderFormData = data.dataItem
      this.removeDialogOpen = true;
    }
  }
  getHolidayCalenderByID(data){
    return new Promise((resolve,reject)=>{
      if(false && data.holidayCalendars){
         resolve(data);
      }
      else{
          HttpClient.get("/api/MasterData/GetCalendarById?id=" + data.id, true, true)
          .then((result:any)=>{
            result.holidayCalendars=result.holidayCalendars.map((d)=>{
              d["name"]=this.globalHolidayObj[d.holidayId];
              d["date"]= new Date(d.date);
              return d;
            })
         
            let index=this.calenderGridData.reduce((acc,d,i)=>{
              if(d.id==result.id){
                acc=i;
              }
            },-1)
            if(index>=0){
              this.calenderGridData[index]=result;
            }
            resolve(result);
          })
     }
    })
  }

  getHolidayData(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`, true)
        .then((result: any) => {
          let holidayData = [];
          holidayData = JSON.parse(result.gridData);
          holidayData = holidayData.map((d) => {
            d["value"] = null;
            return d;
          });
          resolve(holidayData)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  onValueChange(yearname) {
    this.manuculateDate(yearname);
  }

  manuculateDate(year) {
    this.minDate.setDate(1);
    this.minDate.setMonth(0);
    this.minDate.setFullYear(year);
    this.maxDate.setDate(0);
    this.maxDate.setMonth(11);
    this.maxDate.setFullYear(year);
    if(this.type=="new"){
      this.holidayGridData=JSON.parse(JSON.stringify(this.globalHolidayGrid))
    }
    
       
  }

  holidayColumn(holidaydata) {
    this.holidayGridData=holidaydata;
  }

  pading(n){
    if(n>9)
      return n;
    else
     return "0"+n
  }

  dateFormating(da){
    let df=new Date(da);
    return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short}-${df.getFullYear()}`
  }


  onSubmit() {
    let holidaList=JSON.parse(JSON.stringify(this.holidayGridData));
     holidaList=holidaList.filter((d)=>d.date);
     holidaList=holidaList.map((d)=>{
      let ndate=new Date(d.date);
      this.calenderFormData.createdBy = parseInt(getCookie(userid))
      this.calenderFormData.modifiedBy = parseInt(getCookie(userid))
      this.calenderFormData.createdDate = new Date();
      this.calenderFormData.modifiedDate = new Date();
      this.calenderFormData.id = this.type=='new'?0:this.calenderFormData.id;
      this.calenderFormData.loggedInUserId = parseInt(getCookie(userid))
      //ndate.setDate(ndate.getDate()+1)
      // ndate.setHours(0)
      // ndate.setMinutes(ndate.getDate()-330)
      // ndate.setSeconds(0)
      d["date"]=`${ndate.getFullYear()}-${this.pading(ndate.getMonth()+1)}-${this.pading(ndate.getDate())}T00:00:00`
      return d;
    })
    const params = {
      ...this.calenderFormData,
      holidayCalendars:holidaList
    }
    this.error=CalenderValidation(params,"");
    if(this.error.name || this.error.year || this.error.holidayCalendars)
      return ;
  if (this.type == "new") {
     
      HttpClient.fetchData("/api/MasterData/AddCalendar", "post", params, true, true).then((result: any) => {
        
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Calendar",this.type+" Calendar",this.type+" Calendar").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this.calenderFormData=new calender()
          this.holidayGridData=[]
          this.getOnlyCalander()
          // params["id"]=result.id;
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.minDate.setFullYear(null);
          this.maxDate.setFullYear(null);
          // this.calenderGridData.unshift(params)
          // this.calenderGridData = JSON.parse(JSON.stringify(this.calenderGridData));
          this.opened = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }

    if (this.type == "update") {
      HttpClient.fetchData("/api/MasterData/UpdateCalendar", "post", params, true, true).then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Calendar",this.type+" Calendar",this.type+" Calendar").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.calenderFormData=new calender()
       
          this.getOnlyCalander()
          // let index=this.calenderGridData.reduce((acc,d,i)=>{
          //   if(d.id==this.calenderFormData.id){
          //     acc=i;
          //   }
          //   return acc;
          // },-1)
          // if(index>=0){
          //   this.calenderGridData[index]=params;
          //   this.calenderGridData=[...this.calenderGridData]
          // }

          this.manuculateDate(null);
          this.opened = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      })
    }

  }

  getCalenderList() {
    Promise.all([
      this.getHolidayData("dbo", "holiday"),
      HttpClient.get("/api/MasterData/GetCalendars", true, true)
    ])
    .then((result: any) => {
      this.calenderGridData = result[1];
      this.globalHolidayGrid=result[0].map((d)=>{
        this.globalHolidayObj[d.ID]=d.NAME;
          return  {
            "loggedInUserId": 0,
            "id": 0,
            "calendarId": 0,
            "holidayId": d.ID,
            "holidayName": d.ID,
            "date": null,
            "dateString":null,
            "name":d.NAME
          }
      });
    }).catch((err) => {
      return err;
    });
  }
  getOnlyCalander(){
    HttpClient.get("/api/MasterData/GetCalendars", true, true)
    .then((result:any)=>{
        this.calenderGridData=[...result]
    })
    .catch((err)=>{
     
    })
  }

  removeCalender() {
    HttpClient.fetchData("/api/MasterData/DeleteCalendarById", "post", this.calenderFormData, true, true)
      .then((result: any) => {
        if (result.isValid == true) {
          HttpClient.insertAduitLog("Calendar","Delete Calendar","Delete Calendar").then((res:any)=>{
            if (res.isValid == true) {
  
            }
          })
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          this.calenderGridData = this.calenderGridData.filter(x => {
            return x.id != this.calenderFormData.id;
          })
          this.calenderGridData = JSON.parse(JSON.stringify(this.calenderGridData));
          this.removeDialogOpen = false;
        }
        else {
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
        }
      }).catch((error) => {
      });
  }

  viewColumn(data) {
    this.viewDialogOpen = true;
    this.getHolidayCalenderByID(data)
    .then((result:any)=>{
          this.holidayViewGrid=result.holidayCalendars
          this.holidayViewGrid=this.holidayViewGrid.map((d)=>{
            let da=new Date(d.date);
            let dateString=this.monthDayList[da.getMonth()].short+" "+da.getDate()
            return {
              name:d.name,
              year:data.year,
              day:this.monthDayList[da.getDay()].day,
              dateString:dateString
            }
          })
    })
    // this.getHolidayData("dbo", "holiday").then((e) => {
    //   HttpClient.get("/api/MasterData/GetCalendarById?id=" + data.id, true, true)
    //     .then((result: any) => {
    //       const data = result;
    //       let holidayid = result.holidayCalendars.map((e) => {
    //         return e;
    //       })
    //       let holidayGridDataVal = [];
    //       let holidayVal;
    //       let dataValue = [];
    //       holidayid.forEach((e) => {
    //         dataValue.push(e.dateString);
    //         holidayVal = this.holidayGridData.filter((d) => {
    //           return d.ID === e.holidayId;
    //         })
    //         holidayGridDataVal.push(holidayVal);
    //       })
    //       for (let i = 0; i < dataValue.length; i++) {
    //         holidayGridDataVal[i].map((e => {
    //           e["value"] = dataValue[i];
    //           return e;
    //         }));
    //       }
    //       let HolidayGriddataByID = holidayGridDataVal.map((e) => {
    //         return e[0];
    //       })
    //       this.holidayGridData = HolidayGriddataByID;
    //     })
    // })
  }

}


