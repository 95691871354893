import { Component, EventEmitter, Input, OnInit,OnChanges, Output, SimpleChanges } from '@angular/core';
import HttpClient from 'src/Util/ApiHandling';
import {dispatchEvent,EVENT_NAME } from 'src/event';
import {setCookie} from "src/Util/Util"
import {authorization,userid} from "src/Util/const"
import { NotificationService } from "@progress/kendo-angular-notification";
import {User,UserObj} from "src/ApiModel/Model/User"
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnChanges {
 type="login";
 @Input() resetpass:boolean;
 @Input() token:string;
 @Input() userName:string;
 @Input() userId:string
 @Output() loginStatus=new EventEmitter<boolean>();
 public user:UserObj=new UserObj();
  constructor(private _notif:NotificationService) { }  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.resetpass){
      this.resetpass=changes.resetpass.currentValue;
    }
    if(changes.token){
      this.token=changes.token.currentValue;
    }
    if(changes.userName){
      this.userName=changes.userName.currentValue;
    }
    if(changes.userId){
      this.userId=changes.userId.currentValue;
    }
   // throw new Error('Method not implemented.');
  }
  fullname:"";
  email="";
  password="";
  password1="";
  errorMsg="";
  ngOnInit(): void {
  }
  loginUser(){
    this.errorMsg=""
    HttpClient.fetchData("/Api/users/authenticate","post",{
      "username": this.email, 
      "password": this.password
    })
    .then((result:any)=>{
      if(result.jwtToken){
        setCookie(authorization,result.jwtToken,1)
        setCookie(userid,result.userId,1)
        this.getUSer(result.userId)
        dispatchEvent(EVENT_NAME.authorization,true);
        this.loginResponce(true);
        HttpClient.insertAduitLog("Login","Login "+this.email,"Login with "+this.email).then((res:any)=>{
          if (res.isValid == true) {

          }
        })
      }
      else{
        this.errorMsg="Username or Password is Invalid"
      }
    })
    .catch((err)=>{

    })
  }
  loginResponce(status){
    //this.loginUser()
   this.loginStatus.emit(true)
  }

  updatePasswordUser(){
    if(this.password==this.password1){
    this.errorMsg=""
    HttpClient.fetchData("/api/Users/UpdatePassword","post",{
      "username": this.userName, 
      "newPassword": this.password,
      "userId":parseInt(this.userId)
    },true,true)
    .then((result:any)=>{
      if(result.message){
        this.errorMsg=result.message;
        HttpClient.insertAduitLog("UpdatePassword","UpdatePassword "+this.userName,"UpdatePassword with "+this.userName).then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this._notif.show({
          content: result.message,
          type: { style: "error", icon: true },
        });
      }
      else{
        this.resetpass=false;
      }
    // if(result.jwtToken){
    //     // setCookie(authorization,result.jwtToken,1)
    //     // setCookie(userid,result.userId,1)
    //     // dispatchEvent(EVENT_NAME.authorization,true);
    //    // this.loginResponce(true)
    //   }
    //   else{
    //     this.errorMsg="Username or Password is not Invalid"
    //   }
    })
    .catch((err)=>{

    })
  }
  else{
    this.errorMsg="Password not match"
  }
  }


  getUSer(id) {
     HttpClient.get(
        "/api/Users/GetUserById?id=" +id,
        true,
        true
      ) 
      .then((result: any) => {
              
        })
     }
  
  forgetPassword(){
      this.user.getData()
      .then((val)=>{
          let u=this.user.alldata.filter((d)=>d.companyEmail===this.email)
          if(u.length>0){
               this.unLockData(u[0]);
          }
          else{
            this.errorMsg="Email not exist."
          }
      })
      .catch((err)=>{
        console.log(err)
      })
  }
  unLockData(data:User){
    let obj={
      "userId": data.id,
      "username": data.userName,
      "userEmail": data.companyEmail,
      "passwordResetURL": window.location.origin+"/"+environment.reset_password_pathname
    }
    HttpClient.fetchData("/api/Users/ResetPassword","post",obj,true,true)
    .then((result:any)=>{
      console.log(result)
      if(result.message){
        HttpClient.insertAduitLog("ResetPassword","ResetPassword "+data.userName,"ResetPassword with "+data.userName).then((res:any)=>{
          if (res.isValid == true) {

          }
        })
        this.errorMsg=result.message
        this._notif.show({
          content:result.message,
          type: { style: "error", icon: true },
        });
      }
      else{
      this.type='login';
      this.errorMsg=''
      this._notif.show({
        content:"Reset password link successfully send to mail",
        type: { style: "success", icon: true },
      });
    }
    })
    .catch((err)=>{
      console.log(err)
      this._notif.show({
        content:"Error in sending mail.",
        type: { style: "error", icon: true },
      });
    })
  }
}
