import block from "./block"
import location from "./location"
import blocklevel from "./blocklevel"
// import levelarea from "./levelarea"

export default {
    block:{
        ...block
    },
    arealocation:{
        ...location
    },
    blocklevel:{
        ...blocklevel
    }
}