import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { NotificationService } from "@progress/kendo-angular-notification";
import { sampleProducts } from "src/app/Data/table";
import { SparePart, sparePartDetailsValue } from "src/app/models/spare.model";
import { eventListner, EVENT_NAME } from "src/event";
import HttpClient from "src/Util/ApiHandling";
import { environment } from "src/environments/environment";
import { getCookie, shiftLastDataInto1st } from "src/Util/Util";
import { userid } from "src/Util/const";
import { SparePartRequestValidation, SparePartValidation } from "src/validator/spare";
import {UserModuleObj,Permission} from "src/ApiModel/Model/UserModule"
import {Attechment,AttechmentObj} from "src/ApiModel/Model/Attechment"

@Component({
  selector: "app-spare-parts",
  templateUrl: "./spare-parts.component.html",
  styleUrls: ["./spare-parts.component.scss"],
})
export class SparePartsComponent implements OnInit {
  public opened = false;
  public removeDialogOpen = false;
  public sparePartSourceDropDow = [];
  public type = "";
  public actionsLayout = "normal";
  public permission:Permission=new Permission();
  public dailogTitle = "";
  public validate = true;
  public stockCategoryDropDow = [];
  public stockTakeDropDow = [];
  public stockTradeDropDow = [];
  public stockUOMDropDow = [];
  public LifeSpanDropDow = [];
  public statusDropDown = [];
  public facilityDropDown = [];
  public sparePartDetailsArray = [];
  public assetManufactureDropDown = [];
  public MODULEHIEARCHYID;
  public gridData = [];
  public LocDropDown =[]
  public spareTradeObj = {};
  public statusObj = {};
  public facilityObj = {};
  public lifeSpanObj = {};
  public sparePartObj = {};
  public UOMObj = {};
  public facilityCode;
  public stockCategoryObj = {};
  public attachmentOpened = false;
  public documentFormate = [
    { id: "pdf", name: "pdf" },
    { id: "jpg", name: "jpg" },
    { id: "png", name: "png" },
  ];
  public attechmentDoc=new AttechmentObj()
  public attechForm:Attechment=new Attechment();
  public attechError:{URL:boolean,FILENAME:boolean}={URL:false,FILENAME:false}
  public attechId: number = -1;
  sparePartForm: SparePart = new SparePart();
  sparePartDetailsForm: sparePartDetailsValue = new sparePartDetailsValue();
  error1 = SparePartValidation(this.sparePartForm,"init")
  error2=  SparePartRequestValidation(this.sparePartDetailsForm,"init")
  public listItems: Array<{ text: string; value: number; inStock: boolean }> = [
    { text: "1", value: 1, inStock: false },
    { text: "2", value: 2, inStock: false },
    { text: "3", value: 3, inStock: false },
    { text: "4", value: 4, inStock: false },
    { text: "5", value: 5, inStock: false },
  ];

  public column2 = [
    { field: "FILETYPE", title: "File Type", isFilter: true },
    { field: "FILENAME", title: "File Name", isFilter: true },
  ];
  public column3 = [
    { field: "spareTrade", title: "Stock Trade", isFilter: true, width: 150 },
    { field: "stockType", title: "Stock Type", isFilter: true, width: 150 },
    {
      field: "spareCategory",
      title: "Stock Category",
      isFilter: true,
      width: 150,
    },
    { field: "stockNo", title: "Stock No.", isFilter: true, width: 150 },

    { field: "partNo", title: "Part No.", isFilter: true, width: 150 },
    {
      field: "partDescription",
      title: "Part Description",
      isFilter: true,
      width: 150,
    },
    // {field:"c_7",title:"Part Attachment", isFilter:true,width:150},
    // {field:"c_8",title:"Manufacturer", isFilter:true,width:150},

    { field: "uom", title: "Unit of Measurement", isFilter: true, width: 150 },
    {
      field: "facilityName",
      title: "Facility Name",
      isFilter: true,
      width: 150,
    },
    {
      field: "facilityCode",
      title: "Facility Code",
      isFilter: true,
      width: 150,
    },
    { field: "partSource", title: "Part Source", isFilter: true, width: 150 },

    { field: "binNo", title: "Bin No.", isFilter: true, width: 150 },
    { field: "minUnit", title: "Min Unit", isFilter: true, width: 150 },
    { field: "maxUnit", title: "Max Unit", isFilter: true, width: 150 },
    {
      field: "minPricePerUnit",
      title: "Min Price Per Unit (RM)",
      isFilter: true,
      width: 150,
    },

    {
      field: "maxPricePerUnit",
      title: "Max Price Per Unit (RM)",
      isFilter: true,
      width: 150,
    },
    { field: "status", title: "Status", isFilter: true, width: 150 },
    {
      field: "qoh",
      title: "Quantity on hand (QOH)",
      isFilter: true,
      width: 150,
    },
    {
      field: "lifeOption",
      title: "Lifespan Options",
      isFilter: true,
      width: 150,
    },
  ];
  public areagrid = [
    {
      c_1: "",
      c_2: "",
      c_3: "",
      c_4: "",
      c_5: "",
      c_7: "",
      c_8: "",
      c_9: "",
      c_10: "",
      c_11: "",
      c_12: "",
      c_13: "",
      c_14: "",
      c_15: "",
      c_16: "",
      c_17: "",
      c_18: "",
      c_19: "",
      c_20: "",
    },
    {
      c_1: "",
      c_2: "",
      c_3: "",
      c_4: "",
      c_5: "",
      c_7: "",
      c_8: "",
      c_9: "",
      c_10: "",
      c_11: "",
      c_12: "",
      c_13: "",
      c_14: "",
      c_15: "",
      c_16: "",
      c_17: "",
      c_18: "",
      c_19: "",
      c_20: "",
    },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    return !itemArgs.dataItem.inStock;
  }

  maintenence_status: FormGroup;
  submitted = false;
  cont_vendor_r = ["Select"];

  constructor(
    private formBuilder: FormBuilder,
    private _notif: NotificationService
  ) {
    eventListner(EVENT_NAME.edit_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.remove_column, (e) => {
      this.editColumn(e);
    });
    eventListner(EVENT_NAME.attachment_column, (e) => {
      this.attachementColumn(e);
    });
    eventListner(EVENT_NAME.delete_attechment, (e) => {
      this.deleteAttechment(e);
    });
    eventListner(EVENT_NAME.download_attechment, (e) => {
      this.downloadAttechment(e);
    });
  }

  // attachementColumn(data: any) {
  //   this.attechForm = {
  //     ID: 0,
  //     REFERENCEID: data.id,
  //     FILETYPE: "pdf",
  //     FILENAME: "",
  //     URL: "",
  //     EMPLOYEEGLOBALID: parseInt(getCookie(userid)),
  //     CREATEDON: new Date(),
  //     MODULEHIERARCHYID: this.MODULEHIEARCHYID,
  //   };
  //   this.attechForm.EMPLOYEEGLOBALID = data.id;
  //   this.attechId = data.id;
  //   this.getAttechment(data.id)
  //     .then((result: any) => {
  //       this.attechmentDoc = [...result];
  //       this.attachmentOpened = true;
  //       this.dailogTitle = "Add Part Attachment";
  //     })
  //     .catch((err) => {
  //       this.attechmentDoc = [];
  //     });
  // }
  attachementColumn(data:any){
    this.attechForm.ID=0;
    this.attechForm.FILETYPE="pdf";
    this.attechForm.FILENAME=""
    this.attechForm.URL=""
    this.attechForm.MODULEHIERARCHYID=this.MODULEHIEARCHYID;
    this.attechForm.REFERENCEID=data.id;
  
  
  this.attechError={URL:false,FILENAME:false}
  
  // this.attechForm.EMPLOYEEGLOBALID=data.id;
  this.attechId=data.id;
  this.attechmentDoc=new AttechmentObj();
  this.attechmentDoc.getData(data.id,this.MODULEHIEARCHYID)
  .then((result:any)=>{
    this.attachmentOpened = true;
    this.dailogTitle = "Add Attachment"
  //this.attachmentOpened = true;
  //this.dailogTitle = "Add Attachment"
  })
  .catch((err)=>{
   this.attechmentDoc.alldata=[]
  })
  }
  deleteAttechment(e) {
    this.attechForm.deleteData(e.dataItem)
    .then((result)=>{
      this.attechmentDoc.alldata=this.attechmentDoc.alldata.filter((d,i)=>i!==e.rowIndex);
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  downloadAttechment(data) {
    window.open(environment.backendUrl + data.URL);
  }

  editColumn(data) {
    if (data.action == "edit") {
      this.sparePartForm.id = data.dataItem.id;
       
      // this.getspareUpdateDetails("mm", "sparePartUpdate");
      this.getSpareListById(this.sparePartForm.id);
      this.openRoleDailog("update");
    } else if (data.action == "remove") {
      this.removeDialogOpen = true;
    }
  }

  ngOnInit() {
    if(UserModuleObj.spare_parts){
      this.permission=UserModuleObj.spare_parts;
    }
    Promise.all([
      this.getStockCategory("mm", "stockCategory"),
      // this.getStockTake("dbo","stockTake"),
      this.getStockTrade("mm", "stockTrade"),
      this.getLifeSpain("mm", "LifeSpanOption"),
      this.getStatus("dbo", "status"),
      this.getfacility("dbo", "facility"),
      this.getUOM("mm", "uom"),
      this.getAssetManufacture(),
      this.getSparePartSource("mm", "partSource"),
      this.getModuleHierarchYdata("dbo", "moduleHierarchy"),
      this.getLocationData("dbo", "arealocation"),
    ]).then((e) => {
        this.getSpareList();
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.maintenence_status.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.maintenence_status.invalid) {
      return;
    }

    // display form values on success
    alert(
      "SUCCESS!! :-)\n\n" +
        JSON.stringify(this.maintenence_status.value, null, 4)
    );
  }

  onReset() {
    this.openRoleDailog("new");
  }

  onDialogClose() {
    this.opened = false;
  }

  openRoleDailog(type) {
    this.sparePartDetailsForm.statusId = 1;
    this.type = type;
    if (type == "new") {
      this.sparePartForm = new SparePart();
      this.sparePartDetailsForm = new sparePartDetailsValue();
      this.facilityCode = "";
      this.sparePartDetailsForm.statusId = 1;
      this.error1 = SparePartValidation(this.sparePartForm,"init")
      this.error2=  SparePartRequestValidation(this.sparePartDetailsForm,"init")
      this.dailogTitle = "Add Spare Part";
    }
    if (type == "update") {
      this.dailogTitle = "Edit Spare Part";
    }
    this.opened = true;
  }

  getStockTrade(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.stockTradeDropDow = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  //   getStockTake(schema,tablename){
  //    return new Promise((resolve,reject)=>{
  //      HttpClient.get(
  //       `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
  //        true,
  //        true
  //      ).then((result: any) => {
  //        this. stockTakeDropDow = JSON.parse(result.gridData)
  //        resolve(result);

  //      }).catch((e)=>{
  //        reject(e)
  //      })
  //     })

  //  }

  getStockCategory(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.stockCategoryDropDow = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getUOM(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.stockUOMDropDow = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getLifeSpain(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          this.LifeSpanDropDow = JSON.parse(result.gridData);
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  getStatus(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let statusData = [];
          statusData = JSON.parse(result.gridData);
          this.statusDropDown = statusData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getfacility(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData);
          this.facilityDropDown = facilityData;
          resolve(facilityData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAssetManufacture() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/MasterData/SelectAssetManufacturers", true, true)
        .then((result: any) => {
          this.assetManufactureDropDown = result;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  submitSpareFrom() {
    this.error1 = SparePartValidation(this.sparePartForm,"")
    this.error2=  SparePartRequestValidation(this.sparePartDetailsForm,"");
 if(!this.error1.stockNo && !this.error1.stockCategoryId && !this.error1.stockTradeId  && !this.error1.stockType  && !this.error1.uomId &&
   !this.error2.minUnit && !this.error2.partDescription  && !this.error2.partNo  && !this.error2.partSourceId){
    this.sparePartForm.createdOn = new Date();
    let sparePartDetailsArray = [];
    this.sparePartForm.isActive = true;
    sparePartDetailsArray.push(this.sparePartDetailsForm);
    this.sparePartDetailsForm.attachmentId = 1;
    this.sparePartDetailsForm.qoh = 0;
    this.sparePartDetailsForm.isActive = true;
    this.sparePartDetailsForm.createdOn = new Date();
    if (this.type == "new") {
      this.sparePartForm.id = 0;
      this.sparePartDetailsForm.id = 0;
      this.sparePartDetailsForm.sparePartId = 0;
    }
    if (this.type == "update") {
      this.sparePartDetailsForm.sparePartId = this.sparePartForm.id;
    }
    let obj = {
      ...this.sparePartForm,
      sparePartDetails: sparePartDetailsArray,
    };
    let path = "";
    if (this.type === "new") {
      path = `/api/Assets/SaveSpare`;
    } else if (this.type == "update") {
      path = `/api/Assets/SaveSpare?id=${this.sparePartForm.id}`;
    }
    HttpClient.fetchData(path, "post", obj, true)
      .then((result: any) => {
        console.log(result)
        if (result.isValid == true) {
          this._notif.show({
            content: result.messageKey,
            type: { style: "success", icon: true },
          });
          
          this.opened = false;
          this.getSpareList();
        }
        else{
          this._notif.show({
            content: result.messageKey,
            type: { style: "error", icon: true },
          });
          
          this.opened = false;
        }
      })
      .catch((err) => {
        this._notif.show({
          content: "Failed to Add or Update",
          type: { style: "error", icon: true },
        });
      });
  }
  }
  getSpareListById(id) {
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/Assets/GetSpareList?id=${id}`, true, true)
        .then((result: any) => {
          this.sparePartForm = result[0];
          this.sparePartForm.manufacturerID=result[0].manufacturerId;
          console.log("sparepart=>",this.sparePartForm);
          if (result[0].sparePartDetails.length > 0) {
            this.sparePartDetailsForm = result[0].sparePartDetails[0];
            this.sparePartDetailsForm.qoh = Math.abs(result[0].sparePartDetails[0].qoh)
          } 
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAttechment(RefId) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        "/api/MasterData/GetLookupData?Schema=dbo&Table=attachment",
        true,
        true
      )
        .then((result: any) => {
          let attechmentData = JSON.parse(result.gridData);
          let temp = attechmentData.filter(
            (d) =>
              d.REFERENCEID == RefId &&
              d.MODULEHIERARCHYID == this.MODULEHIEARCHYID
          );
          resolve(temp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteLookupAttechment(data) {
    let obj = {
      schemaName: "dbo",
      tableName: "attachment",
      userId: 1,
      oldData: JSON.stringify(data),
    };
    return new Promise((resolve, reject) => {
      HttpClient.fetchData(
        "/api/MasterData/DeleteLookupData",
        "post",
        obj,
        true,
        true
      )
        .then((response) => {
          resolve(response);
          //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName)
          // this.opened=false;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addAttechment() {
    if(!this.attechForm.URL){
      this.attechError.URL=true
    }
    else{
      this.attechError.URL=false
    }
    if(!this.attechForm.FILENAME){
      this.attechError.FILENAME=true
    }
    else{
      this.attechError.FILENAME=false
    }
    if(!this.attechError.URL && !this.attechError.FILENAME ){
      this.attechForm.FILECONTENTS=this.attechForm.URL;
      this.attechForm.MODULEHIERARCHYID=this.MODULEHIEARCHYID;
      this.attechForm.saveData()
      .then((result:any)=>{
        this.attechForm.ID=result.id;
        this.attechmentDoc.getData(this.attechId,this.MODULEHIEARCHYID)
        .then((result)=>{
          this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
          this.attechForm=new Attechment();
        })
        // this.attechmentDoc.alldata.push(this.attechForm);
        // this.attechmentDoc=JSON.parse(JSON.stringify(this.attechmentDoc));
      })
      .catch((err)=>{
         console.log(err)
      })
  }
  }

  saveAttechment(data) {
    let obj = {
      schemaName: "dbo",
      tableName: "attachment",
      userId: 1,
      newData: JSON.stringify(data),
    };
    return new Promise((resolve, reject) => {
      HttpClient.fetchData(
        "/api/MasterData/SaveLookupData",
        "post",
        obj,
        true,
        true
      )
        .then((response) => {
          resolve(response);
          //  this.getLookupData(this.selectedListValue.schema,this.selectedListValue.tableName)
          // this.opened=false;
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getModuleHierarchYdata(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      let ModuleHieracrhyData = JSON.parse(result.gridData);

      let ModFacArr = ModuleHieracrhyData.filter((e) => {
        return e.NAME === "Spare Parts Reg";
      });
      // this.facilityParentId = ModFacArr[0].PARENTID
      this.MODULEHIEARCHYID = ModFacArr[0].ID;
    });
  }

  bindSpareTradeObj(result) {
    this.spareTradeObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindSpareCategoryObj(result) {
    this.stockCategoryObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindfacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindlifeSpanObj(result) {
    this.lifeSpanObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindStatusObj(result) {
    this.statusObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindUOMObj(result) {
    this.UOMObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }

  bindSpareObj(result) {
    this.sparePartObj = result.reduce((acc, d) => {
      acc[d.ID] = d.NAME;
      return acc;
    }, {});
  }
  getSpareList() {
    return new Promise((resolve, reject) => {
      HttpClient.get("/api/Assets/GetSpareList", true, true)
        .then((result: any) => {
         
        let spareData =  shiftLastDataInto1st(result)||[];
          this.bindSpareTradeObj(this.stockTradeDropDow);
          this.bindSpareCategoryObj(this.stockCategoryDropDow);
          this.bindfacilityObj(this.facilityDropDown);
          this.bindlifeSpanObj(this.LifeSpanDropDow);
          this.bindStatusObj(this.statusDropDown);
          this.bindUOMObj(this.stockUOMDropDow);
          this.bindSpareObj(this.sparePartSourceDropDow);
          if (spareData.length > 0) {
            this.gridData =  spareData.map((e) => {
              if (e.sparePartDetails[0] && e.sparePartDetails[0].facilityId) {
                e["facilityName"] =
                  this.facilityObj[e.sparePartDetails[0].facilityId];
                let id = e.sparePartDetails[0].facilityId;
                let value = this.facilityDropDown.filter((e) => {
                  return e.ID === id;
                });
                if(value.length>0){
                  this.facilityCode = value[0].FACILITYCODE;
                  e["facilityCode"] = this.facilityCode;
                }
                
              }
              e["uom"] = this.UOMObj[e.uomId] && this.UOMObj[e.uomId];
              if (e.sparePartDetails.length > 0) {
                e["status"] = this.statusObj[e.sparePartDetails[0].statusId];
                e["partNo"] =
                  e.sparePartDetails[0] && e.sparePartDetails[0].partNo;
                e["partDescription"] =
                  e.sparePartDetails[0] &&
                  e.sparePartDetails[0].partDescription;
                e["maxPricePerUnit"] =
                  e.sparePartDetails[0] &&
                  e.sparePartDetails[0].maxPricePerUnit;
                e["partSource"] =
                  this.sparePartObj[e.sparePartDetails[0].partSourceId];
                e["binNo"] =
                  e.sparePartDetails[0] && e.sparePartDetails[0].binNo;
                e["maxUnit"] =
                  e.sparePartDetails[0] && e.sparePartDetails[0].maxUnit;
                e["minUnit"] =
                  e.sparePartDetails[0] && e.sparePartDetails[0].minUnit;
                e["maxPricePerUnit"] =
                  e.sparePartDetails[0] &&
                  e.sparePartDetails[0].maxPricePerUnit;
                e["minPricePerUnit"] =
                  e.sparePartDetails[0] &&
                  e.sparePartDetails[0].minPricePerUnit;
                e["qoh"] = Math.abs(e.sparePartDetails[0] && e.sparePartDetails[0].qoh);
                e["lifeOption"] =
                  this.lifeSpanObj[e.sparePartDetails[0].lifeOptionId];
              }

              e["spareTrade"] = this.spareTradeObj[e.stockTradeId];
              e["spareCategory"] = this.stockCategoryObj[e.stockCategoryId];
                return e;
              // e["facilityCode"] = this.facilityObj[e.facilityId]
            });
          }
          resolve(result);

        })
        .catch((err) => {
          reject(err);
        });
    // });
      })
       
      
      
  }

  facilityValueChange(data) {
    let facilityValue = this.facilityDropDown.filter((e) => {
      return e.ID === data;
    });
    this.facilityCode = facilityValue[0].FACILITYCODE;
  }

  getSparePartSource(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let sourceData = [];
          sourceData = JSON.parse(result.gridData);
          this.sparePartSourceDropDow = sourceData;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLocationData(schema, tablename) {
    HttpClient.get(
      `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
      true,
      true
    ).then((result: any) => {
      
       this.LocDropDown =  JSON.parse(result.gridData)
    });
  }

  getspareUpdateDetails(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let data = JSON.parse(result.gridData);
          let sparePartUpdateData = data.filter((e) => {
            return e.SPAREPARTID === this.sparePartForm.id;
          });
          var mostRecentDate = new Date(
            Math.max.apply(
              null,
              sparePartUpdateData.map((e) => {
                return new Date(e.CREATEDDATE);
              })
            )
          );
          var mostRecentObject = sparePartUpdateData.filter((e) => {
            var d = new Date(e.CREATEDDATE);
            return d.getTime() == mostRecentDate.getTime();
          })[0];
          this.sparePartDetailsForm.qoh =
            mostRecentObject && mostRecentObject.QUANTITY;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
