
<div class="card m-3"  >
    <!-- <h5 class="card-header">Levels</h5> -->
    <div class="card-header" style="display: flex;justify-content: space-between;">
        <h5>Audit Log</h5>
        
    </div>
    <div   class="card-body register-form-body">
      <form class=""  [formGroup]="formGroup">
        <div class="card-body register-form-body">
            <div class="form-row">
                <div class="form-group col-5">
                <label>Start Period</label>
                <div class="form-row">
                <div class="col-5" style="width:100%">
                    <kendo-datepicker formControlName="StartPeriod" [format]="format" >
                    </kendo-datepicker>
                    
                </div>
                <div class=" col-5" style="width:100%">                  
                <kendo-timepicker   [format]="'hh:mm a'"   #WorkingHourStart formControlName="StartPeriod" ></kendo-timepicker>
                </div>
            </div>
            </div>
            <div class="form-group col-5">
                <label>End Period</label>
                <div class="form-row">
                <div class="form-group col-5" style="width:100%">
                    <kendo-datepicker formControlName="EndPeriod" [format]="format" >
                    </kendo-datepicker>
                  </div>
                <div class="form-group col-5" style="width:100%">
                <kendo-timepicker   [format]="'hh:mm a'"  #WorkingHoursEnd formControlName="EndPeriod" ></kendo-timepicker>
                </div>
            </div>
            </div>
          
        </div>
        <div class="form-row">
            <div class="form-group col-5">
                <label>Actor</label>
                <div style="width:100%">
                    
                <kendo-dropdownlist  [data]="SelectPlatformData"  formControlName="Actor"  class="form-control"
                [textField]="'staffName'" [valueField]="'id'"  [valuePrimitive]="true" >
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="form-group col-5" style="text-align: center;width: 100%;">
                <button kendoButton primary="true" class="mr-2 px-3"  style="margin-top: 35px;"  (click)="fetchReport()">
                    Fetch Report
                </button>
                <button kendoButton primary="true" class="mr-2 px-3"  style="margin-top: 35px;"  (click)="reset()">
                    Reset
                </button>
                <button kendoButton primary="true" class="mr-2 px-3"  style="margin-top: 35px;"  (click)="fetchReport()">
                    Preview 
                </button>
            </div>
          
        </div>    
    </div>  
       </form>
    </div>
    <div class="card-body register-form-body"  >
        <app-table-grid
        [data]="gridData"
        [column]="column"
        [size]="10"
        [editFlag]="false"
        [deleteFlag]="false"
      ></app-table-grid>
    </div>
</div>
      