import { Component, OnInit } from "@angular/core";
import { IntlService } from "@progress/kendo-angular-intl";
import { MessageService } from "@progress/kendo-angular-l10n";
import { CustomMessagesService } from "src/app/services/custom-messages.service";
import { orders } from "src/app/resources/orders";
import { Order } from "src/app/models/order.model";
import { sampleProducts } from "src/app/Data/table";
import { eventListner, EVENT_NAME } from "src/event";
import { LegendLabelsContentArgs, SeriesLabels, SeriesLabelsVisualArgs, SeriesVisualArgs } from "@progress/kendo-angular-charts";
import HttpClient from "src/Util/ApiHandling";
import { environment } from "src/environments/environment";
import { Permission, UserModuleObj } from "src/ApiModel/Model/UserModule";
import { anyChanged } from "@progress/kendo-angular-common";
import { ModelApi } from 'src/ApiModel/const';
import { Model } from 'src/ApiModel/Model';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: "app-dashboard-component",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  type = "dashboard";
  public selectedChart: "Trend" | "Volume" = "Trend";
  public permission:Permission=new Permission();
  public statusDropDown = [];
  public color: any = ["red", "green", "yellow"]
  public WorkOrderType = [];
  public WorkOrderStatus = [];
  public levelData = [];
  public areaData = [];
  public levelObj = {};
  public areaObj = {};
  public facilityObj = {};
  public WorkOrderService = [];
  public dashboardCount = [];
  public opened: boolean = false;
  public woPopUpopened:boolean = false;
  public isWOType:boolean = true;
  public woPopUpData = [];
  public sizeGridChange = "5"
  public autofit = true;
  public dialogTitle = "";
  public slecteFacility = -1;
  public facilityDropDown = [];
  public gridDataByCount = []
  public gridDataRequest =[];
  public imageUrl = environment.backendUrl;
  public woPopupTitle =""
  public withRequest:boolean = false;
  public isAccess: boolean = false;
  public allBlocksValue = [];
  public allLevelsValue =[];
  public allAreasValue = [];
  public popUpNameByCount ="";
  public withDownloadRequest:boolean = false;
  public downloadWorkDataAfterCount = [];
  public downloadWorkOrderData = [];
  public downloadRequestData = [];
  public tabTitle = "WO"
  public selectedFacility:number = 0;
 public pageHeight = "height:82vh";
 public filiterFacility = "";
 public qureyFiliteroption = "";
 public queryFiliterObj={};
 public filiterData:any = null;
 public netwaitingTime;
  public data: any[] = [{
    kind: 'Solar', share: 0.052, colorField: "red"
  }, {
    kind: 'Wind', share: 0.225, colorField: "#000"
  }, {
    kind: 'Other', share: 0.192, colorField: "blue"
  }, {
    kind: 'Hydroelectric', share: 0.175, colorField: "yellow"
  }, {
    kind: 'Nuclear', share: 0.238, colorField: "orange"
  }, {
    kind: 'Coal', share: 0.118, colorField: "green"
  }];

  public selectedCount = 5;
  public countDropDown = [
    {ID:5,NAME:"5"},
    // {ID:5,NAME:5},
    {ID:10,NAME:10},
    {ID:20,NAME:20},
    {ID:30,NAME:30},
    {ID:50,NAME:50}
  ]
  public model:Model=new Model();
  constructor(public intl: IntlService, public messages: MessageService) {
    eventListner(EVENT_NAME.wo_id, (e) => {
      this.checkedColumn(e);
    });

    this.customMsgService = this.messages as CustomMessagesService;
    this.labelContent1 = this.labelContent1.bind(this);
  }

  public labelContent1(args: LegendLabelsContentArgs): any {
    return this.intl.formatNumber((args.percentage), 'p2');
  }
  public labelContent(e: any): string {
    return e.value;
  }
  public orders: Order[] = orders;
  public chartDefaultV4Colors: string[] = [
    "#ff6358",
    "#ffd246",
    "#78d237",
  ];
  public seriesColors: string[] = this.chartDefaultV4Colors;
  public filterFormat = "dd/MM/yyyy";
  public monthDayList = [
    { full: "January", short: "Jan", day: "Sun" },
    { full: "February", short: "Feb", day: "Mon" },
    { full: "March", short: "Mar", day: "Tue" },
    { full: "April", short: "Apr", day: "Wed" },
    { full: "May", short: "May", day: "Thr" },
    { full: "June", short: "Jun", day: "Fri" },
    { full: "July", short: "Jul", day: "Sat" },
    { full: "Augest", short: "Aug", day: "Sun" },
    { full: "September", short: "Sep", day: "" },
    { full: "October", short: "Oct", day: "" },
    { full: "November", short: "Nov", day: "" },
    { full: "December", short: "Dec", day: "" },
  ];
  public onTabSelect(e) {
    if(e.title==="Request"){
      this.withDownloadRequest = true;
      this.tabTitle = "Request"
      if(this.selectedFacility != 0 && this.selectedFacility != -1)
      {
        this.getWorkOrderByRequestbyFacility("facilityId",this.selectedFacility);
      }else{
        this.getWorkOrderByRequest()
      }
    }else{
      this.withDownloadRequest = false;
      this.tabTitle = "WO"
    }
}
  public column = [
    { field: "workOrderNo", title: "Work Order No", width: "80", isFilter: true },
    {
      field: "description",
      title: "Description",
      width: "200",
      isFilter: true
    },
    { field: "slaStatus", title:"Completion SLA " ,width: "50", isFilter: true},
    { field: "ticketStatusObj.NAME", title: "Work Order Status ",filterType:"list", width: "80", isFilter: true },
    {
      field: "acceptanceDate",
      title: "Work Order Date/Time",
      isFilter: true,
      width: "50",
      isDateFilter: "date",
      formate: this.filterFormat,
      parse:(d)=>{
        let d1=new Date(d);
        function padding(n){
          if(n<10)
            return "0"+n;
          else
            return n;
        }
        if(d1){
          return `${padding(d1.getDate())}/${padding(d1.getMonth()+1)}/${padding(d1.getFullYear())} ${padding(d1.getHours())}:${padding(d1.getMinutes())}:${padding(d1.getSeconds())}`
          }
          else{
            return null;
          }
      }
    },
    { field: "assigneeObj.staffName", title: "Assignee ",width: "100" },
    { field: "assignToObj.name", title: " Assign To ",filterType:"list", isFilter: true,width: "100" },
    { field: "serviceTypeName", title: "Service Type",filterType:"list", isFilter: true,width: "100" },
    { field: "consequanceObj.NAME", title: "Category",filterType:"list", isFilter: true ,width: "100"},
    { field: "workOrderTypeObj.name", title: "Work Order Type", width: "50" },
   
    // { field: "ticketStatusObj.NAME", title: "Ticket Status", width: "240", isFilter: true },
    // { field: "", title: "Work Order Type", width: "240", isFilter: true },
    // { field: "nameCreatedBy", title: "Created By", width: "240", isFilter: true },
  ];

  public countColumn = [
    // { field: "workOrderNo", title: "Work Order No", width: "80" },
    { field: "workOrderNo", title: "Work Order No", width: "80" },
    {
      field: "description",
      title: "Description",
      width: "100"
    },
    { field: "slaStatus", title:"Completion SLA " ,width: "50"},
    { field: "ticketStatusObj.NAME", title: "Work Order Status ", width: "80"  },
    {
      field: "acceptanceDate",
      //title: "WO Start Date and Time",
      title: "Work Order Date/Time",
      width: "50",
      formate: this.filterFormat,
      parse:(d)=>{
        let d1=new Date(d);
        function padding(n){
          if(n<10)
            return "0"+n;
          else
            return n;
        }
        if(d1){
          return `${padding(d1.getDate())}/${padding(d1.getMonth()+1)}/${padding(d1.getFullYear())} ${padding(d1.getHours())}:${padding(d1.getMinutes())}:${padding(d1.getSeconds())}`
          }
        else{
            return null;
          }
      }
    },
    { field: "assigneeObj.staffName", title: "Assignee ",width: "100" },
    { field: "assignToObj.name", title: " Assign To ", width: "100" },
    // { field: "serviceTypeName", title: "Service Type", isFilter: true,width: "100" },
    { field: "serviceTypeName", title: "Service", width: "100" },
    { field: "consequanceObj.NAME", title: "Category", width: "100"},
   
    { field: "workOrderTypeObj.name", title: "Work Order Type", width: "50" },
    // { field: "consequanceObj.NAME", title: "Consequence  Category", isFilter: true ,width: "100"},
  
  ];


  public columnRequest=[
    { field: "requestNo", title: "Request No", width: "100",isFilter: true },
    {
      field: "workOrderTypeObj.name",
      title: "Work Order Type ",
      width: "100",
      isFilter: true
    },
    { field: "description", title: "Description ", width: "100" },
    { field: "assignToObj.name", title: "Assign To  ", width: "100",isFilter: true },
    {
      field: "RequestDate&Time",
      title: "Request Date/Time",
      isFilter: true,
      width: "100",
      isDateFilter: "date",
      formate: this.filterFormat,
      parse:(d)=>{
        let d1=new Date(d);
        function padding(n){
          if(n<10)
            return "0"+n;
          else
            return n;
        }
        // return `${padding(d1.getDate())}/${padding(d1.getMonth()+1)}/${padding(d1.getFullYear())}`
        if(d1){
          return `${padding(d1.getDate())}/${padding(d1.getMonth()+1)}/${padding(d1.getFullYear())} ${padding(d1.getHours())}:${padding(d1.getMinutes())}:${padding(d1.getSeconds())}`
          }
        else{
            return null;
          }
      }
    },
    { field: "consequanceObj.NAME", title: "Category",filterType:"list", width: "100",isFilter: true },
    { field: "serviceTypeName", title: "Service Type",filterType:"list", width: "100",isFilter: true },
   
    { field: "slaStatus", title: "Respond SLA ", width: "100" },
   
  
  ]
  public consequanceObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }
  public ticketStatusObj:any = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public serviceTypeObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public assigineeObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }

  public assignToObj={
    alldata:[],
    filterData:[],
    selectedObj:null,
    objectData:{}
  }

  public blockLevelObj = {
    alldata: [],
    filterData: [],
    selectedObj: null,
    objectData: {}
  }

  public workOrderTypeObj = {
    alldata: [],
    filterData: [
      { id: 1, name: "Routine Maintenance" },
      { id: 2, name: "Corrective Maintenance" },
      { id: 3, name: "Reactive Maintenance" }
    ],
    selectedObj: null,
    objectData: {}
  }
  public gridData = [];
  public dateRange: any = {
    start: new Date(2020, 0, 1),
    end: new Date(2020, 4, 1),
  };

  public categories = this.orders.map((dataItem) => {
    return dataItem.orderDate;
  });

  public series: any[] = [
    {
      name: "Tiger Team",
      data: this.fetchData(1),
      color: "#FF6358",
    },
    {
      name: "Lemon Team",
      data: this.fetchData(2),
      color: "#F7C62F",
    },
    {
      name: "Organic Team",
      data: this.fetchData(3),
      color: "#55AB1D",
    },
    {
      name: "Ocean Team",
      data: this.fetchData(4),
      color: "#28B4C8",
    },
  ];

  public customMsgService: CustomMessagesService;


  ngOnInit(): void {
    this.qureyFiliteroption = "";
    if(UserModuleObj.dashboard){
      this.permission=UserModuleObj.dashboard;
      this.isAccess = true;
    }else{
      this.isAccess = false;
    }
    this.model.getData(ModelApi.ServicesType)
    .then((result)=>{

    })
    .catch((err)=>{

    })
    Promise.all([
      
      this.getDashBoardCount(),
      this.getWorkOrderByConseqCategory(),
      this.getWorkOrderByStatus(),
      this.getWorkOrderByServiceType(),
      this.getLoockup("PMC", "TicketConsequenceType"),
      this.getLoockup("pmc", "ticketstatus"),
      // this.getAssignTo(),
      // this.getAssignne(),
      // this.getfacility("dbo", "facility"),
      // this. getfacilityValue(),
      
      // this.getfacility(),

    ]).then((result: any) => {
      
        this.consequanceObj.objectData = result[4].reduce((acc, d) => {
          acc[d.ID] = d;
          return acc;
        }, {})
          this.consequanceObj.filterData = result[4];
      this.ticketStatusObj.objectData = result[5].reduce((acc, d) => {
        acc[d.ID] = d;
        return acc;
      }, {});

        this.ticketStatusObj.filterData =  result[5];
        this.serviceTypeObj.alldata=result[3];
       this.serviceTypeObj.objectData = result[3].reduce((acc,d) =>{
        acc[d.id] = d;
        return acc;
       },{})
  
      this.workOrderTypeObj.objectData = this.workOrderTypeObj.filterData.reduce((acc, d) => {
        acc[d.id] = d;
        return acc;
      }, {})
      this.assignToObj.objectData = result[6].reduce((acc, d) => {
        acc[d.id] = d;
        return acc;
      }, {})

      this.assignToObj.filterData = result[6];
      this.assigineeObj.objectData =  result[7].reduce((acc, d) => {
        acc[d.id] = d;
        return acc;
      }, {})

      // this.getAllWorkOrder();
    });
  }

  pageRefresh()
  {

    if(this.selectedFacility == 0 || this.selectedFacility == -1)
    {
      //this.qureyFiliteroption = "&serviceTypeId=" + valueId;
      this.getDashBoardCount(),
      this.getWorkOrderByConseqCategory(),
      this.getWorkOrderByStatus(),
      this.getWorkOrderByServiceType(),
      this.getLoockup("PMC", "TicketConsequenceType"),
      this.getLoockup("pmc", "ticketstatus"),
      this.getAssignTo(),
      // this.getAssignne(),
      this.getfacility("dbo", "facility"),
      this.getWorkOrderByRequest()
      // this.getAllWorkOrder();
    }
    else{
      this.getWoById("facilityId",this.selectedFacility)
      this.getWorkOrderStatusById("facilityId",this.selectedFacility);
      this.getWorkOByConsequenceCateById("facilityId",this.selectedFacility);
      this.getWorkOrderByServiceTypeById("facilityId",this.selectedFacility);
      this.getDashboardCountById("facilityId",this.selectedFacility);
      this.getAllWorkOrderbyFilter("facilityId",this.selectedFacility);
    }
  }

  ngAfterViewInit(){
    setInterval( ()=>{
      this.filterChangedHandler(this.filiterData || [])
      // Promise.all([
      //   //this.pageRefresh()
      //   // this.getDashBoardCount(),
      //   // this.getWorkOrderByConseqCategory(),
      //   // this.getWorkOrderByStatus(),
      //   // this.getWorkOrderByServiceType(),
      //   // this.getLoockup("PMC", "TicketConsequenceType"),
      //   // this.getLoockup("pmc", "ticketstatus"),
      //   // this.getAssignTo(),
      //   // this.getAssignne(),
      //   // this.getfacility("dbo", "facility"),
      //   // this.getWorkOrderByRequest(),
      //   // this.getfacility(),
  
      // ]).then((result: any) => {

      //   console.log("ggggggggggg")
        
      //   //   this.consequanceObj.objectData = result[4].reduce((acc, d) => {
      //   //     acc[d.ID] = d;
      //   //     return acc;
      //   //   }, {})
      //   //     this.consequanceObj.filterData = result[4];
      //   // this.ticketStatusObj.objectData = result[5].reduce((acc, d) => {
      //   //   acc[d.ID] = d;
      //   //   return acc;
      //   // }, {});
  
      //   //   this.ticketStatusObj.filterData =  result[5];
      //   //  this.serviceTypeObj.objectData = result[3].reduce((acc,d) =>{
      //   //   acc[d.id] = d;
      //   //   return acc;
      //   //  },{})
    
      //   // this.workOrderTypeObj.objectData = this.workOrderTypeObj.filterData.reduce((acc, d) => {
      //   //   acc[d.id] = d;
      //   //   return acc;
      //   // }, {})
      //   // this.assignToObj.objectData = result[6].reduce((acc, d) => {
      //   //   acc[d.id] = d;
      //   //   return acc;
      //   // }, {})
  
      //   // this.assignToObj.filterData = result[6];
      //   // this.assigineeObj.objectData =  result[7].reduce((acc, d) => {
      //   //   acc[d.id] = d;
      //   //   return acc;
      //   // }, {})
      //   // if(this.selectedFacility == 0)
      //   // {
      //   // this.getAllWorkOrder();
      //   // }
      // });
    }, 300000)
  }

  public seriesLabels: SeriesLabels = {
    visible: true, // Note that visible defaults to false
    padding: 3,
    font: "bold 10px Arial, sans-serif",
    color: "#000",
  };

  public visualFn: SeriesLabels = {
    visible: true, // Note that visible defaults to false
    padding: 3,
    font: "bold 10px Arial, sans-serif",
    color: "#000"
    // const geometry = new GeomCircle([10, 10], 10);
    // return new Circle(geometry);
  }

  public fromDate(date: Date) {
    this.dateRange.start = date;
    this.updateSeries();
  }

  public toDate(date: Date) {
    this.dateRange.end = date;
    this.updateSeries();
  }

  public updateSeries() {
    this.series.map((series, index) => {
      return (series.data = this.fetchData(index + 1));
    });
  }

  public fetchData(team) {
    return this.orders.map((dataItem) => {
      if (
        dataItem.teamID === team &&
        dataItem.orderDate >= this.dateRange.start &&
        dataItem.orderDate < this.dateRange.end
      ) {
        return dataItem.orderTotal;
      }
    });
  }

  onDialogClose(type) {
     if(type==="woPopup"){
     this.woPopUpopened = false;
     }if(type == "new"){
      this.opened = false;
     }
  }

  getWorkOrderByStatus() {
    return new Promise((resolve, reject) => {
      var result = [{"id":0,"type":"Close","categoryType":null,"count":"78","typeOrder":0,"colorcode":"9ad201"},{"id":0,"type":"Complete","categoryType":null,"count":"107","typeOrder":0,"colorcode":"3e92cc"},{"id":0,"type":"In Progress","categoryType":null,"count":"101","typeOrder":0,"colorcode":"CC4F46"}];


      this.WorkOrderStatus = result.map((e) => {
              e["countValue"] = parseInt(e.count);
              e["colorcode"] = (`#` + e.colorcode);
              return e;
            });
            resolve(result)

      // HttpClient.get("/api/Dashboard/GetWorkOrderByStatus?" + this.qureyFiliteroption, true, true)
      //   .then((result: any) => {
      //      this.WorkOrderStatus = result.map((e) => {
      //       e["countValue"] = parseInt(e.count);
      //       e["colorcode"] = (`#` + e.colorcode);
      //       return e;
      //     });
      //     resolve(result)
      //   })
      //   .catch((err) => {
      //     reject(err);
      //   });
    });
  }

  getWorkOrderByServiceType() {
    return new Promise((resolve, reject) => {

      var result = [{"id":3,"type":"Mechanical","categoryType":null,"count":"28","typeOrder":0,"colorcode":"CC4F46"},{"id":2,"type":"Electrical","categoryType":null,"count":"149","typeOrder":0,"colorcode":"CC4F46"},{"id":5,"type":"Housekeeping","categoryType":null,"count":"129","typeOrder":0,"colorcode":"CC4F46"},{"id":6,"type":"Hardware","categoryType":null,"count":"88","typeOrder":0,"colorcode":"CC4F46"}];
      this.WorkOrderService = result.map((e) => {
        e["countValue"] = parseInt(e.count);
        e["colorcode"] = (`#` + e.colorcode);
        return e;
      });
      resolve(result)
      // HttpClient.get(
      //   "/api/Dashboard/GetWorkOrderByServiceType?" + this.qureyFiliteroption,
      //   true,
      //   true
      // ).then((result: any) => {

      //   this.WorkOrderService = result.map((e) => {
      //     e["countValue"] = parseInt(e.count);
      //     e["colorcode"] = (`#` + e.colorcode);
      //     return e;
      //   });
      //   resolve(result)
      // });
    }).catch((err) => {
      return err;
    });
  }

  getWorkOrderByConseqCategory() {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        "/api/Dashboard/GetWorkOrderByConsequenceCategory?" + this.qureyFiliteroption,
        true,
        true
      )
        .then((result: any) => {
          this.WorkOrderType = result.map((e) => {
            e["countValue"] = parseInt(e.count);
            e["colorcode"] = (`#` + e.colorcode);
            return e;
          });
          resolve(result)
        })
        .catch((err) => {
          return err;
        });
    });
  }

  bindLevelObj(result) {
    this.levelObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }
  bindFacilityObj(result) {
    this.facilityObj = result.reduce((acc, d) => {
      acc[d.id] = d.name;
      return acc;
    }, {});
  }
  bindAreaObj(result) {
    this.areaObj = result.reduce((acc, d) => {
      acc[d.id] = d.code;
      return acc;
    }, {});
  }

  getAllWorkOrder() {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        "/api/Dashboard/GetAllWorkOrders?" + this.qureyFiliteroption,
        true,
        true
      )
        .then((result: any) => {
          this.bindLevelObj(this.levelData);
          this.bindFacilityObj(this.facilityDropDown);
          this.bindAreaObj(this.areaData);
          this.gridData = result.map((d,index) => {
            d["soNo"] = index+1
            d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
            d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
            d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
            d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
            d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
            d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
            d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
            //d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
            d["serviceTypeName"] = d.serviceTypeName;
            d["facility"] = this.facilityObj[d.facilityID];
            d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
            d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
            d["acceptanceDate"] =  new Date(d.createdDate);
            d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
            if(d.chargable)
            {
            d["chargeable"] = "Yes";
            }
            else{
            d["chargeable"] = "No";
            }
            d["CompletionSLA"] = d.slaStatus;
            d["CompletionSLA"] = d.slaStatus;
            d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
            d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
            d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
            d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
            
            d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);

            d["FollowUpReason"] = d.followUpReason;
            d["blockName"] = d.blockName;
            d["levelName"] = d.levelName;
            d["areaName"] = d.areaName;
            d["locationName"] = d.locationName
            // d["acceptanceDate"] = this.formatDateTime(d.acceptanceDate);
            return d;
          });
  
          this.downloadWorkOrderData = this.gridData.map((e)=>{
            e["serviceTypeName"] = e.serviceTypeName;
              if(e.assignToObj){
                e["assignTo"] = e.assignToObj["name"];}
                if(e.assigneeObj){
                  e["assignee"] = e.assigneeObj["staffName"];
                 }
                if(e.completedObj){
                  e["CompletedBy"] = e.completedObj["staffName"]
                }
                if(e.closedObj){
                  e["ClosedBy"] = e.closedObj["staffName"]
                }
              //  if(e.serviceTypeObj){
              //   e["serviceTypeName"] = e.serviceTypeObj["type"];
              //  }  
               if(e.workOrderTypeObj){
                e["workOrderType"] = e.workOrderTypeObj["name"];
               }
               if( e.ticketStatusObj){
                e["WOStatus"] = e.ticketStatusObj["NAME"];
               }
               if(e.consequanceObj){
                e["consequenceCategory"] = e.consequanceObj["NAME"];
               }
               if(e.consequanceObj){
                e["ConsequenceCategory"] = e.consequanceObj["NAME"];
               }
              return e;
            })
          resolve(result)
        })
      
        .catch((err) => {
          return err;
        });
    });
  }

  getAllWorkOrderbyFilter(paramsName,id,queryObj=null) {
    let queryArray=[]
    if(queryObj){
      for(let k in queryObj){
      queryArray.push(k+"="+queryObj[k])
      }
    }
    else{
      queryArray.push(paramsName+"="+id);
    }
    if(this.selectedFacility != 0 && this.selectedFacility != -1)
    {
      this.qureyFiliteroption = "&facilityId=" + this.selectedFacility;
    }else{
      this.qureyFiliteroption ="";
    }
    this.bindLevelObj(this.levelData);
    this.bindFacilityObj(this.facilityDropDown);
    this.bindAreaObj(this.areaData);
    return new Promise((resolve, reject) => {
      HttpClient.get(`/api/Dashboard/GetAllWorkOrders?${queryArray.join("&")}` + this.qureyFiliteroption,true,true)
        .then((result: any) => {
          this.gridData = result.map((d,index) => {
            d["soNo"] = index+1
            d["serviceTypeName"] = d.serviceTypeName;
            console.log(d.serviceTypeName);
              d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
              d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
              d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
              d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
              d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
              //d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
          
              d["levelName"] = this.levelObj[d.levelID];
              d["facility"] = this.facilityObj[d.facilityID];
              d["areaName"] =this.areaObj[d.areaID];
             
              d["requestDate"] = new Date(d.createdDate);
              // d["RequestDate&Time"] = this.formatDateTime(d.createdDate);
              d["RequestDate&Time"] =  new Date(d.createdDate);
              d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
              d["acceptanceDate"] =  new Date(d.createdDate);
              d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
              d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
              d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
              d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
              d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);

            d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
            d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
            d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
            d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
            if(d.chargable)
            {
            d["chargeable"] = "Yes";
            }
            else{
            d["chargeable"] = "No";
            }
            d["CompletionSLA"] = d.slaStatus;
            d["blockName"] = d.blockName;
            d["levelName"] = d.levelName;
            d["areaName"] = d.areaName;
            d["locationName"] = d.locationName
            d["CompletionSLA"] = d.slaStatus;
            d["FollowUpReason"] = d.followUpReason;
            // d["acceptanceDate"] = this.formatDateTime(d.acceptanceDate);
            return d;
          })
          resolve(result)
          // console.log("gridata",this.gridData)

          this.downloadWorkDataAfterCount = this.gridDataByCount.map((e)=>{
            e["serviceTypeName"] = e.serviceTypeName;
            if(e.assignToObj){
              e["assignTo"] = e.assignToObj["name"];}
              if(e.assigneeObj){
                e["assignee"] = e.assigneeObj["staffName"];
              
              }
              if(e.completedObj){
                e["CompletedBy"] = e.completedObj["staffName"]
              }
              if(e.closedObj){
                e["ClosedBy"] = e.closedObj["staffName"]
              }
             if(e.serviceTypeObj){
              e["serviceTypeName"] = e.serviceTypeObj["type"];
             }  
             if(e.workOrderTypeObj){
              e["workOrderType"] = e.workOrderTypeObj["name"];
             }
             if( e.ticketStatusObj){
              e["WOStatus"] = e.ticketStatusObj["NAME"];
             }
             if(e.consequanceObj){
              e["consequenceCategory"] = e.consequanceObj["NAME"];
             }
            return e;
           })

           this.downloadWorkOrderData = this.gridData.map((e)=>{
            e["serviceTypeName"] = e.serviceTypeName;
            if(e.assignToObj){
              e["assignTo"] = e.assignToObj["name"];}
              if(e.assigneeObj){
                e["assignee"] = e.assigneeObj["staffName"];
               }
              if(e.completedObj){
                e["CompletedBy"] = e.completedObj["staffName"]
              }
              if(e.closedObj){
                e["ClosedBy"] = e.closedObj["staffName"]
              }
             if(e.serviceTypeObj){
              e["serviceTypeName"] = e.serviceTypeObj["type"];
             }  
             if(e.workOrderTypeObj){
              e["workOrderType"] = e.workOrderTypeObj["name"];
             }
             if( e.ticketStatusObj){
              e["WOStatus"] = e.ticketStatusObj["NAME"];
             }
             if(e.consequanceObj){
              e["consequenceCategory"] = e.consequanceObj["NAME"];
             }
             if(e.consequanceObj){
              e["ConsequenceCategory"] = e.consequanceObj["NAME"];
             }
            return e;
          })

        })
        })
        .catch((err) => {
          return err;
        });
  }


  getLoockup(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      ).then((result: any) => {
        let temp = JSON.parse(result.gridData)
        resolve(temp)
      })
        .catch((err) => {
          reject(err)
        })
    })
  }
  pading(n) {
    if (n > 9) return n;
    else return "0" + n;
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    
    return day+month+year;
}

formatDateTime(date) {
  if(date == null)
  return '';
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear(),
hour = '' + d.getHours(),
minutes = '' + d.getMinutes(),
secound = '' + d.getSeconds();
if (month.length < 2) 
  month = '0' + month;
if (day.length < 2) 
  day = '0' + day;
  if (hour.length < 2) 
  hour = '0' + hour;
  if (minutes.length < 2) 
  minutes = '0' + minutes;
  if (secound.length < 2) 
  secound = '0' + secound;
  return day +'/' +month +'/'+year +' ' + hour + ':'+minutes+':' + secound;

}


  dateFormating(da) {
    if (!da) {
      return null;
    }
    let df = new Date(da);
    return `${this.pading(df.getDate())}-${this.monthDayList[df.getMonth()].short
      }-${df.getFullYear()}`;
  }

  getDashBoardCount() {
    return new Promise((resolve, reject) => {

      var result = [{"id":10,"type":"Asset Request","categoryType":null,"count":"53","typeOrder":1,"colorcode":"000"},{"id":0,"type":"Total Asset","categoryType":null,"count":"394","typeOrder":2,"colorcode":"000"},{"id":2,"type":"In Progress Asset","categoryType":null,"count":"95","typeOrder":3,"colorcode":"000"},{"id":3,"type":"Pending Asset","categoryType":null,"count":"11","typeOrder":4,"colorcode":"000"},{"id":4,"type":"Completed Asset","categoryType":null,"count":"57","typeOrder":5,"colorcode":"1eb45d"},{"id":5,"type":"Closed Asset","categoryType":null,"count":"55","typeOrder":6,"colorcode":"1eb45d"},{"id":11,"type":"Cancel Asset Request","categoryType":null,"count":"53","typeOrder":7,"colorcode":"cd180e"},{"id":12,"type":"Asset Type","categoryType":null,"count":"220","typeOrder":8,"colorcode":"cd180e"}];

      this.dashboardCount = result.map((e) => {
        e["colorcode"] = `#` + e.colorcode;
        return e;
      });
      resolve(result);

      // HttpClient.get(
      //   "/api/Dashboard/GetDashboardCounts?" + this.qureyFiliteroption,
      //   true,
      //   true
      // ).then((result: any) => {
      //   this.dashboardCount = result.map((e) => {
      //     e["colorcode"] = `#` + e.colorcode;
      //     return e;
      //   });
      //   resolve(result);
      // })
      //   .catch((err) => {
      //     reject(err)
      //   })
    })
  }


  getfacility(schema, tablename) {
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/MasterData/GetLookupData?Schema=${schema}&&Table=${tablename}`,
        true,
        true
      )
        .then((result: any) => {
          let facilityData = [];
          facilityData = JSON.parse(result.gridData)
          facilityData.unshift({ ID:-1,NAME:"Facility" });
          this.facilityDropDown = facilityData;
          resolve(facilityData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getfacilityValue() {
    return new Promise((resolve, reject) => {
     let blockCodeDropDown = []
     HttpClient.get("/api/Facility/GetFacilities", true, true)
       .then((result: any) => {
        
         if(result.length>0){
         let facilityData = [];
         this.facilityDropDown = result;
         facilityData = result;
         let blocksValue = [];
         if(facilityData.length>0){
         blocksValue = facilityData.map((e) => {
           return e.blocks;
         });
         facilityData.unshift({ id:-1,name:"Facility" });
         for (let i = 0; i < blocksValue.length; i++) {
           for (let j = 0; j < blocksValue[i].length; j++) {
             let arr = [];
             arr = blocksValue[i][j];
             this.allBlocksValue.push(arr);
           }
           blockCodeDropDown = this.allBlocksValue.filter((e)=>{
             return e.statusId==1;
           });
           
         }
        
         let levelsValue = [];
         levelsValue = blockCodeDropDown.map((e) => {
           return e.blockLevels;
         });
         for (let i = 0; i < levelsValue.length; i++) {
           for (let j = 0; j < levelsValue[i].length; j++) {
             let arr = [];
             arr = levelsValue[i][j];
             this.allLevelsValue.push(arr);
           }
         }
         this.levelData = this.allLevelsValue.filter((e)=>{
           return e.statusId==1;
         });
         let areaValue =[];
         areaValue = this.levelData.map((e)=>{
                  return e.levelAreas;
         });
         for (let i = 0; i <  areaValue.length; i++) {
           for (let j = 0; j <  areaValue[i].length; j++) {
             let arr = [];
             arr =  areaValue[i][j];
             this.allAreasValue.push(arr);
           }
         }
         this.areaData = this.allAreasValue.filter((e)=>{
           return e.statusId==1;
         });
         resolve(result);
       }
     }
       })
       .catch((err) => {
         reject(err);
       });
   });
 }

 ClickOnDashboardCount(id, type,count,queryObj=null) {
  let queryArray=[]
  if(this.queryFiliterObj){
    for(let k in this.queryFiliterObj){
    queryArray.push(k+"="+ this.queryFiliterObj[k])
    }
  }
  else{
    //queryArray.push(paramsName+"="+id);
  }
  this.dialogTitle = `${type}:${count}`;
  this.opened = true;
  this.gridDataByCount  = [];
  this.popUpNameByCount = type;
  if(this.selectedFacility != 0 && this.selectedFacility != -1)
  {
    this.filiterFacility = "&facilityId=" + this.selectedFacility;
  }
  else{
    this.filiterFacility = "";
  }
  if(type === "CRM Request" || type === "Exceed Request SLA"){
    this.withRequest = true;
    this.bindFacilityObj(this.facilityDropDown);
    return new Promise((resolve, reject) => {
      HttpClient.get(
        `/api/Dashboard/GetWorkRequest?status=${id}&${queryArray.join("&")}` + this.filiterFacility + this.qureyFiliteroption,
        true,
        true
      )
        .then((result: any) => {
          if (result.length > 0) {
            this.bindLevelObj(this.levelData);
            this.bindFacilityObj(this.facilityDropDown);
            this.bindAreaObj(this.areaData);
            this.gridDataByCount = result.map((d,index) => {
              d["soNo"] = index+1
              d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
              d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
              d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
              d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
              d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
              //d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
            d["serviceTypeName"] = d.serviceTypeName;
              d["blockName"] = d.blockName;
              d["levelName"] = d.levelName;
              d["areaName"] = d.areaName;
              d["locationName"] = d.locationName;
              d["facility"] = this.facilityObj[d.facilityID];
              d["requestDate"] = new Date(d.createdDate);
              if(d.chargable)
              {
              d["chargeable"] = "Yes";
              }
              else{
              d["chargeable"] = "No";
              }
              // d["RequestDate&Time"] = this.formatDateTime(d.createdDate);
              d["RequestDate&Time"] =  new Date(d.createdDate);
              d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
              d["acceptanceDate"] =  new Date(d.createdDate);
              d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
              d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
              d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
              d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
              d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);
              // d["acceptanceDate"] = new Date(d.acceptanceDate);
              return d;
            });
    
            this.downloadWorkDataAfterCount = this.gridDataByCount.map((e)=>{
              e["RequestRespondSLA"] = e.slaStatus;
              if(e.assignToObj){
                e["assignTo"] = e.assignToObj["name"];}
              //  if(e.serviceTypeObj){
              //   e["serviceTypeName"] = e.serviceTypeObj["type"];
              //  }  
               if(e.workOrderTypeObj){
                e["RequestType"] = e.workOrderTypeObj["name"];
               }
               if(e.consequanceObj){
                e["consequenceCategory"] = e.consequanceObj["NAME"];
               }
               if(e.completedObj){
                 e["CompletedBy"] = e.completedObj["staffName"]
               }
               if(e.closedObj){
                 e["ClosedBy"] = e.closedObj["staffName"]
               }
              // if(e.serviceTypeObj){
              //  e["serviceTypeName"] = e.serviceTypeObj["type"];
              // }  
              if(e.workOrderTypeObj){
               e["workOrderType"] = e.workOrderTypeObj["name"];
              }
              if( e.ticketStatusObj){
               e["WOStatus"] = e.ticketStatusObj["NAME"];
              }
              if(e.consequanceObj){
               e["consequenceCategory"] = e.consequanceObj["NAME"];
              }

              return e;
            })
         }
        });
    })
  }
 else{
  this.withRequest = false;
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Dashboard/GetAllWorkOrders?statusId=${id}&${queryArray.join("&")}` +  this.filiterFacility + this.qureyFiliteroption,
      true
    )
      .then((result: any) => {
        if (result.length > 0) {
          this.bindLevelObj(this.levelData);
          this.bindFacilityObj(this.facilityDropDown);
          this.bindAreaObj(this.areaData);
          this.gridDataByCount = result.map((d,index )=> {
            d["soNo"] = index+1
            d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
            d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
            d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
            d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
            d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
            d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
            d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
            // d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
            d["facility"] = this.facilityObj[d.facilityID];
            d["blockName"] = d.blockName;
            d["levelName"] = d.levelName;
            d["areaName"] = d.areaName;
            d["locationName"] = d.locationName;
            d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
            d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
            d["acceptanceDate"] =  new Date(d.createdDate);
            if(d.chargable)
            {
            d["chargeable"] = "Yes";
            }
            else{
            d["chargeable"] = "No";
            }
            d["CompletionSLA"] = d.slaStatus;
            d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
            d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
            d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
            d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
            d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);
            d["FollowUpReason"] = d.followUpReason;
            d["serviceTypeName"] = d.serviceTypeName;
            return d;
          });
         this.downloadWorkDataAfterCount = this.gridDataByCount.map((e)=>{
          if(e.assignToObj){
            e["assignTo"] = e.assignToObj["name"];}
            if(e.assigneeObj){
              e["assignee"] = e.assigneeObj["staffName"];
            
            }
            if(e.completedObj){
              e["CompletedBy"] = e.completedObj["staffName"]
            }
            if(e.closedObj){
              e["ClosedBy"] = e.closedObj["staffName"]
            }
          //  if(e.serviceTypeObj){
          //   e["serviceTypeName"] = e.serviceTypeObj["type"];
          //  }  
           if(e.workOrderTypeObj){
            e["workOrderType"] = e.workOrderTypeObj["name"];
           }
           if( e.ticketStatusObj){
            e["WOStatus"] = e.ticketStatusObj["NAME"];
           }
           if(e.consequanceObj){
            e["consequenceCategory"] = e.consequanceObj["NAME"];
           }
          return e;
         })
        
        }
        else{
          this.downloadWorkDataAfterCount = null;
        }
      });
  })
 }
 
}

  facilityValueChange(id: any): void {
    this.selectedFacility = id;
    this.slecteFacility = id;
    if(id == -1)
    {
      // this.pageRefresh();
      this.qureyFiliteroption = "";
      this.getDashBoardCount(),
      this.getWorkOrderByConseqCategory(),
      this.getWorkOrderByStatus(),
      this.getWorkOrderByServiceType(),
      this.getWorkOrderByRequest()
      // this.getAllWorkOrder();
    }
    else{
   this.getWoById("facilityId",id)
    this.getWorkOrderStatusById("facilityId",id);
    this.getWorkOByConsequenceCateById("facilityId",id);
    this.getWorkOrderByServiceTypeById("facilityId",id);
    this.getDashboardCountById("facilityId",id);
    this.getAllWorkOrderbyFilter("facilityId",id);
    this.getWorkOrderByRequestbyFacility("facilityId",id);
    }
  }

  handleFacilityFilter(value){
    this.facilityDropDown = this.facilityDropDown.filter(
        (s) => s.NAME.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );

    }
    
    getTimeDiff(startdate,enddate){
      if(startdate != null && enddate != null){
        var currentDate = new Date(startdate);
        var dateSent = new Date(enddate);
      var timeMinStart = new Date(startdate).getMinutes();
      var timeMinEnd = new Date(enddate).getMinutes();
      var timeHourStart = new Date(startdate).getHours();
      var timeHourEnd = new Date(enddate).getHours();
      var hourDiff =  timeHourEnd - timeHourStart; //in ms
      let minDiff:any =  timeMinEnd - timeMinStart;
      if (minDiff.toString().length == 1) {
        minDiff = `0` + minDiff
    }
        var duration =  hourDiff +":"+minDiff;
        this.netwaitingTime = duration;
        //return duration;
        //return  Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));


        var today : any = new Date(startdate);
        var todayMin : any = new Date(startdate).getMinutes();

        var Christmas: any = new Date(enddate);
        var ChristmasMin: any = new Date(enddate).getMinutes();

        var diffMs = Christmas - today; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days
        var diffHrs: any = Math.floor((diffMs % 86400000) / 3600000); // hours
        var diffMins: any = Math.floor(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if (diffHrs.toString().length == 1) {
          diffHrs = `0` + diffHrs
      }
      if(diffMins == '0' && todayMin != ChristmasMin){
        diffMins = '1';
      }
      if (diffMins.toString().length == 1) {
        diffMins = `0` + diffMins
    }
    
        return diffHrs +":"+ diffMins;

  }
  return "00:00";
        // console.log("dsfdf",this.workorder,this.user.filterData)
    }

    download() {
      if(this.tabTitle === "WO"){
      let fileName = 'workOrder.csv';
        let columnNames = [
         "So No.",
         "Work Order No", 
         "Description",
         "Respond SLA Start",//backend pending//
         "Respond SLA Recorded",//backend pending//
         "Completion SLA Record Start",
         "Completion SLA Record Stop",
         "Waiting TimeStart",//backend pending//
         "Waiting TimeEnd",//backend pending//
         "Net Waiting Time",//backend pending//
         "Follow Up Reason",//backend pending//
         "Facility",
         "Block Name",//backend pending//
         "Level Name",
         "Area Name",
         "Location Name",//backend pending//
         "Completion SLA",
         "Completed By",
         "Closed by",
         "Work Status",
         "Service Type",
         "Work Order Type", 
         "Consequence Category",
         "Chargeable",
         "Assignee",
         "Assign To",
        ];
         let header = columnNames.join(',');
         let csv = header;
         csv += '\r\n';
       
         this.downloadWorkOrderData.map(c => {
           csv += [
          c["soNo"],
         c["workOrderNo"], 
         c["description"] = c.description.replace(/,/g, '').replace(/[\t\n]+/g,' '),
         c["RespondSLAStart"],
         c["RespondSLARecorded"],
         c["CompletionSLARecordStart"],
         c["CompletionSLARecordStop"],
         c["WaitingTimeStart"],
         c["WaitingTimeEnd"],
         c["NetWaitingTime"],
         c["FollowUpReason"],
         c["facility"],
         c["blockName"],
         c["levelName"],
         c["areaName"],
         c["locationName"],
         c["CompletionSLA"],
         c["CompletedBy"],
         c["ClosedBy"],
         c["WOStatus"],
         c["serviceTypeName"],
         c["workOrderType"], 
         c["consequenceCategory"],
         c["chargeable"],
         c["assignee"],
         c["assignTo"]
           ].join(',');
           debugger;
             csv += '\r\n';
         })
         var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
       
         var link = document.createElement("a");
         if (link.download !== undefined) {
           var url = URL.createObjectURL(blob);
           link.setAttribute("href", url);
           link.setAttribute("download", fileName);
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
         }
      }
    
      else{
      let fileName = 'workOrderRequest.csv';
        let columnNames = [
          "So No.",
          "Request No",
          "Description",
          "Request Date & Time",
          "Facility",
          "Block Name",
          "Level Name",
          "Area Name",
          "Location Name",
          "Request Respond SLA",
           "Request Type",
           "ConsequenceCategory",
           "Service Type",
           "Chargeable",
           "Assign To"
      ];
       let header = columnNames.join(',');
       let csv = header;
       csv += '\r\n';
     
       this.downloadRequestData.map(c => {
         csv += [
          c["soNo"],
          c["requestNo"],
          c["description"] = c.description.replace(/,/g, '').replace(/[\t\n]+/g,' '),
          //c["RequestDate&Time"],
          c["ExpRequestDate&Time"],
          c["facility"],
          c["blockName"],
          c["levelName"],
          c["areaName"],
          c["locationName"],
          c["slaStatus"],
          c["workOrderType"],
          c["ConsequenceCategory"],
          c["serviceTypeName"],
          c["chargeable"],
          c["assignTo"]
          
         ].join(',');
         csv += '\r\n';
       })
       var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
     
       var link = document.createElement("a");
       if (link.download !== undefined) {
         var url = URL.createObjectURL(blob);
         link.setAttribute("href", url);
         link.setAttribute("download", fileName);
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
       }
      }
     }
    
getAssignTo(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/BPM/GetBpms",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}

getAssignne(){
  return new Promise((resolve,reject)=>{
    HttpClient.get("/api/Users/GetAllUser",true,true)
    .then((result)=>{
      resolve(result)
    })
    .catch((err)=>{
      reject(err);
    })
  })
}


filterChangedHandler(data=[]){
  this.filiterData = data;
  this.queryFiliterObj = null;
  this.qureyFiliteroption = "";
  let obj={};
  let datafiliter = {};
  if(data.length==0){
    this.filiterData = null;
    if(this.selectedFacility != 0 && this.selectedFacility != -1)
    {
      this.getWorkOrderStatusById("facilityId",this.selectedFacility);
      this.getWorkOByConsequenceCateById("facilityId",this.selectedFacility);
      this.getWorkOrderByServiceTypeById("facilityId",this.selectedFacility);
      this.getDashboardCountById("facilityId",this.selectedFacility);
      this.getAllWorkOrderbyFilter("facilityId",this.selectedFacility);
      this.getWorkOrderByRequestbyFacility("facilityId",this.selectedFacility);
    }else{
     this.qureyFiliteroption = "";
    this.getDashBoardCount(),
    this.getWorkOrderByConseqCategory(),
    this.getWorkOrderByStatus(),
    this.getWorkOrderByServiceType(),
    // this.getAllWorkOrder(),
    this.getWorkOrderByRequest()
    }
  }
// if(data.length >= 2){
//  data = data.splice(1,1);
// }

  data.forEach((d)=>{
    if(obj[d.field]){
    let count=obj[d.field]["count"]+1 || 1;
    obj[d.field]["to"]=d.value;
    obj[d.field]["count"]=count;
    obj[d.field]["dArray"].push(d.value); 
    }
    else{
      obj[d.field]={
        count:1,
        from:d.value,
        to:null,
        dArray:[d.value]
      }
    }
})
  let dataValue:any =[]
   if(false && data.length==1){
      data.filter((e)=>{
      if(e.field === "acceptanceDate"){
          // this.getWorkOrderStatusById("fromDate",this.formatDate(e.value));
          // this.getWorkOByConsequenceCateById("fromDate",this.formatDate(e.value));
          // this.getWorkOrderByServiceTypeById("fromDate",this.formatDate(e.value));
          // this.getDashboardCountById("fromDate",this.formatDate(e.value));
          // this.getAllWorkOrderbyFilter("fromDate",this.formatDate(e.value));
          let queryObj={}
          let filterData=obj[e.field] || {};
          if(obj[e.field].count==2){
              queryObj={
                fromDate:this.formatDate(filterData.from),
                toDate:this.formatDate(filterData.to)
              }
          }
          else{
            queryObj=null;
          }
          this.queryFiliterObj = queryObj;
          this.getWorkOrderStatusById("fromDate",this.formatDate(e.value),queryObj);
          this.getWorkOByConsequenceCateById("fromDate",this.formatDate(e.value),queryObj);
          this.getWorkOrderByServiceTypeById("fromDate",this.formatDate(e.value),queryObj);
          this.getDashboardCountById("fromDate",this.formatDate(e.value),queryObj);
          this.getAllWorkOrderbyFilter("fromDate",this.formatDate(e.value),queryObj);
          if(queryObj == null){
            this.qureyFiliteroption = "&fromDate=" + this.formatDate(e.value);
            }
      }
      if(e.field ==="ticketStatusObj.NAME"){
        dataValue =   this.ticketStatusObj.filterData.filter((d:any) => {
          return d.NAME == e.value
        });
        // dataValue =   this.ticketStatusObj.filterData.filter((d:any)=>{
        //   return d.NAME.indexOf(e.value)>-1;
        // });
        if( dataValue.length>0){
          let valueId =  dataValue[0].ID;
          this.getWorkOrderStatusById("statusId",valueId);
          this.getWorkOByConsequenceCateById("statusId",valueId);
          this.getWorkOrderByServiceTypeById("statusId",valueId);
          this.getDashboardCountById("statusId",valueId);
          this.getAllWorkOrderbyFilter("statusId",valueId);
        }
       }
      if(e.field==="consequanceObj.NAME"){
        dataValue =   this.consequanceObj.filterData.filter((d:any)=>{
          return d.NAME.indexOf(e.value)>-1;
           });
           if( dataValue.length>0){
          let valueId = dataValue[0].ID;
          let queryObj={}
          let filterData=obj[e.field] || {};
          if(obj[e.field].count==2){
              queryObj={
                categoryId:valueId,
              }
          }
          else{
            queryObj=null;
          }
          this.qureyFiliteroption = "";
           this.getWorkOrderStatusById("consequenceCategoryId",valueId);
           this.getWorkOByConsequenceCateById("consequenceCategoryId",valueId);
           this.getWorkOrderByServiceTypeById("consequenceCategoryId",valueId);
           this.getDashboardCountById("consequenceCategoryId",valueId);
          this.getAllWorkOrderbyFilter("consequenceCategoryId",valueId);
          this.qureyFiliteroption = "&consequenceCategoryId=" + valueId;

         }
       }
      if(e.field==="serviceTypeName"){

          dataValue =   this.WorkOrderService.filter((d:any)=>{
            return d.type.indexOf(e.value)>-1;
             });
             if( dataValue.length>0){
            let valueId = dataValue[0].id;
            let queryObj={}
            let filterData=obj[e.field] || {};
            if(obj[e.field].count==2){
                queryObj={
                  serviceType:valueId,
                }
            }
            else{
              queryObj=null;
            }
             this.getWorkOrderStatusById("serviceTypeName",valueId);
             this.getWorkOByConsequenceCateById("serviceTypeName",valueId);
             this.getWorkOrderByServiceTypeById("serviceTypeName",valueId);
             this.getDashboardCountById("serviceTypeId",valueId);
          this.getAllWorkOrderbyFilter("serviceTypeId",valueId);
             this.qureyFiliteroption = "&serviceTypeId=" + valueId;
           }
      }
      if(e.field==="assignToObj.name"){
            dataValue= this.assignToObj.filterData.filter((d:any)=>{
              return d.name.indexOf(e.value)>-1;
               });
           if( dataValue.length>0){
              let valueId = dataValue[0].id;
               this.getWorkOrderStatusById("vendorId",valueId);
               this.getWorkOByConsequenceCateById("vendorId",valueId);
               this.getWorkOrderByServiceTypeById("vendorId",valueId);
               this.getDashboardCountById("vendorId",valueId);
          this.getAllWorkOrderbyFilter("vendorId",valueId);
               this.qureyFiliteroption = "&vendorId=" + valueId;
             }
      }
    });
  }
  else if(data.length>=1){
    let queryObj={};
    data.filter((e)=>{
      if(e.field === "acceptanceDate"){
          let filterData=obj[e.field] || {};
          if(obj[e.field].count==2){ 
            queryObj["fromDate"]=this.formatDate(filterData.from),
            queryObj["toDate"]=this.formatDate(filterData.to)
          }
      }
      if(e.field ==="ticketStatusObj.NAME"){
        dataValue =   this.ticketStatusObj.filterData.filter((d:any) => {
          return d.NAME == e.value
        });
        if( dataValue.length>0){
          let valueId =  dataValue[0].ID;
          queryObj["statusId"]=valueId;
        }
       }
      if(e.field==="consequanceObj.NAME"){
          dataValue =   this.consequanceObj.filterData.filter((d:any)=>{
                                 return d.NAME.indexOf(e.value)>-1;
                         });
          if( dataValue.length>0){
            let valueId = dataValue[0].ID;
            queryObj["consequenceCategoryId"]=valueId;
           }
       }
      if(e.field==="serviceTypeName"){
          dataValue =   this.model.service.alldata.filter((d:any)=>{
            return d.NAME.indexOf(e.value)>-1;
             });
          if( dataValue.length>0){
            let valueId = dataValue[0].ID;
                queryObj["serviceTypeName"]=valueId;
           }
      }
      if(e.field==="assignToObj.name"){
            dataValue= this.assignToObj.filterData.filter((d:any)=>{
              return d.name.indexOf(e.value)>-1;
            });
           if( dataValue.length>0){
              let valueId = dataValue[0].id;
              queryObj["vendorId"]=valueId;
             }
       }
    });
    this.getWorkOrderStatusById(null,null,queryObj);
    this.getWorkOByConsequenceCateById(null,null,queryObj);
    this.getWorkOrderByServiceTypeById(null,null,queryObj);
    this.getDashboardCountById(null,null,queryObj);
 this.getAllWorkOrderbyFilter(null,null,queryObj);
  }
  //   data = this.gridData;
  //   let filteredArray:any =[]
  //   data.filters.map((e)=>{
  //        return e.filters.map((y)=>{
  //         if(!filteredArray.includes({"field":y.field ,"value":y.value})){
  //            return  filteredArray.push({"field":y.field ,"value":y.value})
  //         }
  //        })
  //  })
 
}


getWorkOrderStatusById(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  if(this.selectedFacility != 0 && this.selectedFacility != -1)
  {
    this.qureyFiliteroption = "&facilityId=" + this.selectedFacility;
  }
  return new Promise((resolve,reject)=>{
    var result = [{"id":0,"type":"Close","categoryType":null,"count":"78","typeOrder":0,"colorcode":"9ad201"},{"id":0,"type":"Complete","categoryType":null,"count":"107","typeOrder":0,"colorcode":"3e92cc"},{"id":0,"type":"In Progress","categoryType":null,"count":"101","typeOrder":0,"colorcode":"CC4F46"}];

    this.WorkOrderStatus = result.map((e) => {
      e["countValue"] = parseInt(e.count);
      e["colorcode"] = (`#` + e.colorcode);
      return e;
    });
    resolve(result)
    // HttpClient.get(`/api/Dashboard/GetWorkOrderByStatus?${queryArray.join("&")}` + this.qureyFiliteroption,true,true)
    // .then((result:any)=>{
    //   this.WorkOrderStatus = result.map((e) => {
    //     e["countValue"] = parseInt(e.count);
    //     e["colorcode"] = (`#` + e.colorcode);
    //     return e;
    //   });

    //   resolve(result)
    // })
    // .catch((err)=>{
    //   reject(err);
    // })
  })
}
getWorkOrderByServiceTypeById(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  if(this.selectedFacility != 0 && this.selectedFacility != -1)
  {
    this.qureyFiliteroption = "&facilityId=" + this.selectedFacility;
  }else{
    this.qureyFiliteroption ="";
  }
  return new Promise((resolve,reject)=>{

    var result = [{"id":3,"type":"Mechanical","categoryType":null,"count":"28","typeOrder":0,"colorcode":"CC4F46"},{"id":2,"type":"Electrical","categoryType":null,"count":"149","typeOrder":0,"colorcode":"CC4F46"},{"id":5,"type":"Housekeeping","categoryType":null,"count":"129","typeOrder":0,"colorcode":"CC4F46"},{"id":6,"type":"Hardware","categoryType":null,"count":"88","typeOrder":0,"colorcode":"CC4F46"}];
    this.WorkOrderService = result.map((e) => {
      e["countValue"] = parseInt(e.count);
      e["colorcode"] = (`#` + e.colorcode);
      return e;
    });

    // HttpClient.get(`/api/Dashboard/GetWorkOrderByServiceType?${queryArray.join("&")}` +  this.qureyFiliteroption,true,true)
    // .then((result:any)=>{
    //   this.WorkOrderService = result.map((e) => {
    //     e["countValue"] = parseInt(e.count);
    //     e["colorcode"] = (`#` + e.colorcode);
    //     return e;
    //   });
    //   resolve(result)
    // })
    // .catch((err)=>{
    //   reject(err);
    // })
  })
}

getDashboardCountById(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  if(this.selectedFacility != 0 && this.selectedFacility != -1)
  {
    this.qureyFiliteroption = "&facilityId=" + this.selectedFacility;
  }else{
    this.qureyFiliteroption ="";
  }
  this.qureyFiliteroption = queryArray.join("&") + this.qureyFiliteroption != "" ? "&" +this.qureyFiliteroption : this.qureyFiliteroption;
  return new Promise((resolve,reject)=>{

    var result = [{"id":10,"type":"Asset Request","categoryType":null,"count":"53","typeOrder":1,"colorcode":"000"},{"id":0,"type":"Total Asset","categoryType":null,"count":"394","typeOrder":2,"colorcode":"000"},{"id":2,"type":"In Progress Asset","categoryType":null,"count":"95","typeOrder":3,"colorcode":"000"},{"id":3,"type":"Pending Asset","categoryType":null,"count":"11","typeOrder":4,"colorcode":"000"},{"id":4,"type":"Completed Asset","categoryType":null,"count":"57","typeOrder":5,"colorcode":"1eb45d"},{"id":5,"type":"Closed Asset","categoryType":null,"count":"55","typeOrder":6,"colorcode":"1eb45d"},{"id":11,"type":"Cancel Asset Request","categoryType":null,"count":"53","typeOrder":7,"colorcode":"cd180e"},{"id":12,"type":"Asset Type","categoryType":null,"count":"220","typeOrder":8,"colorcode":"cd180e"}];

    this.dashboardCount = result.map((e) => {
      e["colorcode"] = `#` + e.colorcode;
      return e;
    });
    resolve(result);

    // HttpClient.get(`/api/Dashboard/GetDashboardCounts?${queryArray.join("&")}`+ this.qureyFiliteroption,true,true)
    // .then((result:any)=>{
    //   this.dashboardCount = result.map((e) => {
    //     e["colorcode"] = `#` + e.colorcode;
    //     return e;
    //   });
    //   resolve(result)
    // })
    // .catch((err)=>{
    //   reject(err);
    // })
  })
}

getWorkOByConsequenceCateById(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  if(this.selectedFacility != 0 && this.selectedFacility != -1)
  {
    this.qureyFiliteroption = "&facilityId=" + this.selectedFacility;
  }else{
    this.qureyFiliteroption ="";
  }
  return new Promise((resolve,reject)=>{
    var result = [{"id":0,"type":"Active","categoryType":null,"count":"119","typeOrder":1,"colorcode":"92d050"},{"id":0,"type":"Maintenance","categoryType":null,"count":"170","typeOrder":2,"colorcode":"3e92cc"},{"id":0,"type":"In Active","categoryType":null,"count":"61","typeOrder":3,"colorcode":"e44235"}];
          this.WorkOrderType = result.map((e) => {
        e["countValue"] = parseInt(e.count);
        e["colorcode"] = (`#` + e.colorcode);
        return e;
      });
      resolve(result);

    // HttpClient.get(`/api/Dashboard/GetWorkOrderByConsequenceCategory?${queryArray.join("&")}` + this.qureyFiliteroption,true,true)
    // .then((result:any)=>{
    //   this.WorkOrderType = result.map((e) => {
    //     e["countValue"] = parseInt(e.count);
    //     e["colorcode"] = (`#` + e.colorcode);
    //     return e;
    //   });
    //   resolve(result)
    // })
    // .catch((err)=>{
    //   reject(err);
    // })
  })
}

getWoById(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Dashboard/GetAllWorkOrders?${queryArray.join("&")}`,
      true,
      true
    )
      .then((result: any) => {
        this.gridData = result.map((d,index) => {
          d["soNo"] = index+1
          d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
          d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
          d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
          d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
          d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
          d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
          // d["acceptanceDate"] = this.formatDateTime(d.acceptanceDate);
          d["acceptanceDate"] = new Date(d.createdDate);
          return d;
        })
      });
  })
}

getWoRequestId(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Dashboard/GetWorkRequest?${queryArray.join("&")}`,
      true,
      true
    )
      .then((result: any) => {
        if (result.length > 0) {
          this.gridDataRequest = result.map((d,index) => {
            d["soNo"] = index+1
            d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
            d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
            d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
            d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
            d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
            d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
            d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
            //d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
            d["serviceTypeName"] = d.serviceTypeName;
            d["facility"] = this.facilityObj[d.facilityID];
            d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
            d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
            d["acceptanceDate"] =  new Date(d.createdDate);
            if(d.chargable)
            {
            d["chargeable"] = "Yes";
            }
            else{
            d["chargeable"] = "No";
            }
            d["CompletionSLA"] = d.slaStatus;
            d["blockName"] = d.blockName;
            d["levelName"] = d.levelName;
            d["areaName"] = d.areaName;
            d["locationName"] = d.locationName
            d["CompletionSLA"] = d.slaStatus;
            d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
            d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
            d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
            d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
            d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);
            d["FollowUpReason"] = d.followUpReason;
            d["RequestDate&Time"] =  new Date(d.createdDate);
            d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
            // d["acceptanceDate"] = new Date(d.acceptanceDate);
            return d;
          });
  
          this.downloadRequestData = this.gridDataRequest.map((e)=>{
             
            if(e.assignToObj){
              e["assignTo"] = e.assignToObj["name"];}
              if(e.assigneeObj){
                e["assignee"] = e.assigneeObj["staffName"];
               }
              if(e.completedObj){
                e["CompletedBy"] = e.completedObj["staffName"]
              }
              if(e.closedObj){
                e["ClosedBy"] = e.closedObj["staffName"]
              }
            //  if(e.serviceTypeObj){
            //   e["serviceTypeName"] = e.serviceTypeObj["type"];
            //  }  
             if(e.workOrderTypeObj){
              e["workOrderType"] = e.workOrderTypeObj["name"];
             }
             if( e.ticketStatusObj){
              e["WOStatus"] = e.ticketStatusObj["NAME"];
             }
             if(e.consequanceObj){
              e["consequenceCategory"] = e.consequanceObj["NAME"];
             }
             if(e.consequanceObj){
              e["ConsequenceCategory"] = e.consequanceObj["NAME"];
             }
            return e;
          })
        }
      });
  })
}

getWorkOrderByRequestbyFacility(paramsName,id,queryObj=null){
  let queryArray=[]
  if(queryObj){
    for(let k in queryObj){
    queryArray.push(k+"="+queryObj[k])
    }
  }
  else{
    queryArray.push(paramsName+"="+id);
  }
  return new Promise((resolve, reject) => {
    HttpClient.get(`/api/Dashboard/GetWorkRequest?${queryArray.join("&")}`,true,true)
      .then((result: any) => {
          this.bindLevelObj(this.levelData);
          this.bindFacilityObj(this.facilityDropDown);
          this.bindAreaObj(this.areaData);
        this.gridDataRequest = result.map((d,index) => {
          d["soNo"] = index+1
          d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
          d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
          d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
          d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
          d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
          d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
          d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
          //d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
          d["serviceTypeName"] = d.serviceTypeName;
          // d["levelName"] = this.levelObj[d.levelID];
          // d["facility"] = this.facilityObj[d.facilityID];
          // d["areaName"] =this.areaObj[d.areaID];
          d["blockName"] = d.blockName;
          d["levelName"] = d.levelName;
          d["areaName"] = d.areaName;
          d["locationName"] = d.locationName;
          d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
          d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
          d["acceptanceDate"] =  new Date(d.createdDate);
          if(d.chargable)
          {
          d["chargeable"] = "Yes";
          }
          else{
          d["chargeable"] = "No";
          }
          d["CompletionSLA"] = d.slaStatus;
          d["blockName"] = d.blockName;
          d["levelName"] = d.levelName;
          d["areaName"] = d.areaName;
          d["locationName"] = d.locationName
          d["CompletionSLA"] = d.slaStatus;
          d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
          d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
          d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
          d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
          d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);
          d["FollowUpReason"] = d.followUpReason;
          d["RequestDate&Time"] =  new Date(d.createdDate);
          d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
          // d["acceptanceDate"] = new Date(d.acceptanceDate);
          return d;
        });

        this.downloadRequestData = this.gridDataRequest.map((e)=>{
           
          if(e.assignToObj){
            e["assignTo"] = e.assignToObj["name"];}
            if(e.assigneeObj){
              e["assignee"] = e.assigneeObj["staffName"];
             }
            if(e.completedObj){
              e["CompletedBy"] = e.completedObj["staffName"]
            }
            if(e.closedObj){
              e["ClosedBy"] = e.closedObj["staffName"]
            }
          //  if(e.serviceTypeObj){
          //   e["serviceTypeName"] = e.serviceTypeObj["type"];
          //  }  
           if(e.workOrderTypeObj){
            e["workOrderType"] = e.workOrderTypeObj["name"];
           }
           if( e.ticketStatusObj){
            e["WOStatus"] = e.ticketStatusObj["NAME"];
           }
           if(e.consequanceObj){
            e["consequenceCategory"] = e.consequanceObj["NAME"];
           }
           if(e.consequanceObj){
            e["ConsequenceCategory"] = e.consequanceObj["NAME"];
           }
          return e;
        })
        resolve(result)
      })
     
      .catch((err) => {
        return err;
      });
  });
}

getWorkOrderByRequest(){
  return new Promise((resolve, reject) => {
    HttpClient.get(
      "/api/Dashboard/GetWorkRequest?" + this.qureyFiliteroption,
      true,
      true
    )
      .then((result: any) => {
          this.bindLevelObj(this.levelData);
          this.bindFacilityObj(this.facilityDropDown);
          this.bindAreaObj(this.areaData);
        this.gridDataRequest = result.map((d,index) => {
          d["soNo"] = index+1
          d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
          d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
          d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
          d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
          d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
          d["completedObj"] = this.assigineeObj.objectData[d.completedBy];
          d["closedObj"] = this.assigineeObj.objectData[d.closedBy];
          // d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
          d["serviceTypeName"] = d.serviceTypeName;
         // d["levelName"] = this.levelObj[d.levelID];
          d["facility"] = this.facilityObj[d.facilityID];
          //d["areaName"] =this.areaObj[d.areaID];
          d["CompletionSLARecordStart"] = this.formatDateTime(d.checkInFlagDateTime);
          d["CompletionSLARecordStop"] = this.formatDateTime(d.closingDateTime);
          d["acceptanceDate"] =  new Date(d.createdDate);
          if(d.chargable)
          {
          d["chargeable"] = "Yes";
          }
          else{
          d["chargeable"] = "No";
          }
          d["CompletionSLA"] = d.slaStatus;
         // d["blockName"] = d.blockName;
         d["blockName"] = d.blockName;
         d["levelName"] = d.levelName;
         d["areaName"] = d.areaName;
         d["locationName"] = d.locationName;
          d["CompletionSLA"] = d.slaStatus;
          d["RespondSLAStart"] = this.formatDateTime(d.createdDate);
          d["RespondSLARecorded"] = this.formatDateTime(d.acceptanceDate);
          d["WaitingTimeStart"] = this.formatDateTime(d.followUpDateTimeStart);
          d["WaitingTimeEnd"] = this.formatDateTime(d.followUpDateTimeStop);
          d["NetWaitingTime"] = this.getTimeDiff(d.followUpDateTimeStart,d.followUpDateTimeStop);
          d["FollowUpReason"] = d.followUpReason;
          d["RequestDate&Time"] =  new Date(d.createdDate);
          d["ExpRequestDate&Time"] =  this.formatDateTime(d.createdDate);
          // d["acceptanceDate"] = new Date(d.acceptanceDate);
          return d;
        });

        this.downloadRequestData = this.gridDataRequest.map((e)=>{
           
          if(e.assignToObj){
            e["assignTo"] = e.assignToObj["name"];}
            if(e.assigneeObj){
              e["assignee"] = e.assigneeObj["staffName"];
             }
            if(e.completedObj){
              e["CompletedBy"] = e.completedObj["staffName"]
            }
            if(e.closedObj){
              e["ClosedBy"] = e.closedObj["staffName"]
            }
          //  if(e.serviceTypeObj){
          //   e["serviceTypeName"] = e.serviceTypeObj["type"];
          //  }  
           if(e.workOrderTypeObj){
            e["workOrderType"] = e.workOrderTypeObj["name"];
           }
           if( e.ticketStatusObj){
            e["WOStatus"] = e.ticketStatusObj["NAME"];
           }
           if(e.consequanceObj){
            e["consequenceCategory"] = e.consequanceObj["NAME"];
           }
           if(e.consequanceObj){
            e["ConsequenceCategory"] = e.consequanceObj["NAME"];
           }
          return e;
        })
        resolve(result)
      })
     
      .catch((err) => {
        return err;
      });
  });
}

checkedColumn(id){

  return new Promise((resolve, reject) => {
    HttpClient.get(
      `/api/Dashboard/GetAllWorkOrders?id=${id}`,
      true,
      true
    )
      .then((result: any) => {
        this.woPopUpData = result.map((d,index)=>{
            d["soNo"] = index+1;
            d["consequanceObj"] = this.consequanceObj.objectData[d.ticketConsequenceId];
            d["ticketStatusObj"] = this.ticketStatusObj.objectData[d.ticketStatusId];
            d["workOrderTypeObj"] = this.workOrderTypeObj.objectData[d.ticketTypeId];
            d["assigneeObj"]=this.assigineeObj.objectData[d.assignee];
            d["assignToObj"]=this.assignToObj.objectData[d.bpmid];
            d["serviceTypeObj"] = this.serviceTypeObj.objectData[d.serviceTypeId];
            d["serviceTypeName"] = d.serviceTypeName;
            // d["requestDate"] = this.formatDateTime(d.createdDate);
            d["requestDate"] = new Date(d.createdDate);
            d["facilityObj"] = this.getFacilityName(d.facilityID);
            d["acceptanceDate"] = new Date(d.createdDate); 
            d["locationName"] = d.locationName;
            this.isWOType = d.convertToWorkOrder;
            
            // d["acceptanceDate"] = new Date(d.acceptanceDate);
            return d;
        });
        this.isWOType ? this.woPopupTitle = this.woPopUpData[0].workOrderNo :  this.woPopupTitle = this.woPopUpData[0].requestNo;
        this.woPopUpopened = true;
      });
    })
  }

  getFacilityName(id) {

    let findedData = this.facilityDropDown.find(i => i.ID === id);
    if (typeof findedData === 'undefined') {
       return null;
    }
    return findedData;
}

downloadForpopup(){

 
  if(this.popUpNameByCount === "CRM Request" || this.popUpNameByCount === "Exceed Request SLA"){
    let fileName = 'workOrderRequest.csv';
    let columnNames = [
     "SO No.",
     "Request No",
     "Description",
     "Request Date & Time",
     "Facility",
     "Block Name",
     "Level Name",
     "Area Name",
     "Location Name",
     "Request Respond SLA",
     "Request Type",
      "Consequence Category",
      "Service Type",
      "Chargeable",
      "Assign To"
  ];
   let header = columnNames.join(',');
   let csv = header;
   csv += '\r\n';
 if(this.downloadWorkDataAfterCount.length != 0)
 {
   this.downloadWorkDataAfterCount.map(c => {
     csv += [
      c["soNo"],
      c["requestNo"],
      // c["description"] = c.description.replace(/,/g, ''),
      c["description"] = c.description.replace(/,/g, '').replace(/[\t\n]+/g,' '),
      // c["description"],
      //c["RequestDate&Time"],
      c["ExpRequestDate&Time"],
      c["facility"],
      c["blockName"],
      c["levelName"],
      c["areaName"],
      c["locationName"],
      c["RequestRespondSLA"],
      c["RequestType"],
      c["consequenceCategory"],
      c["serviceTypeName"],
      c["chargeable"],
      c["assignTo"]
      
     ].join(',');
     debugger;
     csv += '\r\n';
   })
  }
   var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
 
   var link = document.createElement("a");
   if (link.download !== undefined) {
     var url = URL.createObjectURL(blob);
     link.setAttribute("href", url);
     link.setAttribute("download", fileName);
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   }
  
  }
  else{

    let fileName = 'workOrder.csv';
    let columnNames = [
      "SO No.",
         "Work Order Number", 
         "Description",
         "Respond SLA Start",//backend pending//
         "Respond SLA Recorded",//backend pending//
         "Completion SLA Record Start ",
         "Completion SLA Record Stop",
         "Waiting Time Start",//backend pending//
         "Waiting Time End",//backend pending//
         "Net Waiting Time",//backend pending//
         "Follow Up Reason",//backend pending//
         "Facility",
         "Block Name",//backend pending//
         "Level Name",
         "Area Name",
         "Location Name",//backend pending//
         "Completion SLA",
         "Completed by",
         "Closed by",
         "WO Status",
         "Service Type",
         "Work Order Type ", 
         "Consequence Category",
         "Chargeable",
         "Assignee",
         "Assign To",
    ];
     let header = columnNames.join(',');
     let csv = header;
     csv += '\r\n';
   
     if(this.downloadWorkDataAfterCount != null)
     {
     this.downloadWorkDataAfterCount.map(c => {
       csv += [
        c["soNo"],
        c["workOrderNo"], 
        c["description"] = c.description.replace(/,/g, '').replace(/[\t\n]+/g,' '),
        // c["description"],
        c["RespondSLAStart"],
        c["RespondSLARecorded"],
        c["CompletionSLARecordStart"],
        c["CompletionSLARecordStop"],
        c["WaitingTimeStart"],
        c["WaitingTimeEnd"],
        c["NetWaitingTime"],
        c["FollowUpReason"],
        c["facility"],
        c["blockName"],
        c["levelName"],
        c["areaName"],
        c["locationName"],
        c["CompletionSLA"],
        c["CompletedBy"],
        c["ClosedBy"],
        c["WOStatus"],
        c["serviceTypeName"],
        c["workOrderType"], 
        c["consequenceCategory"],
        c["chargeable"],
        c["assignee"],
        c["assignTo"]
        
       ].join(',');
       debugger;
       csv += '\r\n';
     })
    }
     var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
   
     var link = document.createElement("a");
     if (link.download !== undefined) {
       var url = URL.createObjectURL(blob);
       link.setAttribute("href", url);
       link.setAttribute("download", fileName);
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     }
    
  }
  }

  downloadPdf(url) {
    const pdfUrl = url;
    const pdfName = 'woPdf';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  countValueChange(data){
      this.selectedCount = data;
      this.sizeGridChange = data;
      if(data == 10)
      {
        this.pageHeight = "height:850px";
      }
      if(data == 20)
      {
        this.pageHeight = "height:1620px";
      }
      if(data == 30)
      {
        this.pageHeight = "height:2300px";
      }
      if(data == 50)
      {
        this.pageHeight = "height:3700px";
      }
    //  this.getAllWorkOrder();
    //  this.getWorkOrderByRequest()

  }
}
