import { Component, OnInit } from '@angular/core';
import {ModelApi} from "src/ApiModel/const"
import {Model} from "src/ApiModel/Model"
import {TaskAnswerType} from "src/ApiModel/Model/TaskAnswerType"
import { eventListner, EVENT_NAME } from 'src/event';
@Component({
  selector: 'app-checklist-answer-type',
  templateUrl: './checklist-answer-type.component.html',
  styleUrls: ['./checklist-answer-type.component.scss']
})
export class ChecklistAnswerTypeComponent implements OnInit {

  constructor() { 
    eventListner(EVENT_NAME.edit_column, (e) => { this.editColumn(e) });
    eventListner(EVENT_NAME.remove_column, (e) => { this.deleteColumn(e) });

  }
  public tempData:any={}
  public opened=false;
  public type=""
  public dailogTitle="Add Answer Type"
  public model:Model=new Model();
  public data=new TaskAnswerType();
  public task:any=[]
  public column=[
    {field:"DESCRIPTION",title:"Description",isFilter:true,width:200},
  ]
  ngOnInit(): void {
    this.getData();
  }
  onDialogClose(){
    this.opened=false;
  }
  openRoleDailog(type){
     this.type=type;
     this.opened=true;
  }
  valuechange(type,data){
    if(type=="checklist"){
      this.model.checklist.selectedObj=data;
    }
  }
  saveData(){
    if(this.type==="new"){
       this.data.saveData(this.data.DESCRIPTION)
       .then((result)=>{
        
            this.getData()
            this.opened=false;
       })
       .catch((err)=>{
         
       })
    }
    else if(this.type=="update"){
      this.data.updateData(this.data.ID,this.data.DESCRIPTION)
      .then((result)=>{
          
           this.getData()
           this.opened=false;
      })
      .catch((err)=>{
        
      })
    }
  }
  reset(){

  }

  getData(){
    this.model.getData(ModelApi.TaskAnswerType)
    .then((result)=>{
      this.task=this.model.taskAndType.alldata.map((d)=>{
        //d["checkListObj"]=this.model.checklist.objectData[d.CHECKLISTID];
        return d;
       })
    })
  }
  editColumn(d){
    this.dailogTitle="Update Answer Type"
     this.data.ID=d.dataItem.ID;
     this.data.DESCRIPTION=d.dataItem.DESCRIPTION;
   //  this.model.checklist.selectedObj=d.dataItem["checkListObj"];
     this.type="update";
     this.opened=true;
  }

 deleteColumn(d){
    this.data.deleteData(d.dataItem)
    .then((result)=>{
      this.getData();
    })
 }

}
