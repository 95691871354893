const formData=[
    
    {
      name:"ID",
      key:"ID",
      type:"lable",
      label:"",
      value:0,
      insert:true,
      update:true  
    },
    {
        name:"FACILITYID",
        key:"FACILITYID",
        table:"Facility",
        type:"list",
        label:"Facility", 
        props:{
            filter:true,
            textField:"NAME",
            valueField:"ID"
        },
        insert:true,
        update:true  
    },
    {
        name:"CODE",
        key:"CODE",
        type:"text",
        label:"Block Code",
        value:"",
        insert:true,
        update:true    
    },
    {
        name:"NAME",
        key:"NAME",
        type:"text",
        label:"Block Name",
        insert:true,
        update:true  
    },
    {
        name:"SHORTNAME",
        key:"SHORTNAME",
        type:"text",
        label:"Short Name",
        insert:true,
        update:true  
    },
    {
        name:"STATUSID",
        key:"STATUSID",
        table:"Status",
        type:"list",
        props:{
            filter:true,
            textField:"NAME",
            valueField:"ID"
        },
        label:"Status",
        value:{},
        insert:true,
        update:true    
    },
    {
        name:"STATUSID",
        key:"Status.NAME",
        type:"lable",
        label:"hidden",
        value:"",
        insert:false,
        update:false  
    },
    {
        name:"CREATEDBY",
        key:"CREATEDBY",
        type:"hidden",
        label:"",
        insert:false,
        update:false  
    },
    {
        name:"MODIFIEDBY",
        key:"MODIFIEDBY",
        type:"hidden",
        label:"",
        insert:false,
        update:false  
    },
    {
        name:"CREATEDDATE",
        key:"CREATEDDATE",
        type:"hidden",
        label:"",
        insert:false,
        update:false  
    },
    {
        name:"MODIFIEDDATE",
        key:"MODIFIEDDATE",
        type:"hidden",
        label:"",
        insert:false,
        update:false  
    }
]

const gridColumn=[
    { field: "NAME", title: "Block Name", isFilter: true },
    { field: "SHORTNAME", title: "Shortname", isFilter: false },
    { field: "Status.NAME", title: "Status", isFilter: false },
    { field: "Facility.NAME", title: "Facility Name", isFilter: false },
    // { field: "CODE", title: "Code", isFilter: true },
    
]

const listData={
    "Facility":[],
    "Status":[]
}
const fkPrimaryKey={
    "Facility":"ID",
    "Status":"ID",
}
const extraColumn=[
    {key:"FACILITYID",table:"Facility",column:["ID","NAME"]},
    {key:"STATUSID",table:"Status",column:["ID","NAME"]},
]

const template={
    title:"Block",
    add_btn:"Add block",
    add_dailog_title:"Add Block",
    edit_dailog_title:"Edit Block",
    delete_dailog_title:"Delete Block",
    delete_description:"Are you sure you want to delete"
}
export default {formData,gridColumn,listData,fkPrimaryKey,extraColumn,template}